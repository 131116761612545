<div id="loaderG" *ngIf="isLoaderVisible">
    <div [ngClass]="{
        'load-bar': shouldLoaderBeFixed,
        'scroll-bar': shouldLoaderScroll
    }">
        <div class="bar"></div>
        <div class="bar"></div>
        <div (window:scroll)="determineLoadingBarPosition($event)"></div>
    </div>
</div>

<div class="loader" *ngIf="isBlockerVisible">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
</div>
<button *ngIf="_searchService.searchCancelSubject" class="btn-outline clickable cancelSearchButton" (click)="cancel()">Cancel Search</button>
