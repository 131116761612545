import {Injectable} from '@angular/core';
import {UserHelper} from '../helpers/userHelper';

declare function downloadJSOnload();
// eslint-disable-next-line no-var
declare var ws;

@Injectable()
export class ThinkOwlChatService {
    public chatScriptIsLoaded = false;
    public chatScriptLoading = false;

    public unit = '6f367704-0b9f-47f3-854a-bb4a3a4a289c';
    public theme = '22d77972-5d5a-4e08-a0ff-f4d95c0fad7a';
    public theme2 = '45983ff1-1729-4aa7-beb0-5814f74660a2';

    constructor(private _userHelper: UserHelper) {};

    public loadChat() {
        if (!this.chatActive()) {
            return;
        }

        if (!this.chatScriptIsLoaded && !this.chatScriptLoading) {
            this.chatScriptLoading = true;
            const node = document.createElement('script');
            node.src = 'https://odyssey.thinkowl.com/chat-service/api/loadChat.js?unit=' + this.unit + '&theme_id=' + this.theme + '&language=en';
            node.type = 'text/javascript';
            node.async = true;
            document.getElementsByTagName('head')[0].appendChild(node);
        }

        this.initChat();
    }

    public removeChat() {
        if (!this.chatActive()) {
            return;
        }

        const chatWidgets: HTMLCollection = document.getElementsByClassName('chat-widget_');
        let widget: Element;

        if (chatWidgets.length) {
            for (widget of Array.from(chatWidgets)) {
                widget.remove();
            }
        }
    }

    private initChat() {
        setTimeout(() => {
            if ((typeof downloadJSOnload === 'function') && (this.chatScriptLoading)) {
                downloadJSOnload();
                this.chatScriptLoading = false;
                this.chatScriptIsLoaded = true;
            } else if (!this.chatScriptIsLoaded) {
                this.initChat();
            }
        }, 100);
    }

    private chatActive() {
        return !!(this._userHelper.getUserSettings().chatActive || this._userHelper.isAvailableForTesting());
    }
}
