<div class="">
  <div class="create-dashboard-modal">
    <div class="modal-body">
      <div class="bodyContent">
        <div class="description-container">
          <div class="hiddenCheckbox">
            <i class="fa fa-square-o" aria-hidden="true"></i>
          </div>
          <div class="viewName">Name</div>
          <div class="viewNameDetails">View Details</div>
        </div>
        <div class="viewsHolder scrollbar">
          <div class="viewHeader" *ngFor="let category of viewCategories">
            <div class="heading row bottomBorderShadow">
              <div class="headerCheckbox fixedCheck" [ngSwitch]="category.name">
                <span *ngSwitchCase="dashboardViewCategories.user_created">
                  <i class="fa fa-user userCreatedIcon" aria-hidden="true"></i>
                </span>
                <span *ngSwitchCase="dashboardViewCategories.quote_management">
                  <i class="fa fa-usd quoteManagementIcon" aria-hidden="true"></i>
                </span>
                <span *ngSwitchCase="dashboardViewCategories.record_management">
                  <i class="fa fa-user recordManagementIcon" aria-hidden="true"></i>
                </span>
                <span *ngSwitchCase="dashboardViewCategories.global_views">
                  <i class="fa fa-list-alt globalviewsIcon" aria-hidden="true"></i>
                </span>
                <span *ngSwitchDefault>
                  <i class="fa fa-binoculars" aria-hidden="true"></i>
                </span>
              </div>
              <div class="viewTitle">{{category.name}}</div>
              <div class="viewColumns"></div>
            </div>
            <div class="row bottomBorderShadow" *ngFor="let viewtype of category.associatedViews">
              <div class="viewDetailsRow" *ngIf="viewtype.view.editable">
                <div class="checkbox fixedCheck clickable">
                  <i (click)="toggleCheck(viewtype)" *ngIf="!viewtype.selected && viewtype.selectable" class="fa fa-square-o uncheckedBox"
                    aria-hidden="true"></i>
                  <i (click)="toggleCheck(viewtype)" *ngIf="viewtype.selected && viewtype.selectable" class="fa fa-check-square" aria-hidden="true"></i>
                  <span class="emptyCheck" *ngIf="!viewtype.selectable">
                    <i class="fa fa-square-o uncheckedBox"></i>
                  </span>
                </div>
                <div class="viewTitle">{{viewtype.view.name}}</div>
                <div class="viewColumns">
                  <span class="availableHeaders" *ngFor="let column of viewtype.view.visibleColumns; let i = index">
                    <span *ngIf="viewtype?.view?.viewType === 'templateQuery'">{{ column | DisplayData:'searchEntityColumns':viewtype?.view?.templateContentQuery.entityType}}</span>
                    <span *ngIf="viewtype?.view?.viewType === 'contentQuery'">{{ column | DisplayData:'searchEntityColumns':viewtype?.view?.viewContentQuery[0]?.entityType}}</span>
                    <span *ngIf="i < viewtype.view.visibleColumns.length - 1">, </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttonHolder">
      <button class="button btn-outline clickable" (click)="closeModal()">Cancel</button>
      <button class="button btn-outline clickable" (click)="removeViewFromLibrary()">Delete View</button>
      <button class="button btn-outline clickable" (click)="submit()">Create View</button>
    </div>
  </div>
</div>