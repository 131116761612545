import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {map, shareReplay, switchMap} from 'rxjs/operators';
import {HandlingUnit} from '../../../models/handlingUnit';
import {NmfcSearchResult} from '../../../models/nmfc.searchResult';
import {NmfcClass} from '../../../models/nmfcClass';
import {PackageType} from '../../../models/packageType';
import {ProductMasterData} from '../../../models/product.masterData';
import {CommonDataService} from '../../../services/commonData.service';
import {NEW_PRODUCT, ProductsService} from '../../../services/products.service';
import {of} from "rxjs";

@Component({
    selector: 'app-master-data-products',
    styleUrls: ['./masterData-products.component.scss'],
    templateUrl: './masterData-products.component.html'
})
export class MasterDataProductsComponent implements OnInit {
    @ViewChild('nmfcNumberInput')
    public nmfcNumberInput: ElementRef;

    public productForm: UntypedFormGroup;

    public productFormNMFCClasses: Array<NmfcClass> = [];
    public productFormHandlingUnits: Array<HandlingUnit> = [];
    public productFormPackageTypes: Array<PackageType> = [];
    public nmfcSearchModalShown = false;
    public productId: string;

    constructor(
        private _router: Router,
        private _commonDataService: CommonDataService,
        private _productsService: ProductsService,
        private _route: ActivatedRoute,
    ) {
    }

    public ngOnInit() {

        this.productFormNMFCClasses = this._commonDataService.nmfcClasses;
        this.productFormHandlingUnits = this._commonDataService.handlingUnits;
        this.productFormPackageTypes = this._commonDataService.packageTypes;

        const productForm$ = this._route.params.pipe(
            switchMap((params) => {
                this.productId = params['productId'];
                return this._productsService.getProductByPath(this.productId);
            }),
            switchMap(() => {
                if (!this.productForm) {
                    this.productForm = this._productsService.getProductFormFromLastProduct();
                } else {
                    this._productsService.updateFormFromLastProduct(this.productForm);
                }

                return of(this.productForm);
            }),
            shareReplay()
        );

        productForm$.subscribe();
    }

    public cancelChanges(): void {
        this._productsService.updateFormFromLastProduct(this.productForm);
        this.productForm.markAsPristine();
    }

    public saveChanges(): void {
        const product = this._productsService.getProductFromForm(this.productForm);

        if (this.isNewProduct()) {
            this._productsService.createProduct(product).pipe(
                map((p: ProductMasterData) => {
                    this._productsService.getProductsList().subscribe();
                    this._router.navigate([ `masterData/products/${p.produtsId}`]);
                })
            ).subscribe();
        } else {
            this._productsService.updateProduct(product).pipe(
                map(p => {
                    this._productsService.getProductsList().subscribe();
                    this._productsService.lastProduct = p;
                    this._productsService.updateFormFromLastProduct(this.productForm);
                })
            ).subscribe();
        }
    }

    public deleteProduct(): void {
        this._productsService.deleteProduct(this.productId).subscribe(() => {
            this._productsService.getProductsList().subscribe();
            this._router.navigate(['masterData']);
        });
    }

    public getClassLabel(theClass: NmfcClass) {
        return (theClass) ? theClass.displayClass : 0;
    }

    public getClassKey(theClass: NmfcClass) {
        return (theClass) ? theClass.class : 0;
    }

    public nmfcSelected(result: NmfcSearchResult) {
        this.nmfcSearchModalShown = false;
        this.nmfcNumberInput.nativeElement.focus();

        if (result) {
            this.productForm.controls.class.setValue(result.class);
            this.productForm.controls.class.markAsDirty();
            this.productForm.controls.nmfc.setValue(result.itemno);
            this.productForm.controls.nmfc.markAsDirty();
            if (!this.productForm.controls.description.value) {
                this.productForm.controls.description.setValue(result.itemdescription);
                this.productForm.controls.description.markAsDirty();
            }
        }
    }

    public isNewProduct() {
        return this.productId === NEW_PRODUCT;
    }
}
