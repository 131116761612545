<ng-template #defaultContentRenderer let-tab="tab">
    <ng-container *ngFor="let tabIcon of tab.tabIcons">
        <span  class="arrowIcon">
            <span class="fa-stack fa-lg">
                <i *ngIf="tabIcon.outerIcon" [ngClass]="getIconClass(tabIcon.outerIcon, tabIcon.outerIconClass, true)"></i>
                <i *ngIf="tabIcon.innerIcon" [ngClass]="getIconClass(tabIcon.innerIcon, tabIcon.innerIconClass, false)"></i>
            </span>
        </span>
    </ng-container>
    <span class="arrowText">{{tab.text}}</span>
</ng-template>

<div class="tabContainer" [ngStyle]="{'border-radius': borderRadius}">
    <ng-container *ngFor="let tab of tabs; let i = index;">
        <div #tabElements class="arrow clickable" [ngStyle]="{ 'padding-right': (i+1 !== tabs.length) ? '5px' : '0'}" [ngClass]="{firstArrow: i === 0}" (click)="setActiveTab(i)">
            <div [ngStyle]="{'border-radius': (roundAllEdges) ? borderRadius : 0}" [ngClass]="getContentClass(tab, i)">
                <ng-container [ngTemplateOutlet]="contentRenderer || defaultContentRenderer" [ngTemplateOutletContext]="{$implicit: i, tab: tab}"></ng-container>
            </div>
        </div>
    </ng-container>
</div>
<div *ngIf="useDownArrow" style="max-height: 0; position: relative; margin-top: -1px;">
    <div #downPointer class="pointerDown" style="position: absolute;" [style.left.px]="pointerLeftPos"></div>
</div>
