import {mapToCanActivate, Routes} from '@angular/router';

import { AdminCustomerListComponent } from './components/admin/admin-customer-list/admin-customer-list.component';
import { AdminCustomerComponent } from './components/admin/admin-customer/admin-customer.component';
import { AdminMarkupListComponent } from './components/admin/admin-markup-list/admin-markup-list.component';
import {AdminMarkupCarriersComponent} from './components/admin/admin-markups/admin-markup-carriers/admin-markup-carriers.component';
import {AdminMarkupsComponent} from './components/admin/admin-markups/admin-markups.component';
import { AdminRoleListComponent } from './components/admin/admin-role-list/admin-role-list.component';
import { AdminRoleComponent } from './components/admin/admin-role/admin-role.component';
import { AdminUserListComponent } from './components/admin/admin-user-list/admin-user-list.component';
import { AdminUserComponent } from './components/admin/admin-user/admin-user.component';
import { AdminComponent } from './components/admin/admin.component';
import { InviteUserComponent } from './components/admin/invite-user/invite-user.component';
import { AuthGuard } from './guards/auth.guard';

export const ADMIN_ROUTES: Routes = [
    { path: 'admin',
        component: AdminComponent,
        canActivate: mapToCanActivate([AuthGuard]),
        children: [
            { path: '', redirectTo: 'roles', pathMatch: 'full' },
            { path: 'customers/:customerId', component: AdminCustomerComponent, canActivate: mapToCanActivate([AuthGuard]) },
            { path: 'customers', component: AdminCustomerListComponent, canActivate: mapToCanActivate([AuthGuard]) },
            { path: 'roles/:roleId', component: AdminRoleComponent, canActivate: mapToCanActivate([AuthGuard]) },
            { path: 'roles/new', component: AdminRoleComponent, canActivate: mapToCanActivate([AuthGuard]) },
            { path: 'roles', component: AdminRoleListComponent, canActivate: mapToCanActivate([AuthGuard]) },
            { path: 'users/:userId', component: AdminUserComponent, canActivate: mapToCanActivate([AuthGuard]) },
            { path: 'inviteUser', component: InviteUserComponent, canActivate: mapToCanActivate([AuthGuard]) },
            { path: 'users', component: AdminUserListComponent, canActivate: mapToCanActivate([AuthGuard]) },
            { path: 'markups/:markupId', component: AdminMarkupsComponent, canActivate: mapToCanActivate([AuthGuard]) },
            { path: 'markups/carrier/:markupId/:exceptionIndex', component: AdminMarkupCarriersComponent, canActivate: mapToCanActivate([AuthGuard]) },
            { path: 'markups/accl/:markupId/:exceptionIndex', component: AdminMarkupCarriersComponent, canActivate: mapToCanActivate([AuthGuard]) },
            { path: 'markups', component: AdminMarkupListComponent, canActivate: mapToCanActivate([AuthGuard]) },
        ]
    },
    { path: 'userprofile', component: AdminUserComponent, pathMatch: 'full', canActivate: mapToCanActivate([AuthGuard]) },
];
