export class Shipment {
    acContact: string = '';
    acEmail: string = '';
    acFax: string = '';
    acPhone: string = '';
    acPro_Number: string = '';
    actual_carrier_name: string = '';
    actual_carrier: number = 0;
    actualDeliveryDate: string = '';
    actualPickupDate: string = '';
    billRec: string = '';
    billRecBy: string = '';
    billReq: string = '';
    billReqBy: string = '';
    BillTo: number = 0;
    billtoAddress1: string = '';
    billtoAddress2: string = '';
    billtoCity: string = '';
    billtoName: string = '';
    billtoName2: string = '';
    billtoState: string = '';
    billtoZip: string = '';
    BOL: string = '';
    BOLEmail: string = '';
    BOLName: string = '';
    BOLPhone: number = 0;
    cancel_batch: string = '';
    cancel_confirm: string = '';
    Carrier_Name: string = '';
    Carrier: string = '';
    carrierLogoURL: string;
    carrierQuoteNo: string = '';
    COD: number = 0;
    Comments: string = '';
    consignee_address: string = '';
    consignee_address2: string = '';
    consignee_city: string = '';
    consignee_contact_email: string = '';
    consignee_contact_fax: string = '';
    consignee_contact_name: string = '';
    consignee_contact_phone: string = '';
    consignee_country: string = '';
    consignee_hours: string = '';
    Consignee_Name: string = '';
    consignee_name2: string = '';
    consignee_state: string = '';
    consignee_zip: string = '';
    Consignee: number = 0;
    consigneeCallAppt: number = 0;
    consigneeCommercialResidential: string = '';
    consigneeHoursClose: string = '';
    consigneeHoursOpen: string = '';
    consigneeTimezone: string = '';
    Container_No: string = '';
    created: string = '';
    currency: string = '';
    Customer: string = '';
    d_appt_date: string = '';
    d_appt_time: string = '';
    d_appt_tz: string = '';
    Delivery_Date: string = '';
    dest_terminal: string = '';
    direction: string = '';
    dispatcher: number = 0;
    displayStatus: string = '';
    dterm800: string = '';
    dtermAddress: string = '';
    dtermAddress2: string = '';
    dtermCity: string = '';
    dtermFax: string = '';
    dtermName: string = '';
    dtermPhone: string = '';
    dtermState: string = '';
    dtermZip: string = '';
    edi_batch: string = '';
    edi_confirm: string = '';
    emergency_contact: string = '';
    emergency_fax: string = '';
    emergency_phone: string = '';
    equipmentType: number = 0;
    estimatedTransitTime: number = 0;
    fedexDirectSignatureRequired: number = 0;
    fedexDropOffType: string = '';
    fedexEmailNotification: number = 0;
    fedexEmailNotificationAddress: string = '';
    forwarder_ref: string = '';
    FSC_buy: number = 0;
    FSC: number = 0;
    GL_Code: number = 0;
    GL_Code2: string = '';
    guarantee: number = 0;
    ID: number = 0;
    IT_No: string = '';
    lastnote: string = '';
    loadNumber: number = 0;
    location_code: string = '';
    lockAmounts: number = 0;
    matchedEDI: string = '';
    matchedToEDI: number = 0;
    MBOL: string = '';
    miles: number = 0;
    Net_amt: number = 0;
    Net_FSC: number = 0;
    orig_terminal: string = '';
    oterm800: string = '';
    otermAddress: string = '';
    otermAddress2: string = '';
    otermCity: string = '';
    otermFax: string = '';
    otermName: string = '';
    otermPhone: string = '';
    otermState: string = '';
    otermZip: string = '';
    OutsideID: string = '';
    p_appt_date: string = '';
    p_appt_time: string = '';
    p_appt_tz: string = '';
    Payment_Term: string = '';
    Pickup_Date: string = '';
    Pickup_Time: string = '';
    Pickup_Timezone: string = '';
    Pro_Number: string = '';
    problem: number = 0;
    PU_No: string = '';
    quoteId: number = 0;
    quoteNumber: string = '';
    rated_amt: number = 0;
    rated_buy: number = 0;
    ratedClass: number = 0;
    ReferenceNumber: string = '';
    scheduled: string = '';
    sealNumber: string = '';
    service_carrier_code: string = '';
    service_name: string = '';
    Service: number = 0;
    shipper_address: string = '';
    shipper_address2: string = '';
    shipper_city: string = '';
    shipper_contact_email: string = '';
    shipper_contact_fax: string = '';
    shipper_contact_name: string = '';
    shipper_contact_phone: string = '';
    shipper_country: string = '';
    shipper_hours: string = '';
    Shipper_Name: string = '';
    shipper_name2: string = '';
    shipper_ref: string = '';
    shipper_state: string = '';
    shipper_zip: string = '';
    Shipper: number = 0;
    shipperCallAppt: number = 0;
    shipperCommercialResidential: string = '';
    shipperHoursClose: string = '';
    shipperHoursOpen: string = '';
    shipperTimezone: string = '';
    signature: string = '';
    status: number = 0;
    StatusName: string = '';
    submitted: string = '';
    Third_Party: number = 0;
    trailerNo: string = '';
    unitmeasurement: string = '';
    User: number = 0;
}
