<form *ngIf="quickQuoteForm" class="qqBackground" [formGroup]="quickQuoteForm" (keyup)="checkForEnter($event)">

    <div class="qqPage">
        <div class="qqHeader">
            <div>
                <span>Quote Name</span>
                <input tabindex="-1" formControlName="name" />
            </div>
            <div class="qqButtonPanel">
                <app-button (click)="getQuote()" [inactive]="disableBookingOptions" >$ GET A QUOTE</app-button>
                <app-button (click)="clearQuote()" [buttonColor]="'red'">CLEAR QUOTE</app-button>
            </div>
        </div>
        <div class="qqBody">
            <div class="qqInfoPane">
                <div>
                    <span class="required">*</span>Origin<br />
                </div>
                <div class="qqZipPane">
                    <app-zip-search (busy)="zipOriginBusy = $event;" (dialogShown)="dialogUp = $event" class="qqElement" [countryForSearch]="(originCountry) ? originCountry.countryCode : undefined" formControlName="origin" modalTitle="Origin Zip Code Search"></app-zip-search>
                    <app-list [tabindex]="-1" class="qqElement countryDropDown" [list]="countries" [getLabel]="getCountryLabel" [getKey]="getCountryLabel" formControlName="originCountry"></app-list>
                </div>
                <br />
                <div>
                    <span class="required">*</span>Destination<br />
                </div>
                <div class="qqZipPane">
                    <app-zip-search (busy)="zipDestinationBusy = $event;" (dialogShown)="dialogUp = $event" class="qqElement" [countryForSearch]="(destinationCountry) ? destinationCountry.countryCode : undefined" formControlName="destination" modalTitle="Destination Zip Code Search"></app-zip-search>
                    <app-list [tabindex]="-1" class="qqElement countryDropDown" [list]="countries" [getLabel]="getCountryLabel" [getKey]="getCountryLabel" formControlName="destinationCountry"></app-list>
                </div>
            </div>
            <div class="qqMidPane">
                <div>
                    <div>
                        <span class="required">*</span>Ship Date
                    </div>
                    <div>
                        <app-date (dialogShown)="dialogUp = $event" [tabindex]="-1" class="qqElement" title="Ship Date" formControlName="shipDate" [displayDateFormat]="displayDateFormat" [allowIndefinately]="false" [displayDateValidation]="displayDateValidation"></app-date>
                    </div>
                    <br />
                    <app-checkbox [tabindex]="-1" formControlName="tsaRequired" label="TSA"></app-checkbox>
                    <br />
                    <div>
                        Equipment Type
                    </div>
                    <app-list [tabindex]="-1" class="qqElement" [list]="equipmentTypes" formControlName="equipmentType"></app-list>
                </div>
            </div>
            <div class="qqAccessorialPane">
                <app-accessorials (dialogShown)="dialogUp = $event" [tabindex]="-1" style="height: 100%" formControlName="accessorials" [groups]="acclGroups"></app-accessorials>
            </div>
        </div>
        <div class="qqLines">
            <app-product-lines (dialogShown)="dialogUp = $event" (unitOfMeasureChanged)="uomChanged($event)" [unitOfMeasure]="unitOfMeasure" formControlName="lines"></app-product-lines>
        </div>
    </div>
    <app-be-advised></app-be-advised>
</form>


<app-ok-cancel-modal *ngIf="isOkCancelModalVisible" [title]="'Clear Quote'" [message]="'You will lose all changes if your proceed.  Click Cancel to prevent this.'"
                     (onOk)="clearQuote(false)" (onCancel)="isOkCancelModalVisible = false"></app-ok-cancel-modal>

