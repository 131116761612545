import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-cube-calculator',
    styleUrls: ['./app-cube-calculator.component.scss'],
    templateUrl: './app-cube-calculator.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppCubeCalculatorComponent,
            multi: true
        }
    ]
})
export class AppCubeCalculatorComponent implements ControlValueAccessor {
    @Input()
    public piecesType;
    @Input()
    public closeButtonName = 'Close';
    @Input()
    // eslint-disable-next-line @typescript-eslint/ban-types
    public getCubeInfoFn: Function;
    @Output()
    public dialogShown = new EventEmitter();

    public isCubeCalculatorShown = false;
    public value: any = {};
    public prevFocus;

    constructor() {}

    public writeValue(value) {
        this.value = value;
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public showCubeCalculator() {
        this.isCubeCalculatorShown = true;
        this.dialogShown.emit(true);
    }

    public hideCubeCalculator($event) {
        this.isCubeCalculatorShown = false;
        this.dialogShown.emit(false);
        if ($event !== null) {
            this.value = $event;
            this.valueChanged(this.value);
        }
    }

    private valueChanged = (_: any) => {};
}
