<input class="productEntry" [required]="isRequired"
       (ngModelChange)="productSearch($event)"
       (blur)="clearLastSearch()" autocomplete="off" [ngModel]="inputValue" #productEntryElement #inputModel="ngModel" />

<span class="showProductDetailsIcon clickable blue" (click)="showProductDetailsModal(true)">
    <i class="fa fa-file-text" *ngIf="value && (value.Description || value.description2 || value.nmfc || value.Hazmat || value.Hazmat_number)"></i>
</span>

<div *ngIf="showProductPopup" class="matchedProductListContainer">
    <span class="matchedProductItem clickable" *ngFor="let product of products | slice:0:10"
          (click)="updateProduct(product, productEntryElement)">
        {{ product.name }}
    </span>
    <span class="matchedProductItem borderB clickable" *ngIf="products && products.length >= 10"
          (click)="showProductSearchModal()">
        See More...
    </span>
    <span class="matchedProductItem borderB clickable noResultsFoundMsg" *ngIf="products && !products.length">
        No products were found
    </span>
    <span class="matchedProductItem clickable" *ngIf="showNewProductsLink" (click)="showAddNewProductModal()">
        <i class="fa fa-plus-circle blue" aria-hidden="true"></i>
        Add a New Product: {{ inputValue }}
    </span>
</div>

<app-product-search-modal *ngIf="showSearchModal" (passProductToRecordCreation)="updateProduct($event, productEntryElement)"
                          [passedSearchQuery]="inputValue"></app-product-search-modal>
<app-product-details-modal *ngIf="showDetailsModal" [selectedProduct]="detailsToShow"
                           (closeProductDetailsModal)="showProductDetailsModal(false, productEntryElement);"></app-product-details-modal>
