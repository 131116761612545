import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-number-modal',
    templateUrl: './number-modal.component.html',
    styleUrls: ['./number-modal.component.scss']
})

export class NumberModalComponent implements OnInit {
    @Input() public title: string;
    @Input() public message: string;
    @Input() public additionalMessage: string;
    @Input() public payload: any;
    @Input() public prop: string;
    @Input() public okayBtnText: string = 'Okay';
    @Input() public cancelBtnText: string = 'Cancel';
    @Input() public suppressCancelButton: boolean;
    @Input() public negativeAllowed = false;
    @Input() public zeroAllowed = false;
    @Output() public onOk = new EventEmitter();
    @Output() public onCancel = new EventEmitter();

    public isDirty = false;
    public errorMessage = '';
    public value;

    constructor() { }

    ngOnInit() {
        if (this.payload) {
            this.value = (this.prop) ? this.payload[this.prop] : this.payload;
        }
    }

    public validate() {
        this.isDirty = true;
        const parsedInt = parseInt(this.value, 10);

        if (isNaN(parsedInt)) {
            this.errorMessage = 'Invalid numeric value.';
        } else if (!this.zeroAllowed && (parsedInt === 0)) {
            this.errorMessage = 'Zero value not allowed.';
        } else if (!this.negativeAllowed && (parsedInt < 0)) {
            this.errorMessage = 'Negative values not allowed.';
        } else {
            this.errorMessage = '';
        }
    }

    public ok() {
        this.validate();
        if (!this.errorMessage) {
            if (this.prop) {
                this.payload[this.prop] = this.value;
            } else {
                this.payload = this.value;
            }
            this.onOk.emit(this.payload);
        }
    }

    public cancel() {
        this.onCancel.emit(this.payload);
    }
}
