<div class="overlay">
    <div class="productDetailsModal">

        <!-- ============================== MODAL HEADER ============================== -->
        <div class="productDetailsModalHeader">
            <span class="modalHeaderText">
                {{ selectedProduct?.name | titlecase}} Product Details
            </span>

            <span class="modalHeaderIcon clickable" (click)="closeModal()">
                <i class="fa fa-times"></i>
            </span>
        </div>

        <!-- ============================== MODAL BODY ============================== -->
        <div class="productDetailsModalBody">
            <div class="productDetailRow" *ngIf="selectedProduct.description">
                <span class="productDetailRowLabel">
                    Product Description
                </span>
                <div class="productDetailRowValue">
                    {{ selectedProduct?.description }}
                </div>
            </div>

            <div class="productDetailRow" *ngIf="selectedProduct.description2">
                <span class="productDetailRowLabel">
                    Description 2
                </span>
                <div class="productDetailRowValue">
                    {{ selectedProduct?.description2 }}
                </div>
            </div>

            <div class="productDetailRow" *ngIf="selectedProduct.nmfc">
                <span class="productDetailRowLabel">
                    NMFC
                </span>
                <div class="productDetailRowValue">
                    {{ selectedProduct?.nmfc }}
                </div>
            </div>


            <div class="productDetailRow">
                <span class="productDetailRowLabel">
                    Hazmat
                </span>
                <div class="productDetailRowValue">
                    <span *ngIf="selectedProduct.hazmat">
                        Yes
                    </span>

                    <span *ngIf="!selectedProduct.hazmat">
                        No
                    </span>
                </div>
            </div>

            <div class="productDetailRow" *ngIf="selectedProduct.hazmat">
                <span class="productDetailRowLabel">
                    Hazmat #
                </span>
                <div class="productDetailRowValue">
                    {{ selectedProduct?.hazmat_number }}
                </div>
            </div>

            <div class="productDetailRow" *ngIf="selectedProduct.hazmat">
                <span class="productDetailRowLabel">
                    Hazmat Class
                </span>
                <div class="productDetailRowValue">
                    {{ selectedProduct?.hazmat_class }}
                </div>
            </div>

            <div class="productDetailRow" *ngIf="selectedProduct.hazmat">
                <span class="productDetailRowLabel">
                    Hazmat Sub-Class
                </span>
                <div class="productDetailRowValue">
                    {{ selectedProduct?.hazmat_subClass }}
                </div>
            </div>

            <div class="productDetailRow" *ngIf="selectedProduct.hazmat">
                <span class="productDetailRowLabel">
                    Hazmat Packing Group
                </span>
                <div class="productDetailRowValue">
                    {{ selectedProduct?.hazmat_packingGroup }}
                </div>
            </div>

        </div>
    </div>
</div>
