<div class="roleTabs">

    <div class="roleTabSectionContainer" [ngClass]="{'roleTabSectionContainerReadOnly' : isAdminExternal}">
        <div class="roleTabSection generalTab clickable" (click)="setActiveRoleTabSection(roleTabSections.general)" [ngClass]="{ 'currentTab': activeRoleTabSection === roleTabSections.general }">
            <span class="roleTabSectionIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x generalOuterIcon currentOuterIcon"></i>
                <i class="fa fa-info fa-stack-1x generalInnerIcon"></i>
            </span>
            <span class="roleTabSectionText">
                General
            </span>
        </div>

        <div class="roleTabPointerDown" *ngIf="activeRoleTabSection === roleTabSections.general"></div>
    </div>

    <div class="roleTabSectionContainer" *ngIf="isAdminInternal">
        <div class="roleTabSection clickable permissionsTab clickable" (click)="setActiveRoleTabSection(roleTabSections.permissions)"
            [ngClass]="{ 'currentTab': activeRoleTabSection === roleTabSections.permissions }">
            <span class="roleTabSectionIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x permissionsOuterIcon currentOuterIcon"></i>
                <i class="fa fa-key fa-stack-1x permissionsInnerIcon"></i>
            </span>
            <span class="roleTabSectionText">
                Permissions
            </span>
        </div>

        <div class="roleTabPointerDown" *ngIf="activeRoleTabSection === roleTabSections.permissions"></div>
    </div>

    <div class="roleTabSectionContainer" *ngIf="isAdminInternal">
        <div class="roleTabSection clickable quotingTab" (click)="setActiveRoleTabSection(roleTabSections.quoting)" [ngClass]="{ 'currentTab': activeRoleTabSection === roleTabSections.quoting }">
            <span class="roleTabSectionIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x quotingOuterIcon currentOuterIcon"></i>
                <i class="fa fa-dollar fa-stack-1x quotingInnerIcon"></i>
            </span>
            <span class="roleTabSectionText">
                Quoting
            </span>
        </div>

        <div class="roleTabPointerDown" *ngIf="activeRoleTabSection === roleTabSections.quoting"></div>
    </div>

    <div class="roleTabSectionContainer" *ngIf="isAdminInternal">
        <div class="roleTabSection clickable shipmentsTab" (click)="setActiveRoleTabSection(roleTabSections.shipments)" [ngClass]="{ 'currentTab': activeRoleTabSection === roleTabSections.shipments }">
            <span class="roleTabSectionIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x shipmentsOuterIcon currentOuterIcon"></i>
                <i class="fa fa-truck fa-stack-1x shipmentsInnerIcon"></i>
            </span>
            <span class="roleTabSectionText">
                Shipments
            </span>
        </div>

        <div class="roleTabPointerDown" *ngIf="activeRoleTabSection === roleTabSections.shipments"></div>
    </div>

    <div class="roleTabSectionContainer" [ngClass]="{'roleTabSectionContainerReadOnly' : isAdminExternal}">
        <div class="roleTabSection clickable masterDataDefaultsTab" (click)="setActiveRoleTabSection(roleTabSections.defaultsAndDisclaimer)"
            [ngClass]="{ 'currentTab': activeRoleTabSection === roleTabSections.defaultsAndDisclaimer }">
            <span class="roleTabSectionIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x masterDataDefaultsOuterIcon currentOuterIcon"></i>
                <i class="fa fa-book fa-stack-1x masterDataDefaultsInnerIcon"></i>
            </span>
            <span class="roleTabSectionText">
                Defaults &amp; Disclaimer
            </span>
        </div>

        <div class="roleTabPointerDown" *ngIf="activeRoleTabSection === roleTabSections.defaultsAndDisclaimer"></div>
    </div>


</div>