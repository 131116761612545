import {ApiService} from './services/api.service';
import {AuthService} from './services/auth.service';
import {CommonDataService} from './services/commonData.service';
import {ContactService} from './services/contact.service';
import {CustomerService} from './services/customer.service';
import {FilesService} from './services/files.service';
import {InsuranceService} from './services/insurance.service';
import {LoggingService} from './services/logging.service';
import {MarkupsService} from './services/markups.service';
import {NmfcSearchService} from './services/nmfc-search.service';
import {PasswordService} from './services/password.service';
import {PreviousRouteService} from './services/previous-route.service';
import {ProductsService} from './services/products.service';
import {QuickQuoteService} from './services/quickQuote.service';
import {RoleService} from './services/role.service';
import {ShipmentService} from './services/shipment.service';
import {ThinkOwlChatService} from './services/thinkOwlChat.service';
import {TourService} from './services/tour.service';
import {UserService} from './services/user.service';
import {LogoutService} from "./services/logout.service";
import {BreadcrumbService} from "./services/breadcrumb.service";
import {AdminButtonPanelService} from "./services/adminButtonPanel.service";
import {LoginService} from "./services/login.service";
import {SignalsService} from "./services/signals.service";
import {ClipboardService} from "./services/clipboard.service";
import {SearchService} from "./services/search.service";
import {FavoritesService} from "./services/favorites.service";
import {TemplateService} from "./services/template.service";
import {CarriersMasterDataService} from "./services/carriers.masterdata.service";
import {ContactsMasterDataService} from "./services/contacts.masterdata.service";
import {EntityService} from "./services/entity.service";
import {ReportingService} from "./services/reporting.service";
import {NotificationService} from "./services/notification.service";
import {InvoiceService} from "./services/invoice.service";
import {DashboardService} from "./services/dashboard.service";
import {AppStateService} from "./services/app-state.service";

export const APP_SERVICES = [
    AppStateService,
    ApiService,
    AuthService,
    LoggingService,
    PreviousRouteService,
    MarkupsService,
    CustomerService,
    RoleService,
    UserService,
    CommonDataService,
    ContactService,
    QuickQuoteService,
    TourService,
    InsuranceService,
    ShipmentService,
    FilesService,
    ThinkOwlChatService,
    PasswordService,
    ProductsService,
    NmfcSearchService,
    LogoutService,
    BreadcrumbService,
    AdminButtonPanelService,
    LoginService,
    SignalsService,
    ClipboardService,
    SearchService,
    FavoritesService,
    TemplateService,
    CarriersMasterDataService,
    ContactsMasterDataService,
    EntityService,
    ReportingService,
    NotificationService,
    InvoiceService,
    DashboardService,
];
