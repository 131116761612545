import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-shortcuts',
  styleUrls: ['./my-shortcuts.component.scss'],
  templateUrl: './my-shortcuts.component.html'

})
export class MyShortcutsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
