<div class="overlay">
    <div class="datePicker">
        <!--========================================= MODAL HEADER =========================================================-->
        <div class="datePickerHeader">
            <span class="datePickerHeaderText">{{ title }}</span>
            <span class="datePickerHeaderIcon clickable" (click)="closeDatePickerModal()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>

        <!--========================================= MODAL BODY =========================================================-->
        <div class="datePickerBody">
            <!--=========================== DATE RANGE OPTION/RADIOS OF MODAL BODY ====================================-->
            <div class="dateRangePanel" *ngIf="isDateRangePossible && !isDateRangeOnly">
                <div class="selectDatePanel">
                    <span class="dateRangePanelRadio blue clickable" *ngIf="!isDateRangeSelected">
                        <i class="fa fa-dot-circle-o"></i>
                    </span>
                    <span class="dateRangePanelRadio blue clickable" *ngIf="isDateRangeSelected"
                          (click)="toggleSingleOrRangedDateSelection()">
                        <i class="fa fa-circle-o"></i>
                    </span>

                    <span class="dateRangePanelLabel">
                        Select a Date
                    </span>
                </div>

                <div class="selectDateRangePanel">
                <span class="dateRangePanelRadio blue clickable" *ngIf="isDateRangeSelected">
                    <i class="fa fa-dot-circle-o"></i>
                </span>
                    <span class="dateRangePanelRadio blue clickable" *ngIf="!isDateRangeSelected"
                          (click)="toggleSingleOrRangedDateSelection()">
                        <i class="fa fa-circle-o"></i>
                    </span>

                    <span class="dateRangePanelLabel">
                        Select a Date Range
                    </span>
                </div>
            </div>

            <!--=========================== SINGLE DATE FORM ====================================-->
            <form [formGroup]="dateForm" class="datePickerInputHolder" (ngSubmit)="setNewDateFromInputField()"
                  *ngIf="!this.isDateRangeSelected">
                <button type="submit" name="formSubmission" class="btn-hidden-IE"></button>
                <div class="datePickerInputPanel">
                    <div class="datePickerInputLabel">
                        <span class="required">*</span>&nbsp;Date
                    </div>
                    <input type="text" class="datePickerInput" tabindex="1" formControlName="selectedDate"
                           [ngClass]="{ 'invalidDateFormInput': (dateForm['controls'].selectedDate.dirty || dateForm['controls'].selectedDate.touched) && dateForm['controls'].selectedDate.invalid }" #dateField />
                </div>
                <div *ngIf="this.allowSingleIndefinately" class="indefinitelyRow">
                    <div class="indefinitelyPanel">
                        <span class="indefinitelyCheckbox clickable" (click)="toggleIndefinitelyCheckbox()">
                            <i class="fa fa-square-o" *ngIf="dateForm['controls'].selectedDate.value.toLowerCase() !== 'indefinitely'"></i>
                            <i class="fa fa-check-square" *ngIf="dateForm['controls'].selectedDate.value.toLowerCase() === 'indefinitely'"></i>
                        </span>

                        <span class="indefinitelyText">Indefinitely</span>
                    </div>
                </div>
            </form>

            <!--=========================== DATE RANGE FORM ====================================-->
            <form [formGroup]="dateForm" class="dateRangeForm" (ngSubmit)="setNewDateFromRangedInputField()"
                  *ngIf="this.isDateRangeSelected">
                <button type="submit" name="formSubmission" class="btn-hidden-IE"></button>

                <div class="datePickerRangeInputHolder">
                    <div class="datePickerInputPanel">
                        <div class="datePickerInputLabel">
                            <span class="required">*</span>&nbsp;Start
                        </div>
                        <input type="text" class="datePickerInput" tabindex="1" formControlName="selectedDate"
                               [ngClass]="{ 'invalidDateFormInput': (dateForm['controls'].selectedDate.dirty || dateForm['controls'].selectedDate.touched) &&
                               dateForm['controls'].selectedDate.invalid, 'activeDateFormInput': activeDateRangeInputFormControl === dateForm['controls'].selectedDate }"
                               (focus)="setActiveDateRangeInputFormControl(dateForm['controls'].selectedDate)" #rangeDateField />
                    </div>

                    <div class="datePickerInputPanel">
                        <div class="datePickerInputLabel">
                            <span class="required">*</span>&nbsp;End
                        </div>
                        <input type="text" class="datePickerInput" tabindex="2" formControlName="selectedEndDate"
                               [ngClass]="{ 'invalidDateFormInput': (dateForm['controls'].selectedEndDate.dirty || dateForm['controls'].selectedEndDate.touched) &&
                               dateForm['controls'].selectedEndDate.invalid, 'activeDateFormInput': activeDateRangeInputFormControl === dateForm['controls'].selectedEndDate }"
                               (focus)="setActiveDateRangeInputFormControl(dateForm['controls'].selectedEndDate)"/>
                    </div>
                </div>

                <div class="indefinitelyRow">
                    <div class="indefinitelyPanel">
                        <span class="indefinitelyCheckbox clickable" (click)="toggleIndefinitelyCheckbox()">
                            <i class="fa fa-square-o" *ngIf="dateForm['controls'].selectedEndDate.value.toLowerCase() !== 'indefinitely'"></i>
                            <i class="fa fa-check-square" *ngIf="dateForm['controls'].selectedEndDate.value.toLowerCase() === 'indefinitely'"></i>
                        </span>

                        <span class="indefinitelyText">Indefinitely</span>
                    </div>
                </div>
            </form>

            <!--calender display => currentMonthDisplay + calendarHeader + all the weeks-->
            <!--=========================== calendar ====================================-->
            <div class="calendarBody bottomBorderShadow" [ngClass]="{
                'indefinitelyCalendarBody': dateForm && dateForm['controls'].selectedEndDate && dateForm['controls'].selectedEndDate.value.toLowerCase() === 'indefinitely' &&
                activeDateRangeInputFormControl === dateForm['controls'].selectedEndDate}">

                <!--=========================== current month ===============================-->
                <div class="currentMonthDisplay">
                    <span class="changeMonthIcon">
                        <i class="fa fa-chevron-left clickable" aria-hidden="true" (click)="previousMonth()"></i>
                    </span>
                    <span class="currentMonthDisplayText">
                        {{ currentMonth.displayText }}
                    </span>
                    <span class="changeMonthIcon">
                        <i class="fa fa-chevron-right clickable" aria-hidden="true" (click)="nextMonth()"></i>
                    </span>
                </div>

                <!--=========================== weekday headers ===============================-->
                <div class="weekdayDisplayRow">
                    <div class="weekdayDisplayCell" *ngFor="let weekday of weekdayList">
                        {{ weekday }}
                    </div>
                </div>

                <!--=========================== actual calendar stuff ===============================-->
                <div class="weekdayDisplayRow" [ngClass]="{'firstWeekDisplayRow': i === 0}"
                     *ngFor="let week of weeksInMonth; let i = index">
                    <div class="calendarWeekdayDisplayCell clickable" *ngFor="let weekday of week"
                         (click)="setNewDateFromCalendar(weekday)"
                         (dblclick)="selectSingleDateOnDoubleClick(weekday)"
                         [ngClass]="{'selectedWeekday': weekday.selected}">
                        {{ weekday.displayText }}
                    </div>
                </div>
            </div>
            <!--datePicker footer (outside of body)-->
            <div class="datePickerFooter">
                <div class="datePickerButtonHolder">
                    <div class="datePickerButton btn-outline clickable" (click)="closeDatePickerModal()">Cancel</div>
                    <div class="datePickerButton btn-outline clickable" (click)="applyDate()">Apply Date</div>
                </div>
            </div>
        </div>
    </div>
</div>
