import {
    AfterViewInit,
    Component, ElementRef, EventEmitter,
    Input,
    OnInit, Output, ViewChild,
} from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import {SetPassword} from '../../models/setPassword';

@Component({
    selector: 'app-password',
    styleUrls: ['./app-password.component.scss'],
    templateUrl: './app-password.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppPasswordComponent,
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: AppPasswordComponent,
            multi: true
        }
    ]
})
export class AppPasswordComponent implements OnInit, ControlValueAccessor, AfterViewInit {
    @ViewChild('focusPoint') focusPoint: ElementRef;

    @Input() public requireOldPassword = true;
    @Input() public noOldPasswordText;
    @Input() public passwordMatchText;
    @Input() public passwordRegexText;
    @Input() public passwordRegex;
    @Output() public getFocusElem = new EventEmitter<ElementRef>();

    public value: SetPassword = new SetPassword();
    public oldPasswordValue = '';
    public newPasswordValue = '';
    public repeatPasswordValue = '';
    public oldPasswordElementType = 'password';
    public newPasswordElementType = 'password';
    public repeatPasswordElementType = 'password';
    public errors = null;

    private control: UntypedFormControl;

    constructor() {}

    public async ngOnInit() {
        if (!this.passwordRegex) {
            this.passwordRegex = '';
        }
        if (!this.passwordRegexText) {
            this.passwordRegexText = '';
        }
        if (!this.noOldPasswordText) {
            this.noOldPasswordText = 'Please provide the old password.';
        }
        if (!this.passwordMatchText) {
            this.passwordMatchText = 'New password and repeat password must match.';
        }
    }

    public ngAfterViewInit(): void {
        this.getFocusElem.emit(this.focusPoint);
    }

    public validate(c: UntypedFormControl) {
        this.errors = null;
        const regEx = new RegExp(this.passwordRegex);
        this.control = c;
        if (!regEx.test(this.newPasswordValue)) {
            this.errors = {
                regularExpressionFail: true
            };
        }
        if (this.newPasswordValue !== this.repeatPasswordValue) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors.passwordsDontMatch = true;
        }
        if (this.requireOldPassword && !this.oldPasswordValue) {
            if (!this.errors) {
                this.errors = {};
            }
            this.errors.noOldPassword = true;
        }

        return this.errors;
    }

    public writeValue(value) {
        if (value) {
            this.oldPasswordValue = value.oldPasswordValue;
            this.newPasswordValue = value.newPasswordValue;
            this.repeatPasswordValue = value.repeatPasswordValue;
            this.value = value;
        }
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public togglePasswordVisibility(passwordElementType: string) {
        this[passwordElementType] = (this[passwordElementType] === 'password') ? 'text' : 'password';
    }

    public doPasswordUpdate(ev, fieldName) {
        this[fieldName] = ev;
        this.value.newPasswordValue = this.newPasswordValue;
        if (this.requireOldPassword) {
            this.value.oldPasswordValue = this.oldPasswordValue;
        }
        this.value.repeatPasswordValue = this.repeatPasswordValue;
        this.valueChanged(this.value);
    }

    private valueChanged = (__: any) => {};
}
