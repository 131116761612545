<div class="collapsedShortcutsFloater" id="collapsedShortcutsFloater" *ngIf="isClipboardFloaterCollapsed"
     (click)="toggleClipboard()"
     [ngClass]="{ 'isNotesExpanded': !isShipmentNotesCollapsed }">
    <div class="toggleClipboardContainer">
        <span class="clipboardIconContainer">
          <img src="../../../assets/images/MyShortcuts_new.svg " class="clipboardIcon">
        </span>
    </div>
</div>

<div class="expandedClipboardShortcuts" *ngIf="!isClipboardFloaterCollapsed">
    <div class="clipboardHeader" id="clipboardHeaderText">
        <div class="clipboardHeaderLabel">My Shortcuts &amp; Clipboard</div>
        <div class="compressIconContainer">
            <span *ngIf="isCreateIconShown" class="createIcon clickable" (click)="createNote()">
                <i class="fa fa-plus-circle"></i>
            </span>
            <span (click)="toggleClipboard()">
                <img src="../../../assets/images/Compress_Icon.svg " class="compressIcon clickable">
            </span>
            <span class="pinIcon clickable " *ngIf="!isClipboardPinned" (click)="togglePinnedState()">
                <i class="fa fa-lock pinFont"></i>
            </span>
            <span class="pinIcon" *ngIf="isClipboardPinned" (click)="togglePinnedState()">
                <i class="fa fa-unlock pinFont"></i>
            </span>
        </div>
    </div>
    <div class="categoryHeader">
        <div class="categoryHeaderSection clickable" id="categoryHeaderSection-favorites" (click)="showFavorites()"
             [ngClass]="{ 'categoryHeaderSection-selected': isFavoritesShown }">Favorites
        </div>
        <div class="categoryHeaderSection clickable" (click)="showTemplates()"
             [ngClass]="{ 'categoryHeaderSection-selected': isTemplatesShown }">Templates
        </div>
        <div class="categoryHeaderSection clickable" (click)="showNotes()"
             [ngClass]="{ 'categoryHeaderSection-selected': isNotesShown }">Clips
        </div>
    </div>
    <!-- show Favorites -->
    <div *ngIf="isFavoritesShown" class="clipboardItemsHolder scrollbar">
        <div class="clipboardSubHeader">
            <div class="sectionSubHeaderName" *ngIf="favorites.length">Name</div>
            <div class="sectionSubHeaderDate" *ngIf="favorites.length">Date</div>
        </div>
        <div class="favoritesClipboardItem" *ngFor="let fave of favorites">
            <i (click)="removeSelectedFavorite($event, fave)" class="clickable yellow fa fa-star"></i>
            <div class="clipboardNoteName clickable" (click)="navigateToRecord(fave)">{{fave?.favoriteName}}</div>
            <div class="clipboardNoteDate">{{ getClipboardDisplayDate(fave.changeDate) }}</div>
        </div>
    </div>

    <!-- show Templates -->
    <div *ngIf="isTemplatesShown && SHOWTEMPLATES" class="clipboardItemsHolder scrollbar">
        <div class="clipboardSubHeader">
            <div class="templateHeaderName" *ngIf="templates.length">Name</div>
            <div class="templateHeaderType" *ngIf="templates.length">Type</div>
            <div class="templateHeaderDate" *ngIf="templates.length">Date</div>
        </div>
        <div class="templatesClipboardItem clickable" *ngFor="let t of templates"
             (click)="templateSelected(t.templateID)">
            <div class="templateName">{{t.templateName}}</div>
            <ng-container [ngSwitch]="t.templateType">
                <div class="templateType " *ngSwitchCase="MDConstants.TEMPLATE_TYPES_quote">Quote</div>
                <div class="templateType" *ngSwitchCase="MDConstants.TEMPLATE_TYPES_role">Role</div>
                <div class="templateType" *ngSwitchCase="MDConstants.TEMPLATE_TYPES_shipment">Shipment</div>
            </ng-container>
            <div class="templateDate">{{ getClipboardDisplayDate(t.changeDate) }}</div>
            <div class="deleteTemplate" (click)="showTemplateConfirmDeleteModal($event, t.templateID)">
                <i class="fa fa-times blue clickable" aria-hidden="true"></i>
            </div>
        </div>
    </div>

    <!-- show Notes -->
    <div *ngIf="isNotesShown" class="clipboardItemsHolder scrollbar">
        <div class="clipboardSubHeader">
            <div class="clipboardNoteHeaderNote" *ngIf="notes.length">Clips</div>
            <div class="clipboardNoteHeaderDate" *ngIf="notes.length">Date</div>
        </div>

        <div class="clipboardItemsContainerWrapper scrollbar">
            <div class="clipboardItemsContainer">
                <div *ngIf="isCreateNoteInputBoxShown" class="clipboardItem createNoteInputBox">
                    <textarea class="createNoteInput" id="createNoteInput" name="createNoteInput"
                            [(ngModel)]="createNoteInput"
                            autofocus data-html="true">
                    </textarea>
                    <div class="iconCloseContainer">
                        <span class="createIcon blue clickable" (click)="saveNote()">
                          <i class="fa fa-check-circle"></i>
                        </span>
                        <span class="createIcon blue clickable" (click)="cancelNoteCreation()">
                            <i class="fa fa-times-circle"></i>
                        </span>
                    </div>
                </div>

                <div class="notesClipboardItem" *ngFor="let n of notes">
                    <div class="clipboardNote clickable">{{n.clipboard}}</div>
                    <div class="clipboardNoteDate">{{ getClipboardDisplayDate(n.changeDate) }}</div>
                    <span class="createIcon blue clickable" (click)="deleteNote(n.clipboardID)">
                        <i class="fa fa-times-circle"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<app-ok-cancel-modal *ngIf="isConfirmDeleteTemplateModalVisible" [title]="confirmDeleteTemplateTitle"
                     [message]="confirmDeleteTemplateMessage"
                     (onCancel)="hideTemplateConfirmDeleteModal()" (onOk)="deleteTemplate($event)"
                     [okPayload]="selectedTemplateId">
</app-ok-cancel-modal>
