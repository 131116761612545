export class ContactMasterData {
  ID: number;
  contactsId: number;
  Customer: string;
  Active: number;
  Name: string;
  Name2: string;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  Zip: string;
  country: string;
  commercialResidential: string;
  contact_billto: number; // boolean 0 or 1
  contact_consignee: number; // boolean 0 or 1
  contact_shipper: number; // boolean 0 or 1
  contact_thirdparty: number; // boolean 0 or 1
  Contact: string;
  default_contact: number;
  Email: string;
  Fax: string;
  hoursClose: string;
  hoursOpen: string;
  callAppt: number;
  Phone: string;
}
