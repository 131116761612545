<div class="homePanel">

    <div class="searchResultsContainer" *ngIf="searchState.searchRequest && !searchState.filterTerm">
        <div *ngFor="let matchingTypeSet of searchState?.searchResults; let i = index" [ngSwitch]="matchingTypeSet.entityType">
            <div class="recordTypeResultsPanel" *ngSwitchCase="searchEntityTypes.ALL">
                <!--=========================== RESULTS HEADER FOR ALL ===========================-->

                <div class="recordTypeResultsHeader clickable" [ngClass]="{
                        'firstSearchResultTypeSet': i === 0,
                        'secondSearchResultTypeSet': i === 1,
                        'thirdSearchResultTypeSet': i === 2,
                        'fourthSearchResultTypeSet': i === 3,
                        'fifthSearchResultTypeSet': i === 4,
                        'sixthSearchResultTypeSet': i === 5,
                        'seventhSearchResultTypeSet': i === 6,
                        'eighthSearchResultTypeSet': i === 7
                    }" (click)="isExpanded[matchingTypeSet.entityType] = !isExpanded[matchingTypeSet.entityType]">
                    <div class="leftHeaderItems">
                        <div *ngIf="isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <span class="fa-stack searchResultHeaderIcon">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-list fa-stack-1x" aria-hidden="true"></i>
                        </span>
                        <div class="recordTypeLabel">
                            <span>Records ({{ getSearchTotal(matchingTypeSet) }})</span>
                        </div>
                    </div>
                    <div class="rightHeaderItems clickable" (click)="selectFilter(matchingTypeSet.entityType)">
                        <i class="fa fa-filter filterIcon" aria-hidden="true"></i>
                        <p> Filter Results</p>
                    </div>
                </div>

                <!-- ======================================= ACTUAL RESULT BODY ============================================= -->
                <div class="recordTypeResults" *ngIf="isExpanded[matchingTypeSet.entityType]">

                    <!--=========================== RESULTS BODY FOR ALL ===========================-->
                    <div class="recordRowContainer">
                        <div *ngFor="let r of matchingTypeSet.entities | slice:0:5" class="recordRow clickable" (click)="navigateToRecord(r)">
                            <div class="recordCol">
                                <div *ngIf="isFavorite(matchingTypeSet.entityType, r)" (click)="removeFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="yellow fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="!isFavorite(matchingTypeSet.entityType, r)" (click)="addFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="grey fa fa-star-o" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="recordCol">
                                <div class="matchedValue" [innerHtml]="r.bol "></div>
                                <div class="content">Bol Number: {{r.bol}}, Shipper Name: {{r.shipper_name}}, Status: {{r.status}},
                                    Carrier: {{r.carrier_name}}, Purchase Order: {{r.po_number}},
                                    <span *ngIf="isValidDate(r.pickup_date)">
                                        ShipDate: {{r.pickup_date | date:'shortDate' }},
                                    </span>
                                    <span *ngIf="isValidDate(r.delivery_date)">
                                        Delivery Date: {{r.delivery_date | date:'shortDate'}}
                                    </span>
                                </div>
                                <div class="content">{{r.content}}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- ==================================== RESULTS BODY FOOTER ============================================== -->
                <div class="viewMoreRecordsContainer" *ngIf="matchingTypeSet.entities.length >= 5">
                    <span class="blue actionText" (click)="selectFilter(matchingTypeSet.entityType)">View More Records</span>
                </div>
            </div>

            <div class="recordTypeResultsPanel" *ngSwitchCase="searchEntityTypes.QUOTES">
                <!--=========================== RESULTS HEADER FOR QUOTES ===========================-->
                <div class="recordTypeResultsHeader clickable" [ngClass]="{
                        'firstSearchResultTypeSet': i === 0,
                        'secondSearchResultTypeSet': i === 1,
                        'thirdSearchResultTypeSet': i === 2,
                        'fourthSearchResultTypeSet': i === 3,
                        'fifthSearchResultTypeSet': i === 4,
                        'sixthSearchResultTypeSet': i === 5,
                        'seventhSearchResultTypeSet': i === 6,
                        'eighthSearchResultTypeSet': i === 7
                    }" (click)="isExpanded[matchingTypeSet.entityType] = !isExpanded[matchingTypeSet.entityType]">
                    <div class="leftHeaderItems">
                        <div class="globalSearchResultCheckBox">
                            <i class="fa fa-square-o" aria-hidden="true "></i>
                        </div>
                        <div *ngIf="isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <span class="fa-stack searchResultHeaderIcon">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-usd fa-stack-1x" aria-hidden="true"></i>
                        </span>
                        <div class="recordTypeLabel">
                            <span>Quotes ({{ getSearchTotal(matchingTypeSet) }})</span>
                        </div>
                    </div>
                    <div class="rightHeaderItems clickable" (click)="selectFilter(matchingTypeSet.entityType)">
                        <i class="fa fa-filter filterIcon" aria-hidden="true"></i>
                        <p> Filter Results</p>
                    </div>
                </div>

                <!-- ======================================= ACTUAL RESULT BODY ============================================= -->
                <div class="recordTypeResults" *ngIf="isExpanded[matchingTypeSet.entityType]">

                    <!--=========================== RESULTS BODY FOR QUOTES ===========================-->
                    <div class="recordRowContainer">
                        <div *ngFor="let r of matchingTypeSet.entities | slice:0:5" class="recordRow clickable" (click)="navigateToQuoteRecord(r)">
                            <div class="recordCol">
                                <div *ngIf="isFavorite(matchingTypeSet.entityType, r)" (click)="removeFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="yellow fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="!isFavorite(matchingTypeSet.entityType, r)" (click)="addFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="grey fa fa-star-o" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="recordCol">
                                <div class="matchedValue" [innerHtml]="r.ship_id "></div>
                                <div class="content">Amount: {{ r.amount | currency }}, Status: {{r.status}}, Carrier: {{r.carrier}},
                                    SCAC: {{r.scac}},
                                    <span *ngIf="isValidDate(r.shipdate)">
                                        Ship Date: {{r.shipdate | date:'shortDate' }},
                                    </span>
                                    Product: {{r.product}}</div>
                                <div class="content">{{r.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ==================================== RESULTS BODY FOOTER ============================================== -->
                <div class="viewMoreRecordsContainer" *ngIf="matchingTypeSet.entities.length >= 5">
                    <span class="blue actionText" (click)="selectFilter(matchingTypeSet.entityType)">View More Records</span>
                </div>
            </div>

            <div class="recordTypeResultsPanel" *ngSwitchCase="searchEntityTypes.SHIPMENTS">
                <!--=========================== RESULTS HEADER FOR SHIPMENTS ===========================-->
                <div class="recordTypeResultsHeader clickable" [ngClass]="{
                        'firstSearchResultTypeSet': i === 0,
                        'secondSearchResultTypeSet': i === 1,
                        'thirdSearchResultTypeSet': i === 2,
                        'fourthSearchResultTypeSet': i === 3,
                        'fifthSearchResultTypeSet': i === 4,
                        'sixthSearchResultTypeSet': i === 5,
                        'seventhSearchResultTypeSet': i === 6,
                        'eighthSearchResultTypeSet': i === 7
                    }" (click)="isExpanded[matchingTypeSet.entityType] = !isExpanded[matchingTypeSet.entityType]">
                    <div class="leftHeaderItems">
                        <div class="globalSearchResultCheckBox">
                            <i class="fa fa-square-o" aria-hidden="true "></i>
                        </div>
                        <div *ngIf="isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <span class="fa-stack searchResultHeaderIcon">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-truck  fa-stack-1x" aria-hidden="true"></i>
                        </span>
                        <div class="recordTypeLabel">
                            <span>Shipments ({{ getSearchTotal(matchingTypeSet) }})</span>
                        </div>
                    </div>
                    <div class="rightHeaderItems clickable" (click)="selectFilter(matchingTypeSet.entityType)">
                        <i class="fa fa-filter filterIcon" aria-hidden="true"></i>
                        <p> Filter Results</p>
                    </div>
                </div>

                <!-- ======================================= ACTUAL RESULT BODY ============================================= -->
                <div class="recordTypeResults" *ngIf="isExpanded[matchingTypeSet.entityType]">

                    <!--=========================== RESULTS BODY FOR SHIPMENT ===========================-->
                    <div class="recordRowContainer">
                        <div *ngFor="let r of matchingTypeSet.entities | slice:0:5" class="recordRow clickable" (click)="navigateToShipmentRecord(r)">
                            <div class="recordCol">
                                <div *ngIf="isFavorite(matchingTypeSet.entityType, r)" (click)="removeFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="yellow fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="!isFavorite(matchingTypeSet.entityType, r)" (click)="addFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="grey fa fa-star-o" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="recordCol">
                                <div class="matchedValue" [innerHtml]="r.bol "></div>
                                <div class="content">Bol Number: {{r.bol}}, Shipper Name: {{r.shipper_name}}, Status: {{r.status}},
                                    Carrier: {{r.carrier}}, Purchase Order: {{r.po_number}},
                                    <span *ngIf="isValidDate(r.pickup_date)">
                                        ShipDate: {{r.pickup_date | date:'shortDate' }},
                                    </span>
                                    <span *ngIf="isValidDate(r.delivery_date)">
                                        Delivery Date: {{r.delivery_date | date:'shortDate' }}
                                    </span>
                                </div>
                                <div class="content">{{r.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ==================================== RESULTS BODY FOOTER ============================================== -->
                <div class="viewMoreRecordsContainer" *ngIf="matchingTypeSet.entities.length >= 5">
                    <span class="blue actionText" (click)="selectFilter(matchingTypeSet.entityType)">View More Records</span>
                </div>
            </div>

            <div class="recordTypeResultsPanel" *ngSwitchCase="searchEntityTypes.INVOICES">
                <!--=========================== RESULTS HEADER FOR INVOICES ===========================-->
                <div class="recordTypeResultsHeader clickable" [ngClass]="{
                        'firstSearchResultTypeSet': i === 0,
                        'secondSearchResultTypeSet': i === 1,
                        'thirdSearchResultTypeSet': i === 2,
                        'fourthSearchResultTypeSet': i === 3,
                        'fifthSearchResultTypeSet': i === 4,
                        'sixthSearchResultTypeSet': i === 5,
                        'seventhSearchResultTypeSet': i === 6,
                        'eighthSearchResultTypeSet': i === 7
                    }" (click)="isExpanded[matchingTypeSet.entityType] = !isExpanded[matchingTypeSet.entityType]">
                    <div class="leftHeaderItems">
                        <div class="globalSearchResultCheckBox">
                            <i class="fa fa-square-o" aria-hidden="true "></i>
                        </div>
                        <div *ngIf="isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <span class="fa-stack searchResultHeaderIcon">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-calculator fa-stack-1x" aria-hidden="true"></i>
                        </span>
                        <div class="recordTypeLabel">
                            <span>Invoices ({{ getSearchTotal(matchingTypeSet) }})</span>
                        </div>
                    </div>
                    <div class="rightHeaderItems clickable" (click)="selectFilter(matchingTypeSet.entityType)">
                        <i class="fa fa-filter filterIcon" aria-hidden="true"></i>
                        <p> Filter Results</p>
                    </div>
                </div>

                <div class="recordTypeResults" *ngIf="isExpanded[matchingTypeSet.entityType]">
                    <!--=========================== RESULTS BODY FOR INVOICES ===========================-->
                    <div class="recordRowContainer">
                        <div *ngFor="let r of matchingTypeSet.entities | slice:0:5" class="recordRow clickable" (click)="navigateToInvoiceRecord(r)">
                            <div class="recordCol">
                                <div *ngIf="isFavorite(matchingTypeSet.entityType, r)" (click)="removeFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="yellow fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="!isFavorite(matchingTypeSet.entityType, r)" (click)="addFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="grey fa fa-star-o" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="recordCol">
                                <div class="matchedValue" [innerHtml]="r.shipper "></div>
                                <div class="content">Bol Number: {{r.scac}},
                                    <span *ngIf="isValidDate(r.wk_end_date)">
                                        Invoice Date: {{r.wk_end_date | date:'shortDate'}},
                                    </span>
                                    Carrier: {{r.carrier}}, Consignee: {{r.consignee}},
                                    <span *ngIf="isValidDate(r.ship_date)">
                                        Ship Date: {{r.ship_date | date:'shortDate' }},
                                    </span>
                                    <span *ngIf="isValidDate(r.delv_date)">
                                        Delivery Date: {{r.delv_date | date:'shortDate'}}
                                    </span>
                                </div>
                                <div class="content">{{r.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ==================================== RESULTS BODY FOOTER ============================================== -->
                <div class="viewMoreRecordsContainer" *ngIf="matchingTypeSet.entities.length >= 5">
                    <span class="blue actionText" (click)="selectFilter(matchingTypeSet.entityType)">View More Records</span>
                </div>
            </div>

            <div class="recordTypeResultsPanel" *ngSwitchCase="searchEntityTypes.MASTER_DATA_PRODUCTS">
                <!--=========================== RESULTS HEADER FOR PRODUCTS ===========================-->
                <div class="recordTypeResultsHeader clickable" [ngClass]="{
                        'firstSearchResultTypeSet': i === 0,
                        'secondSearchResultTypeSet': i === 1,
                        'thirdSearchResultTypeSet': i === 2,
                        'fourthSearchResultTypeSet': i === 3,
                        'fifthSearchResultTypeSet': i === 4,
                        'sixthSearchResultTypeSet': i === 5,
                        'seventhSearchResultTypeSet': i === 6,
                        'eighthSearchResultTypeSet': i === 7
                    }" (click)="isExpanded[matchingTypeSet.entityType] = !isExpanded[matchingTypeSet.entityType]">
                    <div class="leftHeaderItems">
                        <div class="globalSearchResultCheckBox">
                            <i class="fa fa-square-o" aria-hidden="true "></i>
                        </div>
                        <div *ngIf="isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <span class="fa-stack searchResultHeaderIcon">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-cart-plus fa-stack-1x" aria-hidden="true"></i>
                        </span>
                        <div class="recordTypeLabel">
                            <span>Products ({{ getSearchTotal(matchingTypeSet) }})</span>
                        </div>
                    </div>
                    <div class="rightHeaderItems clickable" (click)="selectFilter(matchingTypeSet.entityType)">
                        <i class="fa fa-filter filterIcon" aria-hidden="true"></i>
                        <p> Filter Results</p>
                    </div>
                </div>

                <!-- ======================================= ACTUAL RESULT BODY ============================================= -->
                <div class="recordTypeResults" *ngIf="isExpanded[matchingTypeSet.entityType]">

                    <!--=========================== RESULTS BODY FOR PRODUCTS ===========================-->
                    <div class="recordRowContainer">
                        <div *ngFor="let r of matchingTypeSet.entities | slice:0:5" class="recordRow clickable" (click)="navigateToMasterDataProduct(r)">
                            <div class="recordCol">
                                <div *ngIf="isFavorite(matchingTypeSet.entityType, r)" (click)="removeFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="yellow fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="!isFavorite(matchingTypeSet.entityType, r)" (click)="addFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="grey fa fa-star-o" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="recordCol">
                                <div class="matchedValue" [innerHtml]="r.productid"></div>
                                <div class="content">
                                    Name: {{ r.name }}, Description: {{ r.description }}, Class: {{ r.displayclass }}, Hazmat:
                                    <span *ngIf="r.hazmat">Y</span>
                                    <span *ngIf="!r.hazmat">N</span>
                                </div>
                                <div class="content">{{r.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ==================================== RESULTS BODY FOOTER ============================================== -->
                <div class="viewMoreRecordsContainer" *ngIf="matchingTypeSet.entities.length >= 5">
                    <span class="blue actionText" (click)="selectFilter(matchingTypeSet.entityType)">View More Records</span>
                </div>
            </div>

            <div class="recordTypeResultsPanel" *ngSwitchCase="searchEntityTypes.MASTER_DATA_CONTACTS">
                <!--=========================== RESULTS HEADER FOR CONTACTS ===========================-->
                <div class="recordTypeResultsHeader clickable" [ngClass]="{
                        'firstSearchResultTypeSet': i === 0,
                        'secondSearchResultTypeSet': i === 1,
                        'thirdSearchResultTypeSet': i === 2,
                        'fourthSearchResultTypeSet': i === 3,
                        'fifthSearchResultTypeSet': i === 4,
                        'sixthSearchResultTypeSet': i === 5,
                        'seventhSearchResultTypeSet': i === 6,
                        'eighthSearchResultTypeSet': i === 7
                    }" (click)="isExpanded[matchingTypeSet.entityType] = !isExpanded[matchingTypeSet.entityType]">
                    <div class="leftHeaderItems">
                        <div class="globalSearchResultCheckBox">
                            <i class="fa fa-square-o" aria-hidden="true "></i>
                        </div>
                        <div *ngIf="isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <span class="fa-stack searchResultHeaderIcon">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-map-marker fa-stack-1x" aria-hidden="true"></i>
                        </span>
                        <div class="recordTypeLabel">
                            <span>Contacts ({{ getSearchTotal(matchingTypeSet) }})</span>
                        </div>
                    </div>
                    <div class="rightHeaderItems clickable" (click)="selectFilter(matchingTypeSet.entityType)">
                        <i class="fa fa-filter filterIcon" aria-hidden="true"></i>
                        <p> Filter Results</p>
                    </div>
                </div>

                <!-- ======================================= ACTUAL RESULT BODY ============================================= -->
                <div class="recordTypeResults" *ngIf="isExpanded[matchingTypeSet.entityType]">

                    <!--=========================== RESULTS BODY FOR CONTACTS ===========================-->
                    <div class="recordRowContainer">
                        <div *ngFor="let r of matchingTypeSet.entities | slice:0:5" class="recordRow clickable" (click)="navigateToMasterDataContact(r)">
                            <div class="recordCol">
                                <div *ngIf="isFavorite(matchingTypeSet.entityType, r)" (click)="removeFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="yellow fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="!isFavorite(matchingTypeSet.entityType, r)" (click)="addFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="grey fa fa-star-o" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="recordCol">
                                <div class="matchedValue" [innerHtml]="r.name"></div>
                                <div class="content">
                                    Address: {{ r.address1 }}, {{ r.city }}, {{ r.state }}, {{ r.zip }}, Email: {{ r.email }}
                                </div>
                                <div class="content">{{r.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ==================================== RESULTS BODY FOOTER ============================================== -->
                <div class="viewMoreRecordsContainer" *ngIf="matchingTypeSet.entities.length >= 5">
                    <span class="blue actionText" (click)="selectFilter(matchingTypeSet.entityType)">View More Records</span>
                </div>
            </div>

            <div class="recordTypeResultsPanel" *ngSwitchCase="searchEntityTypes.MASTER_DATA_SHIPPER">
                <!--=========================== RESULTS HEADER FOR SHIPPER(s) ===========================-->
                <div class="recordTypeResultsHeader clickable" [ngClass]="{
                        'firstSearchResultTypeSet': i === 0,
                        'secondSearchResultTypeSet': i === 1,
                        'thirdSearchResultTypeSet': i === 2,
                        'fourthSearchResultTypeSet': i === 3,
                        'fifthSearchResultTypeSet': i === 4,
                        'sixthSearchResultTypeSet': i === 5,
                        'seventhSearchResultTypeSet': i === 6,
                        'eighthSearchResultTypeSet': i === 7
                    }" (click)="isExpanded[matchingTypeSet.entityType] = !isExpanded[matchingTypeSet.entityType]">
                    <div class="leftHeaderItems">
                        <div class="globalSearchResultCheckBox">
                            <i class="fa fa-square-o" aria-hidden="true "></i>
                        </div>
                        <div *ngIf="isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <span class="fa-stack searchResultHeaderIcon">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-truck fa-stack-1x" aria-hidden="true"></i>
                        </span>
                        <div class="recordTypeLabel">
                            <span>Shipper ({{ getSearchTotal(matchingTypeSet) }})</span>
                        </div>
                    </div>
                    <div class="rightHeaderItems clickable" (click)="selectFilter(matchingTypeSet.entityType)">
                        <i class="fa fa-filter filterIcon" aria-hidden="true"></i>
                        <p> Filter Results</p>
                    </div>
                </div>

                <!-- ======================================= ACTUAL RESULT BODY ============================================= -->
                <div class="recordTypeResults" *ngIf="isExpanded[matchingTypeSet.entityType]">

                    <!--=========================== RESULTS BODY FOR SHIPPER(s) ===========================-->
                    <div class="recordRowContainer">
                        <div *ngFor="let r of matchingTypeSet.entities | slice:0:5" class="recordRow clickable" (click)="navigateToMasterDataContact(r)">
                            <div class="recordCol">
                                <div *ngIf="isFavorite(matchingTypeSet.entityType, r)" (click)="removeFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="yellow fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="!isFavorite(matchingTypeSet.entityType, r)" (click)="addFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="grey fa fa-star-o" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="recordCol">
                                <div class="matchedValue" [innerHtml]="r.name"></div>
                                <div class="content">
                                    Address: {{ r.address1 }}, {{ r.city }}, {{ r.state }}, {{ r.zip }}, Email: {{ r.email }}
                                </div>
                                <div class="content">{{r.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ==================================== RESULTS BODY FOOTER ============================================== -->
                <div class="viewMoreRecordsContainer" *ngIf="matchingTypeSet.entities.length >= 5">
                    <span class="blue actionText" (click)="selectFilter(matchingTypeSet.entityType)">View More Records</span>
                </div>
            </div>

            <div class="recordTypeResultsPanel" *ngSwitchCase="searchEntityTypes.USERS">
                <!--=========================== RESULTS HEADER FOR USERS ===========================-->
                <div class="recordTypeResultsHeader clickable" [ngClass]="{
                        'firstSearchResultTypeSet': i === 0,
                        'secondSearchResultTypeSet': i === 1,
                        'thirdSearchResultTypeSet': i === 2,
                        'fourthSearchResultTypeSet': i === 3,
                        'fifthSearchResultTypeSet': i === 4,
                        'sixthSearchResultTypeSet': i === 5,
                        'seventhSearchResultTypeSet': i === 6,
                        'eighthSearchResultTypeSet': i === 7
                    }" (click)="isExpanded[matchingTypeSet.entityType] = !isExpanded[matchingTypeSet.entityType]">
                    <div class="leftHeaderItems">
                        <div class="globalSearchResultCheckBox">
                            <i class="fa fa-square-o" aria-hidden="true "></i>
                        </div>
                        <div *ngIf="isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <span class="fa-stack searchResultHeaderIcon">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-user fa-stack-1x" aria-hidden="true"></i>
                        </span>
                        <div class="recordTypeLabel">
                            <span>Users ({{ getSearchTotal(matchingTypeSet) }})</span>
                        </div>
                    </div>
                    <div class="rightHeaderItems clickable" (click)="selectFilter(matchingTypeSet.entityType)">
                        <i class="fa fa-filter filterIcon" aria-hidden="true"></i>
                        <p> Filter Results</p>
                    </div>
                </div>

                <div class="recordTypeResults" *ngIf="isExpanded[matchingTypeSet.entityType]">
                    <!--=========================== RESULTS BODY FOR USERS ===========================-->
                    <div class="recordRowContainer">
                        <div *ngFor="let r of matchingTypeSet.entities | slice:0:5" class="recordRow clickable" (click)="navigateToInvoiceRecord(r)">
                            <div class="recordCol">
                                <div *ngIf="isFavorite(matchingTypeSet.entityType, r)" (click)="removeFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="yellow fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="!isFavorite(matchingTypeSet.entityType, r)" (click)="addFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="grey fa fa-star-o" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="recordCol">
                                <div class="matchedValue" [innerHtml]="r.shipper "></div>
                                <div class="content">
                                    <div>{{r.lastname}}, {{r.firstname}} &#64;{{r.username}} {{r.email}}</div>
                                    <div>{{r.customer}}</div>
                                </div>
                                <div class="content">{{r.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ==================================== RESULTS BODY FOOTER ============================================== -->
                <div class="viewMoreRecordsContainer" *ngIf="matchingTypeSet.entities.length >= 5">
                    <span class="blue actionText" (click)="selectFilter(matchingTypeSet.entityType)">View More Records</span>
                </div>
            </div>

            <div class="recordTypeResultsPanel" *ngSwitchCase="searchEntityTypes.MASTER_DATA_MARKUPS">
                <!--=========================== RESULTS HEADER FOR MARKUPS ===========================-->
                <div class="recordTypeResultsHeader clickable" [ngClass]="{
                                        'firstSearchResultTypeSet': i === 0,
                                        'secondSearchResultTypeSet': i === 1,
                                        'thirdSearchResultTypeSet': i === 2,
                                        'fourthSearchResultTypeSet': i === 3,
                                        'fifthSearchResultTypeSet': i === 4,
                                        'sixthSearchResultTypeSet': i === 5,
                                        'seventhSearchResultTypeSet': i === 6,
                                        'eighthSearchResultTypeSet': i === 7
                                    }" (click)="isExpanded[matchingTypeSet.entityType] = !isExpanded[matchingTypeSet.entityType]">
                    <div class="leftHeaderItems">
                        <div class="globalSearchResultCheckBox">
                            <i class="fa fa-square-o" aria-hidden="true "></i>
                        </div>
                        <div *ngIf="isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!isExpanded[matchingTypeSet.entityType]" class="collapseIcon">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <span class="fa-stack searchResultHeaderIcon">
                            <i class="fa fa-circle fa-stack-2x"></i>
                            <i class="fa fa-book fa-stack-1x" aria-hidden="true"></i>
                        </span>
                        <div class="recordTypeLabel">
                            <span>Markups ({{ getSearchTotal(matchingTypeSet) }})</span>
                        </div>
                    </div>
                    <div class="rightHeaderItems clickable" (click)="selectFilter(matchingTypeSet.entityType)">
                        <i class="fa fa-filter filterIcon" aria-hidden="true"></i>
                        <p> Filter Results</p>
                    </div>
                </div>

                <div class="recordTypeResults" *ngIf="isExpanded[matchingTypeSet.entityType]">
                    <!--=========================== RESULTS BODY FOR MARKUPS ===========================-->
                    <div class="recordRowContainer">
                        <div *ngFor="let r of matchingTypeSet.entities | slice:0:5" class="recordRow clickable" (click)="navigateToMasterDataMarkup(r)">
                            <div class="recordCol">
                                <div *ngIf="isFavorite(matchingTypeSet.entityType, r)" (click)="removeFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="yellow fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="!isFavorite(matchingTypeSet.entityType, r)" (click)="addFavorite($event, matchingTypeSet.entityType, r)" class="favIcon blue">
                                    <i class="grey fa fa-star-o" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="recordCol">
                                <div class="matchedValue" [innerHtml]="r.markupname"></div>
                                <div class="content">
                                    Markup Type: {{ r.markuptype }}, Active:
                                    <span *ngIf="r.active">Y</span>
                                    <span *ngIf="!r.active">N</span>
                                </div>
                                <div class="content">{{ r.markupdescription }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ==================================== RESULTS BODY FOOTER ============================================== -->
                <div class="viewMoreRecordsContainer" *ngIf="matchingTypeSet.entities.length >= 5">
                    <span class="blue actionText" (click)="selectFilter(matchingTypeSet.entityType)">View More Records</span>
                </div>
            </div>

        </div>
    </div>


    <!-- ======================================= FILTERED SEARCH RESULTS HTML ============================================= -->
    <div class="refinedSearchResults">
        <div class="filteredSearchResultsContainer" *ngIf="searchState.filteredSearchResults && searchState.filteredSearchResults.entities && searchState.filteredSearchResults.entities.length">
            <div class="filteredSearchResultsHeaderRow">
                <div class="checkboxContainer">
                    <i class="grey fa fa-square-o" aria-hidden="true"></i>
                </div>
                <div class="favoritesContainer">
                    <i class="grey fa fa-star-o" aria-hidden="true"></i>
                </div>
                <div class="columnHeader blue" *ngFor="let columnHeader of defaultSearchColumns">
                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    {{columnHeader | DisplayData:'searchEntityColumns':searchState.filterTerm}}
                </div>
            </div>

            <div class="filteredSearchResultsRow resultRow clickable bottomBorderShadow" *ngFor="let record of searchState.filteredSearchResults.entities"
                (click)="navigateToRecord(record)">
                <div class="checkboxContainer">
                    <i class="grey fa fa-square-o" aria-hidden="true"></i>
                </div>
                <div *ngIf="!isFavorite(searchState.filterTerm, record)" class="favoritesContainer" (click)="addFavorite($event, searchState.filterTerm, record)">
                    <i class="grey fa fa-star-o" aria-hidden="true"></i>
                </div>
                <div *ngIf="isFavorite(searchState.filterTerm, record)" class="favoritesContainer" (click)="removeFavorite($event, searchState.filterTerm, record)">
                    <i class="yellow fa fa-star" aria-hidden="true"></i>
                </div>
                <div class="columnHeader" *ngFor="let fieldName of defaultSearchColumns">
                    {{ record[fieldName] | apiParseError:fieldName | checkDate | amount:fieldName }}
                </div>
            </div>
        </div>
    </div>

</div>
