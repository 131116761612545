import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Insurance} from '../../models/insurance';
import {Shipment} from '../../models/shipment';
import {InsuranceService} from '../../services/insurance.service';

@Component({
    selector: 'app-insurance',
    templateUrl: './app-insurance.component.html',
    styleUrls: ['./app-insurance.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppInsuranceComponent,
            multi: true
        },
    ]
})
export class AppInsuranceComponent implements OnInit, ControlValueAccessor {
    @Input()
    public shipment: Shipment;

    public value: Insurance;
    public isInsuranceModalShown = false;

    constructor(private _insuranceService: InsuranceService) {
    }
    public ngOnInit() {
    }

    public valueChanged = (__: any) => {};

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public writeValue(value: Insurance): void {
        if (value) {
            this.value = value;
        }
    }

    public async removeInsurance() {
        // eslint-disable-next-line prefer-const
        const insurance = new Insurance();
        insurance.records = 0;
        insurance.purchased = false;

        await this._insuranceService.removeRecordInsurance(this.shipment.ID).toPromise();

        this.value = insurance;
        this.valueChanged(this.value);
    }

    public addInsurance() {
        this.isInsuranceModalShown = true;
    }

    public async closeInsuranceQuotingModal(insInfo) {
        this.isInsuranceModalShown = false;

        const insurance = await this._insuranceService.addRecordInsurance(insInfo.declaredShipmentValue, this.shipment.ID).toPromise();
        this.value = insurance;
        this.valueChanged(this.value);
    }
}
