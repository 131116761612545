<!-- ======================== ODYSSEY LOGO ======================== -->
<img class="logoOdyssey" src="../../../assets/images/Odyssey%20Logo%20RGB.svg" >

<!-- ======================== SIGNUP PROMPT ======================== -->
<div class="topButtonContainer">
    <app-button class="topButton" [padding]="'0'" [textTransform]="'none'" [borderRadius]="'45px'" [height]="'50px'" [buttonColor]="'transparent'" (click)="goToLocation('https://www.odysseylogistics.com/')">Back to Home</app-button>
    <app-button class="topButton" [padding]="'0'" [textTransform]="'none'" [borderRadius]="'45px'" [height]="'50px'" [buttonColor]="'transparent'" (click)="navigateToRegistrationScreen()">Register</app-button>
</div>

<!-- ======================== LOGIN MAIN ======================== -->
<div class="loginMain">

    <!-- ======================== LOGIN BODY ======================== -->
    <div class="loginBody">
        <div class="shipriteLogoContainer">
            <img src="../../../assets/images/Logo.svg" class="shipriteLogo">
            Sign in to your account
        </div>

        <form (ngSubmit)="processLogin()" class="loginForm">
            <div class="loginFormInput">
                <i class="fa fa-user-o"></i>
                <input type="text" name="loginName" [(ngModel)]="loginName" placeholder="Username">
            </div>
            <div class="loginFormInput">
                <i class="fa fa-lock"></i>
                <input type="password" name="password" [(ngModel)]="password" placeholder="Password">
            </div>
            <button class="loginButton" type="submit">Login</button>
            <app-button [textTransform]="'none'" [borderRadius]="'25px'" tabindex="0" class="forgotPassword" [buttonColor]="'OdysseyGrey'" (click)="navigateToResetPasswordScreen()">Forgot Password?</app-button>
        </form>
    </div>

</div>
