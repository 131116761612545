<div class="inputSearchBox" [ngClass]="{ 'inputSearchBoxReadOnly': readOnly }">
    <input (change)="searchValueChanged($event)" [value]="value" #inputElement class="input"/>
    <span *ngIf="enableDialog" class="clickable" (click)="showDialog();">
        <i class="fa fa-search searchIcon"></i>
    </span>
</div>

<app-select-contact-modal *ngIf="isSearchModalShown" [modalTitle]="modalTitle" [contactType]="contactType"
                          [contactField]="contactField" [contactInput]="getContactFieldValue()"
                          (updateContactInfo)="hideSearchModal($event)"></app-select-contact-modal>
