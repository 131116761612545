<div class="datePanel">
    <span *ngIf="readOnlyDate" class="readOnly">{{value.date}}</span>
    <input #firstDateElement [attr.tabindex]="tabindex" class="dates" *ngIf="!readOnlyDate" type="text" [value]="value.date" (change)="updateDate($event.target.value)">
    <span *ngIf="isRange" class="separator">
        {{rangeSeparator}}
    </span>
    <span *ngIf="isRange && readOnlyEndDate" class="readOnly">{{value.endDate}}</span>
    <input [attr.tabindex]="tabindex" class="dates" *ngIf="isRange && !readOnlyEndDate" type="text" [value]="value.endDate" (change)="updateEndDate($event.target.value)">
    <span *ngIf="!readOnlyDate || (isRange && !readOnlyEndDate)" class="calendar blue clickable" (click)="showDatePicker()">
        <i class="fa fa-calendar" aria-hidden="true"></i>
    </span>
</div>

<app-custom-datepicker *ngIf="isDatePickerShown" [isDateRangePossible]="isRange && !readOnlyDate && !readOnlyEndDate"
                       [isDateRangeOnly]="isRange && !readOnlyDate && !readOnlyEndDate" [title]="title"
                       [inputDate]="value.date" [inputEndDate]="value.endDate"
                       [allowSingleIndefinately]="(!isRange || !readOnlyEndDate) && allowIndefinately"
                       (closeDatePicker)="hideDatePicker($event)" [dateFormatValidationPattern]="displayDateValidation"
                       [dateFormatPattern]="displayDateFormat"></app-custom-datepicker>
