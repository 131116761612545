import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService  } from '@auth0/angular-jwt';

import * as Constants from '../constants/constants';
import { UserHelper } from '../helpers/userHelper';
import { LoginRequest } from '../models/loginRequest';
import { LoginResponse } from '../models/loginResponse';
import { ResponseDTO } from '../models/responseDto';
import { User } from '../models/user';
import { ApiService, REQUEST_TYPE_GET, REQUEST_TYPE_POST } from './api.service';
import {CommonDataService} from './commonData.service';
import { LoggingService, LogLevels } from './logging.service';
import {ThinkOwlChatService} from './thinkOwlChat.service';
import {LogoutService} from "./logout.service";
import {LoginService} from "./login.service";
import {SignalsService} from "./signals.service";
import {ClipboardService} from "./clipboard.service";
import {TemplateService} from "./template.service";
import {FavoritesService} from "./favorites.service";
import {NotificationService} from "./notification.service";
import { DashboardHelper } from 'app/helpers/dashboardHelper';
import {DashboardService} from "./dashboard.service";

@Injectable()
export class AuthService {
    public fso;

    private userSettingsSubscription;

    private defaultRecordDisplayStyles: Array<string> = [
        Constants.RecordDisplayStyle_Wizard,
        Constants.RecordDisplayStyle_Accordion
    ];

    constructor(
        private _api: ApiService,
        private _clipboardService: ClipboardService,
        private _dashboardService: DashboardService,
        private _loggingService: LoggingService,
        private _notificationService: NotificationService,
        private _router: Router,
        private _templateService: TemplateService,
        private _favoritesService: FavoritesService,
        private _userHelper: UserHelper,
        private _commonDataService: CommonDataService,
        private _thinkOwlChatService: ThinkOwlChatService,
        private _logoutService: LogoutService,
        private _loginService: LoginService,
        private _signalsService: SignalsService,
        private _dashboardHelper: DashboardHelper,
    ) {
    }

    public async refreshLoginAsync(): Promise<void> {
        const token = await this.getUserLoginToken();
        if (!token) {
            return;
        }
        const jwtHelper: JwtHelperService = new JwtHelperService();
        if (jwtHelper.isTokenExpired(token)) {
            return;
        }
        const userProfile: User = jwtHelper.decodeToken(token);
        this._loggingService.sendLogMessage(LogLevels.DEBUG, `Using existing token => [${JSON.stringify(userProfile)}]`); // log this for now, until Login feature is complete

        const userProfileDto: User = await this.getUserProfileAsync(userProfile.userID);
        if (userProfile && userProfile.userID && userProfile.userlogin && userProfileDto && userProfileDto.userID && userProfileDto.userlogin) {
            if (userProfile.userID === userProfileDto.userID && userProfile.userlogin !== userProfileDto.userlogin) {
                this._loggingService.sendLogMessage(LogLevels.ERROR, `User from token [${userProfile.userID}, ${userProfile.userlogin}] does not match User from profile => [${userProfileDto.userID}, ${userProfileDto.userlogin}]; discarding existing token and logging out.`);
                // delete JWT in localStorage and Cookie both, to avoid any conflict around valid tokens
                localStorage.removeItem(Constants.USER_TOKEN_KEY);
                this._logoutService.logout();
                return;
            }
        }

        this._signalsService.loggedOutSignal.set(false);
        // if we've made it this far, must have a valid reusable token; let's load user info using this User/Profile
        await this.getUserLoginRelatedDataAsync(userProfile);
    }

    public async processUserLoginAsync(loginRequest: LoginRequest): Promise<void> {
        let userProfile: User;
        try {
            const loginHttpReq = new HttpRequest(REQUEST_TYPE_POST, `Login`, loginRequest);
            const response = await this._api.callApiService<LoginResponse>(loginHttpReq).toPromise();
            if (response.login && response.site === 'shipwithcts' && response.link) {
                window.open(response.link, '_self');
                return null;
            }
            if (response.login) {
                localStorage.setItem(Constants.USER_TOKEN_KEY, response.jwt);

                const jwtHelper: JwtHelperService = new JwtHelperService();
                userProfile = jwtHelper.decodeToken(response.jwt);
                this._loggingService.sendLogMessage(LogLevels.DEBUG, `User Login => [${JSON.stringify(userProfile)}]`); // log this for now, until Login feature is complete
            } else if (response.lockout) {
                this._signalsService.clearUserLoggedIn();
                this._router.navigate(['locked-out']);
            } else {
                this._signalsService.clearUserLoggedIn();
                this._notificationService.notifyError({ title: `User Login`, message: `Invalid login attempt.` });
            }
            await this.getUserProfileAsync(userProfile.userID);
            await this.getUserLoginRelatedDataAsync(userProfile);

        } catch (err) {
            this._signalsService.clearUserLoggedIn();
            // this._notificationService.notifyError({ message: err, title: 'User Login' });    // 3/5/18 per Kevin's request, this message should not display as a toaster alert
            this._loggingService.sendLogMessage(LogLevels.ERROR, `User Login Error => [${err}]`);
        }
    }

    public async getUserProfileAsync(userId: number): Promise<User> {
        let userProfile: User;
        try {
            const req = new HttpRequest(REQUEST_TYPE_GET, `MasterData/User/${userId}`)
            const response = await this._api.callApiService<ResponseDTO<User>>(req).toPromise();
            if (response && response.isValid && response.dto) {
                userProfile = response.dto;
                this._signalsService.userSignal.set(userProfile);
                this._signalsService.updateAppState({ 'global.isUserSessionActive': true });
                if (userProfile.preferredRecordDisplayStyle) {
                    const userDefaultDisplayStyleIndex = this.defaultRecordDisplayStyles.indexOf(userProfile.preferredRecordDisplayStyle);
                    if (userDefaultDisplayStyleIndex !== -1) {
                        this._signalsService.updateAppState({ 'record.displayStyle': userProfile.preferredRecordDisplayStyle });
                    }
                }
            } else {
                this._signalsService.clearUserLoggedIn();
                this._signalsService.updateAppState({ 'global.isUserSessionActive': false });
                this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'User Profile' });
            }
        } catch (err) {
            this._signalsService.clearUserLoggedIn();
            this._signalsService.updateAppState({ 'global.isUserSessionActive': false });
            this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'User Profile' });
        }
        return userProfile;
    }

    public async impersonate(userId: number): Promise<void> {
        const req = new HttpRequest(REQUEST_TYPE_GET, `MasterData/User/impersonate/${userId}`)
        this._api.callApiService<LoginResponse>(req).subscribe(
            async (response) => {
                let userProfile: User;
                if (response && response.login) {
                    localStorage.setItem(Constants.USER_TOKEN_KEY, response.jwt);

                    const jwtHelper: JwtHelperService = new JwtHelperService();
                    userProfile = jwtHelper.decodeToken(response.jwt);
                    this._loggingService.sendLogMessage(LogLevels.DEBUG, `User Impersonation => [${JSON.stringify(userProfile)}]`); // log this for now, until Login feature is complete
                } else {
                    this._signalsService.clearUserLoggedIn();
                    this._notificationService.notifyError({ title: `User Impersonation`, message: `Invalid impersonation attempt.` });
                }
                this._thinkOwlChatService.removeChat();
                this._dashboardHelper.clearDashboards();
                await this.getUserProfileAsync(userProfile.userID);
                await this.getUserLoginRelatedDataAsync(userProfile);
                this._router.navigate(['']);
            },
            (err: HttpErrorResponse) => {
                this._signalsService.clearUserLoggedIn();
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'User Impersonation' });
            });
    }

    public async deimpersonate(): Promise<void> {
        const req = new HttpRequest(REQUEST_TYPE_GET, `MasterData/User/deimpersonate`)
        this._api.callApiService<LoginResponse>(req).subscribe(
            async (response) => {
                let userProfile: User;
                if (response && response.login) {
                    localStorage.setItem(Constants.USER_TOKEN_KEY, response.jwt);

                    const jwtHelper: JwtHelperService = new JwtHelperService();
                    userProfile = jwtHelper.decodeToken(response.jwt);
                    this._loggingService.sendLogMessage(LogLevels.DEBUG, `User De-Impersonation => [${JSON.stringify(userProfile)}]`); // log this for now, until Login feature is complete
                } else {
                    this._signalsService.clearUserLoggedIn();
                    this._notificationService.notifyError({ title: `User De-Impersonation`, message: `Invalid de-impersonation attempt.` });
                }
                this._thinkOwlChatService.removeChat();
                this._dashboardHelper.clearDashboards();
                await this.getUserProfileAsync(userProfile.userID);
                await this.getUserLoginRelatedDataAsync(userProfile);
                this._router.navigate(['']);
            },
            (err: HttpErrorResponse) => {
                this._signalsService.clearUserLoggedIn();
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'User De-Impersonation' });
            });
    }

    private async getUserLoginRelatedDataAsync(userProfile: User): Promise<void> {
        await this._loginService.getLoginSettings().toPromise();
        await this.getDisplayDataAsync();
        await this._templateService.getUserTemplates().toPromise();
        await this._clipboardService.getAllClipboardNotes();
        if (this._userHelper.shouldBillToContactBeRetrieved()) {
            await this._commonDataService.getBillToContacts().toPromise();
        }
        await this._commonDataService.getBeAdvisedMessage().toPromise();
        await this._commonDataService.getAccessorials(userProfile.userID).toPromise();
        await this._commonDataService.getAccessorialGroups(userProfile.userID).toPromise();
        await this._favoritesService.loadFavorites();
        await this._commonDataService.getAdminRoleTypes().toPromise();
        this._signalsService.tmaSignal.set(await this._commonDataService.getTmaText().toPromise());
        await this._commonDataService.getCarriers().toPromise();
        await this._commonDataService.getCommodityProductOptions().toPromise();
        await this._commonDataService.getTmaList().toPromise();
        await this._commonDataService.getAdminSRODOpsGroupOptions().toPromise();
        await this._commonDataService.getAdminSRODAssignedUsersOptions().toPromise();
        await this._commonDataService.getEquipmentTypes().toPromise();
        this._thinkOwlChatService.loadChat();
    }

    private async getUserLoginToken() {
        const jwtHelper: JwtHelperService = new JwtHelperService();
        const jwtFromFSO = await this._loginService.getJWTFromFSO(this.fso);

        if (jwtFromFSO) {
            if (!jwtHelper.isTokenExpired(jwtFromFSO)) {
                this._loggingService.sendLogMessage(LogLevels.DEBUG, `using token from FSO for ${Constants.USER_TOKEN_KEY}`);
                localStorage.setItem(Constants.USER_TOKEN_KEY, jwtFromFSO);
                return jwtFromFSO;
            }
        } else if (this.fso) {
            this._notificationService.notifyError({ title: `User Login`, message: `Invalid login attempt.` });
            return;
        }

        const tokenFromLocalStorage = localStorage.getItem(Constants.USER_TOKEN_KEY);

        if (tokenFromLocalStorage) {
            if (!jwtHelper.isTokenExpired(tokenFromLocalStorage)) {
                this._loggingService.sendLogMessage(LogLevels.DEBUG, `using token from localStorage for ${Constants.USER_TOKEN_KEY}`);
                return tokenFromLocalStorage;
            } else {
                // ignore this token if expired, but delete it while we are here
                this._loggingService.sendLogMessage(LogLevels.DEBUG, `deleting expired token from localStorage for ${Constants.USER_TOKEN_KEY}`);
                localStorage.removeItem(Constants.USER_TOKEN_KEY);
            }
        }

        // have not found any usable tokens, return null
        return null;
    }

    private async getDisplayDataAsync(): Promise<void> {
        const displayDataHttpReq = new HttpRequest(REQUEST_TYPE_GET, `AppDisplayData`);
        this._api.callApiService<ResponseDTO<any>>(displayDataHttpReq).subscribe( // TODO: will create a model for user response once format/structure has been cleared with Darryl
            async (response) => {
                if (response && response.isValid) {
                    this._signalsService.displaySignal.set(response.dto);
                    this._dashboardService.getDashboards();
                    // this._clipboardActions.getClipboardNotes();    // TODO(Sorum Panchal) We'll make this call when we have an endpoint for Clipboard/Shortcuts
                } else {
                    this._signalsService.displaySignal.set({});
                }
            },
            (err: HttpErrorResponse) => {
                this._signalsService.displaySignal.set({});
                this._notificationService.notifyError({ title: `Display Data Retrieval error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
            });
    }
}
