<div class="overlay">
  <div class="adminCustomerOverflowModal">

    <!-- =========================== MODAL HEADER =========================== -->
    <div class="modalHeader">
      <span class="modalHeaderText">
        Search {{ modalTitle }}
      </span>

      <div class="modalHeaderIcon clickable" (click)="cancel()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </div>
    </div>

    <!-- =========================== MODAL BODY =========================== -->
    <div class="modalBody">

      <!-- =========================== SEARCH PANEL =========================== -->
      <div class="searchPanel">
        <input type="text" class="searchInput" [(ngModel)]="overflowQuery" (ngModelChange)="updateFilteredResultSet($event)">

        <div class="searchIcon clickable">
          <i class="fa fa-search"></i>
        </div>
      </div>

      <!-- =========================== SEARCH RESULTS =========================== -->
      <div class="searchResultsHeader">
        <span class="searchResultsHeaderText">
          Name
        </span>
      </div>

      <div class="searchResultsContainer scrollbar">
        <div class="searchResultRow clickable" *ngFor="let result of filteredResultSet" (click)="selectResult(result)">
          <span class="searchResultRowText">
            {{ result[selectedOverflowModalOption.displayName] }}
          </span>
        </div>
      </div>
    </div>

    <!-- =========================== MODAL FOOTER =========================== -->
    <div class="modalFooter">
    </div>
  </div>
</div>