import {Pipe, PipeTransform} from '@angular/core';
import {SignalsService} from "../services/signals.service";

@Pipe({name: 'DisplayData'})
export class DisplayDataPipe implements PipeTransform {
    public displayData;

    constructor(
        private _signalsService: SignalsService,
    ) {}

    transform(fieldName: string, dataType: string, entityType: string): any {
        this.displayData = this._signalsService.displaySignal();
        if (this.displayData && this.displayData[dataType] && this.displayData[dataType][entityType]) {
            return this.displayData[dataType][entityType][fieldName];
        }
    }
}
