import { AccessorialForQuote } from './accessorialForQuote';
import { Consignee } from './consignee';
import { ProductDetail } from './productDetail';
import { QuotedRate } from './quotedRate';
import { Shipper } from './shipper';

export class QuotesResponse { // Rates
    shipmentId: number;
    accl?: Array<AccessorialForQuote> = [];
    carriers: Array<QuotedRate>;
    cube: number;
    origin: { city: string, state: string, zipCode: string, country: string };
    originType: string;
    originCallAppt: boolean;
    destination: { city: string, state: string, zipCode: string, country: string };
    destinationType: string;
    destinationCallAppt: boolean;
    detail: Array<ProductDetail>; // { "PALLETS": 1.0, "PACKAGES": 0.0, "CLASS": 50.0, "WEIGHT": 1000.0 }
    direction: string;
    error: number;
    miles: number;
    pcf: number;
    quoteId?: number;
    selectedRateId?: number;
    shipdate: string;
    fastestDeliveryRateId: number;
    lowestCostRateId: number;
    unitmeasurement: string;
    tsa: boolean;
    quote_name: string;
    Consignee?: Consignee;
    Shipper?: Shipper;
    equipmentType: number;
}
