<span *ngIf="getName(); let theName">{{theName}}<br /></span>
<ng-container *ngIf="!terminal || terminal.address">
    <ng-container *ngFor="let addressLine of getAddressLines();" >
        <span *ngIf="addressLine">{{addressLine}}<br /></span>
    </ng-container>
    <span *ngIf="getCity(); let theCity">{{theCity}}<span *ngIf="getState() || getPostalCode()">, </span></span>
    <span *ngIf="getState(); let theState">{{theState}}</span>
    <span *ngIf="getPostalCode(); let thePostalCode">&nbsp;{{thePostalCode}}</span><br />
</ng-container>

<ng-container *ngIf="!terminal || terminal.contact">
    <span *ngIf="getPhoneWork(); let phoneWork">{{phoneWork}}<br /></span>
    <span *ngIf="getPhone800(); let phone800">{{phone800}}<br /></span>
    <span *ngIf="getPhoneMobile(); let phoneMobile">{{phoneMobile}} (mobile)<br /></span>
    <span *ngIf="getPhoneFax(); let phoneFax">{{phoneFax}} (fax)<br /></span>
</ng-container>

<ng-container *ngIf="!terminal || terminal.contact.email">
    <span *ngIf="getEmail(); let email">{{email}}<br /></span>
</ng-container>
