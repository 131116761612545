import {CurrencyPipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {ToasterModule, ToasterService} from '@dkachko/angular2-toaster';
import {DragulaModule} from 'ng2-dragula';

import {PdfViewerModule} from 'ng2-pdf-viewer';
import {environment} from '../environments/environment';
import {APP_COMPONENTS} from './app.components';
import {APP_DIRECTIVES} from './app.directives';
import {APP_GUARDS} from './app.guards';
import {APP_HELPERS} from './app.helpers';
import {APP_INTERCEPTORS} from './app.interceptors';
import {APP_PIPES} from './app.pipes';
import {APP_RESOLVERS} from './app.resolvers';
import {APP_ROUTES} from './app.routes';
import {APP_SERVICES} from './app.services';
import {AppComponent} from './components/app/app.component';

import {AdminModule} from './app-admin.module';
import {ScrollingModule} from "@angular/cdk/scrolling";

@NgModule({
    declarations: [
        ...APP_COMPONENTS,
        ...APP_PIPES,
        ...APP_DIRECTIVES,
    ],
    imports: [
        AdminModule,
        BrowserAnimationsModule,
        BrowserModule,
        DragulaModule.forRoot(),
        FormsModule, ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot(APP_ROUTES, {onSameUrlNavigation: 'reload'}), // { useHash: true }),  // HashLocationStrategy not needed anymore as Kevin implemented URL rewriting in Apache
        // RTG: Routing was checked out on Amplify in AWS on 8/12/2021 and seems to work fine for the app.
        ToasterModule,
        PdfViewerModule,
        ScrollingModule,
    ],
    providers: [
        CurrencyPipe,
        ToasterService,
        ...APP_GUARDS,
        ...APP_HELPERS,
        ...APP_RESOLVERS,
        ...APP_SERVICES,
        ...(environment.usingMocking ? APP_INTERCEPTORS : []),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
