import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgModel, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as _ from 'lodash-es';
import * as Constants from '../../constants/constants';
import {UserHelper} from '../../helpers/userHelper';
import {productDetailToProductMasterData} from '../../helpers/utilities';
import {CubeRequest} from '../../models/cubeRequest';
import {HandlingUnit} from '../../models/handlingUnit';
import {NmfcClass} from '../../models/nmfcClass';
import {PackageType} from '../../models/packageType';
import {ProductMasterData} from '../../models/product.masterData';
import {ProductDetail} from '../../models/productDetail';
import {UnitOfMeasure} from '../../models/unitOfMeasure';
import {User} from '../../models/user';
import {CommonDataService} from '../../services/commonData.service';
import {NotificationService} from "../../services/notification.service";
import {ShipmentService} from "../../services/shipment.service";

@Component({
    selector: 'app-product-line',
    styleUrls: ['./app-product-line.component.scss'],
    templateUrl: './app-product-line.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppProductLineComponent,
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: AppProductLineComponent,
            multi: true
        }
    ]
})
export class AppProductLineComponent implements OnInit, ControlValueAccessor {
    @Input()
    public quickQuoteDisplay = true;
    @Input()
    public forceAllDirty = false;
    @Input()
    public unitOfMeasure: UnitOfMeasure;
    @Input()
    public readOnly = false;
    @Output()
    public unitOfMeasureChanged = new EventEmitter();
    @Output()
    public dialogShown = new EventEmitter();
    @ViewChild('lengthModel', { static: false }) lengthModel: NgModel;
    @ViewChild('widthModel', { static: false }) widthModel: NgModel;
    @ViewChild('heightModel', { static: false }) heightModel: NgModel;
    @ViewChild('weightModel', { static: false }) weightModel: NgModel;
    @ViewChild('qtyModel', { static: false }) qtyModel: NgModel;
    @ViewChild('handlingUnitModel', { static: false }) handlingUnitModel: NgModel;
    @ViewChild('cubeModel', { static: false }) cubeModel: NgModel;
    @ViewChild('productModel', { static: false }) productModel: NgModel;

    public product: ProductMasterData = new ProductMasterData();
    public value = new ProductDetail();
    public nmfcClasses: Array<NmfcClass>;
    public handlingUnits: Array<HandlingUnit>;
    public packageTypes: Array<PackageType>;
    public nmfcClass: NmfcClass;
    public handlingUnit: HandlingUnit;
    public packageType: PackageType;
    public unitOfMeasureList: Array<UnitOfMeasure>;
    public calculatorData: any = {};
    public isInvalidAndDirty = false;
    public calculatorPiecesType = 'Packages';

    private control: UntypedFormControl;
    private userProfile: User;

    constructor(private _commonDataService: CommonDataService,
                private _shipmentService: ShipmentService,
                private _userHelper: UserHelper,
                private _notificationService: NotificationService) {}

    public async ngOnInit() {
        const naClass = {
            class: 0,
            displayClass: 'N/A'
        };
        const naHandlingUnit: HandlingUnit = {
            id: '0',
            apiCode: 'NA',
            description: 'N/A'
        };
        const naPackageType: PackageType = {
            id: '0',
            apiCode: 'NA',
            description: 'N/A'
        }
        this.nmfcClasses = _.cloneDeep(await this._commonDataService.loadedPromise(this._commonDataService.nmfcClasses));
        this.nmfcClasses.unshift(naClass as any);
        this.handlingUnits = _.cloneDeep(await this._commonDataService.loadedPromise(this._commonDataService.handlingUnits));
        this.handlingUnits.unshift(naHandlingUnit);
        this.packageTypes = _.cloneDeep(await this._commonDataService.loadedPromise(this._commonDataService.packageTypes));
        this.packageTypes.unshift(naPackageType);
        this.unitOfMeasureList = await this._commonDataService.loadedPromise(this._commonDataService.unitOfMeasures);
        this.userProfile = this._userHelper.getUserProfile();
        this.syncClass();
        this.syncHandlingUnit();
        this.syncPkgType();
    }

    public validate(c: UntypedFormControl) {
        this.control = c;
        return this.validateNumericFields();
    }

    public writeValue(value) {
        if (value) {
            this.value = value;
            this.product = productDetailToProductMasterData(this.value);
            this.syncClass();
            this.syncHandlingUnit();
            this.syncPkgType();
            if (this.control) {
                this.control.markAsPristine();
            }
        }
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public updateProduct(ev) {
        if (ev.Name || (this.value.name !== undefined)) {
            this.value.name = ev.Name;
        }
        if (ev.produtsId !== undefined) {
            this.value.productId = ev.produtsId;
        } else {
            delete this.value.productId;
        }
        if (ev.ID !== undefined) {
            this.value.Product = ev.ID;
        } else {
            delete this.value.Product;
        }
        // eslint-disable-next-line no-unused-expressions
        (ev.Description !== undefined) ? this.value.description = ev.Description : delete this.value.description;
        // eslint-disable-next-line no-unused-expressions
        (ev.description2 !== undefined) ? this.value.description2 = ev.description2 : delete this.value.description2;
        if (ev.class !== undefined) {
            this.value.class = ev.class;
            this.nmfcClass = this.findClass(this.value.class);
        }
        if (ev.Type !== undefined) {
            this.value.Unit_Type = ev.Type;
            this.handlingUnit = this.findHandlingUnit(this.value.Unit_Type);
            if ((this.value.Units === undefined) || (this.quickQuoteDisplay)) {
                this.value.Units = 0;
            }
        } else {
            if (this.quickQuoteDisplay) {
                delete this.value.Unit_Type;
                delete this.value.Units;
            }
        }
        /* eslint-disable no-unused-expressions */
        (ev.Pack !== undefined) ? this.value.Pkg_Type = ev.Pack : delete this.value.Pkg_Type;
        this.packageType = this.findPackageType(this.value.Pkg_Type);
        (ev.Hazmat !== undefined) ? this.value.hazmat = ev.Hazmat : delete this.value.hazmat;
        (ev.hazmat_class !== undefined) ? this.value.hazmat_class = ev.hazmat_class : delete this.value.hazmat_class;
        (ev.hazmat_packingGroup !== undefined) ? this.value.hazmat_packingGroup = ev.hazmat_packingGroup : delete this.value.hazmat_packingGroup;
        (ev.hazmat_subclass !== undefined) ? this.value.hazmat_subClass = ev.hazmat_subclass : delete this.value.hazmat_subClass;
        (ev.Hazmat_number !== undefined) ? this.value.hazmat_number = ev.Hazmat_number : delete this.value.hazmat_number;
        (ev.NMFC !== undefined) ? this.value.nmfc = ev.NMFC : delete this.value.nmfc;

        /* eslint-enable no-unused-expressions */
        this.valueChanged(this.value);
    }

    public updateStackable($event: boolean) {
        if (($event !== undefined) && ($event !== null)) {
            this.value.stackable = $event ? 1 : 0;
            this.valueChanged(this.value);
        }
    }

    public updatePackageType($event: PackageType) {
        if ($event) {
            this.value.Pkg_Type = +$event.id;
            this.valueChanged(this.value);
        }
    }

    public updateHandlingUnit($event: HandlingUnit) {
        if ($event) {
            this.value.Unit_Type = +$event.id;
            this.valueChanged(this.value);
        }
    }
    public updateClass($event: NmfcClass) {
        if ($event) {
            this.value.class = $event.class;
            this.valueChanged(this.value);
        }
    }

    public getPackageTypeListKey(thePackageType: PackageType) {
        if (thePackageType) {
            return thePackageType.id;
        } else {
            return undefined;
        }
    }

    public getHandlingUnitListKey(theHandlingUnit: HandlingUnit) {
        if (theHandlingUnit) {
            return theHandlingUnit.id;
        } else {
            return undefined;
        }
    }

    public getListKey(theClass: NmfcClass) {
        if (theClass) {
            return theClass.class;
        } else {
            return undefined;
        }
    }

    public getPackageTypeListLabel(thePackageType: PackageType) {
        if (thePackageType) {
            return thePackageType.description;
        } else {
            return undefined;
        }
    }

    public getHandlingUnitListLabel(theHandlingUnit: HandlingUnit) {
        if (theHandlingUnit) {
            return theHandlingUnit.description;
        } else {
            return undefined;
        }
    }

    public getListLabel(theClass: NmfcClass) {
        if (theClass) {
            return theClass.displayClass;
        } else {
            return undefined;
        }
    }

    public updateProp(propThatChanged: string, val) {
        setTimeout(() => {
            this.value[propThatChanged] = this.toNumericOrInvalidString(val);
            this.valueChanged(this.value);
        }, 0);
    }

    public calculateCube() {
        if (this.lengthModel.valid && this.value.Length &&
            this.heightModel.valid && this.value.Height &&
            this.widthModel.valid && this.value.Width &&
            (this.qtyModel.valid || (this.handlingUnitModel?.valid))) {
            this.checkUnitOfMeasure();
            const cubeRequest: CubeRequest = {
                length: this.value.Length.toString(),
                width: this.value.Width.toString(),
                height: this.value.Height.toString(),
                piece_count: (this.handlingUnitModel?.valid && (this.value.Units > 0)) ? this.value.Units.toString()
                    : this.value.Qty.toString(),
                unitmeasurement: this.unitOfMeasure.unitOfMeasureCode
            };

            this._shipmentService.updateProductCube(cubeRequest)
                .subscribe(response => {
                    if (response && response.isValid && response.dto) {
                        this.value.Cube = +response.dto.toFixed(Constants.RECORD_DISPLAY_STYLE_DECIMAL_PLACES);
                        this.valueChanged(this.value);
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({
                            response,
                            title: 'Common Cube'
                        });
                    }
                })
        }
    }

    public getCalculatorData() {
        return () => {
            const cubeLineItems: Array<any> = [];
            const cubeLineItem: any = {};
            let description;
            /* eslint-disable no-unused-expressions */
            (this.unitOfMeasure === undefined) ? delete this.calculatorData.unitOfMeasure : this.calculatorData.unitOfMeasure = this.unitOfMeasure.unitOfMeasureCode;
            (this.value.Weight === undefined) ? delete this.calculatorData.weight : this.calculatorData.weight = this.value.Weight;
            (this.value.Cube === undefined) ? delete this.calculatorData.cube : this.calculatorData.cube = this.value.Cube;
            (this.value.Length === undefined) ? delete cubeLineItem.length : cubeLineItem.length = this.value.Length;
            (this.value.Width === undefined) ? delete cubeLineItem.width : cubeLineItem.width = this.value.Width;
            (this.value.Height === undefined) ? delete cubeLineItem.height : cubeLineItem.height = this.value.Height;
            if (this.handlingUnitModel && (this.value.Units > 0)) {
                cubeLineItem.pieceCount = this.value.Units;
                description = this.getHandlingUnitListLabel(this.findHandlingUnit(this.value.Unit_Type));
                if (description) {
                    this.calculatorPiecesType = description;
                } else {
                    this.calculatorPiecesType = 'Handling Units';
                }
            } else {
                (this.value.Qty === undefined) ? delete cubeLineItem.pieceCount : cubeLineItem.pieceCount = this.value.Qty;
                description = this.getPackageTypeListLabel(this.findPackageType(this.value.Pkg_Type));
                if (description) {
                    this.calculatorPiecesType = description;
                } else {
                    this.calculatorPiecesType = 'Packages';
                }
            }
            /* eslint-enable no-unused-expressions */
            cubeLineItems.push(cubeLineItem);
            this.calculatorData.cubeLineItems = cubeLineItems;
            return this.calculatorData;
        }
    }

    public updateFromCalculator(cubeCalcResult: any) {
        this.checkUnitOfMeasure();
        const newUnitOfMeasure: UnitOfMeasure = this.findUnitOfMeasure(cubeCalcResult.unitOfMeasure);
        if (newUnitOfMeasure.unitOfMeasureCode !== this.unitOfMeasure.unitOfMeasureCode) {
            this.unitOfMeasure = newUnitOfMeasure;
            this.unitOfMeasureChanged.emit(this.unitOfMeasure);
        }
        this.value.Weight = this.toNumericOrInvalidString(cubeCalcResult.weight);
        this.weightModel.control.markAsDirty();
        this.value.Cube = this.toNumericOrInvalidString(cubeCalcResult.cube);
        this.cubeModel.control.markAsDirty();
        const findResult = this.findClass(cubeCalcResult.estimatedClass, 'displayClass');
        if (findResult) {
            this.nmfcClass = findResult;
            this.value.class = this.nmfcClass.class;
        }
        if (cubeCalcResult.cubeLineItems) {
            this.value.Length = this.toNumericOrInvalidString(cubeCalcResult.cubeLineItems[0].length);
            this.lengthModel.control.markAsDirty();
            this.value.Width = this.toNumericOrInvalidString(cubeCalcResult.cubeLineItems[0].width);
            this.widthModel.control.markAsDirty();
            this.value.Height = this.toNumericOrInvalidString(cubeCalcResult.cubeLineItems[0].height);
            this.heightModel.control.markAsDirty();
            if (this.handlingUnitModel && this.value.Units > 0) {
                this.value.Units = this.toNumericOrInvalidString(cubeCalcResult.cubeLineItems[0].pieceCount);
            } else {
                this.value.Qty = this.toNumericOrInvalidString(cubeCalcResult.cubeLineItems[0].pieceCount);
            }
            this.qtyModel.control.markAsDirty();
        }
        this.valueChanged(this.value);
        setTimeout(() => {
            this.validateNumericFields();
        }, 0);
    }

    public validateNumericFields() {
        if (!this.quickQuoteDisplay && this.productModel) {
            if (this.isEmpty(this.value.name)) {
                this.productModel.control.setErrors({required: true});
            } else {
                this.productModel.control.setErrors(null);
            }
        }

        if (this.lengthModel) {
            if (!this.isValidDimension(this.value.Length) ||
                (this.isEmpty(this.value.Length) &&
                    !(this.isEmpty(this.value.Width) &&
                        this.isEmpty(this.value.Height)))) {
                this.lengthModel.control.setErrors({notGreaterThanZero: true});
            } else {
                this.lengthModel.control.setErrors(null);
            }
        }

        if (this.widthModel) {
            if (!this.isValidDimension(this.value.Width) ||
                (this.isEmpty(this.value.Width) &&
                    !(this.isEmpty(this.value.Length) &&
                        this.isEmpty(this.value.Height)))) {
                this.widthModel.control.setErrors({notGreaterThanZero: true});
            } else {
                this.widthModel.control.setErrors(null);
            }
        }

        if (this.heightModel) {
            if (!this.isValidDimension(this.value.Height) ||
                (this.isEmpty(this.value.Height) &&
                    !(this.isEmpty(this.value.Length) &&
                        this.isEmpty(this.value.Width)))) {
                this.heightModel.control.setErrors({notGreaterThanZero: true});
            } else {
                this.heightModel.control.setErrors(null);
            }
        }

        if (this.weightModel) {
            if (isNaN(this.value.Weight) || +this.value.Weight <= 0) {
                this.weightModel.control.setErrors({notGreaterThanZero: true});
            } else {
                this.weightModel.control.setErrors(null);
            }
        }

        if (this.qtyModel && this.handlingUnitModel) {
            if (this.oneIsValid(this.value.Qty, this.value.Units)) {
                this.qtyModel.control.setErrors(null);
                this.handlingUnitModel.control.setErrors(null);
                if (isNaN(this.value.Qty) || (+this.value.Qty <  0)) {
                    this.qtyModel.control.setErrors({notGreaterThanZero: true});
                }
                if (isNaN(this.value.Units) || (+this.value.Units <  0)) {
                    this.handlingUnitModel.control.setErrors({notGreaterThanZero: true});
                }
            } else {
                this.qtyModel.control.setErrors({notGreaterThanZero: true});
                this.handlingUnitModel.control.setErrors({notGreaterThanZero: true});
            }
        }

        if (this.cubeModel) {
            if (this.value.class) {
                if (this.value.Cube > 0) {
                    this.cubeModel.control.setErrors(null);
                } else if ((this.cubeModel.control.value === '') || (this.cubeModel.control.value === null) || (this.cubeModel.control.value === undefined)) {
                    this.cubeModel.control.setErrors(null);
                } else {
                    this.cubeModel.control.setErrors({notGreaterThanZero: true});
                }
            } else {
                if (isNaN(this.value.Cube) || +this.value.Cube <= 0) {
                    this.cubeModel.control.setErrors({notGreaterThanZero: true});
                } else {
                    this.cubeModel.control.setErrors(null);
                }
            }
        }

        if (this.lengthModel && this.widthModel && this.heightModel && this.weightModel && this.qtyModel && this.cubeModel && this.productModel) {
            this.isInvalidAndDirty = this.lengthModel.invalid || this.widthModel.invalid || this.heightModel.invalid ||
                (this.productModel.invalid && (this.productModel.dirty || this.forceAllDirty)) ||
                (this.weightModel.invalid && (this.weightModel.dirty || this.forceAllDirty)) ||
                (this.qtyModel.invalid && (this.qtyModel.dirty || this.forceAllDirty)) ||
                (this.handlingUnitModel?.invalid && (this.handlingUnitModel.dirty || this.forceAllDirty)) ||
                (this.cubeModel.invalid && (this.cubeModel.dirty || this.forceAllDirty));

            if (this.lengthModel.invalid || this.widthModel.invalid || this.heightModel.invalid ||
                this.weightModel.invalid || this.qtyModel.invalid || this.cubeModel.invalid ||
                (this.productModel?.invalid) || (this.handlingUnitModel?.invalid)) {
                return {invalidLineItems: true};
            } else {
                return null;
            }
        }

        return null;
    }

    public checkCube(val) {
        setTimeout(() => {
            this.value.Cube = this.toNumericOrInvalidString(val);
            this.valueChanged(this.value);
        }, 0);
    }

    public getNonLengthClassFor(ngModel: NgModel) {
        if (!ngModel) {
            return {};
        }
        return {
            error: ngModel.invalid && (ngModel.dirty || this.forceAllDirty),
            normal: ngModel.valid || (ngModel.pristine && !this.forceAllDirty),
        };
    }

    public getClassFor(ngModel: NgModel) {
        if ((ngModel === this.lengthModel) || (ngModel === this.widthModel) || (ngModel === this.heightModel)) {
            return {
                error: ngModel.invalid,
                blackBorder: ngModel.valid,
            };
        } else if ((ngModel === this.qtyModel) || (ngModel === this.handlingUnitModel) || (ngModel === this.cubeModel)) {
            return {
                error: ngModel.invalid,
                normal: ngModel.valid,
            };
        } else {
            return {
                error: ngModel.invalid && (ngModel.dirty || this.forceAllDirty),
                normal: ngModel.valid || (ngModel.pristine && !this.forceAllDirty),
            };
        }
    }

    public updateWeight(val) {
        setTimeout(() => {
            if (val !== undefined) {
                this.value.Weight = this.toNumericOrInvalidString(val);
                this.valueChanged(this.value);
            }
        }, 0);
    }

    public calcDialogShown($event, cubeModel) {
        this.dialogShown.emit($event);
        if (!$event) {
            cubeModel.focus();
        }
    }

    public calculateDensity() {
        const weight = this.value.Weight;
        const cube = this.value.Cube;

        if (!isNaN(weight) && !isNaN(cube) && (cube > 0)) {
            return weight / cube;
        }
    }

    private valueChanged = (__: any) => {};

    private findUnitOfMeasure(val) {
        return _.find(this.unitOfMeasureList, (unitOfMeasure) => unitOfMeasure.unitOfMeasureCode === val);
    }

    private findClass(val, prop = 'class') {
        return _.find(this.nmfcClasses, (nmfcClass) => nmfcClass[prop] === val);
    }

    private findHandlingUnit(val, prop = 'id') {
        return _.find(this.handlingUnits, (handlingUnit) => handlingUnit[prop] === val);
    }

    private findPackageType(val, prop = 'id') {
        return _.find(this.packageTypes, (packageType) => packageType[prop] === val);
    }

    private syncPkgType() {
        if (this.value.Pkg_Type === undefined) {
            this.packageType = this.findPackageType(0);
        } else {
            this.packageType = this.findPackageType(this.value.Pkg_Type);
        }
    }

    private syncHandlingUnit() {
        if (this.value.Unit_Type === undefined) {
            this.handlingUnit = this.findHandlingUnit(0);
        } else {
            this.handlingUnit = this.findHandlingUnit(this.value.Unit_Type);
        }
    }

    private syncClass() {
        if (this.value.class === undefined) {
            if (this.userProfile?.defaultClass) {
                this.nmfcClass = this.findClass(parseInt(this.userProfile.defaultClass, 10));
                this.value.class = this.nmfcClass.class;
            } else {
                this.nmfcClass = this.findClass(0);
            }
        } else {
            this.nmfcClass = this.findClass(this.value.class);
        }
    }

    private checkUnitOfMeasure() {
        if (!this.unitOfMeasure) {
            this.unitOfMeasure = this.findUnitOfMeasure('U');
        }
    }

    private isEmpty(val) {
        return (val === undefined) || (val === null) || (val === '');
    }

    private isValidDimension(val) {
        let retval = this.hasCubeAndZeroDims() || (this.isEmpty(val) || (!isNaN(val) && (+val > 0)));
        return retval;
    }

    private isValidPositiveNumber(val) {
        return !this.isEmpty(val) && (!isNaN(val) && (+val > 0));
    }

    private oneIsValid(val1, val2) {
        return (this.isValidPositiveNumber(val1) || this.isValidPositiveNumber(val2));
    }

    private hasCubeAndZeroDims() {
        return (this.value.Cube > 0) && (this.value.Length === 0) && (this.value.Width === 0) && (this.value.Height === 0);
    }

    private toNumericOrInvalidString(val) {
        if (isNaN(val) || this.isEmpty(val) || (val < 0)) {
            return val;
        } else {
            return +val;
        }
    }
}
