<div class="overlay">
  <div class="icon-selector-modal">
    <div class="modal-header">
      <div class="modalHeaderContent">Dashboard View Icon Selector</div>
      <div class="modalHeaderContent">
        <i class="closeIcon fa fa-times clickable" aria-hidden="true" (click)="closeModal()"></i>
      </div>
    </div>
    <div class="modal-body">
      <div class="bodyContent">
        <div class="filter-container">
          <form (ngSubmit)="updateFilteredValues()" [formGroup]="iconSelectorForm">
            <div class="queryField">
              <input class="colSearchInput" placeholder="Search" formControlName="filterTerm" name="filterQuery" autofocus (change)="updateFilteredValues()">
              <span class="searchIcon">
                <i class="fa fa-search blue" aria-hidden="true"></i>
              </span>
            </div>
          </form>
        </div>

        <div class="iconContainer scrollbarGray">
          <div class="iconRow clickable bottomBorderShadow" *ngFor="let icon of filteredIcons" (click)="selectIcon(icon)">
            <span class="iconDisplay">
              <i class={{icon.className}} aria-hidden="true"></i>
            </span>
            <span>{{icon.displayName}}</span>
          </div>
        </div>
      </div>

    </div>
    <div class="bottom"></div>
  </div>
</div>