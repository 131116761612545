<div class="overlay">
    <div class="addActiveCarriersModal">
        <!--========================================= MODAL HEADER =========================================================-->
        <div class="modalHeader">
            <span class="modalHeaderText">Add Active {{(isCarrier) ? 'Carrier' : 'Accessorial'}}(s)</span>
            <span class="modalHeaderIcon clickable" (click)="cancelChanges()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>

        <!--========================================= MODAL BODY =========================================================-->
        <div class="modalBody">

            <!-- ============================== SEARCH PANEL ============================== -->
            <form *ngIf="addActiveItemsForm" class="searchPanel" [formGroup]="addActiveItemsForm">
                <input type="text" class="searchPanelInputTag" formControlName="searchQuery"
                       placeholder="Search by Name">

                <span class="searchIcon">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </span>
            </form>

            <!-- ============================== ACTIVE CARRIERS LIST ============================== -->
            <div class="activeCarrierTitleRow">
                <span class="checkboxCol clickable">
                    <i class="fa fa-square-o" aria-hidden="true"></i>
                </span>

                <span class="carrierNameCol">{{(isCarrier) ? 'Carriers' : 'Accessorials'}}</span>
            </div>

            <div class="activeCarrierResultsContainer scrollbar">
                <div class="activeCarrierRow bottomBorderShadow" *ngFor="let item of filteredItemList">
                    <span class="checkboxCol clickable" (click)="toggleCarrierCheckbox(item.code)">
                        <i class="fa fa-square-o" *ngIf="!item.isChecked" aria-hidden="true"></i>
                        <i class="fa fa-check-square" *ngIf="item.isChecked" aria-hidden="true"></i>
                    </span>

                    <span class="carrierNameCol">{{ item.name }} - ({{ item.code }})</span>
                </div>
            </div>
        </div>

        <!--========================================= MODAL FOOTER =========================================================-->
        <div class="modalFooter">
            <div class="footerButtonHolder">
                <button class="footerButton btn-outline clickable" (click)="cancelChanges()">Cancel</button>

                <button class="footerButton btn-filled clickable" (click)="addCarriers()">Add</button>
            </div>
        </div>
    </div>
</div>
