import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {catchError, map} from 'rxjs/operators';
import * as _ from 'lodash-es';
import {UserHelper} from '../helpers/userHelper';
import {clearArray} from '../helpers/utilities';
import {NmfcClass} from '../models/nmfcClass';
import {ProductMasterData} from '../models/product.masterData';
import {CommonDataService} from './commonData.service';
import {EntityService} from "./entity.service";
import {Observable} from "rxjs";

export const NEW_PRODUCT = 'new';

@Injectable()
export class ProductsService {
    public products = [];
    public lastProduct: ProductMasterData;

    constructor(
        private _fb: UntypedFormBuilder,
        private _entityService: EntityService,
        private _commonDataService: CommonDataService,
        private _userHelper: UserHelper,
    ) {}

    public getProductByPath(id) {
        switch (id) {
            case NEW_PRODUCT:
                return new Observable(subscriber => {
                    this.lastProduct = new ProductMasterData();
                    subscriber.next(_.cloneDeep(this.lastProduct));
                    subscriber.complete();
                });

            default:
                return this.getProduct(id).pipe(
                    map(product => {
                        if (Object.keys(product).length > 0) {
                            this.lastProduct = product as ProductMasterData;
                        }
                        return _.cloneDeep(this.lastProduct);
                    }),
                    catchError(err => {
                        throw err;
                    })
                )
        }
    }

    public getProduct(id) {
        return this._entityService.getEntity<ProductMasterData>('Product', id).pipe(
            map(productArray => {
                if ((productArray as unknown as Array<ProductMasterData>).length > 0) {
                    return productArray[0];
                }
                return {};
            })
        );
    }

    public deleteProduct(id) {
        return this._entityService.deleteEntity(id, 'Product');
    }

    public createProduct(product: ProductMasterData) {
        return this._entityService.createEntity<ProductMasterData>(product, 'Product', 'produtsId', 'Name').pipe(
            map(productArray => {
                if ((productArray as unknown as Array<ProductMasterData>).length > 0) {
                    return productArray[0];
                }
                return {};
            })
        );
    }

    public updateProduct(product: ProductMasterData) {
        return this._entityService.updateEntity<ProductMasterData>(product, 'Product', product.produtsId, 'Name').pipe(
            map(productArray => {
                if ((productArray as unknown as Array<ProductMasterData>).length > 0) {
                    return productArray[0];
                }
                return {};
            })
        );
    }

    public getProductsList() {
        return this._entityService.getEntityList<ProductMasterData>('Product').pipe(
            map(list => {
                if (Array.isArray(list)) {
                    clearArray(this.products);
                    this.products.push(...list);
                }
            })
        );
    }

    public updateFormFromLastProduct(productForm: UntypedFormGroup) {
        productForm.controls.id.setValue(this.lastProduct.produtsId);
        productForm.controls.name.setValue(this.lastProduct.Name);
        productForm.controls.description.setValue(this.lastProduct.Description);
        productForm.controls.additionalInfo.setValue(this.lastProduct.description2);
        productForm.controls.hazmat.setValue(!!this.lastProduct.Hazmat);
        productForm.controls.hazmatNumber.setValue(this.lastProduct.Hazmat_number);
        productForm.controls.hazmatClass.setValue(this.lastProduct.hazmat_class);
        productForm.controls.hazmatSubClass.setValue(this.lastProduct.hazmat_subClass);
        productForm.controls.hazmatPackingGroup.setValue(this.lastProduct.hazmat_packingGroup);
        productForm.controls.handlingUnit.setValue(this.lastProduct.Type);
        productForm.controls.packageType.setValue(this.lastProduct.Pack);
        productForm.controls.nmfc.setValue(this.lastProduct.nmfc);
        productForm.controls.class.setValue(this.lastProduct.class);
    }

    public getProductFormFromLastProduct() {
        const productForm = this._fb.group({
            id: this.lastProduct.produtsId,
            name: new UntypedFormControl(this.lastProduct.Name, {
                validators: [
                    Validators.required
                ]
            }),
            description: new UntypedFormControl(this.lastProduct.Description, {
                validators: [
                    Validators.required
                ]
            }),
            additionalInfo: this.lastProduct.description2,
            hazmat: (!!this.lastProduct.Hazmat),
            hazmatNumber: this.lastProduct.Hazmat_number,
            hazmatClass: this.lastProduct.hazmat_class,
            hazmatSubClass: this.lastProduct.hazmat_subClass,
            hazmatPackingGroup: this.lastProduct.hazmat_packingGroup,
            handlingUnit: this.lastProduct.Type,
            packageType: this.lastProduct.Pack,
            nmfc: this.lastProduct.nmfc,
            class: new UntypedFormControl(this.lastProduct.class, {
                validators: [
                    Validators.required
                ]
            }),
        });

        return productForm;
    }

    public getProductFromForm(form: UntypedFormGroup) {
        const productClassValue = form.value.class || 0;
        let productDisplayClassValue = '';
        const displayClassIndex = this._commonDataService.nmfcClasses.findIndex((nmfcClass: NmfcClass) => nmfcClass.class === productClassValue);

        if (displayClassIndex !== -1) {
            productDisplayClassValue = this._commonDataService.nmfcClasses[displayClassIndex].displayClass.toString(); // product.MasterData displayClass value is a string
        }

        const product: ProductMasterData = {
            ...this.lastProduct,
            ID: form.value.id || null,
            Name: form.value.name,
            Description: form.value.description,
            description2: form.value.additionalInfo || '',
            Hazmat: (form.value.hazmat ? 1 : 0),
            Hazmat_number: form.value.hazmatNumber || '',
            hazmat_class: form.value.hazmatClass || '',
            hazmat_subClass: form.value.hazmatSubClass || '',
            hazmat_packingGroup: form.value.hazmatPackingGroup || '',
            Type: form.value.handlingUnit || 0,
            Pack: form.value.packageType || 0,
            nmfc: form.value.nmfc || '',
            class: productClassValue,
            displayClass: productDisplayClassValue,
            Customer: this._userHelper.getUserCustomer(),
        };

        if (!product.produtsId) {
            // defaulted values for API call
            product.ID = null;
            product.Customer = this._userHelper.getUserCustomer();
            product.produtsId = null;
            product.Active = null;
            product.GL_Code = '';
            product.Marks = '';
            product.Type2 = 0;
            product.OutsideID = 0;
        }

        return product;
    }

    public formEqualsLastProduct(form: UntypedFormGroup) {
        const productClassValue = form.value.class || 0;
        let productDisplayClassValue = '';
        const displayClassIndex = this._commonDataService.nmfcClasses.findIndex((nmfcClass: NmfcClass) => nmfcClass.class === productClassValue);

        if (displayClassIndex !== -1) {
            productDisplayClassValue = this._commonDataService.nmfcClasses[displayClassIndex].displayClass.toString(); // product.MasterData displayClass value is a string
        }

        return (this.lastProduct.produtsId === form.value.id) &&
            (this.lastProduct.Name === form.value.name) &&
            (this.lastProduct.Description === form.value.description) &&
            (this.lastProduct.description2 === form.value.additionalInfo) &&
            (this.lastProduct.Hazmat === form.value.hazmat ? 1 : 0) &&
            (this.lastProduct.hazmat_class === form.value.hazmatClass) &&
            (this.lastProduct.hazmat_subClass === form.value.hazmatSubClass) &&
            (this.lastProduct.hazmat_packingGroup === form.value.hazmatPackingGroup) &&
            (this.lastProduct.Type === form.value.handlingUnit) &&
            (this.lastProduct.Pack === form.value.packageType) &&
            (this.lastProduct.nmfc === form.value.nmfc) &&
            (this.lastProduct.class === productClassValue) &&
            (this.lastProduct.displayClass === productDisplayClassValue);
    }
}
