<div class="overlay">
  <form class="cubeCalcModal" [formGroup]="cubeCalculatorForm">
    <!-- ======================================= MODAL HEADER ======================================= -->
    <div class="modalHeader">
      <div class="modalHeaderText">
        Cube Calculator
      </div>

      <div class="closeModalIcon clickable" (click)="cancelModal()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </div>
    </div>

    <!-- ======================================= MODAL BODY ======================================= -->
    <div class="modalBodyContainer">
      <div class="modalBody">

        <!-- ================== UNIT OF MEASURE ROW ================== -->
        <div class="unitOfMeasureRow">
          <span class="unitOfMeasureLabel">
            Unit of Measure
          </span>

          <div class="unitOfMeasureSelectPanel">
            <select class="unitOfMeasureSelectTag" formControlName="unitOfMeasurement">
              <option [value]="uom.unitOfMeasureCode" *ngFor="let uom of unitOfMeasurementOptions">
                {{ uom.unitOfMeasure }}
              </option>
            </select>

            <span class="unitOfMeasureSelectCaret clickable">
              <i class="fa fa-caret-down"></i>
            </span>
          </div>
        </div>

        <!-- ================== CUBE CALCULATION PANEL ================== -->
        <div class="cubeCalculationPanel">
          <div class="cubeCalculationMainLabelRow">
            <span class="cubeCalculationMainLabelRowText">
              Total Cube
            </span>
          </div>

          <div class="cubeCalculationRowContainer">
            <!-- ===================== LABEL ROW ===================== -->
            <div class="cubeCalculationLabelRow">
              <div class="cubeCalculationLabel cubeCalculationLengthCol">
                <span class="required">*</span>Length({{ unitOfMeasurementLabels.length }})
              </div>

              <div class="cubeCalculationLabel cubeCalculationWidthCol">
                <span class="required">*</span>Width({{ unitOfMeasurementLabels.width }})
              </div>

              <div class="cubeCalculationLabel cubeCalculationHeightCol">
                <span class="required">*</span>Height({{ unitOfMeasurementLabels.height }})
              </div>

              <div class="cubeCalculationLabel cubeCalculationCubeCol" [innerHTML]="'Cube(' + unitOfMeasurementLabels.volume + ')'">
              </div>

              <div class="cubeCalculationLabel cubeCalculationPiecesCol">
                <span class="required">*</span>Pieces<span *ngIf="piecesType">&nbsp;({{piecesType}})</span>
              </div>

              <div class="cubeCalculationLabel cubeCalculationTotalCol" [innerHTML]="'Total(' + unitOfMeasurementLabels.volume + ')'">
                Total({{ unitOfMeasurementLabels.volume }})
              </div>
            </div>

            <!-- ===================== FORM ROWS ===================== -->
            <div class="cubeCalculationFormRow" formArrayName="cubeLineItems" *ngFor="let cLI of cubeCalculatorForm['controls'].cubeLineItems['controls']; let i = index;">
              <div class="cubeCalculationFormRowColumn cubeCalculationLengthCol" [formGroupName]="i">
                <input type="text" formControlName="length" class="cubeCalculatorInputTag" #lengthField >

                <span class="cubeCalculationIcon cubeCalculationTimesIcon">
                  <i class="fa fa-times"></i>
                </span>
              </div>

              <div class="cubeCalculationFormRowColumn cubeCalculationWidthCol" [formGroupName]="i">
                <input type="text" formControlName="width" class="cubeCalculatorInputTag">

                <span class="cubeCalculationIcon cubeCalculationTimesIcon">
                  <i class="fa fa-times"></i>
                </span>
              </div>

              <div class="cubeCalculationFormRowColumn cubeCalculationHeightCol" [formGroupName]="i">
                <input type="text" formControlName="height" class="cubeCalculatorInputTag">

                <span class="cubeCalculationIcon cubeCalculationEqualsSignContainer">
                  <img src="../../../../assets/images/equals-solid.svg" class="cubeCalculationEqualsSignIcon" alt="">
                </span>
              </div>

              <div class="cubeCalculationFormRowColumn cubeCalculationCubeCol">
                <span class="cubeResult">
                  {{ cLI.value.cube }}
                </span>

                <span class="cubeCalculationIcon cubeCalculationTimesIcon">
                  <i class="fa fa-times"></i>
                </span>
              </div>

              <div class="cubeCalculationFormRowColumn cubeCalculationPiecesCol" [formGroupName]="i">
                <input type="text" formControlName="pieceCount" class="cubeCalculatorInputTag">

                <span class="cubeCalculationIcon cubeCalculationEqualsSignContainer">
                  <img src="../../../../assets/images/equals-solid.svg" class="cubeCalculationEqualsSignIcon" alt="">
                </span>
              </div>

              <div class="cubeCalculationFormRowColumn cubeCalculationTotalCol">
                <span class="totalResult">
                  {{ getProductOfCubeAndPieceCount(cLI)?.toFixed(2) }}
                </span>
              </div>

            </div>

            <!-- ===================== EMPTY ROW ===================== -->
            <div *ngIf="!singleLineCalculator" class="cubeCalculationEmptyFormRow"></div>
          </div>
        </div>

        <!-- ================== CLASS CALCULATION ROW ================== -->
        <div class="classCalculationPanel">
          <div class="cubeCalculationMainLabelRow">
            <span class="cubeCalculationMainLabelRowText">
              Total Class
            </span>
          </div>

          <div class="classCalculationLabelRow">
            <div class="classCalculationLabel classCalculationCubeCol">
              <span class="required">*</span>
              <span [innerHTML]="'Total Cube(' + unitOfMeasurementLabels.volume + ')'"></span>
            </div>

            <div class="classCalculationLabel classCalculationWeightCol">
              <span class="required">*</span>Total Weight({{ unitOfMeasurementLabels.weight }})
            </div>

            <div class="classCalculationLabel classCalculationDensityCol">
              Density ({{ unitOfMeasurementLabels.density }})
            </div>

            <div class="classCalculationLabelBold classCalculationClassCol">
              Estimated Class
            </div>
          </div>

          <div class="classCalculationFormRow" formGroupName="classTotals">
            <div class="classCalculationCubeCol">
              <input type="text" formControlName="cube" class="cubeCalculatorInputTag">
            </div>

            <div class="classCalculationWeightCol">
              <input type="text" formControlName="weight" class="cubeCalculatorInputTag">
            </div>

            <div class="classCalculationDensityCol">
              <span class="calculatedClassTotal">
                {{ densityClassTotal }}
              </span>
            </div>

            <div class="classCalculationClassCol">
              <span class="calculatedClassTotal">
                {{ estimatedClass }}
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- ======================================= MODAL FOOTER ======================================= -->
    <div class="modalFooter">
      <div class="buttonHolder">
        <div class="btn-outline clickable" (click)="resetForm()">
          Reset
        </div>

        <div class="btn-outline clickable" (click)="closeModal()">
          {{closeButtonName}}
        </div>
      </div>
    </div>

  </form>

</div>
