import {Component, OnDestroy, OnInit} from '@angular/core';

import {NmfcSearchResult} from '../../../models/nmfc.searchResult';
import {NmfcGroup} from '../../../models/nmfcGroup';
import {SearchState} from '../../../models/searchState';
import {CommonDataService} from "../../../services/commonData.service";
import {SignalsService} from "../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {SearchService} from "../../../services/search.service";

@Component({
    selector: 'app-nmfc-search',
    templateUrl: './nmfcSearch.component.html',
    styleUrls: ['./nmfcSearch.component.scss']
})
export class NmfcSearchComponent implements OnInit, OnDestroy {

    public nmfcGroups: Array<NmfcGroup>;
    public matchedNmfcGroups: Array<{ isExpanded: boolean, groupId: number, groupTitle: string, nmfcItems: Array<NmfcSearchResult> }> = [];

    public nmfcSearchQuery: string = '';

    private searchSubscription: any;
    public nmfcSearchResults: Array<NmfcSearchResult> = [];
    public isNoResultsPromptDisplayed: boolean = false;
    private hasSearchNmfcBeenRun: boolean = false;

    private search$;

    constructor(
        private _searchService: SearchService,
        private _commonDataService: CommonDataService,
        private _signalsService: SignalsService,
    ) {
        this.search$ = toObservable(this._signalsService.searchStateSignal);
    }

    public async ngOnInit() {
        this.nmfcGroups = await this._commonDataService.loadedPromise(this._commonDataService.nmfcGroups);

        this.searchSubscription = this.search$.subscribe((searchState: SearchState) => {
            if (searchState.nmfcSearchResults) {
                this.nmfcSearchResults = searchState.nmfcSearchResults;
                this.matchNmfcResultsToGroup();
            }
        });
    }

    public ngOnDestroy() {
        this._searchService.clearNmfcSearhResults();
        this.searchSubscription.unsubscribe();
    }

    // ======================================== SEARCH AND RELATEED METHODS ===============================================
    public searchNmfc(): void {
        this.hasSearchNmfcBeenRun = true;
        this._searchService.processNmfcSearch(this.nmfcSearchQuery);
    }

    private matchNmfcResultsToGroup(): void {
        this.matchedNmfcGroups = [];
        this.nmfcSearchResults.forEach((searchResult: NmfcSearchResult) => {
            const matchedGroupIndex = this.matchedNmfcGroups.findIndex(nmfcGroup => nmfcGroup.groupId === searchResult.parentitemno);
            if (matchedGroupIndex !== -1) {
                this.matchedNmfcGroups[matchedGroupIndex].nmfcItems.push(searchResult);
            } else {
                const groupId = searchResult.parentitemno;
                const groupTitle = searchResult.group_title;
                this.matchedNmfcGroups.push({
                    isExpanded: false,
                    groupId,
                    groupTitle,
                    nmfcItems: [searchResult]
                });
            }
        });
        if (this.matchedNmfcGroups.length) {
            this.matchedNmfcGroups[0].isExpanded = true;
            this.isNoResultsPromptDisplayed = false;
        } else if (this.hasSearchNmfcBeenRun) {
            this.isNoResultsPromptDisplayed = true;
        }
    }

    // ========================================================== UI CHANGE METHODS =========================================
    public toggleGroupIsExpanded(group: { isExpanded: boolean, groupId: number, groupTitle: string, nmfcItems: Array<NmfcSearchResult> }): void {
        group.isExpanded = !group.isExpanded;
    }
}
