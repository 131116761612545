import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import * as Constants from '../../../constants/constants';
import * as DashboardConstants from '../../../constants/dashboard.constants';
import * as IconConstants from '../../../constants/dashboardViewIcons';
import * as SearchConstants from '../../../constants/searchCriteria';
import {DashboardHelper} from '../../../helpers/dashboardHelper';
import {makeClone} from '../../../helpers/utilities';
import {Breadcrumb} from '../../../models/breadCrumb';
import {Dashboard} from '../../../models/dashboard';
import {DashboardView} from '../../../models/dashboardView';
import {SearchCriterion} from '../../../models/searchCriterion';
import {SearchState} from '../../../models/searchState';
import {LoggingService, LogLevels} from '../../../services/logging.service';
import {BreadcrumbService} from "../../../services/breadcrumb.service";
import {SignalsService} from "../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import { SearchService } from 'app/services/search.service';
import {NotificationService} from "../../../services/notification.service";
import {DashboardService} from "../../../services/dashboard.service";
import {AppStateService} from "../../../services/app-state.service";

@Component({
    selector: 'app-create-view',
    styleUrls: ['./create-view.component.scss'],
    templateUrl: './create-view.component.html'

})
export class CreateViewComponent implements OnInit, OnDestroy {
    private appStateSubscription: any;
    private dashboardsSubscription: any;
    private searchSubscription: any;
    private displayDataSubscription: any;
    private dashboardLibraryViewSubscription: any;

    private appState;
    public isViewLibraryModalVisible: boolean = false;
    public activeDashboard: Dashboard;
    public activeDashboardView: DashboardView;
    public dashboardId: number;
    public dashboardViews: Array<DashboardView>;
    public selectedViewDetails: DashboardView;
    public showViewDetailsSection: boolean = false;
    public selectedView: DashboardView;
    public collapsePanel: boolean = false;
    public newDashboardView: DashboardView;
    public selectedViewContentQuery;
    public selectedViewVisibleColumns;
    public dashboardViewColumnsArray: Array<{ checked: boolean, label: string }> = [];
    public searchResponse: SearchState;
    private dashboardsArray: Array<Dashboard> = [];
    public displayData;
    public fontAwesomeIcons: Array<{ className: string, displayName: string }> = [];
    public filterTerm: string = '';
    public showEditViewUI: boolean = false;
    public showSaveLibraryCheckbox: boolean = true;
    public shouldViewBeSavedToLibrary: boolean = true;
    public shouldViewBeSavedToDashboard: boolean = true;
    public shouldLibraryViewBeUpdated: boolean = true;
    public dashboardViewLibrary: Array<DashboardView>;
    public isIconSelectorModalVisible: boolean = false;
    public iconName: string = '';
    public maxViewNameCharacterLength = Constants.MAXIMUM_CHARACTERS_DASHBOARD_VIEW_NAME;
    public viewCategories: Array<{ name: string, isExpanded: boolean, associatedViews: Array<{ selectable: boolean, isSelected: boolean, view: DashboardView }> }> = [];
    private remainingAvailableViews: number;
    public contentQueryViewType: string = Constants.DashboardViewType_CONTENT_QUERY;
    public templateQueryViewType: string = Constants.DashboardViewType_TEMPLATE_QUERY;
    public dashboardViewCategories: {
        user_created: string,
        global_views: string,
        record_management: string,
        quote_management: string
    } = DashboardConstants.DASHBOARD_VIEW_CATEGORY_NAMES;
    public viewLibraryButtons: {
        isEditViewButtonVisible: boolean,
        isCreateViewBySearchButtonVisible: boolean,
        isDeleteViewFromLibraryButtonVisible: boolean,
        isSaveViewFromLibraryButtonVisible: boolean
    } = {
        isEditViewButtonVisible: false,
        isCreateViewBySearchButtonVisible: true,
        isDeleteViewFromLibraryButtonVisible: false,
        isSaveViewFromLibraryButtonVisible: false
    };
    public isEditedViewTemplateQuery: boolean = false;

    private appState$;
    private search$;
    private displayData$;
    private dashboards$;
    private dashboardViewLibrary$;

    constructor(
        private _router: Router,
        private _signalsService: SignalsService,
        private _breadcrumbService: BreadcrumbService,
        private _dashboardService: DashboardService,
        private _notificationService: NotificationService,
        private _appStateService: AppStateService,
        private _dashboardHelper: DashboardHelper,
        private _searchService: SearchService,
        private _loggingService: LoggingService,
    ) {
        this.appState$ = toObservable(this._signalsService.appStateSignal);
        this.search$ = toObservable(this._signalsService.searchStateSignal);
        this.displayData$ = toObservable(this._signalsService.displaySignal);
        this.dashboards$ = toObservable(this._signalsService.dashboardsSignal);
        this.dashboardViewLibrary$ = toObservable(this._signalsService.dashboardViewLibrarySignal);
    }

    ngOnInit() {
        this.displayDataSubscription = this.displayData$.subscribe(displayData => {
            this.displayData = displayData;
        });

        this.dashboardLibraryViewSubscription = this.dashboardViewLibrary$.subscribe((dashboardViewLibrary: Array<DashboardView>) => {
            if (dashboardViewLibrary) {
                this.dashboardViewLibrary = dashboardViewLibrary;
            }

            this.setViewLibraryCategories();
            this.setViewLibraryButtons();
        });

        this.dashboardsSubscription = this.dashboards$.subscribe((dashboards: Array<Dashboard>) => {
            this.dashboardsArray = dashboards;
            const dashboardViews = [];
            if (this.dashboardsArray) {
                this.dashboardsArray.forEach((dashboard: Dashboard) => {
                    dashboard.views.forEach((dV: DashboardView) => {
                        const viewIndex = dashboardViews.findIndex((view: DashboardView) => view.id === dV.id);
                        if (viewIndex === -1) {
                            dashboardViews.push(dV);
                        }
                    });
                });
            }

            this.dashboardViews = dashboardViews;
        });

        this.appStateSubscription = this.appState$.subscribe(appState => {
            this.appState = appState;
            this.showEditViewUI = this.appState['dashboard.editDashboardView'];

            if (appState['dashboard.activeDashboardId'] && this.dashboardsArray.length) {
                this.activeDashboard = this._dashboardHelper.getActiveDashboard();
                this.setViewLibraryCategories();
                if (this.activeDashboard) {
                    this.dashboardId = this.activeDashboard.id;
                }
                if (this.activeDashboard && this.activeDashboard.views) {
                    this.activeDashboardView = this._dashboardHelper.getActiveDashboardView();
                }

            }
            if (this.showEditViewUI) {
                this.setNewDashboardView();

                if (this.newDashboardView) {
                    this.setNewDashboardViewFilterTerm();
                }

                this.showSaveLibraryCheckbox = false;
            }
        });

        this.searchSubscription = this.search$.subscribe((searchResponse: SearchState) => {
            this.setNewDashboardView();

            if (this.newDashboardView) {
                this.setNewDashboardViewFilterTerm();
            }

            this.setViewDetailColumns();
        });

        this.fontAwesomeIcons = IconConstants.FONT_AWESOME_ICONS;
        this.setIconDisplayName();
        this.isViewLibraryModalVisible = true;

        this._dashboardService.getAllLibraryViews();

    }

    ngOnDestroy() {
        this.displayDataSubscription.unsubscribe();
        this.dashboardsSubscription.unsubscribe();
        this.searchSubscription.unsubscribe();
        this.appStateSubscription.unsubscribe();
        this._signalsService.updateAppState({'search.createDashboardView': false});
        this._searchService.loadDashboardViewQuery(null);
        if (!this.appState['dashboard.editDashboardViewSearch']) {
            this._signalsService.updateAppState(
                {
                    'dashboard.editDashboardView': false,
                    'dashboard.editDashboardViewId': null
                }
            );
        }
    }

    public setViewDetailColumns(): void {
        let newColumn;
        if (this.newDashboardView && this.filterTerm) {
            const defaultColumns = Object.keys(this.displayData.searchEntityFilters[this.filterTerm]);
            this.dashboardViewColumnsArray = defaultColumns.map(column => {
                if (this.newDashboardView.visibleColumns.find(dVColumn => dVColumn === column)) {
                    newColumn = {
                        checked: true,
                        label: column
                    };
                } else {
                    newColumn = {
                        checked: false,
                        label: column
                    };
                }
                return newColumn;
            });
        }
    }

    public cancelViewCreation() {
        this._signalsService.updateAppState({
            'search.createDashboardView': false,
            'dashboard.editDashboardView': false,
            'dashboard.editDashboardViewId': null,
            'dashboard.editDashboardViewSearch': false
        });

        this._searchService.completeDashboardViewSearch();
    }

    public navigateHome() {
        this.cancelViewCreation();
        this._router.navigate(['']);
    }

    // ==================================================================
    // ======================== SAVE METHODS ==============================
    // ==================================================================
    public saveSingleViewFromLibrary(selectedView: DashboardView): void {
        this.shouldViewBeSavedToDashboard = true;
        this.shouldViewBeSavedToLibrary = false;

        if (selectedView.viewType === Constants.DashboardViewType_CONTENT_QUERY) {
            this.newDashboardView = {
                id: null,
                name: selectedView.name,
                icon: selectedView.icon,
                category: Constants.DashboardView_USER_CREATED,
                editable: 1,
                visibleColumns: selectedView.visibleColumns,
                viewType: selectedView.viewType,
                viewContentQuery: selectedView.viewContentQuery,
                viewOrder: this.activeDashboard.views.length + 1,
            };

            this.newDashboardView.viewContentQuery.forEach((searchCriterion: SearchCriterion) => {
                if (searchCriterion.entityType !== SearchConstants.SEARCH_CRITERIA_entityType.ALL) {
                    this.filterTerm = searchCriterion.entityType;
                }
            });
        } else if (selectedView.viewType === Constants.DashboardViewType_TEMPLATE_QUERY) {
            this.newDashboardView = {
                id: null,
                name: selectedView.name,
                icon: selectedView.icon,
                category: Constants.DashboardView_USER_CREATED,
                editable: 1,
                visibleColumns: selectedView.visibleColumns,
                viewType: selectedView.viewType,
                templateContentQuery: selectedView.templateContentQuery,
                viewOrder: this.activeDashboard.views.length + 1,
            };

            this.filterTerm = this.newDashboardView.templateContentQuery.entityType;
        }
        this.setIconDisplayName();
    }

    public saveViewToDashboard(dashboardView: DashboardView) {
        const activeDashboardId: number = this._dashboardHelper.getActiveDashboard().id;

        if (dashboardView.id) {
            // put call
            this._dashboardService.updateDashboardView(dashboardView, activeDashboardId);
        } else {
            this._dashboardService.createDashboardView(dashboardView, activeDashboardId);
        }
    }

    public saveDashboardViewChanges(): void {
        if (!this.isNewDashboardValid()) {
            return;
        }

        if (this.isLibraryViewUpdateNeeded()) {
            this.updateLibraryView(this.prepareViewUpdateForLibrary());
        }

        if (this.shouldViewBeSavedToDashboard) {

            this.saveViewToDashboard(this.prepareDashboardView());
        }

        if (this.isLibraryViewSaveNeeded()) {
            this.saveViewToLibrary(this.prepareViewSaveForLibrary());
        }

        this.cancelViewCreation();
    }

    private isLibraryViewUpdateNeeded(): boolean {
        if (!this.shouldLibraryViewBeUpdated || !this.isViewInLibrary()) {
            return false;
        }

        return true;
    }

    private isLibraryViewSaveNeeded(): boolean {
        if (!this.shouldViewBeSavedToLibrary || this.isViewInLibrary()) {
            return false;
        }

        return true;
    }

    private prepareViewUpdateForLibrary(): DashboardView {
        if (!this.getNewDashboardViewFromViewLibrary()) {
            return;
        }

        const updatedViewForLibrary: DashboardView = {
            ...this.getNewDashboardViewFromViewLibrary(),
            name: this.newDashboardView.name,
            icon: this.newDashboardView.icon,
            visibleColumns: this.getSelectedViewColumns(),
        };

        return updatedViewForLibrary;
    }

    private prepareViewSaveForLibrary(): DashboardView {
        const newViewForLibrary: DashboardView = {
            ...this.newDashboardView,
            id: null,
            category: DashboardConstants.DASHBOARD_VIEW_CATEGORY_NAMES.user_created,
            viewType: DashboardConstants.DASHBOARD_VIEW_TYPES.content_query,
            editable: 1,
            visibleColumns: this.getSelectedViewColumns(),
        }

        return newViewForLibrary;
    }

    private prepareDashboardView(): DashboardView {
        if (this.isEditedViewTemplateQuery) {
            const prepareDashboardView: DashboardView = {
                ...this.newDashboardView,
                id: this.getNewDashboardViewFromActiveDashboard() ? this.newDashboardView.id : null,
                category: DashboardConstants.DASHBOARD_VIEW_CATEGORY_NAMES.global_views,
                editable: 1,
                visibleColumns: this.maintainExistingColumnOrder(this.getSelectedViewColumns()),
                viewType: DashboardConstants.DASHBOARD_VIEW_TYPES.template_query,
                viewOrder: this.newDashboardView.viewOrder
            }

            return prepareDashboardView;
        } else {
            const preparedDashboardView: DashboardView = {
                ...this.newDashboardView,
                id: this.getNewDashboardViewFromActiveDashboard() ? this.newDashboardView.id : null,
                category: DashboardConstants.DASHBOARD_VIEW_CATEGORY_NAMES.user_created,
                editable: 1,
                visibleColumns: this.maintainExistingColumnOrder(this.getSelectedViewColumns()),
                viewType: DashboardConstants.DASHBOARD_VIEW_TYPES.content_query,
                viewOrder: this._dashboardHelper.getActiveDashboard().views.length + 1,
            };

            return preparedDashboardView;
        }

    }

    private maintainExistingColumnOrder(activeList: Array<string>) {
        const finalList = [];
        let colLabel;

        if (!this.newDashboardView) {
            return activeList;
        }

        for (colLabel of this.newDashboardView.visibleColumns) {
            if (activeList.find(lbl => lbl === colLabel)) {
                finalList.push(colLabel);
            }
        }

        for (colLabel of activeList) {
            if (!finalList.find(lbl => lbl === colLabel)) {
                finalList.push(colLabel);
            }
        }

        return finalList;
    }

    private saveViewToLibrary(dashboardView: DashboardView): void {
        this._dashboardService.saveViewToDashboardViewLibrary(dashboardView);
    }

    private updateLibraryView(dashboardView: DashboardView): void {
        this._dashboardService.editLibraryView(dashboardView);
    }

    private getSelectedViewColumns(): Array<string> {
        return this.dashboardViewColumnsArray
            .filter((dashCol: { checked: boolean, label: string }) => dashCol.checked)
            .map((dashCol: { checked: boolean, label: string }): string => dashCol.label);
    }

    private isNewDashboardValid(): boolean {
        if (!this.newDashboardView) {
            this._notificationService.notifyError({
                title: `Create View Error`,
                message: `Please create a new view before saving`
            });
            return false;
        } else if (!this.newDashboardView.name && !this.newDashboardView.icon) {
            this._notificationService.notifyError({
                title: 'Create View Error',
                message: 'Please enter a name and icon for your dashboard view'
            });
            return false;
        } else if (!this.newDashboardView.name) {
            this._notificationService.notifyError({
                title: 'Create View Error',
                message: 'Please enter a name for your dashboard view'
            });
            return false;
        } else if (!this.newDashboardView.icon) {
            this._notificationService.notifyError({
                title: 'Create View Error',
                message: 'Please select a icon for your dashboard view'
            });
            return false;
        } else if (this.newDashboardView && this.newDashboardView.name && this.newDashboardView.name.length > Constants.MAXIMUM_CHARACTERS_DASHBOARD_VIEW_NAME) {
            this._notificationService.notifyError({
                title: `Create View Error`,
                message: `Your view name '${this.newDashboardView.name}' has more than ${Constants.MAXIMUM_CHARACTERS_DASHBOARD_VIEW_NAME} characters. Please shorten and try again.`
            })
            return false;
        } else if (!this.shouldViewBeSavedToDashboard && !this.shouldViewBeSavedToLibrary) {
            this._notificationService.notifyError({
                title: `Create View Error`,
                message: `Please choose an option for how to save your view: to Dashboard and/or to My Views`
            });
            return false;
        } else if (this.isViewInLibrary()) {
            if (!this.shouldViewBeSavedToDashboard && !this.shouldLibraryViewBeUpdated) {
                this._notificationService.notifyError({
                    title: `Create View Error`,
                    message: `Please choose an option for how to save and/or update your view`
                });
                return false;
            }
        }

        return true;
    }

    // ==================================================================
    // ======================== UI METHODS ==============================
    // ==================================================================
    public navigateToSearch() {
        if (this.appState['dashboard.editDashboardView']) {
            this._signalsService.updateAppState({'dashboard.editDashboardViewSearch': true});
        }
        this._signalsService.updateAppState({'dashboard.createViewBySearch': true});
        const breadcrumbs: Array<Breadcrumb> = this.prepareBreadcrumbs();
        this._breadcrumbService.addBreadcrumb(breadcrumbs)
        this._router.navigate(['search']);
        this._searchService.clearSearchResults();
    }

    private prepareBreadcrumbs(): Array<Breadcrumb> {
        const breadcrumbs: Array<Breadcrumb> = [
            {
                label: 'Create View',
                urlValue: 'createView'
            },
            {
                label: 'Global Search'
            }
        ];

        return breadcrumbs;
    }

    public showDashboardViewLibrary() {
        this.isViewLibraryModalVisible = true;
    }

    public hideDashboardViewLibrary() {
        this.isViewLibraryModalVisible = false;
    }

    public showIconSelectorModal() {
        this.isIconSelectorModalVisible = true;
    }

    public hideIconSelectorModal() {
        this.isIconSelectorModalVisible = false;
    }

    public togglePanel() {
        this.collapsePanel = !this.collapsePanel;
    }

    public toggleDisplayColumnCheck(column) {
        if (column.checked) {
            column.checked = false;
        } else {
            column.checked = true;
        }
    }

    public toggleAddToLibraryCheck(): void {
        this.shouldViewBeSavedToLibrary = !this.shouldViewBeSavedToLibrary;
    }

    public toggleAddToDashboardCheck(): void {
        this.shouldViewBeSavedToDashboard = !this.shouldViewBeSavedToDashboard;
    }

    public toggleUpdateLibraryViewCheck(): void {
        this.shouldLibraryViewBeUpdated = !this.shouldLibraryViewBeUpdated;
    }

    public deleteViewFromDashboard() {
        if (this.showEditViewUI) {
            this._dashboardService.deleteDashboardView(this.newDashboardView, this.activeDashboard.id);
            this._router.navigate(['dashboard']);
        } else {
            const selectedViews = this.getSelectedViews();
            if (selectedViews.length === 0) {
                this._notificationService.notifyError({
                    title: `Dashboard View Library`,
                    message: `Please select a view before clicking Deleting View`
                });
                return;
            }
            let isRemovable = true;
            selectedViews.forEach((selectedView: DashboardView) => {
                if (selectedView.category !== Constants.DashboardView_USER_CREATED) {
                    this._notificationService.notifyError({
                        title: 'Dashboard View Library',
                        message: `You can only delete views in the My Views category, please unselect ${selectedView.name} and try again.`
                    });
                    isRemovable = false;
                }
            });
            if (isRemovable) {
                this._dashboardService.deleteViewFromLibrary(selectedViews);
            }
        }
    }

    public receiveIconFromSelector(icon: { className: string, displayName: string }): void {
        this.newDashboardView.icon = icon.className;
        this.iconName = icon.displayName;
    }

    public setIconDisplayName() {
        if (this.fontAwesomeIcons && this.newDashboardView && this.newDashboardView.icon) {
            for (let i = 0; i < this.fontAwesomeIcons.length; i++) {
                if (this.fontAwesomeIcons[i].className === this.newDashboardView.icon) {
                    this.iconName = this.fontAwesomeIcons[i].displayName;
                }
            }
        }
    }

    private setNewDashboardView(): void {
        this.newDashboardView =
            this.getNewDashboardViewFromActiveDashboard()
            || this.getNewDashboardViewFromViewLibrary()
            || this.getNewDashboardFromSearch();
    }

    private getNewDashboardViewFromViewLibrary(): DashboardView {
        if (!this.dashboardViewLibrary) {
            return;
        }

        let newDashboardView = this.dashboardViewLibrary.find((view: DashboardView) => view.id === this.appState['dashboard.editDashboardViewId']);

        if (newDashboardView) {
            newDashboardView = makeClone(newDashboardView);
        }

        return newDashboardView;
    }

    private getNewDashboardViewFromActiveDashboard(): DashboardView {
        if (!this.activeDashboard || !this.activeDashboard.views) {
            return;
        }

        let newDashboardView = this.activeDashboard.views.find((view: DashboardView) => view.id === this.appState['dashboard.editDashboardViewId']);

        if (newDashboardView && newDashboardView.viewType && newDashboardView.viewType === DashboardConstants.DASHBOARD_VIEW_TYPES.template_query) {
            this.isEditedViewTemplateQuery = true;
            this.shouldLibraryViewBeUpdated = false;
            this.shouldViewBeSavedToLibrary = false;
        }

        if (newDashboardView) {
            newDashboardView = makeClone(newDashboardView);
        }

        return newDashboardView;
    }

    private getNewDashboardFromSearch(): DashboardView {
        const searchState: SearchState = this.searchResponse || this._searchService.getSearchState();
        const dashboardViewQuery: Array<SearchCriterion> = searchState ? searchState.dashboardViewQuery : null;
        let newDashboardView: DashboardView;

        if (this.showEditViewUI && dashboardViewQuery) {
            newDashboardView = {
                ...this.newDashboardView,
                viewContentQuery: dashboardViewQuery,
            };
        }

        if (dashboardViewQuery && dashboardViewQuery.length && dashboardViewQuery[0].entityType !== SearchConstants.SEARCH_CRITERIA_entityType.ALL) {
            this.filterTerm = dashboardViewQuery[0].entityType;
        }

        if (this.filterTerm) {
            const visibleColumns = Object.keys(this.displayData.searchEntityColumns[this.filterTerm]);

            newDashboardView = {
                id: null,
                name: '',
                icon: '',
                category: DashboardConstants.DASHBOARD_VIEW_CATEGORY_NAMES.user_created,
                editable: 1,
                visibleColumns: visibleColumns,
                viewType: DashboardConstants.DASHBOARD_VIEW_TYPES.content_query,
                viewContentQuery: dashboardViewQuery,
                viewOrder: this._dashboardHelper.getActiveDashboard().views.length + 1,
            };
        }

        return newDashboardView;
    }

    private setNewDashboardViewFilterTerm(): void {
        if (!this.newDashboardView) {
            return;
        }

        if (this.newDashboardView.viewType === Constants.DashboardViewType_TEMPLATE_QUERY && this.newDashboardView.templateContentQuery) {
            this.filterTerm = this.newDashboardView.templateContentQuery.entityType;
        } else if (this.newDashboardView.viewType === Constants.DashboardViewType_CONTENT_QUERY && this.newDashboardView.viewContentQuery) {
            this.filterTerm = this.newDashboardView.viewContentQuery[0].entityType;
        } else {
            this._loggingService.sendLogMessage(LogLevels.ERROR, `invalid dashboardView viewType provided to Create View component, appState subscription`);
        }

        this.setViewDetailColumns();
    }

    // ===============================================================
    // ===================== VIEW LIBRARY METHODS =====================
    // ===============================================================

    private setViewLibraryCategories(): void {
        if (this.activeDashboard && this.activeDashboard.views && this.activeDashboard.views.length && this.dashboardViewLibrary && this.dashboardViewLibrary.length) {
            this.remainingAvailableViews = Constants.MAXIMUM_ALLOWABLE_DASHBOARD_VIEWS - this.activeDashboard.views.length;
            const globalSearchIndex = this.activeDashboard.views.findIndex((view: DashboardView) => view.viewType === Constants.DashboardViewType_GLOBAL_SEARCH);
            this.viewCategories = [];

            this.dashboardViewLibrary.forEach((view, index) => {
                if (globalSearchIndex !== -1 && view.viewType === Constants.DashboardViewType_GLOBAL_SEARCH) {
                    return;
                }
                if (view.viewType === Constants.DashboardViewType_CREATE_VIEW) {
                    return;
                }

                const categoryIndex = this.viewCategories.findIndex(category => category.name === view.category);
                if (categoryIndex === -1) {
                    this.viewCategories.push({
                        name: view.category,
                        associatedViews: [{
                            selectable: true,
                            isSelected: false,
                            view: view
                        }],
                        isExpanded: true
                    });
                } else {
                    this.viewCategories[categoryIndex].associatedViews.push({
                        selectable: true,
                        isSelected: false,
                        view: view
                    });
                }
            });

            if (this.viewCategories.length) {
                const myViewIndex = this.viewCategories.findIndex(category => category.name === Constants.DashboardView_USER_CREATED);
                if (myViewIndex !== 0 && myViewIndex !== -1) {
                    const myViews = this.viewCategories[myViewIndex];
                    this.viewCategories.splice(myViewIndex, 1);
                    this.viewCategories.unshift(myViews);
                }
            }
        }
    }

    public toggleViewSelected(viewType: { selectable: boolean, isSelected: boolean, view: DashboardView }) {
        if (viewType.isSelected) {
            this.remainingAvailableViews++;
        } else {
            this.remainingAvailableViews--;
        }
        viewType.isSelected = !viewType.isSelected;

        if (this.remainingAvailableViews === 0) {
            this.viewCategories.forEach((category) => {
                category.associatedViews.forEach((libraryView: { view: DashboardView, selectable: boolean, isSelected: boolean }) => {
                    libraryView.selectable = libraryView.isSelected ? true : false;
                });
            });
        } else if (this.remainingAvailableViews === 1) {
            this.viewCategories.forEach((category) => {
                category.associatedViews.forEach((libraryView: { view: DashboardView, selectable: boolean, isSelected: boolean }) => {
                    libraryView.selectable = true;
                });
            });
        }

        this.setViewLibraryButtons();
    }

    private setViewLibraryButtons(): void {
        const selectedViews: Array<DashboardView> = this.getSelectedViews();
        let doesContainUneditableViews: boolean;

        selectedViews.forEach((view: DashboardView) => {
            if (view.category !== DashboardConstants.DASHBOARD_VIEW_CATEGORY_NAMES.user_created) {
                doesContainUneditableViews = true;
                return;
            }
        });

        this.viewLibraryButtons.isCreateViewBySearchButtonVisible = !selectedViews.length;
        this.viewLibraryButtons.isEditViewButtonVisible = selectedViews.length === 1 && !doesContainUneditableViews;
        this.viewLibraryButtons.isDeleteViewFromLibraryButtonVisible = selectedViews.length && !doesContainUneditableViews;
        this.viewLibraryButtons.isSaveViewFromLibraryButtonVisible = !!selectedViews.length;
    }

    public toggleViewCategoryExpansion(viewCategory: { name: string, isExpanded: boolean, associatedViews: Array<{ selectable: boolean, isSelected: boolean, view: DashboardView }> }): void {
        viewCategory.isExpanded = !viewCategory.isExpanded;
    }

    public editViewFromLibrary(): void {
        const selectedViews: Array<DashboardView> = this.getSelectedViews();
        if (selectedViews.length !== 1) {
            // NOTE(Hassan & Sorum) 05/15/18 - User should never get here, logic from above method should prevent this, but jic...
            this._notificationService.notifyError({
                title: 'Edit Dashboard View',
                message: 'You can only edit one dashboard view at a time'
            });
            return;
        }

        const dashboardViewToEdit: DashboardView = selectedViews[0];
        this._signalsService.updateAppState({
            'dashboard.editDashboardView': true,
            'dashboard.editDashboardViewId': dashboardViewToEdit.id
        });
    }

    private getSelectedViews(): Array<DashboardView> {
        const selectedViews: Array<DashboardView> = [];

        this.viewCategories.forEach(viewCat => {
            viewCat.associatedViews.forEach(associatedView => {
                if (associatedView.isSelected) {
                    selectedViews.push(associatedView.view);
                }
            });
        });

        return selectedViews;
    }

    public saveViewsFromLibrary() {
        const selectedViews = this.getSelectedViews();
        const activeDashboard = this._dashboardHelper.getActiveDashboard();

        if (!selectedViews.length) {
            this._notificationService.notifyError({
                title: `Dashboard View Library`,
                message: `Please select a view before clicking Create View`
            });
        } else {
            const sortedActiveDashboardViews = activeDashboard.views.slice().sort((firstView, secondView) => firstView.viewOrder - secondView.viewOrder);
            const globalSearchIndex = sortedActiveDashboardViews.findIndex((dbV: DashboardView) => dbV.viewType === Constants.DashboardViewType_GLOBAL_SEARCH);
            const createViewIndex = sortedActiveDashboardViews.findIndex((dBV: DashboardView) => dBV.viewType === Constants.DashboardViewType_CREATE_VIEW);
            let createdViewViewOrder = (globalSearchIndex !== -1 ? globalSearchIndex + 1 : createViewIndex + 1);

            if (selectedViews.length > 1) {
                selectedViews.forEach((view: DashboardView) => {
                    view.viewOrder = ++createdViewViewOrder;
                    this._dashboardService.createDashboardView(view, this.dashboardId);
                });
            } else if (selectedViews[0].viewType === DashboardConstants.DASHBOARD_VIEW_TYPES.global_search) {
                selectedViews[0].viewOrder = Constants.DashboardView_GLOBAL_SEARCH_VIEW_ORDER;
                this._dashboardService.createDashboardView(selectedViews[0], this.dashboardId);
            } else {
                selectedViews[0].viewOrder = createdViewViewOrder + 1;
                this._dashboardService.createDashboardView(selectedViews[0], this.dashboardId);
            }
        }
    }

    public isViewInLibrary(): boolean {
        if (!this.newDashboardView || !this.newDashboardView.id || !this.dashboardViewLibrary) {
            return false;
        }

        return !!this.dashboardViewLibrary.find((view: DashboardView) => view.id === this.newDashboardView.id);
    }

    public isViewFromSearch(): boolean {
        const appState = this.appState || this._appStateService.getAppState();

        return appState['search.createDashboardView'];
    }

    public isViewInActiveDashboard(): boolean {
        const activeDashboard = this.activeDashboard || this._dashboardHelper.getActiveDashboard();

        if (!this.newDashboardView || !this.newDashboardView.id || !activeDashboard) {
            return false;
        }

        return !!activeDashboard.views.find((view: DashboardView) => view.id === this.newDashboardView.id);
    }
}
