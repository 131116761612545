import {
    Component,
    ElementRef,
    EventEmitter,
    Input, Output, ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { map } from 'rxjs/operators';
import {ContactSearchResult} from '../../models/contact.searchResult';
import * as SearchConstants from '../../constants/searchCriteria';
import {ZipSearchResult} from '../../models/zip.searchResult';
import {SearchService} from "../../services/search.service";

@Component({
    selector: 'app-contact-search',
    styleUrls: ['./app-contact-search.component.scss'],
    templateUrl: './app-contact-search.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppContactSearchComponent,
            multi: true
        }
    ]
})
export class AppContactSearchComponent implements ControlValueAccessor {
    @Input()
    public country: string = 'US';
    @Input()
    public contactType: string = '';
    @Input()
    public contactField: string = '';
    @Input()
    public modalTitle: string = '';
    @Input()
    public readOnly: boolean = false;
    @Input()
    public enableDialog: boolean = true;
    @Output()
    public dialogShown = new EventEmitter();
    @Output()
    public contactUpdate = new EventEmitter<ContactSearchResult>();
    @Output()
    public zipUpdate = new EventEmitter<ZipSearchResult>();
    @ViewChild('inputElement', { static: true })
    private focusElement: ElementRef;

    public isSearchModalShown = false;
    public contactFields = SearchConstants.SEARCH_CRITERIA_type;
    public value: string = '';

    constructor( private _searchService: SearchService ) {
    }

    public writeValue(value: string) {
        this.value = value;
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public hideSearchModal(selectedResult: ContactSearchResult): void {
        if (selectedResult) {
            this.value = selectedResult[this.contactField];
            this.valueChanged(this.value);
        }
        this.dialogShown.emit(false);
        this.contactUpdate.emit(selectedResult);
        this.isSearchModalShown = false;
        this.focusElement.nativeElement.focus();
    }

    public showDialog() {
        this.dialogShown.emit(true);
        this.isSearchModalShown = true;
        this.value = this.focusElement.nativeElement.value;
    }

    public getContactFieldValue() {
        return this.value;
    }

    public searchValueChanged($event) {
        this.value = $event.target.value;

        if (this.contactField === this.contactFields.ZIP) {
            this._searchService.zipSearchUSDefault(this.value, this.country).pipe(
                map((response) => {
                    let entity: ZipSearchResult;
                    if (response && response.isValid && response.dto &&
                        response.dto.searchResults &&
                        response.dto.searchResults.length &&
                        response.dto.searchResults[0].entities &&
                        response.dto.searchResults[0].entities.length) {
                        entity = response.dto.searchResults[0].entities[0];
                        this.zipUpdate.emit(entity);
                        this.value = entity.zip_code.toString();
                    }
                    this.valueChanged(this.value);
                })
            ).subscribe();
        } else {
            this.valueChanged(this.value);
        }
    }

    private valueChanged = (_: any) => {
    };
}
