import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import * as SearchConstants from '../../../constants/searchCriteria';
import {Country} from '../../../models/country';
import {SearchCriterion} from '../../../models/searchCriterion';
import {SearchRequest} from '../../../models/searchRequest';
import {ZipSearchResult} from '../../../models/zip.searchResult';
import {CommonDataService} from "../../../services/commonData.service";
import {StateMasterData} from "../../../models/state.masterData";
import {SearchService} from "../../../services/search.service";

@Component({
    selector: 'app-zip-search-modal',
    templateUrl: './zip-search-modal.component.html',
    styleUrls: ['./zip-search-modal.component.scss']
})
export class ZipSearchModalComponent implements OnInit, AfterViewInit {
    @Input() public title: string = '';
    @Input() public zipType: string = '';
    @Input() public countryCode: string = '';
    @Output() public closeZipModal = new EventEmitter<ZipSearchResult>();
    @ViewChild('queryField', { static: true }) queryField;

    public states: Array<string> = [];
    public countries: Array<Country> = [];

    public cityQuery: string = '';
    public selectedState: string = '';

    private cityZipSearchCriterion: SearchCriterion;
    private stateZipSearchCriterion: SearchCriterion;
    private countryZipSearchCriterion: SearchCriterion;

    public zipResults: Array<ZipSearchResult> = [];
    public isNoResultsPromptDisplayed: boolean = false;

    constructor(private _searchService: SearchService,
                private _commonDataService: CommonDataService) {}

    public ngAfterViewInit() {
        this.queryField.nativeElement.focus();
    }

    public async ngOnInit() {
        this.states = (await this._commonDataService.loadedPromise(this._commonDataService.states)).map((state: StateMasterData) => state.Code);
        this.countries = await this._commonDataService.loadedPromise(this._commonDataService.countries);
    }

    public closeModal(selectedZipResult?: ZipSearchResult): void {
        if (selectedZipResult) {
            this.closeZipModal.emit(selectedZipResult);
        }
        this.closeZipModal.emit(null);
    }

    private prepareZipSearchRequest(): SearchRequest {
        const zipCodesSearchRequest: SearchRequest = {
            searchCriteria: []
        }
        if (this.cityQuery) {
            this.cityZipSearchCriterion = {
                type: SearchConstants.SEARCH_CRITERIA_type.CITY,
                value: this.cityQuery,
                entityType: SearchConstants.SEARCH_CRITERIA_entityType.ZIP_POSTAL,
                boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
            };

            zipCodesSearchRequest.searchCriteria.push(this.cityZipSearchCriterion);
        }

        if (this.selectedState) {
            this.stateZipSearchCriterion = {
                type: SearchConstants.SEARCH_CRITERIA_type.STATE,
                value: this.selectedState,
                entityType: SearchConstants.SEARCH_CRITERIA_entityType.ZIP_POSTAL,
                boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
            }

            zipCodesSearchRequest.searchCriteria.push(this.stateZipSearchCriterion);
        }

        if (this.countryCode) {
            this.countryZipSearchCriterion = {
                type: SearchConstants.SEARCH_CRITERIA_type.COUNTRY,
                value: this.countryCode,
                entityType: SearchConstants.SEARCH_CRITERIA_entityType.ZIP_POSTAL,
                boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
            }

            zipCodesSearchRequest.searchCriteria.push(this.countryZipSearchCriterion);
        }

        return zipCodesSearchRequest;
    }

    public searchZipCodes(): void {
        if (!this.cityQuery && !this.selectedState && !this.countryCode) {
            return;
        }
        const zipCodesSearchRequest = this.prepareZipSearchRequest();
        this._searchService.processZipSearch(zipCodesSearchRequest)
            .subscribe(response => {
                if (response.isValid) {
                    if (response.dto.searchResults.length) {
                        this.isNoResultsPromptDisplayed = false;
                        this.zipResults = response.dto.searchResults[0].entities.sort((zipResult1: ZipSearchResult, zipResult2: ZipSearchResult) => {
                            if (zipResult1.zip_code < zipResult2.zip_code) {
                                return -1;
                            }
                            if (zipResult1.zip_code > zipResult2.zip_code) {
                                return 1;
                            }
                            return 0;
                        });
                    } else {
                        this.isNoResultsPromptDisplayed = true;
                        this.zipResults = [];
                    }
                }
            })
    }

    public selectZipResult(selectedZipResult: ZipSearchResult): void {
        this.closeModal(selectedZipResult);
    }

}
