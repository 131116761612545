import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ADMIN_ROUTES } from './app-admin.routes';

@NgModule({
    imports: [
        RouterModule.forChild(ADMIN_ROUTES)
    ],
    exports: [
        RouterModule
    ]
})
export class AdminModule { }
