<div class="productLinesHeader">

    <ng-container *ngIf="quickQuoteDisplay">
        <div class="weightCol headerTitles">Weight <span class="clickable blue underline" (click)="toggleUnits()">({{unitsWeight}})</span></div>

        <div class="classCol headerTitles">Class</div>

        <div class="cubeCol headerTitles">Cube <span class="clickable blue underline" (click)="toggleUnits()">({{unitsCube}})</span></div>

        <div class="calculatorCol"></div>

        <div class="piecesCol headerTitles">Qty</div>

        <div class="lengthCol headerTitles">L <span class="clickable blue underline" (click)="toggleUnits()">({{unitsDimension}})</span></div>

        <div class="widthCol headerTitles">W <span class="clickable blue underline" (click)="toggleUnits()">({{unitsDimension}})</span></div>

        <div class="heightCol headerTitles">H <span class="clickable blue underline" (click)="toggleUnits()">({{unitsDimension}})</span></div>

        <div class="nameCol headerTitles">Product</div>
    </ng-container>

    <ng-container *ngIf="!quickQuoteDisplay">
        <div class="nameCol headerTitles"><span style="font-weight: normal" class="required">*</span>Product</div>

        <div class="classCol headerTitles">Class</div>

        <div class="handlingUnitTypeCol headerTitles">Handling Unit</div>

        <div class="handlingUnitQtyCol headerTitles">Qty</div>

        <div class="packageTypeCol headerTitles">Pkg Type</div>

        <div class="piecesCol headerTitles">Qty</div>

        <div class="lengthCol headerTitles">L <span class="clickable blue underline" (click)="toggleUnits()">({{unitsDimension}})</span></div>

        <div class="widthCol headerTitles">W <span class="clickable blue underline" (click)="toggleUnits()">({{unitsDimension}})</span></div>

        <div class="heightCol headerTitles">H <span class="clickable blue underline" (click)="toggleUnits()">({{unitsDimension}})</span></div>

        <div class="cubeCol headerTitles">Cube <span class="clickable blue underline" (click)="toggleUnits()">({{unitsCube}})</span></div>

        <div class="calculatorCol"></div>

        <div class="weightCol headerTitles">Weight <span class="clickable blue underline" (click)="toggleUnits()">({{unitsWeight}})</span></div>

        <div class="stackableCol headerTitles">Stackable</div>

        <div class="densityCol headerTitles">Density <span class="clickable blue underline" (click)="toggleUnits()">({{unitsDensity}})</span></div>
    </ng-container>

    <span class="trashIconCol"></span>
</div>

<div class="productLinesData" *ngFor="let productLine of value; let i=index" >
    <div class="productLineRow">
        <app-product-line [forceAllDirty]="highlightInvalids && (i < (value.length-1))" [readOnly]="readOnly" [quickQuoteDisplay]="quickQuoteDisplay" (dialogShown)="dialogShown.emit($event)" [unitOfMeasure]="unitOfMeasure" (unitOfMeasureChanged)="updateUnitOfMeasure($event, i)" (ngModelChange)="updateProductLine($event, i)" [ngModel]="productLine" #lineModels="ngModel"></app-product-line>
        <ng-container *ngIf="!readOnly">
            <span class="trashIconCol"><i *ngIf="i !== (value.length - 1)" class="fa fa-trash blue clickable"
                                      (click)="deleteProductDetailLine(i)" aria-hidden="true"></i></span>
        </ng-container>
        <ng-container *ngIf="readOnly">
            <div class="trashIconCol"></div>
        </ng-container>
    </div>
</div>

<div class="productLineRow" style="font-weight: bold;" *ngIf="!quickQuoteDisplay">
    <div class="nameCol textOffset">Totals</div>

    <div class="classCol textOffset"></div>

    <div class="handlingUnitTypeCol textOffset"></div>

    <div class="handlingUnitQtyCol textOffset">{{productLineSum('Units') | number: '1.0-0'}}</div>

    <div class="packageTypeCol textOffset"></div>

    <div class="piecesCol textOffset">{{productLineSum('Qty') | number: '1.0-0'}}</div>

    <div class="lengthCol textOffset"></div>

    <div class="widthCol textOffset"></div>

    <div class="heightCol textOffset"></div>

    <div class="cubeCol textOffset">{{productLineSum('Cube') | number: '1.2-2'}}</div>

    <div class="calculatorCol"></div>

    <div class="weightCol textOffset">{{productLineSum('Weight') | number: '1.2-2'}}</div>

    <div class="stackableCol textOffset"></div>

    <div class="densityCol textOffset">{{(productLineSum('Weight') / productLineSum('Cube')) | number: '1.2-2'}}</div>

    <span class="trashIconCol"></span>
</div>
