import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-save-quote-solicitation-modal',
  templateUrl: './save-quote-solicititation-modal.component.html',
  styleUrls: ['./save-quote-solicititation-modal.component.scss']
})
export class SaveQuoteSolicitationModalComponent implements OnInit {
  @Output() public saveQuote = new EventEmitter();
  @Output() public onCancel = new EventEmitter();

  constructor(

  ) { }

  ngOnInit() {

  }

  public ok() {
    this.saveQuote.emit('');
  }

  public cancel() {
    this.onCancel.emit('');
  }
}
