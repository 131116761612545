import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import * as Constants from '../../../constants/constants';
import * as Defaults from '../../../constants/defaults';
import * as MasterDataConstants from '../../../constants/masterData.constants';
import {ClassRequest} from '../../../models/classRequest';
import {CubeRequest} from '../../../models/cubeRequest';
import {UnitOfMeasure} from '../../../models/unitOfMeasure';
import {LoggingService, LogLevels} from '../../../services/logging.service';
import { SignalsService } from 'app/services/signals.service';
import {CommonDataService} from "../../../services/commonData.service";
import {NotificationService} from "../../../services/notification.service";
import {ShipmentService} from "../../../services/shipment.service";

@Component({
    selector: 'app-cube-calculator-modal',
    styleUrls: ['./cube-calculator-modal.component.scss'],
    templateUrl: './cube-calculator-modal.component.html',
})
export class CubeCalculatorModalComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    public piecesType;
    @Input()
    public singleLineCalculator = false;
    @Input()
    public cubeInfo: any = {};
    @Input()
    public closeButtonName = 'Close';
    @Input()
    public getCubeInfoFn: Function;
    @Output()
    public closeCubeCalculator = new EventEmitter();
    @ViewChild('lengthField') lengthField;

    public unitOfMeasurementOptions: Array<UnitOfMeasure> = [];
    public cubeCalculatorForm: UntypedFormGroup;

    public densityClassTotal: string;
    public estimatedClass: number;
    public unitOfMeasurementLabels: {
        length: string;
        width: string;
        height: string;
        weight: string;
        volume: string;
        density: string;
    } = MasterDataConstants.CUBE_CALCULATOR_UOM_LABELS.imperial;

    private unitOfMeasurementFormControlValueChangesSubscription: any;
    private cubeLineItemsFormArrayValueChangesSubscription: any;
    private classTotalsFormGroupValueChangesSubscription: any;

    constructor(
        private _signalsService: SignalsService,
        private _notificationService: NotificationService,
        private _shipmentService: ShipmentService,
        private _fb: UntypedFormBuilder,
        private _loggingService: LoggingService,
        private _commonDataService: CommonDataService,
    ) {
    }

    public ngAfterViewInit() {
        this.lengthField.nativeElement.focus();
    }

    public ngOnInit() {
        this.unitOfMeasurementOptions = this._commonDataService.unitOfMeasures;

        this.assignComponentProperties();
    }

    public ngOnDestroy() {
        this.unitOfMeasurementFormControlValueChangesSubscription.unsubscribe();
        this.cubeLineItemsFormArrayValueChangesSubscription.unsubscribe();
        this.classTotalsFormGroupValueChangesSubscription.unsubscribe();
    }

    public getProductOfCubeAndPieceCount(cubeLineItem: UntypedFormGroup): number {
        if (!cubeLineItem || !cubeLineItem.get('cube') || !cubeLineItem.get('pieceCount')) {
            return;
        }

        const cubeFormControlValue: number = +cubeLineItem.get('cube').value;
        const pieceCountFormControlValue: number = +cubeLineItem.get('pieceCount').value;

        return cubeFormControlValue && pieceCountFormControlValue ? cubeFormControlValue * pieceCountFormControlValue : null;
    }

    public closeModal(): void {
        this.closeCubeCalculator.emit(this.formToCubeInfo());
        this._signalsService.updateAppState({'modal.isCubeCalculatorModalShown': false});
    }

    public cancelModal(): void {
        this.closeCubeCalculator.emit(null);
        this._signalsService.updateAppState({'modal.isCubeCalculatorModalShown': false});
    }

    public resetForm(): void {
        this.assignComponentProperties();
    }

    // ===========================================================================
    // ============================== SETUP METHODS ==============================
    // ===========================================================================
    private assignComponentProperties(): void {
        let cubeInfo = this.cubeInfo;
        if (this.getCubeInfoFn) {
            cubeInfo = this.getCubeInfoFn();
        }
        this.cubeCalculatorForm = this._fb.group({
            unitOfMeasurement: this.getProperty(cubeInfo, 'unitOfMeasure', Defaults.DEFAULT_UNIT_OF_MEASURE),
            cubeLineItems: this._fb.array(
                this.cubeLineItemsToFormGroups(cubeInfo)
            ),
            classTotals: this._fb.group({
                cube: new UntypedFormControl(this.getProperty(cubeInfo, 'cube'), {
                    updateOn: 'blur',
                    validators: [
                        Validators.required,
                    ]
                }),
                weight: new UntypedFormControl(this.getProperty(cubeInfo, 'weight'), {
                    updateOn: 'blur',
                    validators: [
                        Validators.required,
                    ]
                })
            })
        });
        this.calculateCubeLineItemCubeValue(true);

        this.setupCubeCalculatorFormValueChangeSubscription();
        this.calculateDensityClassTotal();
        this.calculateEstimatedClass();
    }

    private getProperty(obj, propName, defaultValue = '') {
        return (obj && obj[propName]) ? obj[propName] : defaultValue;
    }

    private toCubeLineItemFormGroup(cubeLineItem?: any): UntypedFormGroup {
        return this._fb.group({
            length: new UntypedFormControl(this.getProperty(cubeLineItem, 'length'), {
                updateOn: 'blur',
                validators: [
                    Validators.required,
                ]
            }),
            width: new UntypedFormControl(this.getProperty(cubeLineItem, 'width'), {
                updateOn: 'blur',
                validators: [
                    Validators.required,
                ]
            }),
            height: new UntypedFormControl(this.getProperty(cubeLineItem, 'height'), {
                updateOn: 'blur',
                validators: [
                    Validators.required,
                ]
            }),
            cube: '',
            pieceCount: new UntypedFormControl(this.getProperty(cubeLineItem, 'pieceCount'), {
                validators: [
                    Validators.required,
                ]
            }),
        });
    }

    private setupCubeCalculatorFormValueChangeSubscription(): void {
        if (!this.cubeCalculatorForm) {
            return;
        }

        this.cubeLineItemsFormArrayValueChangesSubscription = this.cubeCalculatorForm.get('cubeLineItems').valueChanges.subscribe(val => {
            this.addNewCubeLineItem();
            this.calculateCubeLineItemCubeValue();
            this.setClassCalculationCubeTotal();
        });

        this.classTotalsFormGroupValueChangesSubscription = this.cubeCalculatorForm.get('classTotals').valueChanges.subscribe(val => {
            this.calculateDensityClassTotal();
            this.calculateEstimatedClass();
        });

        this.unitOfMeasurementFormControlValueChangesSubscription = this.cubeCalculatorForm.get('unitOfMeasurement').valueChanges.subscribe(val => {
            this.setUnitOfMeasurementLabels();
            this.convertUnitsForCubeCalculatorForm();
        });
        this.setUnitOfMeasurementLabels();
    }

    // ===========================================================================
    // ============================== CHANGE METHODS ==============================
    // ===========================================================================
    private addNewCubeLineItem(): void {
        if (!this.cubeCalculatorForm || !this.cubeCalculatorForm.get('cubeLineItems')) {
            return;
        }

        const cubeLineItemsFormArray: UntypedFormArray = this.cubeCalculatorForm.get('cubeLineItems') as UntypedFormArray;
        const numberOfRows: number = cubeLineItemsFormArray.controls.length;
        const isLastCubeLineItemDirty: boolean = (numberOfRows ? cubeLineItemsFormArray.controls[numberOfRows - 1].dirty : true);
        const isLastCubeLineItemValid: boolean = (numberOfRows ? cubeLineItemsFormArray.controls[numberOfRows - 1].valid : true);

        if (isLastCubeLineItemDirty && isLastCubeLineItemValid && !this.singleLineCalculator) {
            const newCubeLineItem: UntypedFormGroup = this.toCubeLineItemFormGroup();
            cubeLineItemsFormArray.push(newCubeLineItem);
        }
    }

    private setUnitOfMeasurementLabels(): void {
        if (!this.cubeCalculatorForm || !this.cubeCalculatorForm.get('unitOfMeasurement')) {
            return;
        }

        const unitOfMeasurementFormControlValue = this.cubeCalculatorForm.get('unitOfMeasurement').value;

        if (unitOfMeasurementFormControlValue === Constants.UNIT_OF_MEASURE_Imperial) {
            this.unitOfMeasurementLabels = MasterDataConstants.CUBE_CALCULATOR_UOM_LABELS.imperial;
        } else if (unitOfMeasurementFormControlValue === Constants.UNIT_OF_MEASURE_Metric) {
            this.unitOfMeasurementLabels = MasterDataConstants.CUBE_CALCULATOR_UOM_LABELS.metric;
        } else {
            this._loggingService.sendLogMessage(LogLevels.ERROR, `invalid unitOfMeasurement ${unitOfMeasurementFormControlValue} in CubeCalculatorModalComponent.setUnitOfMeasurementLabels()`);
        }
    }

    private convertUnitsForCubeCalculatorForm(): void {
        if (!this.cubeCalculatorForm || !this.cubeCalculatorForm.get('unitOfMeasurement') || !this.cubeCalculatorForm.get('cubeLineItems') || !this.cubeCalculatorForm.get('classTotals')) {
            return;
        }

        const unitOfMeasurementFormControlValue: string = this.cubeCalculatorForm.get('unitOfMeasurement').value;
        const cubeLineItemsFormArray: UntypedFormArray = this.cubeCalculatorForm.get('cubeLineItems') as UntypedFormArray;
        const cubeClassTotalFormControl: UntypedFormControl = this.cubeCalculatorForm.get('classTotals.cube') as UntypedFormControl;
        const weightClassTotalFormControl: UntypedFormControl = this.cubeCalculatorForm.get('classTotals.weight') as UntypedFormControl;

        if (unitOfMeasurementFormControlValue === Constants.UNIT_OF_MEASURE_Metric) {
            this.convertUnitFormControl(cubeClassTotalFormControl, Constants.CONVERSION_FACTOR_cubicFt2cubicM);
            this.convertUnitFormControl(weightClassTotalFormControl, Constants.CONVERSION_FACTOR_lb2kg);

            cubeLineItemsFormArray.controls.forEach((cubeLineItem: UntypedFormGroup) => {
                this.convertUnitFormControl(cubeLineItem.get('length') as UntypedFormControl, Constants.CONVERSION_FACTOR_in2cm);
                this.convertUnitFormControl(cubeLineItem.get('width') as UntypedFormControl, Constants.CONVERSION_FACTOR_in2cm);
                this.convertUnitFormControl(cubeLineItem.get('height') as UntypedFormControl, Constants.CONVERSION_FACTOR_in2cm);
                this.convertUnitFormControl(cubeLineItem.get('cube') as UntypedFormControl, Constants.CONVERSION_FACTOR_cubicFt2cubicM);
            });
        } else if (unitOfMeasurementFormControlValue === Constants.UNIT_OF_MEASURE_Imperial) {
            this.convertUnitFormControl(cubeClassTotalFormControl, (1 / Constants.CONVERSION_FACTOR_cubicFt2cubicM));
            this.convertUnitFormControl(weightClassTotalFormControl, (1 / Constants.CONVERSION_FACTOR_lb2kg));

            cubeLineItemsFormArray.controls.forEach((cubeLineItem: UntypedFormGroup) => {
                this.convertUnitFormControl(cubeLineItem.get('length') as UntypedFormControl, (1 / Constants.CONVERSION_FACTOR_in2cm));
                this.convertUnitFormControl(cubeLineItem.get('width') as UntypedFormControl, (1 / Constants.CONVERSION_FACTOR_in2cm));
                this.convertUnitFormControl(cubeLineItem.get('height') as UntypedFormControl, (1 / Constants.CONVERSION_FACTOR_in2cm));
                this.convertUnitFormControl(cubeLineItem.get('cube') as UntypedFormControl, (1 / Constants.CONVERSION_FACTOR_cubicFt2cubicM));
            });
        } else {
            this._loggingService.sendLogMessage(LogLevels.ERROR, `invalid unitOfMeasurement ${unitOfMeasurementFormControlValue} in CubeCalculatorModalComponent.convertUnitsForCubeCalculatorForm()`);
        }
    }

    private convertUnitFormControl(unitFormControl: UntypedFormControl, conversionFactor: number): void {
        if (!unitFormControl || !conversionFactor || !unitFormControl.valid || !+unitFormControl.value) {
            return;
        }

        const convertedUnitFormControlValue = (unitFormControl.value * conversionFactor).toFixed(Constants.RECORD_DISPLAY_STYLE_DECIMAL_PLACES);
        unitFormControl.setValue(convertedUnitFormControlValue);
    }

    private calculateCubeLineItemCubeValue(forceRequest = false): void {
        if (!this.cubeCalculatorForm || !this.cubeCalculatorForm.get('cubeLineItems') || !this.cubeCalculatorForm.get('unitOfMeasurement')) {
            return;
        }

        const cubeLineItemsFormArray: UntypedFormArray = this.cubeCalculatorForm.get('cubeLineItems') as UntypedFormArray;
        const unitOfMeasurementFormControlValue = this.cubeCalculatorForm.get('unitOfMeasurement').value;

        cubeLineItemsFormArray.controls.forEach((cubeLineItem: UntypedFormGroup) => {
            const lengthFormControl: UntypedFormControl = cubeLineItem.get('length') as UntypedFormControl;
            const widthFormControl: UntypedFormControl = cubeLineItem.get('width') as UntypedFormControl;
            const heightFormControl: UntypedFormControl = cubeLineItem.get('height') as UntypedFormControl;

            const areLengthWidthHeightFormControlsValid: boolean = lengthFormControl.valid && widthFormControl.valid && heightFormControl.valid;
            const isSingleCubeLineItemFormControlDirty: boolean = lengthFormControl.dirty || widthFormControl.dirty || heightFormControl.dirty;

            if (!areLengthWidthHeightFormControlsValid || (!forceRequest && !isSingleCubeLineItemFormControlDirty)) {
                return;
            }

            const cubeRequest: CubeRequest = {
                length: lengthFormControl.value,
                width: widthFormControl.value,
                height: heightFormControl.value,
                piece_count: '1',
                unitmeasurement: unitOfMeasurementFormControlValue
            };

            this._shipmentService.updateProductCube(cubeRequest)
                .subscribe(response => {
                    if (response && response.isValid && response.dto) {
                        cubeLineItem.get('cube').setValue(response.dto.toFixed(Constants.RECORD_DISPLAY_STYLE_DECIMAL_PLACES));

                        lengthFormControl.markAsPristine();
                        widthFormControl.markAsPristine();
                        heightFormControl.markAsPristine();
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({
                            response,
                            title: 'Common Cube'
                        });
                    }
                })
        });
    }

    private calculateDensityClassTotal(): void {
        if (!this.cubeCalculatorForm || !this.cubeCalculatorForm.get('classTotals') || !this.cubeCalculatorForm.get('classTotals.cube') || !this.cubeCalculatorForm.get('classTotals.weight')) {
            return;
        }

        const classTotalsFormGroup: UntypedFormGroup = this.cubeCalculatorForm.get('classTotals') as UntypedFormGroup;
        const cubeClassTotalFormControlValue: number = this.cubeCalculatorForm.get('classTotals.cube').value;
        const weightClassTotalFormControlValue: number = this.cubeCalculatorForm.get('classTotals.weight').value;

        if (classTotalsFormGroup.valid && cubeClassTotalFormControlValue && weightClassTotalFormControlValue) {
            this.densityClassTotal = (weightClassTotalFormControlValue / cubeClassTotalFormControlValue).toFixed(Constants.RECORD_DISPLAY_STYLE_DECIMAL_PLACES);
        }
    }

    private calculateEstimatedClass(): void {
        if (!this.cubeCalculatorForm || !this.cubeCalculatorForm.get('unitOfMeasurement') || !this.cubeCalculatorForm.get('classTotals') || !this.cubeCalculatorForm.get('classTotals.cube') || !this.cubeCalculatorForm.get('classTotals.weight')) {
            return;
        }

        const classTotalsFormGroup: UntypedFormGroup = this.cubeCalculatorForm.get('classTotals') as UntypedFormGroup;
        const cubeClassTotalFormControlValue: number = +this.cubeCalculatorForm.get('classTotals.cube').value;
        const weightClassTotalFormControlValue: number = +this.cubeCalculatorForm.get('classTotals.weight').value;
        const unitOfMeasurementFormControlValue = this.cubeCalculatorForm.get('unitOfMeasurement').value;

        if (classTotalsFormGroup.valid && cubeClassTotalFormControlValue && weightClassTotalFormControlValue) {
            const classEstimateRequest: ClassRequest = {
                cube: cubeClassTotalFormControlValue,
                weight: weightClassTotalFormControlValue,
                unitmeasurement: unitOfMeasurementFormControlValue,
            };

            this._commonDataService.getClassEstimate(classEstimateRequest)
                .subscribe(response => {
                    if (response && response.isValid && response.dto) {
                        this.estimatedClass = response.dto.class;
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({
                            response,
                            title: `Class Estimate`
                        });
                    }
                })
        }
    }

    private setClassCalculationCubeTotal(): void {
        if (!this.cubeCalculatorForm || !this.cubeCalculatorForm.get('cubeLineItems')) {
            return;
        }

        const cubeLineItemsFormArray: UntypedFormArray = this.cubeCalculatorForm.get('cubeLineItems') as UntypedFormArray;
        const cubeClassTotalFormControl: UntypedFormControl = this.cubeCalculatorForm.get('classTotals.cube') as UntypedFormControl;
        const cubeClassTotalSum: number = cubeLineItemsFormArray.controls.reduce((sum: number, cubeLineItem: UntypedFormGroup) => sum + this.getProductOfCubeAndPieceCount(cubeLineItem), 0);

        cubeClassTotalFormControl.setValue(cubeClassTotalSum ? cubeClassTotalSum.toFixed(Constants.RECORD_DISPLAY_STYLE_DECIMAL_PLACES) : '');
    }

    // ===========================================================================
    // ================================= UI METHODS ==============================
    // ===========================================================================
    private formToCubeInfo() {
        let cubeLineItem;
        const cubeLineItems = (this.cubeCalculatorForm.get('cubeLineItems') as UntypedFormArray).controls;
        let length;
        let width;
        let height;
        let pieceCount;
        const cubeInfo: any = {
            unitOfMeasure: this.cubeCalculatorForm.get('unitOfMeasurement').value,
        };
        if (this.cubeCalculatorForm.get('classTotals').value.weight) {
            cubeInfo.weight = this.cubeCalculatorForm.get('classTotals').value.weight;
        }
        if (this.cubeCalculatorForm.get('classTotals').value.cube) {
            cubeInfo.cube = this.cubeCalculatorForm.get('classTotals').value.cube;
        }
        if (this.densityClassTotal) {
            cubeInfo.densityClassTotal = this.densityClassTotal;
        }
        if (this.estimatedClass) {
            cubeInfo.estimatedClass = this.estimatedClass;
        }

        if (cubeLineItems.length) {
            for (cubeLineItem of cubeLineItems) {
                length = cubeLineItem.get('length').value;
                width = cubeLineItem.get('width').value;
                height = cubeLineItem.get('height').value;
                pieceCount = cubeLineItem.get('pieceCount').value;

                if (length || width || height || pieceCount) {
                    if (!cubeInfo.cubeLineItems) {
                        cubeInfo.cubeLineItems = [];
                    }
                    cubeInfo.cubeLineItems.push({
                        length,
                        width,
                        height,
                        pieceCount,
                    })
                }
            }
        }

        return cubeInfo;
    }

    private cubeLineItemsToFormGroups(cubeInfo) {
        let cubeLineItem;
        const cubeLineItems = [];
        if (cubeInfo.cubeLineItems && cubeInfo.cubeLineItems.length) {
            for (cubeLineItem of cubeInfo.cubeLineItems) {
                cubeLineItems.push(this.toCubeLineItemFormGroup(cubeLineItem));
            }
        }
        if (!this.singleLineCalculator || !cubeInfo.cubeLineItems || !cubeInfo.cubeLineItems.length) {
            cubeLineItems.push(this.toCubeLineItemFormGroup());
        }

        return cubeLineItems;
    }
}
