import { Component, EventEmitter, OnDestroy, OnInit, Output, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { RoleMasterData } from '../../../models/role.masterData';
import {RoleService} from "../../../services/role.service";

@Component({
    selector: 'app-select-role-modal',
    templateUrl: './select-role-modal.component.html',
    styleUrls: ['./select-role-modal.component.scss'],
})
export class SelectRoleModalComponent implements OnInit, OnDestroy {
    @Output() closeModal: EventEmitter<RoleMasterData> = new EventEmitter();
    @Output() selectRole: EventEmitter<RoleMasterData> = new EventEmitter();

    private masterDataSubscription;

    public masterDataRolesList: Array<RoleMasterData> = [];
    public displayedRoleNameList: Array<RoleMasterData>;
    public roleNameForm: UntypedFormGroup;

    constructor(private _fb: UntypedFormBuilder,
                private _roleService: RoleService) {}

    public ngOnInit() {
        this.masterDataRolesList = this._roleService.roles;

        this.roleNameForm = this._fb.group({
            roleSearchTerm: '',
        });
        this.displayedRoleNameList = this.masterDataRolesList;

        this.roleNameForm.valueChanges.subscribe(values => {
            this.filterValues();
        });
    }

    public ngOnDestroy() {
        this.masterDataSubscription.unsubscribe();
    }

    public cancel(): void {
        this.closeModal.emit(null);
    }

    public passSelectedRole(role: RoleMasterData): void {
        this.selectRole.emit(role);
        this.cancel();
    }

    public filterValues(): void {
        const roleSearchTerm = this.roleNameForm.get('roleSearchTerm').value;
        if (roleSearchTerm) {
            const searchPattern = new RegExp(roleSearchTerm, 'i');
            this.displayedRoleNameList = this.masterDataRolesList.filter((customer: RoleMasterData) => customer.roleName.match(searchPattern));
        } else {
            this.displayedRoleNameList = this.masterDataRolesList;
        }
    }
}
