import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {map} from 'rxjs/operators';
import {PasswordService} from '../../services/password.service';
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'app-forgot-password',
    styleUrls: ['./app-forgot-password.component.scss'],
    templateUrl: './app-forgot-password.component.html'
})
export class AppForgotPasswordComponent implements OnInit, AfterViewInit {
    @ViewChild('username') usernameField: ElementRef;
    public forgotPasswordForm: UntypedFormGroup;

    constructor(
        private _notificationService: NotificationService,
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        private _passwordService: PasswordService,
    ) { }

    public ngOnInit() {
        this.forgotPasswordForm = this._formBuilder.group({
            username: new UntypedFormControl('', {
                validators: [
                    Validators.required,
                ]
            }),
        });
    }

    public ngAfterViewInit() {
        this.usernameField.nativeElement.focus();
    }

    public checkForEnter($event: KeyboardEvent) {
        if ($event.keyCode === 13) {
            this.processForgotPassword();
        }
    }

    public processForgotPassword() {
        this.forgotPasswordForm.get('username').markAsDirty();
        if (this.forgotPasswordForm.valid) {
            this._passwordService.sendPasswordResetEmail(this.forgotPasswordForm.get('username').value).pipe(
                map((response) => {
                    this._router.navigate([''])
                    this._notificationService.notifySuccess({title: 'Password Reset', message: response.messages[0].message});
                })
            ).subscribe();
        }
    }
}
