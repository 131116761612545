import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {map, take} from 'rxjs/operators';
import { filterList } from '../../../helpers/utilities';
import { unsubscribe } from '../../../helpers/utilities';

import * as AdminConstants from '../../../constants/admin.constants';
import { CustomerMasterData } from '../../../models/customer.masterData';
import {CustomerService} from '../../../services/customer.service';
import {BreadcrumbService} from "../../../services/breadcrumb.service";

@Component({
    selector: 'app-admin-customer-list',
    styleUrls: ['./admin-customer-list.component.scss'],
    templateUrl: './admin-customer-list.component.html',
})
export class AdminCustomerListComponent implements OnInit, OnDestroy {
    public sectionName = AdminConstants.ADMIN_SECTION_PATHS.customers;
    public masterDataCustomers: Array<CustomerMasterData>;
    public filteredCustomers: Array<CustomerMasterData> = [];
    public customerListForm: UntypedFormGroup;
    public selectedFilterField: string;
    public dropdownFilterFields: Array<{ displayText: string, filterField: string }> = [
        { displayText: 'All', filterField: 'all' },
        { displayText: 'Customer', filterField: 'custName' },
        { displayText: 'Customer No.', filterField: 'custNo' },
        { displayText: 'City', filterField: 'custCity' },
        { displayText: 'State', filterField: 'custState' },
    ];

    public isConfirmDeleteModalVisible: boolean = false;
    public confirmDeleteCustomerTitle: string = 'Confirm Customer Deletion';
    public confirmDeleteCustomerMessage: string = 'Are you certain you\'d like to delete this customer?';
    public selectedCustomer: CustomerMasterData;

    private customerListValueChangesSubscription: any;

    constructor(
        private _fb: UntypedFormBuilder,
        private _router: Router,
        private _breadcrumbService: BreadcrumbService,
        private _customerService: CustomerService,
    ) { }

    public ngOnInit() {
        this.filteredCustomers = this.masterDataCustomers = this._customerService.customers;

        this.customerListForm = this._fb.group({
            filterTerm: ''
        });

        this.customerListValueChangesSubscription = this.customerListForm.valueChanges.subscribe(val => {
            this.filterCustomers();
        });

        this.selectedFilterField = this.dropdownFilterFields[0].filterField;

        this._breadcrumbService.addBreadcrumb([
            {
                label: 'Customers',
                urlValue: 'admin/customers'
            }
        ]);
    }

    public ngOnDestroy() {
        unsubscribe(this.customerListValueChangesSubscription);
    }

    public filterCustomers(): void {
        const filterTerm = this.customerListForm.get('filterTerm').value;

        this.filteredCustomers = filterList(this.masterDataCustomers, this.dropdownFilterFields, this.selectedFilterField, filterTerm);
    }

    public deleteCustomer(customer: CustomerMasterData): void {
        this.hideConfirmDeleteCustomerModal();
        this._customerService.deleteCustomer(customer.custNo).pipe(
            take(1),
            map(() => this._customerService.getCustomerList().subscribe())
        ).subscribe();
    }

    public hideConfirmDeleteCustomerModal(): void {
        this.isConfirmDeleteModalVisible = false;
    }

    public showConfirmDeleteModal(event: Event, customer: CustomerMasterData): void {
        event.stopPropagation();

        this.selectedCustomer = customer;
        this.isConfirmDeleteModalVisible = true;
    }

    public navigateToCustomerShowPage(customerId: string): void {
        this._router.navigate(['admin/customers', customerId]);
    }

}
