<div class="message">
    <div (click)="togglePanel()" class="collapseIcon blue clickable">
        <i class="fa fa-chevron-up" *ngIf="!collapsePanel" aria-hidden="true"></i>
        <i class="fa fa-chevron-down" *ngIf="collapsePanel" aria-hidden="true"></i>
    </div>

    <div *ngIf="collapsePanel" class="recordInfoText" tabindex="-1">
        <div class="messageHeader">{{ beAdvisedAbbreviation }}</div>
    </div>
    <div *ngIf="!collapsePanel" class="recordInfoText" [innerHtml]="beAdvisedMessage" tabindex="-1"></div>
</div>
