<div class="overlay">
    <div class="site-inaccessible-modal">
        <!-- ================================= SITE INACCESSIBLE MODAL HEADER====================================== -->
        <!-- <div class="siteInaccessibleModalHeader">
            <span class="closeModalButtonHolder clickable" (click)="closeModal()">
                <i class="fa fa-times"></i>
            </span>
        </div> -->

        <!-- ==================  SITE INACCESSIBLE MODAL BODY ================== -->
        <div class="siteInaccessibleModalBody">
            <div class="warningIconHolder">
                <i class="fa fa-lock"></i>
            </div>

            <div class="siteInaccessibleModalBodyText">
                Your account is locked until your admin accepts the
                <br> Shiprite TMA. Once the TMA is accepted, you will be
                <br>notified, and your account will be unlocked.
                <br>We are sorry for the inconvenience.
            </div>
        </div>

        <!-- ================== SITE INACCESSIBLE MODAL FOOTER ================== -->
        <div class="siteInaccessibleModalFooter">
            <div class="clickable cancelButton btn-filled" (click)="logout()">
                LOGOUT
            </div>
        </div>
    </div>
</div>