<!-- ======================== TERMS AND CONDITIONS ==================================== -->
<app-terms-and-conditions-modal *ngIf="areTermsAndConditionsVisible"
                                (closeTermsAndConditionsModal)="hideTermsAndConditions()"></app-terms-and-conditions-modal>

<div class="registrationMain">

    <!-- ======================== REGISTRATION BODY ==================================== -->
    <div class="registrationBody" *ngIf="!areTermsAndConditionsVisible">
        <div class="shipriteLogoContainer">
            <img src="../../../assets/images/Logo.svg" class="shipriteLogo">
        </div>
        <form class="registrationForm" [formGroup]="registrationForm" (ngSubmit)="processRegistration()">
            <div class="registrationFormHolder">
                <div class="regFormRow">
                    <input class="shortInput" type="text" placeholder="First Name" formControlName="firstName"
                           maxlength="45" [ngClass]="{ registrationFormInputInvalid:
                           (registrationForm['controls'].firstName.dirty || registrationForm['controls'].firstName.touched) && registrationForm['controls'].firstName.invalid }">
                    <input class="shortInput" type="text" placeholder="Last Name" formControlName="lastName"
                           maxlength="45" [ngClass]="{ registrationFormInputInvalid:
                           (registrationForm['controls'].lastName.dirty || registrationForm['controls'].lastName.touched) && registrationForm['controls'].lastName.invalid }">
                </div>
                <div class="regFormRow">
                    <input class="shortInput" type="text" placeholder="Phone Number" formControlName="phone"
                           maxlength="45" [ngClass]="{ registrationFormInputInvalid:
                           (registrationForm['controls'].phone.dirty || registrationForm['controls'].phone.touched) && registrationForm['controls'].phone.invalid }">
                    <input class="shortInput" type="text" placeholder="Zip Code" formControlName="zipCode">
                </div>
                <div class="regFormRow">
                    <input class="shortInput" type="text" placeholder="Company Name" formControlName="companyName">
                    <input class="shortInput" type="text" placeholder="Company Code" formControlName="companyCode"
                           [ngClass]="{ registrationFormInputInvalid:
            (registrationForm['controls'].companyCode.dirty || registrationForm['controls'].companyCode.touched) && registrationForm['controls'].companyCode.invalid }">
                </div>
                <div class="regFormRow">
                    <input class="longInput" type="text" placeholder="Email Address" formControlName="email"
                           maxlength="100" [ngClass]="{ registrationFormInputInvalid:
            (registrationForm['controls'].email.dirty || registrationForm['controls'].email.touched) && registrationForm['controls'].email.invalid }">
                </div>
                <div class="regFormRow">
                    <input class="longInput" type="text" placeholder="Username" formControlName="username"
                           maxlength="100" [ngClass]="{ registrationFormInputInvalid:
            (registrationForm['controls'].username.dirty || registrationForm['controls'].username.touched) && registrationForm['controls'].username.invalid }">
                </div>
                <div class="regFormRow">
                    <input class="longInput" type="password" placeholder="Password" id="password"
                           formControlName="password" maxlength="25" [ngClass]="{ registrationFormInputInvalid:
                            (registrationForm['controls'].password.dirty || registrationForm['controls'].password.touched) && registrationForm['controls'].password.invalid }">
                    <span class="viewPasswordIcon blue clickable" (click)="togglePasswordType()">
                        <i *ngIf="isPasswordHidden" class="fa fa-eye" aria-hidden="true"></i>
                        <i *ngIf="!isPasswordHidden" class="fa fa-eye-slash" aria-hidden="true"></i>
                    </span>
                </div>
                <div class="lastRegFormRow">
                    <div class="confirmPasswordCol">
                        <div class="confirmPasswordInputRow">
                            <input class="longInput" type="password" placeholder="Confirm Password" id="comparePassword"
                                   formControlName="confirmPassword"
                                   [ngClass]="{ registrationFormInputInvalid:
                                   (registrationForm['controls'].confirmPassword.dirty || registrationForm['controls'].confirmPassword.touched) && registrationForm['controls'].confirmPassword.invalid }">
                            <span class="viewPasswordIcon blue clickable" (click)="toggleComparePasswordType()">
                                <i *ngIf="isComparePasswordHidden" class="fa fa-eye" aria-hidden="true"></i>
                                <i *ngIf="!isComparePasswordHidden" class="fa fa-eye-slash" aria-hidden="true"></i>
                            </span>
                        </div>
                        <span class="passwordErrorMessage"
                              *ngIf="registrationForm['controls'].confirmPassword.dirty && registrationForm['controls'].confirmPassword.invalid">
                              <i class="fa fa-times"></i>
                          <span>
                            Passwords do not match, please check and try again.
                          </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="signUpButtonHolder">
                <button class="signUpButton" type="submit">Sign Up</button>
            </div>
            <!-- ===============TERMS AND CONDITION================= -->
            <div class="termsAndConditionsContainer">
                <span>By signing up, you agree to our </span>
                <span class="blue clickable actionText termsAndConditonsLink" (click)="displayTermsAndConditions()">Terms & Conditions.</span>
            </div>
        </form>
    </div>

    <!-- ======================== LOGIN PROMPT ==================================== -->
    <div class="loginPromptContainer" *ngIf="!areTermsAndConditionsVisible">
        <span>Already have an account?</span>
        <span class="blue clickable actionText loginLink" (click)="navigateToLoginScreen()">Log In</span>
    </div>
</div>
