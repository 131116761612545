<div class="user-tabs-component">
    <div class="userTabSectionContainer">
        <div class="navBoxContent userTab clickable" [ngClass]="{'current': activeUserSection === constants.USER_SECTIONS_profile}" (click)="selectUserTab.emit(constants.USER_SECTIONS_profile)">
            <span class="navBoxIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x profileOuterIcon currentOuterIcon"></i>
                <i class="fa fa-user fa-stack-1x profileInnerIcon" aria-hidden="true"></i>
            </span>
            <span class="navBoxText">Profile</span>
        </div>
        <div *ngIf="activeUserSection === constants.USER_SECTIONS_profile" class="adminNavBoxPointerDown">
        </div>
    </div>
    <div class="userTabSectionContainer">
        <div class="navBoxContent userTab clickable" [ngClass]="{'current': activeUserSection === constants.USER_SECTIONS_defaults}" (click)="selectUserTab.emit(constants.USER_SECTIONS_defaults)">
            <span class="navBoxIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x defaultsOuterIcon currentOuterIcon"></i>
                <i class="fa fa-list fa-stack-1x defaultsInnerIcon" aria-hidden="true"></i>
            </span>
            <span class="navBoxText">Defaults</span>
        </div>
        <div *ngIf="activeUserSection === constants.USER_SECTIONS_defaults" class="adminNavBoxPointerDown">
        </div>
    </div>
    <div class="userTabSectionContainer" *ngIf="isCreditCardPaymentPermissible">
        <div class="navBoxContent userTab clickable" [ngClass]="{'current': activeUserSection === constants.USER_SECTIONS_creditCard}" (click)="selectUserTab.emit(constants.USER_SECTIONS_creditCard)">
            <span class="navBoxIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x adminUsersOuterIcon currentOuterIcon"></i>
                <i class="fa fa-credit-card fa-stack-1x adminUsersInnerIcon" aria-hidden="true"></i>
            </span>
            <span class="navBoxText">Credit Card</span>
        </div>
        <div *ngIf="activeUserSection === constants.USER_SECTIONS_creditCard" class="adminNavBoxPointerDown">
        </div>
    </div>
</div>
