export const
    FONT_AWESOME_ICONS: Array<{ className: string, displayName: string }> = [
        { className: 'fa fa-bell-o', displayName: 'Bell' },
        { className: 'fa fa-calculator', displayName: 'Calculator' },
        { className: 'fa fa-calendar', displayName: 'Calendar' },
        { className: 'fa fa-bar-chart', displayName: 'Bar Chart' },
        { className: 'fa fa-pie-chart', displayName: 'Pie Chart' },
        { className: 'fa fa-cogs', displayName: 'Cogs' },
        { className: 'fa fa-cloud', displayName: 'Cloud' },
        { className: 'fa fa-line-chart', displayName: 'Line Chart' },
        { className: 'fa fa-compass', displayName: 'Compass' },
        { className: 'fa fa-truck', displayName: 'Truck' },
        { className: 'fa fa-paper-plane', displayName: 'Paper Plane' },
        { className: 'fa fa-globe', displayName: 'Globe' },
        { className: 'fa fa-laptop', displayName: 'Laptop' },
        { className: 'fa fa-folder', displayName: 'Folder' },
        { className: 'fa fa-folder-open-o', displayName: 'Open Folder' },
        { className: 'fa fa-briefcase', displayName: 'Briefcase' },
        { className: 'fa fa-anchor', displayName: 'Anchor' },
        { className: 'fa fa-tachometer', displayName: 'Dashboard Dial' },
        { className: 'fa fa-fire', displayName: 'Flame' },
        { className: 'fa fa-futbol-o', displayName: 'Soccer Ball' },
        { className: 'fa fa-industry', displayName: 'Industry' },
        { className: 'fa fa-leaf', displayName: 'Leaf' },
        { className: 'fa fa-map', displayName: 'Map' },
        { className: 'fa fa-map-signs', displayName: 'Sign Posts' },
        { className: 'fa fa-moon-o', displayName: 'Moon' },
        { className: 'fa fa-rocket', displayName: 'Rocket' },
        { className: 'fa fa-ship', displayName: 'Ship' },
        { className: 'fa fa-trophy', displayName: 'Trophy' },
        { className: 'fa fa-language', displayName: 'Language' },
        { className: 'fa fa-magic', displayName: 'Wand' },
        { className: 'fa fa-newspaper-o', displayName: 'Newspaper' },
        { className: 'fa fa-plane', displayName: 'Plane' },
        { className: 'fa fa-paint-brush', displayName: 'Paintbrush' },
        { className: 'fa fa-snowflake-o', displayName: 'Snowflake' },
        { className: 'fa fa-paperclip', displayName: 'Paperclip' },
        { className: 'fa fa-list-ol', displayName: 'Numbered List' },
        { className: 'fa fa-floppy-o', displayName: 'Floppy Disk' },
        { className: 'fa fa-area-chart', displayName: 'Area Chart' },
        { className: 'fa fa-umbrella', displayName: 'Umbrella' },
        { className: 'fa fa-bullhorn', displayName: 'Bullhorn' },
        { className: 'fa fa-check-square', displayName: 'Checked Square' },
        { className: 'fa fa-bookmark', displayName: 'Bookmark' },
        { className: 'fa fa-bullseye', displayName: 'Bullseye' },
        { className: 'fa fa-certificate', displayName: 'Certificate' },
        { className: 'fa fa-fire-extinguisher', displayName: 'Fire Extinguisher' },
        { className: 'fa fa-flag-checkered', displayName: 'Flag' },
        { className: 'fa fa-handshake-o', displayName: 'Handshake' },
        { className: 'fa fa-envelope-o', displayName: 'Envelope' },
        { className: 'fa fa-envelope-open', displayName: 'Envelope Open' },
        { className: 'fa fa-archive', displayName: 'Archive Box' },
        { className: 'fa fa-balance-scale', displayName: 'Scales' },
        { className: 'fa fa-asterisk', displayName: 'Asterisk' },
        { className: 'fa fa-black-tie', displayName: 'Black Tie' },
        { className: 'fa fa-camera-retro', displayName: 'Camera' },
        { className: 'fa fa-birthday-cake', displayName: 'Birthday Cake' },
        { className: 'fa fa-bolt', displayName: 'Lightening Bolt' },
        { className: 'fa fa-cab', displayName: 'Taxi Cab' },
        { className: 'fa fa-coffee', displayName: 'Coffee Cup' },
        { className: 'fa fa-code', displayName: 'Code' },
        { className: 'fa fa-gift', displayName: 'Gift' },
        { className: 'fa fa-clock-o', displayName: 'Clock' },
        { className: 'fa fa-address-book', displayName: 'Address Book' },
        { className: 'fa fa-lightbulb-o', displayName: 'Light Bulb' },
        { className: 'fa fa-star', displayName: 'Star' },
        { className: 'fa fa-heart-o', displayName: 'Heart' },
        { className: 'fa fa-diamond', displayName: 'Diamond' },
        { className: 'fa fa-thumbs-up', displayName: 'Thumbs Up' },
        { className: 'fa fa-thumbs-down', displayName: 'Thumbs Down' },
    ];
