<div class="overlay" *ngIf="rateMarkupSearchForm">
    <div class="markup-search-modal">
        <!--================================== MODAL HEADER ==============================================-->
        <div class="modal-header">
            <div class="modalHeaderContent modalHeaderText">Rate Markup Search</div>
            <div class="modalHeaderContent">
                <i class="fa fa-times clickable modalHeaderIcon" aria-hidden="true" (click)="closeModal()"></i>
            </div>
        </div>
        <!--================================== MODAL BODY- ==============================================-->
        <div class="modal-body">
            <form [formGroup]="rateMarkupSearchForm" (ngSubmit)="filterResults()">
                <!--================================== MODAL FILTER INPUT- ==============================================-->
                <div class="filterResultsPanel">
                    <input class="filterInput" placeholder="Enter Search Criteria" name="filterQuery" formControlName="searchTerm">
                    <div class="searchIcon">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </form>
            <!--================================== MODAL FILTER RESULTS ==============================================-->
            <div class="headerItemsContainer">
                <div class="headerItemsRow">
                    <p class="headerItem">Name</p>
                    <p class="headerItem">Description</p>
                </div>
            </div>
            <!-- <div class="modal-body"> -->
            <div class="resultsContainer scrollbar">
                <div class="resultRow bottomBorderShadow clickable" *ngFor="let option of filteredRateTierRateMarkupOptions" (click)="selectRateMarkupOption(option)">
                    <p class="resultItem">{{ option?.markupName }}</p>
                    <p class="resultItem">{{ option?.markupDescription }}</p>
                </div>
            </div>
        </div>
        <!--================================== MODAL FOOTER ==============================================-->
        <div class="modal-footer"></div>
    </div>
</div>
