import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import * as SearchConstants from '../../../constants/searchCriteria';
import { makeClone } from '../../../helpers/utilities';
import { FilteredListValue } from '../../../models/filteredListValue';
import { FilteredSearchResultFieldValue } from '../../../models/filteredSearchResultFieldValue';
import { ResponseDTO } from '../../../models/responseDto';
import { SearchRequest } from '../../../models/searchRequest';
import {toObservable} from "@angular/core/rxjs-interop";
import {SignalsService} from "../../../services/signals.service";
import {SearchState} from "../../../models/searchState";
import {SearchService} from "../../../services/search.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit, OnDestroy {
  @Input()
  public field: FilteredSearchResultFieldValue;

  @Output()
  public updateField = new EventEmitter<any>();

  private searchStateSubscription: any;
  private searchState: SearchState;

  public filteredValues: Array<any>;
  public filterQuery: string = '';
  private searchRequestQuery: string = '';
  public filteredListValueCount: number;
  public search$;

  constructor(
    private _notificationService: NotificationService,
    private _searchService: SearchService,
    private _signalsService: SignalsService,
  ) {
      this.search$ = toObservable(this._signalsService.searchStateSignal);
  }

  public ngOnInit() {
    this.searchStateSubscription = this.search$.subscribe((searchState: SearchState) => {
      this.searchState = searchState;
      this.setFilteredListValueCount();
    });

    this.resetValues();
  }

  public ngOnDestroy() {
    this.searchStateSubscription.unsubscribe();
  }

  // ================================================================================================
  // ==================================== SETUP/CHANGE METHODS =======================================
  // ================================================================================================
  private resetValues(): void {
    this.filteredValues = makeClone(this.field.uniqueValues);
  }

  public handleFormInputChanges(): void {
    this.searchRequestQuery = this.filterQuery;
    const searchRequest: SearchRequest = this.getPaginationSearchRequest();

    this.processFilterModalSearchRequest(searchRequest);
  }

  public setFilteredListValueCount(): void {
    if (!this.searchState || !this.searchState.filteredSearchResults || !this.searchState.filteredSearchResults.entityType || !this.searchState.filteredSearchResults.filteredListValues || !this.searchState.filteredSearchResults.filteredListValues.length || !this.field || !this.field.fieldName) {
      return;
    }

    const matchingFilteredListedValue: FilteredListValue = this.searchState.filteredSearchResults.filteredListValues.find((fLV: FilteredListValue) => fLV.fieldName === this.field.fieldName);

    this.filteredListValueCount = matchingFilteredListedValue.count;
  }

  // ================================================================================================
  // ==================================== SUBMISSION METHODS =======================================
  // ================================================================================================
  public setQuery(value: string): void {
    this.filterQuery = value;
    this.closeModal();
  }

  public getPaginationSearchRequest(paginationChanges?: { from: number, size: number }): SearchRequest {
    if (!this.searchState || !this.searchState.filterTerm || !this.searchState.searchRequest || !this.searchState.searchRequest.searchCriteria || !this.searchState.searchRequest.searchCriteria.length) {
      return null;
    }

    const paginationSearchRequest: SearchRequest = {
      searchCriteria: [
        ...this.searchState.searchRequest.searchCriteria,
        {
          from: paginationChanges && paginationChanges.from ? paginationChanges.from : SearchConstants.SEARCH_CRITERIA_FROM.default,
          size: paginationChanges && paginationChanges.size ? paginationChanges.size : SearchConstants.SEARCH_CRITERIA_SIZE.default,
          pattern: SearchConstants.SEARCH_CRITERIA_pattern.FILTER_LIST,
          type: this.field.fieldName,
          value: this.searchRequestQuery,
          entityType: this.searchState.filterTerm,
          boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
        }
      ],
    };

    return paginationSearchRequest;
  }

  private processFilterModalSearchRequest(searchRequest: SearchRequest): void {
    this._searchService.processPaginatedFilterValueSearch(searchRequest).subscribe(
      (searchResponse: ResponseDTO<{ filteredListValues: FilteredListValue }>) => {
        if (searchResponse && searchResponse.isValid && searchResponse.dto && searchResponse.dto.filteredListValues) {
          const filteredListValue: FilteredListValue = searchResponse.dto.filteredListValues;
          this._searchService.leftTrimFilterListUniqueValues(filteredListValue);

          this.filteredValues = filteredListValue.uniqueValues && filteredListValue.uniqueValues.length ? filteredListValue.uniqueValues : [];
          this.filteredListValueCount = filteredListValue.count;
        } else {
          this._notificationService.showNotificationsFromResponseDtoMessages({ response: searchResponse, title: 'Filter Modal Search' });
        }
      },
      (err: HttpErrorResponse) => {
        this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Filter Modal Search' });
      }
    );
  }

  // NOTE Hassan (08/24/18) - this method now deprecated as we are using new /Search/filterList API to draw results and search request is made when closing modal
  // public updateFilteredValues(query: string): void {
  //   this.resetValues();
  //   const pattern = new RegExp(query, 'i');
  //   this.filteredValues = this.filteredValues.filter(v => {
  //     let formattedValue = v;
  //     if (moment(v, moment.ISO_8601).isValid() && typeof v === 'string') {
  //       formattedValue = moment(v).format('L');
  //     }
  //     if (formattedValue.toString().match(pattern)) {
  //       return v;
  //     }
  //   });
  // }

  // ================================================================================================
  // ======================================= UI METHODS =============================================
  // ================================================================================================
  public cancel(): void {
    this.filterQuery = '';
    this.closeModal();
  }

  public closeModal(): void {
    this.field.query = this.filterQuery;
    this.field.pattern = SearchConstants.SEARCH_CRITERIA_pattern.WILDCARD;
    this.updateField.emit();
  }

  public handlePaginationPanelChanges(paginationChanges: { from: number, size: number }): void {
    const paginationSearchRequest: SearchRequest = this.getPaginationSearchRequest(paginationChanges);

    this.processFilterModalSearchRequest(paginationSearchRequest);
  }
}
