import { AccessorialsForQuoteRequest } from './accessorialsForQuoteRequest';
import { Consignee } from './consignee';
import { Product } from './product';
import { ProductDetail } from './productDetail';
import { Shipper } from './shipper';

export class QuotesRequest { // aka RateRequest
    uid: number;
    fromzip: string;
    fromcountry: string;
    originType: string;
    originCallAppt: boolean;
    tozip: string;
    tocountry: string;
    destinationType: string;
    destinationCallAppt: boolean;
    shipdate: string; // format YYYY-MM-DD
    tsa: boolean;
    cube: number;
    detail: Array<ProductDetail>;
    accessorials: AccessorialsForQuoteRequest;
    unitmeasurement: string;
    direction: string;
    quote_name: string;
    Shipper?: Shipper;
    Consignee?: Consignee;
    equipmentType?: number;
}
