<div class="lockedOutMain">
    <div class="lockedOutBody">
        <div class="shipriteLogoContainer">
            <img src="../../../assets/images/Logo.svg" class="shipriteLogo">
        </div>
        <div class="instructions">
            For security reasons your user account is locked out.  Please try again later.
        </div>
        <app-button class="lockedOutSubmitButton" (click)="processLockedOut()">Okay</app-button>
    </div>
</div>
