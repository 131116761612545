<app-admin-tabs [activeAdminSection]="sectionName"></app-admin-tabs>
<div class="customerListComponent">
    <div class="filterSectionRow">
        <div class="filterSection">
            <div class="searchCriteriaTypePanel">
                <select class="searchCriteriaTypeText" name="selectedFilterField" id="searchCriteriaTypes" [(ngModel)]="selectedFilterField"
                    (change)="filterMarkups()">
                    <option [value]="ff.filterField" *ngFor="let ff of dropdownFilterFields">{{ ff.displayText }}</option>
                </select>
                <span class="searchCriteriaTypeChevron">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
            </div>
            <form class="filterInputPanel" (ngSubmit)="filterMarkups()" [formGroup]="markupListForm">
                <input type="text" id="searchQuery" autofocus class="searchQuery" name="searchQuery" formControlName="filterTerm" placeholder="Enter Search Criteria"
                    (change)="filterMarkups()" />
            </form>
            <span class="iconSearch clickable blue">
                <i class="fa fa-search" aria-hidden="true"></i>
            </span>
        </div>
        <div class="createCustomerSection">
            <span class="createCustomerIcon blue clickable">
                <i class="fa fa-plus-circle"></i>
            </span>
            <span class="createCustomerLabel blue clickable actionText" (click)="navigateToCreateMarkupPage()">Create</span>
        </div>
    </div>

    <div class="customerListContainer">
        <div class="customerListHeaderRow">
            <div class="headerItem">Markup Name</div>
            <div class="headerItem">Markup ID</div>
            <div class="headerItem">Description</div>
            <div class="lastCol">&nbsp;</div>
        </div>
        <div class="customerDetailContainer" *ngIf="masterDataMarkups.length">
            <cdk-virtual-scroll-viewport itemSize="25" style="height: 100%">
                <div class="customerDetailRow bottomBorderShadow" *cdkVirtualFor="let markup of filteredMarkups; let i = index" (click)="navigateToMarkupShowPage(markup)">
                    <div class="rowDetailItem rowDetailItemName blue actionText">{{ markup?.markupName }}</div>
                    <div class="rowDetailItem">{{ markup?.markupID }}</div>
                    <div class="rowDetailItem ellipsis">{{ markup?.markupDescription }}</div>
                    <div class="lastCol">
                        <!-- <i class="fa fa-times blue clickable" aria-hidden="true"></i> -->
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>
