import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import * as Constants from '../constants/constants';
import * as DateConstants from '../constants/datetime.constants';
import {UserHelper} from '../helpers/userHelper';
import {AccessorialForQuote} from '../models/accessorialForQuote';
import {Country} from '../models/country';
import {EquipmentType} from '../models/equipmentType';
import {ProductDetail} from '../models/productDetail';
import {
    Accessorial,
    Address, BookingOption,
    HandlingUnit,
    Manifest,
    PackingUnit,
    Product, Rate, RateBreakdown,
    Stop,
    USO
} from '../models/unifiedShipmentObject';
import {
    AccessorialImpl, DimensionsImpl,
    EquipmentImpl,
    EquipmentTypeImpl,
    HandlingUnitImpl, ManifestImpl, PackingUnitImpl, ProductImpl,
    StopImpl,
    UsoImpl
} from '../models/usoImpl';
import {ZipSearchResult} from '../models/zip.searchResult';
import {CommonDataService} from './commonData.service';
import {EntityService} from "./entity.service";

export const PATH_NEW = 'new';
export const PATH_ENTRY = 'entry';
export const PATH_CHANGE = 'change';

@Injectable()
export class QuickQuoteService {

    public changeQuoteName;

    private lastQuickQuote: USO;
    private uomFoundInTransforms;
    private linesFormControl;

    constructor(
        private _fb: UntypedFormBuilder,
        private _entityService: EntityService,
        private _commonDataService: CommonDataService,
        private _userHelper: UserHelper,
    ) {}

    public getQuickQuoteByPath(id) {
        switch (id) {
            case PATH_NEW:
                return new Observable(subscriber => {
                    this.lastQuickQuote = new UsoImpl();
                    this.applyDefaultsToQuickQuote(this.lastQuickQuote);
                    subscriber.next(_.cloneDeep(this.lastQuickQuote));
                    subscriber.complete();
                });

            case PATH_CHANGE:
            case PATH_ENTRY:
                return new Observable(subscriber => {
                    subscriber.next(_.cloneDeep(this.lastQuickQuote));
                    subscriber.complete();
                });

            default:
                return this.getQuickQuote(id).pipe(
                    map(quickQuote => {
                        if (Object.keys(quickQuote).length > 0) {
                            this.lastQuickQuote = quickQuote as USO;
                        }
                        return _.cloneDeep(this.lastQuickQuote);
                    }),
                    catchError(err => {
                        throw err;
                    })
                )
        }
    }

    public getQuickQuote(id) {
        return this._entityService.getEntity<USO>('USOQuote', {
            id,
            customer: this._userHelper.getUserCustomer()
        });
    }

    public getBookingOptions(quote: USO) {
        return this._entityService.createEntity<USO>(quote, 'USOBookingOptions', undefined, undefined);
    }

    public getQuickQuoteFormFromLastQuickQuote() {
        const origin: ZipSearchResult = this.stopTransform(1);
        const destination: ZipSearchResult = this.stopTransform(2);
        this.linesFormControl = new UntypedFormControl(this.deleteZeroValues(this.productDetailTransform()));
        const controls = {
            name: this.lastQuickQuote.name ? this.lastQuickQuote.name : '',
            tsaRequired: this.lastQuickQuote.tsaRequired,
            equipmentType: this.equipmentTypeTransform(),
            origin,
            originCountry: this.countryTransform(origin),
            destination,
            destinationCountry: this.countryTransform(destination),
            shipDate: this.shipDateTransform(),
            accessorials:  new UntypedFormControl(this.accessorialsTransform()),
            lines: this.linesFormControl,
            unitOfMeasure: this.uomFoundInTransforms,
        }

        const qqForm = this._fb.group(controls);
        qqForm.setValidators([
            (fg: UntypedFormGroup) => {
                const errors: any = {};
                if (!this.propInValue(fg.controls.origin, 'zip_code')) {
                    errors.originRequired = true;
                }
                if (!this.propInValue(fg.controls.destination, 'zip_code')) {
                    errors.destinationRequired = true;
                }
                if (!this.propInValue(fg.controls.originCountry, 'countryCode')) {
                    errors.originCountryCodeRequired = true;
                }
                if (!this.propInValue(fg.controls.destinationCountry, 'countryCode')) {
                    errors.destinationCountryCodeRequired = true;
                }
                if (!fg.controls.lines || !fg.controls.lines.value || !(fg.controls.lines.value.length > 1)) {
                    errors.linesRequired = true;
                }

                if (Object.keys(errors).length) {
                    return errors;
                }

                return null;
            },
        ]);
        return qqForm;
    }

    public clearLastQuote() {
        this.lastQuickQuote = new UsoImpl();
    }

    public updateForm(quickQuoteForm: UntypedFormGroup) {
        const origin: ZipSearchResult = this.stopTransform(1);
        const destination: ZipSearchResult = this.stopTransform(2);

        quickQuoteForm.controls.name.setValue(this.lastQuickQuote.name ? this.lastQuickQuote.name : '');
        quickQuoteForm.controls.tsaRequired.setValue(this.lastQuickQuote.tsaRequired);
        quickQuoteForm.controls.equipmentType.setValue(this.equipmentTypeTransform());
        quickQuoteForm.controls.origin.setValue(origin);
        quickQuoteForm.controls.originCountry.setValue(this.countryTransform(origin));
        quickQuoteForm.controls.destination.setValue(destination);
        quickQuoteForm.controls.destinationCountry.setValue(this.countryTransform(destination));
        quickQuoteForm.controls.shipDate.setValue(this.shipDateTransform());
        quickQuoteForm.controls.accessorials.setValue(this.accessorialsTransform());
        quickQuoteForm.controls.lines.setValue(this.deleteZeroValues(this.productDetailTransform()));
        quickQuoteForm.controls.unitOfMeasure.setValue(this.uomFoundInTransforms);
    }

    public updateLastQuote(quote: USO) {
        this.lastQuickQuote = quote;
    }

    public getQuoteFromForm(form: UntypedFormGroup) {
        const quote: USO = _.cloneDeep(this.lastQuickQuote);
        const equipmentType = new EquipmentTypeImpl();
        let accessorialForQuote: AccessorialForQuote
        let stop: Stop = new StopImpl();
        let accessorial: Accessorial;
        let productLine: ProductDetail;
        let handlingUnit: HandlingUnit;
        let packingUnit: PackingUnit;
        let unitOfMeasure;
        let product: Product;
        let manifest: Manifest;
        let i;

        quote.name = form.controls.name.value;
        quote.tsaRequired = !!(form.controls.tsaRequired.value);
        if (form.controls.equipmentType.value.id) {
            quote.equipment = new EquipmentImpl();
            equipmentType.id = form.controls.equipmentType.value.id;
            equipmentType.name = form.controls.equipmentType.value.description;
            quote.equipment.equipmentTypes.push(equipmentType);
        }
        quote.route = [];
        stop.stopID = 1;
        stop.location.address.city = form.controls.origin.value.city;
        stop.location.address.state = form.controls.origin.value.state;
        stop.location.address.postalCode = form.controls.origin.value.zip_code;
        stop.location.address.country = form.controls.originCountry.value.countryCode;
        stop.window.date = moment(form.controls.shipDate.value.date, DateConstants.DATE_DISPLAY_MM_DD_YYYY_with_slashes.format).format(DateConstants.DATE_DISPLAY_YYYY_MM_DD.format);
        quote.route.push(stop);
        stop = new StopImpl();
        stop.stopID = 2;
        stop.location.address.city = form.controls.destination.value.city;
        stop.location.address.state = form.controls.destination.value.state;
        stop.location.address.postalCode = form.controls.destination.value.zip_code;
        stop.location.address.country = form.controls.destinationCountry.value.countryCode;
        quote.route.push(stop);

        quote.accessorials = [];
        quote.manifest = [];
        const accessorialsForQuote: Array<AccessorialForQuote> = form.controls.accessorials.value;
        if (accessorialsForQuote.length) {
            for (accessorialForQuote of accessorialsForQuote) {
                accessorial = new AccessorialImpl();
                accessorial.code = accessorialForQuote.code;
                accessorial.name = accessorialForQuote.displayName;
                if (accessorialForQuote.valueRequired) {
                    accessorial.description = accessorialForQuote.valueRequired;
                    accessorial.value = accessorialForQuote.value;
                }
                quote.accessorials.push(accessorial)
            }
        }

        const productLines: Array<ProductDetail> = form.controls.lines.value;
        if (productLines.length > 1) {
            unitOfMeasure = form.controls.unitOfMeasure.value.unitOfMeasureCode;
            for (i = 0; i < productLines.length - 1; i++) {
                manifest = new ManifestImpl();
                productLine = productLines[i];
                handlingUnit = new HandlingUnitImpl();
                handlingUnit.weight.value = productLine.Weight;
                handlingUnit.weight.uom = unitOfMeasure;
                handlingUnit.volume.value = +productLine.Cube;
                handlingUnit.volume.uom = unitOfMeasure;
                handlingUnit.quantity = productLine.Qty;
                if (productLine.Length && productLine.Width && productLine.Height) {
                    handlingUnit.dimensions = new DimensionsImpl();
                    handlingUnit.dimensions.length = productLine.Length;
                    handlingUnit.dimensions.width = productLine.Width;
                    handlingUnit.dimensions.height = productLine.Height;
                    handlingUnit.dimensions.uom = unitOfMeasure;
                }
                handlingUnit.stackable = !!(productLine.stackable);
                handlingUnit.hazmat = !!(productLine.hazmat);
                packingUnit = undefined;
                if (productLine.Pkg_Type !== undefined) {
                    packingUnit = new PackingUnitImpl();
                    packingUnit.id = productLine.Pkg_Type;
                    handlingUnit.packingUnits.push(packingUnit)
                }

                if (productLine.class || productLine.nmfc || productLine.hazmat_packingGroup || productLine.hazmat_class ||
                    productLine.Product || productLine.name || productLine.description || productLine.description2 || productLine.hazmat) {
                    product = new ProductImpl();

                    if (productLine.hazmat) {
                        product.hazmat = true;
                    }
                    if (productLine.name) {
                        product.name = productLine.name;
                    }
                    if (productLine.class) {
                        product.freightClass.code = productLine.class;
                    }
                    if (productLine.nmfc) {
                        product.nmfcItemCode = productLine.nmfc;
                    }
                    if (productLine.hazmat_packingGroup) {
                        product.hazmatDetail.packingGroup = productLine.hazmat_packingGroup;
                    }
                    if (productLine.hazmat_class) {
                        product.hazmatDetail.hazmatClass = productLine.hazmat_class;
                    }
                    if (productLine.Product) {
                        product.id = productLine.Product;
                    }
                    if (productLine.description || productLine.description2) {
                        if (!productLine.description) {
                            product.description.push('');
                        } else {
                            product.description.push(productLine.description);
                        }
                        if (productLine.description2) {
                            product.description.push(productLine.description2);
                        }
                    }
                    if (!packingUnit) {
                        packingUnit = new PackingUnitImpl();
                        handlingUnit.packingUnits.push(packingUnit);
                    }
                    if (product.hazmat) {
                        packingUnit.hazmat = true;
                    }
                    packingUnit.products.push(product);
                }
                manifest.handlingUnits.push(handlingUnit);
                quote.manifest.push(manifest);
            }
        }
        return quote;
    }

    public getLastQuickQuote() {
        return this.lastQuickQuote;
    }

    public saveQuote(quickQuote: USO, rateID, doUpdate) {
        const params: any = {
            customer: this._userHelper.getUserCustomer(),
            rateID,
        };

        if (quickQuote.name) {
            params.name = quickQuote.name;
        }

        if (quickQuote.id) {
            params.id = quickQuote.id;
        }

        if (doUpdate) {
            return this._entityService.updateEntity(quickQuote, 'USOQuote', params, 'name');
        } else {
            return this._entityService.createEntity(quickQuote, 'USOQuote', undefined, 'name', undefined, params);
        }
    }

    public loadCosts(bookingOption: BookingOption) {
        let rate: Rate;
        let rateBreak: RateBreakdown;
        const result = {
            extra: 0,
            fuel: 0,
            lineHaul: 0,
            total: 0,
            currency: ''
        };

        if (bookingOption && bookingOption.rateDetails && bookingOption.rateDetails.length) {
            rate = bookingOption.rateDetails[0].rates[0];
            result.total = rate.rate.amount;
            result.currency = rate.rate.currency.currency;
            for (rateBreak of rate.rateBreakdown) {
                switch (rateBreak.code) {
                    case 'LH':
                        result.lineHaul = rateBreak.rate.amount;
                        break;
                    case 'FSC':
                        result.fuel = rateBreak.rate.amount;
                        break;
                    default:
                        result.extra += rateBreak.rate.amount;
                }
            }
        }
        return result;
    }

    private addressToZipSearch(addr: Address): ZipSearchResult {
        const zipSearchResult = new ZipSearchResult();
        if (addr.country) {
            zipSearchResult.countryname = addr.country;
            zipSearchResult.zip_code = addr.postalCode;
            zipSearchResult.city = addr.city;
            zipSearchResult.state = addr.state;
        }
        return zipSearchResult;
    }

    private propInValue(obj, propName) {
        return (obj && obj.value && obj.value[propName]);
    }

    private deleteZeroValues(lines: Array<ProductDetail>) {
        let line: ProductDetail;
        for (line of lines) {
            if (!line.Length) {
                delete line.Length;
            }
            if (!line.Width) {
                delete line.Width;
            }
            if (!line.Height) {
                delete line.Height;
            }
            if (!line.Weight) {
                delete line.Weight;
            }
            if (!line.Cube) {
                delete line.Cube;
            }
            if (!line.Qty) {
                delete line.Qty;
            }
        }
        return lines;
    }

    private equipmentTypeTransform() {
        const equipmentType = new EquipmentType();
        if (this.lastQuickQuote.equipment &&
            this.lastQuickQuote.equipment.equipmentTypes &&
            this.lastQuickQuote.equipment.equipmentTypes.length) {
            equipmentType.id = this.lastQuickQuote.equipment.equipmentTypes[0].id;
            equipmentType.description = this.lastQuickQuote.equipment.equipmentTypes[0].description;
        } else {
            equipmentType.id = 0;
        }
        return equipmentType;
    }

    private stopTransform(stopId: number) {
        let result = new ZipSearchResult();
        const stop: Stop = _.find(this.lastQuickQuote.route, (stopFound: Stop) => stopFound.stopID === stopId);

        if (stop && stop.location && stop.location.address) {
            result = this.addressToZipSearch(stop.location.address);
        }

        return result;
    }

    private countryTransform(zipSearchResult: ZipSearchResult) {
        let result = _.find(this._commonDataService.countries, (country: Country) => country.countryCode === zipSearchResult.countryname);

        if (!result) {
            result = new Country();
        }

        return result;
    }

    private shipDateTransform() {
        const stop: Stop = _.find(this.lastQuickQuote.route, (stopFound: Stop) => stopFound.stopID === 1);

        if (stop && stop.window) {
            return { date: moment(stop.window.date).format(DateConstants.UNIFORM_DATE_DISPLAY.format) };
        } else {
            return { date: moment(new Date()).format(DateConstants.UNIFORM_DATE_DISPLAY.format) };
        }
    }

    private accessorialsTransform() {
        let accessorial: Accessorial;
        const accessorials = [];
        let quoteAccessorial: AccessorialForQuote;

        for (accessorial of this.lastQuickQuote.accessorials) {
            quoteAccessorial = new AccessorialForQuote();
            quoteAccessorial.displayName = accessorial.name;
            quoteAccessorial.code = accessorial.code;
            if (accessorial.description) {
                quoteAccessorial.valueRequired = accessorial.description;
                quoteAccessorial.value = accessorial.value;
            }
            accessorials.push(quoteAccessorial);
        }

        return accessorials;
    }

    private uomSearch(measurement) {
        if (this.uomFoundInTransforms === undefined) {
            if (measurement && measurement.uom) {
                this.uomFoundInTransforms = _.find(this._commonDataService.unitOfMeasures, (unitOfMeasure) => unitOfMeasure.unitOfMeasureCode === measurement.uom);
            }
        }
    }

    private productDetailTransform() {
        const userProfile = this._userHelper.getUserProfile();
        const productLines = [];
        let i = 0;
        let manifest: Manifest;
        let productDetail: ProductDetail;
        let product: Product;
        let handlingUnit: HandlingUnit;
        let packingUnit: PackingUnit;

        this.uomFoundInTransforms = undefined;

        for (manifest of this.lastQuickQuote.manifest) {
            productDetail = new ProductDetail();
            productDetail.productId = i;
            if (manifest.handlingUnits.length) {
                handlingUnit = manifest.handlingUnits[0];
                productDetail.Weight = handlingUnit.weight.value;
                this.uomSearch(handlingUnit.weight);
                productDetail.Cube = handlingUnit.volume.value;
                this.uomSearch(handlingUnit.volume);
                productDetail.Qty = handlingUnit.quantity;
                if (handlingUnit.dimensions) {
                    productDetail.Length = handlingUnit.dimensions.length;
                    productDetail.Width = handlingUnit.dimensions.width;
                    productDetail.Height = handlingUnit.dimensions.height;
                    this.uomSearch(handlingUnit.dimensions);
                }
                productDetail.stackable = handlingUnit.stackable ? 1 : 0;
                productDetail.hazmat = handlingUnit.hazmat ? 1 : 0;
                if (handlingUnit.packingUnits && handlingUnit.packingUnits.length) {
                    packingUnit = handlingUnit.packingUnits[0];
                    productDetail.Pkg_Type = packingUnit.id;
                    if (!productDetail.Qty && packingUnit.quantity) {
                        productDetail.Qty = packingUnit.quantity;
                        if (packingUnit.dimensions) {
                            productDetail.Length = packingUnit.dimensions.length;
                            productDetail.Width = packingUnit.dimensions.width;
                            productDetail.Height = packingUnit.dimensions.height;
                            this.uomSearch(packingUnit.dimensions);
                        }
                    }
                    if (handlingUnit.packingUnits[0].products &&
                        handlingUnit.packingUnits[0].products.length) {
                        product = handlingUnit.packingUnits[0].products[0];
                        productDetail.class = product.freightClass.code;
                        productDetail.nmfc = product.nmfcItemCode;
                        productDetail.hazmat_packingGroup = product.hazmatDetail.packingGroup;
                        productDetail.hazmat_class = product.hazmatDetail.hazmatClass;
                        productDetail.Product = product.id;
                        productDetail.name = product.name;
                        if (product.description.length) {
                            productDetail.description = product.description[0];
                        }
                        if (product.description.length > 1) {
                            productDetail.description2 = product.description[1];
                        }
                    }
                }
            }

            productLines.push(productDetail);
            i++;
        }

        if (this.uomFoundInTransforms === undefined) {
            this.uomFoundInTransforms = _.find(this._commonDataService.unitOfMeasures, (unitOfMeasure) => unitOfMeasure.unitOfMeasureCode === ((userProfile.defaultUoM) ? userProfile.defaultUoM : Constants.UNIT_OF_MEASURE_Imperial));
        }

        return productLines;
    }

    private applyDefaultsToQuickQuote(quote: USO) {
        const userProfile = this._userHelper.getUserProfile();
        const userSettings = this._userHelper.getUserSettings();
        const originStop: Stop = new StopImpl();
        const destStop: Stop = new StopImpl();

        originStop.stopID = 1;
        destStop.stopID = 2;
        quote.route.push(originStop);
        quote.route.push(destStop);

        originStop.window.date = moment(new Date()).format(DateConstants.DATE_DISPLAY_YYYY_MM_DD.format);

        quote.tsaRequired = !!userProfile.defaultTSA;

        if (userSettings[0]?.defaultBillTo) {
            quote.billTo.id = parseInt(userSettings.defaults[0].defaultBillTo, 10);
        }

        if (userProfile.defaultOriginType) {
            originStop.location.type = (userProfile.defaultOriginType.toUpperCase() === 'R') ? 'R' : 'C';
        }

        if (userProfile.defaultDestType) {
            destStop.location.type = (userProfile.defaultDestType.toUpperCase() === 'R') ? 'R' : 'C';
        }

        if (userProfile.defaultDirection) {
            switch (userProfile.defaultDirection) {
                case 'import':
                case 'inbound':
                    quote.deliveryDirectionType = 'inbound';
                    break;
                case 'export':
                case 'outbound':
                    quote.deliveryDirectionType = 'outbound';
                    break;
            }
        }

        if (userProfile.defaultDestZip) {
            destStop.location.address.postalCode = userProfile.defaultDestZip;
        }

        if (userProfile.defaultOriginZip) {
            originStop.location.address.postalCode = userProfile.defaultOriginZip;
        }
    }
}
