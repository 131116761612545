<div class="terms-and-conditions-modal">
    <!-- =================================MODAL HEADER ROW================================== -->
    <div class="modal-header">
        <div class="modalHeaderText">Transportation Terms & Conditions</div>
        <div class="modalHeaderContent">
            <i class="fa fa-times clickable modalHeaderIcon" (click)="closeModal()"></i>
        </div>
    </div>
    <!-- ========================================MODAL BODY COLUMN=========================================== -->
    <div class="modal-body">
        <div class="termsAndConditionsContainer">
            <div class="termsAndConditionsBody">
                <div class="termsAndConditionsText scrollbarGray">
                    <div>The following TRANSPORTATION TERMS AND CONDITIONS shall apply to all shipments scheduled by Customer,
                        unless and until these TERMS AND CONDITIONS are altered or amended by the Company. The Company is
                        licensed by the Department of Transportation (DOT), Federal Motor Carrier Safety Administration (FMSCA)
                        and/or other government agencies as required by law. The Company reserves the right, in its sole
                        discretion, to refuse any shipment at any time. THE COMPANY IS NOT A FREIGHT CARRIER OR AN INDEPENDENT
                        AGENT FOR A FREIGHT CARRIER.
                        <br>
                        <br>
                    </div>
                    <div class="definitionOfTermsLabel">
                        <u>Definitions of Terms</u>
                    </div>
                    <p>
                        <span class="definitionOfTerms"> Carrier: </span>The term “Carrier”, as used herein, shall refer
                        to the freight carrier selected to transport the customer’s shipment. Carrier(s) contracted by CTS
                        for the movement of Customer’s freight utilizing the System shall be defined as “Program Providers”
                    </p>
                    <p>
                        <span class="definitionOfTerms"> Company or CTS:</span>The term “Company” or “CTS”, as used herein,
                        shall refer to Capital Transportation Solutions LLC, with a place of business at 1915 Vaughn Rd.,
                        Kennesaw, Ga. 30144. Transportation services provided by Program Providers are arranged by Company
                        (DOT / FMCSA License No. MC-734068-B). Company is licensed as a Broker of Property by the Department
                        of Transportation (DOT), Federal Motor Carrier Safety Administration (FMSCA), and maintains other
                        necessary authority from the FMCSA or other government agencies as required by law. The Company reserves
                        the right, in its sole discretion, to refuse any shipment at any time. THE COMPANY IS NOT A FREIGHT
                        CARRIER OR AN INDEPENDENT AGENT FOR A FREIGHT CARRIER.
                    </p>
                    <p>
                        <span class="definitionOfTerms"> Customer:</span>The term “Customer”, as used herein, shall refer
                        to any person using Services, including the customer, shipper and consignee.
                    </p>
                    <p>
                        <span class="definitionOfTerms"> General Rules Tariff(s):</span>
                        The term “General Rules Tariff, as used herein, shall refer to the terms and conditions of carriage set forth by each carrier
                        in regards to shipments scheduled with such carrier, that govern the relationship between the customer
                        and the carrier.
                    </p>
                    <p>
                        <span class="definitionOfTerms">Service or Services:</span>The term “Service” or “Services”, as used
                        herein, shall refer to any service provided by CTS
                    </p>
                    <p>
                        <span class="definitionOfTerms"> System:</span>
                        The term System shall mean the licensed use by Customer of CTS proprietary software “ShipRite” and web-based service “ShipRite
                        on Demand”
                    </p>
                    <p>
                        <span class="definitionOfTerms">Vanguard:</span>The term Vanguard shall mean Vanguard Logistics Services
                        (USA), Inc. f/k/a NACA Logistics (USA), Inc. with its principal place of business at 5000 Airport
                        Plaza Dr., Suite 200, Long Beach, CA 90815.
                    </p>
                    <br>
                    <br>
                    <ol>1. Acceptable Use and Terms of Service</ol>
                    <p>By using the Services, Customer agrees to the terms and conditions as stated herein. The General Rules
                        Tariffs, set forth by the Program Providers utilized within CTS services will take precedence in
                        all legal proceedings, and when applicable, will take precedence over these Terms and Conditions.
                        If an issue is not addressed in The General Rules Tariff, then these Terms and Conditions shall control.
                        Additional charges may apply based on each carrier’s General Rules Tariffs. In the case of conflict
                        between these Terms and Conditions and those set forth by the carrier’s General Rules Tariff, the
                        selected carrier’s General Rules Tariff shall control. CTS reserves the right to change or amend
                        these Terms and Conditions at any time. If CTS makes any changes to the Terms and Conditions, CTS
                        will notify the customer by posting an announcement on this site. </p>
                    <ul>
                        <u>Terms of Service.</u>
                    </ul>
                    <div class="termsOfService">
                        <li>
                            <span>CTS has negotiated and entered into transportation agreements with certain Program Providers,
                                as they may be amended or supplemented from time to time by CTS to provide motor truck transportation
                                services to Customer as required. The services to be provided by CTS comprise the following
                                services (sometimes collectively referred to as the “SROD Program”):</span>
                        </li>
                        <li> Carrier contract pricing;</li>
                        <li>Use of “SHIPRITE” quoting software;</li>
                        <li> Use of “SROD” web-based shipment execution software;</li>
                        <li>Use of Bill of Lading software and dispatch services;</li>
                        <li>Tracking and Tracing; and</li>
                        <li>Optional Cargo claims processing.</li>
                    </div>
                    <br>
                    <li>Customer agrees:</li>
                    <br>
                    <div class="termsOfService">
                        <li>to adhere to all requirements of CTS with regard to the use of the SROD Program as may be communicated
                            to Customer from time to time, including the requirement that each shipment must utilize an international
                            origin or destination; and</li>
                        <li>that no services shall be contracted by Customer directly with Program Providers and all shipments
                            must be entered and routed for shipment through the SROD web-based shipment execution software
                            program; and
                        </li>
                        <li> that no services shall be contracted by Customer directly with Program Providers and all shipments
                            must be entered and routed for shipment through the SROD web-based shipment execution software
                            program; and
                        </li>
                        <li>to make payment in accordance with the provisions of this Agreement for all freight and other
                            charges associated with the transportation services arranged by Customer through the SROD Program.</li>
                    </div>
                    <br>
                    <li>Customer’s divisions, subsidiaries, joint ventures, agents, and affiliates (“Customer Affiliates”)
                        for which Customer wishes to have the rights to utilize the SROD Program pursuant to this Agreement
                        are bound by the provisions of this Agreement to the same extent as if they were parties hereto.
                        Customer shall be responsible to CTS for the obligations of Customer Affiliates, and shall indemnify
                        and hold harmless CTS and Vanguard for any loss, directly or indirectly, cause by Customer or Customer
                        Affiliates.</li>
                    <br>
                    <li>CTS hereby grants to Customer a non-assignable and non-exclusive license to use the software listed
                        in Section 1.1 for the purpose of this Agreement only. The license granted by this Agreement may
                        not be assigned or sublicensed, and shall have a term that expires on the Termination Date.</li>
                    <br>
                    <li>CTS shall negotiate and execute transportation agreements with Program Providers on behalf of and
                        for the benefit of Customer.Except when prohibited by applicable law, Customer will be made a third-party
                        beneficiary of contracts between CTS and Program Providers for the benefit of Customer.</li>
                    <br>
                    <li>Customer authorizes CTS to act as its agent for the purposes of negotiating, modifying, and executing
                        contracts for the transportation of freight, and for arranging shipments and the receipt and payment
                        of invoices for services on behalf of Customer. Transportation agreements with Program Providers
                        shall apply to the benefit of Customer during the term of this Agreement, and will terminate with
                        respect to Customer on the Termination Date.
                    </li>
                    <br>
                    <li> All motor carrier transportation agreements entered into under authority of this Section 1 shall
                        be pursuant to the terms of agency set forth in Section 1.5, and shall be binding upon Customer as
                        a party thereto.</li>
                    <br>
                    <li>Customer shall ensure that no shipping documents for services under this Agreement, including bills
                        of lading and freight bills, identify CTS as the consignor or consignee but shall reference CTS only
                        as the payment agent of Customer (directing invoices to Customer c/o CTS); provided, however, that
                        shipping documents may designate Vanguard as consignee for any shipments destined for any Vanguard
                        warehouse.
                    </li>
                    <br>
                    <li>Term and Termination. This Agreement shall become effective on the date of electronic acceptance
                        of these Transportation Terms and Conditions, or the first day of service described herein, whichever
                        is earlier (the “Effective Date”) and shall terminate on the first anniversary of the Effective Date;
                        provided that it shall be renewed automatically for successive periods of one year unless otherwise
                        terminated according to the provisions of this Agreement.</li>
                    <br> 1.10This Agreement may be terminated by any Party, with or without cause, on not less than thirty
                    (30) days written notice pursuant to this Agreement; provided, however, that Company may terminate this
                    Agreement immediately if Customer fails to pay any amounts due as required hereinunder.
                    <br>
                    <br>
                    <ol>2. Rate Quote</ol>
                    Less-than –Truckload (LTL) rates are based on the freight class determined by the NMFC (National Motor Freight Classification)
                    and the total weight of the shipment. The customer agrees to provide CTS with the correct freight class
                    and weight of their shipment so that CTS may provide the customer with accurate rate quotes. The customer
                    agrees that it is their responsibility to determine the correct freight class and weight of their own
                    shipment. CTS may assist the customer in determining their freight class via phone, email or this website,
                    however it is the customer’s responsibility to make sure this information is correct by contacting the
                    NMFTA, National Motor Freight Traffic Association, direct or by other means. Truckload (TL) rates are
                    based on dock door pickup/dock door delivery and shipper load/consignee unload and are mileage and state-to-state
                    based. Additional charges may apply for tractor detention, trailer detention, and driver assistance.
                    Truckload cancellations require at least a 24-hour notice to avoid a cancellation fee. In the event CTS
                    contracts with a TL carrier to pick up a truck load shipment and the Customer does not tender the load
                    to the carrier, the customer agrees to pay CTS a $200 (USD) cancellation fee. Air rates are based on
                    actual weight or dimensional weight, whichever is greater, as determined by the carrier. Additional charges
                    may apply for oversized air freight. Van Line rates are determined by state-to-state, mileage, weight
                    (actual or density) and product commodity type. Flatbed rates are determined by equipment type, state
                    to state, mileage, and weight. All transit times, for any type of shipment, are estimates only and do
                    not include the day of pickup, weekends or holidays. Transit times and pick up dates are not guaranteed
                    by CTS or the Carrier, unless it is specifically a guaranteed LTL service from the carrier. CTS makes
                    no guarantee that the rate quotes are accurate. Freight carriers may change prices at any time, resulting
                    in a price change to the customer. The customer agrees that quotes are not guaranteed to be accurate
                    and are subject to change at any time.
                    <br>
                    <ol>3. Limitations of Liability </ol>
                    CTS or Vanguard are not liable for any loss, damage, mis-delivery or non-delivery caused by the act, default or omission
                    of the Carrier, Program Provider, Customer, or any other person who has interest in the shipment. CTS
                    or Vanguard are not liable for any loss, damage, mis-delivery or non-delivery caused by the Customer
                    violating these terms and conditions or the terms and conditions in the bill of lading or of the carrier’s
                    General Rules Tariff, including but not limited to: improper or insufficient packaging, securing, addressing
                    or failing to adhere to any of the rules relating to shipments that are only permitted under certain
                    conditions or shipments not permitted for transportation. CTS or Vanguard are not liable for any loss,
                    damage, mis-delivery or non-delivery caused by acts of God, war, terrorism, riots, strikes, labor disputes,
                    weather conditions, mechanical delay or defect of aircraft or other equipment, perils of the air, public
                    enemies, public authorities, or acts or omissions of Customs officials. CTS or Vanguard are not liable
                    for the acts or omissions of any person other than employees of CTS or Vanguard. In no event, will CTS
                    or Vanguard be liable for any indirect, consequential, exemplary, incidental, or special damages, Including
                    but not limited to loss of profits or income, whether or not CTS or Vanguard had knowledge of the possibility
                    of such damages, arising from these Terms and Conditions or relating in whole or in part to Customer’s
                    right’s hereunder.
                    <ol>4. Warranties Disclaimer</ol>
                    CTS MAKES NO WARRANTIES, EXPRESS OR IMPLIED INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                    PARTICULAR PURPOSE, WITH REGARD TO SHIPMENT DELIVERIES, INFORMATION PROVIDED BY CTS, OR FOR SERVICES
                    TRANSACTED BY CUSTOMER. CTS CANNOT GUARANTEE DELIVERY OF ANY SHIPMENT BY A SPECIFIC DATE OR TIME.
                    <br>
                    <ol> 5. Bills of Lading, Rates, Freight Charges and Transit Times</ol>
                    Rates and charges for LTL transportation are based on the total size and weight of the shipment and the freight class determined
                    by the NMFC (National Motor Freight Classification) which is based on the actual description of the Goods.
                    Additional fees may apply for other charges including various accessorial services. Customer agrees that
                    it is Customer’s responsibility to determine the correct freight class and weight of each shipment and
                    provide this information to the Company for each shipment. Upon request, Company may assist the Customer
                    in determining the freight class via phone, email or this website; however, Customer is solely responsible
                    for the determination.
                    <br>
                    <br> Rates and charges for TL transportation are based on dock door pickup/dock door delivery and shipper
                    load/consignee unload, and are mileage and state-to-state based. Additional charges may apply for tractor
                    detention, trailer detention, and driver assistance. FTL cancellations require at least a 24-hour notice
                    to avoid a cancellation fee. In the event Company contracts with an FTL carrier to pick up a FTL shipment
                    and the Customer does not tender the load to the carrier, Customer agrees to pay Company a $200 (USD)
                    cancellation fee.
                    <br>
                    <br> Rates and charges for shipments by air are based on actual weight or dimensional weight, whichever
                    is greater, as determined by the carrier. Additional charges may apply for oversized air freight. Van
                    Line rates are determined by state-to-state, mileage, weight (actual or density) and product commodity
                    type. Flatbed rates are determined by equipment type, state to state, mileage, and weight.
                    <br>
                    <br> All transit times, for any type of shipment, are estimates only and do not include the day of pickup,
                    weekends or holidays. Transit times and pick up dates are not guaranteed by Company or the carrier, unless
                    the shipment is a guaranteed LTL service is specifically agreed by the carrier. Rate quotes are estimates
                    and Company makes no guarantee that the rate quotes are accurate. Carriers may change rates or charges
                    at any time, resulting in a price change to the Customer. Customer agrees that quotes are not guaranteed
                    to be accurate and are subject to change at any time.
                    <br>
                    <br> When Customer schedules a shipment through CTS using the System, a Bill of Lading will be issued
                    based on the information provided by the Customer. Customer agrees to only use the Bill of Lading provided
                    by CTS. Failure to use CTS’s Bill of Lading will void their discounted rate quote and result in being
                    charged the carrier’s full rate and or a $50 fee. The Bill of Lading shall be considered, conclusively,
                    to have been prepared by the customer regardless of CTS’s participation in the creation of such bill
                    of lading. All Bills of Lading are NON-NEGOTIABLE. Any unauthorized alteration, unauthorized use of Bills
                    of Lading, scheduling shipments to any carrier other than that designated by CTS, or use of a Bill of
                    Lading not authorized or issued by CTS, shall void all rate quotes and P.A.F’s obligation to make any
                    payments relating to the shipment. If a substitute form of bill of lading is needed to complete delivery
                    of this shipment and the Company completes that document, the terms of this bill of lading will govern.
                    The Company is not liable to the Customer or to any other person for any actions taken or not taken on
                    behalf of the Customer under this provision. LTL rates are based on the freight class as determined by
                    the NMFC (National Motor Freight Classification) which are based on the actual description, size, and
                    weight of the shipment. Additional fees may apply for other charges including appointment delivery, reweighs
                    and reclassifications, lift gate services, inside delivery or various other accessorial services. TL
                    rates are based on dock door pickup/dock door delivery and Customer load/consignee unload and are state
                    to state and mileage based. Additional fees may apply for charges including but not limited to, tractor
                    detention, trailer detention, and driver assistance. Once the Company has contracted with a carrier to
                    move a TL shipment, the scheduled load must be tendered to the carrier as requested on the bill of lading
                    at the agreed upon price, or an equipment not used (EON) fee will be assessed. All displayed transit
                    times are estimates only and do not include day of pickup. Pickup dates are not guaranteed.
                    <ol>6. Customer Obligations and Warranties</ol>
                    The Customer is responsible for and warrants their compliance with all applicable laws, rules, and regulations including
                    but not limited to customs laws, import and export laws and governmental regulation of any country to,
                    from, through or over which the shipment may be carried. Customer further warrants that it is registered
                    and in compliance with the security plan and training requirements, and any amendments related thereto,
                    related to hazardous materials, 49 C.F.R. #172.701-704, and 49 C.F.R. #172.800-804. Customer further
                    warrants that it will immediately advise Company in the event that its registration and/or compliance
                    with these regulations expires or are terminated. The Customer agrees to furnish such information and
                    documentation as necessary to establish its compliance with such laws, rules and regulations.
                    <div class="customerObligationsAndWarranties">
                        <br>
                        <br> (i) System. Customer acknowledges and agrees that, in its use of the System to purchase Services,
                        it will comply at all times and in all respects with the Terms and Conditions as provided herein.
                        <br>
                        <br> (ii) Hazardous Materials. Customer represents, warrants, and covenants that: (i) without prior
                        notice to and acceptance of Company, it will not tender for shipment to any carrier any Goods that
                        would comprise or be deemed to constitute “hazardous materials” as defined by or determined under
                        the provisions of 49 USC Sec. 5101 et seq., and the regulations promulgated thereunder (including
                        49 CFR part 171 et seq.), or any comparable state or local statue, law, ordinance, rule or regulation;
                        (ii) if, with prior approval of Company, hazardous materials are accepted for transportation by a
                        carrier, Customer further warrants that it is registered and in compliance with the security plan
                        and training requirements, and any amendments related thereto, related to hazardous materials, 49
                        C.F.R. #172.701-704, and 49 C.F.R. #172.800-804, and it will immediately advise Company in the event
                        that its registration and/or compliance with these regulations ceases, expires or is otherwise terminated.
                        The Customer agrees to furnish such information and documentation as necessary to establish its compliance
                        with all laws, rules and regulations. The Company assumes no liability to the Customer or to any
                        other person for any loss or expense due to the failure of the Customer to comply with this provision.
                        <br>
                        <br> (iii) Authority. Any individual or entity acting on behalf of the Customer in scheduling shipments
                        hereunder warrants that it has the right to act on behalf of the Customer and the right to legally
                        bind Customer. Customer agrees to indemnify Company for any and all claims or damages incurred as
                        a result of Customer's failure to comply with the provisions of this section.
                        <br>
                    </div>
                    <br> The Company nor Vanguard assumes liability to the Customer or to any other person for any loss or
                    expense due to the failure of the Customer to comply with this provision. Any individual or entity acting
                    on behalf of the Customer in scheduling shipments hereunder warrants that it has the right to act on
                    behalf of the Customer and the right to legally bind Customer. Customer agrees to indemnify Company or
                    Vanguard for any and all claims or damages incurred as a result of Customer’s failure to comply with
                    the provisions of this section.

                    <ol>7. Claims and Cargo Liability</ol>
                    Use of Company services to process claims on behalf of Subscriber is an optional service which the Company shall provide
                    for a fee determined by the Company; however, Company undertakes no responsibility for any loss or expense
                    related to cargo liability and corresponding claim(s) of Customer, but merely provides an administrative
                    service on behalf of Customer for the processing of Customer claim(s), in the event that both Customer
                    and Company mutually agree to utilize this optional service.
                    <br>
                    <br> Notwithstanding the foregoing, the Company is under no obligation to perform claims processing and
                    assumes no liability for cargo liability or claims as a result of performing claims processing services
                    on behalf of the Customer. All claims and cargo liability remain exclusively between Customer and Carrier.
                    If the Customer requests claims processing services, and the Company agrees to provide those services,
                    the Company will process claims in accordance with 49 C.F.R. 370. All claims should be submitted immediately
                    to the Company to help ensure timely resolution. The Company will use commercially reasonable efforts
                    to assist and cooperate with Customer to investigate and process any freight loss or damage claims and
                    any claim for damage to Customer’s property occurring in the course of the transportation services rendered
                    to such Customer. The liability for any cargo damage, loss, or theft from any cause shall be determined
                    under the Carmack Amendment, 49 U.S.C. 14706. The Company will use the individual carrier’s governing
                    General Rules Tariff which determines the standard liability cargo insurance coverage offered by all
                    carriers. Those Tariffs can be viewed at the Company’s corporate offices. If the shipment contains freight
                    with a predetermined exception value, as determined by the selected carrier, the maximum exception liability
                    will override the otherwise standard liability coverage. The maximum amount that Customer will receive
                    on a claim will be that which is recoverable under the respective transportation tariffs. All claims
                    must be submitted to the Company within 30 days after delivery. Claims for damages that are not readily
                    apparent (“concealed damage”) must be submitted to the Company within 3 days after delivery. The filing
                    of a claim does not relieve the responsible party for payment of freight charges. Freight payment is
                    necessary in order to process a claim. Customer may not offset freight or other charges owed to Company
                    against claims for any loss, damage, mis-delivery or non-delivery. The Company has a lien on funds recovered
                    through the processing of damage claims and reserves the right to apply recovery amounts to open past
                    due invoices on account. In no case will the maximum cargo liability for new goods be greater than $100,000
                    for a Truckload shipment or $10 per pound for an LTL shipment. In no case will the maximum cargo liability
                    for used or resold goods be more than $0.10 per pound for any shipment. The Company does offer for purchase
                    by the Customer, upon request, Customer’s interest cargo insurance all risk policies.
                    <ol>8. Payment</ol>
                    All Customers are subject to credit approval. Customers may be granted credit terms or required to make payment by credit
                    card or electronic bank debit as determined by the Company. Upon credit approval, all charges are payable
                    in US Dollars and are due within seven (7) days after date of invoice for payment. If Customer fails
                    to provide good funds within such seven (7) day period, CTS may, in its sole discretion, suspend service
                    under this Agreement to Customer. Customer agrees that freight charges due under the terms of this Agreement
                    are due in full and without deduction or setoff for any claim or other charge due or contended to be
                    due from CTS. Termination of this Agreement does not relieve Customer from any obligations incurred before
                    the Termination Date.
                    <br>
                    <br> Any payment which is past due shall be subject to an additional charge at the rate of 1.5% per month
                    of the average outstanding balance due, or the highest rate of interest permitted by applicable law,
                    whichever is less. In the event the Company retains an attorney or collection agency to collect unpaid
                    charges or for the enforcement of these Terms and Conditions, all unpaid charges will be subject to a
                    late payment penalty of 25% and Customer shall also be liable for all attorneys and collection agency
                    fees incurred, together with related costs and expenses. All Customers, shippers, consignors, consignees,
                    freight forwarders or freight brokers are jointly and severally liable for the freight charges owed to
                    the Company relating to this shipment and the Company holds a warehouseman’s general lien on all tangible
                    personal property for any outstanding balances owed to the Company. The Company reserves the right to
                    amend or adjust the original quoted amount or re-invoice the Customer if the original quoted amount was
                    based upon incorrect information received at the time of the original quote, if additional services by
                    the carrier were required, or as otherwise necessary to perform the pickup, transportation and delivery
                    functions therein. When paying by credit card or electronic funds, the Customer agrees they will be responsible
                    for all charges payable, including any adjustments, on account of such Customer’s shipment. These charges
                    and adjustments, if any, will be automatically debited to the Customer’s credit card or bank account.
                    Customer is permitted thirty (30) business days from the date of the invoice to dispute any invoiced
                    charges. If the Company does not receive a dispute within the allowable thirty (30) business days, the
                    disputed item will be denied by the Company.
                    <ol>9. Forum Selection and Choice of Law</ol>
                    Any claim, dispute or litigation relating to these Transportation Terms and Conditions, any shipment scheduled or tendered
                    hereunder or through the Company’s website, or relating to any and all disputes between the Company and
                    the enrolled Customer, Customer and/or Consignee and/or Brokers for any enrolled Customer, Customer and/or
                    Consignee, shall be filed in the Superior Court of Cobb County, Georgia or in the United States District
                    Court for the Northern District of Georgia and shall be subject to Georgia law. Customer hereby irrevocably
                    consents and submits themselves to the personal jurisdiction of said courts for such purposes. CUSTOMER
                    HEREBY IRREVOCABLY AND UNCONDITIONALLY WAIVES, TO THE FULLEST EXTENT IT MAY LEGALLY AND EFFECTIVELY DO
                    SO, TRIAL BY JURY IN ANY SUIT, ACTION OR PROCEEDING ARISING HEREUNDER.
                    <br>
                    <br>
                    <div class="sectionTitle">
                        <li> Relationship of Parties.</li>
                    </div>
                    <br>
                    <div class="termsOfService">
                        <li> Except as provided in Section 1, all parties shall be and perform their obligations under this
                            Agreement as independent contractors, and nothing in this Agreement shall be construed to create
                            any other relationship or status.</li>
                        <br>
                        <li> With respect to the services set forth in Section 1 of this Agreement, CTS shall be the agent
                            of Customer for the purpose of arranging transportation on behalf of and/or for the benefit of
                            Customer. CTS shall have the sole discretion to select the Program Providers with which it negotiates
                            and executes transportation agreements.</li>
                        <br>
                        <li>CTS and Vanguard assume full responsibility for the payment of local, state, and federal payroll
                            taxes, contributions or taxes for unemployment insurance, old age pensions, workmen’s compensation,
                            or other social security and related protection, with respect to the persons employed by CTS
                            and/or Vanguard.</li>
                        <br>
                        <li>Customer assumes full responsibility for any and all local and/or state taxes that may be assessed
                            on the services provided hereunder and/or the compensation paid by Customer therefor.</li>
                    </div>
                    <br>
                    <br>
                    <div class="sectionTitle">
                        <li>Liability.</li>
                    </div>
                    <div class="termsOfService">
                        <li>Customer agrees to defend, indemnify and hold harmless CTS and Vanguard from and against any
                            and all losses, claims, demands, liabilities or causes of action of every kind and character
                            in favor of any person or entity, for injury to or illness or death of any person or entity,
                            or for any property damage, arising out of or incident to the services provided to Customer by
                            CTS or Vanguard hereunder, except to the extent due to the negligence of CTS or Vanguard, as
                            the case may be.</li>
                        <br>
                        <br>
                        <li>Customer agrees that it will look exclusively to Program Providers to satisfy all claims arising
                            from transportation services, including for loss and damage to cargo, and hereby waives and releases
                            all such claims with respect to CTS and Vanguard. All such claims shall be resolved with the
                            Program Providers, with no recourse to CTS or Vanguard.</li>
                        <br>
                        <br>
                        <li>Customer, if acting as an Indirect Air Carrier, is responsible for selecting Transportation Security
                            Administration (“TSA”) compliant carriers for all shipments that will be moved using air freight
                            services. The TSA requires, among other things, the Indirect Air Carrier provide written notification
                            to its authorized agents detailing the security functions and responsibilities that authorized
                            agents must carry out. Any TSA requirements are the sole responsibility of the Customer.</li>
                        <br>
                        <br> 11.4 The liability of either of CTS or Vanguard under this Agreement is limited to damages caused
                        directly by the failure of CTS or Vanguard, as applicable, to provide the transportation management
                        services in accordance with this Agreement. In no event shall CTS or Vanguard be liable for direct
                        damages that exceed in amount the compensation paid by Customer hereunder for the services with respect
                        to which Customer’s claim arises, nor for any other damages of any nature whatsoever, including (i)
                        incidental, consequential, exemplary or punitive damages; (ii) damages to property or premises; and
                        (ii) damages arising partially or wholly from causes beyond the control of CTS or Vanguard, including
                        Customer’s failure to provide timely and accurate payment instructions or rate information.
                        <br>
                        <br>
                        <li> Neither CTS nor Vanguard shall be held liable for any duplicate payments resulting from the
                            processing and payment of any freight invoices other than those processed through the freight
                            payment system pursuant to this Agreement.</li>
                    </div>
                    <br>
                    <div class="sectionTitle">
                        <li> Assignability.</li>
                    </div>
                    <div class="termsOfService">
                        <p> 12.1This Agreement shall be binding upon and inure to the benefit of the Parties and their permitted
                            assigns.
                        </p>
                        <p>12.2None of the rights or obligations of this Agreement may be assigned, transferred or delegated
                            separately. This Agreement may only be assigned, transferred or delegated in its entirety as
                            a single instrument, and any such assignment, transfer or delegation may only be done pursuant
                            to this Section</p>
                        <p>12.3This Agreement may not be assigned, transferred or delegated by Customer without the prior
                            written consent of CTS, which consent may be withheld or conditioned by CTS in its sole discretion.</p>
                        <p>12.4Any attempted assignment, transfer or delegation of this Agreement in violation of this Section
                            9 shall be void.</p>
                    </div>
                    <div class="sectionTitle">
                        <li> Waiver.</li>
                    </div>
                    <div class="termsOfService">
                        <p>The failure by any Party to insist on the full and complete performance by any other Party of
                            any of its obligations shall not waive or release such Party’s right to insist on full and complete
                            performance of such obligation in the future.</p>
                    </div>
                    <br>
                    <div class="sectionTitle">
                        <li>Force Majeure.</li>
                    </div>
                    <div class="termsOfService">
                        <p>In the event that any Party is unable to meet its obligations under this Agreement due to any
                            cause beyond its reasonable control, including strikes or lockouts, labor shortages or disturbances,
                            acts of God, fires, accidents, floods, severe storms, wars, riots, acts of governmental authority,
                            and acts or omissions of third parties, the performance obligations of the Party or Parties affected
                            by the force majeure conditions shall be suspended to that extent for the duration of such event;
                            provided, however, that the Parties shall make all reasonable efforts to continue to meet their
                            obligations during the duration of the force majeure condition. The Party declaring force majeure
                            shall notify the other Parties in writing when force majeure exists, the nature of the force
                            majeure, and when the condition is terminated. The suspension of any obligations owing to force
                            majeure shall not cause the term of this Agreement to be extended or affect any rights accrued
                            under this Agreement prior to the force majeure condition.</p>
                    </div>
                    <br>
                    <div class="sectionTitle">
                        <li>Confidentiality.</li>
                    </div>
                    <div class="termsOfService">
                        <p> 15.1The Parties agree to keep all provisions of this Agreement confidential and shall not disclose
                            such without the prior written consent of the other Parties; provided, however, that the Parties
                            may disclose the terms hereof to their respective officers, directors, legal or financial advisors
                            on a need to know basis, provided such individuals agree to be bound by the terms of this Section
                            12.
                            <br>
                            <br> 15.2No news release, public announcement, denial or confirmation of same, relating to any
                            part of the subject matter of this Agreement or any phase of any program hereunder, shall be
                            made without the prior written approval of CTS and Vanguard.</p>
                    </div>
                    <br>
                    <div class="sectionTitle">
                        <li> Disclosures.</li>
                    </div>
                    <div class="termsOfService">
                        <p>
                            16.1CTS and/or Vanguard hereby disclose that they have entered and/or may enter into separate transportation management services
                            agreements with other Customers.
                            <br>
                            <br> 16.2CTS and Vanguard hereby disclose that they have entered into and/or may enter into contract
                            administration agreements with Program Providers for services related to this Agreement. CTS
                            and Vanguard further disclose that they have tied or may tie the execution of a transportation
                            agreement with a Program Provider to the contemporaneous execution of a contract administration
                            agreement with the same Program Provider.
                            <br>
                            <br> 16.3Customer hereby acknowledges and consents to these separate agreements and waives any
                            right it may have now, or in the future, to review or obtain those agreements to which it is
                            not a party.
                        </p>
                    </div>
                    <br>
                    <br>
                    <div class="sectionTitle">
                        <li>Further Assurance.</li>
                    </div>
                    <div class="termsOfService">
                        <p>In addition to the undertakings specifically provided for herein, each Party, upon request by
                            any other Party, shall take all actions and sign and deliver all documents and instruments that
                            are reasonably necessary to protect, maintain and enforce the rights granted under this Agreement.</p>
                    </div>
                    <br>
                    <div class="sectionTitle">
                        <li> Entire Agreement.</li>
                    </div>
                    <div class="termsOfService">
                        <li>All attachments to this Agreement are incorporated herein to the same extent as though fully
                            set out herein. This Agreement comprises the entire agreement of the Parties with respect to
                            the subject matter hereof. This Agreement replaces and supersedes any oral or written agreements
                            or understandings among the Parties with respect to the subject matter hereof. This Agreement
                            supersedes all prior discussions, communications, understandings, negotiations, and agreements
                            concerning the subject matter of this Agreement.</li>
                        <br>
                        <li>The Parties acknowledge that the performance of this Agreement must be conducted in conjunction
                            with numerous transportation agreements with Program Providers and should be construed as consistent
                            with those agreements. The Program Provider agreements, although related to this Agreement, are
                            separate from this Agreement and are not superseded or affected by this Agreement.</li>
                    </div>
                    <br>
                    <div class="sectionTitle">
                        <li>Construction of Terms.</li>
                    </div>
                    <div class="termsOfService">
                        <p>The terms of this Agreement have been arrived at after mutual negotiation and it is the intention
                            of the Parties that its terms not be construed against any Party by reason of the fact that it
                            was prepared by one of the Parties. The words “include”, “includes” and “including” are defined
                            as being followed by the phrase “without limitation”.</p>
                    </div>
                    <div class="sectionTitle">
                        <li> Section Captions.</li>
                    </div>
                    <div class="termsOfService">
                        <p>Section captions and the order of the Sections are for convenience and shall not affect the construction
                            of this Agreement.</p>
                    </div>
                    <div class="sectionTitle">
                        <li>Amendments.</li>
                    </div>
                    <div class="termsOfService">
                        <p>Except as otherwise provided herein, this Agreement may not be amended except in writing signed
                            by all Parties. Severability. If any part, term, or provision of this Agreement is declared unlawful
                            or unenforceable, by judicial determination or performance, the remainder of this Agreement shall
                            remain in full force and effect.</p>
                    </div>
                    <div class="sectionTitle">
                        <li>Severability</li>
                    </div>
                    <div class="termsOfService">
                        <p>If any part,term or provision of this Agreement is declared unlawful or unenforceable, by judicial
                            determination or performance, the remainder of this Agreement shall remain in full force and
                            effect.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ============================================MODAL FOOTER ROW===================================================== -->
    <div class="modal-footer">
        <div class="termsAndConditionsButtonHolder" (click)="closeModal()">
            <button class="btn-filled clickable termsAndConditionsButton">Close</button>
        </div>
    </div>

</div>