export const
  API_PARSE_ERROR_VALUES = {
    FALSE: 'false',
    NULL: 'null',
    TRUE: 'true',
  },
  FILTER_LIST_VALUE_fieldType = {
    STRING: 'string',
    DATE: 'date',
    NUMBER: 'numeric',
  },
  SEARCH_CRITERIA_type = {
    ALL: 'full_text',
    CONTACT_SHIPPER: 'contact_shipper',
    CONTACT_CONSIGNEE: 'contact_consignee',
    NAME: 'name',
    ADDRESS1: 'address1',
    ZIP: 'zip',
    CITY: 'city',
    COUNTRY: 'countryname',
    STATE: 'state',
    MASTER_DATA_CONTACT_BILL_TO: 'contact_billto',
    MASTER_DATA_CONTACT_CONSIGNEE: 'contact_consignee',
    MASTER_DATA_CONTACT_SHIPPER: 'contact_shipper',
    MASTER_DATA_CONTACT_THIRD_PARTY: 'contact_thirdparty',
    MASTER_DATA_CONTACT_CUSTOMER: 'customer',
    PRICE: 'rated_amt',
    PRICE_2: 'amount',
    SCAC: 'scac',
    DELIVERY_DATE: 'delv_date',
    ZIP_CODE: 'zip_code'  // for use in Record Creation Quote, Shipper and Consignee zip searches
  },
  SEARCH_CRITERIA_value = {
    TRUE: '1',
    FALSE: '0',
  },
  SEARCH_CRITERIA_entityType = {
    ALL: 'records',
    SHIPMENTS: 'shipments',
    QUOTES: 'quotes',
    INVOICES: 'invoices',
    USERS: 'users',
    MASTER_DATA_CONTACTS: 'contacts',
    MASTER_DATA_PRODUCTS: 'products',
    MASTER_DATA_SHIPPER: 'shipper_location',
    MASTER_DATA_MARKUPS: 'markups',
    ZIP_POSTAL: 'cityzip',
    SEARCH: 'search',
  },
  SEARCH_CRITERIA_boolQuery = {
    MUST: 'must',
    MUST_NOT: 'must_not',
    SHOULD: 'should'
  },
  SEARCH_CRITERIA_pattern = {
    MATCH: 'match',
    EXACT: 'match_phrase',
    RANGE: 'range',
    WILDCARD: 'wildcard',
    FILTER_LIST: 'aggregate',
  },
  SEARCH_ENTITY_FILTER_DATE_VALUES = [
    'pickup_date',
    'delivery_date',
    'shipdate',
    'wk_end_date',
    'delv_date',
    'ship_date',
    'paid_date',
    'recd_date'
  ],
  SEARCH_CRITERIA_FROM = {
    default: 0,
  },
  SEARCH_CRITERIA_SIZE = {
    default: 20,
  };
