import { Component, OnDestroy, OnInit } from '@angular/core';
import {SignalsService} from "../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {SearchService} from "../../services/search.service";

@Component({
    selector: 'app-loader-graphic',
    styleUrls: ['./loaderGraphic.component.scss'],
    templateUrl: './loaderGraphic.component.html',
})

export class LoaderGraphicMovingBallComponent implements OnInit, OnDestroy {

    public shouldLoaderBeFixed: boolean = true;
    public shouldLoaderScroll: boolean = false;

    public isLoaderVisible: boolean = false;
    public isBlockerVisible: boolean = false;
    private loaderStateSubscription: any;

    private currentTimeout: any;
    private delay: number = 300; // delay in milliseconds
    private loader$;

    constructor(
        public _searchService: SearchService,
        private _signalsService: SignalsService,
    ) {
        this.loader$ = toObservable(this._signalsService.loadingBarSignal);
    }

    public ngOnInit() {
        this.loaderStateSubscription = this.loader$
            .subscribe((loader) => {
                this.isLoaderVisible = loader.isLoaderVisible;
                this.isBlockerVisible = loader.isBlockerVisible;
                this._signalsService.updateAppState({ 'record.isLoaderOverlayShown': this.isBlockerVisible });

                // use delay/timeout to only show when needed
                if (this.currentTimeout) {
                    return;
                }
                this.currentTimeout = setTimeout(() => {
                    this.cancelTimeout();
                }, this.delay);
            });
    }

    public ngOnDestroy() {
        this.loaderStateSubscription.unsubscribe();
        this.cancelTimeout();
    }

    public cancel(): void {
        this._searchService.doCancel();
        this._signalsService.stopLoadingBar();
    }

    public determineLoadingBarPosition(event: Event): void {
        const scrollMargin = document.documentElement.scrollTop || document.body.scrollTop;
        this.shouldLoaderBeFixed = (scrollMargin >= 10) ? false : true;
        this.shouldLoaderScroll = (scrollMargin >= 10) ? true : false;
    }

    private cancelTimeout(): void {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = undefined;
    }
}

/**
 * StackOverflow post by the gurus on how to cancel HTTP requests =>
 * https://stackoverflow.com/questions/38008334/angular-rxjs-when-should-i-unsubscribe-from-subscription/41177163#41177163
 */

 // useful link for implementing delay when showing loader/blocker => https://stackoverflow.com/questions/36075152/how-to-achieve-block-ui-kind-of-behaviour-in-angular-2
/*
    private currentTimeout: any;
    private delay:number = 300; //delay in milliseconds
         // use delay/timeout to only show when needed
        if (this.currentTimeout) {
            return;
        }
        this.currentTimeout = setTimeout(() => {
            // this.isDelayedRunning = value;
            this.cancelTimeout();
        }, this.delay);
     private cancelTimeout(): void {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = undefined;
    }
 */
