import {mapToCanActivate, Routes} from '@angular/router';

import { MasterDataContactsComponent } from 'app/components/masterData/contacts/masterData-contacts.component';
import { NmfcSearchComponent } from 'app/components/masterData/nmfcSearch/nmfcSearch.component';
import {AppForgotPasswordComponent} from './components/app-forgot-password/app-forgot-password.component';
import {AppPasswordResetComponent} from './components/app-password-reset/app-password-reset.component';
import {AppShipmentComponent} from './components/app-shipment/app-shipment.component';
import {AppTourBaseComponent} from './components/app-tour-base/app-tour-base.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { CreateViewComponent } from './components/dashboard/create-view/create-view.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { MasterDataCarriersComponent } from './components/masterData/carriers/carriers.component';
import { MasterDataComponent } from './components/masterData/masterdata.component';
import { MasterDataProductsComponent } from './components/masterData/products/masterData-products.component';
import { PageNotFoundComponent } from './components/pageNotFound/pageNotFound.component';
import { ProductsComponent } from './components/products/products.component';
import {QuickQuoteDisplayComponent} from './components/quick-quote-display/quick-quote-display.component';
import {QuickQuoteComponent} from './components/quick-quote/quick-quote.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ReportsInvoiceDetailComponent } from './components/reports/reports-invoice/reports-invoice-detail/reports-invoice-detail.component';
import { ReportsInvoiceListComponent } from './components/reports/reports-invoice/reports-invoice-list/reports-invoice-list.component';
import { ReportsShipmentDetailComponent } from './components/reports/reports-shipment/reports-shipment-detail/reports-shipment-detail.component';
import { ReportsShipmentListComponent } from './components/reports/reports-shipment/reports-shipment-list/reports-shipment-list.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SearchComponent } from './components/search/search/search.component';
import { AuthGuard } from './guards/auth.guard';
import {AppLockedOutComponent} from "./components/app-locked-out/app-locked-out.component";
import {AppQuoteRedirectComponent} from "./components/app-quote-redirect/app-quote-redirect.component";

export const APP_ROUTES: Routes = [
    // MasterData screens
    { path: 'masterData', component: MasterDataComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'masterData/products', component: MasterDataProductsComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'masterData/products/nmfc/search', component: NmfcSearchComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'masterData/products/:productId', component: MasterDataProductsComponent, canActivate: mapToCanActivate([AuthGuard]) },

    { path: 'masterData/contacts', component: MasterDataContactsComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'masterData/contacts/:contactId', component: MasterDataContactsComponent, canActivate: mapToCanActivate([AuthGuard]) },

    { path: 'masterData/carriers', component: MasterDataCarriersComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'masterData/carriers/:scac', component: MasterDataCarriersComponent, canActivate: mapToCanActivate([AuthGuard]) },

    // Quick Quote screens
    { path: 'quick-quote/:quickQuoteId', component: QuickQuoteComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'quick-quote-display/:quickQuoteId', component: QuickQuoteDisplayComponent, canActivate: mapToCanActivate([AuthGuard]) },

    // Dashboard screens
    { path: 'createView', component: CreateViewComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'dashboard', component: DashboardComponent, canActivate: mapToCanActivate([AuthGuard]) },
    // Search screens
    { path: 'search', component: SearchComponent, canActivate: mapToCanActivate([AuthGuard]) },
    // Misc screens
    { path: 'registration', component: RegistrationComponent },
    { path: 'registration/:customerCode', component: RegistrationComponent },
    { path: 'contacts', component: ContactsComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'products', component: ProductsComponent, canActivate: mapToCanActivate([AuthGuard]) },
    // Reports
    { path: 'reports', component: ReportsComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'reports/invoices', component: ReportsInvoiceListComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'reports/invoices/:invoiceId', component: ReportsInvoiceDetailComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'reports/shipments', component: ReportsShipmentListComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'reports/shipments/:shipmentId', component: ReportsShipmentDetailComponent, canActivate: mapToCanActivate([AuthGuard]) },
    // Tour
    { path: 'tour-guide', component: AppTourBaseComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'shipments/:customerId/:shipmentUnifiedId', component: AppShipmentComponent, canActivate: mapToCanActivate([AuthGuard]) },
    { path: 'quote/:quoteId', component: AppQuoteRedirectComponent, canActivate: mapToCanActivate([AuthGuard])},
    { path: 'forgot-password', component: AppForgotPasswordComponent },
    { path: 'locked-out', component: AppLockedOutComponent },
    // Root
    { path: '', component: HomeComponent, },
    { path: 'passwordReset/:token', component: AppPasswordResetComponent },
    // Catch all for invalid routes
    { path: '**', component: PageNotFoundComponent }
];
