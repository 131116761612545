import { CustomerDetailPickupRequest } from './customerDetail.pickupRequest';
import { CustomerDetailStatusUpdate } from './customerDetail.statusUpdate';
import { LogoDetail } from './logoDetail';

export class CustomerDetailMasterData {
  custNo: string; // main customer ID value
  custActive: number;
  APIEDI: {
    StatusUpdates: CustomerDetailStatusUpdate;
    PickupRequest: CustomerDetailPickupRequest
  };
  OLTCredentials: {
    WINIntegration: {
      srodUID: number;
      defaultClass: string;
      sellUID: number;
      organizationName: string;
      buyUID: number;
      netUID: number;
    };
    WIN: {
      password: string;
      username: string;
    };
  };
  SROD: {
    assignedUser: number;
    opsGroup: string;
    teamEmail: string;
    srodName: string;
  };
  invoices: {
    updateCheckData: number;
    oppReports: number; // boolean wrapped as number
  };
  logo: LogoDetail;
  custAcclRebateActive: number = 0;
  custAcctMgr: string = '';
  custActiveStatus: number = 0;
  custAddress1: string = '';
  custAddress2: string = '';
  custAddress3: string = '';
  custARTCRate: string = '0'; // number wrapped as a string, need to parse
  custAudFeeActive: number = 0;
  custBalDueAllow: number = 0;
  custBDAPercent: number = 0;
  custBusType: string = '';
  custBuyAmt: number = 0;
  custBuyInv: number = 0;
  custBuyMin: number = 0;
  custBuyPercent: number = 0;
  custCanadaCheck: number = 0;
  custCity: string = '';
  custClaimsFeeActive: number = 0;
  custClaimsRebateActive: number = 0;
  custClaimsRebateAmt: number = 0;
  custComment: string = '';
  custContact: string = '';
  custCountry: string = '';
  custCreditMgr: string = '';
  custCTScompCust: string = '';
  custCurrency: string = '';
  custEDIRef: string = '';
  custEmail: string = '';
  custEndDate: string = ''; // YYYY-MM-DD, ex: 1970-01-01
  custFax: string = '';
  custFaxArea: string = '';
  custFaxCountry: string = '';
  custFeeEmail: string = '';
  custFeeFTP: string = '';
  custFeeFTPDir: string = '';
  custFeeFTPLogin: string = '';
  custFeeFTPPass: string = '';
  custFeeFTPPort: number = 0;
  custFeeSendBy: string = '';
  custFiscalStartDate: string = ''; // YYYY-MM-DD, ex: 1970-01-01
  custFrtBillPaymentTerms: string = '';
  custFrtBillPayToAcct: string = '';
  custInvEmail: string = '';
  custInvFTP: string = '';
  custInvFTPDir: string = '';
  custInvFTPLogin: string = '';
  custInvFTPPass: string = '';
  custInvFTPPort: number = 0;
  custInvoicePath: string = '';
  custInvSendBy: string = '';
  custIsParent: number = 0; // boolean as number, 0 or 1
  custMarketSeg: string = '';
  custMarketSubSeg: string = '';
  custName: string = '';
  custNetInv: number = 0;
  custPaidRebate: number = 0;
  custPaidRebateActive: number = 0;
  custPaidRebateType: string = '';
  custParentOrg: string = '';
  custPhone: string = '';
  custPhoneArea: string = '';
  custPhoneCountry: string = '';
  custPhoneExt: string = '';
  custPink: number = 0;
  custProjectedAnnlSpend: number = 0;
  custRejectAFee: number = 0;
  custRejectBFee: number = 0;
  custRejectDFee: number = 0;
  custRejectFeeActive: number = 0;
  custRejectLFee: number = 0;
  custRejectNFee: number = 0;
  custRejectPFee: number = 0;
  custReqBL: number = 0;
  custReqGL: number = 0;
  custReqLoc: number = 0;
  custReqPO: number = 0;
  custSalesRep: string = '';
  custSellAmt: number = 0;
  custSellInv: number = 0;
  custSellMin: number = 0;
  custSellPercent: number = 0;
  custSellType: string = '';
  custServiceFeeActive: number = 0; // boolean as number, 0 or 1
  custServiceFeePath: string = '';
  custServiceFeePayToAcct: string = '';
  custServiceFeeTerm: string = '';
  custSourcingParent: string = '';
  custStartDate: string = ''; // YYYY-MM-DD ex. 2007-04-02
  custState: string = '';
  custTerms: string = '';
  custTMEmail: string = '';
  custTMFax: string = '';
  custTMFaxArea: string = '';
  custTMFaxCountry: string = '';
  custTMPhone: string = '';
  custTMPhoneArea: string = '';
  custTMPhoneCountry: string = '';
  custTMPhoneExt: string = '';
  custTrafficMan: string = '';
  custTransFeeActive: number = 0; // boolean as number, 0 or 1
  custTransferGL: number = 0;
  custTransferLoc: number = 0;
  custWebReport: string = '';
  custZip: string = '';
  custZip2: string = '';
  notificationText: string = '';
  tmaAccepted: number = 0; // boolean as number, 0 or 1
  tmaFirstName: string = '';
  tmaID: number = 0;
  tmaLastName: string = '';
  tmaTitle: string = '';
  tmaUser: number = 0;
  chatActive: number = 1;
  insurancePrompt: number = 1;
}
