import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import * as DateConstants from '../constants/datetime.constants';
import {DateTimeHelper} from '../helpers/datetime.helper';
import {clearArray} from '../helpers/utilities';
import {CustomerMasterData} from '../models/customer.masterData';
import {CustomerDetailMasterData} from '../models/customerDetail.masterData';
import { EntityService } from './entity.service';

@Injectable()
export class CustomerService {
    public customers = [];
    private lastCustomer: CustomerDetailMasterData;

    constructor(
        private _fb: UntypedFormBuilder,
        private _dateTimeHelper: DateTimeHelper,
        private _entityService: EntityService,
        private _dateHelper: DateTimeHelper,
    ) {}

    public getCustomerList() {
        return this._entityService.getEntityList<CustomerMasterData>('Customer').pipe(
            map(list => {
                if (Array.isArray(list)) {
                    clearArray(this.customers);
                    this.customers.push(...list);
                }
            })
        );
    }

    public deleteCustomer(id) {
        return this._entityService.deleteEntity(id, 'Customer');
    }

    public createCustomer(customer) {
        return this._entityService.createEntity<CustomerDetailMasterData>(customer, 'Customer', 'custNo', 'custNo', 'admin/customers');
    }

    public updateCustomer(customer) {
        return this._entityService.updateEntity<CustomerDetailMasterData>(customer, 'Customer', customer.custNo, 'custNo');
    }

    public getCustomer(id) {
        return this._entityService.getEntity<CustomerDetailMasterData>('Customer', id);
    }

    public getCustomerByPath(id) {
        switch (id) {
            case 'new':
                return new Observable(subscriber => {
                    this.lastCustomer = new CustomerDetailMasterData();
                    subscriber.next(_.cloneDeep(this.lastCustomer));
                    subscriber.complete();
                });

            default:
                return this.getCustomer(id).pipe(
                    map(customer => {
                        if (Object.keys(customer).length > 0) {
                            this.lastCustomer = customer as CustomerDetailMasterData;
                        }
                        return _.cloneDeep(this.lastCustomer);
                    }),
                    catchError(err => {
                        throw err;
                    })
                )
        }
    }

    getCustomerFormFromLastCustomer() {
        const customerForm: UntypedFormGroup = this._fb.group({
            // ===================== GENERAL TAB PORTION =====================
            custNo: new UntypedFormControl(this.lastCustomer.custNo, {
                validators: [
                    Validators.required,
                    Validators.maxLength(6),
                    Validators.pattern('\\b(?!new\\b)\\S+'),
                ],
            }),
            custName: new UntypedFormControl(this.lastCustomer.custName, {
                validators: [
                    Validators.required
                ],
            }),
            custActive: this.lastCustomer.custActive ? this.lastCustomer.custActive === 1 : false,
            custStartDate: new UntypedFormControl(
                (this.lastCustomer.custStartDate)
                    ? moment(this.lastCustomer.custStartDate).format(DateConstants.UNIFORM_DATE_DISPLAY.format)
                    : this._dateHelper.getFormattedCurrentDate(), {
                    validators: [
                        this._dateHelper.dateValueValidator(),
                    ],
                    updateOn: 'blur'
                }),
            custEDIRef: new UntypedFormControl(this.lastCustomer.custEDIRef, {
                validators: [
                    Validators.maxLength(3),
                ],
            }),
            logo_logo: this.lastCustomer.logo ? this.lastCustomer.logo.logo : '',
            logo_filename: this.lastCustomer.logo ? this.lastCustomer.logo.filename : '',
            logo_height: this.lastCustomer.logo ? this.lastCustomer.logo.height : 0,
            logo_width: this.lastCustomer.logo ? this.lastCustomer.logo.width : 0,

            custMarketSeg: this.lastCustomer.custMarketSeg ? this.lastCustomer.custMarketSeg : '',
            custMarketSubSeg: this.lastCustomer.custMarketSubSeg ? this.lastCustomer.custMarketSubSeg : '',
            custAcctMgr: this.lastCustomer.custAcctMgr ? this.lastCustomer.custAcctMgr : '',
            custSalesRep: this.lastCustomer.custSalesRep ? this.lastCustomer.custSalesRep : '',

            tmaID: new UntypedFormControl(this.lastCustomer.tmaID ? this.lastCustomer.tmaID : '', {
                validators: [
                    Validators.required
                ],
            }),
            tmaAccepted: !!this.lastCustomer.tmaAccepted,
            SROD_opsGroup: this.lastCustomer.SROD ? this.lastCustomer.SROD.opsGroup : '',
            SROD_assignedUser: this.lastCustomer.SROD ? this.lastCustomer.SROD.assignedUser : '',
            chatActive: this.lastCustomer.chatActive ? this.lastCustomer.chatActive === 1 : false,
            insurancePrompt: this.lastCustomer.insurancePrompt ? true : false,
            invoices_oppReports: this.lastCustomer.invoices ? this.lastCustomer.invoices.oppReports === 1 : false,
            SROD_teamEmail: this.lastCustomer.SROD ? this.lastCustomer.SROD.teamEmail : '',
            SROD_srodName: new UntypedFormControl(this.lastCustomer.SROD ? this.lastCustomer.SROD.srodName : '', {
                validators: [
                    Validators.maxLength(45),
                ],
            }),

            // ===================== OLT CREDENTIALS PORTION =====================
            OTLCredentials_WIN_username: this.lastCustomer.OLTCredentials && this.lastCustomer.OLTCredentials.WIN ? this.lastCustomer.OLTCredentials.WIN.username : '',
            OLTCredentials_WIN_password: this.lastCustomer.OLTCredentials && this.lastCustomer.OLTCredentials.WIN ? this.lastCustomer.OLTCredentials.WIN.password : '',

            OLTCredentials_WINIntegration_organizationName: this.lastCustomer.OLTCredentials && this.lastCustomer.OLTCredentials.WINIntegration ? this.lastCustomer.OLTCredentials.WINIntegration.organizationName : '',
            OLTCredentials_WINIntegration_srodUID: this.lastCustomer.OLTCredentials && this.lastCustomer.OLTCredentials.WINIntegration ? this.lastCustomer.OLTCredentials.WINIntegration.srodUID : '',
            OLTCredentials_WINIntegration_netUID: this.lastCustomer.OLTCredentials && this.lastCustomer.OLTCredentials.WINIntegration ? this.lastCustomer.OLTCredentials.WINIntegration.netUID : '',
            OLTCredentials_WINIntegration_buyUID: this.lastCustomer.OLTCredentials && this.lastCustomer.OLTCredentials.WINIntegration ? this.lastCustomer.OLTCredentials.WINIntegration.buyUID : '',
            OLTCredentials_WINIntegration_sellUID: this.lastCustomer.OLTCredentials && this.lastCustomer.OLTCredentials.WINIntegration ? this.lastCustomer.OLTCredentials.WINIntegration.sellUID : '',
            OLTCredentials_WINIntegration_defaultClass: this.lastCustomer.OLTCredentials && this.lastCustomer.OLTCredentials.WINIntegration ? this.lastCustomer.OLTCredentials.WINIntegration.defaultClass : '',
        });

        return customerForm;
    }

    public updateLastCustomer(customerResult: CustomerDetailMasterData) {
        this.lastCustomer = customerResult;
    }

    public getCustomerFromForm(customerForm) {
        const customer: CustomerDetailMasterData = _.cloneDeep(this.lastCustomer);

        if (!customer.custNo) {
            customer.custStartDate = this._dateTimeHelper.getPreparedCurrentDate(DateConstants.DATE_FORMAT_yyyymmdd);
            customer.custFiscalStartDate = this._dateTimeHelper.getPreparedCurrentDate(DateConstants.DATE_FORMAT_yyyymmdd);
            customer.custEndDate = this._dateTimeHelper.getPreparedCurrentDate(DateConstants.DATE_FORMAT_yyyymmdd);
        } else {
            customer.custStartDate = this._dateHelper.prepareDate(customerForm.get('custStartDate').value, DateConstants.DATE_FORMAT_yyyymmdd);
        }

        // eslint-disable-next-line no-unused-expressions
        (customerForm.controls.custNo.value) ? customer.custNo = customerForm.get('custNo').value : null;
        customer.custName = customerForm.get('custName').value;
        customer.custActive = customerForm.get('custActive').value ? 1 : 0;
        customer.custEDIRef = customerForm.controls.custEDIRef.value;
        customer.tmaID = customerForm.get('tmaID').value;
        customer.tmaAccepted = (customerForm.controls.tmaAccepted.value) ? 1 : 0;
        customer.logo = {
            logo: customerForm.get('logo_logo').value,
            filename: customerForm.get('logo_filename').value,
            width: customerForm.get('logo_width').value,
            height: customerForm.get('logo_height').value,
        };
        customer.custMarketSeg = customerForm.get('custMarketSeg').value;
        customer.custMarketSubSeg = customerForm.get('custMarketSubSeg').value;
        customer.custAcctMgr = customerForm.get('custAcctMgr').value;
        customer.custSalesRep = customerForm.get('custSalesRep').value;
        customer.invoices = {
            updateCheckData: 0,
            oppReports: customerForm.get('invoices_oppReports').value ? 1 : 0,
        };
        customer.insurancePrompt = customerForm.get('insurancePrompt').value ? 1 : 0;
        customer.chatActive = customerForm.get('chatActive').value ? 1 : 0;
        customer.SROD = {
            opsGroup: customerForm.get('SROD_opsGroup').value,
            assignedUser: customerForm.get('SROD_assignedUser').value,
            teamEmail: customerForm.get('SROD_teamEmail').value,
            srodName: customerForm.get('SROD_srodName').value,
        }
        customer.OLTCredentials = {
            // WIN CREDENTIALS SUB-PORTION
            WIN: {
                username: customerForm.get('OTLCredentials_WIN_username').value,
                password: customerForm.get('OLTCredentials_WIN_password').value,
            },

            // WIN INTEGRATION SUB-PORTION
            WINIntegration: {
                organizationName: customerForm.get('OLTCredentials_WINIntegration_organizationName').value,
                srodUID: customerForm.get('OLTCredentials_WINIntegration_srodUID').value,
                netUID: customerForm.get('OLTCredentials_WINIntegration_netUID').value,
                buyUID: customerForm.get('OLTCredentials_WINIntegration_buyUID').value,
                sellUID: customerForm.get('OLTCredentials_WINIntegration_sellUID').value,
                defaultClass: customerForm.get('OLTCredentials_WINIntegration_defaultClass').value,
            }
        }

        return customer;
    }

    public updateForm(customerForm: UntypedFormGroup) {
        let a;
        a = (this.lastCustomer.custNo) ? customerForm.controls.custNo.setValue(this.lastCustomer.custNo) : null;
        a = (this.lastCustomer.custName) ? customerForm.controls.custName.setValue(this.lastCustomer.custName) : null;
        customerForm.controls.custActive.setValue(this.lastCustomer.custActive === 1);
        a = (this.lastCustomer.custStartDate) ? customerForm.controls.custStartDate.setValue(moment(this.lastCustomer.custStartDate).format(DateConstants.UNIFORM_DATE_DISPLAY.format)) : null;
        a = (this.lastCustomer.custEDIRef) ? customerForm.controls.custEDIRef.setValue(this.lastCustomer.custEDIRef) : null;
        a = (this.lastCustomer.tmaID) ? customerForm.controls.tmaID.setValue(this.lastCustomer.tmaID) : null;
        a = (this.lastCustomer.tmaAccepted) ? customerForm.controls.tmaAccepted.setValue(this.lastCustomer.tmaAccepted) : null;
        if (this.lastCustomer.logo) {
            customerForm.controls['logo_logo'].setValue(this.lastCustomer.logo.logo);
            customerForm.controls['logo_filename'].setValue(this.lastCustomer.logo.filename);
            customerForm.controls['logo_height'].setValue(this.lastCustomer.logo.height);
            customerForm.controls['logo_width'].setValue(this.lastCustomer.logo.width);
        }
        a = (this.lastCustomer.custMarketSeg) ? customerForm.controls.custMarketSeg.setValue(this.lastCustomer.custMarketSeg) : null;
        a = (this.lastCustomer.custMarketSubSeg) ? customerForm.controls.custMarketSubSeg.setValue(this.lastCustomer.custMarketSubSeg) : null;
        a = (this.lastCustomer.custAcctMgr) ? customerForm.controls.custAcctMgr.setValue(this.lastCustomer.custAcctMgr) : null;
        a = (this.lastCustomer.custSalesRep) ? customerForm.controls.custSalesRep.setValue(this.lastCustomer.custSalesRep) : null;
        customerForm.controls.chatActive.setValue(this.lastCustomer.chatActive === 1);
        customerForm.controls.insurancePrompt.setValue(this.lastCustomer.insurancePrompt === 1);
        if (this.lastCustomer.invoices) {
            customerForm.controls.invoices_oppReports.setValue(this.lastCustomer.invoices.oppReports === 1);
        } else {
            customerForm.controls.invoices_oppReports.setValue(false);
        }
        if (this.lastCustomer.SROD) {
            customerForm.controls['SROD_opsGroup'].setValue(this.lastCustomer.SROD.opsGroup);
            customerForm.controls['SROD_assignedUser'].setValue(this.lastCustomer.SROD.assignedUser);
            customerForm.controls['SROD_teamEmail'].setValue(this.lastCustomer.SROD.teamEmail);
            customerForm.controls['SROD_srodName'].setValue(this.lastCustomer.SROD.srodName);
        }
        if (this.lastCustomer.OLTCredentials && this.lastCustomer.OLTCredentials.WIN) {
            customerForm.controls['OTLCredentials_WIN_username'].setValue(this.lastCustomer.OLTCredentials.WIN.username);
            customerForm.controls['OLTCredentials_WIN_password'].setValue(this.lastCustomer.OLTCredentials.WIN.password);
        } else {
            customerForm.controls['OTLCredentials_WIN_username'].setValue('');
            customerForm.controls['OLTCredentials_WIN_password'].setValue('');
        }
        if (this.lastCustomer.OLTCredentials && this.lastCustomer.OLTCredentials.WINIntegration) {
            customerForm.controls['OLTCredentials_WINIntegration_organizationName'].setValue(this.lastCustomer.OLTCredentials.WINIntegration.organizationName);
            customerForm.controls['OLTCredentials_WINIntegration_srodUID'].setValue(this.lastCustomer.OLTCredentials.WINIntegration.srodUID);
            customerForm.controls['OLTCredentials_WINIntegration_netUID'].setValue(this.lastCustomer.OLTCredentials.WINIntegration.netUID);
            customerForm.controls['OLTCredentials_WINIntegration_buyUID'].setValue(this.lastCustomer.OLTCredentials.WINIntegration.buyUID);
            customerForm.controls['OLTCredentials_WINIntegration_sellUID'].setValue(this.lastCustomer.OLTCredentials.WINIntegration.sellUID);
            customerForm.controls['OLTCredentials_WINIntegration_defaultClass'].setValue(this.lastCustomer.OLTCredentials.WINIntegration.defaultClass);
        } else {
            customerForm.controls['OLTCredentials_WINIntegration_organizationName'].setValue('');
            customerForm.controls['OLTCredentials_WINIntegration_srodUID'].setValue('');
            customerForm.controls['OLTCredentials_WINIntegration_netUID'].setValue('');
            customerForm.controls['OLTCredentials_WINIntegration_buyUID'].setValue('');
            customerForm.controls['OLTCredentials_WINIntegration_sellUID'].setValue('');
            customerForm.controls['OLTCredentials_WINIntegration_defaultClass'].setValue('');
        }
    }

    formEqualsLastCustomer(customerForm: UntypedFormGroup) {
        return (this.lastCustomer.custNo === customerForm.controls.custNo.value) &&
            (this.lastCustomer.custName === customerForm.controls.custName.value) &&
            ((this.lastCustomer.custActive ? (this.lastCustomer.custActive === 1 ? 1 : 0) : 0) === (customerForm.controls.custActive.value ? 1 : 0)) &&
            (this.lastCustomer.custStartDate === this._dateHelper.prepareDate(customerForm.controls.custStartDate.value, DateConstants.DATE_FORMAT_yyyymmdd)) &&
            (this.lastCustomer.custEDIRef === customerForm.controls.custEDIRef.value) &&
            (this.lastCustomer.tmaID === customerForm.controls.tmaID.value) &&
            (this.lastCustomer.tmaAccepted === (customerForm.controls.tmaAccepted.value ? 1 : 0)) &&
            (this.lastCustomer.logo.logo === customerForm.controls.logo_logo.value) &&
            (this.lastCustomer.logo.filename === customerForm.controls.logo_filename.value) &&
            (this.lastCustomer.logo.height === customerForm.controls.logo_height.value) &&
            (this.lastCustomer.logo.width === customerForm.controls.logo_width.value) &&
            (this.lastCustomer.custMarketSeg === customerForm.controls.custMarketSeg.value) &&
            (this.lastCustomer.custMarketSubSeg === customerForm.controls.custMarketSubSeg.value) &&
            (this.lastCustomer.custAcctMgr === customerForm.controls.custAcctMgr.value) &&
            (this.lastCustomer.custSalesRep === customerForm.controls.custSalesRep.value) &&
            ((this.lastCustomer.invoices ? (this.lastCustomer.invoices.oppReports === 1 ? 1 : 0) : 0) === (customerForm.controls.invoices_oppReports.value ? 1 : 0)) &&
            ((this.lastCustomer.insurancePrompt ? (this.lastCustomer.insurancePrompt === 1 ? 1 : 0) : 0) === (customerForm.controls.insurancePrompt.value ? 1 : 0)) &&
            ((this.lastCustomer.chatActive ? (this.lastCustomer.chatActive === 1 ? 1 : 0) : 0) === (customerForm.controls.chatActive.value ? 1 : 0)) &&
            (this.lastCustomer.SROD.assignedUser === customerForm.controls.SROD_assignedUser.value) &&
            (this.lastCustomer.SROD.opsGroup === customerForm.controls.SROD_opsGroup.value) &&
            (this.lastCustomer.SROD.teamEmail === customerForm.controls.SROD_teamEmail.value) &&
            (this.lastCustomer.SROD.srodName === customerForm.controls.SROD_srodName.value) &&
            (this.lastCustomer.OLTCredentials.WIN.username === customerForm.controls.OTLCredentials_WIN_username.value) &&
            (this.lastCustomer.OLTCredentials.WIN.password === customerForm.controls.OLTCredentials_WIN_password.value) &&
            (this.lastCustomer.OLTCredentials.WINIntegration.organizationName === customerForm.controls.OLTCredentials_WINIntegration_organizationName.value) &&
            (this.lastCustomer.OLTCredentials.WINIntegration.srodUID === customerForm.controls.OLTCredentials_WINIntegration_srodUID.value) &&
            (this.lastCustomer.OLTCredentials.WINIntegration.netUID === customerForm.controls.OLTCredentials_WINIntegration_netUID.value) &&
            (this.lastCustomer.OLTCredentials.WINIntegration.buyUID === customerForm.controls.OLTCredentials_WINIntegration_buyUID.value) &&
            (this.lastCustomer.OLTCredentials.WINIntegration.sellUID === customerForm.controls.OLTCredentials_WINIntegration_sellUID.value) &&
            (this.lastCustomer.OLTCredentials.WINIntegration.defaultClass === customerForm.controls.OLTCredentials_WINIntegration_defaultClass.value);
    }
}
