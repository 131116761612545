import {HttpErrorResponse, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import * as Constants from '../constants/constants';
import * as SearchConstants from '../constants/searchCriteria';
import {clearArray} from '../helpers/utilities';
import {CarrierMasterData} from '../models/carrier.masterData';
import {HandlingUnit} from '../models/handlingUnit';
import {MarkupRangeType} from '../models/markup.rangeType';
import {RateOn} from '../models/markupRateOn';
import {MarkupType} from '../models/markupType';
import {NmfcGroup} from '../models/nmfcGroup';
import {PackageType} from '../models/packageType';
import {RateTypeResponse} from '../models/rateTypeResponse';
import {SRODAssignedUser} from '../models/SRODAssignedUser';
import {SRODOpsGroup} from '../models/SRODOpsGroup';
import {AccessorialForQuote} from '../models/accessorialForQuote';
import {CommodityProducts} from '../models/commodityProducts';
import {Country} from '../models/country';
import {EquipmentType} from '../models/equipmentType';
import {MarkupRateTierOption} from '../models/markupRateTierOption';
import {MasterDataContractID} from '../models/masterDataContractID';
import {NameCode} from '../models/nameCode';
import {NmfcClass} from '../models/nmfcClass';
import {QuoteUID} from '../models/quoteUID';
import {ResponseDTO} from '../models/responseDto';
import {RoleType} from '../models/roleDetailRoleType';
import {SearchResponse} from '../models/searchResponse';
import {ShipmentUID} from '../models/shipmentUID';
import {StateMasterData} from '../models/state.masterData';
import {TmaOption} from '../models/tmaOption';
import {UnitOfMeasure} from '../models/unitOfMeasure';
import {ApiService, REQUEST_TYPE_GET, REQUEST_TYPE_POST} from './api.service';
import {TmaRequest} from "../models/tmaRequest";
import {ClassRequest} from "../models/classRequest";
import {Observable} from "rxjs";
import {ClassResponse} from "../models/classResponse";
import {NotificationService} from "./notification.service";
import {SignalsService} from "./signals.service";
import {toObservable} from "@angular/core/rxjs-interop";

@Injectable()
export class CommonDataService {
    public availableAccessorials = [];
    public accessorialGroups = [];
    public contractIds = [];
    public billToContacts = [];
    public rateOptions = [];
    public fuelOptions = [];
    public acclOptions = [];
    public srodOpsGroupOptions = [];
    public srodAssignedUsersOptions = [];
    public nmfcClasses = [];
    public nmfcGroups = [];
    public handlingUnits = [];
    public packageTypes = [];
    public roleTypes = [];
    public countries = [];
    public states = [];
    public recordTabOptions = [];
    public unitOfMeasures = [];
    public commodityProducts = [];
    public tmaList = [];
    public equipmentTypes = [];
    public carriers = [];
    public markupTypes = [];
    public rateOnOptions = [];
    public markupRangeTypes = [];
    public tmaText;
    public nameCodeMap: {
        custAcctMgr: Array<NameCode>,
        custSalesRep: Array<NameCode>,
        custMarketSeg: Array<NameCode>,
        custMarketSubSeg: Array<NameCode>
    } = {
        custAcctMgr: [],
        custSalesRep: [],
        custMarketSeg: [],
        custMarketSubSeg: []
    }
    public insuranceTermsOfAgreement;
    public beAdvisedMessage;

    public record = {
        direction: Constants.DEFAULT_DIRECTION_INBOUND,
        equipmentType: 0
    }

    public menuItemPaths;

    private loaded: Map<any, boolean> = new Map<any, boolean>();
    private displayData$;

    constructor(
        private _notificationService: NotificationService,
        private _api: ApiService,
        private _signalsService: SignalsService,
    ) {
        this.displayData$ = toObservable(this._signalsService.displaySignal);
    }

    public loadedPromise(ref) {
        return new Promise<Array<any>>((resolve) => {
            this.waitForArrayLoadRef(ref, resolve);
        })
    }

    public getDisplayData() {
        let displayDataState = null;
        this.displayData$.subscribe((displayData) => {
            displayDataState = displayData;
        });

        return displayDataState;
    }

    public getDisplayDataEntityFilterFields(entityFilter: string): object {
        const displayData = this.getDisplayData();

        if (!displayData || !entityFilter || !displayData['searchEntityFilters']) {
            return null;
        }

        return displayData['searchEntityFilters'][entityFilter];
    }

    public getDisplayDataEntityDateFields(entityFilter: string): Array<string> {
        const displayData = this.getDisplayData();

        if (!displayData || !entityFilter || !displayData['dateFields']) {
            return null;
        }

        return displayData['dateFields'][entityFilter];
    }

    public clearBillToContacts() {
        clearArray(this.billToContacts);
    }

    public clearAvailableAccessorials() {
        clearArray(this.availableAccessorials);
    }

    public clearMarkupTypes() {
        clearArray(this.markupTypes);
    }

    public clearRateOnOptions() {
        clearArray(this.rateOnOptions);
    }

    public clearRoleTypes() {
        clearArray(this.roleTypes);
    }

    public clearMarkupRangeTypes() {
        clearArray(this.markupRangeTypes);
    }

    public loadAllNameCodeOptions() {
        this.loadNameCodeOptionsToMap('AccountManager', 'custAcctMgr');
        this.loadNameCodeOptionsToMap('SalesRep', 'custSalesRep');
        this.loadNameCodeOptionsToMap('Market/Segment', 'custMarketSeg');
        this.loadNameCodeOptionsToMap('Market/SubSegment', 'custMarketSubSeg');
    }

    public loadShipmentUIDOptions(customerId: string) {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Common/Admin/ShipmentUIDs/${customerId}`);
        return this._api.callApiService<ResponseDTO<Array<ShipmentUID>>>(req).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Common Data - Shipment UIDs` });
                throw err;
            })
        );
    }

    public loadQuoteUIDOptions(customerId: string) {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Common/Admin/QuoteUIDs/${customerId}`);
        return this._api.callApiService<ResponseDTO<Array<QuoteUID>>>(req).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Common Data - Quote UIDs` });
                    throw err;
                }
            )
        );
    }

    public getMarkupTypesAndRateTypes() {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Common/Markups/RateTypes`);
        return this._api.callApiService<ResponseDTO<Array<RateTypeResponse>>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    const markupTypes: Array<MarkupType> = response.dto.map((rateTypeResponse: RateTypeResponse): MarkupType => ({
                        ...rateTypeResponse.markup,
                        rateTypes: rateTypeResponse.rateTypes
                    }));

                    clearArray(this.markupTypes);
                    this.markupTypes.push(...markupTypes);
                    this.loaded.set(this.markupTypes, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Markups = Rate Types` });
                throw err;
            })
        );
    }

    public getMarkupRateOnOptions() {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Common/Markups/RateOn`);
        return this._api.callApiService<ResponseDTO<Array<RateOn>>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.rateOnOptions);
                    this.rateOnOptions.push(...response.dto);
                    this.loaded.set(this.rateOnOptions, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Markups - Rate On` });
                throw err;
            })
        );
    }

    public getMarkupRangeTypes() {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Common/Markups/RangeTypes`);
        return this._api.callApiService<ResponseDTO<Array<MarkupRangeType>>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.markupRangeTypes);
                    this.markupRangeTypes.push(...response.dto);
                    this.loaded.set(this.markupRangeTypes, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Markups - Range Types` });
                throw err;
            })
        );
    }

    public getTmaText() {
        const getTmaTextReq = new HttpRequest(REQUEST_TYPE_GET, `MasterData/User/TMA/`);
        return this._api.callApiService<ResponseDTO<Array<{ TMA: string }>>>(getTmaTextReq).pipe(
            map(response => {
                if (response && response.dto) {
                    if (response.isValid && Object.keys(response.dto).length) {
                        this.tmaText = response.dto[0].TMA;
                        return this.tmaText;
                    } else if (!Object.keys(response.dto).length) {
                        const error: Error = {...(new Error()), ...response};
                        throw error;
                    }
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Error retrieving TMA data` });
                throw err;
            })
        );
    }

    public getCarriers() {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Rate/Carriers`);
        return this._api.callApiService<ResponseDTO<Array<CarrierMasterData>>>(req).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    clearArray(this.carriers);
                    this.carriers.push(...response.dto);
                    this.loaded.set(this.carriers, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Carriers` });
                throw err;
            })
        );
    }

    public getEquipmentTypes() {
        const getEquipmentTypesReq = new HttpRequest(REQUEST_TYPE_GET, `common/equipmentType`);
        return this._api.callApiService<ResponseDTO<Array<EquipmentType>>>(getEquipmentTypesReq).pipe(
            map(response => {
                if (response && response.isValid && response.dto && response.dto.length) {
                    clearArray(this.equipmentTypes);
                    this.equipmentTypes.push(...response.dto.sort((a: EquipmentType, b: EquipmentType) =>
                        (a.description > b.description) ? 1 : (a.description < b.description) ? -1 : 0));
                    this.loaded.set(this.equipmentTypes, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Equipment Types` });
                throw err;
            })
        );
    }

    public getTmaList() {
        const getTmaListReq = new HttpRequest(REQUEST_TYPE_GET, `Masterdata/TMA/Main`);
        return this._api.callApiService<ResponseDTO<Array<TmaOption>>>(getTmaListReq).pipe(
            map(response => {
                if (response && response.isValid && response.dto && response.dto.length) {
                    clearArray(this.tmaList);
                    this.tmaList.push(...response.dto);
                    this.loaded.set(this.tmaList, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `TMA Options` });
                throw err;
            })
        );
    }

    public getUnitOfMeasures() {
        return this._api.callApiService<ResponseDTO<Array<UnitOfMeasure>>>(new HttpRequest(REQUEST_TYPE_GET, `common/unitOfMeasure`)).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.unitOfMeasures);
                    this.unitOfMeasures.push(...response.dto);
                    this.loaded.set(this.unitOfMeasures, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `MASTERDATA-Units of Measure` });
                throw err;
            })
        );
    }

    public getCommodityProductOptions() {
        const masterDataCommodityProductsReq = new HttpRequest(REQUEST_TYPE_GET, `common/Commodities`);
        return this._api.callApiService<ResponseDTO<Array<CommodityProducts>>>(masterDataCommodityProductsReq).pipe(
            map(response => {
                if (response && response.isValid && response.dto && response.dto.length) {
                    clearArray(this.commodityProducts);
                    this.commodityProducts.push(...response.dto);
                    this.loaded.set(this.commodityProducts, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: 'Get MasterData Commodity Products List'
                })
                throw err;
            })
        )
    }

    public getNmfcGroups() {
        return this._api.callApiService<ResponseDTO<Array<NmfcGroup>>>(new HttpRequest(REQUEST_TYPE_GET, `MasterData/NMFC/Groups`)).pipe(
            map(response => {
                if (response.isValid) {
                    clearArray(this.nmfcGroups);
                    this.nmfcGroups.push(...response.dto);
                    this.loaded.set(this.nmfcGroups, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ title: `MASTERDATA-NMFC Groups`, response: err, });
                throw err;
            })

        );
    }

    public getNmfcClasses() {
        return this._api.callApiService<ResponseDTO<Array<NmfcClass>>>(new HttpRequest(REQUEST_TYPE_GET, `common/class`)).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.nmfcClasses);
                    this.nmfcClasses.push(...response.dto);
                    this.loaded.set(this.nmfcClasses, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ title: `MASTERDATA-NMFC Classes`, response: err, });
                throw err;
            })
        );
    }

    public getPackageTypes() {
        return this._api.callApiService<ResponseDTO<Array<PackageType>>>(new HttpRequest(REQUEST_TYPE_GET, `common/packageTypes`)).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.packageTypes);
                    this.packageTypes.push(...response.dto);
                    this.loaded.set(this.packageTypes, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ title: `MASTERDATA-Package Types`, response: err, });
                throw err;
            })

        );
    }

    public getHandlingUnits() {
        return this._api.callApiService<ResponseDTO<Array<HandlingUnit>>>(new HttpRequest(REQUEST_TYPE_GET, `common/handlingUnits`)).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.handlingUnits);
                    this.handlingUnits.push(...response.dto);
                    this.loaded.set(this.handlingUnits, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ title: `MASTERDATA-Handling Units`, response: err, });
                throw err;
            })
        );
    }

    public getAdminRoleTypes() {
        return this._api.callApiService<ResponseDTO<Array<RoleType>>>(new HttpRequest(REQUEST_TYPE_GET, `Common/Roles/RoleTypes`)).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.roleTypes);
                    this.roleTypes.push(...response.dto);
                    this.loaded.set(this.roleTypes, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Role Detail - Role Types' });
                throw err;
            })
        );
    }

    public getStates() {
        return this._api.callApiService<ResponseDTO<Array<StateMasterData>>>(new HttpRequest(REQUEST_TYPE_GET, `common/States`)).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.states);
                    if (response.dto.length) {
                        const unAlphabetizedStatesList = response.dto;
                        const alphabetizedStatesList = response.dto.sort((a, b) => {
                            if (a.Code > b.Code) {
                                return 1;
                            } else if (a.Code < b.Code) {
                                return -1;
                            }
                            return 0;
                        });
                        this.states.push(...alphabetizedStatesList);
                    }
                    this.loaded.set(this.states, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `MASTERDATA-States` });
                throw err;
            })
        );
    }

    public getCountries() {
        return this._api.callApiService<ResponseDTO<Array<Country>>>(new HttpRequest(REQUEST_TYPE_GET, `common/Countries`)).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.countries);
                    this.countries.push(...response.dto);
                    this.loaded.set(this.countries, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `MASTERDATA-Countries` });
                throw err;
            })
        );
    }

    public getAdminSRODAssignedUsersOptions() {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Common/Admin/SRODAssignedUsers`);
        return this._api.callApiService<ResponseDTO<Array<SRODAssignedUser>>>(req).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    clearArray(this.srodAssignedUsersOptions);
                    this.srodAssignedUsersOptions.push(...response.dto);
                    this.loaded.set(this.srodAssignedUsersOptions, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Common Data - SROD Assigned Users` });
                throw err;
            })
        );
    }

    public getAdminSRODOpsGroupOptions() {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Common/Admin/SRODOps`);
        return this._api.callApiService<ResponseDTO<Array<SRODOpsGroup>>>(req).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    clearArray(this.srodOpsGroupOptions);
                    this.srodOpsGroupOptions.push(...response.dto);
                    this.loaded.set(this.srodOpsGroupOptions, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Common Data - SROD Ops Groups` });
                throw err;
            })
        );
    }

    public getAcclOptions() {
        return this._api.callApiService<ResponseDTO<Array<MarkupRateTierOption>>>(new HttpRequest(REQUEST_TYPE_POST, `MasterData/Markups/Accl`, {})).pipe(
            map(response => {
                if (response && response.isValid && response.dto && response.dto.length) {
                    clearArray(this.acclOptions);
                    this.acclOptions.push(...response.dto);
                    this.loaded.set(this.acclOptions, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: 'Master Data - Get Accl Tier Options'
                });
                throw err;
            })
        );
    }

    public getFuelOptions() {
        return this._api.callApiService<ResponseDTO<Array<MarkupRateTierOption>>>(new HttpRequest(REQUEST_TYPE_POST, `MasterData/Markups/Fuel`, {})).pipe(
            map(response => {
                if (response && response.isValid && response.dto && response.dto.length) {
                    clearArray(this.fuelOptions);
                    this.fuelOptions.push(...response.dto);
                    this.loaded.set(this.fuelOptions, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: 'Master Data - Get Rate Tier Options'
                });
                throw err;
            })
        );
    }

    public getRateOptions() {
        return this._api.callApiService<ResponseDTO<Array<MarkupRateTierOption>>>(new HttpRequest(REQUEST_TYPE_POST, `MasterData/Markups/Rate`, {})).pipe(
            map(response => {
                if (response && response.isValid && response.dto && response.dto.length) {
                    clearArray(this.rateOptions);
                    this.rateOptions.push(...response.dto);
                    this.loaded.set(this.rateOptions, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: 'Master Data - Get Rate Tier Options'
                });
                throw err;
            })
        );
    }

    public getBillToContacts() {
        const req = new HttpRequest(REQUEST_TYPE_POST, `Search`, {
            searchCriteria: [
                {
                    type: SearchConstants.SEARCH_CRITERIA_type.MASTER_DATA_CONTACT_BILL_TO,
                    value: SearchConstants.SEARCH_CRITERIA_value.TRUE,
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_CONTACTS,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
                }
            ]
        });
        return this._api.callApiService<ResponseDTO<SearchResponse>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    let billToContacts = [];
                    clearArray(this.billToContacts);
                    if (response.dto && response.dto.searchResults && response.dto.searchResults.length > 0) {
                        billToContacts = response.dto.searchResults[0].entities;
                        this.billToContacts.push(...billToContacts);
                    }
                    this.loaded.set(this.billToContacts, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: 'Bill-To Contacts'
                });
                throw err;
            })
        );
    }

    public getContractIDs() {
        return this._api.callApiService<ResponseDTO<Array<MasterDataContractID>>>(new HttpRequest(REQUEST_TYPE_GET, `Rate/Customers`)).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    clearArray(this.contractIds);
                    this.contractIds.push(...response.dto);
                    this.loaded.set(this.contractIds, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: 'Get MasterData Customer List'
                })
                throw err;
            })
        );
    }

    public getRoleBillToContacts(roleCustomer: string) {
        const req = new HttpRequest(REQUEST_TYPE_POST, `Search`, {
            searchCriteria: [
                {
                    type: SearchConstants.SEARCH_CRITERIA_type.MASTER_DATA_CONTACT_BILL_TO,
                    value: SearchConstants.SEARCH_CRITERIA_value.TRUE,
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_CONTACTS,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
                },
                {
                    type: SearchConstants.SEARCH_CRITERIA_type.MASTER_DATA_CONTACT_CUSTOMER,
                    value: roleCustomer,
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_CONTACTS,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
                }
            ]
        });
        return this._api.callApiService<ResponseDTO<SearchResponse>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    if (response.dto && response.dto.searchResults && response.dto.searchResults.length > 0) {
                        const roleBillToContacts = response.dto.searchResults[0].entities;
                        return roleBillToContacts;
                    }
                } else {
                    return [];
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: 'Role Bill-To Contacts'
                });
                throw err;
            })
        );
    }

    public getAccessorials(userId: number) {
        return this._api.callApiService<ResponseDTO<Array<AccessorialForQuote>>>(new HttpRequest(REQUEST_TYPE_GET, `accessorials/accls/${userId}`)).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.availableAccessorials);
                    if (response.dto) {
                        this.availableAccessorials.push(...response.dto);
                    }
                    this.loaded.set(this.availableAccessorials, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: 'Get MasterData Accessorials'
                })
                throw err;
            })
        );
    }

    public getAccessorialGroups(userId: number) {
        return this._api.callApiService<ResponseDTO<Array<AccessorialForQuote>>>(new HttpRequest(REQUEST_TYPE_GET, `accessorials/groups/${userId}`)).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.accessorialGroups);
                    if (response.dto) {
                        this.accessorialGroups.push(...response.dto);
                    }
                    this.loaded.set(this.accessorialGroups, true);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: 'Get Accessorial Groups'
                })
                throw err;
            })
        );
    }

    public getInsuranceTermsOfAgreement() {
        return this._api.callApiService<ResponseDTO<string>>(new HttpRequest(REQUEST_TYPE_GET, `shipment/insurance/legal`)).pipe(
            map(response => {
                if (response && response.isValid) {
                    if (response.dto) {
                        this.insuranceTermsOfAgreement = response.dto;
                    }
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: 'Record - Insurance Terms of Agreement'
                })
                throw err;
            })
        );
    }

    public getBeAdvisedMessage() {
        const req = new HttpRequest(REQUEST_TYPE_GET, `MasterData/User/BeAdvised`);
        return this._api.callApiService<ResponseDTO<{ message: string, rank: string }>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    this.beAdvisedMessage = response.dto[0].message;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Be Advised Message' });
                throw err;
            })
        );
    }

    private waitForArrayLoadRef(ref, resolve) {
        if ((ref.length > 0) || this.loaded.get(ref)) {
            resolve(ref);
        } else {
            setTimeout(() => {
                this.waitForArrayLoadRef(ref, resolve);
            }, 100);
        }
    }

    private loadNameCodeOptionsToMap(subPath: string, key: string) {
        this.loadNameCodeOptions(subPath, key).pipe(
            map(options => {
                if (Array.isArray(options)) {
                    clearArray(this.nameCodeMap[key]);
                    this.nameCodeMap[key].push(...options);
                }
                this.loaded.set(this.nameCodeMap[key], true);
            }),
        ).subscribe();
    }

    private loadNameCodeOptions(subPath, name) {
        const req = new HttpRequest(REQUEST_TYPE_GET, `common/${subPath}`);
        return this._api.callApiService<ResponseDTO<Array<NameCode>>>(req).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Common Data - ${name}` });
                throw err;
            })
        );
    }

    public submitTMAApproval(tmaApprovalReq: TmaRequest): void {
        const submitTmaApprovalReq = new HttpRequest(REQUEST_TYPE_POST, `MasterData/Customer/TMA`, tmaApprovalReq);
        this._api.callApiService<ResponseDTO<TmaRequest>>(submitTmaApprovalReq).subscribe(
            (response) => {
                if (response && response.isValid && response.dto) {
                    this._notificationService.notifySuccess({
                        title: `Success`,
                        message: `Thank you ${response.dto.firstName}, your TMA approval has been submitted`
                    });
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({
                        response,
                        title: 'Submit TMA Approval'
                    });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: 'Submit TMA Approval'
                });
            });
    }

    public getClassEstimate(classRequest: ClassRequest): Observable<ResponseDTO<ClassResponse>> {
        const classEstimateReq = new HttpRequest(REQUEST_TYPE_POST, `Tools/classCalc`, classRequest);
        return this._api.callApiService<ResponseDTO<ClassResponse>>(classEstimateReq);
    }
}
