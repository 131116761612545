<div class="starsRow">
    <span class="star">
        <i *ngIf="stars >= 1" class="fa fa-star star-yellow"></i>
        <i *ngIf="!(stars >= 1)" class="fa fa-star-o star-grey"></i>
    </span>
    <span class="star">
        <i *ngIf="stars >= 2" class="fa fa-star star-yellow"></i>
        <i *ngIf="!(stars >= 2)" class="fa fa-star-o star-grey"></i>
    </span>
    <span class="star">
        <i *ngIf="stars >= 3" class="fa fa-star star-yellow"></i>
        <i *ngIf="!(stars >= 3)" class="fa fa-star-o star-grey"></i>
    </span>
    <span class="star">
        <i *ngIf="stars >= 4" class="fa fa-star star-yellow"></i>
        <i *ngIf="!(stars >= 4)" class="fa fa-star-o star-grey"></i>
    </span>
    <span class="star">
        <i *ngIf="stars >= 5" class="fa fa-star star-yellow"></i>
        <i *ngIf="!(stars >= 5)" class="fa fa-star-o star-grey"></i>
    </span>
</div>