import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import * as Constants from '../../constants/constants';
import { RegistrationRequest } from '../../models/registrationRequest'
import {UserService} from "../../services/user.service";
import {NotificationService} from "../../services/notification.service";

@Component({
  selector: 'app-registration',
  styleUrls: ['./registration.component.scss'],
  templateUrl: './registration.component.html'
})
export class RegistrationComponent implements OnInit {

  public isPasswordHidden: boolean = true;
  public isComparePasswordHidden: boolean = true;
  public registrationForm: UntypedFormGroup;
  private jwtFromUrl: string;
  public areTermsAndConditionsVisible: boolean = false;
  public isTmaAcceptanceVisible: boolean = false;

  constructor(
    private _notificationService: NotificationService,
    private _userService: UserService,
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.jwtFromUrl = this._activatedRoute.snapshot.paramMap.get('customerCode');
    this.registrationForm = this._formBuilder.group({
      firstName: new UntypedFormControl('', {
        validators: [
          Validators.required
        ]
      }),
      lastName: new UntypedFormControl('', {
        validators: [
          Validators.required,
        ]
      }),
      phone: new UntypedFormControl('', {
        validators: [
          Validators.required,
        ]
      }),
      zipCode: '',
      companyName: '',
      companyCode: new UntypedFormControl(this.jwtFromUrl || '', {
        validators: [
          Validators.required
        ]
      }),  // ie custNo
      email: new UntypedFormControl('', {
        validators: [
          Validators.required,
        ]
      }),
      username: new UntypedFormControl('', {
        validators: [
          Validators.required,
        ]
      }),
      password: new UntypedFormControl('', {
        validators: [
          Validators.required,
        ]
      }),
      confirmPassword: new UntypedFormControl('', {
        validators: [
          Validators.required,
          this.compareInputtedPasswords()
        ],
      })
    });

    this.registrationForm.get('password').valueChanges.subscribe(val => {
      this.checkPasswordMatch();
    })
  }

  private prepareRegistrationRequest(): RegistrationRequest {
    const registrationReq: RegistrationRequest = {
      username: this.registrationForm.get('username').value,
      password: this.registrationForm.get('password').value,
      firstName: this.registrationForm.get('firstName').value,
      lastname: this.registrationForm.get('lastName').value,
      email: this.registrationForm.get('email').value,
      phone: this.registrationForm.get('phone').value,
      custCode: this.registrationForm.get('companyCode').value,
    }
    return registrationReq;
  }

  public processRegistration(): void {
    if (!this.registrationForm.get('firstName').value) {
      this._notificationService.notifyError({ title: 'Missing Registration Info', message: 'Please enter your first name' });
    } else if (!this.registrationForm.get('lastName').value) {
      this._notificationService.notifyError({ title: 'Missing Registration Info', message: 'Please enter your last name' });
    } else if (!this.registrationForm.get('phone').value) {
      this._notificationService.notifyError({ title: 'Missing Registration Info', message: 'Please enter a phone number' });
    } else if (!this.registrationForm.get('companyCode').value) {
      this._notificationService.notifyError({ title: 'Missing Registration Info', message: 'Missing company code' });
    } else if (!this.registrationForm.get('email').value) {
      this._notificationService.notifyError({ title: 'Missing Registration Info', message: 'Please enter an email address' });
    } else if (!this.registrationForm.get('username').value) {
      this._notificationService.notifyError({ title: 'Missing Registration Info', message: 'Please enter a user name' });
    } else if (!this.registrationForm.get('password').value) {
      this._notificationService.notifyError({ title: 'Missing Registration Info', message: 'Please enter a password' });
    } else if (this.registrationForm.get('password').value !== this.registrationForm.get('confirmPassword').value) {
      this._notificationService.notifyError({ title: 'Registration Error', message: 'Your passwords do not match, please check and try again' });
    } else {
      this._userService.processUserRegistration(this.prepareRegistrationRequest());
    }
  }

  private doesCustomerRequireTmaAcceptance(): boolean {
    let decodedJwt: { custNo: string, email: string, expires: string, roleId: number, tmaAuthorized: boolean, type: string };
    const jwt = this.prepareRegistrationRequest().custCode;
    const jwtHelper: JwtHelperService = new JwtHelperService();
    decodedJwt = jwtHelper.decodeToken(jwt);

    if (decodedJwt.tmaAuthorized) {
      return true;
    } else {
      return false;
    }
  }

  public acceptTMA(): void {
    this.isTmaAcceptanceVisible = false;
    this._userService.processUserRegistration(this.prepareRegistrationRequest());
  }

  private checkPasswordMatch(): void {
    const passwordFormControl: AbstractControl = this.registrationForm.get('password');
    const confirmPasswordFormControl: AbstractControl = this.registrationForm.get('confirmPassword')
    if (!confirmPasswordFormControl.value) {
      return;
    }
    if (confirmPasswordFormControl.value !== passwordFormControl.value) {
      confirmPasswordFormControl.updateValueAndValidity({ onlySelf: true });
    }
  }

  public navigateToLoginScreen(): void {
    this._router.navigate(['']);
  }

  public displayTermsAndConditions(): void {
    this.areTermsAndConditionsVisible = true;
  }

  public hideTermsAndConditions(): void {
    this.areTermsAndConditionsVisible = false;
  }

  public togglePasswordType(): void {
    if (!this.registrationForm.get('password').value) {
      return;
    }

    const buttonHTMLElement = document.getElementById('password');
    if (this.isPasswordHidden) {
      buttonHTMLElement.setAttribute('type', Constants.INPUT_TYPE_TEXT);
    } else {
      buttonHTMLElement.setAttribute('type', Constants.INPUT_TYPE_PASSWORD);
    }

    this.isPasswordHidden = !this.isPasswordHidden;
  }

  public toggleComparePasswordType(): void {
    if (!this.registrationForm.get('confirmPassword').value) {
      return;
    }

    const buttonHTMLElement = document.getElementById('comparePassword');
    if (this.isComparePasswordHidden) {
      buttonHTMLElement.setAttribute('type', Constants.INPUT_TYPE_TEXT);
    } else {
      buttonHTMLElement.setAttribute('type', Constants.INPUT_TYPE_PASSWORD);
    }

    this.isComparePasswordHidden = !this.isComparePasswordHidden;
  }

  private compareInputtedPasswords() {
    return function (passwordConfirmationFormControl: UntypedFormControl): { arePasswordsSame: { valid: boolean } } {
      if (!passwordConfirmationFormControl.root || !passwordConfirmationFormControl.root['controls']) {
        return null;
      }
      const passwordFormControl = passwordConfirmationFormControl.root.get('password');
      const isPasswordValid = passwordFormControl.valid;
      if (!isPasswordValid) {
        return null;
      }

      if (passwordFormControl.value !== passwordConfirmationFormControl.value) {
        return {
          arePasswordsSame: {
            valid: false
          }
        };
      }

    }
  }

}
