<div class="overlay">
    <div class="custom-modal">
        <div class="modal-header">
            <div class="modalHeaderContent">
                Invite User
            </div>
            <div class="modalHeaderContent">
                <i class="fa fa-times clickable" aria-hidden="true" (click)="close()"></i>
            </div>
        </div>
        <div class="modal-body">
            <div class="inviteUserFormContainer">
                <div class="message">Complete the fields below to invite a new user to set up their profile with Shiprite</div>
                <form [formGroup]="inviteUserForm" class="userInviteForm" (ngSubmit)="confirmUserInvite()">
                    <div class="userFormSection">
                        <div class="formLabel">
                            <span class="required">*</span>Email</div>
                        <input class="formValue" formControlName="email" placeholder="Enter Invitee Email">
                    </div>
                    <div class="userFormSection">
                        <div class="formLabel">
                            <span class="required">*</span>Expiration Period (days)</div>
                        <input class="formValue" formControlName="expiration" type="number" placeholder="Enter a number between 2-30">
                    </div>
                    <div class="userFormSection">
                        <div class="formLabel">
                            <span class="required">*</span>Invitee Role</div>
                        <input class="formValue" formControlName="roleId" type="number" placeholder="Enter the ID number here">
                    </div>
                    <div class="userFormSection">
                        <div class="formLabel">
                            <span class="required">*</span>Customer No</div>
                        <input class="formValue" formControlName="custNo" type="number" placeholder="Enter customer number here">
                    </div>
                </form>
                <div class="button-holder">
                    <button class="buttons clickable" (click)="confirmUserInvite()">Invite User</button>
                    <button class="buttons clickable" (click)="close()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>