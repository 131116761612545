export const
    DASHBOARD_VIEW_CATEGORY_NAMES = {
        user_created: 'My Views',
        global_views: 'Global Views',
        record_management: 'Record Management',
        quote_management: 'Quote Management',
    },
    DASHBOARD_VIEW_TYPES = {
        global_search: 'globalSearch',
        create_view: 'createView',
        template_query: 'templateQuery',
        content_query: 'contentQuery',
        help_center: 'helpCenter',
    };
