import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-stars-rating',
    templateUrl: './stars-rating.component.html',
    styleUrls: ['./stars-rating.component.scss']
})
export class StarsRatingComponent {
    @Input() public stars: number;

    constructor() { }

}
