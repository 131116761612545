import {InvoiceSummaryReportResponse} from "./invoiceSummaryReportResponse";
import {InvoiceDetailReportResponse} from "./invoiceDetailReportResponse";
import {ShipmentSummaryReportResponse} from "./shipmentSummaryReportResponse";
import {ShipmentDetailReportResponse} from "./shipmentDetailReportResponse";
import {ReportDropdownTypeSet} from "./reportDropdownTypeSet";
import {ShipmentSummaryReportRequest} from "./shipmentSummaryReportRequest";
import {InvoiceSummaryReportRequest} from "./invoiceSummaryReportRequest";

export class ReportingState {
    invoiceSummaryReports: InvoiceSummaryReportResponse;
    invoiceDetailReports: InvoiceDetailReportResponse;
    shipmentSummaryReports: ShipmentSummaryReportResponse;
    shipmentDetailReports: ShipmentDetailReportResponse;
    invoiceReportGroupTypes: Array<ReportDropdownTypeSet>;
    invoiceReportDateTypes: Array<ReportDropdownTypeSet>;
    shipmentReportGroupTypes: Array<ReportDropdownTypeSet>;
    shipmentReportDateTypes: Array<ReportDropdownTypeSet>;
    shipmentSummaryReportRequest: ShipmentSummaryReportRequest;
    invoiceSummaryReportRequest: InvoiceSummaryReportRequest;
};

export const initialReportingState: ReportingState = {
    invoiceSummaryReports: null,
    invoiceDetailReports: null,
    shipmentDetailReports: null,
    shipmentSummaryReports: null,
    invoiceReportGroupTypes: [],
    invoiceReportDateTypes: [],
    shipmentReportGroupTypes: [],
    shipmentReportDateTypes: [],
    shipmentSummaryReportRequest: null,
    invoiceSummaryReportRequest: null,
}
