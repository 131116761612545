<div class="overlay">
    <!--========================================= MODAL HEADER =========================================================-->
    <div class="searchZipModal">
        <div class="modalHeader">
            <span *ngIf="!title" class="modalHeaderText">Search {{ zipType }} Postal / Zip</span>
            <span *ngIf="title" class="modalHeaderText">{{ title }}</span>
            <span class="modalHeaderIcon clickable" (click)="closeModal()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>

        <!--========================================= MODAL BODY =========================================================-->
        <div class="searchZipModalBody">

            <!--================================ SEARCH INPUTS and what not ===========================================-->
            <form (ngSubmit)="searchZipCodes()">
                <button type="submit" name="formSubmission" class="btn-hidden-IE"></button>
                <div class="searchZipModalQueryHolder">
                    <input type="text" class="cityInput" placeholder="City" name="cityQuery"
                           [(ngModel)]="cityQuery" tabindex="1" #queryField />
                    <div class="stateSelectPanel">
                        <select required name="selectedState" id="" class="stateSelectTag" [(ngModel)]="selectedState"
                                tabindex="2">
                            <option value="" [selected] disabled data-default>State/Province</option>
                            <option [value]=""></option>
                            <option *ngFor="let state of states" [value]="state">{{ state }}</option>
                        </select>
                        <span class="searchZipModalQuerySelectCaret clickableCaret">
                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="countrySelectPanel">
                        <select required name="selectedCountryCode" id="" class="countrySelectTag"
                                [(ngModel)]="countryCode" tabindex="3">
                            <option value="" [selected] disabled data-default>Country</option>
                            <option [value]=""></option>
                            <option *ngFor="let country of countries"
                                    [value]="country.countryCode">{{ country.countryDesc }}</option>
                        </select>
                        <span class="searchZipModalQuerySelectCaret clickableCaret">
                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="searchIcon clickable" (click)="searchZipCodes()" tabindex="4"
                         (keyup.enter)="searchZipCodes()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </form>

            <!--===================================== PERFORM SEARCH PROMPT ================================================-->
            <div class="performSearchPrompt" *ngIf="!zipResults.length && !isNoResultsPromptDisplayed">
                <div class="performSearchPromptText">
                    Perform your search above
                </div>
                <div class="performSearchPromptIconHolder">
                    <i class="fa fa-smile-o"></i>
                </div>
            </div>

            <!--===================================== NO RESULTS PROMPT ================================================-->
            <div class="performSearchPrompt" *ngIf="isNoResultsPromptDisplayed">
                <div class="performSearchPromptText">
                    No results found.
                </div>
                <div class="performSearchPromptIconHolder">
                    <i class="fa fa-frown-o"></i>
                </div>
            </div>

            <!--===================================== SEARCH RESULTS HEADER================================================-->
            <div class="searchResultHeaderRow padLeft padRight" *ngIf="zipResults.length">
                <div class="searchResultHeaderZip">
                    Zip
                </div>
                <div class="searchResultHeaderCity">
                    City
                </div>
                <div class="searchResultHeaderState">
                    State/Province
                </div>
                <div class="searchResultHeaderCountry">
                    Country
                </div>
            </div>

            <!--========================= ACTUAL SEARCH RESULTS =======================================================-->
            <div class="searchResultsContainer" *ngIf="zipResults.length">
                <div class="searchResultRow clickable" *ngFor="let zip of zipResults" (click)="selectZipResult(zip)">
                    <span class="searchResultRowZip">
                        {{ zip.zip_code }}
                    </span>
                    <span class="searchResultRowCity">
                        {{ zip.city | titlecase}}
                    </span>
                    <span class="searchResultRowState">
                        <span *ngIf="zip.statename">
                            {{ zip.statename }}
                        </span>
                        <span *ngIf="!zip.statename">
                            {{ zip.state }}
                        </span>
                    </span>
                    <span class="searchResultRowCountry">
                        {{ zip.countryname }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
