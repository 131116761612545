<div class="overlay">
    <div class="modal">
        <form (ngSubmit)="closeModal()">
            <!-- ================================= QUOTE RERATE MODAL HEADER====================================== -->
            <div class="header">
                <div class="title">Name Template</div>
                <span class="closeModalButtonHolder clickable" (click)="cancelModal()">
                    <i class="fa fa-times"></i>
                </span>
            </div>

            <!-- ==================  QUOTE RERATE MODAL BODY ================== -->
            <div class="body">
                <div class="field">
                    <input type="text" id="templateName" name="templateName" class="field" [(ngModel)]="templateName" />
                </div>
            </div>

            <!-- ==================QUOTE RERATE MODAL FOOTER ================== -->
            <div class="footer">
                <div class="clickable btn-outline" (click)="closeModal()">
                    CREATE TEMPLATE
                </div>
            </div>
        </form>
    </div>
</div>