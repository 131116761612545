import {Component} from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-locked-out',
    styleUrls: ['./app-locked-out.component.scss'],
    templateUrl: './app-locked-out.component.html'
})
export class AppLockedOutComponent {

    constructor(
        private _router: Router,
    ) { }

    public checkForEnter($event: KeyboardEvent) {
        if ($event.keyCode === 13) {
            this.processLockedOut();
        }
    }

    public processLockedOut() {
        this._router.navigate([''])
    }
}
