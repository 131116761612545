<div class="searchBarMainBody">
    <div class="searchBar">
        <form class="searchForm" (submit)="search()">

            <div class="searchCriteriaTypePanel">
                <select class="searchCriteriaTypeText" name="searchCriteriaTypes" id="searchCriteriaTypes" required [(ngModel)]="selectedSearchCriteriaType">
                    <option [value]="fromSearchConstants.SEARCH_CRITERIA_type.ALL">All</option>
                    <option *ngFor="let searchCriteriaType of searchCriteriaTypes" [value]="searchCriteriaType.fieldName">
                        {{searchCriteriaType.displayName}}
                    </option>
                </select>
                <span class="searchCriteriaTypeChevron">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
            </div>

            <input type="text" id="searchQuery" autofocus class="searchQuery" name="searchQuery" [(ngModel)]="searchQuery" required />

            <span class="iconSearch clickable blue" (click)="search()">
                <i class="fa fa-search" aria-hidden="true"></i>
            </span>
        </form>
        <div class="hidden actionText blue" (click)="displayInProgressNotification()">Advanced Search</div>
    </div>

    <!--================================ PERFORM SEARCH PROMPT AND/OR NO RESULTS FOUND PROMPT ========================================-->
    <!--===================================== PERFORM SEARCH PROMPT ================================================-->
    <div class="performSearchPrompt" *ngIf="!isNoResultsPromptDisplayed && !hasSearchBeenMade">
        <div class="searchPromptHolder">
            <div class="performSearchPromptText">
                Perform your search above
            </div>
            <div class="performSearchPromptIconHolder">
                <i class="fa fa-smile-o"></i>
            </div>
        </div>
    </div>

    <!--===================================== NO RESULTS PROMPT ================================================-->
    <div class="performSearchPrompt" *ngIf="isNoResultsPromptDisplayed || (searchState && searchState.filteredSearchResults && searchState.filteredSearchResults.entities && !searchState.filteredSearchResults.entities.length)">
        <div class="searchPromptHolder">
            <div class="performSearchPromptText">
                No results found.
            </div>
            <div class="performSearchPromptIconHolder">
                <i class="fa fa-frown-o"></i>
            </div>
        </div>
    </div>
</div>