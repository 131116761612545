import {Injectable} from "@angular/core";
import {HttpRequest} from "@angular/common/http";
import {ApiService, REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT} from "./api.service";
import {ResponseDTO} from "../models/responseDto";
import {CarrierMasterData} from "../models/carrier.masterData";
import {catchError, map} from "rxjs/operators";
import {CarrierMasterDataRequest} from "../models/carrier.masterData.request";
import {clearArray} from "../helpers/utilities";
import {NotificationService} from "./notification.service";

@Injectable()
export class CarriersMasterDataService {

    public carriersMasterData = [];

    constructor(
        private _notificationService: NotificationService,
        private _api: ApiService,
    ) {}

    public getMasterDataCarrier(scac: string) {
        const req = new HttpRequest(REQUEST_TYPE_GET, `MasterData/Carrier/${scac}`);
        return this._api.callApiService<ResponseDTO<CarrierMasterData>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    return response.dto[0];
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Master Data - Get Carrier` });
                throw err;
            })
        );
    }

    public createMasterDataCarrier(createCarrierReq: CarrierMasterDataRequest) {
        const req = new HttpRequest(REQUEST_TYPE_POST, `MasterData/Carrier`, createCarrierReq);
        return this._api.callApiService<ResponseDTO<CarrierMasterData>>(req).pipe(
            map(response => {
                if (response.isValid && response.dto) {
                    return response.dto[0];
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Master Data - Create Carrier` });
                throw err;
            })
        );
    }

    public updateMasterDataCarrier(updateCarrierReq: CarrierMasterDataRequest) {
        const req = new HttpRequest(REQUEST_TYPE_PUT, `MasterData/Carrier`, updateCarrierReq);
        return this._api.callApiService<ResponseDTO<CarrierMasterData>>(req).pipe(
            map(response => {
                if (response.isValid && response.dto) {
                    return response.dto[0];
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Master Data - Update Carrier` });
                throw err;
            })
        );
    }

    public deleteMasterDataCarrier(carrier: CarrierMasterData) {
        const deleteMasterDataCarrierReq = new HttpRequest(REQUEST_TYPE_DELETE, `MasterData/Carrier/${carrier.scac}`);
        return this._api.callApiService<ResponseDTO<Array<any>>>(deleteMasterDataCarrierReq).pipe(
            map(response => {
                if (response.isValid) {
                    this._notificationService.notifySuccess({
                        title: 'Master Data - Delete Carrier',
                        message: `Carrier successfully deactivated.`
                    });
                    return response.dto[0];
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Master Data - Delete Carrier` });
                throw err;
            })
        );
    }

    public getMasterDataCarriersList() {
        const req = new HttpRequest(REQUEST_TYPE_GET, `MasterData/Carrier/`);
        return this._api.callApiService<ResponseDTO<Array<CarrierMasterData>>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.carriersMasterData);
                    this.carriersMasterData.push(...response.dto);
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Master Data - Get Carriers List` });
                throw err;
            })
        );
    }

    public clearMasterDataCarriers() {
        clearArray(this.carriersMasterData);
    }
}
