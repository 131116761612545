<!-- ================================= MARKUP DEFINITION ================================= -->
<div class="ruleDefinitionContainer borderBottomGray">
    <!-- ============================== HEADER ROW ============================== -->
    <div class="ruleDefinitionHeaderRow">
        <div class="ruleDefinitionHeaderRowLeftText">
            Default Markup by {{ markupType?.displayMarkupType }}
            <span class="clickable" (click)="doAddEvent($event)" *ngIf="markupType">
                <i class="fa fa-plus-circle tooltip" aria-hidden="true"><span style="top: -30px; left: -45px" class="tooltiptext">Add Default Rate</span></i>
            </span>
        </div>
        <div class="ruleDefinitionHeaderRowRightText hidden">
            See All Rate Adjustments for User
        </div>
    </div>
    <div class="descriptionContainer">
        The default markup will apply to all weights,and carriers that are not included in the exceptions.
    </div>

    <!-- ============================== LABEL ROW ============================== -->
    <div class="ruleDefinitionLabelRow borderBottomGray">

        <div class="rateColumnsContainer">
            <div class="rateTypeCol">
                            <span class="labelRowText">
                                Rate Type
                            </span>
            </div>
            <div class="rateValueCol">
                            <span class="labelRowText">
                                Rate
                            </span>
            </div>
            <div class="rateOnCol">
                            <span class="labelRowText">
                                Rate On
                            </span>
            </div>
        </div>

        <div class="ruleDefinitionRangeColumnsContainer">
            <div class="ruleMarkupRangeCol">
                            <span class="labelRowText">
                                Markup Range
                            </span>
            </div>
            <div class="ruleRangeOnCol">
                            <span class="labelRowText">
                                Range On
                            </span>
            </div>
        </div>

        <div class="dateRangeContainer">
                        <span class="labelRowText">
                            Date Range
                        </span>
        </div>
    </div>

    <!-- ============================== ACTUAL DEFINITION FORM ROW ============================== -->
    <div class="ruleDefinitionFormRow" *ngFor="let rateGroup of ratesFormArray; let idx=index;" [formGroup]="rateGroup">

        <div class="rateColumnsContainer">

            <div class="rateTypeCol">
                <div class="ruleRowSelectPanel clickable clickableCaretExtension">
                    <select class="ruleRowSelectTag" formControlName="rateType">
                        <option [ngValue]="rateType.rateTypeID"
                                *ngFor="let rateType of markupType.rateTypes">
                            {{ rateType.displayRateType }}
                        </option>
                    </select>
                    <span class="ruleRowSelectCaret clickableCaret clickableRateCaretPositioning ">
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    </span>
                </div>
            </div>

            <div class="rateValueCol">
                <div class="ruleRateValueInputPanel"
                     [ngClass]="{
                          'ruleRateValueInputPanelCurrency': getRateIcon(rateGroup?.controls?.rateType?.value) === '$',
                          'ruleRateValueInputPanelPercent': getRateIcon(rateGroup?.controls?.rateType?.value) === '%'
                      }">
                    <span class="ruleRateValueIcon" *ngIf="getRateIcon(rateGroup?.controls?.rateType?.value) === '$'" >
                        <i class="fa fa-usd" aria-hidden="true"></i>
                    </span>
                    <input type="text" class="ruleRateValueInputTag" formControlName="rate">
                    <span class="ruleRateValueIcon" *ngIf="getRateIcon(rateGroup?.controls?.rateType?.value) === '%'">
                        <i class="fa fa-percent" aria-hidden="true"></i>
                    </span>
                </div>
            </div>

            <div class="rateOnCol">
                <div class="ruleRowSelectPanel clickable clickableCaretExtension">
                    <select class="ruleRowSelectTag" formControlName="rateOn">
                        <option [value]="rateOn.rateOnID" *ngFor="let rateOn of rateOnOptions">
                            {{ rateOn.displayRateOn }}
                        </option>
                    </select>
                    <span class="ruleRowSelectCaret clickableCaret clickableCaretPositioning ">
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        </div>

        <div class="ruleDefinitionRangeColumnsContainer">
            <div class="ruleMarkupRangeCol"
                 [ngClass]="{ 'markupRangeInvalid': rateGroup && rateGroup.errors && rateGroup.errors!.markupRangeInvalid }">
                <div class="markupRangePanel" [ngClass]="{ 'markupRangePanelInvalid':
                                (rateGroup.controls.minMarkup.dirty || rateGroup.controls.minMarkup.touched) &&
                                rateGroup.controls.minMarkup.errors &&
                                (rateGroup.controls.minMarkup.errors.pattern || rateGroup.controls.minMarkup.errors.min)}">
                    <span class="markupRangeIcon">
                        <i class="fa fa-usd" aria-hidden="true"></i>
                    </span>
                    <input type="text" class="markupRangeInputTag" formControlName="minMarkup">
                </div>

                <span class="markupRangeText">
                                to
                </span>

                <div class="markupRangePanel" [ngClass]="{ 'markupRangePanelInvalid':
                                (rateGroup.controls.maxMarkup.dirty || rateGroup.controls.maxMarkup.touched) &&
                                 rateGroup.controls.maxMarkup.errors &&
                                (rateGroup.controls.maxMarkup.errors.pattern || rateGroup.controls.maxMarkup.errors.min)}">
                                <span class="markupRangeIcon">
                                    <i class="fa fa-usd" aria-hidden="true"></i>
                                </span>
                    <input type="text" class="markupRangeInputTag" formControlName="maxMarkup">
                </div>
            </div>

            <div class="ruleRangeOnCol">
                <div class="ruleRowSelectPanel clickable clickableCaretExtension">
                    <select class="ruleRowSelectTag" formControlName="minMarkupon">
                        <option [value]="rangeOn.rateOnID" *ngFor="let rangeOn of rateOnOptions">
                            {{ rangeOn.displayRateOn }}
                        </option>
                    </select>
                    <span class="ruleRowSelectCaret clickableCaret clickableCaretPositioning">
                                    <i class="fa fa-caret-down"></i>
                    </span>
                </div>
            </div>
        </div>


        <div class="dateRangeContainer">
            <div class="dateRangePanel" [ngClass]="{ 'dateRangeContradiction': rateGroup && (
                                (rateGroup.controls.effective && rateGroup.controls.effective.errors) ||
                                (rateGroup.controls.end && rateGroup.controls.end.errors)
                            )}">
                <input type="text" class="dateRangeStart" formControlName="effective">
                <span class="dateRangeText">
                                to
                </span>
                <input type="text" class="dateRangeEnd" formControlName="end">
                <span class="dateRangeIcon blue clickable" (click)="showDatePicker(rateGroup)">
                                <i class="fa fa-calendar" aria-hidden="true"></i>
                </span>
            </div>
        </div>
        <div>
            <span class="clickable" (click)="doDeleteEvent(idx)">
                <i class="fa fa-trash tooltip" aria-hidden="true">
                    <span style="top: -30px; left: -45px" class="tooltiptext">Delete Default Rate</span>
                </i>
            </span>
        </div>
    </div>
</div>

<app-custom-datepicker *ngIf="isDatePickerShown" [isDateRangePossible]="true" [title]="'Select Date Range'"
                       [dateFormatPattern]="markupDisplayDateFormat"
                       [inputDate]="selectedDateFormGroup.value.effective"
                       (closeDatePicker)="hideDatePicker($event)"
                       [dateFormatValidationPattern]="_dateHelper.getDisplayDateValidation()"
                       [isDateRangeOnly]="true"
                       [inputEndDate]="selectedDateFormGroup.value.end"></app-custom-datepicker>
