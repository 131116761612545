export const
  AUTO_ADDED_ACCESSORIAL_CODES = {
    residential_pickup: 'RESIDENTIALPICKUP',
    residential_delivery: 'RESIDENTIALDELIVERY',
    appointment: 'APPOINTMENT',
    hazmat: 'HAZMAT'
  },
  DELIVERY_TYPE_RESIDENTIAL = 'R',
  PICKUP_TYPE_RESIDENTIAL = 'R',
  CC_URL_VALUES = {
    hosted_secure_id: 'cpt9282730',
    action: 'buildForm',
    collectAddress: '1',
    allowed_types: 'Discover|MasterCard|Visa|American Express',
    required: 'all',
  },
  CC_IFRAME_BASE_URL = 'https://www.chasepaymentechhostedpay.com/hpf/1_1/?',
  SHIPMENT_STATUS_VALUE_new = 0,
  SHIPMENT_STATUS_VALUE_cancelled = 7,
  SHIPMENT_STATUS_VALUE_delivered = 5,
  SHIPMENT_STATUS_VALUE_invoiced = 6,
  SHIPMENT_STATUS_VALUE_reviewed = 13,
  SHIPMENT_STATUS_VALUE_processing = 14,
  VANGUARD_CFS_CONTACT_TYPE = {
    ORIGIN: 'I',
    DESTINATION: 'E',
  };
