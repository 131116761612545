import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {map} from 'rxjs/operators';

import {NmfcSearchResult} from '../../../models/nmfc.searchResult';
import {NmfcGroup} from '../../../models/nmfcGroup';
import {CommonDataService} from '../../../services/commonData.service';
import {NmfcSearchService} from '../../../services/nmfc-search.service';

@Component({
    selector: 'app-nmfc-search-modal',
    templateUrl: './app-nmfc-search-modal.component.html',
    styleUrls: ['./app-nmfc-search-modal.component.scss']
})
export class AppNmfcSearchModalComponent implements OnInit, AfterViewInit {
    @Input()
    public title = 'NMFC Lookup';
    @Input()
    public nmfcSearchQuery = '';
    @Output()
    public nmfcSelected = new EventEmitter<NmfcSearchResult>();

    @ViewChild('nmfcQueryInput')
    public nmfcQueryInputElement: ElementRef;

    public nmfcGroups: Array<NmfcGroup>;
    public matchedNmfcGroups: Array<{ isExpanded: boolean, groupId: number, groupTitle: string, nmfcItems: Array<NmfcSearchResult> }> = [];

    public isNoResultsPromptDisplayed: boolean = false;

    private hasSearchNmfcBeenRun: boolean = false;


    constructor(
        private _commonDataService: CommonDataService,
        private _nmfcSearchService: NmfcSearchService,
    ) {
    }

    public ngOnInit() {
        this.nmfcGroups = this._commonDataService.nmfcGroups;
    }

    public ngAfterViewInit() {
        this.nmfcQueryInputElement.nativeElement.focus();
    }

    // ======================================== SEARCH AND RELATEED METHODS ===============================================
    public searchNmfc(): void {
        this.hasSearchNmfcBeenRun = true;
        this._nmfcSearchService.nmfcSearch(this.nmfcSearchQuery).pipe(
            map(nmfcSearchResults => {
                this.matchedNmfcGroups = [];
                nmfcSearchResults.forEach((searchResult: NmfcSearchResult) => {
                    const matchedGroupIndex = this.matchedNmfcGroups.findIndex(nmfcGroup => nmfcGroup.groupId === searchResult.parentitemno);
                    if (matchedGroupIndex !== -1) {
                        this.matchedNmfcGroups[matchedGroupIndex].nmfcItems.push(searchResult);
                    } else {
                        const groupId = searchResult.parentitemno;
                        const groupTitle = searchResult.group_title;
                        this.matchedNmfcGroups.push({
                            isExpanded: false,
                            groupId,
                            groupTitle,
                            nmfcItems: [searchResult]
                        });
                    }
                });
                if (this.matchedNmfcGroups.length) {
                    this.matchedNmfcGroups[0].isExpanded = true;
                    this.isNoResultsPromptDisplayed = false;
                } else if (this.hasSearchNmfcBeenRun) {
                    this.isNoResultsPromptDisplayed = true;
                }
            })
        ).subscribe();
    }

    public toggleGroupIsExpanded(group: { isExpanded: boolean, groupId: number, groupTitle: string, nmfcItems: Array<NmfcSearchResult> }): void {
        group.isExpanded = !group.isExpanded;
    }

    public selectNmfcSearchResult(nmfcSearchResult: NmfcSearchResult): void {
        this.nmfcSelected.emit(nmfcSearchResult);
    }
}
