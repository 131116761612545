import {Component, OnInit} from '@angular/core';
import {SignalsService} from "../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";

@Component({
    selector: 'app-home',
    styleUrls: ['./home.component.scss'],
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
    public appStateSubscription;
    public isUserSessionActive: boolean;

    private appState$;

    constructor(private _signalsService: SignalsService) {
        this.appState$ = toObservable(this._signalsService.appStateSignal);
    }

    public ngOnInit() {
        this.appStateSubscription = this.appState$.subscribe((appState) => {
            this.isUserSessionActive = appState['global.isUserSessionActive'];
        });

    }
}
