import { Pipe, PipeTransform } from '@angular/core';

import { PaginationPageNumber } from '../models/paginationPageNumber';

@Pipe({
  name: 'paginationPageNumberFilter',
})
export class PaginationPageNumberFilterPipe implements PipeTransform {
  transform(pageNumberOptions: Array<PaginationPageNumber>): Array<PaginationPageNumber> {
    pageNumberOptions.forEach((pNO: PaginationPageNumber): PaginationPageNumber => {
      return {
        ...pNO,
        isPreviousSetEllipsis: false,
        isNextSetEllispis: false,
      };
    });

    if (pageNumberOptions.length === 1) {
      return [];
    }

    if (pageNumberOptions.length <= 3) {
      return pageNumberOptions;
    }

    const firstPage: PaginationPageNumber = pageNumberOptions[0];
    const currentPage: PaginationPageNumber = pageNumberOptions.find((pNO: PaginationPageNumber) => pNO.isCurrentPage);
    const currentPageIndex: number = pageNumberOptions.indexOf(currentPage);
    const lastPage: PaginationPageNumber = pageNumberOptions[pageNumberOptions.length - 1];

    if (currentPage.pageNumber <= 3) {
      const nextSetPage: PaginationPageNumber = pageNumberOptions[3];
      nextSetPage.isNextSetEllispis = true;

      return [
        ...pageNumberOptions.slice(0, 3),
        nextSetPage,
        lastPage,
      ];
    } else if (currentPage.pageNumber > (lastPage.pageNumber - 3)) {
      const previousSetPage: PaginationPageNumber = pageNumberOptions[pageNumberOptions.length - 4];
      previousSetPage.isPreviousSetEllipsis = true;

      return [
        firstPage,
        previousSetPage,
        ...pageNumberOptions.slice(-3),
      ];
    } else {
      const previousSetPage = pageNumberOptions.find((pNO: PaginationPageNumber) => pNO.pageNumber === (currentPage.pageNumber - 2));
      const nextSetPage = pageNumberOptions.find((pNO: PaginationPageNumber) => pNO.pageNumber === (currentPage.pageNumber + 2));
      previousSetPage.isPreviousSetEllipsis = true;
      nextSetPage.isNextSetEllispis = true;

      return [
        firstPage,
        previousSetPage,
        ...pageNumberOptions.slice(currentPageIndex - 1, currentPageIndex + 2),
        nextSetPage,
        lastPage,
      ]
    }

  }
}
