export class ClipboardNote {
    clipboardID: number; // 19,
    clipboard: string; // THIS IS THE NOTE!
    clipboardType: string; // "Q",
    clipboardGroup: string; // "",
    clipboardUserid: number; // 8194,
    changeDate: string; // "2018-04-26 16:44:43.0",
    changeBy: string; // "2020",
}

export class CreateClipboardNoteRequest {
    clipboardGroup: string;
    clipboardType: string;
    clipboard: string;
}

export class CreateClipboardNoteResponse {
    clipboardID: number;
}

export const initialClips = [];
