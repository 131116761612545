import {Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    styleUrls: ['./app-checkbox.component.scss'],
    templateUrl: './app-checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppCheckboxComponent,
            multi: true
        }
    ]
})
export class AppCheckboxComponent implements OnInit, ControlValueAccessor {
    @Input()
    public readOnly = false;
    @Input()
    public labelAfter: boolean = false;
    @Input()
    public label: string;
    @Input()
    public value = false;
    @Output()
    public changeMade = new EventEmitter();
    @Input()
    public tabindex = 0;

    @HostBinding('tabindex') hostTabindex;

    constructor() { }

    @HostListener('keydown.space', ['$event']) toggleCheckmark(e) {
        if (!this.readOnly) {
            e.preventDefault();
            this.value = !this.value;
            this.valueChanged(this.value);
            this.changeMade.emit(this.value);
        }
    }

    public ngOnInit(): void {
        this.hostTabindex = this.tabindex;
    }

    public writeValue(value) {
        this.value = value;
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    private valueChanged = (_: any) => {};

}
