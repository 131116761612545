<form *ngIf="customerForm" class="customerMainPanel" [formGroup]="customerForm" (ngSubmit)="saveChanges()">

    <!-- =========================== ADMIN CUSTOMER TABS =========================== -->
    <div class="tabs">
        <app-tab-row tabindex="0" class="tabsRow" [activeTab]="activeTab" (activatedTab)="activeTab = $event.index" [grayTabs]="false" [useArrows]="false" [useDownArrow]="true" [tabs]="tabs"></app-tab-row>
    </div>

    <!-- =========================== CUSTOMER GENERAL SCREEN =========================== -->
    <div class="customerGeneralBody customerBodyScreen" *ngIf="activeTab === 0">
        <!-- =========================== CUSTOMER GENERAL LEFT PANEL =========================== -->
        <div class="customerGeneralLeftPanel">
            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    <span class="required">*</span>&nbsp; ID
                </div>

                <div *ngIf="!isNewCustomer()" class="customerGeneralValue adminCustomerReadOnly">
                    {{ customerId }}
                </div>

                <input *ngIf="isNewCustomer()" type="text" formControlName="custNo" class="customerGeneralValue customerGeneralInputTag"
                       [ngClass]="{ 'invalidBorder': !custNo.pristine && custNo.errors && (custNo.errors!.required || custNo.errors!.maxlength || custNo.errors!.pattern) }">
            </div>
            <div *ngIf="!custNo.pristine && custNo.errors && (custNo.errors!.required || custNo.errors!.maxlength || custNo.errors!.pattern)" class="customerGeneralRow invalidMessage">
                <div class="customerGeneralLabel"></div>
                <div class="customerGeneralValue">
                    {{(custNo.errors!.required) ? 'ID is required' : (custNo.errors!.pattern) ? 'Spaces and "new" not allowed ' : 'ID must be less than 6 characters'}}
                </div>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    <span class="required">*</span>&nbsp; Name
                </div>
                <input formControlName="custName" class="customerGeneralValue customerGeneralInputTag" [ngClass]="{ 'invalidBorder': !custName.pristine && custName.errors && custName.errors!.required }">
            </div>
            <div *ngIf="!custName.pristine && custName.errors && custName.errors!.required" class="customerGeneralRow invalidMessage">
                <div class="customerGeneralLabel"></div>
                <div class="customerGeneralValue">
                    Name is required
                </div>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    Active
                </div>
                <div class="customerGeneralValue">
                    <div class="activePanel">
                        <div class="customerGeneralRadioPanel">
                            <div class="customerGeneralValue customerGeneralRadioPanel">
                                <app-radio-group class="customerGeneralRadioLabel" [justify]="'flex-start'" [values]="yesNoRadioValues" formControlName="custActive" ></app-radio-group>
                            </div>
                        </div>

                        <div class="customerGeneralLabel">
                            Start
                        </div>

                        <div class="customerDateValue customerGeneralInputTag">
                            <input class="customerDateInput" name="startDate" formControlName="custStartDate" />
                            <span class="blue clickable" style="padding-right: 5px" (click)="showDatePickerModal()">
                                <i class="fa fa-calendar" aria-hidden="true" (click)="showDatePickerModal()"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    EDI #
                </div>

                <div class="customerGeneralValue">
                    <input type="text" formControlName="custEDIRef" style="max-width: 5em;" class="customerGeneralInputTag"
                           [ngClass]="{ 'invalidBorder': custEDIRef.errors && custEDIRef.errors!.maxlength }">
                </div>

            </div>
            <div *ngIf="custEDIRef.errors && custEDIRef.errors!.maxlength" class="customerGeneralRow invalidMessage">
                <div class="customerGeneralLabel"></div>
                <div class="customerGeneralValue">
                    EDI # must be 3 characters or less
                </div>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    <span class="required">*</span>&nbsp; TMA Assignment
                </div>
                <div class="customerGeneralValue tmaSelectPanel">
                    <div class="customerGeneralSelectPanel" [ngClass]="{ 'invalidBorder': !tmaID.pristine && tmaID.errors && tmaID.errors!.required }">
                        <app-list class="tmaSelectTag customerGeneralSelectTag" [valueField]="'tmaID'" [isDisabled]="tmaAccepted" [getLabel]="getTmaName" [getKey]="getTmaID" [list]="getTmaList()" formControlName="tmaID" ></app-list>
                    </div>

                    <div class="tmaCheckPanel" [ngClass]="{
                        acceptedTmaCheckPanel: customerForm['controls'].tmaAccepted.value,
                        unacceptedTmaCheckPanel: !customerForm['controls'].tmaAccepted.value
                    }">
                        <span class="tmaCheckIcon">
                            <i class="fa fa-square-o unacceptedTmaIcon clickable" (click)="setTma(true)"></i>

                            <i *ngIf="!customerForm['controls'].tmaAccepted.value || !tmaAccepted" class="fa fa-check-square acceptedTmaIcon clickable"
                               (click)="setTma(false)"></i>
                            <i *ngIf="customerForm['controls'].tmaAccepted.value && tmaAccepted" class="fa fa-check acceptedTmaIcon"></i>
                        </span>

                        <span class="tmaCheckText">
                            <span class="acceptedTmaText">
                                Agreement Accepted
                            </span>

                            <span class="unacceptedTmaText">
                                Manually Accept TMA
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="!tmaID.pristine && tmaID.errors && tmaID.errors!.required" class="customerGeneralRow invalidMessage">
                <div class="customerGeneralLabel"></div>
                <div class="customerGeneralValue">
                    TMA Assignment is required
                </div>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    Chat Active
                </div>

                <div class="customerGeneralValue customerGeneralRadioPanel">
                    <app-radio-group class="customerGeneralRadioLabel" [justify]="'flex-start'" [values]="yesNoRadioValues" formControlName="chatActive" ></app-radio-group>
                </div>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    Insurance Prompt
                </div>

                <div class="customerGeneralValue customerGeneralRadioPanel">
                    <app-radio-group class="customerGeneralRadioLabel" [justify]="'flex-start'" [values]="yesNoRadioValues" formControlName="insurancePrompt" ></app-radio-group>
                </div>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    Opp Reports
                </div>

                <div class="customerGeneralValue customerGeneralRadioPanel">
                    <app-radio-group class="customerGeneralRadioLabel" [justify]="'flex-start'" [values]="yesNoRadioValues" formControlName="invoices_oppReports" ></app-radio-group>
                </div>
            </div>

            <div class="customerGeneralRow customerGeneralLogoRow">
                <div class="customerGeneralLabel">
                    Logo
                </div>
                <div class="customerGeneralValue customerGeneralLogoPanel">
                    <div class="customerGeneralLogoFileName">
                        {{ customerForm.controls['logo_filename']?.value }}
                    </div>

                    <input type="file" name="file" id="file" class="logoInput" (change)="onFileChange($event)">
                    <label for="file" class="customerGeneralLogoBrowseButton btn-outline clickable">Browse ...</label>
                </div>
            </div>

            <div class="logoNoteRow">
                <div class="logoNoteLeft"></div>
                <div class="logoNoteTextContainer">
                    <span class="logoNoteText">
                        Note: File must fit within a 666 by 108 pixel rectangle. Accepted formats include .jpg or .png files.
                    </span>
                </div>
            </div>

        </div>

        <!-- =========================== CUSTOMER GENERAL RIGHT PANEL =========================== -->
        <div class="customerGeneralRightPanel">
            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    Market Segment
                </div>

                <div class="customerGeneralValue customerGeneralSelectPanel">
                    <select class="customerGeneralSelectTag" formControlName="custMarketSeg">
                        <option value=""></option>
                        <option [value]="option.Code" *ngFor="let option of nameCodeMap.custMarketSeg">
                            {{ option.Name }}
                        </option>
                    </select>

                    <span class="customerGeneralSelectCaret">
                        <i class="fa fa-caret-down"></i>
                    </span>
                </div>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    Market Sub Segment
                </div>

                <div class="customerGeneralValue customerGeneralSelectPanel">
                    <select class="customerGeneralSelectTag" formControlName="custMarketSubSeg">
                        <option value=""></option>
                        <option [value]="option.Code" *ngFor="let option of nameCodeMap.custMarketSubSeg">
                            {{ option.Name }}
                        </option>
                    </select>

                    <span class="customerGeneralSelectCaret">
                        <i class="fa fa-caret-down"></i>
                    </span>
                </div>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    Account Manager
                </div>

                <div class="customerGeneralValue customerGeneralSelectPanel">
                    <select class="customerGeneralSelectTag" formControlName="custAcctMgr">
                        <option value=""></option>
                        <option [value]="option.Code" *ngFor="let option of nameCodeMap.custAcctMgr">
                            {{ option.Name }}
                        </option>
                    </select>

                    <span class="customerGeneralSelectCaret">
                        <i class="fa fa-caret-down"></i>
                    </span>
                </div>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    Sales Group
                </div>

                <div class="customerGeneralValue customerGeneralSelectPanel">
                    <select class="customerGeneralSelectTag" formControlName="custSalesRep">
                        <option value=""></option>
                        <option [value]="option.Code" *ngFor="let option of nameCodeMap.custSalesRep">
                            {{ option.Name }}
                        </option>
                    </select>

                    <span class="customerGeneralSelectCaret">
                        <i class="fa fa-caret-down"></i>
                    </span>
                </div>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    SROD Ops Group
                </div>

                <div class="customerGeneralValue customerGeneralSelectPanel">
                    <select class="customerGeneralSelectTag" formControlName="SROD_opsGroup">
                        <option value=""></option>
                        <option [value]="SRODOpsGroup.code" *ngFor="let SRODOpsGroup of SRODOpsGroupOptions">
                            {{ SRODOpsGroup.description }}
                        </option>
                    </select>

                    <span class="customerGeneralSelectCaret">
                        <i class="fa fa-caret-down"></i>
                    </span>
                </div>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    SROD Assigned User
                </div>

                <div class="customerGeneralValue customerGeneralSelectPanel" *ngIf="SRODAssignedUsersOptions?.length <= dropdownResultLimit">
                    <select class="customerGeneralSelectTag" formControlName="SROD_assignedUser">
                        <option value=""></option>
                        <option [ngValue]="SRODAssignedUser.userid" *ngFor="let SRODAssignedUser of SRODAssignedUsersOptions">
                            {{ SRODAssignedUser.Names }}
                        </option>
                    </select>

                    <span class="customerGeneralSelectCaret">
                        <i class="fa fa-caret-down"></i>
                    </span>
                </div>

                <ng-container *ngIf="findDisplayObject(customerForm?.value.SROD_assignedUser, SRODAssignedUsersOptions) as displayObject">
                    <div class="customerGeneralValue customerGeneralOverflowPanel clickable" *ngIf="!SRODAssignedUsersOptions || SRODAssignedUsersOptions.length > dropdownResultLimit"
                         [ngClass]="{
                                'hideOverflowPanelDisplayName': !displayObject.Names
                            }" (click)="showAdminCustomerOverflowModal('userid', 'Names', SRODAssignedUsersOptions, customerForm['controls'].SROD_assignedUser,
                    'Assigned Users')" >
                        <span class="customerGeneralValueDisplayContainer">
                            <span class="valueDisplayText">
                                {{ displayObject.Names}}
                            </span>

                            <span class="removeValueIcon clickable" (click)="clearOverflowFormControlDisplayName($event, customerForm['controls'].SROD_assignedUser)">
                                <i class="fa fa-times"></i>
                            </span>
                        </span>

                        <span class="overflowSearchIcon clickable">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </ng-container>
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    SROD Team Email
                </div>
                <input formControlName="SROD_teamEmail" class="customerGeneralValue customerGeneralInputTag">
            </div>

            <div class="customerGeneralRow">
                <div class="customerGeneralLabel">
                    SROD Cust. Mask
                </div>
                <input formControlName="SROD_srodName" class="customerGeneralValue customerGeneralInputTag"
                       [ngClass]="{ 'invalidBorder': SROD_srodName.errors && SROD_srodName.errors!.maxlength }">
            </div>
            <div *ngIf="SROD_srodName.errors && SROD_srodName.errors.maxlength" class="customerGeneralRow invalidMessage">
                <div class="customerGeneralLabel"></div>
                <div class="customerGeneralValue">
                    SROD Customer Mask too long
                </div>
            </div>
        </div>
    </div>

    <!-- =========================== API/EDI SCREEN ==================================== -->
    <div class="customerApiEdiBody customerBodyScreen" *ngIf="activeTab === 1">
        <!-- ======================== PICKUP REQUEST PANEL ======================== -->
        <div class="apiEdiDetailContainer">
            <div class="apiEdiDetailHeader">
                <span class="apiEdiDetailHeaderText">
                    Pickup Request
                </span>
            </div>

            <div class="apiEdiDetailRowContainer">
                <div class="apiEdiDetailRow">
                    <div class="apiEdiDetailRowLabel">
                        Active
                    </div>
                    <div class="apiEdiDetailRowValue">
                        <span *ngIf="APIEDI && APIEDI.PickupRequest">
                            <span *ngIf="APIEDI.PickupRequest.active">
                                Active
                            </span>

                            <span *ngIf="!APIEDI.PickupRequest.active">
                                Inactive
                            </span>
                        </span>

                        <span *ngIf="!APIEDI || !APIEDI.PickupRequest">
                            Not Setup
                        </span>
                    </div>
                </div>

                <div class="apiEdiDetailRow">
                    <div class="apiEdiDetailRowLabel">
                        Sender ID
                    </div>
                    <div class="apiEdiDetailRowValue">
                        <span *ngIf="APIEDI && APIEDI.PickupRequest">
                            {{ APIEDI.PickupRequest.SenderID }}
                        </span>
                    </div>
                </div>

                <div class="apiEdiDetailRow">
                    <div class="apiEdiDetailRowLabel">
                        Mode
                    </div>
                    <div class="apiEdiDetailRowValue">
                        <span *ngIf="APIEDI && APIEDI.PickupRequest">
                            {{ APIEDI.PickupRequest.Mode }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- ======================== STATUS UPDATES PANEL ======================== -->
        <div class="apiEdiDetailContainer">
            <div class="apiEdiDetailHeader">
                <span class="apiEdiDetailHeaderText">
                    Status Updates
                </span>
            </div>

            <div class="apiEdiDetailRowContainer">
                <div class="apiEdiDetailRow">
                    <div class="apiEdiDetailRowLabel">
                        Active
                    </div>
                    <div class="apiEdiDetailRowValue">
                        <span *ngIf="APIEDI && APIEDI.StatusUpdates">
                            <span *ngIf="APIEDI.StatusUpdates.active">
                                Active
                            </span>

                            <span *ngIf="!APIEDI.StatusUpdates.active">
                                Inactive
                            </span>
                        </span>

                        <span *ngIf="!APIEDI || !APIEDI.StatusUpdates">
                            Not Setup
                        </span>
                    </div>
                </div>

                <div class="apiEdiDetailRow">
                    <div class="apiEdiDetailRowLabel">
                        Type
                    </div>
                    <div class="apiEdiDetailRowValue">
                        <span *ngIf="APIEDI && APIEDI.StatusUpdates">
                            {{ APIEDI.StatusUpdates.type | titlecase }}
                        </span>
                    </div>
                </div>

                <div class="apiEdiDetailRow">
                    <div class="apiEdiDetailRowLabel">
                        Status
                    </div>
                    <div class="apiEdiDetailRowValue">
                        <span *ngIf="APIEDI && APIEDI.StatusUpdates">
                            {{ APIEDI.StatusUpdates.status }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- =========================== OLT CREDENTIALS SCREEN ==================================== -->
    <div class="customerOLTCredBody customerBodyScreen" *ngIf="activeTab === 2">

        <!-- =========================== WIN CREDENTIALS PANEL =========================== -->
        <div class="winCredPanel">
            <div class="OLTCredHeader">
                <span class="OLTCredHeaderText">
                    WIN Credentials
                </span>
            </div>

            <!-- =========================== WIN CREDENTIALS FORM FIELDS ======================== -->
            <div class="OLTCredFormContainer">

                <!-- =========================== WIN CREDENTIALS LEFT PANEL =========================== -->
                <div class="OLTCredLeftPanel">
                    <div class="OLTCredFormRow">
                        <div class="OLTCredFormRowLabel">
                            Username
                        </div>
                        <input type="text" formControlName="OTLCredentials_WIN_username" class="OLTCredFormRowValue OLTCredFormRowInputTag">
                    </div>
                </div>

                <!-- =========================== WIN CREDENTIALS RIGHT PANEL =========================== -->
                <div class="OLTCredRightPanel">
                    <div class="OLTCredFormRow">
                        <div class="OLTCredFormRowLabel">
                            Password
                        </div>
                        <div class="OLTCredFormRowValue OLTCredPasswordPanel">
                            <input id="oltCredPasswordInput" [type]="passwordInputType" formControlName="OLTCredentials_WIN_password" class="OLTCredPasswordInput">
                            <span class="OLTCredPasswordIconContainer">
                                <i class="fa fa-eye OLTCredPasswordIcon clickable" (click)="passwordInputType = (passwordInputType === 'text') ? 'password' : 'text'"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- =========================== WIN/TMS INTEGRATION  PANEL =========================== -->
        <div class="winTMSPanel">
            <div class="OLTCredHeader">
                <span class="OLTCredHeaderText">
                    WIN/TMS Integration
                </span>
            </div>

            <!-- =========================== WIN/TMS INTEGRATION FORM FIELDS ======================== -->
            <div class="OLTCredFormContainer">

                <!-- =========================== WIN/TMS INTEGRATION LEFT PANEL =========================== -->
                <div class="OLTCredLeftPanel">
                    <div class="OLTCredFormRow">
                        <div class="OLTCredFormRowLabel">
                            Organization Name
                        </div>
                        <input type="text" formControlName="OLTCredentials_WINIntegration_organizationName" class="OLTCredFormRowValue OLTCredFormRowInputTag">
                    </div>

                    <div class="OLTCredFormRow" *ngIf="!isNewCustomer()">
                        <div class="OLTCredFormRowLabel">
                            SROD UID
                        </div>

                        <div class="OLTCredFormRowValue OLTCredFormRowSelectPanel" *ngIf="shipmentUIDOptions.length <= dropdownResultLimit">
                            <select class="OLTCredSelectTag" formControlName="OLTCredentials_WINIntegration_srodUID">
                                <option value=""></option>
                                <option [ngValue]="shipmentUID.userid" *ngFor="let shipmentUID of shipmentUIDOptions">
                                    {{ shipmentUID.ShipmentUIDs }}
                                </option>
                            </select>

                            <span class="OLTCredSelectCaret">
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </div>

                        <ng-container *ngIf="findDisplayObject(customerForm?.value.OLTCredentials_WINIntegration_srodUID, shipmentUIDOptions) as displayObject">
                            <div class="customerGeneralValue customerGeneralOverflowPanel clickable" *ngIf="!shipmentUIDOptions || shipmentUIDOptions.length > dropdownResultLimit"
                                 [ngClass]="{
                                'hideOverflowPanelDisplayName': !displayObject.ShipmentUIDs
                            }" (click)="showAdminCustomerOverflowModal('userid', 'ShipmentUIDs', shipmentUIDOptions, customerForm['controls'].OLTCredentials_WINIntegration_srodUID, 'SROD UID')">
                                <span class="customerGeneralValueDisplayContainer">
                                    <span class="valueDisplayText">
                                        {{ displayObject.ShipmentUIDs }}
                                    </span>

                                    <span class="removeValueIcon clickable" (click)="clearOverflowFormControlDisplayName($event, customerForm['controls'].OLTCredentials_WINIntegration_srodUID)">
                                        <i class="fa fa-times"></i>
                                    </span>
                                </span>

                                <span class="overflowSearchIcon clickable">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </ng-container>
                    </div>

                    <div class="OLTCredFormRow" *ngIf="!isNewCustomer()">
                        <div class="OLTCredFormRowLabel">
                            Net UID
                        </div>

                        <div class="OLTCredFormRowValue OLTCredFormRowSelectPanel" *ngIf="quoteUIDOptions.length <= dropdownResultLimit">
                            <select class="OLTCredSelectTag" formControlName="OLTCredentials_WINIntegration_netUID">
                                <option value=""></option>
                                <option [ngValue]="quoteUID.userid" *ngFor="let quoteUID of quoteUIDOptions">
                                    {{ quoteUID.QuoteUIDs }}
                                </option>
                            </select>

                            <span class="OLTCredSelectCaret">
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </div>

                        <ng-container *ngIf="findDisplayObject(customerForm?.value.OLTCredentials_WINIntegration_netUID, quoteUIDOptions) as displayObject">
                            <div class="customerGeneralValue customerGeneralOverflowPanel clickable" *ngIf="!quoteUIDOptions || quoteUIDOptions.length > dropdownResultLimit"
                                 [ngClass]="{
                                'hideOverflowPanelDisplayName': !displayObject.QuoteUIDs
                            }" (click)="showAdminCustomerOverflowModal('userId', 'QuoteUIDs', quoteUIDOptions, customerForm['controls'].OLTCredentials_WINIntegration_netUID, 'Net UID')">
                                <span class="customerGeneralValueDisplayContainer">
                                    <span class="valueDisplayText">
                                        {{ displayObject.QuoteUIDs }}
                                    </span>

                                    <span class="removeValueIcon clickable" (click)="clearOverflowFormControlDisplayName($event, customerForm['controls'].OLTCredentials_WINIntegration_netUID)">
                                        <i class="fa fa-times"></i>
                                    </span>
                                </span>

                                <span class="overflowSearchIcon clickable">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- =========================== WIN/TMS INTEGRATION RIGHT PANEL =========================== -->
                <div class="OLTCredRightPanel">
                    <div class="OLTCredFormRow" *ngIf="!isNewCustomer()">
                        <div class="OLTCredFormRowLabel">
                            Buy UID
                        </div>

                        <div class="OLTCredFormRowValue OLTCredFormRowSelectPanel" *ngIf="quoteUIDOptions.length <= dropdownResultLimit">
                            <select class="OLTCredSelectTag" formControlName="OLTCredentials_WINIntegration_buyUID">
                                <option value=""></option>
                                <option [ngValue]="quoteUID.userid" *ngFor="let quoteUID of quoteUIDOptions">
                                    {{ quoteUID.QuoteUIDs }}
                                </option>
                            </select>

                            <span class="OLTCredSelectCaret">
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </div>

                        <ng-container *ngIf="findDisplayObject(customerForm?.value.OLTCredentials_WINIntegration_buyUID, quoteUIDOptions) as displayObject">
                            <div class="customerGeneralValue customerGeneralOverflowPanel clickable" *ngIf="!quoteUIDOptions || quoteUIDOptions.length > dropdownResultLimit"
                                 [ngClass]="{
                                'hideOverflowPanelDisplayName': displayObject.QuoteUIDs
                            }" (click)="showAdminCustomerOverflowModal('userId', 'QuoteUIDs', quoteUIDOptions, customerForm['controls'].OLTCredentials_WINIntegration_buyUID, 'Buy UID')">
                                <span class="customerGeneralValueDisplayContainer">
                                    <span class="valueDisplayText">
                                        {{ displayObject.QuioteUIDs }}
                                    </span>

                                    <span class="removeValueIcon clickable" (click)="clearOverflowFormControlDisplayName($event, customerForm['controls'].OLTCredentials_WINIntegration_buyUID)">
                                        <i class="fa fa-times"></i>
                                    </span>
                                </span>

                                <span class="overflowSearchIcon clickable">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </ng-container>
                    </div>

                    <div class="OLTCredFormRow" *ngIf="!isNewCustomer()">
                        <div class="OLTCredFormRowLabel">
                            Sell UID
                        </div>

                        <div class="OLTCredFormRowValue OLTCredFormRowSelectPanel" *ngIf="quoteUIDOptions.length <= dropdownResultLimit">
                            <select class="OLTCredSelectTag" formControlName="OLTCredentials_WINIntegration_sellUID">
                                <option value=""></option>
                                <option [ngValue]="quoteUID.userid" *ngFor="let quoteUID of quoteUIDOptions">
                                    {{ quoteUID.QuoteUIDs }}
                                </option>
                            </select>

                            <span class="OLTCredSelectCaret">
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </div>

                        <ng-container *ngIf="findDisplayObject(customerForm?.value.OLTCredentials_WINIntegration_sellUID, quoteUIDOptions) as displayObject">
                            <div class="customerGeneralValue customerGeneralOverflowPanel clickable" *ngIf="!quoteUIDOptions || quoteUIDOptions.length > dropdownResultLimit"
                                 [ngClass]="{
                                'hideOverflowPanelDisplayName': !displayObject.QuoteUIDs
                            }" (click)="showAdminCustomerOverflowModal('userId', 'QuoteUIDs', quoteUIDOptions, customerForm['controls'].OLTCredentials_WINIntegration_sellUID, 'Sell UID')">
                                <span class="customerGeneralValueDisplayContainer">
                                    <span class="valueDisplayText">
                                        {{ displayObject.QuoteUIDs }}
                                    </span>

                                    <span class="removeValueIcon clickable" (click)="clearOverflowFormControlDisplayName($event, customerForm['controls'].OLTCredentials_WINIntegration_sellUID)">
                                        <i class="fa fa-times"></i>
                                    </span>
                                </span>

                                <span class="overflowSearchIcon clickable">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </ng-container>
                    </div>

                    <div class="OLTCredFormRow">
                        <div class="OLTCredFormRowLabel">
                            Default Class
                        </div>
                        <div class="OLTCredFormRowValue OLTCredFormRowSelectPanel">
                            <select class="OLTCredSelectTag" formControlName="OLTCredentials_WINIntegration_defaultClass">
                                <option value=""></option>
                                <option [value]="nmfcClass.class" *ngFor="let nmfcClass of nmfcClasses">
                                    {{ nmfcClass.displayClass }}
                                </option>
                            </select>

                            <span class="OLTCredSelectCaret">
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- =========================== CUSTOMER HIERARCHY PANEL =========================== -->
    <div class="customerHierarchyPanel" *ngIf="!isNewCustomer()">
        <span class="customerHierarchyMainLabel">
            Customer Hierarchy
        </span>

        <span class="customerHierarchyLabel">
            Number of Groups
        </span>
        <span class="customerHierarchyValue clickable">
            --
        </span>

        <span class="customerHierarchyLabel">
            Number of Users
        </span>
        <span class="customerHierarchyValue clickable">
            --
        </span>

        <span class="customerHierarchyLabel">
            TMA
        </span>
        <span class="customerHierarchyValue clickable">
            View Agreement
        </span>
    </div>
</form>

<app-admin-customer-overflow-modal *ngIf="isCustomerOverflowModalVisible" [selectedOverflowModalOption]="selectedOverflowModalOption"
    [modalTitle]="overflowModalTitle" (closeModal)="closeAdminCustomerOverflowModal($event)"></app-admin-customer-overflow-modal>

<app-custom-datepicker *ngIf="isDatePickerModalShown " [isDateRangePossible]="false " [inputDate]="defaultDatePickerDate
                " [title]=" 'Select a Start Date' " [dateFormatPattern]="dateConstants.UNIFORM_DATE_DISPLAY.format" [dateFormatValidationPattern]="dateConstants.UNIFORM_DATE_DISPLAY.validation"
                       (closeDatePicker)="hideDatePickerModal($event) "></app-custom-datepicker>
