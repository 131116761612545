export class DocumentUploadRequest {
    customerId: string;
    shipmentId: number;
    userId: number;
    file: {
        filename: string,
        filetype: string,
        value: string, // document content as base64 encoded binary data
    }
}
