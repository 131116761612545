<div class="overlay">
    <div class="passwordModal">
        <div class="modalHeader">
            <div>{{title}}</div>
            <div class="closeModalContainer">
                <div class="closeModalIconContainer clickable">
                    <i class="fa fa-times closeModalIcon" (click)="cancel.emit();"></i>
                </div>
            </div>
        </div>
        <form [formGroup]="passwordForm">
            <div class="passwordBoxContainer">
                <app-password [passwordRegexText]="passwordRegexText" [passwordRegex]="passwordRegex" formControlName="password"></app-password>
                <div class="buttonPanel">
                    <div>
                        <app-button [tabIndex]="0" [inactive]="passwordForm.invalid" [buttonColor]="'blue'" (click)="changePassword()" >CHANGE PASSWORD</app-button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
