<ng-template #defaultSectionRenderer let-section="section" >
    Please create sectionRenderer template so {{section}} can be rendered.
</ng-template>

<ng-template #defaultHeaderRenderer let-section="section" >
    {{section}}
</ng-template>

<div class="accordionView">
    <ng-container *ngFor="let section of sections; let i = index">
        <div class="accordionRow clickable" (click)="clicked($event, i)">
            <div class="accordionContentHolder">
                <span class="chevron" *ngIf="sectionStatus[i]">
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                </span>
                <span class="chevron" *ngIf="!sectionStatus[i]">
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </span>
                <span class="accordionHeader">
                    <ng-container [ngTemplateOutlet]="headerRenderer || defaultHeaderRenderer" [ngTemplateOutletContext]="{ $implicit: i, section: section }"></ng-container>
                </span>
            </div>
        </div>
        <div class="accordionRecordSection" *ngIf="sectionStatus[i]">
            <ng-container [ngTemplateOutlet]="sectionRenderer || defaultSectionRenderer" [ngTemplateOutletContext]="{ $implicit: i, section: section }"></ng-container>
        </div>
    </ng-container>
</div>
