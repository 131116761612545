import { RateTierRole } from './rateTier.role.masterData';
import { RoleMasterDataPermissionDetail } from './role.masterData.permissionDetail';

export class RoleDetailMasterData {
    changeBy?: string;
    changeDate?: string;
    roleCustomer: string;
    roleDescription: string;
    roleID?: number;
    roleName: string;
    roleType: string;
    tmaAuthorized: number;
    defaults: {
        autoSaveContacts: boolean;
        changeBy?: string;
        changeDate?: string;
        defaultAccessorials: string;
        defaultBillTo: string;
        defaultClass: string;
        defaultCommodity: string;
        defaultConsignee: string;
        defaultDestCountry: string;
        defaultDestType: string;
        defaultDestZip: string;
        defaultDirection: string;
        defaultOriginCountry: string;
        defaultOriginType: string;
        defaultOriginZip: string;
        defaultRecordTab: string;
        defaultShipper: string;
        defaultTSA: number;
        defaultUoM: string;
        defaultWeight: number;
        EditDefaults: number;
        hideQuoteDisclaimer: number;
        preferredRecordDisplayStyle: string;
        quoteDisclaimer: string;
        RoleID?: number;
        paymentTerms: string;
        // RolesUserDefaultscol: string;    // replaced by 'defaultRecordTab'
    };
    permissions: {
        group: RoleMasterDataPermissionDetail,
        global: RoleMasterDataPermissionDetail,
        user: RoleMasterDataPermissionDetail
    };
    quotes: {
        APcontract: string;
        BOLFormat: any;
        BOLRequired: number;
        changeBy?: string;
        changeDate?: string;
        displayBOL: number;
        displayCarriers: string;
        displayPO: number
        displayRates: number;
        excludeCarriers: string;
        finalMile: number;
        guaranteeRates: number;
        limitCube: number;
        limitCubeValue: string;
        POFormat: string;
        PORequired: number;
        ratedClass: number;
        requireCommodity: number;
        requireCube: number;
        roleID?: number;
        showCarrierKPI: number;
        showVLSCFS: number;
        spotRite: number;
        VLSCFS: string;
    };
    rateTiers: Array<RateTierRole>;
    shipments: {
        autoClearShipments: number;
        autoTender: number;
        createShipment: number;
        creditCard: number;
        executeShipment: number;
        forwarderFields: number;
        insurance: number;
        placeToPrintBOL: number;
        srodTrackingAccl: number;
        lockBillTo: number;
    }
}
