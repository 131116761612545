<div class="overlay">
  <div class="insuranceQuotingModal">

    <!--=========================== MODAL HEADER ==============================-->
    <div class="modalHeader">
      <div class="modalHeaderText">Add Insurance</div>
      <div class="modalHeaderIcon">
        <i class="fa fa-times clickable" aria-hidden="true" (click)="closeModal()"></i>
      </div>
    </div>

    <!--======================== MODAL BODY ========================-->
    <div class="modalBody">
      <!--======================== TERMS OF AGREEMENT ========================-->
      <div class="termsOfAgreementHolder">
        <div class="termsOfAgreementTitle">
          <span class="termsOfAgreementTitleMain">
            Terms of Agreement
          </span>
          <span class="termsOfAgreementTitleNote">
            Note: Insurance is only valid if purchased before ship date and is billed through CTS.
          </span>
        </div>

        <div class="termsOfAgreementBody scrollbar" id="termsOfAgreementContainer">
        </div>
      </div>

      <!--======================== ACTUAL INSURANCE PANEL ========================-->
      <div class="shipmentInsurancePanel borderLeft">
        <div class="shipmentInsuranceInfo borderBottom">
          <div class="shipmentInfoTitle">
            Enter Shipment Information
          </div>

          <form class="shipmentInsuranceInfoSection" [formGroup]="declareShipmentValueForm" (ngSubmit)="requestInsuranceQuotedValue()">
            <div class="shipmentInsuranceLabel">
              <span class="required">*</span>Shipment Value
            </div>

            <div class="shipmentInsuranceValue">
              <span class="shipmentValueInputSymbol">$</span>
              <input type="text" class="shipmentValueInput" formControlName="declaredShipmentValue">
              <span class="shipmentValueCurrency">USD</span>
            </div>
          </form>

          <div class="shipmentInsuranceInfoSection">
            <div class="shipmentInsuranceLabel">
              Shipment Cost
            </div>
            <div class="shipmentInsuranceValue">
              {{ shipmentCost | currency }}
            </div>
          </div>

          <div class="shipmentInsuranceInfoSection">
            <div class="shipmentInsuranceLabel">
              Insurance Cost
            </div>
            <div class="shipmentInsuranceValue">
              {{ insuranceCharge | currency }}
            </div>
          </div>

        </div>
        <!--============ TOTAL SHIPMENT COST PORTION ============-->
        <div class="totalShipmentCostContainer borderBottom">
          <div class="totalShipmentCostDisplay">
            <div class="shipmentInsuranceLabel">
              Total Shipment Cost
            </div>
            <div class="shipmentInsuranceValue">
              <span>
                {{ totalShipmentCost | currency }}
              </span>
            </div>
          </div>

          <div class="totalShipmentCostButtonHolder">
            <button class="btn-outline clickable" (click)="requestInsuranceQuotedValue()">Get A Quote</button>
            <button class="btn-outline clickable" (click)="closeModal()">Cancel</button>
          </div>
        </div>

        <div class="purchaseInsurancePanel">
          <div class="purchaseInsuranceDisplay">
            By clicking "Purchase Insurance" below, you are agreeing to all the terms of agreement.
          </div>
          <div class="purchaseInsuranceButtonHolder">
            <button class="btn-outline clickable" (click)="purchaseInsurance()">Purchase Insurance</button>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>