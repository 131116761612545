import {Injectable} from "@angular/core";
import {HttpRequest} from "@angular/common/http";
import {ApiService, REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT} from "./api.service";
import {ResponseDTO} from "../models/responseDto";
import {catchError, map, take} from "rxjs/operators";
import {Router} from "@angular/router";
import {NotificationService} from "./notification.service";

@Injectable()
export class EntityService {
    private entityTypeToURL = {
        Customer: 'MasterData/Customer',
        Markup: 'MasterData/Markups/Main',
        User: 'MasterData/User',
        Role: 'MasterData/Roles',
        Contact: 'MasterData/Contacts',
        USOQuote: 'USO/quote',
        USOBookingOptions: 'USO/bookingoptions',
        ShipmentUnified: 'shipment/main/Test',
        Product: 'MasterData/Products',
    }

    constructor(
        private _api: ApiService,
        private _notificationService: NotificationService,
        private _router: Router,
    ) {
    }
    public getEntityListAPI<T>(entityType: string) {
        const req = new HttpRequest(REQUEST_TYPE_GET, `${(this.entityTypeToURL)[entityType]}`);
        return this._api.callApiService<ResponseDTO<T>>(req).pipe(
            take(1),
            map(response => {
                this.checkMessages(response, `Get List ${entityType}`);
                if (response && response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            })
        );
    }

    public getEntityList<T>(entityType: string) {
        return this.getEntityListAPI(entityType).pipe(
            take(1),
            map(entityList => entityList),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: `Get ${entityType} List`
                });
                throw err;
            })
        );
    }

    public getEntityAPI<T>(entityType: string, params: any) {
        const req = new HttpRequest(REQUEST_TYPE_GET, this.getUrlFor(entityType, params));
        return this._api.callApiService<ResponseDTO<T>>(req).pipe(
            take(1),
            map(response => {
                this.checkMessages(response, `Get ${entityType}`);
                if (response && response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
        )
    }

    public getEntity<T>(entityType: string, params: any) {
        return this.getEntityAPI<T>(entityType, params).pipe(
            take(1),
            map(entity => entity),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: `Get ${entityType}`
                });
                throw err;
            })
        );
    }

    public updateEntityAPI<T>(entity: T, entityType: string, params: any) {
        const req = new HttpRequest(REQUEST_TYPE_PUT, this.getUrlFor(entityType, params), entity);
        return this._api.callApiService<ResponseDTO<T>>(req).pipe(
            take(1),
            map(response => {
                this.checkMessages(response, `Update ${entityType}`);
                if (response && response.isValid && response.dto) {
                    const updatedEntity: T = response.dto;

                    return updatedEntity
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            })
        );
    };

    public updateEntity<T>(entity: T, entityType: string, params: any, nameFieldName: string) {
        return this.updateEntityAPI<T>(entity, entityType, params).pipe(
            take(1),
            map((entityResult: T) => {
                this._notificationService.notifySuccess({
                    title: `Update ${entityType}`,
                    message: `${entityType} ${entityResult[nameFieldName] ? entityResult[nameFieldName] : ''} successfully saved.`
                });
                return entityResult;
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: `Update ${entityType}`
                });
                throw err;
            })
        );
    }

    public createEntityAPI<T>(entity: T, entityType: string, params?: any) {
        const req = new HttpRequest(REQUEST_TYPE_POST, this.getUrlFor(entityType, params), entity);
        return this._api.callApiService<ResponseDTO<T>>(req).pipe(
            map(response => {
                this.checkMessages(response, `Create ${entityType}`);
                if (response && response.isValid && response.dto) {
                    const createdEntity: T = response.dto;

                    return createdEntity;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            })
        );
    };

    public createEntity<T>(entity: T, entityType: string, idFieldName?: string, nameFieldName?: string, navToPath?: string, params?: any) {
        return this.createEntityAPI<T>(entity, entityType, params).pipe(
            take(1),
            map((entityResult: T) => {
                if (nameFieldName) {
                    this._notificationService.notifySuccess({
                        title: `Create ${entityType}`,
                        message: `${entityType} ${entityResult[nameFieldName] ? entityResult[nameFieldName] : ''} successfully created.`
                    });
                }
                if (navToPath) {
                    if (!idFieldName) {
                        this._router.navigate([navToPath]);
                    } else {
                        this._router.navigate([navToPath, entityResult[idFieldName]]);
                    }
                }
                return entityResult;
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: `Create ${entityType}`
                });
                throw err;
            })
        );
    }

    public deleteEntityAPI(params: any, entityType: string) {
        const req = new HttpRequest(REQUEST_TYPE_DELETE, this.getUrlFor(entityType, params));
        return this._api.callApiService<{ isValid: boolean, messages: Array<any> }>(req).pipe(
            take(1),
            map(response => {
                this.checkMessages(response, `Delete ${entityType}`);
                if (response && response.isValid) {
                    return response;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            })
        );
    }

    public deleteEntity(params: any, entityType: string, deleteVerb = 'delete') {
        const cappedVerb = deleteVerb.charAt(0).toUpperCase() + deleteVerb.slice(1);
        return this.deleteEntityAPI(params, entityType).pipe(
            take(1),
            map(response => {
                this._notificationService.notifySuccess({
                    title: `${cappedVerb} ${entityType}`,
                    message: `You have successfully ${deleteVerb}d this ${entityType}`
                });
                return response;
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: `${cappedVerb} ${entityType}`
                });
                throw err;
            })
        );
    }

    private getUrlFor(entityType: string, params?: any): string {
        let queryString = `${(this.entityTypeToURL)[entityType]}`;
        let key;
        let i = 0;
        if (params === undefined) {
            return queryString;
        }
        if (typeof params === 'number' || typeof params === 'string' || params instanceof String) {
            return `${queryString}/${params}`;
        } else if (typeof params === 'object') {
            for (key of Object.keys(params)) {
                queryString += `${((i++ === 0) ? '?' : '&')}${key}=${params[key]}`;
            }
            return queryString;
        }
    }

    private checkMessages(response, title) {
        let message;
        if (response.messages && response.messages.length) {
            for (message of response.messages) {
                if (message.messageType.toUpperCase() === 'ERROR') {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                } else if (message.messageType.toUpperCase() === 'WARNING') {
                    this._notificationService.showNotificationsFromResponseDtoMessages({
                        response,
                        title
                    });
                }
            }
        }
    }
}
