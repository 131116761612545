export class ProductMasterData {
  produtsId: number;
  Customer: string;
  ID: number; // shipment ID
  Name: string;
  Description: string;
  description2: string;
  class: number;
  displayClass: string;
  Active: number;
  GL_Code: string;
  Hazmat: number;
  hazmat_class: string;
  hazmat_packingGroup: string;
  hazmat_subClass: string;
  Hazmat_number: string;
  Type2: number;
  OutsideID: number; // customer assigned product ID/SKU
  // NMFC: number;
  nmfc: string;
  NMFC?: string;  // this property is returned from the GET /MasterData/Products call
  Pack: number;
  Type: number;
  Marks: string;
}
