import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'app-page-not-found',
    styleUrls: ['./pageNotFound.component.scss'],
    templateUrl: './pageNotFound.component.html',
})
export class PageNotFoundComponent implements OnInit {
    public route: string;

    constructor(
        private _notificationService: NotificationService,
        private _router: Router,
    ) { }

    public ngOnInit() {
        this.route = this._router.routerState.snapshot.url;
        this._notificationService.notifyError({ title: `Invalid URL`, message: `${this.route}` });
    }
}
