import {HttpErrorResponse, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import { Shipper } from 'app/models/shipper';
import { ZipSearchResult } from 'app/models/zip.searchResult';
import {LabelValue} from '../components/app-drop-value/app-drop-value.component';
import {HAZMAT_CODE} from '../components/app-shipment/app-shipment.component';
import * as Constants from '../constants/constants';
import * as DateConstants from '../constants/datetime.constants';
import {UserHelper} from '../helpers/userHelper';
import {clearArray} from '../helpers/utilities';
import {AccessorialForQuote} from '../models/accessorialForQuote';
import {AccessorialForShipment} from '../models/accessorialForShipment';
import {Consignee} from '../models/consignee';
import {ContactMasterData} from '../models/contact.masterData';
import {ContactSearchResult} from '../models/contact.searchResult';
import {EquipmentType} from '../models/equipmentType';
import {Product} from '../models/product';
import {ProductDetail} from '../models/productDetail';
import {PurchaseOrder} from '../models/purchaseOrder';
import {QuotedRate} from '../models/quotedRate';
import {QuoteSaveRequest} from '../models/quoteSaveRequest';
import {QuoteSaveResponse} from '../models/quoteSaveResponse';
import {QuotesRequest} from '../models/quotesRequest';
import {QuotesResponse} from '../models/quotesResponse';
import {ResponseDTO} from '../models/responseDto';
import {ShipmentNote} from '../models/shipmentNote';
import {ShipmentUnified} from '../models/shipmentUnified';
import * as SearchConstants from '../constants/searchCriteria';
import {ApiService, REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT} from './api.service';
import {CommonDataService} from './commonData.service';
import {SearchService} from "./search.service";
import {EntityService} from "./entity.service";
import {NotificationService} from "./notification.service";
import {CubeRequest} from "../models/cubeRequest";
import {SearchResponse} from "../models/searchResponse";
import {SearchRequest} from "../models/searchRequest";
import { saveAs } from 'file-saver-es';
import {EMPTY, Observable, from, of} from 'rxjs';
import {catchError, concatMap, last, map, switchMap} from 'rxjs/operators';
import {Insurance} from "../models/insurance";
import {InsuranceService} from "./insurance.service";

export const PATH_NEW = 'new';
export const PATH_QUOTE = 'quote';
export const PATH_TEMPLATE = 'template';

export const EMPTY_BILLTO = 'Select your option';

const dropValueFields = [
    {label: 'Pickup Number', field: 'PU_No'},
    {label: 'Master BOL', field: 'MBOL'},
    {label: 'IT #', field: 'IT_No'},
    {label: 'Container #', field: 'Container_No'},
    {label: 'Reference #', field: 'ReferenceNumber'}
];

@Injectable()
export class ShipmentService {

    lastShipmentUnified: ShipmentUnified;
    lastInsurance: Insurance;
    defaultShipmentValue;

    constructor(
        private _fb: UntypedFormBuilder,
        private _entityService: EntityService,
        private _commonDataService: CommonDataService,
        private _userHelper: UserHelper,
        private _api: ApiService,
        private _notificationService: NotificationService,
        private _router: Router,
        private _searchService: SearchService,
        private _insuranceService: InsuranceService,
    ) {
    }

    public getShipmentUnifiedByPath(id, customerId) {

        switch (id) {
            case PATH_NEW:
                this.lastInsurance = undefined;
                return new Observable(subscriber => {
                    this.lastShipmentUnified = new ShipmentUnified();
                    this.lastShipmentUnified.Main.Customer = customerId;
                    this.lastShipmentUnified.Main.User = this._userHelper.getUserId();
                    const contactObservables = this.applyDefaultsToShipmentUnified(this.lastShipmentUnified);
                    if (contactObservables.length > 0) {
                        from(contactObservables).pipe(
                            concatMap(obs => obs),
                            last(),
                            map(() => {
                                this.enforceAccessorials();
                                subscriber.next(_.cloneDeep(this.lastShipmentUnified));
                                subscriber.complete();
                            }),
                            catchError(err => EMPTY)
                        ).subscribe();
                    } else {
                        this.enforceAccessorials();
                        subscriber.next(_.cloneDeep(this.lastShipmentUnified));
                        subscriber.complete();
                    }
                });
            case PATH_QUOTE:
                this.lastInsurance = undefined;
                return new Observable(subscriber => {
                    this.transformQuoteToShipmentUnified(this.lastShipmentUnified.Quote, this.lastShipmentUnified);
                    this.getBolNumber().pipe(
                        switchMap(bolNumber => {
                            this.lastShipmentUnified.Main.BOL = bolNumber;
                            this.enforceAccessorials();
                            return this._insuranceService.getInsuranceRec(undefined,this.lastShipmentUnified.Quote.quoteId);
                        }),
                        map(insurance => {
                            if (insurance) {
                                this.lastInsurance = insurance;
                            }
                            subscriber.next(_.cloneDeep(this.lastShipmentUnified));
                            subscriber.complete();
                        })
                    ).subscribe();
                });
            case PATH_TEMPLATE:
                return new Observable(subscriber => {
                    this.lastShipmentUnified.Main.Pickup_Date = moment(new Date(), DateConstants.DATE_DISPLAY_MM_DD_YYYY_with_slashes.format).format(DateConstants.DATE_DISPLAY_YYYY_MM_DD.format);
                    this.getBolNumber(this.lastShipmentUnified.Main.BOL === '').pipe(
                        map(bolNumber => {
                            this.lastShipmentUnified.Main.BOL = bolNumber;
                            this.enforceAccessorials();
                            subscriber.next(_.cloneDeep(this.lastShipmentUnified));
                            subscriber.complete();
                        })
                    ).subscribe();
                });
            default:
                this.lastInsurance = undefined;
                return this.getShipmentUnified(id, customerId).pipe(
                    switchMap(shipmentUnified => {
                        if (Object.keys(shipmentUnified).length > 0) {
                            this.lastShipmentUnified = shipmentUnified as ShipmentUnified;
                            this.enforceAccessorials();
                        }
                        return this._insuranceService.getInsuranceRec(shipmentUnified.Main.ID, (shipmentUnified.Quote ? shipmentUnified.Quote.quoteId : undefined));
                    }),
                    map(insurance => {
                        if (insurance) {
                            this.lastInsurance = insurance;
                        }
                        return _.cloneDeep(this.lastShipmentUnified);
                    }),
                    catchError(err => {
                        throw err;
                    })
                )
        }
    }

    public getShipmentUnified(id, customerId) {
        return this._entityService.getEntity<ShipmentUnified>('ShipmentUnified', customerId + '/' + id);
    }

    public getShipmentUnifiedFromForm(form: UntypedFormGroup, quote: QuotesResponse, rate: QuotedRate) {
        const shipmentUnified: ShipmentUnified = _.cloneDeep(this.lastShipmentUnified);
        let acc: AccessorialForQuote;
        let shipAcc: AccessorialForShipment;
        let productDetail: ProductDetail;
        let product: Product;
        let chgId;
        let i;
        let labelField;
        let dropValueField;
        let purchaseOrderLine: PurchaseOrder;
        let isHazmatShipment = false;

        shipmentUnified.Main.BOL = form.controls.bol.value;
        if (form.controls.pickupDate.value.date) {
            shipmentUnified.Main.Pickup_Date = moment(form.controls.pickupDate.value.date, DateConstants.DATE_DISPLAY_MM_DD_YYYY_with_slashes.format).format(DateConstants.DATE_DISPLAY_YYYY_MM_DD.format);
        }

        // Shipper Info:
        shipmentUnified.Main.Shipper_Name = form.controls.shipper.value.name;
        shipmentUnified.Main.shipper_name2 = form.controls.shipper.value.name2;
        shipmentUnified.Main.shipper_address = form.controls.shipper.value.address1;
        shipmentUnified.Main.shipper_address2 = form.controls.shipper.value.address2;
        shipmentUnified.Main.shipper_city = form.controls.shipper.value.city;
        shipmentUnified.Main.shipper_contact_email = form.controls.shipper.value.email;
        shipmentUnified.Main.shipper_contact_fax = form.controls.shipper.value.fax;
        shipmentUnified.Main.shipper_contact_name = form.controls.shipper.value.contact;
        shipmentUnified.Main.shipper_contact_phone = form.controls.shipper.value.phone;
        shipmentUnified.Main.shipper_country = form.controls.shipper.value.country;
        shipmentUnified.Main.shipper_state = form.controls.shipper.value.state;
        shipmentUnified.Main.shipper_zip = form.controls.shipper.value.zip;
        shipmentUnified.Main.Shipper = 0; // (form.controls.shipper.value.id) ? form.controls.shipper.value.id : 0;
        shipmentUnified.Main.shipperCallAppt = form.controls.shipper.value.callappt;
        shipmentUnified.Main.shipperCommercialResidential = form.controls.shipper.value.commercialresidential;
        shipmentUnified.Main.shipperHoursOpen = form.controls.shipper.value.hoursopen;
        shipmentUnified.Main.shipperHoursClose = form.controls.shipper.value.hoursclose;

        // Consignee Info:
        shipmentUnified.Main.Consignee_Name = form.controls.consignee.value.name;
        shipmentUnified.Main.consignee_name2 = form.controls.consignee.value.name2;
        shipmentUnified.Main.consignee_address = form.controls.consignee.value.address1;
        shipmentUnified.Main.consignee_address2 = form.controls.consignee.value.address2;
        shipmentUnified.Main.consignee_city = form.controls.consignee.value.city;
        shipmentUnified.Main.consignee_contact_email = form.controls.consignee.value.email;
        shipmentUnified.Main.consignee_contact_fax = form.controls.consignee.value.fax;
        shipmentUnified.Main.consignee_contact_name = form.controls.consignee.value.contact;
        shipmentUnified.Main.consignee_contact_phone = form.controls.consignee.value.phone;
        shipmentUnified.Main.consignee_country = form.controls.consignee.value.country;
        shipmentUnified.Main.consignee_state = form.controls.consignee.value.state;
        shipmentUnified.Main.consignee_zip = form.controls.consignee.value.zip;
        shipmentUnified.Main.Consignee = 0; // (form.controls.consignee.value.id) ? form.controls.consignee.value.id : 0;
        shipmentUnified.Main.consigneeCallAppt = form.controls.consignee.value.callappt;
        shipmentUnified.Main.consigneeCommercialResidential = form.controls.consignee.value.commercialresidential;
        shipmentUnified.Main.consigneeHoursOpen = form.controls.consignee.value.hoursopen;
        shipmentUnified.Main.consigneeHoursClose = form.controls.consignee.value.hoursclose;

        if (quote) {
            shipmentUnified.Quote = quote;
        }
        shipmentUnified.Quote.accl = [];
        shipmentUnified.Accessorial = [];
        for (acc of form.controls.accessorials.value) {
            shipmentUnified.Quote.accl.push(acc);
            shipAcc = new AccessorialForShipment();
            shipAcc.Customer = this.lastShipmentUnified.Main.Customer;
            shipAcc.ID = this.lastShipmentUnified.Main.ID;
            shipAcc.description = acc.code;
            shipAcc.buy_amount = 0;
            shipAcc.net_amount = 0;
            shipAcc.amount = this.getAccessorialAmountFromQuote(acc.code, quote, rate);
            chgId = this.getAccessorialChargeIdIfExists(acc.code);
            if (chgId) {
                shipAcc.Chg_ID = chgId;
            }
            shipAcc.value = acc.value ? acc.value : 0;
            shipAcc.displayName = acc.displayName;
            shipmentUnified.Accessorial.push(shipAcc);

            if (acc.code === HAZMAT_CODE) {
                isHazmatShipment = true;
            }
        }

        shipmentUnified.Quote.tsa = form.controls.tsaOnly.value;
        shipmentUnified.Main.equipmentType = form.controls.equipment.value.id;
        if (form.controls.deliveryDate.value.date) {
            shipmentUnified.Main.Delivery_Date = moment(form.controls.deliveryDate.value.date, DateConstants.DATE_DISPLAY_MM_DD_YYYY_with_slashes.format).format(DateConstants.DATE_DISPLAY_YYYY_MM_DD.format);
        } else if (form.controls.deliveryDate.value.date === '') {
            shipmentUnified.Main.Delivery_Date = '';
        }

        clearArray(shipmentUnified.Products);
        for (i=0; i<form.controls.productLines.value.length-1; i++) {
            productDetail = form.controls.productLines.value[i];
            product = this.transformProductDetailToProduct(productDetail, shipmentUnified.Main.Customer);
            shipmentUnified.Products.push(product);
        }

        for (dropValueField of dropValueFields) {
            labelField = _.find(form.controls.miscFields.value, (labelValue) => labelValue.label === dropValueField.label);
            if (labelField) {
                shipmentUnified.Main[dropValueField.field] = labelField.value;
            }
        }

        shipmentUnified.Main.Comments = form.controls.specialInstructions.value;
        shipmentUnified.Main.direction = form.controls.direction.value;
        shipmentUnified.Main.Payment_Term = form.controls.paymentTerms.value;

        shipmentUnified.PurchaseOrders = [];
        for (i=0; i<form.controls.purchaseOrderLines.value.length-1; i++) {
            purchaseOrderLine = _.cloneDeep(form.controls.purchaseOrderLines.value[i]);
            purchaseOrderLine.Packages = +purchaseOrderLine.Packages;
            purchaseOrderLine.Weight = +purchaseOrderLine.Weight;
            if (!purchaseOrderLine.poNumber) {
                purchaseOrderLine.poNumber = '';
            }
            if (!purchaseOrderLine.Packages) {
                purchaseOrderLine.Packages = 0;
            }
            if (!purchaseOrderLine.Weight) {
                purchaseOrderLine.Weight = 0;
            }
            shipmentUnified.PurchaseOrders.push(purchaseOrderLine);
        }

        shipmentUnified.Main.BillTo = form.controls.billTo.value.id;
        shipmentUnified.Main.billtoName = form.controls.billTo.value.name;
        shipmentUnified.Main.billtoZip = form.controls.billTo.value.zip;
        shipmentUnified.Main.billtoState = form.controls.billTo.value.state;
        shipmentUnified.Main.billtoCity = form.controls.billTo.value.city;
        shipmentUnified.Main.billtoAddress1 = form.controls.billTo.value.address1;
        shipmentUnified.Main.billtoAddress2 = form.controls.billTo.value.address2;
        shipmentUnified.Main.billtoName2 = form.controls.billTo.value.name2;

        shipmentUnified.Main.Pro_Number = form.controls.proNo.value;
        shipmentUnified.Main.trailerNo = form.controls.trailerNo.value;
        shipmentUnified.Main.sealNumber = form.controls.sealNo.value;
        shipmentUnified.Main.carrierQuoteNo = form.controls.carrierQuoteNo.value;
        shipmentUnified.Main.unitmeasurement = form.controls.unitOfMeasure.value;

        shipmentUnified.Main.emergency_contact = (isHazmatShipment) ? form.controls.emergencyContact.value : '';
        shipmentUnified.Main.emergency_phone = (isHazmatShipment) ? form.controls.emergencyPhone.value : '';

        return shipmentUnified;
    }

    public getShipmentUnifiedFormFromLastShipmentUnified() {
        const shipperObj = this.contactTransform(SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER);
        const consigneeObj = this.contactTransform(SearchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE);

        const controls = {
            bol: new UntypedFormControl(this.lastShipmentUnified.Main.BOL ? this.lastShipmentUnified.Main.BOL : '', {
                validators: [
                    Validators.required
                ]
            }),
            pickupDate: this.dateTransform(this.lastShipmentUnified.Main.Pickup_Date),
            shipper: new UntypedFormControl(shipperObj),
            consignee: new UntypedFormControl(consigneeObj),
            accessorials: new UntypedFormControl(this.accessorialsTransform(this.lastShipmentUnified)),
            tsaOnly: this.tsaTransform(this.lastShipmentUnified.Quote.tsa),
            equipment: this.equipmentTypeTransform(this.lastShipmentUnified.Main.equipmentType),
            deliveryDate: this.dateTransform(this.lastShipmentUnified.Main.Delivery_Date),
            productLines: new UntypedFormControl(this.productsTransform(this.lastShipmentUnified.Products)),
            placedProductLines: new UntypedFormControl(this.productsTransform(this.lastShipmentUnified.Products)),
            miscFields: new UntypedFormControl(this.miscFieldsTransform(this.lastShipmentUnified)),
            specialInstructions: this.lastShipmentUnified.Main.Comments,
            direction: this.lastShipmentUnified.Main.direction,
            paymentTerms: this.lastShipmentUnified.Main.Payment_Term,
            insurance: this.lastInsurance,
            purchaseOrderLines: new UntypedFormControl(this.lastShipmentUnified.PurchaseOrders ? _.cloneDeep(this.lastShipmentUnified.PurchaseOrders) : []),
            billTo: this.billToTransform(),
            proNo: this.lastShipmentUnified.Main.Pro_Number,
            trailerNo: this.lastShipmentUnified.Main.trailerNo,
            sealNo: this.lastShipmentUnified.Main.sealNumber,
            carrierQuoteNo: this.lastShipmentUnified.Main.carrierQuoteNo,
            unitOfMeasure: this.lastShipmentUnified.Main.unitmeasurement ? this.lastShipmentUnified.Main.unitmeasurement : Constants.UNIT_OF_MEASURE_Imperial,
            emergencyContact: this.lastShipmentUnified.Main.emergency_contact,
            emergencyPhone: this.lastShipmentUnified.Main.emergency_phone,
        }

        const shipmentUnifiedForm = this._fb.group(controls);
        shipmentUnifiedForm.setValidators([
            (fg: UntypedFormGroup) => {
                const errors: any = {};

                if (Object.keys(errors).length) {
                    return errors;
                }

                return null;
            },
        ]);
        return shipmentUnifiedForm;
    }

    public getLastShipmentUnified() {
        return this.lastShipmentUnified;
    }

    public updateForm(shipmentUnifiedForm: UntypedFormGroup) {
        shipmentUnifiedForm.controls.bol.setValue(this.lastShipmentUnified.Main.BOL ? this.lastShipmentUnified.Main.BOL : '');
        shipmentUnifiedForm.controls.pickupDate.setValue(this.dateTransform(this.lastShipmentUnified.Main.Pickup_Date));
        shipmentUnifiedForm.controls.shipper.setValue(this.contactTransform(SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER));
        shipmentUnifiedForm.controls.consignee.setValue(this.contactTransform(SearchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE));
        shipmentUnifiedForm.controls.accessorials.setValue(this.accessorialsTransform(this.lastShipmentUnified));
        shipmentUnifiedForm.controls.tsaOnly.setValue(this.tsaTransform(this.lastShipmentUnified.Quote.tsa));
        shipmentUnifiedForm.controls.equipment.setValue(this.equipmentTypeTransform(this.lastShipmentUnified.Main.equipmentType));
        shipmentUnifiedForm.controls.deliveryDate.setValue(this.dateTransform(this.lastShipmentUnified.Main.Delivery_Date));
        shipmentUnifiedForm.controls.productLines.setValue(this.productsTransform(this.lastShipmentUnified.Products));
        shipmentUnifiedForm.controls.miscFields.setValue(this.miscFieldsTransform(this.lastShipmentUnified));
        shipmentUnifiedForm.controls.specialInstructions.setValue(this.lastShipmentUnified.Main.Comments ? this.lastShipmentUnified.Main.Comments : '');
        shipmentUnifiedForm.controls.direction.setValue(this.lastShipmentUnified.Main.direction ? this.lastShipmentUnified.Main.direction : '');
        shipmentUnifiedForm.controls.paymentTerms.setValue(this.lastShipmentUnified.Main.Payment_Term ? this.lastShipmentUnified.Main.Payment_Term : '');
        shipmentUnifiedForm.controls.insurance.setValue(this.lastInsurance);
        shipmentUnifiedForm.controls.purchaseOrderLines.setValue(this.lastShipmentUnified.PurchaseOrders ? this.lastShipmentUnified.PurchaseOrders : []);
        shipmentUnifiedForm.controls.billTo.setValue(this.billToTransform());
        shipmentUnifiedForm.controls.proNo.setValue(this.lastShipmentUnified.Main.Pro_Number ? this.lastShipmentUnified.Main.Pro_Number : '');
        shipmentUnifiedForm.controls.trailerNo.setValue(this.lastShipmentUnified.Main.trailerNo ? this.lastShipmentUnified.Main.trailerNo : '');
        shipmentUnifiedForm.controls.sealNo.setValue(this.lastShipmentUnified.Main.sealNumber ? this.lastShipmentUnified.Main.sealNumber : '');
        shipmentUnifiedForm.controls.carrierQuoteNo.setValue(this.lastShipmentUnified.Main.carrierQuoteNo ? this.lastShipmentUnified.Main.carrierQuoteNo : '');
        shipmentUnifiedForm.controls.unitOfMeasure.setValue(this.lastShipmentUnified.Main.unitmeasurement ? this.lastShipmentUnified.Main.unitmeasurement : Constants.UNIT_OF_MEASURE_Imperial);
        shipmentUnifiedForm.controls.emergencyContact.setValue(this.lastShipmentUnified.Main.emergency_contact ? this.lastShipmentUnified.Main.emergency_contact : '');
        shipmentUnifiedForm.controls.emergencyPhone.setValue(this.lastShipmentUnified.Main.emergency_phone ? this.lastShipmentUnified.Main.emergency_phone : '');
    }

    public clearLastShipmentUnified() {
        this.lastShipmentUnified = new ShipmentUnified();
    }

    public updateLastShipmentUnified(shipmentUnified: ShipmentUnified) {
        this.lastShipmentUnified = shipmentUnified;
    }

    public getSelectedCarrier() {
        if (this.lastShipmentUnified?.Quote?.selectedRateId && this.lastShipmentUnified?.Quote?.carriers) {
            return this.lastShipmentUnified.Quote.carriers.find((c: QuotedRate) => c.rateId === this.lastShipmentUnified.Quote.selectedRateId);
        }
    }

    public loadCosts(quotedRate: QuotedRate) {
        const result = {
            extra: 0,
            fuel: 0,
            lineHaul: 0,
            total: 0,
            currency: ''
        };

        result.total = quotedRate.finalcharge;
        result.currency = quotedRate.currency;
        result.lineHaul = quotedRate.initialcharge;
        result.fuel = quotedRate.fsc;
        result.extra = result.total - result.lineHaul - result.fuel;

        return result;
    }

    public getQuoteFromForm(form: UntypedFormGroup) {
        const quoteRequest = new QuotesRequest();
        let productLine: ProductDetail;
        let productLines;
        let accessorial: AccessorialForQuote;

        quoteRequest.uid = this._userHelper.getUserId();
        quoteRequest.fromzip = form.controls.shipper.value.zip;
        quoteRequest.fromcountry = form.controls.shipper.value.country;
        quoteRequest.originType = form.controls.shipper.value.commercialresidential;
        quoteRequest.originCallAppt = !!form.controls.shipper.value.callappt;
        quoteRequest.tozip = form.controls.consignee.value.zip;
        quoteRequest.tocountry = form.controls.consignee.value.country;
        quoteRequest.destinationType = form.controls.consignee.value.commercialresidential;
        quoteRequest.destinationCallAppt = !!form.controls.consignee.value.callappt;
        if (form.controls.pickupDate.value.date) {
            quoteRequest.shipdate = moment(form.controls.pickupDate.value.date, DateConstants.DATE_DISPLAY_MM_DD_YYYY_with_slashes.format).format(DateConstants.DATE_DISPLAY_YYYY_MM_DD.format);
        }
        quoteRequest.tsa = form.controls.tsaOnly.value;
        quoteRequest.cube = 0;
        productLines = form.controls.productLines.value.slice(0, form.controls.productLines.value.length-1);

        // Mutating the product line values for Units and Qty, easiest to do here:
        // This sets up default Units if both are empty.
        for (productLine of productLines) {
            if (!productLine.Qty && !productLine.Units) {
                productLine.Units = 1;
                productLine.Qty = 0;
            }
        }

        // Copying the product line so further changes are done on a copy and don't mutate:
        quoteRequest.detail = _.cloneDeep(productLines);
        for (productLine of quoteRequest.detail) {

            if (!productLine.Cube) {
                productLine.Cube = 0;
            } else {
                quoteRequest.cube += +productLine.Cube;
            }
            if (!productLine.class) {
                productLine.class = 0;
            }
            if (!productLine.Qty) {
                productLine.Qty = 0;
            }
            if (!productLine.Units) {
                productLine.Units = 0;
            }
            if (!productLine.Length) {
                productLine.Length = 0;
            }
            if (!productLine.Width) {
                productLine.Width = 0;
            }
            if (!productLine.Height) {
                productLine.Height = 0;
            }
        }
        quoteRequest.accessorials = {};
        for (accessorial of form.controls.accessorials.value) {
            if (accessorial.valueRequired) {
                quoteRequest.accessorials[accessorial.code] = accessorial.value;
            } else {
                quoteRequest.accessorials[accessorial.code] = 1;
            }
        }
        quoteRequest.unitmeasurement = form.controls.unitOfMeasure.value;
        quoteRequest.direction = form.controls.direction.value;
        quoteRequest.quote_name = '';

        quoteRequest.Shipper = new Shipper();
        quoteRequest.Shipper.shipper_address = form.controls.shipper.value.address1;
        quoteRequest.Shipper.shipper_address2 = form.controls.shipper.value.address2;
        quoteRequest.Shipper.shipper_city = form.controls.shipper.value.city;
        quoteRequest.Shipper.shipper_contact_email = form.controls.shipper.value.email;
        quoteRequest.Shipper.shipper_contact_fax = form.controls.shipper.value.fax;
        quoteRequest.Shipper.shipper_contact_name = form.controls.shipper.value.contact;
        quoteRequest.Shipper.shipper_contact_phone = form.controls.shipper.value.phone;
        quoteRequest.Shipper.shipper_country = form.controls.shipper.value.country;
        quoteRequest.Shipper.shipper_name2 = form.controls.shipper.value.name2;
        quoteRequest.Shipper.shipper_state = form.controls.shipper.value.state;
        quoteRequest.Shipper.shipper_zip = form.controls.shipper.value.zip;
        quoteRequest.Shipper.ID = form.controls.shipper.value.id;
        quoteRequest.Shipper.shipperCallAppt = form.controls.shipper.value.callappt;
        quoteRequest.Shipper.shipperCommercialResidential = form.controls.shipper.value.commercialresidential;
        quoteRequest.Shipper.shipperHoursOpen = form.controls.shipper.value.hoursopen;
        quoteRequest.Shipper.shipperHoursClose = form.controls.shipper.value.hoursclose;
        quoteRequest.Shipper.contactsId = form.controls.shipper.value.contactsid;
        quoteRequest.Shipper.BOL = form.controls.bol.value;
        quoteRequest.Shipper.Pickup_date = quoteRequest.shipdate;

        quoteRequest.Consignee = new Consignee();
        quoteRequest.Consignee.consignee_address = form.controls.consignee.value.address1;
        quoteRequest.Consignee.consignee_address2 = form.controls.consignee.value.address2;
        quoteRequest.Consignee.consignee_city = form.controls.consignee.value.city;
        quoteRequest.Consignee.consignee_contact_email = form.controls.consignee.value.email;
        quoteRequest.Consignee.consignee_contact_fax = form.controls.consignee.value.fax;
        quoteRequest.Consignee.consignee_contact_name = form.controls.consignee.value.contact;
        quoteRequest.Consignee.consignee_contact_phone = form.controls.consignee.value.phone;
        quoteRequest.Consignee.consignee_country = form.controls.consignee.value.country;
        quoteRequest.Consignee.consignee_name2 = form.controls.consignee.value.name2;
        quoteRequest.Consignee.consignee_state = form.controls.consignee.value.state;
        quoteRequest.Consignee.consignee_zip = form.controls.consignee.value.zip;
        quoteRequest.Consignee.consigneeCallAppt = form.controls.consignee.value.callappt;
        quoteRequest.Consignee.consigneeCommercialResidential = form.controls.consignee.value.commercialresidential;
        quoteRequest.Consignee.consigneeHoursOpen = form.controls.consignee.value.hoursopen;
        quoteRequest.Consignee.consigneeHoursClose = form.controls.consignee.value.hoursclose;
        quoteRequest.Consignee.contactsId = form.controls.consignee.value.contactsid;
        if (form.controls.deliveryDate.value.date) {
            quoteRequest.Consignee.Delivery_Date = moment(form.controls.deliveryDate.value.date, DateConstants.DATE_DISPLAY_MM_DD_YYYY_with_slashes.format).format(DateConstants.DATE_DISPLAY_YYYY_MM_DD.format);
        }

        return quoteRequest;
    }

    public getRates(quoteRequest: QuotesRequest) {
        const quoteCreateReq = new HttpRequest(REQUEST_TYPE_POST, `Rate`, quoteRequest);
        return this._api.callApiService<ResponseDTO<QuotesResponse>>(quoteCreateReq).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Get Rates` });
                    throw err;
                }
            )
        );
    }

    public getQuote(quoteId: number) {
        const req = new HttpRequest(REQUEST_TYPE_GET, `savedQuote/${quoteId}`);
        return this._api.callApiService<ResponseDTO<QuotesResponse>>(req).pipe(
            map(response => {
                if (response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Get Quote` });
                    throw err;
                }
            )
        );
    }

    public placeShipment(customerId, shipmentId, placeShipmentFormValues: { bolEmail: string, bolName: string, bolPhone: string }) {
        const placeShipmentReqBody = {
            Customer: customerId,
            ID: shipmentId,
            BOLEmail: placeShipmentFormValues.bolEmail,
            BOLPhone: placeShipmentFormValues.bolPhone,
            BOLName: placeShipmentFormValues.bolName
        };
        const placeShipmentReq = new HttpRequest(REQUEST_TYPE_POST, `Shipment/Place`, placeShipmentReqBody);

        return this._api.callApiService<ResponseDTO<any>>(placeShipmentReq).pipe(
            map(response => {
                if (response && response.isValid) {
                    return true;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Place Shipment` });
                    throw err;
                }
            )
        );
    }

    public validateShipment(customerId, shipmentId) {
        const validateShipmentReqBody = {
            Customer: customerId,
            ID: shipmentId
        }

        const validateShipmentReq = new HttpRequest(REQUEST_TYPE_POST, 'Shipment/Place/Validate', validateShipmentReqBody);

        return this._api.callApiService<ResponseDTO<any>>(validateShipmentReq).pipe(
            map(response => {
                if (response && response.isValid) {
                    return true;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Unable to Validate Shipment` });
                    throw err;
                }
            )
        );
    }

    public updateQuoteSelectedRate({ quoteId, rateId, quoteName }: { quoteId: number, rateId: number, quoteName: string }) {
        const req = new HttpRequest(REQUEST_TYPE_PUT, `savedQuote/${quoteId}`, {
            selectedRateId: rateId,
            quote_name: quoteName,
        });
        return this._api.callApiService<ResponseDTO<QuotesResponse>>(req).pipe(
            map(response => {
                if (response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Update Quote Selected Rate` });
                    throw err;
                }
            )

        );
    }

    public createQuote(saveQuoteRequest: QuoteSaveRequest) {
        const req = new HttpRequest(REQUEST_TYPE_POST, `savedQuote`, saveQuoteRequest);
        return this._api.callApiService<ResponseDTO<QuoteSaveResponse>>(req).pipe(
            map(response => {
                if (response.isValid) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Save Quote` });
                    throw err;
                }
            )
        );
    }

    public updateShipmentUnified(shipment: ShipmentUnified) {
        const customer = shipment.Main.Customer;
        const shipId = shipment.Main.ID;
        const req = new HttpRequest(REQUEST_TYPE_PUT, `shipment/main/Test/${customer}/${shipId}`, shipment);

        const apiObservables = this.getDeleteProductObservables(shipment);
        apiObservables.push(...this.getDeleteProductOrderObservables(shipment));

        apiObservables.push(this._api.callApiService<ResponseDTO<ShipmentUnified>>(req, true).pipe(
            map((response) => {
                if (response && response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Update Shipment` });
                    throw err;
                }
            )
        ));

        return from(apiObservables).pipe(
            concatMap(request => request),
            last()
        ) as Observable<ShipmentUnified>;
    }

    public createShipmentUnified(shipment: ShipmentUnified) {
        const req = new HttpRequest(REQUEST_TYPE_POST, `shipment/main/Test`, shipment);
        return this._api.callApiService<ResponseDTO<ShipmentUnified>>(req, true).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Create Shipment` });
                    throw err;
                }
            )
        );
    }

    public shipmentIsPlaced(shipmentUnified: ShipmentUnified) {
        return (shipmentUnified?.Main.status !== undefined) && (shipmentUnified?.Main.status !== 0);
    }

    public lastShipmentIsPlaced() {
        return this.shipmentIsPlaced(this.lastShipmentUnified);
    }

    public dateTransform(dateString) {
        if (dateString) {
            return { date: moment(dateString).format(DateConstants.UNIFORM_DATE_DISPLAY.format) };
        } else {
            return { date: '' };
        }
    }

    public accessorialsTransform(shipmentUnified: ShipmentUnified) {
        const accl = shipmentUnified.Quote.accl;
        let accessorial: AccessorialForQuote;
        const accessorials = [];
        let quoteAccessorial: AccessorialForQuote;
        let matchedAccessorial: AccessorialForQuote;
        let shipmentAccl: AccessorialForShipment;

        if (accl) {
            for (accessorial of accl) {
                quoteAccessorial = new AccessorialForQuote();
                quoteAccessorial.displayName = accessorial.displayName;
                quoteAccessorial.code = accessorial.code;
                if (accessorial.value !== undefined) {
                    matchedAccessorial = _.find(this._commonDataService.availableAccessorials, availAccl => availAccl.code === accessorial.code);
                    if (matchedAccessorial) {
                        quoteAccessorial.valueRequired = matchedAccessorial.valueRequired;
                    }
                    quoteAccessorial.value = accessorial.value;
                }
                accessorials.push(quoteAccessorial);
            }
        }

        if (shipmentUnified.Accessorial) {
            for (shipmentAccl of shipmentUnified.Accessorial) {
                if (!_.find(accessorials, (acc) => acc.code === shipmentAccl.description)) {
                    quoteAccessorial = new AccessorialForQuote();
                    quoteAccessorial.displayName = shipmentAccl.displayName;
                    quoteAccessorial.code = shipmentAccl.description;
                    if (shipmentAccl.value !== undefined) {
                        matchedAccessorial = _.find(this._commonDataService.availableAccessorials, availAccl => availAccl.code === shipmentAccl.description);
                        if (matchedAccessorial) {
                            quoteAccessorial.valueRequired = matchedAccessorial.valueRequired;
                        }
                        quoteAccessorial.value = shipmentAccl.value;
                    }

                    accessorials.push(quoteAccessorial);
                }
            }
        }

        return accessorials;
    }

    public equipmentTypeTransform(equipment) {
        let equipmentType = new EquipmentType();
        if (equipment) {
            equipmentType = _.find(this._commonDataService.equipmentTypes, (eq: EquipmentType) => eq.id === +equipment);
        } else {
            equipmentType.id = 0;
        }
        return equipmentType;
    }

    public tsaTransform(tsa: any) {
        return (tsa === 1) || ((typeof tsa === 'boolean') && (tsa === true)) || ((typeof tsa === 'string') && ((tsa.toLocaleLowerCase() === 'true') || (tsa.toLocaleLowerCase() === 't')));
    }

    public productsTransform(products: Array<Product>) {
        const productLines = new Array<ProductDetail>();
        let productDetail: ProductDetail;
        let product: Product;

        for (product of products) {
            productDetail = new ProductDetail();
            if (product.ID !== undefined) {
                productDetail.ID = product.ID;
            }
            productDetail.Product = product.Product;
            productDetail.productId = product.productId;
            productDetail.Detail_ID = product.Detail_ID;
            productDetail.name = product.name;
            productDetail.description = product.description;
            productDetail.Weight = product.Weight;
            productDetail.Cube = product.Cube;
            productDetail.Qty = product.Qty;
            productDetail.Units = product.Units;
            productDetail.Pkg_Type = product.Pkg_Type;
            productDetail.Unit_Type = product.Unit_Type;
            productDetail.Units = product.Units;
            productDetail.stackable = product.stackable;
            productDetail.class = product.class;
            productDetail.description2 = product.description2;
            productDetail.hazmat = product.hazmat;
            productDetail.hazmat_class = product.hazmat_class;
            productDetail.hazmat_number = product.hazmat_number;
            productDetail.hazmat_packingGroup = product.hazmat_packingGroup;
            productDetail.hazmat_subClass = product.hazmat_subClass;
            productDetail.Height = (product.Height > 0) ? product.Height : undefined;
            productDetail.Length = (product.Length > 0) ? product.Length : undefined;
            productDetail.Width = (product.Width > 0) ? product.Width : undefined;
            productDetail.loadingMeters = product.loadingMeters;
            productDetail.nmfc = product.nmfc;

            productLines.push(productDetail);
        }
        return productLines;
    }

    public transformQuoteToShipmentUnified(quote: QuotesResponse, shipmentUnified: ShipmentUnified) {
        let productDetail: ProductDetail;
        let product: Product;
        shipmentUnified.Main.unitmeasurement = quote.unitmeasurement;
        shipmentUnified.Quote.accl = quote.accl ? quote.accl : [];
        shipmentUnified.Products = [];
        for (productDetail of quote.detail) {
            product = this.transformProductDetailToProduct(productDetail, shipmentUnified.Main.Customer);
            shipmentUnified.Products.push(product);
        }
        shipmentUnified.Main.equipmentType = quote.equipmentType ? quote.equipmentType : 0;
        shipmentUnified.Quote.tsa = !!quote.tsa;
        shipmentUnified.Main.direction = quote.direction ? quote.direction : 'inbound';
        shipmentUnified.Main.shipperCallAppt = quote.Shipper.shipperCallAppt ? quote.Shipper.shipperCallAppt : 0;
        shipmentUnified.Main.shipperHoursClose = quote.Shipper.shipperHoursClose ? quote.Shipper.shipperHoursClose : '00:00:00';
        shipmentUnified.Main.shipper_contact_name = quote.Shipper.shipper_contact_name ? quote.Shipper.shipper_contact_name : '';
        shipmentUnified.Main.shipperHoursOpen = quote.Shipper.shipperHoursOpen ? quote.Shipper.shipperHoursOpen : '00:00:00';
        shipmentUnified.Main.Pickup_Date = quote.Shipper.Pickup_date ? quote.Shipper.Pickup_date : shipmentUnified.Main.Pickup_Date;
        shipmentUnified.Main.shipper_address = quote.Shipper.shipper_address ? quote.Shipper.shipper_address : '';
        shipmentUnified.Main.shipper_address2 = quote.Shipper.shipper_address2 ? quote.Shipper.shipper_address2 : '';
        shipmentUnified.Main.shipper_contact_email = quote.Shipper.shipper_contact_email ? quote.Shipper.shipper_contact_email : '';
        shipmentUnified.Main.shipper_state = quote.Shipper.shipper_state ? quote.Shipper.shipper_state : '';
        shipmentUnified.Main.shipper_zip = quote.Shipper.shipper_zip ? quote.Shipper.shipper_zip : '';
        shipmentUnified.Main.shipper_contact_phone = quote.Shipper.shipper_contact_phone ? quote.Shipper.shipper_contact_phone : '';
        shipmentUnified.Main.shipper_city = quote.Shipper.shipper_city ? quote.Shipper.shipper_city : '';
        shipmentUnified.Main.shipper_name2 = quote.Shipper.shipper_name2 ? quote.Shipper.shipper_name2 : '';
        shipmentUnified.Main.Shipper_Name = quote.Shipper.Shipper_Name ? quote.Shipper.Shipper_Name : '';
        shipmentUnified.Main.shipper_contact_fax = quote.Shipper.shipper_contact_fax ? quote.Shipper.shipper_contact_fax : '';
        shipmentUnified.Main.shipper_country = quote.Shipper.shipper_country ? quote.Shipper.shipper_country : '';
        shipmentUnified.Main.shipperCommercialResidential = quote.Shipper.shipperCommercialResidential ? quote.Shipper.shipperCommercialResidential : shipmentUnified.Main.shipperCommercialResidential;
        shipmentUnified.Main.BOL = quote.Shipper.BOL ? quote.Shipper.BOL : '';

        shipmentUnified.Main.consigneeCallAppt = quote.Consignee.consigneeCallAppt ? quote.Consignee.consigneeCallAppt : 0;
        shipmentUnified.Main.consigneeHoursClose = quote.Consignee.consigneeHoursClose ? quote.Consignee.consigneeHoursClose : '00:00:00';
        shipmentUnified.Main.consignee_contact_name = quote.Consignee.consignee_contact_name ? quote.Consignee.consignee_contact_name : '';
        shipmentUnified.Main.consigneeHoursOpen = quote.Consignee.consigneeHoursOpen ? quote.Consignee.consigneeHoursOpen : '00:00:00';
        shipmentUnified.Main.Delivery_Date = quote.Consignee.Pickup_date ? quote.Consignee.Delivery_Date : shipmentUnified.Main.Delivery_Date;
        shipmentUnified.Main.consignee_address = quote.Consignee.consignee_address ? quote.Consignee.consignee_address : '';
        shipmentUnified.Main.consignee_address2 = quote.Consignee.consignee_address2 ? quote.Consignee.consignee_address2 : '';
        shipmentUnified.Main.consignee_contact_email = quote.Consignee.consignee_contact_email ? quote.Consignee.consignee_contact_email : '';
        shipmentUnified.Main.consignee_state = quote.Consignee.consignee_state ? quote.Consignee.consignee_state : '';
        shipmentUnified.Main.consignee_zip = quote.Consignee.consignee_zip ? quote.Consignee.consignee_zip : '';
        shipmentUnified.Main.consignee_contact_phone = quote.Consignee.consignee_contact_phone ? quote.Consignee.consignee_contact_phone : '';
        shipmentUnified.Main.consignee_city = quote.Consignee.consignee_city ? quote.Consignee.consignee_city : '';
        shipmentUnified.Main.consignee_name2 = quote.Consignee.consignee_name2 ? quote.Consignee.consignee_name2 : '';
        shipmentUnified.Main.Consignee_Name = quote.Consignee.Consignee_Name ? quote.Consignee.Consignee_Name : '';
        shipmentUnified.Main.consignee_contact_fax = quote.Consignee.consignee_contact_fax ? quote.Consignee.consignee_contact_fax : '';
        shipmentUnified.Main.consignee_country = quote.Consignee.consignee_country ? quote.Consignee.consignee_country : '';
        shipmentUnified.Main.consigneeCommercialResidential = quote.Consignee.consigneeCommercialResidential ? quote.Consignee.consigneeCommercialResidential : shipmentUnified.Main.consigneeCommercialResidential;
    }

    public transformQuoteTemplateToShipmentUnified(template: any, shipmentUnified: ShipmentUnified) {
        let newProd: Product;
        let prod;
        shipmentUnified.Main.shipper_zip = template.fromzip;
        shipmentUnified.Main.shipper_country = template.fromcountry;
        shipmentUnified.Main.shipperCommercialResidential = template.originType;
        shipmentUnified.Main.shipperCallAppt = template.originCallAppt;
        shipmentUnified.Main.shipper_state = template.Shipper.shipper_state;
        shipmentUnified.Main.shipper_city = template.Shipper.shipper_city;
        shipmentUnified.Main.consignee_zip = template.tozip;
        shipmentUnified.Main.consignee_country = template.tocountry;
        shipmentUnified.Main.consignee_state = template.Consignee.consignee_state;
        shipmentUnified.Main.consignee_city = template.Consignee.consignee_city;
        shipmentUnified.Main.consigneeCommercialResidential = template.destinationType;
        shipmentUnified.Main.consigneeCallAppt = template.destinationCallAppt;
        shipmentUnified.Main.direction = template.direction;
        shipmentUnified.Main.unitmeasurement = template.unitmeasurement;
        shipmentUnified.Quote.tsa = template.tsa;
        shipmentUnified.Quote.cube = template.cube;
        // eslint-disable-next-line no-unused-expressions
        (template.equipmentType) ? shipmentUnified.Quote.equipmentType = template.equipmentType : null;
        shipmentUnified.Quote.accl = [];
        Object.keys(template.accessorials).map((p) => {
            const acc4quote = {
                code: p,
                displayName: p,
                value: template.accessorials[p],
            };
            shipmentUnified.Quote.accl.push(acc4quote);
        });
        shipmentUnified.Products = [];
        for (prod of template.detail) {
            newProd = _.cloneDeep(prod);
            shipmentUnified.Products.push(newProd);
        }
    }

    public applyDefaultsToShipmentUnified(shipmentUnified) {
        const userProfile = this._userHelper.getUserProfile();
        const userSettings = this._userHelper.getUserSettings();
        const contactObservables = [];

        if (userProfile.shipmentValue) {
            this.defaultShipmentValue = userProfile.shipmentValue;
        }

        shipmentUnified.Main.Pickup_Date = moment(new Date(), DateConstants.DATE_DISPLAY_MM_DD_YYYY_with_slashes.format).format(DateConstants.DATE_DISPLAY_YYYY_MM_DD.format);

        if (userSettings?.defaults[0]?.defaultBillTo) {
            shipmentUnified.Main.BillTo = parseInt(userSettings.defaults[0].defaultBillTo, 10);
        }
        if (userProfile.paymentTerms) {
            shipmentUnified.Main.Payment_Term = userProfile.paymentTerms;
        }
        if (userProfile.defaultDestType) {
            shipmentUnified.Main.consigneeCommercialResidential = userProfile.defaultDestType;
        }
        if (userProfile.defaultOriginType) {
            shipmentUnified.Main.shipperCommercialResidential = userProfile.defaultOriginType;
        }
        switch (userProfile.defaultDirection) {
            case 'import':
            case 'inbound':
                shipmentUnified.Main.direction = 'inbound';
                break;
            case 'export':
            case 'outbound':
                shipmentUnified.Main.direction = 'outbound';
                break;
        }
        if (userProfile.defaultTSA) {
            shipmentUnified.Quote.tsa = true;
        }
        if (userProfile.defaultUoM) {
            shipmentUnified.Main.unitmeasurement = userProfile.defaultUoM;
        }
        if (userProfile.defaultDestZip) {
            shipmentUnified.Main.consignee_zip = userProfile.defaultDestZip;
            contactObservables.push(this.updateShipmentWithZipSearch(shipmentUnified, SearchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE));
        }
        if (userProfile.defaultOriginZip) {
            shipmentUnified.Main.shipper_zip = userProfile.defaultOriginZip;
            contactObservables.push(this.updateShipmentWithZipSearch(shipmentUnified, SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER));
        }
        if (userProfile.defaultShipper && +userProfile.defaultShipper) {
            contactObservables.push(this.getContactById(+userProfile.defaultShipper).pipe(
                map(contact => {
                    this.contactMasterDataToShipmentUnified(shipmentUnified, SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER, contact);
                }),
                catchError(e => of(e))
            ));
        }
        if (userProfile.defaultConsignee && +userProfile.defaultConsignee) {
            contactObservables.push(this.getContactById(+userProfile.defaultConsignee).pipe(
                map(contact => {
                    this.contactMasterDataToShipmentUnified(shipmentUnified, SearchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE, contact);
                }),
                catchError(e => of(e))
            ));
        }
        contactObservables.push(this.getBolNumber().pipe(
            map(bolNumber => {
                this.lastShipmentUnified.Main.BOL = bolNumber;
            })
        ))
        return contactObservables;
    }

    public getShipmentNotes(customerId: string, shipmentId: number) {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Shipment/Notes/${customerId}/${shipmentId}`);
        return this._api.callApiService<ResponseDTO<Array<ShipmentNote>>>(req).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: `Shipment Notes`
                });
                throw err;
            })
        );
    }

    public copyShipment(customer, shipmentId) {
        const copyShipmentReqBody = {
            customer,
            id: shipmentId
        }

        const req = new HttpRequest(REQUEST_TYPE_POST, `shipment/main/Copy`, copyShipmentReqBody);
        return this._api.callApiService<ResponseDTO<{ customer: string, id: number }>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Copy Shipment' });
                throw err;
            })
        );
    }

    public navigateViaShipment(customer, id) {
        this._router.navigate(['shipments', customer, id]);
    }

    public navigateViaQuote(id) {
        this.getShipmentUnifiedByPath(PATH_NEW, this._userHelper.getUserCustomer()).pipe(
            switchMap(() => this.getQuote(id)),
            map((quote) => {
                this.lastShipmentUnified.Quote = quote;
                this._router.navigate(['shipments', this._userHelper.getUserCustomer(), PATH_QUOTE])
            })
        ).subscribe();
    }

    public updateShipmentUnifiedWithQuote(shipmentUnified: ShipmentUnified, quote: QuotesResponse) {
        const shipment = shipmentUnified.Main;
        const selectedQuoteRate = quote.carriers.find((c: QuotedRate) => c.rateId === quote.selectedRateId);
        shipment.quoteId = quote.quoteId;

        shipment.Pickup_Date = quote.shipdate;

        shipment.Carrier = selectedQuoteRate.scac;
        shipment.Carrier_Name = selectedQuoteRate.name;

        shipment.direction = quote.direction;
        shipment.shipperCommercialResidential = quote.originType;
        shipment.shipper_zip = quote.origin.zipCode;
        shipment.shipper_country = quote.origin.country;
        shipment.shipperCallAppt = quote.originCallAppt ? 1 : 0;

        shipment.orig_terminal = selectedQuoteRate.originterminal.code;
        shipment.otermName = selectedQuoteRate.originterminal.name;
        shipment.otermAddress = selectedQuoteRate.originterminal.address;
        shipment.otermAddress2 = selectedQuoteRate.originterminal.address1;
        shipment.otermCity = selectedQuoteRate.originterminal.city;
        shipment.otermState = selectedQuoteRate.originterminal.state;
        shipment.otermZip = selectedQuoteRate.originterminal.zip;
        shipment.otermPhone = selectedQuoteRate.originterminal.phone;
        shipment.otermFax = selectedQuoteRate.originterminal.fax;
        shipment.oterm800 = selectedQuoteRate.originterminal.phone800;

        // ===============================================================
        // price/amount related data
        // ===============================================================
        shipment.rated_amt = selectedQuoteRate.finalcharge;
        shipment.ratedClass = selectedQuoteRate.ratedClass;
        shipment.FSC = selectedQuoteRate.fsc;
        shipment.currency = selectedQuoteRate.currency;

        // ===============================================================
        // hidden carrier related data
        // ===============================================================
        shipment.Service = selectedQuoteRate.service.number;
        shipment.service_name = selectedQuoteRate.service.name;
        shipment.estimatedTransitTime = selectedQuoteRate.days;
        shipment.guarantee = selectedQuoteRate.guarantee ? 1 : 0;

        shipment.dest_terminal = selectedQuoteRate.destinationterminal.code;
        shipment.dtermName = selectedQuoteRate.destinationterminal.name;
        shipment.dtermAddress = selectedQuoteRate.destinationterminal.address;
        shipment.dtermAddress2 = selectedQuoteRate.destinationterminal.address1;
        shipment.dtermCity = selectedQuoteRate.destinationterminal.city;
        shipment.dtermState = selectedQuoteRate.destinationterminal.state;
        shipment.dtermZip = selectedQuoteRate.destinationterminal.zip;
        shipment.dtermPhone = selectedQuoteRate.destinationterminal.phone;
        shipment.dtermFax = selectedQuoteRate.destinationterminal.fax;
        shipment.dterm800 = selectedQuoteRate.destinationterminal.phone800;

        shipment.consignee_zip = quote.destination.zipCode;
        shipment.consignee_country = quote.destination.country;
        shipment.consigneeCommercialResidential = quote.destinationType;
        shipment.consigneeCallAppt = quote.destinationCallAppt ? 1 : 0;
    }

    private getDeleteProductOrderObservables(shipment: ShipmentUnified) {
        const deleteObservables = [];
        let po: PurchaseOrder;

        if (this.lastShipmentUnified.PurchaseOrders?.length) {
            for (po of this.lastShipmentUnified.PurchaseOrders) {
                if (!_.find(shipment.PurchaseOrders, (p: PurchaseOrder) => p.poId === po.poId)) {
                    deleteObservables.push(this.deletePurchaseOder(po));
                }
            }
        }

        return deleteObservables;
    }

    private deletePurchaseOder(po: PurchaseOrder) {
        const customerId = po.Customer;
        const shipmentId = po.ID;
        const poId = po.poId;
        const removePurchaseOrderReq = new HttpRequest(REQUEST_TYPE_DELETE, `Shipment/PO/${customerId}/${shipmentId}/${poId}`);

        return this._api.callApiService<ResponseDTO<any>>(removePurchaseOrderReq).pipe(
            map(response => {
                if (!response.isValid) {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Error removing Purchase Order for Customer ${customerId}, Shipment: ${shipmentId}, and Purchase Order: ${poId}` });
                    throw err;
                }
            )
        );
    }

    private getDeleteProductObservables(shipment: ShipmentUnified) {
        const deleteObservables = [];
        let product: Product;

        if (this.lastShipmentUnified.Products?.length) {
            for (product of this.lastShipmentUnified.Products) {
                if (!_.find(shipment.Products, (p: Product) => p.Detail_ID === product.Detail_ID)) {
                    deleteObservables.push(this.deleteProduct(product));
                }
            }
        }

        return deleteObservables;
    }

    private deleteProduct(productToRemove: Product) {
        const customerId = productToRemove.Customer;
        const shipmentId = productToRemove.ID;
        const detailId = productToRemove.Detail_ID;
        const removeProductReq = new HttpRequest(REQUEST_TYPE_DELETE, `Shipment/Product/${customerId}/${shipmentId}/${detailId}`);
        return this._api.callApiService<ResponseDTO<any>>(removeProductReq).pipe(
            map(response => {
                if (!response.isValid) {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Error removing Product for Customer ${customerId}, Shipment: ${shipmentId}, and detailId: ${detailId}` });
                    throw err;
                }
            )
        );
    }

    private getAccessorialChargeIdIfExists(code) {
        if (this.lastShipmentUnified.Accessorial) {
            const acc = _.find(this.lastShipmentUnified.Accessorial, (shipAcc: AccessorialForShipment) => shipAcc.description === code);
            if (acc) {
                return acc.Chg_ID;
            }
        }
    }

    private getAccessorialAmountFromQuote(code, quote: QuotesResponse, selectedRate: QuotedRate) {
        if (!quote || !(selectedRate?.rateId)) {
            return 0;
        }

        const rate: QuotedRate = _.find(quote.carriers, (carrier: QuotedRate) => carrier.rateId === selectedRate.rateId);
        if (rate) {
            const acc = _.find(rate.asc.charges, (charge) => charge.code === code);
            if (acc) {
                return acc.amount ? acc.amount : 0;
            }
        }

        return 0;
    }

    private contactMasterDataToShipmentUnified(shipmentUnified: ShipmentUnified, contactType: string, cmd: ContactMasterData) {
        if ((contactType === SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER) && cmd) {
            shipmentUnified.Main.Shipper_Name = cmd.Name;
            shipmentUnified.Main.shipper_name2 = cmd.Name2;
            shipmentUnified.Main.shipper_address = cmd.Address1;
            shipmentUnified.Main.shipper_address2 = cmd.Address2;
            shipmentUnified.Main.shipper_city = cmd.City;
            shipmentUnified.Main.shipper_contact_email = cmd.Email;
            shipmentUnified.Main.shipper_contact_fax = cmd.Fax;
            shipmentUnified.Main.shipper_contact_name = cmd.Contact;
            shipmentUnified.Main.shipper_contact_phone = cmd.Phone;
            shipmentUnified.Main.shipper_country = cmd.country;
            shipmentUnified.Main.shipper_state = cmd.State;
            shipmentUnified.Main.shipper_zip = cmd.Zip;
            shipmentUnified.Main.Shipper = cmd.ID;
            shipmentUnified.Main.shipperCallAppt = cmd.callAppt;
            shipmentUnified.Main.shipperCommercialResidential = cmd.commercialResidential;
            shipmentUnified.Main.shipperHoursClose = cmd.hoursClose;
            shipmentUnified.Main.shipperHoursOpen = cmd.hoursOpen;
        }else if ((contactType === SearchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE) && cmd) {
            shipmentUnified.Main.Consignee_Name = cmd.Name;
            shipmentUnified.Main.consignee_name2 = cmd.Name2;
            shipmentUnified.Main.consignee_address = cmd.Address1;
            shipmentUnified.Main.consignee_address2 = cmd.Address2;
            shipmentUnified.Main.consignee_city = cmd.City;
            shipmentUnified.Main.consignee_contact_email = cmd.Email;
            shipmentUnified.Main.consignee_contact_fax = cmd.Fax;
            shipmentUnified.Main.consignee_contact_name = cmd.Contact;
            shipmentUnified.Main.consignee_contact_phone = cmd.Phone;
            shipmentUnified.Main.consignee_country = cmd.country;
            shipmentUnified.Main.consignee_state = cmd.State;
            shipmentUnified.Main.consignee_zip = cmd.Zip;
            shipmentUnified.Main.Consignee = cmd.ID;
            shipmentUnified.Main.consigneeCallAppt = cmd.callAppt;
            shipmentUnified.Main.consigneeCommercialResidential = cmd.commercialResidential;
            shipmentUnified.Main.consigneeHoursClose = cmd.hoursClose;
            shipmentUnified.Main.consigneeHoursOpen = cmd.hoursOpen;
        }
    }

    private contactTransform(contactType) {
        const contact = new ContactSearchResult();

        if (SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER === contactType) {
            contact.name = (this.lastShipmentUnified.Main.Shipper_Name) ? this.lastShipmentUnified.Main.Shipper_Name : '';
            contact.name2 = (this.lastShipmentUnified.Main.shipper_name2) ? this.lastShipmentUnified.Main.shipper_name2 : '';
            contact.address1 = (this.lastShipmentUnified.Main.shipper_address) ? this.lastShipmentUnified.Main.shipper_address : '';
            contact.address2 = (this.lastShipmentUnified.Main.shipper_address2) ? this.lastShipmentUnified.Main.shipper_address2 : '';
            contact.city = (this.lastShipmentUnified.Main.shipper_city) ? this.lastShipmentUnified.Main.shipper_city : '';
            contact.email = (this.lastShipmentUnified.Main.shipper_contact_email) ? this.lastShipmentUnified.Main.shipper_contact_email : '';
            contact.fax = (this.lastShipmentUnified.Main.shipper_contact_fax) ? this.lastShipmentUnified.Main.shipper_contact_fax : '';
            contact.contact = (this.lastShipmentUnified.Main.shipper_contact_name) ? this.lastShipmentUnified.Main.shipper_contact_name : '';
            contact.phone = (this.lastShipmentUnified.Main.shipper_contact_phone) ? this.lastShipmentUnified.Main.shipper_contact_phone : '';
            contact.country = (this.lastShipmentUnified.Main.shipper_country) ? this.lastShipmentUnified.Main.shipper_country : '';
            contact.state = (this.lastShipmentUnified.Main.shipper_state) ? this.lastShipmentUnified.Main.shipper_state : '';
            contact.zip = (this.lastShipmentUnified.Main.shipper_zip) ? this.lastShipmentUnified.Main.shipper_zip : '';
            contact.id = (this.lastShipmentUnified.Main.Shipper) ? this.lastShipmentUnified.Main.Shipper : undefined;
            contact.callappt = this.lastShipmentUnified.Main.shipperCallAppt;
            contact.commercialresidential = (this.lastShipmentUnified.Main.shipperCommercialResidential) ? this.lastShipmentUnified.Main.shipperCommercialResidential : 'C';
            contact.hoursclose = (this.lastShipmentUnified.Main.shipperHoursClose) ? this.lastShipmentUnified.Main.shipperHoursClose : '';
            contact.hoursopen = (this.lastShipmentUnified.Main.shipperHoursOpen) ? this.lastShipmentUnified.Main.shipperHoursOpen : '';
        } else if (SearchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE === contactType) {
            contact.name = (this.lastShipmentUnified.Main.Consignee_Name) ? this.lastShipmentUnified.Main.Consignee_Name : '';
            contact.name2 = (this.lastShipmentUnified.Main.consignee_name2) ? this.lastShipmentUnified.Main.consignee_name2 : '';
            contact.address1 = (this.lastShipmentUnified.Main.consignee_address) ? this.lastShipmentUnified.Main.consignee_address : '';
            contact.address2 = (this.lastShipmentUnified.Main.consignee_address2) ? this.lastShipmentUnified.Main.consignee_address2 : '';
            contact.city = (this.lastShipmentUnified.Main.consignee_city) ? this.lastShipmentUnified.Main.consignee_city : '';
            contact.email = (this.lastShipmentUnified.Main.consignee_contact_email) ? this.lastShipmentUnified.Main.consignee_contact_email : '';
            contact.fax = (this.lastShipmentUnified.Main.consignee_contact_fax) ? this.lastShipmentUnified.Main.consignee_contact_fax : '';
            contact.contact = (this.lastShipmentUnified.Main.consignee_contact_name) ? this.lastShipmentUnified.Main.consignee_contact_name : '';
            contact.phone = (this.lastShipmentUnified.Main.consignee_contact_phone) ? this.lastShipmentUnified.Main.consignee_contact_phone : '';
            contact.country = (this.lastShipmentUnified.Main.consignee_country) ? this.lastShipmentUnified.Main.consignee_country : '';
            contact.state = (this.lastShipmentUnified.Main.consignee_state) ? this.lastShipmentUnified.Main.consignee_state : '';
            contact.zip = (this.lastShipmentUnified.Main.consignee_zip) ? this.lastShipmentUnified.Main.consignee_zip : '';
            contact.id = (this.lastShipmentUnified.Main.Consignee) ? this.lastShipmentUnified.Main.Consignee : undefined;
            contact.callappt = this.lastShipmentUnified.Main.consigneeCallAppt;
            contact.commercialresidential = (this.lastShipmentUnified.Main.consigneeCommercialResidential) ? this.lastShipmentUnified.Main.consigneeCommercialResidential : 'C';
            contact.hoursclose = (this.lastShipmentUnified.Main.consigneeHoursClose) ? this.lastShipmentUnified.Main.consigneeHoursClose : '';
            contact.hoursopen = (this.lastShipmentUnified.Main.consigneeHoursOpen) ? this.lastShipmentUnified.Main.consigneeHoursOpen : '';
        }
        return contact;
    }

    private miscFieldsTransform(shipment: ShipmentUnified) {
        const labelValues: Array<LabelValue> = new Array<LabelValue>();
        let foundOne = false;
        let dropValueField;

        for (dropValueField of dropValueFields) {
            if (shipment.Main[dropValueField.field]) {
                labelValues.push({label: dropValueField.label, value: shipment.Main[dropValueField.field]});
                foundOne = true;
            }
        }

        if (!foundOne) {
            labelValues.push({label: dropValueFields[0].label, value: ''});
        } else if (labelValues.length < dropValueFields.length) {
            // Add first empty label we can find so user can enter value for that:
            for (dropValueField of dropValueFields) {
                foundOne = _.find(labelValues, (lvp) => lvp.label === dropValueField.label);
                if (!foundOne) {
                    labelValues.push({label: dropValueField.label, value: ''});
                    break;
                }
            }
        }

        return labelValues;
    }

    private billToTransform() {
        const billTo = new ContactSearchResult();
        let matchedContact;
        if (this.lastShipmentUnified.Main.BillTo) {
            matchedContact = _.find(this._commonDataService.billToContacts, (btc: ContactSearchResult) => +(btc.id) === +(this.lastShipmentUnified.Main.BillTo));
            if (matchedContact) {
                return matchedContact;
            }
        }
        billTo.name = EMPTY_BILLTO;
        return billTo;
    }

    private getBolNumber(skip = false) {
        const req = new HttpRequest(REQUEST_TYPE_GET, 'shipment/bolNumber');
        if (skip) {
            return of('');
        } else {
            return this._api.callApiService<ResponseDTO<string>>(req).pipe(
                map(response => {
                    if (response && response.isValid) {
                        return response.dto;
                    } else {
                        const error: Error = {...(new Error()), ...response};
                        throw error;
                    }
                }),
                catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Get BOL Number` });
                    return of('');
                }),
            )
        }
    }

    private getContactById(id: number) {
        const req = new HttpRequest(REQUEST_TYPE_GET, `MasterData/Contacts/${id}`);
        return this._api.callApiService<ResponseDTO<Array<ContactMasterData>>>(req).pipe(
            map((response) => {
                if (response && response.isValid) {
                    if (response.dto && response.dto.length > 0) {
                        if (response.dto.length > 1) {
                            this._notificationService.notifyWarning({ title: 'Contact', message: `${response.dto.length} contacts returned, displaying first value.` });
                        }
                        return response.dto[0];
                    } else {
                        this._notificationService.notifyError({title: 'Contact', message: 'No contact(s) found.'});
                        return undefined;
                    }
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Contact` });
                    throw err;
                }
            )
        );
    }

    private addAccessorialToLastShipmentUnified(accl: AccessorialForQuote) {
        if (this.lastShipmentUnified.Quote.accl === undefined) {
            this.lastShipmentUnified.Quote.accl = [];
        }

        if (!_.find(this.lastShipmentUnified.Quote.accl, (acc: AccessorialForQuote) => acc.code === accl.code)) {
            this.lastShipmentUnified.Quote.accl.push(accl);
        }
    }

    private enforceAccessorials() {
        let accessorial: AccessorialForQuote;
        if (this.lastShipmentUnified.Main.shipperCommercialResidential === 'R') {
            accessorial = _.find(this._commonDataService.availableAccessorials, (acc: AccessorialForQuote) => acc.code === 'RESIDENTIALPICKUP');
            this.addAccessorialToLastShipmentUnified({
                displayName: accessorial.displayName,
                code: accessorial.code,
                value: 0
            });
        }

        if (this.lastShipmentUnified.Main.consigneeCommercialResidential === 'R') {
            accessorial = _.find(this._commonDataService.availableAccessorials, (acc: AccessorialForQuote) => acc.code === 'RESIDENTIALDELIVERY');
            this.addAccessorialToLastShipmentUnified({
                displayName: accessorial.displayName,
                code: accessorial.code,
                value: 0
            });
        }

        if (this.lastShipmentUnified.Main.shipperCallAppt) {
            accessorial = _.find(this._commonDataService.availableAccessorials, (acc: AccessorialForQuote) => acc.code === 'APPOINTMENTATPICKUP');
            this.addAccessorialToLastShipmentUnified({
                displayName: accessorial.displayName,
                code: accessorial.code,
                value: 0
            });
        }

        if (this.lastShipmentUnified.Main.consigneeCallAppt) {
            accessorial = _.find(this._commonDataService.availableAccessorials, (acc: AccessorialForQuote) => acc.code === 'APPOINTMENT');
            this.addAccessorialToLastShipmentUnified({
                displayName: accessorial.displayName,
                code: accessorial.code,
                value: 0
            });
        }
    }

    private transformProductDetailToProduct(productDetail: ProductDetail, customer: string) {
        const product = new Product();
        product.Customer = customer;
        product.class = (productDetail.class) ? productDetail.class : 0;
        product.Cube = (productDetail.Cube) ? productDetail.Cube : 0;
        product.description = (productDetail.description) ? productDetail.description : '';
        product.description2 = (productDetail.description2) ? productDetail.description2 : '';
        product.Detail_ID = (productDetail.Detail_ID) ? productDetail.Detail_ID : null;
        product.hazmat_class = (productDetail.hazmat_class) ? productDetail.hazmat_class : '';
        product.hazmat_number = (productDetail.hazmat_number) ? productDetail.hazmat_number : '';
        product.hazmat_packingGroup = (productDetail.hazmat_packingGroup) ? productDetail.hazmat_packingGroup : '';
        product.hazmat_subClass = (productDetail.hazmat_subClass) ? productDetail.hazmat_subClass : '';
        product.hazmat = (productDetail.hazmat) ? productDetail.hazmat : 0;
        product.Height = (productDetail.Height) ? productDetail.Height : 0;
        if (productDetail.ID) {
            product.ID = productDetail.ID;
        } else {
            product.ID = 0;
        }
        product.Length = (productDetail.Length) ? productDetail.Length : 0;
        product.loadingMeters = (productDetail.loadingMeters) ? productDetail.loadingMeters : 0;
        product.name = (productDetail.name) ? productDetail.name : '';
        product.nmfc = (productDetail.nmfc) ? productDetail.nmfc : '';
        product.Pkg_Type = (productDetail.Pkg_Type) ? productDetail.Pkg_Type : 0;
        product.Product = (productDetail.Product) ? productDetail.Product : 0;
        product.Qty = (productDetail.Qty) ? productDetail.Qty : 0;
        product.stackable = (productDetail.stackable) ? productDetail.stackable : 0;
        product.Unit_Type = (productDetail.Unit_Type) ? productDetail.Unit_Type : 0;
        product.Units = (productDetail.Units) ? productDetail.Units : 0;
        product.Width = (productDetail.Width) ? productDetail.Width : 0;
        product.Weight = (productDetail.Weight) ? productDetail.Weight : 0;
        product.productId = (productDetail.productId) ? productDetail.productId : 0;

        return product;
    }

    private updateShipmentWithZipSearch(shipment: ShipmentUnified, type) {
        return this._searchService.zipSearchUSDefault((type === SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER) ? shipment.Main.shipper_zip : shipment.Main.consignee_zip).pipe(
            map((response) => {
                let zipResult: ZipSearchResult;
                if (response && response.isValid && response.dto &&
                    response.dto.searchResults &&
                    response.dto.searchResults.length &&
                    response.dto.searchResults[0].entities &&
                    response.dto.searchResults[0].entities.length) {
                    zipResult = response.dto.searchResults[0].entities[0];
                    if (type === SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER) {
                        shipment.Main.shipper_zip = zipResult.zip_code;
                        shipment.Main.shipper_city = zipResult.city;
                        shipment.Main.shipper_state = zipResult.state;
                        shipment.Main.shipper_country = zipResult.countryname;
                    } else {
                        shipment.Main.consignee_zip = zipResult.zip_code;
                        shipment.Main.consignee_city = zipResult.city;
                        shipment.Main.consignee_state = zipResult.state;
                        shipment.Main.consignee_country = zipResult.countryname;
                    }
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: (type === SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER) ? 'Shipper Zip' : 'Consignee Zip', title: 'Zip Search Failure' });
                }
            })
        );
    }

    public updateProductCube(cubeRequest: CubeRequest): Observable<ResponseDTO<number>> {
        const req = new HttpRequest(REQUEST_TYPE_POST, `common/cube/`, cubeRequest);
        return this._api.callApiService<ResponseDTO<number>>(req);
    }

    public searchRecordProducts(productSearchTerm: string): Observable<ResponseDTO<SearchResponse>> {
        const searchReq: SearchRequest = {
            searchCriteria: [
                {
                    type: SearchConstants.SEARCH_CRITERIA_type.ALL,
                    value: productSearchTerm,
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_PRODUCTS,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
                }
            ]
        };

        const req = new HttpRequest(REQUEST_TYPE_POST, `Search`, searchReq);

        return this._api.callApiService<ResponseDTO<SearchResponse>>(req);
    }

    public openBOL({ customerId, shipmentId }): void {
        const req = new HttpRequest(REQUEST_TYPE_GET, `shipment/vics/${customerId}/${shipmentId}`);
        this._api.callApiServiceBinary(req)
            .subscribe((response) => {
                    const blob = new Blob([response], { type: 'application/pdf' }); // { type: response.type } ||  { type: 'application/pdf' }
                    saveAs(blob, `BOL_${customerId}_${shipmentId}.pdf`);
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Shipment VICS BOL' });
                });
    }

    public openShippingLabel({ customerId, shipmentId }): void {
        const req = new HttpRequest(REQUEST_TYPE_GET, `shipment/labels/${customerId}/${shipmentId}`);
        this._api.callApiServiceBinary(req)
            .subscribe((response) => {
                    const blob = new Blob([response], { type: 'application/pdf' }); // { type: response.type } ||  { type: 'application/pdf' }
                    saveAs(blob, `SHIPPINGLABEL_${customerId}_${shipmentId}.pdf`);
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Shipping Label' });
                });
    }

    public getVanguardContacts(vanguardContactType: string): Observable<ResponseDTO<Array<ContactMasterData>>> {
        if (!vanguardContactType) {
            return;
        }

        const req = new HttpRequest(REQUEST_TYPE_GET, `Shipment/vanguardCfs/${vanguardContactType}`);
        return this._api.callApiService<ResponseDTO<Array<ContactMasterData>>>(req);
    }
}
