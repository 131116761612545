<app-admin-tabs [activeAdminSection]="sectionName"></app-admin-tabs>
<div class="customerListComponent">
    <div class="filterSectionRow">
        <div class="filterSection">
            <div class="searchCriteriaTypePanel">
                <select class="searchCriteriaTypeText" name="selectedFilterField" id="searchCriteriaTypes" [(ngModel)]="selectedFilterField"
                    (change)="filterCustomers()">
                    <option [value]="ff.filterField" *ngFor="let ff of dropdownFilterFields">{{ ff.displayText }}</option>
                </select>
                <span class="searchCriteriaTypeChevron">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
            </div>
            <form class="filterInputPanel" (ngSubmit)="filterCustomers()" [formGroup]="customerListForm">
                <input type="text" id="searchQuery" autofocus class="searchQuery" name="searchQuery" formControlName="filterTerm"
                    placeholder="Enter Search Criteria" (change)="filterCustomers()" />
            </form>
            <span class="iconSearch clickable blue">
                <i class="fa fa-search" aria-hidden="true"></i>
            </span>
        </div>
        <div class="createCustomerSection">
            <span class="createCustomerIcon blue clickable">
                <i class="fa fa-plus-circle"></i>
            </span>
            <span class="createCustomerLabel blue clickable actionText" (click)="navigateToCustomerShowPage('new')">Create</span>
        </div>
    </div>

    <div class="customerListContainer">
        <div class="customerListHeaderRow">
            <div class="headerItem">Customer Name</div>
            <div class="headerItem">Customer No.</div>
            <div class="headerItem">City</div>
            <div class="headerItem">State</div>
            <div class="lastCol">&nbsp;</div>
        </div>
        <div class="customerDetailContainer" *ngIf="masterDataCustomers.length">
            <cdk-virtual-scroll-viewport itemSize="25" style="height: 100%">
                <div class="customerDetailRow bottomBorderShadow" *cdkVirtualFor="let customer of filteredCustomers; let i = index" (click)="navigateToCustomerShowPage(customer.custNo)">
                    <div class="rowDetailItem rowDetailItemName blue actionText">{{ customer?.custName }}</div>
                    <div class="rowDetailItem">{{ customer?.custNo }}</div>
                    <div class="rowDetailItem">{{ customer?.custCity }}</div>
                    <div class="rowDetailItem">{{ customer?.custState }}</div>
                    <div class="lastCol" (click)="showConfirmDeleteModal($event, customer)">
                        <i class="fa fa-times blue clickable" aria-hidden="true"></i>
                    </div>

                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>

<app-ok-cancel-modal *ngIf="isConfirmDeleteModalVisible" [title]="confirmDeleteCustomerTitle" [message]="confirmDeleteCustomerMessage"
    (onCancel)="hideConfirmDeleteCustomerModal()" (onOk)="deleteCustomer($event)" [okPayload]="selectedCustomer"></app-ok-cancel-modal>
