import {MarkupExceptionAccessorial} from './markupExceptionAccessorial';
import { MarkupExceptionCarrier } from './markupExceptionCarrier';

export class MarkupException {
  exceptionDescription: string;
  effective: string;
  end: string;
  low: number;
  high: number;
  breakType: string;
  rate: number;
  rateType: number;
  displayRateType?: string;
  rateOn: string;
  minMarkup: number;
  minMarkupon: string;
  maxMarkup: number;
  maxMarkupType: number;
  minCharge?: number;
  displayMaxMarkupType?: string;

  exceptionCarriers: Array<MarkupExceptionCarrier>;
  exceptionAccessorials?: Array<MarkupExceptionAccessorial>;

  markupExceptionID?: number;
  changeDate?: string;
  changeBy?: string;
  overlap?: string;
  overlapsWith?: string;
}
