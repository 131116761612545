<div class="paginationPanel" *ngIf="pageNumbers?.length > 1">
  <div class="arrow clickable" (click)="callPreviousPage()" [ngClass]="{
    'hidden': currentPage?.pageNumber === 1
  }">
    <i class="fa fa-chevron-left" aria-hidden="true"></i>
  </div>

  <div class="pageNumbersContainer" *ngFor="let pageNumberOption of pageNumbers | paginationPageNumberFilter">
    <span class="pageNumberOption number clickable" *ngIf="!pageNumberOption.isPreviousSetEllipsis && !pageNumberOption.isNextSetEllispis"
      (click)="callPageNumber(pageNumberOption)" [ngClass]="{
        'currentPage': pageNumberOption.isCurrentPage
      }">
      {{ pageNumberOption.pageNumber }}
    </span>

    <span class="pageNumberOption ellipsis clickable" *ngIf="pageNumberOption.isPreviousSetEllipsis" (click)="callPreviousSet(pageNumberOption)">
      ...
    </span>

    <span class="pageNumberOption ellipsis clickable" *ngIf="pageNumberOption.isNextSetEllispis" (click)="callNextSet(pageNumberOption)">
      ...
    </span>
  </div>

  <div class="arrow clickable" (click)="callNextPage()" [ngClass]="{
    'hidden': currentPage?.pageNumber === pageNumbers.length
  }">
    <i class="fa fa-chevron-right" aria-hidden="true"></i>
  </div>

  <!-- ===================== RESULTS PER PAGE PANEL ===================== -->
  <div class="resultsPerPagePanel">
    <div class="resultsPerPageSelectPanel">
      <select name="" id="" class="resultsPerPageSelectTag" [(ngModel)]="resultsPerPage" (focus)="setPreviousResultsPerPageValue()"
        (change)="handleResultsPerPageChanges()">
        <option [ngValue]="resultsPerPageOption" *ngFor="let resultsPerPageOption of resultsPerPageOptions">
          {{ resultsPerPageOption }}
        </option>
      </select>

      <span class="resultsPerPageSelectCaret clickableCaret clickableCaretPositioning">
        <i class="fa fa-caret-down"></i>
      </span>
    </div>

    <span class="resultsPerPageText">
      Results Per Page
    </span>
  </div>
</div>