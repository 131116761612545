<div class="overlay">
  <div class="productSearchModal">
    <!--================================================ MODAL HEADER =========================================-->
    <div class="modalHeader">
      <div class="modalHeaderText">
        Product Search
      </div>
      <div class="modalHeaderIcon clickable" (click)="closeModal()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </div>
    </div>

    <!--================================================ MODAL BODY =========================================-->
    <div class="modalBody">
      <!--====================================== SEARCH INPUT PORTION ====================================-->
      <div class="productSearch">
        <form class="productsSearchQueryHolder" (ngSubmit)="searchProducts()">
          <button type="submit" name="formSubmission" class="btn-hidden-IE"></button>
          <input type="text" class="productsSearchQueryInput" name="productSearchQuery" [(ngModel)]="productSearchQuery">
          <div class="productsSearchQueryIcon clickable" (click)="searchProducts()">
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </form>
      </div>

      <!--====================================== NO SEARCH RESULTS PROMPT ====================================-->
      <div class="performSearchPrompt" *ngIf="!searchResults.length">
        <div class="performSearchPromptText">
          No results found.
        </div>
        <div class="performSearchPromptIconHolder">
          <i class="fa fa-frown-o"></i>
        </div>
      </div>

      <!--====================================== SEARCH RESULTS HEADER ====================================-->
      <div class="modalSearchResults" *ngIf="searchResults.length">
        <div class="modalSearchResultsHeader">
          <span class="modalSearchResultsHeaderName">
            Name
          </span>
          <span class="modalSearchResultsHeaderHazmat">
            Hazmat
          </span>
          <span class="modalSearchResultsHeaderNMFC">
            NMFC
          </span>
          <span class="modalSearchResultsHeaderClass">
            Class
          </span>
        </div>

        <!--====================================== SEARCH RESULTS BODY ====================================-->
        <div class="modalSearchResultsContainer scrollbar">
          <div class="searchResultRow clickable" *ngFor="let product of searchResults" (click)="selectProduct(product)">
            <span class="searchResultRowName">
              {{ product.name | titlecase }}
            </span>
            <span class="searchResultRowHazmat" *ngIf="product.hazmat">
              Y
            </span>
            <span class="searchResultRowHazmat" *ngIf="!product.hazmat">
              N
            </span>
            <span class="searchResultRowNMFC" *ngIf="product.nmfc">
              {{ product.nmfc }}
            </span>
            <span class="searchResultRowNMFC" *ngIf="!product.nmfc">
              -
            </span>
            <span class="searchResultRowClass" *ngIf="product.productclass">
              {{ product.displayclass }}
            </span>
            <span class="searchResultRowClass" *ngIf="!product.productclass">
              -
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>