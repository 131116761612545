<div class="overlay">
    <div class="searchContactModal">
        <!--================================== MODAL HEADER ==============================================-->
        <div class="modalHeader">
            <div class="modalHeaderText">
                {{modalTitle}}
            </div>
            <span class="modalHeaderIcon clickable" (click)="cancel()">
                <i class="fa fa-times"></i>
            </span>
        </div>

        <!--================================== MODAL BODY ==============================================-->
        <div class="modalBody">
            <div class="searchContactQueryPanel">
                <div class="searchContactQuerySelectPanel">
                    <select name="" id="" class="selectTag" [(ngModel)]="selectedContactField"
                            (change)="searchContactsOnChange()">
                        <option [value]="contactField.contactField"
                                *ngFor="let contactField of dropdownContactFields">{{ contactField.displayText }}</option>
                    </select>
                    <span class="selectIcon clickableCaret">
                        <i class="fa fa-caret-down"></i>
                    </span>
                </div>
                <!--================================== MODAL INPUT AND DROPDOWN ==============================================-->
                <form class="searchContactQueryInputPanel" (ngSubmit)="searchContactsOnChange()">
                    <input type="text" class="inputTag" placeholder="Search" name="contactQuery"
                           (keyup)="searchContactsOnChange()" [(ngModel)]="contactQuery">
                    <span class="inputIcon clickable" (click)="searchContactsOnChange()">
                        <i class="fa fa-search"></i>
                    </span>
                </form>
            </div>

            <!--================================== PERFORM SEARCH PROMPT ==============================================-->
            <div class="performSearchPrompt" *ngIf="!contactResults.length && !isNoResultsPromptDisplayed">
                <div class="performSearchPromptText">
                    Perform your search above
                </div>
                <div class="performSearchPromptIconHolder">
                    <i class="fa fa-smile-o"></i>
                </div>
            </div>

            <!--================================== NO RESULTS PROMPT ==============================================-->
            <div class="performSearchPrompt" *ngIf="isNoResultsPromptDisplayed">
                <div class="performSearchPromptText">
                    No results found.
                </div>
                <div class="performSearchPromptIconHolder">
                    <i class="fa fa-frown-o"></i>
                </div>
            </div>


            <!--================================== MODAL SEARCH RESULTS ==============================================-->
            <div class="searchResultsHeader" *ngIf="contactResults.length">
                <span class="nameHeader">Name</span>
                <span class="locationHeader">Location</span>
            </div>
            <div class="searchResultsContainer" *ngIf="contactResults.length">
                <div class="searchResultRow clickable" *ngFor="let contact of contactResults"
                     (click)="selectContactInfo(contact)">
                    <span class="searchResultRowName">
                        {{ contact.name | titlecase }}
                    </span>
                    <span class="searchResultRowLocation">
                        {{ contact.city | titlecase }}, {{ contact.state | titlecase }}, {{ contact.zip }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
