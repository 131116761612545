import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { MockClipboardInterceptor } from './mocks/clipboard.interceptor';
import { MockDashboardInterceptor } from './mocks/dashboard.interceptor';
import { MockMasterDataInterceptor } from './mocks/masterData.interceptor';
import { MockQuoteInterceptor } from './mocks/quote.interceptor';
import { MockRecordInterceptor } from './mocks/record.interceptor';
import { MockSearchInterceptor } from './mocks/search.interceptor';

export const APP_INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: MockClipboardInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: MockDashboardInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: MockMasterDataInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: MockQuoteInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: MockRecordInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: MockSearchInterceptor, multi: true },
];

// import { environment } from '../environments/environment';

// const interceptors = [];
// if (environment.useMocking.clipboard) {
//   interceptors.push({ provide: HTTP_INTERCEPTORS, useClass: MockClipboardInterceptor, multi: true });
// }
// if (environment.useMocking.dashboard) {
//   interceptors.push({ provide: HTTP_INTERCEPTORS, useClass: MockDashboardInterceptor, multi: true });
// }
// if (environment.useMocking.masterData) {
//   interceptors.push({ provide: HTTP_INTERCEPTORS, useClass: MockMasterDataInterceptor, multi: true });
// }
// if (environment.useMocking.quote) {
//   interceptors.push({ provide: HTTP_INTERCEPTORS, useClass: MockQuoteInterceptor, multi: true });
// }
// if (environment.useMocking.record) {
//   interceptors.push({ provide: HTTP_INTERCEPTORS, useClass: MockRecordInterceptor, multi: true });
// }
// if (environment.useMocking.search) {
//   interceptors.push({ provide: HTTP_INTERCEPTORS, useClass: MockSearchInterceptor, multi: true });
// }
// if (environment.useMocking.user) {
//   interceptors.push({ provide: HTTP_INTERCEPTORS, useClass: MockUserInterceptor, multi: true });
// }

// export const APP_INTERCEPTORS = [
//   ...interceptors
// ];
