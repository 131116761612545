<div class="purchaseOrderLinesHeader">
    <div class="purchaseOrderNumberCol headerTitles">Purchase Order #</div>

    <div class="packagesCol headerTitles">Packages</div>

    <div class="weightCol headerTitles">Weight</div>

    <div class="palletSlipCol headerTitles">Pallet/Slip</div>

    <div class="additionalInfoCol headerTitles">Additional Info.</div>

    <div class="trashIconCol headerTitles"></div>
</div>

<div class="purchaseOrderLinesData" *ngFor="let purchaseOrderLine of value; let i=index" >
    <div class="purchaseOrderLineRow">
        <app-purchase-order-line (ngModelChange)="updatePurchaseOrderLine($event, i)" [ngModel]="purchaseOrderLine" #lineModels="ngModel"></app-purchase-order-line>
        <span class="trashIconCol"><i *ngIf="i !== (value.length - 1)" class="fa fa-trash blue clickable"
                                      (click)="deletePurchaseOrderDetailLine(i)" aria-hidden="true"></i></span>
    </div>
</div>
