export const
    DEFAULT_COUNTRY_CODE = 'US',
    DEFAULT_DELIVERY_TYPE_Commercial = 'C',
    DEFAULT_DELIVERY_TYPE_Residential = 'R',
    DEFAULT_PICKUP_TYPE_Commercial = 'C',
    DEFAULT_PICKUP_TYPE_Residential = 'R',
    DEFAULT_UNIT_OF_MEASURE = 'U',
    MASTER_DATA_CONTACT_DEFAULT_TIME_FORM_VALUE = {
        hourMinute: '12:00',
        meridian: 'AM'
    },
    MASTER_DATA_CONTACT_DEFAULT_CLOSE_TIME_FORM_VALUE = {
        hourMinute: '12:00',
        meridian: 'PM'
    },
    RECORD_CREATION_DEFAULT_TIME_VALUE = '00:00:00',
    RECORD_CREATION_DEFAULT_OUTBOUND_LABEL = 'Outbound',
    RECORD_CREATION_DEFAULT_INBOUND_LABEL = 'Inbound',
    SHIPRITE_HEADER_PANEL_HEIGHT_PX_VALUE = 85,
    SHIPRITE_HEADER_PANEL_MAXIMUM_HEIGHT_PX_VALUE = 108,
    SHIPRITE_HEADER_PANEL_MAXIMUM_HEIGHT_EM_VALUE = '6.75em',
    SHIPRITE_LOGO_RELATIVE_PATH = '../../../assets/images/Logo.svg',
    USER_SETTINGS = {
        BILL_TO: 'defaultBillTo',
        CLASS: 'defaultClass',
        CONSIGNEE: 'defaultConsignee',
        DEST_ZIP: 'defaultDestZip',
        ORIGIN_ZIP: 'defaultOriginZip',
        SHIPPER: 'defaultShipper',
    };
