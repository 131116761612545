<div class="overlay">
    <div class="tour-menu-bar">
        <app-button [inactive]="prevInactive()" (click)="prevPage()">Prev</app-button>
        <app-button [inactive]="nextInactive()" (click)="nextPage()">Next</app-button>
        <app-button (click)="cancel()">{{cancelText}}</app-button>
    </div>
    <div class="tour-content-files" *ngIf="showingFiles">
        <img [src]="fileNames[currentTourPage-1]" />
    </div>
    <ng-container *ngIf="!showingFiles">
        <pdf-viewer class="tour-content"
                    (after-load-complete)="pdfInfo($event)"
                    (error)="pdfError($event)"
                    [src]="(tourService.tourPdfNameSubject|async)"
                    [page]="currentTourPage"
                    [rotation]="0"
                    [original-size]="false"
                    [show-all]="false"
                    [fit-to-page]="true"
                    [autoresize]="false"
                    [show-borders]="false"
        ></pdf-viewer>
    </ng-container>
</div>
