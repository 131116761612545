import { Injectable } from '@angular/core';
import {SignalsService} from "../services/signals.service";

@Injectable()
export class AppStateService {

    constructor(
        private _signalsService: SignalsService,
    ) { }

    public getAppState() {
        return this._signalsService.appStateSignal();
    }

    public isUserSessionActive(): boolean {
        const appState = this.getAppState();

        return appState ? appState['global.isUserSessionActive'] : false;
    }

}
