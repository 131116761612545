import { Component } from '@angular/core';

@Component({
    selector: 'app-admin-group',
    styleUrls: ['./admin-group.component.scss'],
    templateUrl: './admin-group.component.html',
})
export class AdminGroupComponent {

    constructor(
    ) { }
}
