export const
    NOT_SUBMITTED = 0,
    ORDER_RECEIVED = 1,
    SCHEDULED_PICKUP = 2,
    UNKNOWN = 3,
    ENROUTE_TO_DESTINATION = 4,
    DELIVERED = 5,
    INVOICED = 6,
    CANCELLED = 7,
    PENDING = 8,
    REJECTED = 9,
    CARRIER_CONFIRMED = 10,
    OS_AND_D = 11,
    QUOTE = 12,
    REVIEW = 13,
    PROCESSING = 14,
    PENDING_REVIEW = 15,
    PENDING_CONFIRMATION = 16;
