<ng-template #noShipmentFiller>
    <span>
        <i class="fa fa-exclamation-triangle triangle"></i>
    </span>&nbsp;
    <span>Note: To add insurance, please save your shipment</span>
</ng-template>
<ng-container *ngIf="shipment?.ID; else noShipmentFiller">
    <div class="columns leftColumn">
        <div class="insuranceHeading">Insurance</div>
        <div>Insurance Accepted:</div>
        <div>Shipment Value:</div>
        <div>Shipment Charge:</div>
    </div>
    <div class="columns rightColumn">
        <ng-template #addButton>
            <div class="buttonContainer">
                <app-button (click)="addInsurance()" [buttonColor]="'white'">Add Insurance</app-button>
            </div>
        </ng-template>
        <div class="buttonContainer" *ngIf="value?.purchased; else addButton">
            <app-button (click)="removeInsurance()" [buttonColor]="'white'">Remove Insurance</app-button>
        </div>
        <div>
            {{(value?.purchased) ? 'Yes' : 'No'}}
        </div>
        <div>
            {{value?.shipmentValue | number: '1.2-2' }}
        </div>
        <div>
            {{value?.charge | number: '1.2-2' }}
        </div>
    </div>
</ng-container>

<app-insurance-quoting-modal *ngIf="isInsuranceModalShown" [shipment]="shipment" (updateInsurancePurchase)="closeInsuranceQuotingModal($event)"></app-insurance-quoting-modal>
