<div class="overlay">
    <div class="rerate-warning-modal">
        <!-- ================================= QUOTE RERATE MODAL HEADER====================================== -->
        <div class="rerateQuoteModalHeader">
            <span class="closeModalButtonHolder clickable" (click)="cancelReRate()">
                <i class="fa fa-times"></i>
            </span>
        </div>

        <!-- ==================  QUOTE RERATE MODAL BODY ================== -->
        <div class="rerateQuoteModalBody">
            <div class="warningIconHolder">
                <i class="fa fa-exclamation-triangle"></i>
            </div>

            <div class="rerateQuoteModalBodyText">
                You have changed information that requires a new carrier selection.
            </div>
            <div class="rerateQuoteModalBodyText">
                Updates to your quote will generate a new quote number.
            </div>
        </div>

        <!-- ==================QUOTE RERATE MODAL FOOTER ================== -->
        <div class="rerateQuoteModalFooter">
            <div class="clickable cancelButton btn-outline rerateQuoteModalButton" (click)="cancelReRate()">
                Cancel Changes
            </div>
            <div class="clickable saveQuoteButton btn-filled rerateQuoteModalButton" (click)="confirmReRate()">
                Continue
            </div>
        </div>

    </div>
</div>