<div class="overlay">
    <div class="accessorial-modal">
        <!--================================== MODAL HEADER ==============================================-->
        <div class="modal-header">
            <div class="modalHeaderContent modalHeaderText">Add Accessorial</div>
            <div class="modalHeaderContent">
                <i class="fa fa-times clickable modalHeaderIcon" aria-hidden="true" (click)="cancelModal()"></i>
            </div>
        </div>
        <!--================================== MODAL BODY- ==============================================-->
        <div class="modal-body">
            <form (ngSubmit)="updateFilteredValues()" [formGroup]="accessorialSelectorForm">
                <!--================================== MODAL FILTER INPUT- ==============================================-->
                <div class="queryField">
                    <input class="filterInput" placeholder="Search" formControlName="searchTerm" name="filterQuery"
                           #queryField>
                    <div class="searchIcon" (click)="updateFilteredValues()">
                        <span>
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </form>
            <!--================================== MODAL FILTER RESULTS ==============================================-->
            <div class="queryHeader">
                <span class="selectIcon clickable" *ngIf="!checkAll" (click)="selectAll()">
                    <i class="fa fa-square-o uncheckedBox selectBoxSize" aria-hidden="true"></i>
                </span>
                <span class="selectIcon clickable" *ngIf="checkAll" (click)="unselectAll()">
                    <i class="fa fa-check-square blue selectBoxSize" aria-hidden="true"></i>
                </span>
                <p>Name</p>
            </div>
            <div class="resultsContainer scrollbarGray">
                <div class="valueRow bottomBorderShadow clickable" *ngFor="let accessorial of filteredAccessorials"
                     (click)="toggleAccessorialSelection(accessorial)">
                    <span class="selectIcon clickable" *ngIf="checkedAccessorials?.indexOf(accessorial) === -1">
                        <i class="fa fa-square-o uncheckedBox selectBoxSize" aria-hidden="true"></i>
                    </span>
                    <span class="selectIcon clickable" *ngIf="checkedAccessorials?.indexOf(accessorial) !== -1">
                        <i class="fa fa-check-square blue selectBoxSize" aria-hidden="true"></i>
                    </span>
                    <p class="accCode">{{accessorial?.displayName}}</p>
                    <div *ngIf="accessorial.valueRequired" class="optionalValReq">
                        <div class="fieldName">
                            {{accessorial?.valueRequired}}
                        </div>
                        <input (click)="$event.stopPropagation(); selectAccessorial(accessorial)" class="valueInput"
                               [(ngModel)]="accessorial.accessorialValue" name="valueRequiredInput">
                    </div>
                </div>
            </div>
        </div>
        <!--================================== MODAL FOOTER ==============================================-->

        <div class="modal-footer">
            <div class="buttonHolder">
                <button class="addButton btn-outline clickable" (click)="passToRecord()">{{okayButton}}</button>
            </div>
        </div>
    </div>
</div>
