export const
    ADMIN_SECTIONS = {
        roles: 'Roles & Permissions',
        customers: 'Customers',
        users: 'Users',
        groups: 'Groups',
        markups: 'Markups'
    },
    ADMIN_SECTION_PATHS = {
        roles: 'roles',
        customers: 'customers',
        users: 'users',
        groups: 'groups',
        markups: 'markups',
    },
    CUSTOMER_TAB_SECTIONS = {
        general: 'GENERAL',
        api_edi: 'API/EDI',
        olt_credentials: 'OLT_CREDENTIALS'
    },
    ADMIN_CREATE_PATH = 'new',
    ADMIN_CUSTOMER_LOGO_FILETYPES = {
        jpg: 'image/jpeg',
        png: 'image/png',
    },
    ADMIN_CUSTOMER_LOGO_SIZE_RESTRICTIONS = {
        maximum_height: 108,
        maximum_width: 666,
    },
    ADMIN_NEW_USER_PROFILE_PATH = '/admin/users/new',
    ADMIN_USER_PROFILE_PATH = 'userprofile';
