<div class="overlay">
  <div class="exceptionDescriptionModal">

    <!-- ======================== MODAL HEADER ======================== -->
    <div class="modalHeader">
      <div class="modalTitle">
        Exception Description
      </div>

      <span class="closeModalIcon clickable" (click)="closeModal()">
        <i class="fa fa-times"></i>
      </span>
    </div>

    <!-- ======================== MODAL BODY ======================== -->
    <div class="modalBody">
      <div class="editMarkupDescriptionContainer">
        <div class="descriptionRow">
          <div class="editMarkupDescriptionLabel">
            Description
          </div>

          <textarea [(ngModel)]="exceptionDescription" placeholder="Enter Description Here" class="descriptionInput"></textarea>
        </div>
      </div>
    </div>

    <!-- ======================== MODAL FOOTER ======================== -->
    <div class="modalFooter">
      <div class="btn-outline clickable" (click)="closeModal()">
        Cancel
      </div>

      <div class="btn-filled clickable" (click)="updateMarkupDescription()">
        Update Description
      </div>
    </div>
  </div>
</div>