import { Component } from '@angular/core';

@Component({
    selector: 'app-tour-base',
    styleUrls: ['./app-tour-base.component.scss'],
    templateUrl: './app-tour-base.component.html',
})
export class AppTourBaseComponent {

    constructor() {
    }
}
