<div class="forgotPasswordMain">
    <div class="forgotPasswordBody">
        <div class="shipriteLogoContainer">
            <img src="../../../assets/images/Logo.svg" class="shipriteLogo">
        </div>
        <div class="instructions">
            If you have forgotten your password or are having trouble logging in, please enter your username below.
            &nbsp;&nbsp;An email will be sent to the email address associated with the account with instructions to
            reset your password.
        </div>
        <form class="forgotPasswordForm" [formGroup]="forgotPasswordForm" (keyup)="checkForEnter($event)">
            <div class="forgotPasswordFormRow">
                <input #username class="longInput" type="text" placeholder="Username" formControlName="username" maxlength="100" [ngClass]="{ forgotPasswordFormInputInvalid:
                    (forgotPasswordForm['controls'].username.dirty || forgotPasswordForm['controls'].username.touched) && forgotPasswordForm['controls'].username.invalid }" />
            </div>
            <app-button class="forgotPasswordSubmitButton" (click)="processForgotPassword()">Submit</app-button>
        </form>
    </div>
</div>
