<div class="blockMessage" *ngIf="files === undefined">
    <div class="blockMessageHolder">
        <div class="blockIconHolder">
            <i class="blockIcon fa fa-exclamation-triangle"></i>
        </div>
        <div class="blockMessageTextHolder">
            <div class="blockMessageText">In order to attach files, the shipment needs to be saved.</div>
        </div>
        <app-button *ngIf="showSaveButton" class="blockMessageButtonHolder" (click)="saveRecord()">
            <i class="save-btn-icon fa fa-floppy-o" aria-hidden="true"></i> Save Shipment
        </app-button>
    </div>
</div>

<div class="filesHeaderTitle" *ngIf="files !== undefined">
    <div class="headerTitle">Files</div>
    <div class="upDownloadButtons">
        <app-button (click)="downloadSelectedFiles()">Download Selected</app-button>
        <input #fileInput multiple type="file" [id]="fileElemId" class="selectFilePanelInput" (change)="onFileChange($event)">
        <label [for]="fileElemId" class="blue btn-outline clickable">Upload</label>
    </div>
</div>

<div class="filesContainer" *ngIf="files !== undefined">
    <div class="filesHeader">
        <div class="checkboxCol"><app-checkbox (click)="toggleSelectAll()" [ngModel]="allFiles"></app-checkbox></div>
        <div class="filenameCol">File Name</div>
        <div class="dateaddedCol">Date Added</div>
        <div class="addedbyCol">Added By</div>
        <div class="downloadCol"></div>
    </div>

    <div class="fileAndDescriptionLine" *ngFor="let file of files">
        <div class="fileLine">
            <div class="checkboxCol"><app-checkbox (changeMade)="doSelect(file)" [(ngModel)]="file.selected"></app-checkbox></div>
            <div class="filenameCol">{{file.fileName}}
                <span class="blue actionText" (click)="toggleDescription(file)" *ngIf="file.description && !file.expanded">
                    <i class="fa fa-chevron-up"></i>
                </span>
                <span class="blue actionText" (click)="toggleDescription(file)" *ngIf="file.description && file.expanded">
                    <i class="fa fa-chevron-down"></i>
                </span>
            </div>
            <div class="dateaddedCol">{{getDocumentDisplayDate(file)}}</div>
            <div class="addedbyCol">{{file.attachedByName}}</div>
            <div class="propertiesCol">
                <div (click)="showProperties(file)" class="actionText blue underline">Properties</div>
                <span class="deleteIcon blue clickable" (click)="showConfirmDeleteModal(file)">
                    <i class="fa fa-times"></i>
                </span>
            </div>
        </div>
        <div class="attachmentsFormRowDescription" *ngIf="file.description && file.expanded">
            Description: {{ file.description }}
        </div>
    </div>
</div>

<app-ok-cancel-modal *ngIf="isConfirmDeleteModalVisible" [title]="'Delete Attached File Confirmation'"
                     [message]="'Are you sure you\'d like to delete this attachment?'"
                     (onCancel)="hideConfirmDeleteModal()" (onOk)="deleteAttachment($event)"
                     [okPayload]="fileToDelete"></app-ok-cancel-modal>

<app-document-properties-modal *ngIf="isEditDocumentPropertiesModalShown" [document]="fileToUpdate"
                               (onClose)="hideEditDocumentPropertiesModal($event)"></app-document-properties-modal>
