<div class="adminTabs" *ngIf="showLevel1Header">
    <div class="adminTabSectionContainer">
        <div class="adminTabSection clickable" (click)="setActiveAdminSection(adminSectionPaths.roles)" [ngClass]="{ 'currentTab': activeAdminSection === adminSectionPaths.roles }">
            <span class="adminTabSectionIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x rolesOuterIcon currentOuterIcon"></i>
                <i class="fa fa-key fa-stack-1x rolesInnerIcon"></i>
            </span>

            <span class="adminTabSectionText">
                Roles &amp; Permissions
            </span>
        </div>

        <div class="adminTabPointerDown" *ngIf="activeAdminSection === adminSectionPaths.roles"></div>
    </div>

    <div class="adminTabSectionContainer">
        <div class="adminTabSection clickable" (click)="setActiveAdminSection(adminSectionPaths.customers)" [ngClass]="{ 'currentTab': activeAdminSection === adminSectionPaths.customers }">
            <span class="adminTabSectionIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x customersOuterIcon currentOuterIcon"></i>
                <i class="fa fa-building-o fa-stack-1x customersInnerIcon"></i>
            </span>

            <span class="adminTabSectionText">
                Customers
            </span>
        </div>

        <div class="adminTabPointerDown" *ngIf="activeAdminSection === adminSectionPaths.customers"></div>
    </div>

    <div class="adminTabSectionContainer">
        <div class="adminTabSection clickable" (click)="setActiveAdminSection(adminSectionPaths.users)" [ngClass]="{ 'currentTab': activeAdminSection === adminSectionPaths.users }">
            <span class="adminTabSectionIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x currentOuterIcon usersOuterIcon"></i>
                <i class="fa fa-users fa-stack-1x usersInnerIcon"></i>
            </span>

            <span class="adminTabSectionText">
                Users
            </span>
        </div>

        <div class="adminTabPointerDown" *ngIf="activeAdminSection === adminSectionPaths.users"></div>
    </div>

    <div class="adminTabSectionContainer">
        <div class="adminTabSection clickable" (click)="setActiveAdminSection(adminSectionPaths.markups)" [ngClass]="{ 'currentTab': activeAdminSection === adminSectionPaths.markups }">
            <span class="adminTabSectionIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x groupsOuterIcon currentOuterIcon"></i>
                <i class="fa fa-percent fa-stack-1x groupsInnerIcon"></i>
            </span>

            <span class="adminTabSectionText">
                Markups
            </span>
        </div>

        <div class="adminTabPointerDown" *ngIf="activeAdminSection === adminSectionPaths.markups"></div>
    </div>
</div>