export class LoadingBar {
    isLoaderVisible: boolean;
    isBlockerVisible: boolean;
    refCount: number;
};

export const initialLoadingBarState: LoadingBar = {
    isLoaderVisible: false,
    isBlockerVisible: false,
    refCount: 0,
};
