<div class="overlay">
    <div class="tmaAcceptanceModal">

        <!--=========================== MODAL HEADER ==============================-->
        <div class="modalHeader">
            <div class="modalHeaderContentText">Transportation Management Agreement</div>
        </div>

        <!--======================== MODAL BODY ========================-->
        <div class="modalBody">
            <div class="tmaHolder">
                <div class="tmaTitle">
                    <span class="tmaTitleNote">
                        To access Shiprite, please review the TMA below. Enter your first name, last name, and title to electronically sign.
                    </span>
                </div>

                <div class="tmaBody scrollbar" id="tmaContainer"></div>
                <div class="inputsSection">
                    <input class="authorizerInput" [(ngModel)]="approverFirstName" placeholder="* First Name">
                    <input class="authorizerInput" [(ngModel)]="approverLastName" placeholder="* Last Name">
                    <input class="authorizerInput" [(ngModel)]="approverTitle" placeholder="* Title">
                </div>
            </div>
            <!--======================== INPUTS SECTION ========================-->
            <!--======================== BUTTONS/FOOTER SECTION ========================-->

            <div class="buttonHolder">
                <button class="cancelButton clickable" (click)="logout()">CANCEL</button>
                <button class="okButton clickable" (click)="submitApproval()">ACCEPT</button>
            </div>
        </div>
        <div class="bottom"></div>
    </div>
</div>