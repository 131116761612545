export const
    DATE_FORMAT_ddmmyyyy = 'DD-MM-YYYY',
    DATE_FORMAT_dMMMy = 'dd-MMM-y',
    DATE_FORMAT_yyyymmdd = 'YYYY-MM-DD',
    DATE_FORMAT_YYYY_MM_DD_WITH_SLASHES = 'YYYY/MM/DD',
    DATE_FORMAT_MMDDYY_WITH_SLASHES = 'MM/DD/YY',
    DATE_FORMAT_MMMDDYYY_WITH_SLASHES = 'MM/DD/YYYY',
    DATE_FORMAT_MM_DD_YYYY = 'MM-DD-YYYY',
    DATE_TIME_FORMAT_MMMMDDYYYYHHmmss = 'MMMM, DD YYYY HH:mm:ss',
    DATE_TIME_FORMAT_YYYYMMDDHHmmssS = 'YYYY-MM-DD HH:mm:ss.S',
    DATE_TIME_FORMAT_YYYYMMDDHHmmss = 'YYYY-MM-DD HH:mm:ss',
    DATE_TIME_FORMAT_ddMMMy_UTC = 'dd-MMM-y HH:mm Z',
    DATE_TIME_FORMAT_ddMMMy_local = 'dd-MMM-y h:mm a',
    DATE_VALUE_INDEFINITELY = '9999-12-31',
    DATE_VALUE_INDEFINITELY_display = 'Indefinitely',
    DATE_VALUE_INDEFINITELY_format = 'YYYY-MM-DD',

    TIME_FORMAT_12_HOUR_MINUTE_SECOND = 'hh:mm:ss',
    TIME_FORMAT_24_HOUR_MINUTE_SECOND = 'HH:mm:ss',
    TIME_FORMAT_12_HOUR_MINUTE_MERIDIAN = 'hh:mmA',
    TIME_FORMAT_12_HOUR_MINUTE_MERIDIAN_WITH_SPACE = 'hh:mm A',
    TIME_FORMAT_12_HOUR_MINUTE_MERIDIAN_lowercase = 'h:mma',

    VALID_DATE_REGEX = new RegExp(/^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/),
    VALID_DATE_REGEX_MM_DD_YYYY = new RegExp(/^(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])\-\d{4}/),
    VALID_DATE_REGEX_MM_DD_YYYY_Slash = new RegExp(/^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}/),
    VALID_DATE_REGEX_YYYY_MM_DD = new RegExp(/^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/),
    VALID_DATE_REGEX_YYYY_MM_DD_Slash = new RegExp(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/),
    VALID_TIME_HOUR_MINUTE_REGEX = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/),

    // all separate date options below here, imported from constants file
    DATE_DISPLAY_YYYY_MM_DD: { format: string, validation: RegExp } = {
        format: DATE_FORMAT_yyyymmdd,
        validation: VALID_DATE_REGEX_YYYY_MM_DD
    },
    DATE_DISPLAY_YYYY_MM_DD_with_slashes: { format: string, validation: RegExp } = {
        format: DATE_FORMAT_YYYY_MM_DD_WITH_SLASHES,
        validation: VALID_DATE_REGEX_YYYY_MM_DD_Slash
    },
    DATE_DISPLAY_MM_DD_YYYY_with_slashes: { format: string, validation: RegExp } = {
        format: DATE_FORMAT_MMMDDYYY_WITH_SLASHES,
        validation: VALID_DATE_REGEX_MM_DD_YYYY_Slash
    },
    DATE_DISPLAY_MM_DD_YYYY: { format: string, validation: RegExp } = {
        format: DATE_FORMAT_MM_DD_YYYY,
        validation: VALID_DATE_REGEX_MM_DD_YYYY
    },

    // actual uniform date display value set here from choices above
    UNIFORM_DATE_DISPLAY: { format: string, validation: RegExp } = DATE_DISPLAY_MM_DD_YYYY_with_slashes,
    WEEKDAYS = [
        'SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'
    ];
