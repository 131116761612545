import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import * as _ from 'lodash-es';
import {Observable} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {clearArray} from '../helpers/utilities';
import {ContactMasterData} from '../models/contact.masterData';
import {EntityService} from "./entity.service";

@Injectable()
export class ContactService {
    public contacts = [];
    private lastContact: ContactMasterData;

    constructor(
        private _entityService: EntityService,
    ) {}

    public getContactsList() {
        return this._entityService.getEntityList<ContactMasterData>('Contact').pipe(
            take(1),
            map(list => {
                if (Array.isArray(list)) {
                    clearArray(this.contacts);
                    this.contacts.push(...list);
                }
            })
        );
    }

    public getContactByPath(id) {
        switch (id) {
            case 'new':
                return new Observable(subscriber => {
                    this.lastContact = new ContactMasterData();
                    subscriber.next(_.cloneDeep(this.lastContact));
                    subscriber.complete();
                });
            default:
                return this.getContact(id).pipe(
                    take(1),
                    map(contact => {
                        if (Object.keys(contact).length > 0) {
                            this.lastContact = contact as ContactMasterData;
                        }
                        return _.cloneDeep(this.lastContact);
                    }),
                    catchError(err => {
                        throw err;
                    })
                )
        }
    }

    public deleteContact(id) {
        return this._entityService.deleteEntity(id, 'Contact');
    }

    public createContact(contact) {
        return this._entityService.createEntity<any>(contact, 'Contact', 'contactsId', 'Name');
    }

    public updateContact(contact) {
        return this._entityService.updateEntity<any>(contact, 'Contact', contact.contactsId, 'Name');
    }

    public getContact(id) {
        return this._entityService.getEntity<ContactMasterData>('Contact', id);
    }

    getContactFormFromLastContact() {
        return undefined;
    }

    formEqualsLastContact(contactForm: UntypedFormGroup) {
        return false;
    }

    updateForm(contactForm: UntypedFormGroup) {
    }

    getContactFromForm(contactForm: UntypedFormGroup) {
        return undefined;
    }

    updateLastContact(contact: ContactMasterData) {
        this.lastContact = contact;
    }
}
