<div class="mainBody padLeft padRight">
  <!--========================================= HEADER =======================================================-->
  <div class="masterDataHeader">
    <div class="masterDataHeaderText">
      <div class="topMasterDataHeaderText">
        Master Data
      </div>
      <span *ngIf="masterDataCarrier">
        Master Data
      </span>
      <div *ngIf="masterDataCarrier && areGlobalSearchResultsBreadcrumbsDisplayed" class="breadcrumbs">
        <span class="clickable" (click)="navigateToDashboard()">Dashboard &gt;</span>
        <span class="clickable" (click)="navigateToSearch()">Global Search &gt;</span>
        <span class="clickable" (click)="navigateToSearch()">Search Results &gt;</span>
        <span class="breadCrumbsFinal">&nbsp;{{ masterDataCarrier.carrierName }} Carrier Details </span>
      </div>
      <div *ngIf="masterDataCarrier && areMasterDataBreadcrumbsDisplayed" class="breadcrumbs">
        <span class="clickable" (click)="navigateToMasterDataManagement()">Master Data &gt;</span>
        <span class="clickable" (click)="navigateToMasterDataCarrierList()">&nbsp;Carriers &gt;</span>
        <span class="breadCrumbsFinal">&nbsp;{{ masterDataCarrier.carrierName }} Carrier Details </span>
      </div>
      <div *ngIf="!masterDataCarrier && areMasterDataBreadcrumbsDisplayed" class="breadcrumbs">
        <span class="clickable" (click)="navigateToMasterDataManagement()">Master Data &gt;</span>
        <span class="clickable" (click)="navigateToMasterDataCarrierList()">&nbsp;Carriers &gt;</span>
        <span class="breadCrumbsFinal">&nbsp;Create Carrier </span>
      </div>

    </div>
    <div class="masterDataHeaderButtonsHolder">
      <button *ngIf="masterDataCarrierForm.dirty" class="btn-outline clickable" (click)="revertCarrierChanges()">
        <i class="fa fa-ban btn-icon" aria-hidden="true"></i>
        Cancel
      </button>
      <button class="btn-filled clickable" [ngClass]="{'carrierFormDirty': masterDataCarrierForm.dirty}" (click)="saveChanges()">
        <i class="fa fa-floppy-o" aria-hidden="true"></i>
        Save
      </button>
    </div>

  </div>

  <!--========================================= FORM HEADER =======================================================-->
  <div class="carrierFormHeader">
    <span>
      Create Carrier
    </span>
  </div>
  <!--========================================= MASTER DATA CARRIER FORM BODY =======================================================-->
  <form [formGroup]="masterDataCarrierForm" (ngSubmit)="saveChanges()">
    <button type="submit" name="formSubmission" class="btn-hidden-IE"></button>
    <div class="carrierFormColumnContainer">
      <div class="carrierFormLeftColumn">
        <div class="carrierFormRow">
          <div class="carrierFormLabel">
            <span class="required">*</span>&nbsp;Carrier Name
          </div>
          <input type="text" class="carrierFormValue" formControlName="name">
        </div>
        <div class="carrierFormRow">
          <div class="carrierFormLabel">
            <span class="required">*</span>&nbsp;SCAC
          </div>
          <input type="text" class="carrierFormValue" formControlName="scac">
        </div>
      </div>
    </div>

  </form>

</div>