import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, } from '@angular/router';

import * as MasterdataConstants from '../../constants/masterData.constants';
import {SignalsService} from "../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";

@Component({
    selector: 'app-master-data',
    templateUrl: './masterdata.component.html',
    styleUrls: ['./masterdata.component.scss']
})

export class MasterDataComponent implements OnInit, OnDestroy {
    private appStateSubscription: any;
    public activeMasterdataSection: string;
    public masterDataTabSections: {
        contacts: string,
        products: string,
        carriers: string,
    } = MasterdataConstants.MASTER_DATA_SECTIONS;
    public activeMasterdataSectionLabel: string;

    private appState$;

    constructor(
        private _router: Router,
        private _signalsService: SignalsService,
    ) {
        this.appState$ = toObservable(this._signalsService.appStateSignal);
    }

    ngOnInit() {
        this.activeMasterdataSection = MasterdataConstants.MASTER_DATA_SECTIONS.contacts;
        this.activeMasterdataSectionLabel = MasterdataConstants.MASTER_DATA_SECTIONS.contacts;

        this.appStateSubscription = this.appState$.subscribe((as) => {
            if (as['masterdata.activeSection']) {
                if (as['masterdata.activeSection'] === MasterdataConstants.MASTER_DATA_SECTION_PATHS.products) {
                    this.activeMasterdataSection = MasterdataConstants.MASTER_DATA_SECTIONS.products;
                    this.activeMasterdataSectionLabel = MasterdataConstants.MASTER_DATA_SECTIONS.products;
                } else if (as['masterdata.activeSection'] === MasterdataConstants.MASTER_DATA_SECTION_PATHS.carriers) {
                    this.activeMasterdataSection = MasterdataConstants.MASTER_DATA_SECTIONS.carriers;
                    this.activeMasterdataSectionLabel = MasterdataConstants.MASTER_DATA_SECTIONS.carriers;
                }
            }

        });

    }

    ngOnDestroy() {
        this.appStateSubscription.unsubscribe();
    }

    public navigateToMasterData(): void {
        this._router.navigate(['masterData']);
    }

    public switchMasterdataTabs(selectedTab: string): void {
        this.activeMasterdataSection = selectedTab;
        this.activeMasterdataSectionLabel = selectedTab;
    }
}
