<div *ngIf="markupForm" class="markupMainPanel">
    <!-- =========================== MARKUP BODY =========================== -->
    <form class="markupBody" [formGroup]="markupForm">
        <!-- =========================== MARKUP GENERAL PAGE =========================== -->
        <div class="markupsGeneralScreen markupBodyScreen">
            <!-- ============================== HEADER ROW ============================== -->
            <div class="markupsGeneralHeaderRow">
                General Markup Information
            </div>
            <div class="markupsGeneralBody">
                <!-- =========================== MARKUP GENERAL LEFT COLUMN =========================== -->
                <div class="markupsGeneralLeftColumn">

                    <div class="markupsGeneralRow">
                        <div class="markupsGeneralRowLabel">
                            <span class="required">*</span>
                            &nbsp; Markup Name
                        </div>
                        <input type="text" class="markupsGeneralRowField" formControlName="markupName" [ngClass]="{ 'invalidBorder': !markupName.pristine && markupName.errors && markupName.errors!.required }">
                    </div>
                    <div *ngIf="!markupName.pristine && markupName.errors && markupName.errors!.required" class="markupsGeneralRow invalidMessage">
                        <div class="markupsGeneralRowLabel"></div>
                        <div class="markupsGeneralRowField invalidMessage">
                            Markup Name is required
                        </div>
                    </div>

                    <div class="markupsGeneralRow">
                        <div class="markupsGeneralRowLabel">
                            Active
                        </div>
                        <div class="nonHovermarksupsGeneralRowField nonHovermarkupsGeneralRowRadioPanel">
                            <div class="markupsGeneralRowRadioOption">
                                <span class="markupsGeneralRadioButton clickable" (click)="toggleMarkupActive()">
                                  <i class="fa fa-circle-o" *ngIf="!markupForm.controls.markupActive.value"></i>
                                  <i class="fa fa-dot-circle-o" *ngIf="markupForm.controls.markupActive.value"></i>
                                </span>
                                <span class="markupsGeneralRadioButtonLabel">
                                    Yes
                                </span>
                            </div>

                            <div class="markupsGeneralRowRadioOption">
                                <span class="markupsGeneralRadioButton clickable" (click)="toggleMarkupActive()">
                                    <i class="fa fa-circle-o" *ngIf="markupForm.controls.markupActive.value"></i>
                                    <i class="fa fa-dot-circle-o" *ngIf="!markupForm.controls.markupActive.value"></i>
                                </span>
                                <span class="markupsGeneralRadioButtonLabel">
                                    No
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- =========================== MARKUP GENERAL RIGHT COLUMN =========================== -->
                <div class="markupsGeneralRightColumn">

                    <div class="markupsGeneralRow">
                        <div class="markupsGeneralRowLabel">
                            <span class="required">*</span>&nbsp; Markup Type
                        </div>
                        <!-- <input type="text" class="markupsGeneralRowField"> -->
                        <div class="markupsGeneralRowSelectPanel clickableCaretExtension">
                            <select class="markupsGeneralRowSelectTag" formControlName="markupType">
                                <option *ngFor="let markupType of markupTypes" [value]="markupType.markupType">
                                    {{ markupType.displayMarkupType }}
                                </option>
                            </select>
                            <span class="markupsGeneralRowSelectCaret clickableCaret clickableCaretPositioning">
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </div>
                    </div>

                    <div class="markupsGeneralRow">
                        <div class="markupsGeneralRowTextAreaLabel">
                            <span class="required">*</span>&nbsp; Description
                        </div>
                        <textarea class="markupsGeneralRowTextArea" formControlName="markupDescription" [ngClass]="{ 'invalidBorder': !markupDescription.pristine && markupDescription.errors && markupDescription.errors!.required }"></textarea>
                    </div>
                    <div *ngIf="!markupDescription.pristine && markupDescription.errors && markupDescription.errors!.required" class="markupsGeneralRow invalidMessage">
                        <div class="markupsGeneralRowLabel"></div>
                        <div class="markupsGeneralRowField invalidMessage">
                            Description is required
                        </div>
                    </div>

                </div>
            </div>

            <div class="markupsGeneralBottom borderBottomGray"></div>
        </div>

        <app-admin-markup-rates [rateOnOptions]="rateOnOptions"
                                [markupTypes]="markupTypes"
                                [markupType]="markupType"
                                [ratesFormArray]="markupForm.controls.rates['controls']"
                                (add)="addRate()"
                                (delete)="deleteRate($event)"
                                class="markupsGeneralScreen markupsExceptionsScreen"
        >
        </app-admin-markup-rates>

        <app-admin-markup-exceptions [rateOnOptions]="rateOnOptions"
                                [markupTypes]="markupTypes"
                                [markupType]="markupType"
                                [rangeTypes]="rangeTypes"
                                [exceptionsFormArray]="markupForm.controls.exceptions['controls']"
                                [markupId]="markupId"
                                (add)="addException()"
                                (delete)="deleteException($event)"
                                class="markupsGeneralScreen markupsExceptionsScreen"
        >
        </app-admin-markup-exceptions>

    </form>
</div>
