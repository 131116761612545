<div class="createViewPanel">
  <div class="headerBreadCrumbContainer">
    <div *ngIf="!showEditViewUI" class="leftItems">
      <span class="header2">Create View</span>
      <span class="breadcrumbs">
        <span class="clickable" (click)="cancelViewCreation()">Dashboard &gt;</span>
        <span class="blue bold">Create View</span>
      </span>
    </div>

    <div *ngIf="showEditViewUI" class="leftItems">
      <span class="header2">Edit View</span>
      <span class="breadcrumbs">
        <span class="clickable" (click)="cancelViewCreation()">Dashboard &gt;</span>
        <span class="blue bold">Edit View</span>
      </span>
    </div>

    <div class="rightItems">
      <div class="buttonHolder">
        <div class="clickable cancelIcon" (click)="navigateHome()">
          <i class="fa fa-ban" aria-hidden="true"></i>
        </div>

        <div *ngIf="showEditViewUI" class="clickable deleteIcon" (click)="deleteViewFromDashboard()">
          <i class="fa fa-trash-o" aria-hidden="true"></i>
        </div>

        <div *ngIf="showEditViewUI" class="clickable saveIcon" (click)="saveDashboardViewChanges()">
          <i class="fa fa-floppy-o" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="libraryInstructionsContainer" *ngIf="!showEditViewUI">
    <div class="libraryInstructionsText">Create a new view by clicking the create button to the right, or select an existing
      view below:</div>
    <div class="libraryButtonHolder">
      <div id="createViewBySearchButton" *ngIf="viewLibraryButtons.isCreateViewBySearchButtonVisible" class="button btn-filled clickable"
        (click)="navigateToSearch()">
        <i class="fa fa-plus-circle" aria-hidden="true"></i>
        Create View By Search
      </div>

      <div *ngIf="viewLibraryButtons.isDeleteViewFromLibraryButtonVisible" class="button btn-outline clickable" (click)="deleteViewFromDashboard()">
        <i class="fa fa-trash-o" aria-hidden="true"></i>
        Delete
      </div>

      <div *ngIf="viewLibraryButtons.isEditViewButtonVisible" id="createView-editMyViewButton" class="button btn-outline clickable"
        (click)="editViewFromLibrary()">
        <i class="fa fa-pencil" aria-hidden="true"></i>
        Edit
      </div>

      <div *ngIf="viewLibraryButtons.isSaveViewFromLibraryButtonVisible" id="createView-saveMyViewButton" class="button btn-filled clickable"
        (click)="saveViewsFromLibrary()">
        <i class="fa fa-floppy-o" aria-hidden="true"></i>
        Save
      </div>
    </div>
  </div>

  <div class="viewLibrarySection" *ngIf="!showEditViewUI">
    <div class="viewLibraryHeaderRow">
      <span class="viewLibraryHeader">
        View Library
      </span>
    </div>

    <!-- ===================== VIEW LIBRARY RESULTS ===================== -->
    <div class="viewLibraryMain scrollbar">
      <div class="viewGroupContainer" *ngFor="let category of viewCategories ">
        <div class="viewGroupHeaderRow bottomBorderShadow clickable" (click)="toggleViewCategoryExpansion(category)">
          <span class="categoryCollapseExpandIcon">
            <i *ngIf="category.isExpanded" class="fa fa-chevron-up"></i>
            <i *ngIf="!category.isExpanded" class="fa fa-chevron-down"></i>
          </span>

          <div class="fa-stack categoryIcon" [ngSwitch]="category.name">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-user fa-stack-1x" *ngSwitchCase="dashboardViewCategories.user_created" aria-hidden="true"></i>
            <i class="fa fa-usd fa-stack-1x" *ngSwitchCase="dashboardViewCategories.quote_management" aria-hidden="true"></i>
            <i class="fa fa-user fa-stack-1x" *ngSwitchCase="dashboardViewCategories.record_management" aria-hidden="true"></i>
            <i class="fa fa-list-alt fa-stack-1x" *ngSwitchCase="dashboardViewCategories.global_views" aria-hidden="true"></i>
            <i class="fa fa-binoculars fa-stack-1x" *ngSwitchDefault aria-hidden="true"></i>
          </div>

          <div class="viewTitle">{{category.name}} ({{ category.associatedViews.length }})</div>
        </div>

        <div class="viewGroupResultsContainer" *ngIf="category.isExpanded">
          <div class="viewGroupResultRow bottomBorderShadow" *ngFor="let viewtype of category.associatedViews; let i = index">
            <div class="viewDetailsRow clickable" *ngIf="viewtype.view.editable" (click)="toggleViewSelected(viewtype)">
              <div class="checkboxContainer" [id]="'createView-viewLibrary-viewType' + i">
                <i *ngIf="!viewtype.isSelected && viewtype.selectable" class="fa fa-square-o uncheckedBox" aria-hidden="true"></i>
                <i *ngIf="viewtype.isSelected && viewtype.selectable" class="fa fa-check-square blue" aria-hidden="true"></i>
              </div>
              <div class="viewTitle">{{viewtype.view.name}}</div>
              <div class="viewColumns">
                <span class="availableHeaders" *ngFor="let column of viewtype.view.visibleColumns; let i = index">
                  <span *ngIf="viewtype?.view?.viewType === 'templateQuery'">{{ column | DisplayData:'searchEntityFilters':viewtype?.view?.templateContentQuery.entityType}}</span>
                  <span *ngIf="viewtype?.view?.viewType === 'contentQuery'">{{ column | DisplayData:'searchEntityFilters':viewtype?.view?.viewContentQuery[0]?.entityType}}</span>
                  <span *ngIf="i < viewtype.view.visibleColumns.length - 1">, </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="viewDetailsSection" *ngIf="showEditViewUI">
    <div>
      <div class="viewDetailsSection">
        <div class="sectionTitle bold">View Details</div>
        <div class="nameIconDetails">
          <div class="rightCol" id="createView-viewDetailsSection">
            <div class="editViewDetails">
              <div class="labelInput">
                <div class="label">Description</div>
                <p class="bold">Global Search with results for
                  <span id="createView-entityTypeText">{{ filterTerm | titlecase }},</span>
                  <span *ngFor="let field of newDashboardView?.viewContentQuery; let i = index">
                    <span *ngIf="i > 0 && i !== newDashboardView.viewContentQuery.length - 1">
                      {{ field.type | DisplayData:'searchEntityFilters':filterTerm }}: {{ field.value | apiParseError:field.type | checkDate |
                      amount:field.type }},
                    </span>

                    <span *ngIf="i > 0 && i === newDashboardView.viewContentQuery.length - 1">
                      {{ field.type | DisplayData:'searchEntityFilters':filterTerm }}: {{ field.value | apiParseError:field.type | checkDate |
                      amount:field.type }}.
                    </span>
                  </span>
                </p>
                <span *ngIf="!isEditedViewTemplateQuery" class="blue bold clickable link" (click)="navigateToSearch()">Edit
                  Search Criteria</span>
              </div>
            </div>
            <div class="nameDetails">
              <div class="labelInput">
                <div class="label">
                  <span class="red">*</span>Name View</div>
                <input class="nameInput" [(ngModel)]="newDashboardView.name">
              </div>
              <div class="maxCharacterNote" *ngIf="newDashboardView?.name?.length > maxViewNameCharacterLength">*Please limit
                your view name to {{maxViewNameCharacterLength}} characters</div>
            </div>
            <div class="iconDetails">
              <div class="label">
                <span class="red">*</span>Select Icon</div>
              <div class="viewIconSelection clickable" (click)="showIconSelectorModal()">
                <span class="iconDisplay" *ngIf="newDashboardView?.icon">
                  <i class={{newDashboardView?.icon}} aria-hidden="true"></i>
                </span>
                <span class="iconName">{{iconName}}</span>
                <span class="searchIcon">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="leftCol">
          </div>
        </div>
      </div>

      <div class="viewColumnsSection">
        <div class="sectionTitle bold">
          <label>Edit Columns</label>
        </div>
        <div class="columnDetails" *ngIf="!collapsePanel">
          <div class="columnCheckLabel clickable" (click)="toggleDisplayColumnCheck(column)" *ngFor="let column of dashboardViewColumnsArray">
            <div class="check">
              <i *ngIf="column.checked" class="fa fa-check-square blue" aria-hidden="true"></i>
              <i *ngIf="!column.checked" class="fa fa-square-o uncheckedBox" aria-hidden="true"></i>
            </div>

            <div class="columnLabel">{{column.label | DisplayData:'searchEntityFilters':filterTerm }}</div>
          </div>
        </div>
      </div>

      <div class="saveOptionsSection" *ngIf="showEditViewUI && newDashboardView">
        <div class="sectionTitle clickable bold">
          <label>Saving Options</label>
        </div>

        <div class="saveOptionCheckContainer">
          <div class="addToLibrary clickable" (click)="toggleAddToDashboardCheck()">
            <div class="check">
              <i *ngIf="shouldViewBeSavedToDashboard" class="fa fa-check-square blue" aria-hidden="true"></i>
              <i *ngIf="!shouldViewBeSavedToDashboard" class="fa fa-square-o uncheckedBox" aria-hidden="true"></i>
            </div>
            <div class="libraryLabel">Save to Dashboard</div>
          </div>

          <div class="addToLibrary clickable" *ngIf="!isViewInLibrary() && !isEditedViewTemplateQuery" (click)="toggleAddToLibraryCheck()">
            <div class="check">
              <i *ngIf="shouldViewBeSavedToLibrary" class="fa fa-check-square blue" aria-hidden="true"></i>
              <i *ngIf="!shouldViewBeSavedToLibrary" class="fa fa-square-o  uncheckedBox" aria-hidden="true"></i>
            </div>
            <div class="libraryLabel">Save to My Views</div>
          </div>

          <div class="addToLibrary clickable" *ngIf="isViewInLibrary()" (click)="toggleUpdateLibraryViewCheck()">
            <div class="check">
              <i *ngIf="shouldLibraryViewBeUpdated" class="fa fa-check-square blue" aria-hidden="true"></i>
              <i *ngIf="!shouldLibraryViewBeUpdated" class="fa fa-square-o  uncheckedBox" aria-hidden="true"></i>
            </div>
            <div class="libraryLabel">
              Update Library View
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

<app-icon-selector-modal *ngIf="isIconSelectorModalVisible" [fontAwesomeIcons]="fontAwesomeIcons" (closeIconSelectorModal)="hideIconSelectorModal()"
  (passIconToViewCreation)="receiveIconFromSelector($event)"></app-icon-selector-modal>