<div *ngIf="markupForm" class="exceptionMainPanel">
    <div class="exceptionBody">
        <!-- =========================== ACTIVE CARRIERS FORM =========================== -->
        <form class="activeCarrierForm" [formGroup]="exceptionGroup"
              [ngClass]="{ 'borderRightGray': pastItems.length }">

            <!-- =========================== ACTIVE CARRIERS HEADER =========================== -->
            <span class="exceptionBodyHeaderText">
                Active {{(isCarrier) ? 'Carriers': 'Accessorials'}}
                <span>
                    ({{ activeItems.length }})
                </span>
                <span class="clickable" (click)="doAddItemsModal()">
                    <i class="fa fa-plus-circle tooltip" aria-hidden="true"><span style="top: -30px; left: -45px" class="tooltiptext">Add Carrier</span></i>
                </span>
            </span>

            <!-- =========================== ACTIVE CARRIERS TITLE ROW =========================== -->
            <div class="activeCarrierFormRow activeCarrierFormTitleRow borderBottomGray">
                <span class="carrierNameCol">
                    {{(isCarrier) ? 'Carrier': 'Accessorial'}} Name
                </span>

                <span class="dateRangeCol">
                    Date Range
                </span>

                <span class="removeCarrierCol"></span>
            </div>

            <!-- =========================== (ACTUAL) ACTIVE CARRIERS FORM ROWS =========================== -->
            <div class="activeCarrierFormRow activeCarrierFormBodyRow borderBottomGray"
                 *ngFor="let carrier of activeItems; let i = index;" [formGroup]="carrier">
                <div class="carrierNameCol">
                    {{(isCarrier) ? carrier.value.SCAC : carrier.value.code }}
                </div>

                <div class="dateRangeCol">
                    <div class="dateRangePanel" [ngClass]="{ 'invalidDate':
                    (carrier.errors && carrier.errors!.dateRangeContradiction && !carrier.errors!.dateRangeContradiction.valid) ||
                    (carrier.controls.effective.errors && carrier.controls.effective.errors!.invalidDate) ||
                    (carrier.controls.end.errors && carrier.controls.end.errors!.invalidDate)}">
                        <input type="text" class="dateRangeStart" formControlName="effective">

                        <span class="dateRangeText">
                            to
                        </span>

                        <input type="text" class="dateRangeEnd" formControlName="end">

                        <span class="dateRangeIcon blue clickable" (click)="showDatePicker(i)">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>

                <div class="removeCarrierCol">
                    <span class="removeCarrierIcon clickable" (click)="removeExceptionItem(i)">
                        <i class="fa fa-trash"></i>
                    </span>
                </div>
            </div>

        </form>

        <!-- =========================== PAST CARRIERS PANEL =========================== -->
        <div class="pastCarriersPanel" [ngClass]="{
            'hasPastCarriers': pastItems.length,
            'noPastCarriers': !pastItems.length
            }">
            <span class="exceptionBodyHeaderText">
                Past {{(isCarrier) ? 'Carriers': 'Accessorials'}} ({{ pastItems.length }})
            </span>

            <!-- =========================== NO PAST CARRIERS PANEL =========================== -->
            <div class="noPastCarriersContainer">
                <span class="noPastCarriersMessage">
                    There are no Past {{(isCarrier) ? 'Carriers': 'Accessorials'}}
                </span>

                <span class="noPastCarriersIcon">
                    <i class="fa fa-inbox"></i>
                </span>
            </div>

            <!-- =========================== PAST CARRIERS SCROLLABLE CONTAINER =========================== -->
            <div class="pastCarriersBody">
                <div class="pastCarriersTitleRow">
                <span class="pastCarrierNameCol">
                    {{(isCarrier) ? 'Carrier': 'Accessorial'}} Name
                </span>
                    <span class="pastDateRangeCol">
                        Date Range
                    </span>
                </div>

                <div class="pastCarrierRowContainer scrollbar">
                    <div class="pastCarriersRow bottomBorderShadow"
                         *ngFor="let pastCarrier of pastItems">
                        <span class="pastCarrierNameCol">
                            {{(isCarrier) ? pastCarrier.value.SCAC : pastCarrier.value.code }}
                        </span>

                        <span class="pastDateRangeCol">
                            {{ pastCarrier.value.effective }} to {{ pastCarrier.value.end }}
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<app-custom-datepicker *ngIf="isDatePickerShown" [isDateRangePossible]="true" [title]="'Select Date Range'"
                       [dateFormatPattern]="markupDisplayDateFormat"
                       [inputDate]="selectedItemDateFormGroup.value.effective"
                       (closeDatePicker)="hideDatePicker($event)"
                       [dateFormatValidationPattern]="markupDisplayDateValidation"
                       [isDateRangeOnly]="true"
                       [inputEndDate]="selectedItemDateFormGroup.value.end"></app-custom-datepicker>

<app-add-active-carriers-modal *ngIf="isAddActiveItemsModalShown" [isCarrier]="isCarrier"
                               (closeAddActiveCarriersModal)="hideAddActiveItemsModal($event)"></app-add-active-carriers-modal>
