export class Document {
    id: number;
    downloadId: string;
    fileName: string; // 'dev-shiprite.png';
    fileSize: number;
    fileType: string; // 'PNG';
    attachedByName: string; // 'Markup%withMMAOnBuy';
    attachedDate: string; // 'November; 01 2017 10:22:11';
    createdBy: number; // userId
    description: string;
}
