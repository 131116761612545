import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class TourService {
    public tourPdfNameSubject: Subject<any> = new BehaviorSubject(null);

    constructor(private _router: Router) {
    }

    public startTour(pdfName) {
        this._router.navigate(['tour-guide']);
        this.tourPdfNameSubject.next(pdfName);
    }

    public stopTour() {
        this._router.navigate((['/']));
        this.tourPdfNameSubject.next(null);
    }

    public startDashboardTour() {
        this.startTour([
            '/assets/dashboard-tour/slide1.png',
            '/assets/dashboard-tour/slide2.png',
            '/assets/dashboard-tour/slide3.png',
            '/assets/dashboard-tour/slide4.png',
            '/assets/dashboard-tour/slide5.png',
            '/assets/dashboard-tour/slide6.png',
            '/assets/dashboard-tour/slide7.png',
            '/assets/dashboard-tour/slide8.png',
            '/assets/dashboard-tour/slide9.png',
            '/assets/dashboard-tour/slide10.png',
        ]);
    }

    public startClipboardTour() {
        this.startTour([
            '/assets/shortcuts-clipboard-tour/slide1.png',
            '/assets/shortcuts-clipboard-tour/slide2.png',
            '/assets/shortcuts-clipboard-tour/slide3.png',
            '/assets/shortcuts-clipboard-tour/slide4.png',
            '/assets/shortcuts-clipboard-tour/slide5.png',
            '/assets/shortcuts-clipboard-tour/slide6.png',
            '/assets/shortcuts-clipboard-tour/slide7.png',
            '/assets/shortcuts-clipboard-tour/slide8.png',
        ]);
    }

    public startShipmentsTour() {
        this.startTour([
            '/assets/shipments-tour/slide1.png',
            '/assets/shipments-tour/slide2.png',
            '/assets/shipments-tour/slide3.png',
            '/assets/shipments-tour/slide4.png',
            '/assets/shipments-tour/slide5.png',
            '/assets/shipments-tour/slide6.png',
            '/assets/shipments-tour/slide7.png',
            '/assets/shipments-tour/slide8.png',
            '/assets/shipments-tour/slide9.png',
            '/assets/shipments-tour/slide10.png',
            '/assets/shipments-tour/slide11.png',
            '/assets/shipments-tour/slide12.png',
            '/assets/shipments-tour/slide13.png',
            '/assets/shipments-tour/slide14.png',
            '/assets/shipments-tour/slide15.png',
            '/assets/shipments-tour/slide16.png',
        ]);
    }

    public startQuickQuoteTour() {
        this.startTour([
            '/assets/qq-tour/slide1.png',
            '/assets/qq-tour/slide2.png',
            '/assets/qq-tour/slide3.png',
            '/assets/qq-tour/slide4.png',
            '/assets/qq-tour/slide5.png',
            '/assets/qq-tour/slide6.png',
            '/assets/qq-tour/slide7.png',
            '/assets/qq-tour/slide8.png',
            '/assets/qq-tour/slide9.png',
            '/assets/qq-tour/slide10.png',
            '/assets/qq-tour/slide11.png',
            '/assets/qq-tour/slide12.png',
            '/assets/qq-tour/slide13.png',
            '/assets/qq-tour/slide14.png',
            '/assets/qq-tour/slide15.png',
            '/assets/qq-tour/slide16.png',
            '/assets/qq-tour/slide17.png',
            '/assets/qq-tour/slide18.png'
        ]);
    }
}
