<!-- ================================================================================== -->
<div class="filterPanelContainer">
  <div *ngIf="searchResponse.filterTerm">
    <p id="resultsFor">Results For</p>
    <div (click)="clearEntityFilter()" id="filterList">
      <p [ngSwitch]="searchResponse.filterTerm">
        <span *ngSwitchCase="entityTypeConstants.ALL">Records</span>
        <span *ngSwitchCase="entityTypeConstants.QUOTES">Quotes</span>
        <span *ngSwitchCase="entityTypeConstants.SHIPMENTS">Shipments</span>
        <span *ngSwitchCase="entityTypeConstants.INVOICES">Invoices</span>
        <span *ngSwitchCase="entityTypeConstants.USERS">Users</span>
        <span *ngSwitchCase="entityTypeConstants.MASTER_DATA_PRODUCTS">Products</span>
        <span *ngSwitchCase="entityTypeConstants.MASTER_DATA_CONTACTS">Contacts</span>
        <span *ngSwitchCase="entityTypeConstants.MASTER_DATA_SHIPPER">Shipper</span>
        <span *ngSwitchCase="entityTypeConstants.MASTER_DATA_MARKUPS">Markups</span>
      </p>
      <i class="fa fa-times blue clickable"></i>
    </div>
    <div class="queryList">
      <div *ngFor="let field of searchResponse.filteredSearchResults.fieldValues | searchCheckboxFilter:searchResponse.filterTerm:false"
        class="queryField">
        <div *ngIf="field.query" (click)="removeFieldQuery(field)">
          <p>{{ field.displayName }}</p>
          <div class="queryValue">
            <p>{{ field.query | apiParseError:field.fieldName | checkDate | amount:field.fieldName }}</p>
            <i class="blue fa fa-times"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span id="filterHeader">
    <p>Filter By</p>
  </span>

  <!-- ================================================================================== -->
  <div *ngIf="!searchResponse.filterTerm" class="entityFilterChoices">
    <span id="filterSubHeader">
      <p>Entity</p>
    </span>
    <ul>
      <div class="entityCheck" *ngFor="let entity of entityTypes" (click)="selectEntityFilter(entity)" [ngSwitch]="entity">
        <li *ngSwitchCase="entityTypeConstants.ALL">
          <i class="fa fa-circle-o blue" aria-hidden="true"></i>
          <i class="fa fa-circle" aria-hidden="true"></i>
          <span>Records</span>
        </li>

        <li *ngSwitchCase="entityTypeConstants.QUOTES">
          <i class="fa fa-circle-o blue" aria-hidden="true"></i>
          <i class="fa fa-circle" aria-hidden="true"></i>
          <span>Quotes</span>
        </li>

        <li *ngSwitchCase="entityTypeConstants.SHIPMENTS">
          <i class="fa fa-circle-o blue" aria-hidden="true"></i>
          <i class="fa fa-circle" aria-hidden="true"></i>
          <span>Shipments</span>
        </li>

        <li *ngSwitchCase="entityTypeConstants.INVOICES">
          <i class="fa fa-circle-o blue" aria-hidden="true"></i>
          <i class="fa fa-circle" aria-hidden="true"></i>
          <span>Invoices</span>
        </li>

        <li *ngSwitchCase="entityTypeConstants.USERS">
          <i class="fa fa-circle-o blue" aria-hidden="true"></i>
          <i class="fa fa-circle" aria-hidden="true"></i>
          <span>Users</span>
        </li>

        <li *ngSwitchCase="entityTypeConstants.MASTER_DATA_PRODUCTS">
          <i class="fa fa-circle-o blue" aria-hidden="true"></i>
          <i class="fa fa-circle" aria-hidden="true"></i>
          <span>Products</span>
        </li>

        <li *ngSwitchCase="entityTypeConstants.MASTER_DATA_CONTACTS">
          <i class="fa fa-circle-o blue" aria-hidden="true"></i>
          <i class="fa fa-circle" aria-hidden="true"></i>
          <span>Contacts</span>
        </li>

        <li *ngSwitchCase="entityTypeConstants.MASTER_DATA_SHIPPER">
          <i class="fa fa-circle-o blue" aria-hidden="true"></i>
          <i class="fa fa-circle" aria-hidden="true"></i>
          <span>Shipper</span>
        </li>

        <li *ngSwitchCase="entityTypeConstants.MASTER_DATA_MARKUPS">
          <i class="fa fa-circle-o blue" aria-hidden="true"></i>
          <i class="fa fa-circle" aria-hidden="true"></i>
          <span>Markups</span>
        </li>
      </div>
      <span id="filterZip">
        <p>Zip Code</p>
      </span>
      <input type="text" id="zipInput">
    </ul>
  </div>

  <!-- ================================================================================== -->
  <div *ngIf="searchResponse.filterTerm">
    <div *ngFor="let field of searchResponse.filteredSearchResults.fieldValues | searchCheckboxFilter:searchResponse.filterTerm:false">
      <p class="fieldDisplayName">
        {{ field.displayName }}
      </p>

      <div *ngIf="field.isDate" class="overlayField" (click)="showDatePicker(field)">
        <input type="text" class="fieldInput">
        <i class="fa fa-calendar searchIcon blue" aria-hidden="true"></i>
      </div>

      <div *ngIf="field.uniqueValues.length >= 15 && !field.isDate" class="overlayField">
        <input type="text" class="fieldInput" (click)="displayModal(field)">
        <i class="fa fa-search searchIcon blue" aria-hidden="true"></i>
      </div>

      <div class="entityFieldSelectPanel" *ngIf="field.uniqueValues.length < 15 && !field.isDate">
        <select class="fieldDropdown form-control" [(ngModel)]="field.query" (change)="updateFilteredSearchResults()">
          <option></option>
          <option class="fieldDropdown" *ngFor="let value of field.uniqueValues" [value]="value">
            {{ value | apiParseError:field.fieldName | checkDate | amount:field.fieldName }}
          </option>
        </select>

        <span class="entityFieldSelectChevron blue clickableCaret">
          <i class="fa fa-caret-down" aria-hidden="true"></i>
        </span>
      </div>
    </div>

    <div *ngFor="let field of searchResponse.filteredSearchResults.fieldValues | searchCheckboxFilter:searchResponse.filterTerm:true">
      <div class="contactFieldCheckboxPanel" *ngIf="searchResponse.filterTerm === entityTypeConstants.MASTER_DATA_CONTACTS">
        <span class="contactFieldCheckboxIcon clickable" (click)="toggleEntityFilterCheckbox(field)">
          <i class="fa fa-square-o" *ngIf="!field.query || field.query === searchCriteriaValueConstants.FALSE"></i>
          <i class="fa fa-check-square" *ngIf="field.query && field.query === searchCriteriaValueConstants.TRUE"></i>
        </span>

        <span class="contactFieldDisplayName">
          {{ field.displayName }}
        </span>
      </div>
    </div>
  </div>

  <!-- ================================================================================== -->
</div>
<app-filter-modal [field]="modalField" (updateField)="closeModal()" *ngIf="showModal"></app-filter-modal>
<app-custom-datepicker *ngIf="isDatePickerShown" [isDateRangePossible]="true" [inputDate]="defaultDatePickerDate" [title]="'Date Range Filter'"
  (closeDatePicker)="hideDatePicker($event)" [dateFormatPattern]="entityFilterDateFormatPattern" [dateFormatValidationPattern]="entityFilterDateValidationFormatPattern"></app-custom-datepicker>
