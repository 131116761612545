<div class="mainBody padLeft padRight">
  <!--====================================================== NMFC HEADER ======================================================-->
  <div class="nmfcSearchHeader">
    <div class="nmfcSearchHeaderTop">NMFC Search</div>
    <div class="breadcrumbs">
      Dashboard &gt; Global Search &gt; Search Results &gt;
      <span class="breadCrumbsFinal">&nbsp;NMFC Search</span>
    </div>
  </div>
  <div class="separator">
    <hr/>
  </div>

  <!--================================================= NMFC SEARCH PANEL ======================================================-->
  <form class="nmfcSearchPanel" (ngSubmit)="searchNmfc()">
    <input autofocus type="text" class="nmfcSearchInputTag" [(ngModel)]="nmfcSearchQuery" name="nmfcInputTag">
    <span class="searchIcon clickable" (click)="searchNmfc()">
      <i class="fa fa-search"></i>
    </span>
  </form>

  <!--===================================== PERFORM SEARCH PROMPT ================================================-->
  <div class="performSearchPrompt" *ngIf="!matchedNmfcGroups.length && !isNoResultsPromptDisplayed">
    <div class="performSearchPromptText">
      Perform your search above
    </div>
    <div class="performSearchPromptIconHolder">
      <i class="fa fa-smile-o"></i>
    </div>
  </div>

  <!--===================================== NO RESULTS PROMPT ================================================-->
  <div class="performSearchPrompt" *ngIf="isNoResultsPromptDisplayed">
    <div class="performSearchPromptText">
      No results found.
    </div>
    <div class="performSearchPromptIconHolder">
      <i class="fa fa-frown-o"></i>
    </div>
  </div>

  <!--================================================= NMFC SEARCH RESULTS ======================================================-->
  <div class="nmfcResultGroupContainer" *ngFor="let group of matchedNmfcGroups">
    <!--============================================== group header ========================================-->
    <div class="nmfcResultGroupHeader clickable" (click)="toggleGroupIsExpanded(group)">
      <span class="nmfcResultGroupHeaderIcon">
        <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="group.isExpanded"></i>
        <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="!group.isExpanded"></i>
      </span>
      <div class="nmfcResultGroupHeaderText">
        {{ group.groupTitle }} ({{ group.nmfcItems.length }})
      </div>
    </div>

    <!--============================================ actual result =============================================-->
    <div class="nmfcResultRowContainer scrollbar" *ngIf="group.isExpanded">
      <div class="nmfcResultRow" *ngFor="let nmfcResult of group.nmfcItems">
        <div class="nmfcResultRowTextPanel">
          <span class="nmfcResultLabel">
            Item ID
          </span>
          <span class="nmfcResultText">
            {{ nmfcResult.itemno }}
          </span>
        </div>
        <div class="nmfcResultRowTextPanel">
          <span class="nmfcResultLabel">
            Class
          </span>
          <span class="nmfcResultText" *ngIf="nmfcResult.class">
            {{ nmfcResult.displayclass }}
          </span>
          <span class="nmfcResultText" *ngIf="!nmfcResult.class">
            -
          </span>
        </div>
        <div class="nmfcResultRowTextPanel">
          <span class="nmfcResultLabel">
            Description
          </span>
          <span class="nmfcResultText">
            {{ nmfcResult.itemdescription }}
          </span>
        </div>
      </div>
    </div>

  </div>
</div>
