import {Injectable} from '@angular/core';
import {AdminButtonPanelConfig, AdminButtonPanelFunctionRegistrations} from '../models/adminButtonPanelConfig';

@Injectable()
export class AdminButtonPanelService {
    adminButtonPanelConfig : AdminButtonPanelConfig = {
        activateSaveButton: false,
        activateCustomButton: true,
        activateCustomButton2: true,
        showSaveButton: false,
        showCancelButton: false,
        showCustomButton: false,
        showCustomButton2: false,
        customButtonIcon: 'fa-exclamation',
        customButton2Icon: 'fa-exclamation',
        customButtonLabel: 'Unknown',
        customButton2Label: 'Unknown',
        registration: new AdminButtonPanelFunctionRegistrations()
    }

    constructor() {}

    public register(registrations: AdminButtonPanelFunctionRegistrations) {
        this.adminButtonPanelConfig.registration = registrations;
    }

    public showSaveCancelButtons(val = true) {
        this.adminButtonPanelConfig.showSaveButton = val;
        this.adminButtonPanelConfig.showCancelButton = val;
    }

    public showSaveButton(val = true) {
        this.adminButtonPanelConfig.showSaveButton = val;
    }

    public showCancelButton(val = true) {
        this.adminButtonPanelConfig.showCancelButton = val;
    }

    public showCustomButton(val = true) {
        this.adminButtonPanelConfig.showCustomButton = val;
    }

    public showCustomButton2(val = true) {
        this.adminButtonPanelConfig.showCustomButton2 = val;
    }

    public setCustomButtonLabel(val = 'Unknown') {
        this.adminButtonPanelConfig.customButtonLabel = val;
    }

    public setCustomButton2Label(val = 'Unknown') {
        this.adminButtonPanelConfig.customButton2Label = val;
    }

    public setCustomButtonIcon(val = 'fa-exclamation') {
        this.adminButtonPanelConfig.customButtonIcon = val;
    }

    public setCustomButton2Icon(val = 'fa-exclamation') {
        this.adminButtonPanelConfig.customButton2Icon = val;
    }

    public activateSaveButton(val = true) {
        this.adminButtonPanelConfig.activateSaveButton = val;
    }

    public activateCustomButton(val = true) {
        this.adminButtonPanelConfig.activateCustomButton = val;
    }

    public activateCustomButton2(val = true) {
        this.adminButtonPanelConfig.activateCustomButton2 = val;
    }

    public saveEvent() {
        this.adminButtonPanelConfig.registration.saveEvent();
    }

    public cancelEvent() {
        this.adminButtonPanelConfig.registration.cancelEvent();
    }

    public customEvent() {
        this.adminButtonPanelConfig.registration.customEvent();
    }

    public customEvent2() {
        this.adminButtonPanelConfig.registration.custom2Event();
    }
}
