import {
    Component, ContentChild,
    EventEmitter,
    Input, OnInit,
    Output, TemplateRef,
} from '@angular/core';

@Component({
    selector: 'app-accordian',
    styleUrls: ['./app-accordian.component.scss'],
    templateUrl: './app-accordian.component.html',
})
export class AppAccordianComponent implements OnInit {
    @Input()
    public sections: Array<string> = [];
    @Input()
    public sectionStatus: Array<boolean> = [];
    @Input()
    public autoClose = false;
    @Output()
    public click = new EventEmitter();
    @ContentChild('sectionRenderer', {static: false})
    sectionRenderer: TemplateRef<any>;
    @ContentChild('headerRenderer', {static: false})
    headerRenderer: TemplateRef<any>;

    constructor() {
    }

    public ngOnInit() {
        let section;

        if (!this.sectionStatus) {
            for (section of this.sections) {
                this.sectionStatus.push(false);
            }
        }
    }

    public clicked(event, i) {
        let j;

        this.sectionStatus[i] = !this.sectionStatus[i];
        if (this.autoClose) {
            for (j=0; j<this.sections.length; j++) {
                if (j !== i) {
                    this.sectionStatus[j] = false;
                }
            }
        }
    }
}
