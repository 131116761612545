import {Component, OnInit} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import * as MasterdataConstants from '../../../constants/masterData.constants';
import {CarrierMasterData} from '../../../models/carrier.masterData';
import {CarrierMasterDataRequest} from '../../../models/carrier.masterData.request';
import {PreviousRouteService} from '../../../services/previous-route.service';
import {SignalsService} from "../../../services/signals.service";
import {CarriersMasterDataService} from "../../../services/carriers.masterdata.service";

@Component({
    selector: 'app-master-data-carriers',
    // template: ``,
    templateUrl: './carriers.component.html',
    styleUrls: ['./carriers.component.scss']
})
export class MasterDataCarriersComponent implements OnInit {
    public masterDataCarrierForm: UntypedFormGroup;
    public masterDataCarrier: CarrierMasterData;
    private carrierScac;
    public rootMasterDataUrl: string = MasterdataConstants.MASTER_DATA_ROOT_URL;
    public areMasterDataBreadcrumbsDisplayed: boolean = false;
    private rootGlobalSearchUrl: string = '/search';
    public areGlobalSearchResultsBreadcrumbsDisplayed: boolean = false;

    constructor(
        private _fb: UntypedFormBuilder,
        private _signalsService: SignalsService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _previousRouteService: PreviousRouteService,
        private _carriersMasterDataService: CarriersMasterDataService,
    ) {
    }

    ngOnInit() {
        if (this._route.snapshot.paramMap.get('scac')) {
            this.carrierScac = this._route.snapshot.paramMap.get('scac');
            if ((!this.masterDataCarrier) || (this.carrierScac !== this.masterDataCarrier.scac)) {
                this._carriersMasterDataService.getMasterDataCarrier(this.carrierScac).subscribe(res => {
                    this.masterDataCarrier = res;
                    this.assignComponentProperties();
                });
            }
        } else {
            this.masterDataCarrier = null;
            this.assignComponentProperties();
        }

        const prevRouteUrl = this._previousRouteService.getPreviousUrl();

        if (prevRouteUrl === this.rootMasterDataUrl) {
            this.areMasterDataBreadcrumbsDisplayed = true;
        } else if (prevRouteUrl === this.rootGlobalSearchUrl) {
            this.areGlobalSearchResultsBreadcrumbsDisplayed = true;
        }

        this.assignComponentProperties();
    }

    private assignComponentProperties(): void {
        if (this.masterDataCarrier) {
            this.masterDataCarrierForm = this._fb.group({
                name: this.masterDataCarrier.carrier_name,
                scac: this.masterDataCarrier.scac,
            });
        } else {
            this.masterDataCarrierForm = this._fb.group({
                name: '',
                scac: '',
            });
        }
    }

    private prepareCarrierFormUpdate(): { carrier_name: string, scac: string } {
        let req;
        req = {
            carrier_name: this.masterDataCarrierForm.value.name,
            scac: this.masterDataCarrierForm.value.scac
        }
        return req;
    }

    public saveChanges(): void {
        if (!this.masterDataCarrierForm.dirty) {
            return;
        }
        const masterDataCarrierRequest: CarrierMasterDataRequest = this.prepareCarrierFormUpdate();
        if (!this.masterDataCarrier) {
            this._carriersMasterDataService.createMasterDataCarrier(masterDataCarrierRequest).subscribe(res => {
                this._carriersMasterDataService.getMasterDataCarriersList().subscribe();
            });
        } else {
            this._carriersMasterDataService.updateMasterDataCarrier(masterDataCarrierRequest).subscribe(res => {
                this._carriersMasterDataService.getMasterDataCarriersList().subscribe();
            });
        }
    }

    public revertCarrierChanges() {
        this.assignComponentProperties();
    }

    public navigateToDashboard(): void {
        this._router.navigate(['']);
    }

    public navigateToSearch(): void {
        this._router.navigate(['search']);
    }

    public navigateToMasterDataManagement(): void {
        this._router.navigate(['masterData']);
        this._signalsService.updateAppState({'masterdata.activeSection': ''});
    }

    public navigateToMasterDataCarrierList(): void {
        this._router.navigate(['masterData']);
        this._signalsService.updateAppState({'masterdata.activeSection': MasterdataConstants.MASTER_DATA_SECTION_PATHS.carriers});
    }

}
