<div class="reportsInvoiceListComponent">
    <!-- =========================== INVOICE REPORT LIST HEADER =========================== -->

    <div class="invoicesReportHeader">
        <div class="invoicesReportHeaderTextPanel">
            <span class="invoicesReportHeaderTextMain">
                Reporting
            </span>
            <span class="invoicesReportHeaderBreadCrumbs">
                <span class="clickable" (click)="navigateToInvoiceSummary()">Reporting &gt;</span>
                <span class="invoicesReportHeaderBreadCrumbsFinal">Invoice Detail</span>
            </span>
        </div>
    </div>

    <!-- =========================== INVOICE REPORT LIST BODY =========================== -->
    <div class="reportInvoicesListContainer">
        <div class="reportInvoicesListHeaderRow">
            <div class="firstRowItem">BOL</div>
            <div class="headerItem">Customer</div>
            <div class="headerItem">Paid $</div>
            <div class="headerItem">Pro Number</div>
            <div class="headerItem">Weight</div>
            <div class="headerItem">Miles</div>
            <div class="headerItem">Ship Date</div>
            <div class="headerItem">Seq #</div>
            <div class="headerItem">Shipper Zip</div>
            <div class="headerItem">Consignee Zip</div>
            <div class="headerItem">Class</div>
            <div class="headerItem">Carrier</div>
        </div>

        <div class="reportInvoicesDetailContainer">
            <div class="reportInvoicesDetailRow clickable borderBottomShadow" (click)="navigateToInvoice(ir)" *ngFor="let ir of invoiceDetailReports">
                <div class="firstRowItem">{{ ir?.BOL }}</div>
                <div class="headerItem">{{ ir?.cust_no }}</div>
                <div class="rowDetailItem">${{ ir?.amountPaid | number }}</div>
                <div class="rowDetailItem">{{ ir?.proNumber }}</div>
                <div class="rowDetailItem">{{ ir?.weight | number }}</div>
                <div class="rowDetailItem">{{ ir?.miles | number }}</div>
                <div class="rowDetailItem">{{ ir?.shipDate }}</div>
                <div class="rowDetailItem">{{ ir?.seq_no }}</div>
                <div class="rowDetailItem">{{ ir?.shipperZip }}</div>
                <div class="rowDetailItem">{{ ir?.consigneeZip }}</div>
                <div class="rowDetailItem">{{ ir?.class }}</div>
                <div class="rowDetailItem">{{ ir?.carrier }}</div>
            </div>
        </div>
    </div>
</div>
