<div class="reportsShipmentListComponent">
    <!-- =====================================SHIPMENT REPORT LIST HEADER SECTION ===================================== -->
    <div class="shipmentsReportHeader">
        <div class="shipmentsReportHeaderTextPanel">
            <span class="shipmentsReportHeaderTextMain">
                Reporting
            </span>
            <span class="shipmentsReportHeaderBreadCrumbs">
                <span class="clickable" (click)="navigateToShipmentSummary()"> Reporting &gt; </span>
                <span class="shipmentsReportHeaderBreadCrumbsFinal">Shipment Detail </span>
            </span>
        </div>

        <div class="shipmentsReportListHeaderButtonPanel">
            <div class="btn-outline clickable" (click)="displayInProgressNotification()">
                <i class="fa fa-cogs" aria-hidden="true"></i>
                Change Report Parameters
            </div>

            <div class="btn-outline clickable" (click)="displayInProgressNotification()">
                <i class="fa fa-share-square-o" aria-hidden="true"></i>
                Export
            </div>
        </div>
    </div>
    <!-- =======================================================SHIPMENT REPORT DETAIL BODY SECTION=========================================================== -->
    <div class="shipmentsReportListContainer">
        <div class="shipmentsReportListHeaderRow">
            <div class="firstRowItem">BOL</div>
            <div class="headerItem">Carrier</div>
            <div class="headerItem">Carrier Name</div>
            <div class="headerItem">Class</div>
            <div class="headerItem">Consignee</div>
            <div class="headerItem">Consignee City</div>
            <div class="headerItem">Consignee State</div>
            <div class="headerItem">Consignee Zip</div>
            <div class="headerItem">Delivery Date</div>
            <div class="headerItem">Miles</div>
            <div class="headerItem">Pro Number</div>
            <div class="headerItem">Ship Date</div>
            <div class="headerItem">Shipper</div>
            <div class="headerItem">Shipper City</div>
            <div class="headerItem">Shipper Country</div>
            <div class="headerItem">Shipper State</div>
            <div class="headerItem">Shipper Zip</div>
            <div class="headerItem">Weight</div>
        </div>

        <div class="shipmentsReportListContainer">
            <div class="shipmentsReportDetailRow clickable borderBottomShadow" (click)="navigateToRecord(shipDetail)" *ngFor="let shipDetail of shipmentReportDetailList">
                <div class="firstRowItem">{{ shipDetail?.BOL }}</div>
                <div class="rowDetailItem">{{ shipDetail?.carrier | titlecase}}</div>
                <div class="rowDetailItem">{{ shipDetail?.carrierName | titlecase}}</div>
                <div class="rowDetailItem">{{ shipDetail?.class}}</div>
                <div class="rowDetailItem">{{ shipDetail?.consignee | titlecase }}</div>
                <div class="rowDetailItem">{{ shipDetail?.consigneeCity | titlecase}}</div>
                <div class="rowDetailItem">{{ shipDetail?.consigneeState }}</div>
                <div class="rowDetailItem">{{ shipDetail?.consigneeZip }}</div>
                <div class="rowDetailItem">{{ shipDetail?.deliveryDate | date:'yyyy-MM-dd' }}</div>
                <div class="rowDetailItem">{{ shipDetail?.miles | number }}</div>
                <div class="rowDetailItem">{{ shipDetail?.proNumber }}</div>
                <div class="rowDetailItem">{{ shipDetail?.shipDate | date:'yyyy-MM-dd' }}</div>
                <div class="rowDetailItem">{{ shipDetail?.shipper | titlecase }}</div>
                <div class="rowDetailItem">{{ shipDetail?.shipperCity | titlecase}}</div>
                <div class="rowDetailItem">{{ shipDetail?.shipperCountry}}</div>
                <div class="rowDetailItem">{{ shipDetail?.shipperState}}</div>
                <div class="rowDetailItem">{{ shipDetail?.shipperZip}}</div>
                <div class="rowDetailItem">{{ shipDetail?.weight | number }}</div>
            </div>
        </div>
    </div>

</div>
