<div class="overlay">
    <div class="terminalDetailsModal">
        <!-- =========================== MODAL HEADER =========================== -->
        <div class="modalHeader">
            <div>{{getCarrierName()}}</div>
            <div class="closeModalIconContainer clickable">
                <i class="fa fa-times closeModalIcon" (click)="cancelClicked()"></i>
            </div>
        </div>

        <div class="originTerminal borderBottomDouble">
            Origin Terminal
        </div>
        <div class="destinationTerminal borderBottomDouble">
            Destination Terminal
        </div>

        <ng-container *ngIf="bookingOptions && (bookingOptions.length > 0)">
            <div class="originTerminal">
                <app-address [terminal]="getOriginTerminal()"></app-address>
            </div>

            <div class="destinationTerminal">
                <app-address [terminal]="getDestinationTerminal()"></app-address>
            </div>
        </ng-container>
        <ng-container *ngIf="!bookingOptions && (rates.length > 0)">
            <div class="originTerminal">
                <app-address [rateTerminal]="getOriginTerminal()"></app-address>
            </div>

            <div class="destinationTerminal">
                <app-address [rateTerminal]="getDestinationTerminal()"></app-address>
            </div>
        </ng-container>
    </div>
</div>
