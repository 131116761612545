import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as DateConstants from '../../constants/datetime.constants';

@Component({
    selector: 'app-date',
    styleUrls: ['./app-date.component.scss'],
    templateUrl: './app-date.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppDateComponent,
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: AppDateComponent,
            multi: true
        }
    ]
})
export class AppDateComponent implements OnChanges, ControlValueAccessor {
    @Input()
    public allowEmpty = false;
    @Input()
    public readOnlyDate = false;
    @Input()
    public readOnlyEndDate = false;
    @Input()
    public isRange = false;
    @Input()
    public rangeSeparator = ' - ';
    @Input()
    public title;
    @Input()
    public allowIndefinately = false;
    @Input()
    public displayDateValidation;
    @Input()
    public displayDateFormat;
    @Input()
    public tabindex = 0;
    @Output()
    public dialogShown = new EventEmitter();
    @ViewChild('firstDateElement') private focusElement: ElementRef;

    public isDatePickerShown = false;
    public value: any = {};
    private validateFn: Function;

    constructor() {}

    public ngOnChanges(changes) {
        if (changes) {
            this.validateFn = (c: UntypedFormControl) => this.validateDate();
        }
    }

    public validate(c: UntypedFormControl) {
        return this.validateFn(c);
    }

    public writeValue(value) {
        this.value = value;
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public updateDate(value) {
        this.value.date = value;
        this.valueChanged(this.value);
    }

    public updateEndDate(value) {
        this.value.endDate = value;
        this.valueChanged(this.value);
    }

    public showDatePicker() {
        this.isDatePickerShown = true;
        this.dialogShown.emit(true);
    }

    public hideDatePicker($event) {
        this.isDatePickerShown = false;
        this.dialogShown.emit(false);
        this.focusElement.nativeElement.focus();
        if ($event.selectedDate !== undefined) {
            if (this.readOnlyDate) {
                this.value.endDate = $event.selectedDate;
            } else {
                this.value.date = $event.selectedDate;
            }
        }
        if ($event.selectedEndDate !== undefined) {
            this.value.endDate = $event.selectedEndDate;
        }
        this.valueChanged(this.value);
    }

    private valueChanged = (_: any) => {};

    private validateDate() {
        if (this.allowEmpty && (this.value.date === '') && (!this.isRange || (this.value.endDate === ''))) {
            return null;
        }
        if (!this.value.date.match(this.displayDateValidation)) {
            return { invalidDate: true };
        } else {
            if (this.isRange) {
                if (this.allowIndefinately) {
                    if (this.value.endDate.toLowerCase() === DateConstants.DATE_VALUE_INDEFINITELY_display.toLowerCase()) {
                        return null;
                    }
                }
                if (!this.value.endDate.match(this.displayDateValidation)) {
                    return { invalidDate: true };
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }
}
