import {Injectable} from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { MASTER_DATA_SECTIONS, RECORD_SECTIONS, USER_TOKEN_KEY } from '../constants/constants';
import * as Constants from '../constants/constants';
import { Defaults, LoginSettings, Permissions } from '../models/loginSettings';
import { User } from '../models/user';
import { LoggingService, LogLevels } from '../services/logging.service';
import {SignalsService} from "../services/signals.service";
import {NotificationService} from "../services/notification.service";

@Injectable()
export class UserHelper {

    constructor(
        private _loggingService: LoggingService,
        private _notificationService: NotificationService,
        private _signalsService: SignalsService,
    ) {
    }

    // ====================================================================================
    // =========================== USER PROFILE HELPER METHODS  ===========================
    // ====================================================================================
    public getUserProfile(): User {
        let user: User;
        user = this._signalsService.userSignal();
        if (!user) {
            this._loggingService.sendLogMessage(LogLevels.ERROR, 'No user data found.');
            if (!this._signalsService.loggedOutSignal()) {
                this._notificationService.notifyError({ title: 'User Profile', message: 'No user data found.' });
            }
        }
        return user;
    }

    public getUserId(): number {
        let userId = null;
        const currentUser = this.getUserProfile();
        if (currentUser) {
            userId = currentUser.userID;
            if (!userId) {
                this._loggingService.sendLogMessage(LogLevels.ERROR, `User profile: ${JSON.stringify(currentUser)} does not contain a userId property.`);
                if (!this._signalsService.loggedOutSignal()) {
                    this._notificationService.notifyError({ title: 'User Id', message: 'Logged in user does not contain a user id' });
                }
            }
        }
        return userId;
    }

    public getUserCustomer(): string {
        const userProfile = this.getUserProfile();
        const customer = userProfile ? userProfile.customer : 'TEST';
        return customer;
    }

    public isAuthorizedApiUser() {
        const userProfile = this.getUserProfile();
        return !!userProfile.isAuthorizedApiUser;
    }

    public integrationTokenExists() {
        const userProfile = this.getUserProfile();
        return !!(userProfile.isAuthorizedApiUser && userProfile.integrationTokenExists);
    }
    public isInternalUser(user: User): boolean {
        // how to check for internal users???
        if (user && user.customer === 'CTS') {
            return true;
        } else {
            return false;
        }
    }

    // ====================================================================================
    // =========================== USER SETTINGS HELPER METHODS ===========================
    // ====================================================================================
    public getUserSettings(): LoginSettings {
        return this._signalsService.loginSettingsSignal();
    }

    public getUserDefault(d: string): string {
        const userSettings: LoginSettings = this.getUserSettings();

        if (!userSettings) {
            return null;
        }

        const defaults: Array<Defaults> = userSettings.defaults;
        if (!defaults || !defaults[0]) {
            return null;
        }

        const value = defaults[0][d];
        return value;
    }

    // ====================================================================================
    // ======================== USER PERMISSIONS HELPER METHODS ===========================
    // ====================================================================================
    public getUserPermissions(): Permissions {
        const userSettings: LoginSettings = this.getUserSettings();
        if (!userSettings || !userSettings.permissions || !userSettings.permissions.length || !userSettings.permissions[0]) {
            return null; // no permissions for this user, short-circuit and report NO ACCESS!
        }

        const p: Permissions = userSettings.permissions[0];
        return p;
    }

    public canAccess(accessDetails: { requestedAccess: number, thingToBeAccessed: string, permissions: LoginSettings, accessPermissionLevel: string }): boolean { // isOwner: boolean, isGroup: boolean
        const userAccessRights = this.getAccessLevelFromUserPermission(accessDetails.thingToBeAccessed, accessDetails.permissions);
        this._loggingService.sendLogMessage(LogLevels.DEBUG, `canAccess(${accessDetails.requestedAccess}, ${accessDetails.thingToBeAccessed}, ${JSON.stringify(accessDetails.permissions)})  => ${userAccessRights}`); // ${isOwner}, ${isGroup}
        const userAccessRight: number = this.getSpecificAccessRight(userAccessRights, accessDetails.accessPermissionLevel);
        return this.canAccessIt(accessDetails.requestedAccess, userAccessRight);
    }

    private getSpecificAccessRight(userAccessRight: string, accessPermissionLevel: string): number {
        if (!userAccessRight) {
            return 0;
        }

        const userLevelAccessRight = +userAccessRight[0];
        const groupLevelAccessRight = +userAccessRight[1];
        const globalLevelAccessRight = +userAccessRight[2];

        if (!accessPermissionLevel) {
            return userLevelAccessRight;
        }

        switch (accessPermissionLevel) {
            case Constants.ACCESS_PERMISSION_LEVELS.user:
                return userLevelAccessRight;

            case Constants.ACCESS_PERMISSION_LEVELS.group:
                return groupLevelAccessRight;

            case Constants.ACCESS_PERMISSION_LEVELS.global:
                return globalLevelAccessRight;

            default:
                this._loggingService.sendLogMessage(LogLevels.DEBUG, `accessPermissionLevel(${accessPermissionLevel}, ${userAccessRight})`);
                return userLevelAccessRight;
        }
    }

    private canAccessIt(requestedAccess: number, userAccessRight: number): boolean {
        const result: boolean = (requestedAccess <= userAccessRight);
        this._loggingService.sendLogMessage(LogLevels.DEBUG, `canAccessIt(${requestedAccess}, ${userAccessRight}) => ${result}`);
        return result;
    }

    public getAccessLevelFromUserPermission(thingToBeAccessed: string, userSettings: LoginSettings): string {
        this._loggingService.sendLogMessage(LogLevels.DEBUG, `getAccessLevelFromUserPermission(thingToBeAccessed => ${thingToBeAccessed}, userSettings => ${JSON.stringify(userSettings)}`);
        if (!userSettings || !userSettings.permissions || !userSettings.permissions.length || !userSettings.permissions[0]) {
            return '000'; // no permissions for this user, short-circuit and report NO ACCESS!
        }

        const p = userSettings.permissions[0];

        switch (thingToBeAccessed) {
            case RECORD_SECTIONS.Quote:
                return p.quotes;

            case RECORD_SECTIONS.GeneralRef:
                return p.shipments;

            case RECORD_SECTIONS.Shipper:
                return p.shipments;

            case RECORD_SECTIONS.Consignee:
                return p.shipments;

            case RECORD_SECTIONS.ProductList:
                return p.shipments;

            case RECORD_SECTIONS.CarrierRef:
                return p.shipments;

            case RECORD_SECTIONS.Invoicing:
                return p.shipments;

            case RECORD_SECTIONS.AttachedFiles:
                return p.shipments;

            case RECORD_SECTIONS.Summary:
                return p.shipments;

            case MASTER_DATA_SECTIONS.CARRIER:
                return p.carrier;

            case MASTER_DATA_SECTIONS.CONTACTS:
                return p.contacts;

            case MASTER_DATA_SECTIONS.MARKUPS:
                return p.markups;

            case MASTER_DATA_SECTIONS.PRODUCTS:
                return p.products;

            default:
                if (p && p.hasOwnProperty(thingToBeAccessed)) {
                    return p[thingToBeAccessed];
                } else {
                    return '000';
                }
        }
    }

    public shouldBillToContactBeRetrieved(): boolean {
        const loginSettings: LoginSettings = this.getUserSettings();

        if (loginSettings) {
            return this.canAccess({ requestedAccess: Constants.ACCESS_TYPES.read, thingToBeAccessed: 'shipments', permissions: loginSettings, accessPermissionLevel: Constants.ACCESS_PERMISSION_LEVELS.user });
        }

        return false;
    }

    public isAvailableForTesting() {
        // User id 8054 is the Prospective Client which is used for testing and has fuller setup than internal admin
        // Customer NCSAMP is a Vanguard test account
        return this.getUserId() === 8058 ||
            this.getUserId() === 8059 ||
            this.getUserId() === 14311 ||
            this.getUserId() === 8020 ||
            this.getUserId() === 8054 ||
            this.getUserId() === 11513 ||
            this.getUserCustomer().toUpperCase() === 'CTLLAX' ||
            this.getUserCustomer().toUpperCase() === 'CTLNYC' ||
            this.getUserCustomer().toUpperCase() === 'PRIFS' ||
            this.getUserCustomer().toUpperCase() === 'DAMCO' ||
            this.getUserCustomer().toUpperCase() === 'SENINT' ||
            this.getUserCustomer().toUpperCase() === 'DGSLOG' ||
            this.getUserCustomer().toUpperCase() === 'KRABRG' ||
            this.isAdminInternal();
    }

    public isAdminLinkAccessible(requestedAccess: number, thingToBeAccessed: string, userSettings: LoginSettings): boolean {
        if (!userSettings || !userSettings.permissions || !userSettings.permissions.length) {
            return false;
        }
        const p = userSettings.permissions[0];
        const adminFieldPermissionValue: number = p.users_profile_adminfields;
        if (adminFieldPermissionValue) {
            return true;
        } else {
            return false;
        }
    }

    public checkIsImpersonating(): boolean {
        const jwtHelper: JwtHelperService = new JwtHelperService();
        const usertoken = localStorage.getItem(USER_TOKEN_KEY);
        if (!usertoken) {
            return false;
        }

        const usertokenDecoded = jwtHelper.decodeToken(usertoken);
        if (usertokenDecoded && usertokenDecoded.hasOwnProperty('ActualuserID')) {
            return true;
        } else {
            return false;
        }
    }

    public isAdminInternal(): boolean {
        const userPermissions: Permissions = this.getUserPermissions();
        if (!userPermissions) {
            return false;
        }

        return userPermissions.admin === Constants.USER_PERMISSIONS_ADMIN.INTERNAL;
    }

    public isAdminExternal(): boolean {
        const userPermissions: Permissions = this.getUserPermissions();
        if (!userPermissions) {
            return false;
        }

        return userPermissions.admin === Constants.USER_PERMISSIONS_ADMIN.EXTERNAL;
    }
}
