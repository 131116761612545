<div class="contactsListComponent">
    <div class="filterSectionRow">
        <div class="filterSection">
            <div class="searchCriteriaTypePanel">
                <select class="searchCriteriaTypeText" name="selectedFilterField" id="searchCriteriaTypes" [(ngModel)]="selectedFilterField"
                    (change)="filterContacts()">
                    <option [value]="ff.filterField" *ngFor="let ff of dropdownFilterFields">{{ ff.displayText }}</option>
                </select>
                <span class="searchCriteriaTypeChevron">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
            </div>
            <form class="filterInputPanel" (ngSubmit)="filterContacts()" [formGroup]="contactListForm">
                <input type="text" id="searchQuery" autofocus class="searchQuery" name="searchQuery" formControlName="filterTerm" placeholder="Enter Search Criteria"
                    (change)="filterContacts()" />
            </form>
            <span class="iconSearch clickable blue">
                <i class="fa fa-search" aria-hidden="true"></i>
            </span>
        </div>
        <div class="createCustomerSection">
            <span class="createCustomerIcon blue clickable">
                <i class="fa fa-plus-circle"></i>
            </span>
            <span class="createCustomerLabel blue clickable actionText" (click)="navigateToCreateContactPage()">Create</span>
        </div>
    </div>

    <div class="customerListContainer">
        <div class="customerListHeaderRow">
            <div class="headerItem">Contact Name</div>
            <div class="headerItem">Address</div>
            <div class="headerItem">City</div>
            <div class="headerItem">State</div>
            <div class="headerItem">Zip</div>
            <div class="lastCol">&nbsp;</div>
        </div>
        <div class="customerDetailContainer" *ngIf="masterDataContactsList.length">
            <cdk-virtual-scroll-viewport itemSize="25" style="height: 100%">
                <div class="customerDetailRow bottomBorderShadow" *cdkVirtualFor="let contact of filteredContactsList; let i = index" (click)="navigateToContactShowPage(contact)">
                    <div class="rowDetailItem rowDetailItemName blue actionText">{{ contact?.Name }}</div>
                    <div class="rowDetailItem">{{ contact?.Address1 }}</div>
                    <div class="rowDetailItem">{{ contact?.City }}</div>
                    <div class="rowDetailItem">{{ contact?.State }}</div>
                    <div class="rowDetailItem">{{ contact?.Zip }}</div>
                    <div class="lastCol" (click)="showConfirmDeleteModal($event, contact)">
                        <i class="fa fa-times blue clickable" aria-hidden="true"></i>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>

<app-ok-cancel-modal *ngIf="isConfirmDeleteModalVisible" [title]="confirmDeleteContactTitle" [message]="confirmDeleteContactMessage"
    (onCancel)="hideConfirmDeleteContactModal()" (onOk)="deleteContact($event)" [okPayload]="selectedContact"></app-ok-cancel-modal>
