import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-create-dashboard-modal',
    templateUrl: './create-dashboard-modal.component.html',
    // template:,
    styleUrls: ['./create-dashboard-modal.component.scss']
})
export class CreateDashboardModalComponent {
    @Input() public maxDashboardNameCharacterLength: number;
    @Output() public closeCreateDashboardModal = new EventEmitter();
    @Output() public emitDashboardName = new EventEmitter();

    public createdDashboardName: string;

    constructor(
        private _notificationService: NotificationService,
    ) {}

    public closeModal(): void {
        this.closeCreateDashboardModal.emit('');
    }

    public submit(): void {
        if (!this.createdDashboardName) {
            this._notificationService.notifyError({
                title: 'Create Dashboard',
                message: 'Please enter a dashboard name'
            });
        } else if (this.createdDashboardName.length > this.maxDashboardNameCharacterLength) {
            this._notificationService.notifyError({
                title: 'Create Dashboard',
                message: `Please limit your dashboard name to ${this.maxDashboardNameCharacterLength} characters.`
            });
        } else {
            this.emitDashboardName.emit(this.createdDashboardName);
        }
    }
}
