<div class="masterdataTabs">
    <div class="adminTabSectionContainer">
        <div class="adminTabSection clickable" (click)="setActiveMasterDataSection(masterDataTabSections.contacts)" [ngClass]="{ 'currentTab': activeMasterdataSection === masterDataTabSections.contacts }">
            <span class="adminTabSectionIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x rolesOuterIcon currentOuterIcon"></i>
                <i class="fa fa-phone fa-stack-1x rolesInnerIcon"></i>
            </span>

            <span class="adminTabSectionText">
                Contacts
            </span>
        </div>

        <div class="adminTabPointerDown" *ngIf="activeMasterdataSection === masterDataTabSections.contacts"></div>
    </div>

    <div class="adminTabSectionContainer">
        <div class="adminTabSection clickable" (click)="setActiveMasterDataSection(masterDataTabSections.products)" [ngClass]="{ 'currentTab': activeMasterdataSection === masterDataTabSections.products }">
            <span class="adminTabSectionIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x customersOuterIcon currentOuterIcon"></i>
                <i class="fa fa-cart-plus fa-stack-1x customersInnerIcon"></i>
            </span>

            <span class="adminTabSectionText">
                Products
            </span>
        </div>

        <div class="adminTabPointerDown" *ngIf="activeMasterdataSection === masterDataTabSections.products"></div>
    </div>

    <div class="adminTabSectionContainer">
        <div class="adminTabSection clickable" (click)="setActiveMasterDataSection(masterDataTabSections.carriers)" [ngClass]="{ 'currentTab': activeMasterdataSection === masterDataTabSections.carriers }">
            <span class="adminTabSectionIcon fa-stack fa-2x">
                <i class="fa fa-circle fa-stack-2x currentOuterIcon usersOuterIcon"></i>
                <i class="fa fa-road fa-stack-1x usersInnerIcon"></i>
            </span>

            <span class="adminTabSectionText">
                Carriers
            </span>
        </div>

        <div class="adminTabPointerDown" *ngIf="activeMasterdataSection === masterDataTabSections.carriers"></div>
    </div>
</div>