import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-terms-and-conditions-modal',
    styleUrls: ['./terms-and-conditions-modal.component.scss'],
    templateUrl: './terms-and-conditions-modal.component.html',
})
export class TermsAndConditionsModalComponent implements OnInit {
    @Output() public closeTermsAndConditionsModal = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    public closeModal() {
        this.closeTermsAndConditionsModal.emit('');
    }

}
