import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-solicit-template-name-modal',
    styleUrls: ['./solicit-template-name-modal.component.scss'],
    templateUrl: './solicit-template-name-modal.component.html',
})
export class SolicitTemplateNameModalComponent implements OnInit, OnDestroy {
    @Input() public templateName: boolean;
    @Output() public closeSolicitTemplateNameModal = new EventEmitter();

    constructor(
        private _formBuilder: UntypedFormBuilder
    ) { }

    public ngOnInit() {
        document.getElementById('templateName').focus();
    }

    public ngOnDestroy() {
    }

    public closeModal(): void {
        this.closeSolicitTemplateNameModal.emit(this.templateName);
    }

    public cancelModal(): void {
        this.closeSolicitTemplateNameModal.emit();
    }
}
