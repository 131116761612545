import { Component, OnInit } from '@angular/core';

export const { version: appVersion } = require('../../../../package.json');

@Component({
    selector: 'app-footer',
    template: `
        <div class="footerPanel">
        </div>
    `,
    styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {
    public appVersion: string;

    public ngOnInit() {
        this.appVersion = appVersion;
    }
}
