<div class="overlay">
  <div class="queryFilterModal">

    <!-- =========================== MODAL HEADER =========================== -->
    <div class="modalHeader">
      <span class="modalHeaderText">
        Search {{ field.displayName | titlecase }}
      </span>

      <div class="modalHeaderIcon clickable">
        <i class="fa fa-times" aria-hidden="true" (click)="cancel()"></i>
      </div>
    </div>

    <!-- =========================== MODAL BODY =========================== -->
    <div class="modalBody">

      <!-- =========================== SEARCH PANEL =========================== -->
      <form class="searchPanel" (ngSubmit)="handleFormInputChanges()">
        <input type="text" class="searchInput" [(ngModel)]="filterQuery" name="filterQuery">

        <div class="searchIcon clickable" (click)="handleFormInputChanges()">
          <i class="fa fa-search"></i>
        </div>
      </form>

      <!-- =========================== SEARCH RESULTS =========================== -->
      <div class="searchResultsHeader">
        <span class="searchResultsHeaderText">
          Name
        </span>
      </div>

      <div class="searchResultsContainer scrollbar">
        <div class="searchResultRow clickable" *ngFor="let result of filteredValues" (click)="setQuery(result)">
          <span class="searchResultRowText">
            {{ result | apiParseError:field.fieldName | checkDate | amount:field.fieldName }}
          </span>
        </div>
      </div>
    </div>

    <!-- =========================== MODAL FOOTER =========================== -->
    <div class="modalFooter">
      <app-search-pagination-panel [searchResultTotal]="filteredListValueCount" (pageChanged)="handlePaginationPanelChanges($event)"></app-search-pagination-panel>
    </div>
  </div>
</div>