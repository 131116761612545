<div class="productLinesHeader">
    <div class="nameCol headerTitles">Product</div>

    <div class="handlingUnitQtyCol headerTitles">Handling Unit Qty</div>

    <div class="piecesCol headerTitles">Package Qty</div>

    <div class="cubeCol headerTitles">Cube <span>({{unitsCube}})</span></div>

    <div class="weightCol headerTitles">Weight <span>({{unitsWeight}})</span></div>

    <div class="densityCol headerTitles">Density <span>({{unitsDensity}})</span></div>
</div>

<div class="productLinesData" *ngFor="let productLine of lines" >
    <div class="productLineRow">
        <div class="productLine">
            <span class="nameCol">{{productLine.name}}</span>

            <span class="handlingUnitQtyCol">{{productLine.Units}}</span>

            <span class="piecesCol">{{productLine.Qty}}</span>

            <span class="cubeCol">{{productLine.Cube | number: '1.2-2'}}</span>

            <span class="weightCol">{{productLine.Weight | number: '1.2-2'}}</span>

            <div class="densityCol">{{calculateDensity(productLine.Weight, productLine.Cube) | number: '1.2-2'}}</div>
        </div>
    </div>
</div>

<div class="productLinesData">
    <div class="productLineRow">
        <div class="productLine summaryLine">
            <span class="nameCol ">Totals</span>

            <span class="handlingUnitQtyCol">{{productLineSum('Units')}}</span>

            <span class="piecesCol">{{productLineSum('Qty')}}</span>

            <span class="cubeCol">{{productLineSum('Cube') | number: '1.2-2'}}</span>

            <span class="weightCol">{{productLineSum('Weight') | number: '1.2-2'}}</span>

            <div class="densityCol">{{calculateDensity(productLineSum('Weight'), productLineSum('Cube')) | number: '1.2-2'}}</div>
        </div>
    </div>
</div>
