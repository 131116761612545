import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of, Observable} from 'rxjs';

import {NmfcSearchResult} from 'app/models/nmfc.searchResult';
import {environment} from '../../environments/environment';
import * as SearchConstants from '../constants/searchCriteria';
import {ResponseDTO} from '../models/responseDto';
import {SearchRequest} from '../models/searchRequest';
import {SearchResponse} from '../models/searchResponse';
import {REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT} from '../services/api.service';

export const mockResponse_Search: ResponseDTO<SearchResponse> = {
    isValid: true,
    messages: [
        {
            message: 'First Message',
            messageType: 'Primary'
        },
        {
            message: 'Second Message',
            messageType: 'secondary'
        }
    ],
    dto: {
        searchResults: [
            {
                entities: [
                    {
                        '@timestamp': '2017-09-26T21:14:42.544Z',
                        '@version': 1,
                        'accessorial': 'FSC',
                        'audit_code': '0000',
                        'carrier': 'ESTES EXP LINES',
                        'check_no': 1028883,
                        'consignee': 'TRUCKLITE CO INC',
                        'customer': '009009',
                        'delv_date': '2016-06-14T04:00:00.000Z',
                        'filler': '',
                        'gl_code': 519.59,
                        'id': '009009_40839_42',
                        'mileage': 471,
                        'paid_date': '2016-07-07T04:00:00.000Z',
                        'po_bol': 41640,
                        'pro_number': 940929078,
                        'recd_date': '2016-06-24T04:00:00.000Z',
                        'scac': 'EXLA',
                        'ship_date': '2016-06-13T04:00:00.000Z',
                        'shipper': 'STAR TECHNOLOGY',
                        'ttl_wgt': 3265,
                        'wk_end_date': '2016-06-30T04:00:00.000Z'
                    },
                    {
                        'wk_end_date': '2016-06-23T04:00:00.000Z',
                        'gl_code': 519.59,
                        'accessorial': 'FSC',
                        'mileage': 171,
                        'consignee': 'TRUCK - LITE COMPANY',
                        '@timestamp': '2017-09-26T21:14:42.536Z',
                        'filler': 688416,
                        'ttl_wgt': 220,
                        'pro_number': 21412541,
                        'shipper': 'FALCONER ELECTRONICS',
                        'carrier': 'NEW ENGLAND MTR FRT',
                        'delv_date': '2016-06-01T04:00:00.000Z',
                        '@version': 1,
                        'scac': 'NEMF',
                        'po_bol': 'NS',
                        'audit_code': '0000',
                        'ship_date': '2016-06-01T04:00:00.000Z',
                        'check_no': 1028751,
                        'customer': '009009',
                        'id': '009009_40830_2',
                        'recd_date': '2016-06-21T04:00:00.000Z',
                        'paid_date': '2016-06-28T04:00:00.000Z'
                    },
                    {
                        'wk_end_date': '2016-06-23T04:00:00.000Z',
                        'gl_code': 519.59,
                        'accessorial': 'FSC',
                        'mileage': 203,
                        'consignee': 'TRUCK - LITE COMPANY',
                        '@timestamp': '2017-09-26T21:14:42.536Z',
                        'filler': '',
                        'ttl_wgt': 85,
                        'pro_number': 21413645,
                        'shipper': 'JAMESTOWN PLASTICS',
                        'carrier': 'NEW ENGLAND MTR FRT',
                        'delv_date': '2016-06-03T04:00:00.000Z',
                        '@version': 1,
                        'scac': 'NEMF',
                        'po_bol': '26492NY',
                        'audit_code': '0105',
                        'ship_date': '2016-06-03T04:00:00.000Z',
                        'check_no': 1028751,
                        'customer': '009009',
                        'id': '009009_40830_3',
                        'recd_date': '2016-06-21T04:00:00.000Z',
                        'paid_date': '2016-06-28T04:00:00.000Z'
                    },
                    {
                        'wk_end_date': '2016-06-23T04:00:00.000Z',
                        'gl_code': '101.3410.900',
                        'accessorial': 'FSC',
                        'mileage': 2745,
                        'consignee': 'STATELINE AUTO PARTS',
                        '@timestamp': '2017-09-26T21:14:42.537Z',
                        'filler': '14150592OD000LI',
                        'ttl_wgt': 114,
                        'pro_number': 4933937497,
                        'shipper': 'TRUCK-LITE COMPANY I',
                        'carrier': 'YRC, INC.',
                        'delv_date': '2016-06-03T04:00:00.000Z',
                        '@version': 1,
                        'scac': 'RDWY',
                        'po_bol': '02717723',
                        'audit_code': '0000',
                        'ship_date': '2016-06-03T04:00:00.000Z',
                        'check_no': 1028761,
                        'customer': '009009',
                        'id': '009009_40832_6',
                        'recd_date': '2016-06-21T04:00:00.000Z',
                        'paid_date': '2016-06-28T04:00:00.000Z'
                    },
                    {
                        'wk_end_date': '2016-06-23T04:00:00.000Z',
                        'gl_code': '101.3410.900',
                        'accessorial': 'FSC',
                        'mileage': 2152,
                        'consignee': 'NAPA AUTO PARTS',
                        '@timestamp': '2017-09-26T21:14:42.537Z',
                        'filler': '141076470D000LI',
                        'ttl_wgt': 280,
                        'pro_number': 4933937625,
                        'shipper': 'TRUCK - LITE COMPANY',
                        'carrier': 'YRC, INC.',
                        'delv_date': '2016-05-27T04:00:00.000Z',
                        '@version': 1,
                        'scac': 'RDWY',
                        'po_bol': '02708725',
                        'audit_code': '0000',
                        'ship_date': '2016-05-27T04:00:00.000Z',
                        'check_no': 1028761,
                        'customer': '009009',
                        'id': '009009_40832_7',
                        'recd_date': '2016-06-21T04:00:00.000Z',
                        'paid_date': '2016-06-28T04:00:00.000Z'
                    },
                    {
                        'wk_end_date': '2016-06-30T04:00:00.000Z',
                        'gl_code': 519.59,
                        'accessorial': 'FSC',
                        'mileage': 108,
                        'consignee': 'ACCURATE COATINGS',
                        '@timestamp': '2017-09-26T21:14:42.545Z',
                        'filler': 691765,
                        'ttl_wgt': 690,
                        'pro_number': 950499728,
                        'shipper': 'MAG-TEC CASTING CORP',
                        'carrier': 'ESTES EXP LINES',
                        'delv_date': '2016-06-14T04:00:00.000Z',
                        '@version': 1,
                        'scac': 'EXLA',
                        'po_bol': 17492,
                        'audit_code': '0000',
                        'ship_date': '2016-06-13T04:00:00.000Z',
                        'check_no': 1028883,
                        'customer': '009009',
                        'id': '009009_40839_45',
                        'recd_date': '2016-06-24T04:00:00.000Z',
                        'paid_date': '2016-07-07T04:00:00.000Z'
                    },
                    {
                        'wk_end_date': '2016-06-23T04:00:00.000Z',
                        'gl_code': '101.3410.900',
                        'accessorial': 'FSC',
                        'mileage': 2582,
                        'consignee': 'FLEETPRIDE INC STORE',
                        '@timestamp': '2017-09-26T21:14:42.538Z',
                        'filler': 7667308,
                        'ttl_wgt': 235,
                        'pro_number': 4933937708,
                        'shipper': 'TRUCK - LITE COMPANY',
                        'carrier': 'YRC, INC.',
                        'delv_date': '2016-05-27T04:00:00.000Z',
                        '@version': 1,
                        'scac': 'RDWY',
                        'po_bol': '02709507',
                        'audit_code': '0000',
                        'ship_date': '2016-05-27T04:00:00.000Z',
                        'check_no': 1028761,
                        'customer': '009009',
                        'id': '009009_40832_11',
                        'recd_date': '2016-06-21T04:00:00.000Z',
                        'paid_date': '2016-06-28T04:00:00.000Z'
                    },
                    {
                        'wk_end_date': '2016-06-23T04:00:00.000Z',
                        'gl_code': '101.3410.900',
                        'accessorial': 'FSC',
                        'mileage': 1297,
                        'consignee': 'PACCAR PARTS DIVISIO',
                        '@timestamp': '2017-09-26T21:14:42.538Z',
                        'filler': 'STK548317',
                        'ttl_wgt': 310,
                        'pro_number': 4933937744,
                        'shipper': 'TRUCK - LITE COMPANY',
                        'carrier': 'YRC, INC.',
                        'delv_date': '2016-05-31T04:00:00.000Z',
                        '@version': 1,
                        'scac': 'RDWY',
                        'po_bol': '02711875',
                        'audit_code': '0000',
                        'ship_date': '2016-05-31T04:00:00.000Z',
                        'check_no': 1028761,
                        'customer': '009009',
                        'id': '009009_40832_12',
                        'recd_date': '2016-06-21T04:00:00.000Z',
                        'paid_date': '2016-06-28T04:00:00.000Z'
                    },
                    {
                        'wk_end_date': '2016-06-23T04:00:00.000Z',
                        'gl_code': '101.3410.900',
                        'accessorial': 'FSC',
                        'mileage': 327,
                        'consignee': 'GENUINE PARTS CO STO',
                        '@timestamp': '2017-09-26T21:14:42.538Z',
                        'filler': '027216190P000',
                        'ttl_wgt': 299,
                        'pro_number': 6566703,
                        'shipper': 'TRUCK - LITE MCELHAT',
                        'carrier': 'Rayloc Merchandise Dist.',
                        'delv_date': '2016-06-03T04:00:00.000Z',
                        '@version': 1,
                        'scac': 'RYLC',
                        'po_bol': '02717469',
                        'audit_code': '0000',
                        'ship_date': '2016-06-03T04:00:00.000Z',
                        'check_no': 290953,
                        'customer': '009009',
                        'id': '009009_40833_3',
                        'recd_date': '2016-06-21T04:00:00.000Z',
                        'paid_date': '2016-06-28T04:00:00.000Z'
                    },
                    {
                        'wk_end_date': '2016-06-23T04:00:00.000Z',
                        'gl_code': '101.3410.900',
                        'accessorial': 'FSC',
                        'mileage': 228,
                        'consignee': 'NAPA WASHINGTON DC',
                        '@timestamp': '2017-09-26T21:14:42.539Z',
                        'filler': '027216240P000',
                        'ttl_wgt': 359,
                        'pro_number': 6566707,
                        'shipper': 'TRUCK - LITE MCELHAT',
                        'carrier': 'Rayloc Merchandise Dist.',
                        'delv_date': '2016-06-03T04:00:00.000Z',
                        '@version': 1,
                        'scac': 'RYLC',
                        'po_bol': '02716454',
                        'audit_code': '0000',
                        'ship_date': '2016-06-03T04:00:00.000Z',
                        'check_no': 290953,
                        'customer': '009009',
                        'id': '009009_40833_7',
                        'recd_date': '2016-06-21T04:00:00.000Z',
                        'paid_date': '2016-06-28T04:00:00.000Z'
                    }
                ],
                entityType: 'invoices'
            },
            {
                entities: [
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        'rated_amt': 0,
                        'consignee_city': 'CAROL STREAM',
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        'pro_number': 342347293,
                        'consignee_zip': 60188,
                        'carrier': 'DAYTON FREIGHT LINES',
                        'shipper_state': 'MI',
                        'po_number': '',
                        'status': 'Delivered',
                        'shipper_zip': 49858,
                        '@version': 1,
                        'scac': 'DAFG',
                        'delivery_date': '2015-09-01T04:00:00.000Z',
                        'product': null,
                        'consignee_state': 'IL',
                        'customer': '039305',
                        'shipper_city': 'MENIMINEE',
                        'id': 220931,
                        'rowid': '039305220931',
                        'shipper_name': 'NU VU FOOD SERVICE SYSTEMS',
                        'bol': 'CHI/BNE/B06729',
                        'consignee_name': 'BRENNAN c/o Vanguard'
                    },
                    {
                        'rated_amt': 0,
                        'consignee_city': 'CAROL STREAM',
                        'pickup_date': '2015-08-25T04:00:00.000Z',
                        '@timestamp': '2017-09-26T22:08:09.792Z',
                        'pro_number': 1043394914,
                        'consignee_zip': 60188,
                        'carrier': 'USF HOLLAND',
                        'shipper_state': 'KY',
                        'po_number': '',
                        'status': 'Delivered',
                        'shipper_zip': 41048,
                        '@version': 1,
                        'scac': 'HMES',
                        'delivery_date': '2015-08-31T04:00:00.000Z',
                        'product': null,
                        'consignee_state': 'IL',
                        'customer': '039305',
                        'shipper_city': 'HEBRON',
                        'id': 220908,
                        'rowid': '039305220908',
                        'shipper_name': 'Ceva Logistics',
                        'bol': 'NYC/BUS/D85013',
                        'consignee_name': 'DCL c/o Vanguard'
                    },
                    {
                        'rated_amt': 0,
                        'consignee_city': 'Forest Park',
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        '@timestamp': '2017-09-26T22:08:09.792Z',
                        'pro_number': 1050180735,
                        'consignee_zip': 30297,
                        'carrier': 'USF HOLLAND',
                        'shipper_state': 'AL',
                        'po_number': '',
                        'status': 'Delivered',
                        'shipper_zip': 35040,
                        '@version': 1,
                        'scac': 'HMES',
                        'delivery_date': '2015-08-27T04:00:00.000Z',
                        'product': null,
                        'consignee_state': 'GA',
                        'customer': '039305',
                        'shipper_city': 'CALERA',
                        'id': 220915,
                        'rowid': '039305220915',
                        'shipper_name': 'RAIN BIRD DISTRIBUTION',
                        'bol': 'MIA/MVD/C12966',
                        'consignee_name': 'Vanguard Logistics Services, Inc.'
                    },
                    {
                        'rated_amt': 0,
                        'consignee_city': 'CAROL STREAM',
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        'pro_number': 1035556392,
                        'consignee_zip': 60188,
                        'carrier': 'USF HOLLAND',
                        'shipper_state': 'IL',
                        'po_number': '',
                        'status': 'Delivered',
                        'shipper_zip': 60416,
                        '@version': 1,
                        'scac': 'HMES',
                        'delivery_date': '2015-08-27T04:00:00.000Z',
                        'product': null,
                        'consignee_state': 'IL',
                        'customer': '039305',
                        'shipper_city': 'COAL CITY',
                        'id': 220934,
                        'rowid': '039305220934',
                        'shipper_name': 'CHICAGO AEROSOL',
                        'bol': 'CHE/VLN/D00046',
                        'consignee_name': 'DCL c/o Vanguard'
                    },
                    {
                        'rated_amt': 0,
                        'consignee_city': 'CAROL STREAM',
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        'pro_number': 344894903,
                        'consignee_zip': 60188,
                        'carrier': 'DAYTON FREIGHT LINES',
                        'shipper_state': 'MN',
                        'po_number': '',
                        'status': 'Delivered',
                        'shipper_zip': 55963,
                        '@version': 1,
                        'scac': 'DAFG',
                        'delivery_date': '2015-09-01T04:00:00.000Z',
                        'product': null,
                        'consignee_state': 'IL',
                        'customer': '039305',
                        'shipper_city': 'PINE ISLAND',
                        'id': 220935,
                        'rowid': '039305220935',
                        'shipper_name': 'PROGRESSIVE TOOL and MFG',
                        'bol': 'LAX/SYD/D23257',
                        'consignee_name': 'DCL c/o Vanguard'
                    },
                    {
                        'rated_amt': 0,
                        'consignee_city': 'NORTH CHARLESTON',
                        'pickup_date': '2015-08-25T04:00:00.000Z',
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        'pro_number': 937963520,
                        'consignee_zip': 29418,
                        'carrier': 'SAIA',
                        'shipper_state': 'SC',
                        'po_number': '',
                        'status': 'Delivered',
                        'shipper_zip': 29650,
                        '@version': 1,
                        'scac': 'SAIA',
                        'delivery_date': '2015-08-31T04:00:00.000Z',
                        'product': null,
                        'consignee_state': 'SC',
                        'customer': '039305',
                        'shipper_city': 'GREERE',
                        'id': 220937,
                        'rowid': '039305220937',
                        'shipper_name': 'SAIA MOTOR FREIGHT',
                        'bol': 'LAX/BGK/D31261',
                        'consignee_name': 'VANGUARD LOGISTICS C/O PTL'
                    },
                    {
                        'rated_amt': 0,
                        'consignee_city': 'Forest Park',
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        'pro_number': '',
                        'consignee_zip': 30297,
                        'carrier': 'WILSON TRUCKING CORPORATION',
                        'shipper_state': 'GA',
                        'po_number': '',
                        'status': 'Cancelled',
                        'shipper_zip': 30013,
                        '@version': 1,
                        'scac': 'WTVA',
                        'delivery_date': '2015-08-27T04:00:00.000Z',
                        'product': null,
                        'consignee_state': 'GA',
                        'customer': '039305',
                        'shipper_city': 'CONVYERS',
                        'id': 220942,
                        'rowid': '039305220942',
                        'shipper_name': 'ABC COMPOUNDING CO INC',
                        'bol': 'ATL/LIV/C05825',
                        'consignee_name': 'Vanguard Logistics Services, Inc.'
                    },
                    {
                        'rated_amt': 0,
                        'consignee_city': 'CAROL STREAM',
                        'pickup_date': '2015-08-27T04:00:00.000Z',
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        'pro_number': '',
                        'consignee_zip': 60188,
                        'carrier': 'DAYTON FREIGHT LINES',
                        'shipper_state': 'KY',
                        'po_number': '',
                        'status': 'Scheduled Pickup',
                        'shipper_zip': 41051,
                        '@version': 1,
                        'scac': 'DAFG',
                        'delivery_date': '2015-09-01T04:00:00.000Z',
                        'product': null,
                        'consignee_state': 'IL',
                        'customer': '039305',
                        'shipper_city': 'Independence',
                        'id': 220947,
                        'rowid': '039305220947',
                        'shipper_name': 'MAGNI INDUSTRIES INC',
                        'bol': 'DET/MEB/D03082',
                        'consignee_name': 'DCL c/o Vanguard'
                    },
                    {
                        'rated_amt': 0,
                        'consignee_city': 'CAROL STREAM',
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        'pro_number': 325808923,
                        'consignee_zip': 60188,
                        'carrier': 'DAYTON FREIGHT LINES',
                        'shipper_state': 'IN',
                        'po_number': '',
                        'status': 'Delivered',
                        'shipper_zip': 46383,
                        '@version': 1,
                        'scac': 'DAFG',
                        'delivery_date': '2015-08-27T04:00:00.000Z',
                        'product': null,
                        'consignee_state': 'IL',
                        'customer': '039305',
                        'shipper_city': 'VALPARAISO',
                        'id': 220953,
                        'rowid': '039305220953',
                        'shipper_name': 'TASK FORCE TIPS',
                        'bol': 'CHI/IZR/D03600',
                        'consignee_name': 'DCL c/o Vanguard'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 2',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 3',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 4',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 5',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 6',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 7',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 8',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 9',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 10',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23524,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 11',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 12',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23516,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 13',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23519,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 14',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                    {
                        '@timestamp': '2017-09-26T22:08:09.808Z',
                        '@version': 1,
                        'bol': 'NYC/MEB/B82357',
                        'carrier': 'WARD TRUCKING CORP.',
                        'consignee_city': 'NORFOLK TEST 15',
                        'consignee_name': 'BRENNAN / PHILADELPHIA TRUCK L',
                        'consignee_state': 'VA',
                        'consignee_zip': 23518,
                        'customer': '039305',
                        'delivery_date': '2015-08-26T04:00:00.000Z',
                        'id': 220926,
                        'pickup_date': '2015-08-24T04:00:00.000Z',
                        'po_number': '',
                        'pro_number': '',
                        'product': null,
                        'rated_amt': 0,
                        'rowid': '039305220926',
                        'scac': 'WARD',
                        'shipper_city': 'DALEVILLE',
                        'shipper_name': 'UPG',
                        'shipper_state': 'VA',
                        'shipper_zip': 24083,
                        'status': 'Order Received'
                    },
                ],
                entityType: 'shipments'
            },
            {
                entities: [
                    {
                        '@timestamp': '2017-09-26T20:14:15.333Z',
                        'amount': 118.05999755859375,
                        'carrier': 'VITRAN EXPRESS',
                        'customer': 'BAKER',
                        'dzip': 76052,
                        'id': 2,
                        'ozip': 90210,
                        'product': 'N/A',
                        'scac': 'VITR',
                        'ship_id': 1621364,
                        'shipdate': '2013-05-06T04:00:00.000Z'
                    },
                    {
                        'shipdate': '2013-05-07T04:00:00.000Z',
                        'ship_id': 1667770,
                        'scac': 'VITR',
                        'amount': 105.36299896240234,
                        'product': 'N/A',
                        'ozip': 30101,
                        'customer': 'HANINT',
                        '@timestamp': '2017-09-26T20:14:15.333Z',
                        'id': 4,
                        'dzip': 60638,
                        'carrier': 'VITRAN EXPRESS'
                    },
                    {
                        'shipdate': '2013-11-18T05:00:00.000Z',
                        'ship_id': 10646622,
                        'scac': 'HMES',
                        'amount': 89.25,
                        'product': 'N/A',
                        'ozip': 60143,
                        'customer': 'BAKER',
                        '@timestamp': '2017-09-26T20:14:15.333Z',
                        'id': 5,
                        'dzip': 60143,
                        'carrier': 'HOLLAND MOTOR EXPRESS'
                    },
                    {
                        'shipdate': '2013-12-02T05:00:00.000Z',
                        'ship_id': 11275160,
                        'scac': 'RLCA',
                        'amount': 149.86099243164062,
                        'product': 'N/A',
                        'ozip': 80104,
                        'customer': 'SRCOMT',
                        '@timestamp': '2017-09-26T20:14:15.333Z',
                        'id': 6,
                        'dzip': 30101,
                        'carrier': 'R&L CARRIERS'
                    },
                    {
                        'shipdate': '2013-11-25T05:00:00.000Z',
                        'ship_id': 11300955,
                        'scac': 'RDFS',
                        'amount': 92.8125,
                        'product': 'N/A',
                        'ozip': 76052,
                        'customer': 'SRCOMT',
                        '@timestamp': '2017-09-26T20:14:15.333Z',
                        'id': 7,
                        'dzip': 90210,
                        'carrier': 'ROADRUNNER DAWES'
                    },
                    {
                        'shipdate': '2013-12-09T05:00:00.000Z',
                        'ship_id': 11420595,
                        'scac': 'RLCA',
                        'amount': 92.7562026977539,
                        'product': 'N/A',
                        'ozip': 28217,
                        'customer': 'SRCOMT',
                        '@timestamp': '2017-09-26T20:14:15.333Z',
                        'id': 11,
                        'dzip': '06810',
                        'carrier': 'R&L CARRIERS'
                    },
                    {
                        'shipdate': '2016-01-19T05:00:00.000Z',
                        'ship_id': 41955740,
                        'scac': 'AACT',
                        'amount': 104.5719985961914,
                        'product': 'N/A',
                        'ozip': 28201,
                        'customer': 'SROLIT',
                        '@timestamp': '2017-09-26T20:14:15.348Z',
                        'id': 18,
                        'dzip': 30354,
                        'carrier': 'AAA COOPER TRANSPORT'
                    },
                    {
                        'shipdate': '2016-01-21T05:00:00.000Z',
                        'ship_id': 42018581,
                        'scac': 'SEFL',
                        'amount': 62.400001525878906,
                        'product': 'N/A',
                        'ozip': 33556,
                        'customer': 'SROLIT',
                        '@timestamp': '2017-09-26T20:14:15.348Z',
                        'id': 20,
                        'dzip': 30041,
                        'carrier': 'SOUTHEASTERN FRT LNS'
                    },
                    {
                        'shipdate': '2016-01-22T05:00:00.000Z',
                        'ship_id': 42044896,
                        'scac': 'AACT',
                        'amount': 160.3679962158203,
                        'product': 'N/A',
                        'ozip': 33169,
                        'customer': 'SROLIT',
                        '@timestamp': '2017-09-26T20:14:15.348Z',
                        'id': 21,
                        'dzip': 60001,
                        'carrier': 'AAA COOPER TRANSPORT'
                    },
                    {
                        'shipdate': '2016-02-02T05:00:00.000Z',
                        'ship_id': 42314267,
                        'scac': 'AACT',
                        'amount': 150.73699951171875,
                        'product': 'N/A',
                        'ozip': 75261,
                        'customer': 'HEALIT',
                        '@timestamp': '2017-09-26T20:14:15.348Z',
                        'id': 24,
                        'dzip': 78717,
                        'carrier': 'AAA COOPER TRANSPORT'
                    }
                ],
                entityType: 'quotes'
            }
        ],
        searchRequest: {
            searchCriteria: [
                {
                    type: 'Shipper',
                    value: '2014-1-14',
                    entityType: 'shipments',
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
                }
            ]
        },
        searchTotals: [
            {
                index: 'invoices',
                total: 500
            },
            {
                index: 'shipments',
                total: 500
            },
            {
                index: 'quotes',
                total: 500
            }
        ]
    }
}

const mockResponse_NmfcSearch: ResponseDTO<Array<NmfcSearchResult>> = {
    isValid: true,
    messages: [],
    dto: [
        {
            'searchable_description': 'Sets, nutcracker, consisting of steel nutcracker, steel nutpicks and bowl,,in boxes',
            'itemno': '101345',
            'parentitemno': 100500,
            'itemdescription': 'Sets, nutcracker, consisting of steel nutcracker, steel nutpicks and bowl,,in boxes',
            'class': '85',
            'displayclass': '85',
            'group_title': 'HOUSEHOLD UTENSILS GROUP'
        },
        {
            'searchable_description': '15 but less than 22.5,22.5 but less than 30,30 or greater,Insulation or Insulating Material, viz.:,Barriers, Shields, Windows or Glass Window Lights or Blocks, radiation,shielding, with or without frames, see Note, item 103301;,Felt or Felt Paper;,Fire Blocking or Sound Attenuation Material, consisting of glass fibers and,unexpanded vermiculite, in sheet form, cut, grooved or bent;,Insulation or Insulating Material, NOI;,Mineral Wool (Rock, Slag or Glass Wool) or Synthetic Vitreous Fiber;,Silencers or Sound Traps, air duct, sheet steel or sheet steel and,insulating material combined;,Sleeves or Hollow Shapes, mold riser, exothermic compound;,Sound Deadening Material, NOI;,In packages, subject to Items 170 and 171 and having a density in pounds per,cubic foot of:,Less than 1,1 but less than 2,2 but less than 4,4 but less than 6,6 but less than 8,8 but less than 10,10 but less than 12,12 but less than 15',
            'itemno': '103300',
            'parentitemno': 103000,
            'itemdescription': 'Insulation or Insulating Material, viz.:,Barriers, Shields, Windows or Glass Window Lights or Blocks, radiation,shielding, with or without frames, see Note, item 103301;,Felt or Felt Paper;,Fire Blocking or Sound Attenuation Material, consisting of glass fibers and,unexpanded vermiculite, in sheet form, cut, grooved or bent;,Insulation or Insulating Material, NOI;,Mineral Wool (Rock, Slag or Glass Wool) or Synthetic Vitreous Fiber;,Silencers or Sound Traps, air duct, sheet steel or sheet steel and,insulating material combined;,Sleeves or Hollow Shapes, mold riser, exothermic compound;,Sound Deadening Material, NOI;,In packages, subject to Items 170 and 171 and having a density in pounds per,cubic foot of:',
            'class': '0',
            'displayclass': '',
            'group_title': 'INSULATION OR INSULATING MATERIAL GROUP'
        },
        {
            'searchable_description': '15 but less than 22.5,22.5 but less than 30,30 or greater,Insulation or Insulating Material, viz.:,Barriers, Shields, Windows or Glass Window Lights or Blocks, radiation,shielding, with or without frames, see Note, item 103301;,Felt or Felt Paper;,Fire Blocking or Sound Attenuation Material, consisting of glass fibers and,unexpanded vermiculite, in sheet form, cut, grooved or bent;,Insulation or Insulating Material, NOI;,Mineral Wool (Rock, Slag or Glass Wool) or Synthetic Vitreous Fiber;,Silencers or Sound Traps, air duct, sheet steel or sheet steel and,insulating material combined;,Sleeves or Hollow Shapes, mold riser, exothermic compound;,Sound Deadening Material, NOI;,In packages, subject to Items 170 and 171 and having a density in pounds per,cubic foot of:,Less than 1,1 but less than 2,2 but less than 4,4 but less than 6,6 but less than 8,8 but less than 10,10 but less than 12,12 but less than 15',
            'itemno': '103300-1',
            'parentitemno': 103000,
            'itemdescription': 'Less than 1',
            'class': '400',
            'displayclass': '400',
            'group_title': 'INSULATION OR INSULATING MATERIAL GROUP'
        },
        {
            'searchable_description': '15 but less than 22.5,22.5 but less than 30,30 or greater,Insulation or Insulating Material, viz.:,Barriers, Shields, Windows or Glass Window Lights or Blocks, radiation,shielding, with or without frames, see Note, item 103301;,Felt or Felt Paper;,Fire Blocking or Sound Attenuation Material, consisting of glass fibers and,unexpanded vermiculite, in sheet form, cut, grooved or bent;,Insulation or Insulating Material, NOI;,Mineral Wool (Rock, Slag or Glass Wool) or Synthetic Vitreous Fiber;,Silencers or Sound Traps, air duct, sheet steel or sheet steel and,insulating material combined;,Sleeves or Hollow Shapes, mold riser, exothermic compound;,Sound Deadening Material, NOI;,In packages, subject to Items 170 and 171 and having a density in pounds per,cubic foot of:,Less than 1,1 but less than 2,2 but less than 4,4 but less than 6,6 but less than 8,8 but less than 10,10 but less than 12,12 but less than 15',
            'itemno': '103300-2',
            'parentitemno': 103000,
            'itemdescription': '1 but less than 2',
            'class': '300',
            'displayclass': '300',
            'group_title': 'INSULATION OR INSULATING MATERIAL GROUP'
        },
        {
            'searchable_description': '15 but less than 22.5,22.5 but less than 30,30 or greater,Insulation or Insulating Material, viz.:,Barriers, Shields, Windows or Glass Window Lights or Blocks, radiation,shielding, with or without frames, see Note, item 103301;,Felt or Felt Paper;,Fire Blocking or Sound Attenuation Material, consisting of glass fibers and,unexpanded vermiculite, in sheet form, cut, grooved or bent;,Insulation or Insulating Material, NOI;,Mineral Wool (Rock, Slag or Glass Wool) or Synthetic Vitreous Fiber;,Silencers or Sound Traps, air duct, sheet steel or sheet steel and,insulating material combined;,Sleeves or Hollow Shapes, mold riser, exothermic compound;,Sound Deadening Material, NOI;,In packages, subject to Items 170 and 171 and having a density in pounds per,cubic foot of:,Less than 1,1 but less than 2,2 but less than 4,4 but less than 6,6 but less than 8,8 but less than 10,10 but less than 12,12 but less than 15',
            'itemno': '103300-3',
            'parentitemno': 103000,
            'itemdescription': '2 but less than 4',
            'class': '250',
            'displayclass': '250',
            'group_title': 'INSULATION OR INSULATING MATERIAL GROUP'
        },
        {
            'searchable_description': '15 but less than 22.5,22.5 but less than 30,30 or greater,Insulation or Insulating Material, viz.:,Barriers, Shields, Windows or Glass Window Lights or Blocks, radiation,shielding, with or without frames, see Note, item 103301;,Felt or Felt Paper;,Fire Blocking or Sound Attenuation Material, consisting of glass fibers and,unexpanded vermiculite, in sheet form, cut, grooved or bent;,Insulation or Insulating Material, NOI;,Mineral Wool (Rock, Slag or Glass Wool) or Synthetic Vitreous Fiber;,Silencers or Sound Traps, air duct, sheet steel or sheet steel and,insulating material combined;,Sleeves or Hollow Shapes, mold riser, exothermic compound;,Sound Deadening Material, NOI;,In packages, subject to Items 170 and 171 and having a density in pounds per,cubic foot of:,Less than 1,1 but less than 2,2 but less than 4,4 but less than 6,6 but less than 8,8 but less than 10,10 but less than 12,12 but less than 15',
            'itemno': '103300-4',
            'parentitemno': 103000,
            'itemdescription': '4 but less than 6',
            'class': '175',
            'displayclass': '175',
            'group_title': 'INSULATION OR INSULATING MATERIAL GROUP'
        },
        {
            'searchable_description': '15 but less than 22.5,22.5 but less than 30,30 or greater,Insulation or Insulating Material, viz.:,Barriers, Shields, Windows or Glass Window Lights or Blocks, radiation,shielding, with or without frames, see Note, item 103301;,Felt or Felt Paper;,Fire Blocking or Sound Attenuation Material, consisting of glass fibers and,unexpanded vermiculite, in sheet form, cut, grooved or bent;,Insulation or Insulating Material, NOI;,Mineral Wool (Rock, Slag or Glass Wool) or Synthetic Vitreous Fiber;,Silencers or Sound Traps, air duct, sheet steel or sheet steel and,insulating material combined;,Sleeves or Hollow Shapes, mold riser, exothermic compound;,Sound Deadening Material, NOI;,In packages, subject to Items 170 and 171 and having a density in pounds per,cubic foot of:,Less than 1,1 but less than 2,2 but less than 4,4 but less than 6,6 but less than 8,8 but less than 10,10 but less than 12,12 but less than 15',
            'itemno': '103300-5',
            'parentitemno': 103000,
            'itemdescription': '6 but less than 8',
            'class': '125',
            'displayclass': '125',
            'group_title': 'INSULATION OR INSULATING MATERIAL GROUP'
        },
        {
            'searchable_description': '15 but less than 22.5,22.5 but less than 30,30 or greater,Insulation or Insulating Material, viz.:,Barriers, Shields, Windows or Glass Window Lights or Blocks, radiation,shielding, with or without frames, see Note, item 103301;,Felt or Felt Paper;,Fire Blocking or Sound Attenuation Material, consisting of glass fibers and,unexpanded vermiculite, in sheet form, cut, grooved or bent;,Insulation or Insulating Material, NOI;,Mineral Wool (Rock, Slag or Glass Wool) or Synthetic Vitreous Fiber;,Silencers or Sound Traps, air duct, sheet steel or sheet steel and,insulating material combined;,Sleeves or Hollow Shapes, mold riser, exothermic compound;,Sound Deadening Material, NOI;,In packages, subject to Items 170 and 171 and having a density in pounds per,cubic foot of:,Less than 1,1 but less than 2,2 but less than 4,4 but less than 6,6 but less than 8,8 but less than 10,10 but less than 12,12 but less than 15',
            'itemno': '103300-6',
            'parentitemno': 103000,
            'itemdescription': '8 but less than 10',
            'class': '100',
            'displayclass': '100',
            'group_title': 'INSULATION OR INSULATING MATERIAL GROUP'
        },
        {
            'searchable_description': '15 but less than 22.5,22.5 but less than 30,30 or greater,Insulation or Insulating Material, viz.:,Barriers, Shields, Windows or Glass Window Lights or Blocks, radiation,shielding, with or without frames, see Note, item 103301;,Felt or Felt Paper;,Fire Blocking or Sound Attenuation Material, consisting of glass fibers and,unexpanded vermiculite, in sheet form, cut, grooved or bent;,Insulation or Insulating Material, NOI;,Mineral Wool (Rock, Slag or Glass Wool) or Synthetic Vitreous Fiber;,Silencers or Sound Traps, air duct, sheet steel or sheet steel and,insulating material combined;,Sleeves or Hollow Shapes, mold riser, exothermic compound;,Sound Deadening Material, NOI;,In packages, subject to Items 170 and 171 and having a density in pounds per,cubic foot of:,Less than 1,1 but less than 2,2 but less than 4,4 but less than 6,6 but less than 8,8 but less than 10,10 but less than 12,12 but less than 15',
            'itemno': '103300-7',
            'parentitemno': 103000,
            'itemdescription': '10 but less than 12',
            'class': '92',
            'displayclass': '92.5',
            'group_title': 'INSULATION OR INSULATING MATERIAL GROUP'
        },
        {
            'searchable_description': '15 but less than 22.5,22.5 but less than 30,30 or greater,Insulation or Insulating Material, viz.:,Barriers, Shields, Windows or Glass Window Lights or Blocks, radiation,shielding, with or without frames, see Note, item 103301;,Felt or Felt Paper;,Fire Blocking or Sound Attenuation Material, consisting of glass fibers and,unexpanded vermiculite, in sheet form, cut, grooved or bent;,Insulation or Insulating Material, NOI;,Mineral Wool (Rock, Slag or Glass Wool) or Synthetic Vitreous Fiber;,Silencers or Sound Traps, air duct, sheet steel or sheet steel and,insulating material combined;,Sleeves or Hollow Shapes, mold riser, exothermic compound;,Sound Deadening Material, NOI;,In packages, subject to Items 170 and 171 and having a density in pounds per,cubic foot of:,Less than 1,1 but less than 2,2 but less than 4,4 but less than 6,6 but less than 8,8 but less than 10,10 but less than 12,12 but less than 15',
            'itemno': '103300-8',
            'parentitemno': 103000,
            'itemdescription': '12 but less than 15',
            'class': '85',
            'displayclass': '85',
            'group_title': 'INSULATION OR INSULATING MATERIAL GROUP'
        },
        {
            'searchable_description': '15 but less than 22.5,22.5 but less than 30,30 or greater,Insulation or Insulating Material, viz.:,Barriers, Shields, Windows or Glass Window Lights or Blocks, radiation,shielding, with or without frames, see Note, item 103301;,Felt or Felt Paper;,Fire Blocking or Sound Attenuation Material, consisting of glass fibers and,unexpanded vermiculite, in sheet form, cut, grooved or bent;,Insulation or Insulating Material, NOI;,Mineral Wool (Rock, Slag or Glass Wool) or Synthetic Vitreous Fiber;,Silencers or Sound Traps, air duct, sheet steel or sheet steel and,insulating material combined;,Sleeves or Hollow Shapes, mold riser, exothermic compound;,Sound Deadening Material, NOI;,In packages, subject to Items 170 and 171 and having a density in pounds per,cubic foot of:,Less than 1,1 but less than 2,2 but less than 4,4 but less than 6,6 but less than 8,8 but less than 10,10 but less than 12,12 but less than 15',
            'itemno': '103300-9',
            'parentitemno': 103000,
            'itemdescription': '15 but less than 22.5',
            'class': '70',
            'displayclass': '70',
            'group_title': 'INSULATION OR INSULATING MATERIAL GROUP'
        },
        {
            'searchable_description': '15 but less than 22.5,22.5 but less than 30,30 or greater,Insulation or Insulating Material, viz.:,Barriers, Shields, Windows or Glass Window Lights or Blocks, radiation,shielding, with or without frames, see Note, item 103301;,Felt or Felt Paper;,Fire Blocking or Sound Attenuation Material, consisting of glass fibers and,unexpanded vermiculite, in sheet form, cut, grooved or bent;,Insulation or Insulating Material, NOI;,Mineral Wool (Rock, Slag or Glass Wool) or Synthetic Vitreous Fiber;,Silencers or Sound Traps, air duct, sheet steel or sheet steel and,insulating material combined;,Sleeves or Hollow Shapes, mold riser, exothermic compound;,Sound Deadening Material, NOI;,In packages, subject to Items 170 and 171 and having a density in pounds per,cubic foot of:,Less than 1,1 but less than 2,2 but less than 4,4 but less than 6,6 but less than 8,8 but less than 10,10 but less than 12,12 but less than 15',
            'itemno': '103300-10',
            'parentitemno': 103000,
            'itemdescription': '22.5 but less than 30',
            'class': '65',
            'displayclass': '65',
            'group_title': 'INSULATION OR INSULATING MATERIAL GROUP'
        }]
};

@Injectable()
export class MockSearchInterceptor implements HttpInterceptor {

    constructor() {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { // TODO 9/6/17: commented out until made obsolete via refactoring
        if (environment.useMocking.search) {
            if (req.url === `${environment.apiBaseUrl}/Search` && req.method === REQUEST_TYPE_POST && req.body.filterQueries) {
                // const searchResponse = this.getDashboardViewResults(req.body);
                // const response = new HttpResponse({ body: searchResponse });
                const searchResponse = this.getSearchResults(req.body);
                const response = new HttpResponse({body: searchResponse});
                return of(response);

            } else if (req.url === `${environment.apiBaseUrl}/Search` && req.method === REQUEST_TYPE_POST) {
                const searchResponse = this.getSearchResults(req.body);
                const response = new HttpResponse({body: searchResponse});
                return of(response);

            } else if (req.url.substring(0, environment.apiBaseUrl.length + 22) === `${environment.apiBaseUrl}/MasterData/NMFC/search`) {
                const searchResponse = mockResponse_NmfcSearch;
                const response = new HttpResponse({body: searchResponse});
                return of(response);
            }
        }
        return next.handle(req);
    }

    private getSearchResults(searchRequest: SearchRequest): ResponseDTO<SearchResponse> {
        const response: ResponseDTO<SearchResponse> = {
            dto: {
                searchResults: mockResponse_Search.dto.searchResults,
                searchRequest: searchRequest,
                searchTotals: mockResponse_Search.dto.searchTotals
            },
            isValid: true,
            messages: []
        };

        return response;
    }

    // typed to any right now, will be generically typed to document type once API has returned consistent data,
    // ...and can model it with created file /models/DashboardViewSearchResponse.ts
    // private getDashboardViewResults(searchRequest: SearchRequest): ResponseDTO<Array<any>> {
    //     const response: ResponseDTO<Array<any>> = {
    //         dto: [

    //         ],
    //         isValid: true,
    //         messages: []
    //     };
    //     if (searchRequest.searchQuery.startsWith('12')) {
    //         response.dto = mockResponse_SearchResults_12.filter((searchResponse: SearchResultTypeSet) => searchResponse.entityType === searchRequest.filterQueries[0].value)[0].entities;
    //         searchRequest.filterQueries.forEach((fq: FilterQueryType) => {
    //             if (fq.type !== 'filterTerm') {
    //                 response.dto = response.dto.filter(record => record[fq.type] === fq.value);
    //             }
    //         });
    //     }
    //     return response;
    // }
}
