/* for any variable constant, see the DefinePlugins plugin
 * in the 'webpack.common.js' or 'webpack.<env>.js' files; these values are identified
 * by the double underscore (__****__) naming style, and the values are provided by webpack
 * during a webpack build and therefore may not be recognized as valid values by the compiler here
 */
import { RecordSections } from '../models/recordSections';

export const
    AppName = 'ShipRite',
    ACCESS_TYPES = {
        read: 4,
        write: 2,
        execute: 1
    },
    ACCESS_PERMISSION_LEVELS = {
        user: 'user',
        group: 'group',
        global: 'global'
    },
    ADMIN_SECTIONS_customers = 'customers',
    ADMIN_SECTIONS_groups = 'groups',
    ADMIN_SECTIONS_roles = 'roles',
    ADMIN_SECTIONS_users = 'users',
    AMERICAN_COUNTRY_CODE = 'US',
    AUTOCLEAR_SHIPMENT_OPTIONS = [30, 60, 90],
    CANADIAN_COUNTRY_CODE = 'CA',
    CARRIER_LOGO_URL = 'https://upload.wikimedia.org/wikipedia/en/0/08/Saia-logo.png',
    COD_PAYMENT_TYPE_Cashier = 'Cashier',
    COD_PAYMENT_TYPE_Consignee = 'Consignee',
    CONVERSION_FACTOR_in2cm = 2.54,
    CONVERSION_FACTOR_cubicFt2cubicM = 0.028316846711688,
    CONVERSION_FACTOR_lb2kg = 0.45359237,
    CONVERSION_FACTOR_pcf2kgm = 0.062427960576145,
    CONVERSION_FACTOR_cubicCm2cubicM = 0.000001,
    COOKIE_DOMAIN = '.shiprite.com',
    COOKIE_PATH = '/',
    CTS_CONTACT_EMAIL = 'livequotes@shipwithcts.com',
    CTS_CONTACT_PHONE = '770.874.4998',
    DashboardView_USER_CREATED = 'My Views',
    DashboardView_FUNDAMENTALS = 'Global Views',
    DashboardView_RECORD_MANAGEMENT = 'Record Management',
    DashboardView_QUOTE_MANAGEMENT = 'Quote Management',
    DashboardViewType_GLOBAL_SEARCH = 'globalSearch',
    DashboardViewType_CREATE_VIEW = 'createView',
    DashboardViewType_TEMPLATE_QUERY = 'templateQuery',
    DashboardViewType_CONTENT_QUERY = 'contentQuery',
    DashboardView_GLOBAL_SEARCH_VIEW_ORDER = 100,
    DashboardView_CREATE_VIEW_VIEW_ORDER = 101,
    DEBOUNCE_INTERVAL = 400,
    DEBOUNCE_INTERVAL_LONG = 700,
    DEFAULT_COUNTRY_CODE = 'US',
    DEFAULT_DELIVERY_TYPE_Commercial = 'C',
    DEFAULT_DIRECTION_INBOUND = 'inbound',
    DEFAULT_DIRECTION_OUTBOUND = 'outbound',
    DEFAULT_PICKUP_TYPE_Commercial = 'C',
    DEFAULT_UNIT_OF_MEASURE = 'U',
    DROPDOWN_RESULT_LIMIT = 15,
    INVOICE_NAVIGATION_SOURCE_search = 'Search Results',
    INVOICE_NAVIGATION_SOURCE_dashboard = 'Dashboard',
    INVOICE_NAVIGATION_SOURCE_reporting = 'Reporting',
    KEYBOARD_EVENT_KEY_CODES = {
        enter: 13,
        escape: 27,
        leftArrow: 37,
        rightArrow: 39,
        spaceBar: 32,
    },
    KEYBOARD_EVENT_CODES = {
        spaceBar: 'Space'
    },
    LOGIN_SETTINGS_PERMISSIONS_FIELDS = {
        admin: 'admin',
        shipments: 'shipments',
        invoices: 'invoices',
    },
    MASTER_DATA_CONTACT_TIME_VALUES = [
        '01:00',
        '01:30',
        '02:00',
        '02:30',
        '03:00',
        '03:30',
        '04:00',
        '04:30',
        '05:00',
        '05:30',
        '06:00',
        '06:30',
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
    ],
    MASTER_DATA_SECTIONS = {
        CARRIER: 'carrier',
        CONTACTS: 'contacts',
        MARKUPS: 'markups',
        PRODUCTS: 'products'
    },
    MAXIMUM_FILE_UPLOAD_SIZE_IN_MEGABYTES = 5,
    MAXIMUM_ALLOWABLE_DASHBOARD_VIEWS = 7,
    MAXIMUM_CHARACTERS_DASHBOARD_VIEW_NAME = 21, // refers to dashboard view name
    MAXIMUM_CHARACTERS_DASHBOARD_NAME = 45, // refers to dashboard name
    NAVIGATE_home = 'home',
    NODE_TYPES = {
        ELEMENT_NODE: 1,
        TEXT_NODE: 3,
        PROCESSING_INSTRUCTION_NODE: 7,
        COMMENT_NODE: 8,
        DOCUMENT_NODE: 9,
        DOCUMENT_TYPE_NODE: 10,
        DOCUMENT_FRAGMENT_NODE: 11
    },
    PAYMENT_TERMS_Prepaid = 'P',
    PAYMENT_TERMS_Collect = 'C',
    PAYMENT_TERMS_ThirdParty = '3',
    INPUT_TYPE_PASSWORD = 'password',
    INPUT_TYPE_TEXT = 'text',
    INVITE_USER_TYPE_single = 'single',
    INVITE_USER_TYPE_multi = 'multi',
    PRODUCT_CUBE_CALCULATION_MIN_VALUE = 0.0001,
    PURCHASE_ORDER_FORM_MIN_NUMERIC_VALUE = 0.0000001,
    QUOTING_ZIP_TYPES = {
        origin: 'origin',
        destination: 'destination',
    },
    RECORD_DISPLAY_STYLE_DECIMAL_PLACES = 2,
    RecordDisplayStyle_Wizard = 'Wizard',
    RecordDisplayStyle_Accordion = 'Accordion',
    RecordDisplayStyleAccordion_ExpandAll = 'Expand All',
    RecordDisplayStyleAccordion_CollapseAll = 'Collapse All',
    RecordMode_QUOTE = 'quote',
    RecordMode_CREATE = 'record-creation',
    RecordMode_VIEW = 'record',
    RECORD_SECTIONS: RecordSections = {
        Quote: 'Quote',
        GeneralRef: 'GeneralRef',
        Shipper: 'Shipper',
        Consignee: 'Consignee',
        ProductList: 'ProductList',
        CarrierRef: 'CarrierRef',
        Invoicing: 'Invoicing',
        AttachedFiles: 'AttachedFiles',
        Summary: 'Summary',
    },
    RECORD_CREATION_SHIPPER_CONSIGNEE_TIMES = [
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
    ],
    RESPONSE_MESSAGE_TYPE = {
        SUCCESS: 'SUCCESS',
        ERROR: 'ERROR',
        WARNING: 'WARNING',
        INFO: 'INFO',
        REQUIRED: 'REQUIRED',
        INVALID: 'INVALID'
    },
    UNIT_OF_MEASURE_Imperial = 'U',
    UNIT_OF_MEASURE_Metric = 'M',
    USER_LABEL_TYPES = {
        RECORD_CREATION: 'RecordCreation',
    },
    USER_LABEL_FIELDS = {
        recordCreation: {
            INBOUND: 'inboundLabel',
            OUTBOUND: 'outboundLabel',
        },
    },
    USER_PERMISSIONS_ADMIN = {
        INTERNAL: 'internal',
        EXTERNAL: 'external',
    },
    USER_SECTIONS_profile = 'profile',
    USER_SECTIONS_defaults = 'defaults',
    USER_SECTIONS_creditCard = 'creditCard',
    USER_TOKEN_KEY = 'SHIPRITE-AUTH-TOKEN',
    VALID_AMERICAN_ZIP = new RegExp(/^\d{5}$/),
    VALID_POSITIVE_INTEGER_REGEX = new RegExp(/^[0-9]*$/i),
    VALID_DECIMAL_REGEX = new RegExp(/^\d+(\.\d{1,3})?$/i);
