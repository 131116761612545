import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import {CommonDataService} from '../../../services/commonData.service';

@Component({
    selector: 'app-add-active-carriers-modal',
    templateUrl: './add-active-carriers-modal.component.html',
    styleUrls: ['./add-active-carriers-modal.component.scss']
})
export class AddActiveCarriersModalComponent implements OnInit {
    @Input()
    isCarrier: boolean = true;

    @Output() closeAddActiveCarriersModal = new EventEmitter<Array<any>>();

    public itemList = [];
    public filteredItemList = [];
    public addActiveItemsForm: UntypedFormGroup;

    constructor(
        private _fb: UntypedFormBuilder,
        private _commonDataService: CommonDataService,
    ) {}

    async ngOnInit() {
        const itemList = (this.isCarrier) ? this._commonDataService.carriers : this._commonDataService.availableAccessorials;

        await this._commonDataService.loadedPromise(itemList);

        this.itemList = itemList.map((item) => ((this.isCarrier) ? {
            name: item.carrier_name,
            code: item.scac,
            isChecked: false
        } : {
            name: item.displayName,
            code: item.code,
            isChecked: false
        }));

        this.filteredItemList = this.itemList.map(carrier => carrier);

        this.addActiveItemsForm = this._fb.group({
            searchQuery: ''
        });

        this.addActiveItemsForm.get('searchQuery').valueChanges.subscribe(query => {
            const queryRegex = new RegExp(query, 'i');
            this.filteredItemList = this.itemList.filter(item => {
                if (item.name.match(queryRegex) || item.code.match(queryRegex)) {
                    return item;
                }
            });
        });
    }

    public toggleCarrierCheckbox(code: string): void {
        const item = this.itemList.find((carrier) => carrier.code === code);

        if (item) {
            item.isChecked = !item.isChecked;
        }
    }

    public addCarriers(): void {
        const checkedItems = this.itemList.filter(item => item.isChecked);

        this.closeAddActiveCarriersModal.emit(checkedItems);
    }

    public cancelChanges(): void {
        this.closeAddActiveCarriersModal.emit();
    }
}
