import {Component, OnChanges, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';

import {UserHelper} from 'app/helpers/userHelper';
import {Country} from 'app/models/country';
import {StateMasterData} from 'app/models/state.masterData';
import * as Constants from '../../../constants/constants';
import * as DateConstants from '../../../constants/datetime.constants';
import * as Defaults from '../../../constants/defaults';
import * as MasterdataConstants from '../../../constants/masterData.constants';
import {ContactMasterData} from '../../../models/contact.masterData';
import {PreviousRouteService} from '../../../services/previous-route.service';
import {CommonDataService} from "../../../services/commonData.service";
import {ContactsMasterDataService} from "../../../services/contacts.masterdata.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-master-data-contacts',
    styleUrls: ['./masterData-contacts.component.scss'],
    templateUrl: './masterData-contacts.component.html'
})
export class MasterDataContactsComponent implements OnChanges, OnInit {
    private contactId: number;
    public masterDataContact: ContactMasterData = null;
    public contactForm: UntypedFormGroup;

    public contactFormStates: Array<StateMasterData> = [];
    public contactFormCountries: Array<Country> = [];
    public contactFormOpenCloseTimes: Array<string> = Constants.MASTER_DATA_CONTACT_TIME_VALUES;
    public rootMasterDataUrl: string = MasterdataConstants.MASTER_DATA_ROOT_URL;
    public areMasterDataBreadcrumbsDisplayed: boolean = false;
    private rootGlobalSearchUrl: string = '/search';
    public areGlobalSearchResultsBreadcrumbsDisplayed: boolean = false;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _fb: UntypedFormBuilder,
        private _userHelper: UserHelper,
        private _previousRouteService: PreviousRouteService,
        private _commonDataService: CommonDataService,
        private _contactsMasterDataService: ContactsMasterDataService,
        private _notificationService: NotificationService,
    ) {
    }

    public ngOnInit() {
        if (this._route.snapshot.paramMap.get('contactId')) {
            this.contactId = parseInt(this._route.snapshot.paramMap.get('contactId'), 10);
            if ((!this.masterDataContact) || (this.contactId !== this.masterDataContact.contactsId)) {
                this._contactsMasterDataService.getMasterDataContact(this.contactId).subscribe(response => {
                    this.masterDataContact = response;
                    this.assignComponentPropertiesFromContact();
                });
            }
        } else {
            this.masterDataContact = null;
            this.assignComponentPropertiesFromContact();
        }

        this.contactFormStates = this._commonDataService.states;
        this.contactFormCountries = this._commonDataService.countries;

        const prevRouteUrl = this._previousRouteService.getPreviousUrl();

        if (prevRouteUrl === this.rootMasterDataUrl) {
            this.areMasterDataBreadcrumbsDisplayed = true;
        } else if (prevRouteUrl === this.rootGlobalSearchUrl) {
            this.areGlobalSearchResultsBreadcrumbsDisplayed = true;
        }

        this.assignComponentPropertiesFromContact();
    }

    public ngOnChanges() {
        this.assignComponentPropertiesFromContact();
    }

    // ==========================================================================================
    // ========================== CONTACT FORM SETUP AND API METHODS ===========================
    // ==========================================================================================

    private assignComponentPropertiesFromContact(): void {
        if (this.masterDataContact) {
            const openTime = this.assignContactFormOpenClose(this.masterDataContact.hoursOpen);
            const closeTime = this.assignContactFormOpenClose(this.masterDataContact.hoursClose);
            this.contactForm = this._fb.group({
                name: new UntypedFormControl(this.masterDataContact.Name, [
                    Validators.required
                ]),
                alias: this.masterDataContact.Name2,
                address1: new UntypedFormControl(this.masterDataContact.Address1, [
                    Validators.required
                ]),
                address2: this.masterDataContact.Address2,
                city: new UntypedFormControl(this.masterDataContact.City, [
                    Validators.required
                ]),
                state: new UntypedFormControl(this.masterDataContact.State, [
                    Validators.required
                ]),
                zip: new UntypedFormControl(this.masterDataContact.Zip, [
                    Validators.required
                ]),
                country: this.masterDataContact.country,
                contact: this.masterDataContact.Contact,
                email: this.masterDataContact.Email,
                phone: this.masterDataContact.Phone,
                fax: this.masterDataContact.Fax,
                open: this._fb.group({
                    hourMinute: openTime.hourMinute,
                    meridian: openTime.meridian
                }),
                close: this._fb.group({
                    hourMinute: closeTime.hourMinute,
                    meridian: closeTime.meridian
                }),
                callForAppt: (this.masterDataContact.callAppt ? true : false),
                locationType: this.masterDataContact.commercialResidential,
                shipperContactType: (this.masterDataContact.contact_shipper ? true : false),
                thirdPartyContactType: (this.masterDataContact.contact_thirdparty ? true : false),
                consigneeContactType: (this.masterDataContact.contact_consignee ? true : false),
                billToContactType: (this.masterDataContact.contact_billto ? true : false)
            });
        } else {
            this.clearMasterDataContactFormInfo();
        }
    }

    private clearMasterDataContactFormInfo(): void {
        this.contactForm = this._fb.group({
            name: new UntypedFormControl('', [
                Validators.required
            ]),
            alias: '',
            address1: new UntypedFormControl('', [
                Validators.required
            ]),
            address2: '',
            city: new UntypedFormControl('', [
                Validators.required
            ]),
            state: new UntypedFormControl('', [
                Validators.required
            ]),
            zip: new UntypedFormControl('', [
                Validators.required
            ]),
            country: 'US',
            contact: '',
            email: '',
            phone: '',
            fax: '',
            open: this._fb.group({
                hourMinute: Defaults.MASTER_DATA_CONTACT_DEFAULT_TIME_FORM_VALUE.hourMinute,
                meridian: Defaults.MASTER_DATA_CONTACT_DEFAULT_TIME_FORM_VALUE.meridian
            }),
            close: this._fb.group({
                hourMinute: Defaults.MASTER_DATA_CONTACT_DEFAULT_TIME_FORM_VALUE.hourMinute,
                meridian: Defaults.MASTER_DATA_CONTACT_DEFAULT_CLOSE_TIME_FORM_VALUE.meridian
            }),
            callForAppt: false,
            locationType: 'C',
            shipperContactType: false,
            thirdPartyContactType: false,
            consigneeContactType: false,
            billToContactType: false
        });
    }

    private assignContactFormOpenClose(contactTimeValue: string): { hourMinute: string, meridian: string } {
        if (!contactTimeValue) {
            return {
                hourMinute: Defaults.MASTER_DATA_CONTACT_DEFAULT_TIME_FORM_VALUE.hourMinute,
                meridian: Defaults.MASTER_DATA_CONTACT_DEFAULT_TIME_FORM_VALUE.meridian
            };
        }

        const parsedTimeValue = moment(
            contactTimeValue,
            DateConstants.TIME_FORMAT_12_HOUR_MINUTE_SECOND
        ).format(DateConstants.TIME_FORMAT_12_HOUR_MINUTE_MERIDIAN);
        const hourMinute = parsedTimeValue.substring(0, 5);
        const meridian = parsedTimeValue.substring(5);

        return {hourMinute, meridian};
    }

    // ==========================================================================================
    // =========================== CONTACT FORM PREP AND SUBMISSION METHODS ===========================
    // ==========================================================================================

    private prepareContact(): ContactMasterData {
        let contact: ContactMasterData;
        if (this.masterDataContact) {
            contact = {
                ...this.masterDataContact,
                Name: this.contactForm.value.name,
                Name2: this.contactForm.value.alias,
                Address1: this.contactForm.value.address1,
                Address2: this.contactForm.value.address2,
                City: this.contactForm.value.city,
                State: this.contactForm.value.state,
                Zip: this.contactForm.value.zip,
                country: this.contactForm.value.country,
                Contact: this.contactForm.value.contact,
                Email: this.contactForm.value.email,
                Phone: this.contactForm.value.phone,
                Fax: this.contactForm.value.fax,
                hoursOpen: this.prepareContactFormOpenCLose(this.contactForm.value.open) || Defaults.RECORD_CREATION_DEFAULT_TIME_VALUE,
                hoursClose: this.prepareContactFormOpenCLose(this.contactForm.value.close) || Defaults.RECORD_CREATION_DEFAULT_TIME_VALUE,
                callAppt: (this.contactForm.value.callForAppt ? 1 : 0),
                commercialResidential: this.contactForm.value.locationType,
                contact_shipper: (this.contactForm.value.shipperContactType ? 1 : 0),
                contact_thirdparty: (this.contactForm.value.thirdPartyContactType ? 1 : 0),
                contact_consignee: (this.contactForm.value.consigneeContactType ? 1 : 0),
                contact_billto: (this.contactForm.value.billToContactType ? 1 : 0)
            }
        } else {
            contact = {
                // form values
                Name: this.contactForm.value.name,
                Name2: this.contactForm.value.alias,
                Address1: this.contactForm.value.address1,
                Address2: this.contactForm.value.address2,
                City: this.contactForm.value.city,
                State: this.contactForm.value.state,
                Zip: this.contactForm.value.zip,
                country: this.contactForm.value.country,
                Contact: this.contactForm.value.contact,
                Email: this.contactForm.value.email,
                Phone: this.contactForm.value.phone,
                Fax: this.contactForm.value.fax,
                hoursOpen: this.prepareContactFormOpenCLose(this.contactForm.value.open) || Defaults.RECORD_CREATION_DEFAULT_TIME_VALUE,
                hoursClose: this.prepareContactFormOpenCLose(this.contactForm.value.close) || Defaults.RECORD_CREATION_DEFAULT_TIME_VALUE,
                callAppt: (this.contactForm.value.callForAppt ? 1 : 0),
                commercialResidential: this.contactForm.value.locationType,
                contact_shipper: (this.contactForm.value.shipperContactType ? 1 : 0),
                contact_thirdparty: (this.contactForm.value.thirdPartyContactType ? 1 : 0),
                contact_consignee: (this.contactForm.value.consigneeContactType ? 1 : 0),
                contact_billto: (this.contactForm.value.billToContactType ? 1 : 0),
                // defaulted values for API call
                contactsId: null,
                Customer: this._userHelper.getUserCustomer(),
                ID: null,
                Active: 0,
                default_contact: 0,
            };
        }

        return contact;
    }

    private prepareContactFormOpenCLose({hourMinute, meridian}: { hourMinute: string, meridian: string }): string {
        if (!hourMinute || !meridian) {
            return '';
        }

        const unparsedTimeValue = hourMinute + meridian;
        return moment(
            unparsedTimeValue,
            DateConstants.TIME_FORMAT_12_HOUR_MINUTE_MERIDIAN
        ).format(DateConstants.TIME_FORMAT_24_HOUR_MINUTE_SECOND);
    }

    public saveChanges(): void {
        const isContactFormDirty = this.contactForm.dirty;
        const isContactFormValid = this.contactForm.valid;

        if (!isContactFormValid) {
            const requiredFormControls: Array<AbstractControl> = [
                this.contactForm.controls.name,
                this.contactForm.controls.address1,
                this.contactForm.controls.city,
                this.contactForm.controls.state,
                this.contactForm.controls.zip
            ];

            requiredFormControls.forEach(contactFormControl => {
                if (contactFormControl.invalid) {
                    contactFormControl.markAsTouched();
                }
            })
        }

        if (!isContactFormDirty || !isContactFormValid) {
            return;
        }

        const contact = this.prepareContact();
        if (contact.contactsId) {
            this._contactsMasterDataService.updateMasterDataContact(contact).subscribe(() => {
                this._notificationService.notifySuccess({
                    title: 'Master Data - Update Contact',
                    message: `Contact successfully updated.`
                });
                this._contactsMasterDataService.getMasterDataContactsList(this._userHelper.getUserCustomer()).subscribe();
            });
        } else {
            this._contactsMasterDataService.createMasterDataContact(contact).subscribe((response) => {
                this._notificationService.notifySuccess({
                    title: 'Master Data - Create Contact',
                    message: `Contact successfully created with id: ${response.locationId}`
                });
                this._contactsMasterDataService.getMasterDataContactsList(this._userHelper.getUserCustomer()).subscribe();
            });
        }
    }

    public deleteContact(): void {
        this._contactsMasterDataService.deleteMasterDataContact(this.masterDataContact.contactsId, false).subscribe(() => {
            this._notificationService.notifySuccess({
                title: 'Master Data - Delete Contact',
                message: `Contact ${this.masterDataContact.contactsId} successfully deleted.`
            });
            this._contactsMasterDataService.getMasterDataContactsList(this._userHelper.getUserCustomer()).subscribe();
        });
    }

    // ==========================================================================================
    // ============================ CONTACT FORM UI CHANGE METHODS =====================================
    // ==========================================================================================

    public toggleContactFormCallForAppt(): void {
        const callForApptFormControl: UntypedFormControl = this.contactForm.get('callForAppt') as UntypedFormControl;
        const currentContactFormCallForApptValue = callForApptFormControl.value;

        callForApptFormControl.markAsDirty();
        callForApptFormControl.setValue(!currentContactFormCallForApptValue);
    }

    public setContactFormLocationType(selectedLocationType: string): void {
        const locationTypeFormControl: UntypedFormControl = this.contactForm.get('locationType') as UntypedFormControl;

        locationTypeFormControl.markAsDirty();
        locationTypeFormControl.setValue(selectedLocationType);
    }

    public toggleContactFormContactType(toggledContactTypeField: string): void {
        const toggledContactTypeFormControl: UntypedFormControl = this.contactForm.get(toggledContactTypeField) as UntypedFormControl;
        const currentToggledFieldValue = toggledContactTypeFormControl.value;

        toggledContactTypeFormControl.markAsDirty();
        toggledContactTypeFormControl.setValue(!currentToggledFieldValue);
    }

    public revertContactChanges(): void {
        this.assignComponentPropertiesFromContact();
    }

    public navigateToDashboard(): void {
        this._router.navigate(['']);
    }

    public navigateToSearch(): void {
        this._router.navigate(['search']);
    }

    public navigateToMasterDataManagement(): void {
        this._router.navigate(['masterData']);
    }

}
