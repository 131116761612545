import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import {DragulaService} from 'ng2-dragula';

import {map} from 'rxjs/operators';
import * as Constants from '../../../constants/constants';
import * as DashboardConstants from '../../../constants/dashboard.constants';
import * as SearchContstants from '../../../constants/searchCriteria';
import {DashboardHelper} from '../../../helpers/dashboardHelper';
import {UserHelper} from '../../../helpers/userHelper';
import {ContactSearchResult} from '../../../models/contact.searchResult';
import {Dashboard} from '../../../models/dashboard';
import {DashboardView} from '../../../models/dashboardView';
import {Favorite} from '../../../models/favorite';
import {FavoriteRequest} from '../../../models/favoriteRequest';
import {InvoiceSearchResult} from '../../../models/invoice.searchResult';
import {MarkupSearchResult} from '../../../models/markup.searchResult';
import {ProductSearchResult} from '../../../models/product.searchResult';
import {QuoteSearchResult} from '../../../models/quote.searchResult';
import {SearchCriterion} from '../../../models/searchCriterion';
import {ShipmentSearchResult} from '../../../models/shipment.searchResult';
import {UserSearchResult} from '../../../models/user.searchResult';
import {CommonDataService} from '../../../services/commonData.service';
import {ShipmentService} from '../../../services/shipment.service';
import {TourService} from '../../../services/tour.service';
import {ShipmentNote} from "../../../models/shipmentNote";
import {SignalsService} from "../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {FavoritesService} from "../../../services/favorites.service";
import {NotificationService} from "../../../services/notification.service";
import {InvoiceService} from "../../../services/invoice.service";
import {DashboardService} from "../../../services/dashboard.service";

@Component({
    selector: 'app-dashboard-view-content',
    styleUrls: [
        './dashboard-view-content.component.scss',
        '../../../../assets/css/styles-dragula.scss'
    ],
    templateUrl: './dashboard-view-content.component.html',
})
export class DashboardViewContentComponent implements OnInit, OnDestroy {
    public activeDashboard: Dashboard;
    public activeDashboardView: DashboardView;
    private dashboardViewContentSubscription: any;
    public dashboardViewContent: Array<any>;
    public dashboardViewTypes: {
        global_search: string;
        create_view: string;
        template_query: string;
        content_query: string;
        help_center: string;
    } = DashboardConstants.DASHBOARD_VIEW_TYPES;

    private displayDataSubscription: any;
    public displayData;

    public columnHeaders = [];
    private appStateSubscription: any;
    private dashboardsSubscription;
    public dashboards: Array<Dashboard>;
    public showSearchAll: boolean = false;
    public filteredDashboardViewContent: Array<any>;
    public filterAllQuery: string = '';
    public showCancelSearchButton: boolean = false;
    public filterTerm: string = '';

    public sortedBy: string = null; // column.modelName
    public sortAsc?: boolean = null;
    public expandFilterByColumnRow: boolean = false;

    public favoritesSubscription: any;
    public userFavorites: Array<Favorite> = [];
    public isShipmentNotesVisible = false;
    public userName;
    public shipmentNotes: Array<ShipmentNote>;

    private appState$;
    private favorites$;
    private displayData$;
    private dashboards$;
    private dashboardViewContent$;

    constructor(
        private _router: Router,
        private _dragula: DragulaService,
        private _dashboardHelper: DashboardHelper,
        private _favoritesService: FavoritesService,
        private _signalsService: SignalsService,
        private _dashboardService: DashboardService,
        private _invoiceService: InvoiceService,
        private _notificationService: NotificationService,
        private _tourService: TourService,
        public _userHelper: UserHelper,
        public _commonDataService: CommonDataService,
        public _shipmentService: ShipmentService,
    ) {
        this.appState$ = toObservable(this._signalsService.appStateSignal);
        this.favorites$ = toObservable(this._signalsService.favoritesSignal);
        this.displayData$ = toObservable(this._signalsService.displaySignal);
        this.dashboards$ = toObservable(this._signalsService.dashboardsSignal);
        this.dashboardViewContent$ = toObservable(this._signalsService.dashboardViewContentSignal);
    }

    ngOnInit() {
        this.userName = this._userHelper.getUserProfile().name;
        this.dashboardsSubscription = this.dashboards$.subscribe((dashboards: Array<Dashboard>) => {
            if (dashboards) {
                this.dashboards = dashboards;
            }

            this.activeDashboard = this._dashboardHelper.getActiveDashboard();
            this.activeDashboardView = this._dashboardHelper.getActiveDashboardView();
            this.setDashboardViewColumns();
        });

        this.appStateSubscription = this.appState$.subscribe((appState) => {
            if (this.activeDashboard && appState['dashboard.activeDashboardId'] !== this.activeDashboard.id) {
                this.activeDashboard = this._dashboardHelper.getActiveDashboard();
            }

            if (!this.activeDashboardView || (this.activeDashboardView && appState['dashboard.activeDashboardViewId'] !== this.activeDashboardView.id)) {
                this.activeDashboardView = this._dashboardHelper.getActiveDashboardView();
                this.setDashboardViewColumns();
            }
        });

        this.dashboardViewContentSubscription = this.dashboardViewContent$.subscribe((dVC: Array<any>) => {
            this.dashboardViewContent = dVC;
            this.filteredDashboardViewContent = dVC;
        });

        this.favoritesSubscription = this.favorites$.subscribe((favs: Array<Favorite>) => {
            this.userFavorites = favs;
        });

        this.displayDataSubscription = this.displayData$.subscribe(displayData => {
            this.displayData = displayData;
            this.setDashboardViewColumns();
        });

        this._dragula.createGroup('bag-view-columns', {
            revertOnSpill: true,
            direction: 'horizontal',
        });

        this._dragula.drop('bag-view-columns').subscribe(columns => {
            const viewArray = [];
            if (columns.name !== 'bag-view-columns') {
                return;
            }
            viewArray.push(columns.el);
            viewArray.push(columns.target);
            viewArray.push(columns.source);
            viewArray.push(columns.sibling);
            this.reorderDashboardViewColumns(viewArray);
        });
    }

    private setDashboardViewColumns() {
        if (this.activeDashboardView && this.activeDashboardView.viewContentQuery) {
            this.activeDashboardView.viewContentQuery.forEach((searchCriterion: SearchCriterion) => {
                if (searchCriterion.entityType) {
                    this.filterTerm = searchCriterion.entityType;
                }
            });
        }

        if (this.activeDashboardView && this.activeDashboardView.templateContentQuery) {
            this.filterTerm = this.activeDashboardView.templateContentQuery.entityType;
        }

        if (this.activeDashboardView && this.activeDashboardView.visibleColumns) {
            this.columnHeaders = this.activeDashboardView.visibleColumns.map(col => {
                return {modelName: col, showSearch: false, searchTerm: ''}
            });
        }
    }

    private reorderDashboardViewColumns(columns) {
        if (this.activeDashboardView !== this._dashboardHelper.getActiveDashboardView()) {
            return;
        }

        const reorderedDashboardViewColumns = [];
        const [draggedColumnNode, columnHeaderContainerNode] = columns;
        for (let index = 0; index < columnHeaderContainerNode.childNodes.length; index++) {
            const columnHeaderNode = columnHeaderContainerNode.childNodes[index];
            if (columnHeaderNode.nodeType === Constants.NODE_TYPES.ELEMENT_NODE) {
                const modelName = columnHeaderNode.getAttribute('modelname');
                reorderedDashboardViewColumns.push(modelName);
            }
        }
        const updatedDashboardView: DashboardView = {
            ...this.activeDashboardView,
            visibleColumns: reorderedDashboardViewColumns
        };

        let isColumnOrderDifferent = false;
        this.activeDashboardView.visibleColumns.forEach((col: string, index: number) => {
            if (col !== updatedDashboardView.visibleColumns[index]) {
                isColumnOrderDifferent = true;
                return;
            }
        });

        if (isColumnOrderDifferent) {
            this._dashboardService.updateDashboardView(updatedDashboardView, this.activeDashboard.id);
        }
    }

    ngOnDestroy() {
        this.filterAllQuery = '';
        this.columnHeaders.forEach((col: { modelName: string, showSearch: false, searchTerm: string }) => {
            col.searchTerm = '';
            col.showSearch = false;
        });
        this.dashboardsSubscription.unsubscribe();
        this.appStateSubscription.unsubscribe();
        this.dashboardViewContentSubscription.unsubscribe();
        this.displayDataSubscription.unsubscribe();
        this._dragula.destroy('bag-view-columns');
    }

    // ==============================================================================
    // ============================= FILTER METHODS =================================
    // ==============================================================================
    public expandViewContentSearch() {
        this.showSearchAll = true;
    }

    public hideSearchAll() {
        this.filteredDashboardViewContent = this.dashboardViewContent;
        this.showSearchAll = false;
    }

    public filterByColumn(): void {
        let filteredResults = this.dashboardViewContent;
        this.columnHeaders.forEach((col: { modelName: string, showSearch: false, searchTerm: string }) => {
            if (!col.showSearch) {
                col.searchTerm = '';
            }
            if (col.showSearch && col.searchTerm) {
                const columnSearchPattern = new RegExp(col.searchTerm, 'i');
                filteredResults = filteredResults.filter(record => {
                    if (record[col.modelName]) {
                        let formattedRecord = record[col.modelName];
                        if (moment(record[col.modelName], moment.ISO_8601).isValid() && typeof record[col.modelName] === 'string') {
                            formattedRecord = moment(record[col.modelName]).format('L');
                        }
                        return formattedRecord.toString().match(columnSearchPattern);
                    }
                });
            }
        });
        this.filteredDashboardViewContent = filteredResults;
    }

    public showColumnSearch(column) {
        this.expandFilterByColumnRow = true;
        if (!column.showSearch) {
            column.showSearch = true;
        }
        this.showCancelSearchButton = true;
    }

    public cancelColumnFilter() {
        // ban symbol - cancels the filter by column functionality and disappears the col filter row
        this.expandFilterByColumnRow = false;
        this.columnHeaders.forEach((col: { modelName: string, showSearch: false, searchTerm: string }) => {
            col.showSearch = false;
        });
        this.filterByColumn();
        this.showCancelSearchButton = false;
    }

    public clearColumnFilterQuery(column) {
        // x symbol - deletes searchTerm
        column.searchTerm = '';
        this.filterByColumn();
    }

    public filterAll() {
        const filteredResults = [];
        const searchPattern = new RegExp(this.filterAllQuery, 'i');
        this.activeDashboardView.visibleColumns.forEach(col => {
            this.dashboardViewContent.forEach(record => {
                if (record[col]) {
                    if (record[col].toString().match(searchPattern)) {
                        let included = false;
                        for (let i = 0; i < filteredResults.length; i++) {
                            if (filteredResults[i] === record) {
                                included = true;
                            }
                        }
                        if (!included) {
                            filteredResults.push(record);
                        }
                    }
                }
            });
        });
        this.filteredDashboardViewContent = filteredResults;
    }

    // ==============================================================================
    // ============================= FAVORITE METHODS ==============================
    // ==============================================================================
    public selectAllFavorites() {
        if (!this.filterTerm) {
            return;
        }
        const allFavorites: Array<FavoriteRequest> = this.filteredDashboardViewContent.map((record): FavoriteRequest => this._favoritesService.prepareFavoriteRequest(this.filterTerm, record));
        this._favoritesService.addMultipleFavorites(allFavorites);
    }

    public unselectAllFavorites() {
        if (!this.filterTerm) {
            return;
        }

        const selectedFavorites = this.filteredDashboardViewContent.filter(record => this.isFavorite(record));
        const selectedFavoriteNumbers = selectedFavorites.map((record): { favoriteNo: number } => {
            return {favoriteNo: this._favoritesService.getFavoriteNumber(this.filterTerm, record)};
        });
        this._favoritesService.removeMultipleFavorites(selectedFavoriteNumbers);
    }

    public checkIfAllFavorite(): boolean {
        if (!this.filterTerm || !this.filteredDashboardViewContent.length) {
            return false;
        }
        const favoritedRecords = this.filteredDashboardViewContent.filter((record) => this.isFavorite(record));
        if (favoritedRecords.length === this.filteredDashboardViewContent.length) {
            return true;
        }
        return false;
    }

    public isFavorite(entity: any): boolean {
        if (!this.filterTerm) {
            return;
        }

        return this._favoritesService.isFavorite(this.filterTerm, entity);
    }

    public addSelectedFavorite(event: Event, record) {
        event.stopPropagation();
        if (!this.filterTerm) {
            return;
        }
        const favoriteReq: FavoriteRequest = this._favoritesService.prepareFavoriteRequest(this.filterTerm, record);
        this._favoritesService.addFavorite(favoriteReq);
    }

    public removeSelectedFavorite(event: Event, record) {
        event.stopPropagation();
        if (!this.filterTerm) {
            return;
        }

        const favoriteNo = this._favoritesService.getFavoriteNumber(this.filterTerm, record);
        this._favoritesService.removeFavorite(favoriteNo);
    }

    // ==============================================================================
    // ============================= NAVIGATION METHODS ===========================
    // ==============================================================================
    public navigateToRecord(record: any, modelName: string): void {
        if (modelName === 'shipmentNotes') {
            this.isShipmentNotesVisible = true;
            this._shipmentService.getShipmentNotes(record.customer, record.id).pipe(
                map(result => {
                    this.shipmentNotes = result;
                    this._signalsService.updateAppState({ 'floater.shipmentNotesCollapsed': false });
                })
            ).subscribe();
            return;
        }
        switch (this.filterTerm) {

            case SearchContstants.SEARCH_CRITERIA_entityType.SHIPMENTS:
                if (!record.id || !record.customer) {
                    if (!record.id) {
                        this._notificationService.notifyError({
                            title: 'Navigate to Record',
                            message: 'Unable to navigate to record, ID is missing'
                        });
                    } else {
                        this._notificationService.notifyError({
                            title: 'Navigate to Record',
                            message: 'Unable to navigate to record, Customer is missing'
                        });
                    }
                    break;
                }

                this.navigateToShipmentRecord(record);
                break;

            case SearchContstants.SEARCH_CRITERIA_entityType.QUOTES:
                if (!record.id) {
                    this._notificationService.notifyError({
                        title: 'Navigate to Record',
                        message: 'Unable to navigate to record, ID is missing'
                    });
                    break;
                }

                this.navigateToQuoteRecord(record);
                break;

            case SearchContstants.SEARCH_CRITERIA_entityType.INVOICES:
                if (!record.id) {
                    this._notificationService.notifyError({
                        title: 'Navigate to Invoice',
                        message: 'Unable to navigate to invoice, ID is missing'
                    });
                    break;
                }

                this.navigateToInvoiceRecord(record);
                break;

            case SearchContstants.SEARCH_CRITERIA_entityType.MASTER_DATA_CONTACTS:
                this.navigateToMasterDataContact(record);
                break;

            case SearchContstants.SEARCH_CRITERIA_entityType.MASTER_DATA_PRODUCTS:
                this.navigateToMasterDataProduct(record);
                break;

            case SearchContstants.SEARCH_CRITERIA_entityType.MASTER_DATA_MARKUPS:
                this.navigateToMasterDataMarkup(record);
                break;

            case SearchContstants.SEARCH_CRITERIA_entityType.USERS:
                this.navigateToUserRecord(record);
                break;

            default:
                break;
        }
    }

    public navigateToInvoiceRecord(r: InvoiceSearchResult): void {
        if (!r.id) {
            this._notificationService.notifyError({
                title: 'Navigate to Invoice',
                message: 'Unable to navigate to invoice, ID is missing'
            });
            return;
        }

        this._invoiceService.getInvoice(r.id);
        this._signalsService.updateAppState({'invoice.navigationOrigin': Constants.INVOICE_NAVIGATION_SOURCE_dashboard});
        this._router.navigate(['invoice', r.id]);
    }

    public navigateToQuoteRecord(r: QuoteSearchResult): void {
        this._shipmentService.navigateViaQuote(r.id);
    }

    public navigateToShipmentRecord(r: ShipmentSearchResult): void {
        this._shipmentService.navigateViaShipment(r.customer, r.id);
    }

    public navigateToUserRecord(r: UserSearchResult): void {
        // this._router.navigate(['admin/users', r.userid]);
        this._notificationService.notifyWarning({
            title: 'Navigate to User Record',
            message: 'Functionality is in progress!'
        });
    }

    public navigateToMasterDataProduct(product: ProductSearchResult): void {
        this._router.navigate(['masterData/products', product.productid]);
    }

    public navigateToMasterDataContact(contact: ContactSearchResult): void {
        this._router.navigate(['masterData/contacts', contact.contactsid]);
    }

    public navigateToMasterDataRecord(r: any): void {
        this._router.navigate(['masterData', r.type, r.id]);
    }

    public navigateToMasterDataMarkup(r: MarkupSearchResult): void {
        this._router.navigate(['masterData/markups', r.id]);
    }

    // ==============================================================================
    // ============================= SORT METHODS =================================
    // ==============================================================================
    public sortOn(columnModelName: string): void {
        if (this.sortedBy === columnModelName) {
            this.sortAsc = !this.sortAsc;
        } else {
            this.sortedBy = columnModelName;
            this.sortAsc = true;
        }
        this.filteredDashboardViewContent = this.sort(this.filteredDashboardViewContent);
    }

    private sort(content: Array<any>): Array<any> {
        let sortedContent = _.sortBy(content, [this.sortedBy]);
        if (this.sortAsc === false) {
            sortedContent = _.reverse(sortedContent);
        }
        return sortedContent;
    }

    public isSortedAscending(columnModelName: string): boolean {
        if (this.sortedBy === columnModelName && this.sortAsc) {
            return true;
        }
        return null;
    }

    public isSortedDescending(columnModelName: string): boolean {
        if (this.sortedBy === columnModelName && this.sortAsc === false) {
            return true;
        }
        return null;
    }

    private resetSortCriteria(): void {
        this.sortedBy = null;
        this.sortAsc = null;
    }

    // =======================================================================================
    // ============================== GUIDED TOUR RELATED METHODS ===========================
    // =======================================================================================
    public removeHelpCenterView(): void {
        const helpCenterDashboardView: DashboardView = this._dashboardHelper.getActiveDashboardView();
        const dashboardId: number = this._dashboardHelper.getActiveDashboard().id;

        this._dashboardService.deleteDashboardView(helpCenterDashboardView, dashboardId);
    }

    public startShipmentsTour() {
        this._tourService.startShipmentsTour();
    }

    public startQuickQuoteTour() {
        this._tourService.startQuickQuoteTour();
    }

    public startClipboardTour(): void {
        this._tourService.startClipboardTour();
    }

    public startDashboardTour(): void {
        this._tourService.startDashboardTour();
    }
}
