import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { InvoiceDetail } from '../models/invoice.detail';
import { ResponseDTO } from '../models/responseDto';
import { ApiService, REQUEST_TYPE_GET } from '../services/api.service';
import {NotificationService} from "../services/notification.service";
import {SignalsService} from "../services/signals.service";

@Injectable()
export class InvoiceService {

    constructor(
        private _api: ApiService,
        private _notificationService: NotificationService,
        private _signalsService: SignalsService,
    ) { }

    public getInvoice(id: string): void {
        const req = new HttpRequest(REQUEST_TYPE_GET, `Invoices/Main/${id}`);
        this._api.callApiService<ResponseDTO<InvoiceDetail>>(req).subscribe(
            (response) => {
                if (response.isValid && response.dto) {
                    this._signalsService.currentInvoiceSignal.set(response.dto);
                } else {
                    this._signalsService.currentInvoiceSignal.set({});
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Load Invoice` });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.notifyError({ title: `Load Invoice Error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
            });
    }
}
