import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ClipboardNote, CreateClipboardNoteRequest, CreateClipboardNoteResponse } from '../models/clipboardNote';
import { ResponseDTO } from '../models/responseDto';
import { ApiService, REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST } from '../services/api.service';
import {SignalsService} from "./signals.service";
import {NotificationService} from "./notification.service";

@Injectable()
export class ClipboardService {

    constructor(
        private _api: ApiService,
        private _notificationService: NotificationService,
        private _signalsService: SignalsService,
    ) { }

    public createClipboardNote(newNote: CreateClipboardNoteRequest): void {
        const req = new HttpRequest(REQUEST_TYPE_POST, `MasterData/Clipboard`, newNote);
        this._api.callApiService<ResponseDTO<Array<CreateClipboardNoteResponse>>>(req).subscribe(
            (response) => {
                if (response && response.isValid && response.dto && response.dto.length === 1 && response.dto[0].clipboardID > 0) {
                    this.getAllClipboardNotes(); // simply do full refresh
                } else {
                    this._notificationService.notifyError({ title: 'Clipboard Note', message: `Invalid response for POST ClipboardNote` });
                }
            },
            (err) => {
                this._notificationService.notifyError({ title: 'Clipboard Notes', message: JSON.stringify(err) });
            });
    }

    public getAllClipboardNotes(): void {
        const req = new HttpRequest(REQUEST_TYPE_GET, `MasterData/Clipboard`);
        this._api.callApiService<ResponseDTO<Array<ClipboardNote>>>(req).subscribe(
            (response) => {
                if (response && response.dto) {
                    this._signalsService.clipsSignal.set(response.dto);
                } else {
                    this._notificationService.notifyError({ title: 'Clipboard Notes', message: `Invalid response for GET ClipboardNotes` });
                }
            },
            (err) => {
                this._notificationService.notifyError({ title: 'Clipboard Notes', message: JSON.stringify(err) });
            });
    }

    public deleteClipboardNote(id: number): void {
        const req = new HttpRequest(REQUEST_TYPE_DELETE, `MasterData/Clipboard/${id}`);
        this._api.callApiService<ResponseDTO<ClipboardNote>>(req).subscribe(
            (response) => {
                if (response.isValid) {
                    this.getAllClipboardNotes(); // simply do full refresh
                } else {
                    this._notificationService.notifyError({ title: 'Clipboard Note', message: `Invalid response for DELETE ClipboardNote ${id}` });
                }
            },
            (err) => {
                this._notificationService.notifyError({ title: 'Clipboard Notes', message: JSON.stringify(err) });
            });
    }

}
