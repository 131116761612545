import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import * as _ from 'lodash-es';
import { MarkupRateTierOption } from '../../../models/markupRateTierOption';
import {CommonDataService} from '../../../services/commonData.service';

@Component({
    selector: 'app-fuel-markup-search-modal',
    templateUrl: './fuel-markup-search-modal.component.html',
    styleUrls: ['./fuel-markup-search-modal.component.scss']
})

export class FuelMarkupSearchModalComponent implements OnInit, OnDestroy {
    @Output() public closeFuelMarkupSearchModal = new EventEmitter();
    @Output() public passFuelMarkupOption = new EventEmitter();

    public rateTierFuelRateOptions: Array<MarkupRateTierOption> = [];
    public filteredRateTierFuelRateOptions: Array<MarkupRateTierOption> = [];
    public fuelMarkupSearchForm: UntypedFormGroup;

    private fuelMarkupFormValueChangesSubscription: any;

    constructor(
        private _fb: UntypedFormBuilder,
        private _commonDataService: CommonDataService,
    ) { }

    public async ngOnInit() {
        await this._commonDataService.loadedPromise(this._commonDataService.fuelOptions);
        this.rateTierFuelRateOptions = _.cloneDeep(this._commonDataService.fuelOptions);
        this.filteredRateTierFuelRateOptions = this.rateTierFuelRateOptions;

        this.fuelMarkupSearchForm = this._fb.group({
            searchTerm: ''
        });

        this.fuelMarkupFormValueChangesSubscription = this.fuelMarkupSearchForm.valueChanges.subscribe(values => {
            this.filterResults();
        })
    }

    public ngOnDestroy() {
        this.fuelMarkupFormValueChangesSubscription.unsubscribe();
    }

    public closeModal(): void {
        this.closeFuelMarkupSearchModal.emit('');
    }

    public filterResults(): void {
        const searchTerm = this.fuelMarkupSearchForm.get('searchTerm').value;
        const searchPattern = new RegExp(searchTerm, 'i');

        this.filteredRateTierFuelRateOptions = this.rateTierFuelRateOptions.filter((result: MarkupRateTierOption) => {
            if (result.markupName.match(searchPattern) || result.markupDescription.match(searchPattern)) {
                return result;
            }
        });
    }

    public selectFuelMarkupOption(fuelMarkupOption: MarkupRateTierOption): void {
        this.passFuelMarkupOption.emit(fuelMarkupOption);
    }
}
