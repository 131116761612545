import { Injectable } from '@angular/core';

import * as Constants from '../constants/constants';
import { Dashboard } from '../models/dashboard';
import { DashboardView } from '../models/dashboardView';
import {SignalsService} from "../services/signals.service";
import {makeClone} from "./utilities";
import {AppStateService} from "../services/app-state.service";

@Injectable()
export class DashboardHelper {

    constructor(
        private _appStateService: AppStateService,
        private _signalsService: SignalsService,
    ) { }

    public getDashboardState(): Array<Dashboard> {
        return this._signalsService.dashboardsSignal();
    }

    public getActiveDashboard(): Dashboard {
        let activeDashboardId: number = null;
        const appState = this._appStateService.getAppState();

        if (appState['dashboard.activeDashboardId']) {
            activeDashboardId = appState['dashboard.activeDashboardId'];
        }

        const dashboards: Array<Dashboard> = this.getDashboardState();
        const activeDashboard = dashboards.find((dB: Dashboard) => dB.id === activeDashboardId);

        return activeDashboard;
    }

    public getActiveDashboardView(): DashboardView {
        const activeDashboardViewId = this.getActiveDashboardViewId();
        const activeDashboard: Dashboard = this.getActiveDashboard();

        let activeDashboardView: DashboardView;
        if (activeDashboard && activeDashboard.views) {
            activeDashboardView = activeDashboard.views.find((view: DashboardView) => view.id === activeDashboardViewId);
        }
        return activeDashboardView;
    }

    public getActiveDashboardViewId(): number {
        const appState = this._appStateService.getAppState();
        let activeDashboardViewId: number;
        if (appState['dashboard.activeDashboardViewId']) {
            activeDashboardViewId = appState['dashboard.activeDashboardViewId'];
        }

        return activeDashboardViewId;
    }

    public doesDashboardHaveMaxNumberOfViews(): boolean {
        const activeDashboard = this.getActiveDashboard();
        if (activeDashboard && activeDashboard.views && (activeDashboard.views.length >= Constants.MAXIMUM_ALLOWABLE_DASHBOARD_VIEWS)) {
            return true;
        }

        return false;
    }

    public loadDashboards(dashboards: Array<Dashboard>) {
        this._signalsService.dashboardsSignal.set(makeClone(dashboards));
    }

    public loadDashboard(dashboardToLoad: Dashboard) {
        let dashboards = this._signalsService.dashboardsSignal();
        let newDashboards;
        const existingDashboardIndex = dashboards.findIndex(dashboard => {
            return dashboard.id === dashboardToLoad.id;
        });

        if (existingDashboardIndex !== -1) {
            // state contains dashboard to be updated
            newDashboards = makeClone(dashboards);
            newDashboards[existingDashboardIndex] = dashboardToLoad;
        } else {
            // state does not contain the dashboard to be updated
            newDashboards = makeClone(dashboards);
            newDashboards.push(dashboardToLoad);
        }
        this._signalsService.dashboardsSignal.set(newDashboards);
    }

    loadDashboardView(dashboardViewInfo: { dashboardView: any, dashboardId: number }) {
        let newDashboards = makeClone(this._signalsService.dashboardsSignal());
        newDashboards.find((db: Dashboard) => db.id === dashboardViewInfo.dashboardId)
            .views.push(dashboardViewInfo.dashboardView);
        this._signalsService.dashboardsSignal.set(newDashboards);
    }

    updateDashboardView(dashboardViewInfo: { updatedDashboardView: any, dashboardId: number }) {
        let newDashboards = makeClone(this._signalsService.dashboardsSignal());
        let activeDashboard = newDashboards.find((db: Dashboard) => db.id === dashboardViewInfo.dashboardId);
        const updatedDashboardViewIndex = activeDashboard.views.findIndex((dbv: DashboardView) => dbv.id === dashboardViewInfo.updatedDashboardView.id);
        activeDashboard.views[updatedDashboardViewIndex] = dashboardViewInfo.updatedDashboardView;
        this._signalsService.dashboardsSignal.set(newDashboards);
    }

    removeDashboard(id: number) {
        let newDashboards = makeClone(this._signalsService.dashboardsSignal());
        const removedDashboardIndex = newDashboards.findIndex((db: Dashboard) => db.id === id);
        if (removedDashboardIndex !== -1) {
            newDashboards.splice(removedDashboardIndex, 1);
        }
        this._signalsService.dashboardsSignal.set(newDashboards);
    }

    removeDashboardView(dashboardViewInfo: {dashboardViewId: number, dashboardId: number}) {
        let newDashboards = makeClone(this._signalsService.dashboardsSignal());
        const dashboardIndex = newDashboards.findIndex((db: Dashboard) => db.id === dashboardViewInfo.dashboardId);
        const removedDashboardViewIndex = newDashboards[dashboardIndex].views.findIndex((dbV: DashboardView) => dbV.id === dashboardViewInfo.dashboardViewId);
        newDashboards[dashboardIndex].views.splice(removedDashboardViewIndex, 1);
        this._signalsService.dashboardsSignal.set(newDashboards);
    }

    clearDashboards() {
        this._signalsService.dashboardsSignal.set([]);
    }

    updateDashboardViewContent(viewContent) {
        this._signalsService.dashboardViewContentSignal.set(makeClone(viewContent));
    }

    clearDashboardViewContent() {
        this._signalsService.dashboardViewContentSignal.set([]);
    }

    loadDashboardLibraryViews(newViews: Array<DashboardView>) {
        this._signalsService.dashboardViewLibrarySignal.set(newViews);
    }
}
