export class PurchaseOrder {
    poId: number;
    ID: number;
    Customer: string;
    poNumber: string;
    Packages: number;
    Info: string;
    Weight: number;
    Pallet: number;
}
