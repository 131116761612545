<div class="homePanel">
  <div class="header" id="dashboardHeader">
    <span *ngIf="!titleEditable" class="header1" id="dashboardName">{{currentDashboardName}}</span>
    <i *ngIf="!titleEditable" class="menuIcon blue clickable fa fa-pencil" id="dashboardMenuIcon" aria-hidden="true" (click)="showDashboardMenu()"></i>
    <form (ngSubmit)="updateDashboardName(activeDashboard.name)">
      <input *ngIf="titleEditable" class="editTitleInput" id="dashboardNameInput" [(ngModel)]="activeDashboard.name" name="dashboardName"
        autofocus (blur)="exitEditDashboardName()">
      <div class="maxReNameCharacterNote" *ngIf="activeDashboard?.name?.length > maxDashboardNameCharacterLength">*Please limit your view name to {{maxDashboardNameCharacterLength}} characters</div>
    </form>
  </div>
  <div class="iconsHolderContainer" id="dashboardViewIconsHolder">
    <div class="iconsHolder" *ngIf="activeDashboard?.views" [dragula]='"bag-views"' [(dragulaModel)]="activeDashboard.views">
      <div class="iconSection" id="view{{i}}" *ngFor="let view of activeDashboard?.views; let i = index" [attr.viewId]="view.id">
        <div class="icon clickable" (click)="navigateToSection(view)" [ngClass]="activeDashboardView?.id === view?.id ? 'current-' + i : 'icon-' + i"
          *ngIf="view.viewOrder !== globalSearchViewOrder && view.viewOrder !== createViewViewOrder">
          <i class="viewIcon {{view.icon}}" aria-hidden="true"></i>
          <div class="viewLabelHolder">
            <div class="label">{{view.name}}</div>
            <span class="editViewIcon">
              <i *ngIf="view.editable && view.viewType !== dashboardViewTypes.global_search && view.viewType !== dashboardViewTypes.help_center"
                class="editView clickable fa fa-pencil" (click)="showEditView(view?.id)" aria-hidden="true"></i>
            </span>
            <span class="editViewIcon">
              <i *ngIf="view.editable && (view.viewType === dashboardViewTypes.global_search) || (view.viewType === dashboardViewTypes.help_center)"
                class="editView clickable fa fa-trash-o" (click)="deleteView($event, view)" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="searchCreateViewIconsHolder">
      <div class="iconSection" *ngFor="let view of activeDashboard?.views; let i = index">
        <div class="icon clickable" id="globalSearchDashboardViewIcon" (click)="navigateToSection(view)" [ngClass]="activeDashboardView?.id === view?.id ? 'current-' + i : 'icon-' + i"
          *ngIf="view.viewOrder === globalSearchViewOrder">
          <i class="viewIcon {{view.icon}}" aria-hidden="true"></i>
          <div class="viewLabelHolder">
            <div class="label">{{view.name}}</div>
            <span class="editViewIcon">
              <i *ngIf="view.editable && view.viewType !== dashboardViewTypes.global_search && view.viewType !== dashboardViewTypes.help_center"
                class="editView clickable fa fa-pencil" (click)="showEditView(view?.id)" aria-hidden="true"></i>
            </span>
            <span class="editViewIcon">
              <i *ngIf="view.editable && (view.viewType === dashboardViewTypes.global_search) || (view.viewType === dashboardViewTypes.help_center)"
                class="editView clickable fa fa-trash-o" (click)="deleteView($event, view)" aria-hidden="true"></i>
            </span>
          </div>
        </div>
        <div class="createViewicon clickable" (click)="navigateToSection(view)" *ngIf="view.viewOrder === createViewViewOrder" id="createViewNode">
          <div class="viewLabelHolder1">
            <div class="label"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="recordsContainer" *ngIf="activeDashboard?.views">
    <app-dashboard-view-content *ngIf="activeDashboardView?.viewType === dashboardViewTypes.content_query || activeDashboardView?.viewType === dashboardViewTypes.template_query || activeDashboardView?.viewType === dashboardViewTypes.help_center">
    </app-dashboard-view-content>
  </div>
</div>

<app-dashboard-menu class="dashboardMenu" *ngIf="showMenu" [availableDashboards]="dashboards" (dashboardEditable)="renameDashboard()"
  (closeDashMenu)="hideDashboardMenu()" (showModal)="showCreateDashboardModal()" (emitDashboardId)="changeActiveDashboard($event)"
  (showConfirmDeleteModal)="showConfirmDeleteModal()" (confirmDefault)="setDashboardAsDefault()"></app-dashboard-menu>

<app-create-dashboard-modal *ngIf="showModal" (closeCreateDashboardModal)="hideCreateDashboardModal()" (emitDashboardName)="receiveCreatedDashboardName($event)"
  [maxDashboardNameCharacterLength]="maxDashboardNameCharacterLength"></app-create-dashboard-modal>

<app-ok-cancel-modal *ngIf="showOkCancelModal" [title]="dashboardDeleteTitle" [message]="dashboardDeleteMessage" (onCancel)="hideConfirmDeleteModal()"
  (onOk)="confirmDeleteDashboard()"></app-ok-cancel-modal>
