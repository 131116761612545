<div *ngIf="showVlscfs" class="contactRow">
    <div class="leftCol">VLS CFS</div>
    <app-vlscfs class="rightCol" (contactUpdate)="contactChanged($event)" [vanguardContactType]="vlscfsContactType">Select Location</app-vlscfs>
</div>

<div class="contactRow">
    <div class="leftCol typeTitle">{{getContactTypeTitle()}}<span style="font-weight: normal" class="required">*</span></div>
    <app-contact-search required [ngClass]="getClassFor(contactNameModel)" class="rightCol curved" (ngModelChange)="updateField('name', $event)"
                        [ngModel]="contact.name" (contactUpdate)="contactChanged($event)"
                        [contactField]="contactFields.NAME" [contactType]="contactType"
                        [modalTitle]="'Search ' + getContactTypeTitle() + ' Contacts'" #contactNameModel="ngModel"></app-contact-search>
</div>

<div *ngIf="showAliasLine" class="contactRow">
    <div class="leftCol">Alias</div>
    <input required [ngClass]="getClassFor(aliasModel)" class="rightCol curved" [ngModel]="contact.name2" (ngModelChange)="updateField('name2', $event)" #aliasModel="ngModel"/>
</div>

<div class="contactRow">
    <div class="leftCol">Address<span class="required">*</span></div>
    <app-contact-search required [ngClass]="getClassFor(address1Model)" class="rightCol curved" (ngModelChange)="updateField('address1', $event)"
                        [ngModel]="contact.address1" (contactUpdate)="contactChanged($event)"
                        [contactField]="contactFields.ADDRESS1" [contactType]="contactType"
                        [modalTitle]="'Search ' + getContactTypeTitle() + ' Contacts'" #address1Model="ngModel"></app-contact-search>
</div>

<div *ngIf="showSecondAddressLine" class="contactRow">
    <div class="leftCol">Address2</div>
    <input class="rightCol curved" [ngModel]="contact.address2" (ngModelChange)="updateField('address2', $event)" />
</div>

<div class="contactRow">
    <div class="leftCol">City<span class="required">*</span></div>
    <app-contact-search required [ngClass]="getClassFor(cityModel)" class="cityNZipCol curved" (ngModelChange)="updateField('city', $event)"
                        [ngModel]="contact.city" (contactUpdate)="contactChanged($event)"
                        [contactField]="contactFields.CITY" [contactType]="contactType"
                        [modalTitle]="'Search ' + getContactTypeTitle() + ' Contacts'" #cityModel="ngModel"></app-contact-search>
    <div class="stateNCountryLabelCol">State<span class="required">*</span></div>
    <app-list required [ngClass]="getClassFor(stateModel)" class="stateNCountryCol curved" [list]="states" [getKey]="getStateCode" [getLabel]="getStateCode"
              (ngModelChange)="updateState($event)" [ngModel]="listStateObj" #stateModel="ngModel"></app-list>
</div>

<div class="contactRow">
    <div class="leftCol">Postal<span class="required">*</span></div>
    <app-contact-search required [ngClass]="getClassFor(zipModel)" class="cityNZipCol curved" (ngModelChange)="updateField('zip', $event)"
                        [ngModel]="contact.zip" (contactUpdate)="contactChanged($event)"
                        (zipUpdate)="zipChanged($event)" [country]="contact.country" [contactField]="contactFields.ZIP"
                        [contactType]="contactType" [modalTitle]="'Search ' + getContactTypeTitle() + ' Contacts'" #zipModel="ngModel"></app-contact-search>
    <div class="stateNCountryLabelCol">Country<span class="required">*</span></div>
    <app-list required [ngClass]="getClassFor(countryModel)" class="stateNCountryCol curved" [list]="countries" [getKey]="getCountryCode" [getLabel]="getCountryCode"
              (ngModelChange)="updateCountry($event)" [ngModel]="listCountryObj" #countryModel="ngModel"></app-list>
</div>

<div class="contactRow">
    <div class="leftCol">Contact<span class="required">*</span></div>
    <input required [ngClass]="getClassFor(contactModel)" class="rightCol curved" [ngModel]="contact.contact" (ngModelChange)="updateField('contact', $event)" #contactModel="ngModel"/>
</div>

<div class="contactRow">
    <div class="leftCol">Phone<span class="required">*</span></div>
    <input required [ngClass]="getClassFor(contactPhoneModel)" class="phoneCol curved" [ngModel]="contact.phone" (ngModelChange)="updateField('phone', $event)" #contactPhoneModel="ngModel"/>
    <div class="faxLabelCol">Fax&nbsp;</div>
    <input class="faxCol curved" [ngModel]="contact.fax" (ngModelChange)="updateField('fax', $event)" />
</div>

<div class="contactRow">
    <div class="leftCol">Email</div>
    <input class="rightCol curved" [ngModel]="contact.email" (ngModelChange)="updateField('email', $event)" />
</div>

<div class="contactRow">
    <div class="leftCol">Open</div>
    <app-time class="timeCol" [ngModel]="contact.hoursopen" (ngModelChange)="updateField('hoursopen', $event)" ></app-time>
    <app-radio-group class="residentialCommercialCol" [allowClear]="false" [ngModel]="contact.commercialresidential" (ngModelChange)="updateField('commercialresidential', $event)" [values]="radioGroupValues" [justify]="'flex-start'"></app-radio-group>
</div>

<div class="contactRow">
    <div class="leftCol">Close</div>
    <app-time class="timeCol" [ngModel]="contact.hoursclose" (ngModelChange)="updateField('hoursclose', $event)" ></app-time>
    <app-checkbox class="appointmentCol" [ngModel]="appointmentCheckbox" (ngModelChange)="updateAppointment($event)" [label]="'Call for Appointment'" [labelAfter]="true" ></app-checkbox>
</div>

<div class="contactRow contentRight">
    <div class="linkText blue clickable" (click)="saveContact()">Save {{getContactTypeTitle()}} Info</div>
    <div class="linkText blue clickable" (click)="clearContact()">Clear {{getContactTypeTitle()}}</div>
</div>
