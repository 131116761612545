export class Product {
    // density: number;
    class: number;
    Cube: number;
    Customer: string;
    description: string;
    description2: string;
    Detail_ID: number;
    hazmat_class: string;
    hazmat_number: string;
    hazmat_packingGroup: string;
    hazmat_subClass: string;
    hazmat: number;
    Height: number;
    ID: number;
    Length: number;
    loadingMeters: number;
    name: string;
    nmfc: string;
    Pkg_Type: number;
    Product: number;
    Qty: number;
    stackable: number;
    Unit_Type: number;
    Units: number;
    Weight: number;
    Width: number;
    productId: number;
}
