import {
    Component, ContentChild,
    EventEmitter,
    HostBinding,
    HostListener,
    Input, OnInit,
    Output, TemplateRef,
} from '@angular/core';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-button',
    styleUrls: ['./app-button.component.scss'],
    templateUrl: './app-button.component.html',
})
export class AppButtonComponent implements OnInit {
    @Input()
    public padding;
    @Input()
    public textTransform = 'uppercase';
    @Input()
    public borderRadius = '3px';
    @Input()
    public menuLeft = false;
    @Input()
    public height;
    @Input()
    public menuAbove = false;
    @Input()
    public active = false;
    @Input()
    set inactive(val: boolean) {
        this._inactive = val;
    }
    get inactive() {
        return this._inactive;
    }
    @Input()
    public buttonColor = 'blue';
    @Input()
    public menu;
    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-native
    public click = new EventEmitter();
    @ContentChild('menuItemRenderer', {static: false})
    menuItemRenderer: TemplateRef<any>;

    @HostBinding('class.inactive') _inactive: boolean = false;

    public showMenu = false;
    public customStyling = {};

    constructor() {
    }

    @HostListener('keydown.space', ['$event']) preventDefaultClicked(event) {
        this.clicked(event, event);
    }

    public ngOnInit(): void {
        this.customStyling['border-radius'] = this.borderRadius;
        this.customStyling['text-transform'] = this.textTransform;
        if (this.height !== undefined) {
            this.customStyling['height'] = this.height;
        }
        if (this.padding !== undefined) {
            this.customStyling['padding'] = this.padding;
        }
    }

    public closeMenu(event) {
        event.stopPropagation();
        event.preventDefault();
        this.showMenu = false;
    }

    public clicked(event, info) {
        event.stopPropagation();
        event.preventDefault();
        if (!this.inactive) {
            if (this.menu) {
                if (this.showMenu && _.find(this.menu, (i) => i === info) ) {
                    this.click.emit(info);
                }
                this.showMenu = !this.showMenu;
            } else {
                this.click.emit(info);
            }
        }
    }

    public getMenuClass() {
        const classObj: any = {};

        if (this.menuLeft) {
            if (this.menuAbove) {
                classObj.popMenuTopLeft = true;
            } else {
                classObj.popMenuBottomLeft = true;
            }
        } else {
            if (this.menuAbove) {
                classObj.popMenuTopRight = true;
            } else {
                classObj.popMenuBottomRight = true;
            }
        }

        return classObj;
    }
}
