<div class="overlay">
    <div class="documentPropertiesModal">

        <!-- ======================== MODAL HEADER ======================== -->
        <div class="modalHeader">
            <div class="modalTitle">
                Document Properties ({{selectedDocument.fileName}})
            </div>
            <span class="closeModalIcon clickable" (click)="closeModal()">
                <i class="fa fa-times"></i>
            </span>
        </div>

        <!-- ======================== MODAL BODY ======================== -->
        <div class="modalBody">
            <form class="editDocumentPropertiesForm" [formGroup]="documentPropertiesForm" (ngSubmit)="updateFile()">
                <div class="descriptionRow">
                    <div class="editDocumentPropertiesFormLabel">
                        Description
                    </div>
                    <textarea form="documentPropertiesForm" formControlName="description" class="descriptionInput"
                              placeholder="Enter Description Here"></textarea>
                </div>
            </form>
        </div>

        <!-- ======================== MODAL FOOTER ======================== -->
        <div class="modalFooter">
            <div class="btn-outline clickable" (click)="closeModal()">
                Cancel
            </div>

            <div class="btn-outline clickable" (click)="updateFile()">
                Update Properties
            </div>
        </div>
    </div>
</div>
