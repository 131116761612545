import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import * as DateConstants from '../../../../constants/datetime.constants';
import {DateTimeHelper} from '../../../../helpers/datetime.helper';
import {MarkupRangeType} from '../../../../models/markup.rangeType';
import {RateOn} from '../../../../models/markupRateOn';
import {MarkupType} from '../../../../models/markupType';
import {RateType} from '../../../../models/rateType';
import {MarkupsService} from '../../../../services/markups.service';

@Component({
    selector: 'app-admin-markup-exceptions',
    templateUrl: './admin-markup-exceptions.component.html',
    styleUrls: ['./admin-markup-exceptions.component.scss']
})
export class AdminMarkupExceptionsComponent {
    @Input()
    public exceptionsFormArray: UntypedFormArray;
    @Input()
    public rateOnOptions: Array<RateOn>;
    @Input()
    public markupTypes: Array<MarkupType>;
    @Input()
    public markupType: MarkupType;
    @Input()
    public rangeTypes: Array<MarkupRangeType>;
    @Input()
    public markupId;

    @Output()
    add: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @Output()
    delete: EventEmitter<number> = new EventEmitter<number>();

    public isDatePickerShown = false;
    public selectedDateFormGroup: UntypedFormGroup;
    public markupDisplayDateFormat: string = DateConstants.UNIFORM_DATE_DISPLAY.format;
    public isMarkupExceptionDescriptionModalShown: boolean = false;
    public editedMarkupExceptionFormGroup: UntypedFormGroup = null;

    constructor(
        private _markupsService: MarkupsService,
        private _router: Router,
        public _dateHelper: DateTimeHelper,
    ) {}

    public showDatePicker(group): void {
        this.selectedDateFormGroup = group;
        this.isDatePickerShown = true;
    }

    public hideDatePicker({selectedDate, selectedEndDate}: { selectedDate: string, selectedEndDate?: string }): void {
        if (selectedDate) {
            this.selectedDateFormGroup.get('effective').setValue(selectedDate);
        }

        if (selectedEndDate) {
            this.selectedDateFormGroup.get('end').setValue(selectedEndDate);
        }

        this.isDatePickerShown = false;
    }

    public getRateIcon(rateTypeId) {
        const foundRateType: RateType = this.markupType.rateTypes.find((rateType) => (rateType.rateTypeID === rateTypeId));
        return (foundRateType) ? foundRateType.RateTypeIcon : undefined;
    }

    public doAddEvent($event: MouseEvent) {
        this.add.emit($event);
    }

    public doDeleteEvent(idx) {
        this.delete.emit(idx);
    }

    public showMarkupExceptionDescriptionModal(exceptionLineItem: AbstractControl): void {
        if (!exceptionLineItem) {
            return;
        }

        this.editedMarkupExceptionFormGroup = exceptionLineItem as UntypedFormGroup;
        this.isMarkupExceptionDescriptionModalShown = true;
    }

    public hideMarkupExceptionDescriptionModal(): void {
        this.isMarkupExceptionDescriptionModalShown = false;
    }

    public navigateToCarrierSelection(exceptionIndex: number): void {
        this._markupsService.setLastMarkupForm(this.exceptionsFormArray[exceptionIndex].parent.parent as UntypedFormGroup);
        this._router.navigate(['admin/markups/carrier', this.markupId, exceptionIndex]);
    }

    public navigateToAccessorialSelection(exceptionIndex: number) {
        this._markupsService.setLastMarkupForm(this.exceptionsFormArray[exceptionIndex].parent.parent as UntypedFormGroup);
        this._router.navigate(['admin/markups/accl', this.markupId, exceptionIndex]);
    }
}
