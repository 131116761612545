import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contacts',
  styleUrls: ['./contacts.component.scss'],
  templateUrl: './contacts.component.html'

})
export class ContactsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
