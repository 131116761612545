<div class="userDetailsSection padLeft padRight" *ngIf="userForm && (activeUserSection === profile)">
    <form [formGroup]="userForm">
        <button type="submit" name="formSubmission" class="btn-hidden-IE"></button>
        <div class="userDetailFormSection">
            <div class="defaultsHeader">User Details</div>
            <div class="userDefaultsFormContainer">
                <div class="leftColumn">
                    <div *ngIf="!isNewUser" class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">User ID</div>
                        <div class="userDefaultsFormValue userIdDiv">
                            <div class="userIdVal">{{ userForm?.value.userID }}</div>
                            <div *ngIf="lockedOut && isAdminInternal" class="lockoutDiv">
                                <i class="fa fa-lock" aria-hidden="true"></i>
                                <app-button (click)="doLockoutReset(userForm.value.userID)">Reset Lockout</app-button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isNewUser" class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">User Name</div>
                        <div class="userDefaultsFormValue">{{ userForm?.value.userlogin }}</div>
                    </div>
                    <div *ngIf="isNewUser" class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">
                            <span class="required">*</span>User Name</div>
                        <div class="userDefaultsFormValue">
                            <input type="text" formControlName="userlogin" [ngClass]="{ 'invalidBorder': !userlogin.pristine && userlogin.errors && (userlogin.errors!.required || userlogin.errors!.pattern) }">
                        </div>
                    </div>
                    <div *ngIf="!userlogin.pristine && userlogin.errors && (userlogin.errors!.required || userlogin.errors!.pattern)" class="userDefaultsFormRow invalidMessage">
                        <div class="userDefaultsFormLabel"></div>
                        <div class="userDefaultsFormValue">
                            {{(userlogin.errors!.required) ? 'User Name is required' : 'Spaces not allowed '}}
                        </div>
                    </div>
                    <div *ngIf="isNewUser" class="userProfileFormPasswordRow">
                        <div class="userDefaultsFormLabel">
                            <span class="required">*</span>Password
                        </div>
                        <div class="userDefaultsFormValue">
                            <app-password [passwordRegex]="passwordRegex" [passwordRegexText]="passwordRegexText" [requireOldPassword]="false" formControlName="password"></app-password>
                        </div>
                    </div>
                    <div *ngIf="!isNewUser && isCurrentUser" class="userProfileFormPasswordRow">
                        <div (click)="showPasswordChange = true;" class="userPasswordLinkLabel">
                            Change Password
                        </div>
                    </div>
                    <div *ngIf="isAuthorizedApiUser" class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">
                            Integrations Token
                        </div>
                        <div class="userDefaultsFormValue tokenContainer">
                            <div *ngIf="integrationsToken" class="tokenCol">
                                <i class="copyIcon clickable fa fa-files-o" (click)="doClipboardCopy()" aria-hidden="true"></i>{{integrationsToken}}
                            </div>
                            @if (integrationsTokenDaysLeft < 0) {
                                <span class="errorText">Token Expired!</span>
                            } @else if (integrationsTokenIsExpiring) {
                                <span class="errorText">Token expiring{{(integrationsTokenDaysLeft > 0) ? ' in ' + integrationsTokenDaysLeft + ' days!' : ' today!'}}</span>
                            }
                            <app-button (click)="getNewToken()" style="max-width: fit-content;">Get New Token</app-button>
                        </div>
                    </div>
                </div>
                <div class="rightColumn">
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">
                            <span class="required">*</span>First Name</div>
                        <div class="userDefaultsFormValue">
                            <input formControlName="firstname" [ngClass]="{ 'invalidBorder': !firstname.pristine && firstname.errors && firstname.errors!.required }">
                        </div>
                    </div>
                    <div *ngIf="!firstname.pristine && firstname.errors && firstname.errors!.required" class="userDefaultsFormRow invalidMessage">
                        <div class="userDefaultsFormLabel"></div>
                        <div class="userDefaultsFormValue">
                            First Name is required
                        </div>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">
                            <span class="required">*</span>Last Name</div>
                        <div class="userDefaultsFormValue">
                            <input formControlName="lastname" [ngClass]="{ 'invalidBorder': !lastname.pristine && lastname.errors && lastname.errors!.required }">
                        </div>
                    </div>
                    <div *ngIf="!lastname.pristine && lastname.errors && lastname.errors!.required" class="userDefaultsFormRow invalidMessage">
                        <div class="userDefaultsFormLabel"></div>
                        <div class="userDefaultsFormValue">
                            Last Name is required
                        </div>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">
                            <span class="required">*</span>Email</div>
                        <div class="userDefaultsFormValue">
                            <input formControlName="email" [ngClass]="{ 'invalidBorder': !email.pristine && email.errors && (email.errors!.required || email.errors!.pattern) }">
                        </div>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Phone</div>
                        <div class="userDefaultsFormValue">
                            <input type="text" formControlName="phone" maxlength="12">
                        </div>
                    </div>
                    <div *ngIf="!email.pristine && email.errors && (email.errors!.required || email.errors!.pattern)" class="userDefaultsFormRow invalidMessage">
                        <div class="userDefaultsFormLabel"></div>
                        <div class="userDefaultsFormValue">
                            {{(email.errors!.required) ? 'Email is required' : 'Spaces not allowed '}}
                        </div>
                    </div>
                    <div class="userDefaultsFormRow" *ngIf="isUserProfileAdminFieldVisible">
                        <div class="userProfileFormToggleLabel">Active</div>
                        <div class="userProfileFormToggleValue">
                            <span class="toggleLabel">No</span>
                            <span class="toggleIcon blue" (click)="this.userForm.controls['active'].setValue(!this.userForm.get('active').value)">
                                <i *ngIf="!userForm.value.active" class="fa fa-toggle-off" aria-hidden="true"></i>
                                <i *ngIf="userForm.value.active" class="fa fa-toggle-on" aria-hidden="true"></i>
                            </span>
                            <span class="toggleLabel">Yes</span>
                        </div>
                    </div>
                    <div class="userDefaultsFormRow" *ngIf="isNewUser || isAdminInternal">
                        <div class="userDefaultsFormLabel"><span class="required">*</span>Customer Tied To</div>
                        <div class="selectCustPanel clickable" (click)="isCustomerRolesModalShown = true">
                            <div class="customerDetailContainer">
                                <div class="customerDetailContents" *ngIf="getMatchFromList('customer', masterDataCustomersList, 'custNo'); let customerFound;">
                                    <div class="customerName" (click)="navAway($event,'admin/customers', 'customer')">{{ customerFound.custName }}</div>
                                </div>
                            </div>
                            <span>
                                <i class="fa fa-search searchIcon blue"></i>
                            </span>
                        </div>
                    </div>

                    <div class="userDefaultsFormRow" *ngIf="isUserProfileAdminFieldVisible">
                        <div class="userDefaultsFormLabel">
                            <span class="required">*</span>Role </div>
                        <div class="selectCustPanel clickable" (click)="isCustomerRoleIdModalShown = true">
                            <div class="customerDetailContainer">
                                <div class="customerDetailContents" *ngIf="getMatchFromList('roleID', masterDataRolesList, 'RoleID'); let role;">
                                    <div class="customerName" (click)="navAway($event,'admin/roles', 'roleID')">{{ role.roleName }}</div>
                                </div>
                            </div>
                            <span>
                                <i class="fa fa-search searchIcon blue"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="userDefaultsSection padLeft padRight" *ngIf="userForm && (activeUserSection === defaults)">
    <form [formGroup]="userForm">
        <button type="submit" name="formSubmission" class="btn-hidden-IE"></button>
        <div class="userDefaultsFormSection">
            <div class="defaultsHeader">User Defaults</div>
            <div class="userDefaultsFormContainer">
                <div class="leftColumn">
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Shipper</div>
                        <div class="userDefaultsFormValue">
                            <input formControlName="defaultShipper">
                        </div>
                        <span class="userDefaultsFormSelectCaret clickable">
                            <i aria-hidden="true" class="fa fa-search searchIcon"
                               (click)="showContactModal('defaultShipper', searchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER)">
                            </i>
                        </span>
                    </div>
                    <div class="userDefaultsFormRow" *ngIf="defaultShipper && userForm['controls'].defaultShipper.value">
                        <div class="addressPanel">
                            <div>{{ defaultShipper?.name }}</div>
                            <div>{{ defaultShipper?.address }}</div>
                            <div>{{ defaultShipper?.city }}
                                <span *ngIf="defaultShipper?.city">,</span> {{ defaultShipper?.state }} {{ defaultShipper?.zip
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Origin Zip Code</div>
                        <div class="userDefaultsFormValue">
                            <input type="text" formControlName="defaultOriginZip">
                        </div>
                        <span class="userDefaultsFormSelectCaret clickable" (click)="showZipSearchModal(zipTypes.origin)">
                            <i aria-hidden="true" class="fa fa-search searchIcon"></i>
                        </span>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Origin Type</div>
                        <div class="userDefaultsRadioButtonPanel">
                            <div class="originOption">
                                <span class="userDefaultsFormRadio clickable" *ngIf="userForm.value.defaultOriginType !== 'R'" (click)="userForm.controls['defaultOriginType'].setValue('R')">
                                    <i class="fa fa-circle-o"></i>
                                </span>
                                <span class="userDefaultsFormRadio clickable" *ngIf="userForm.value.defaultOriginType === 'R'" (click)="userForm.controls['defaultOriginType'].setValue('R')">
                                    <i class="fa fa-dot-circle-o"></i>
                                </span>
                                <span class="userDefaultsFormRadioLabel">Residential</span>
                            </div>
                            <div class="originOption">
                                <span class="userDefaultsFormRadio clickable" *ngIf="userForm.value.defaultOriginType !== 'C'" (click)="userForm.controls['defaultOriginType'].setValue('C')">
                                    <i class="fa fa-circle-o"></i>
                                </span>
                                <span class="userDefaultsFormRadio clickable" *ngIf="userForm.value.defaultOriginType === 'C'" (click)="userForm.controls['defaultOriginType'].setValue('C')">
                                    <i class="fa fa-dot-circle-o"></i>
                                </span>
                                <span class="userDefaultsFormRadioLabel">Commercial</span>
                            </div>
                        </div>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Commodity Products</div>
                        <div class="userDefaultsFormSelectPanel">
                            <select class="userDefaultsFormSelectEntry" formControlName="defaultCommodity">

                                <option *ngFor="let cProd of commodityProductOptions" [value]="cProd.comID">{{ cProd.comDisplayName
                                    }}
                                </option>
                            </select>
                            <span class="userDefaultsFormSelectCaret clickableCaret">
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </div>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Direction</div>
                        <div class="rolesDefaultsRowField userDefaultsRadioButtonPanel">
                            <div class="userDefaultsRowRadioOption">
                                <span class="userDefaultsFormRadio clickable" (click)="userForm['controls'].defaultDirection.setValue('import')">
                                    <i class="fa fa-circle-o" *ngIf="userForm['controls'].defaultDirection.value !== 'import'"></i>
                                    <i class="fa fa-dot-circle-o" *ngIf="userForm['controls'].defaultDirection.value === 'import'"></i>
                                </span>
                                <span class="userDefaultsFormRadioLabel">
                                    Import
                                </span>
                            </div>
                            <div class="userDefaultsRowRadioOption">
                                <span class="userDefaultsFormRadio clickable" (click)="userForm['controls'].defaultDirection.setValue('export')">
                                    <i class="fa fa-circle-o" *ngIf="userForm['controls'].defaultDirection.value !== 'export'"></i>
                                    <i class="fa fa-dot-circle-o" *ngIf="userForm['controls'].defaultDirection.value === 'export'"></i>
                                </span>
                                <span class="userDefaultsFormRadioLabel">
                                    Export
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Unit of Measure</div>
                        <div class="userDefaultsFormSelectPanel">
                            <select class="userDefaultsFormSelectEntry" formControlName="defaultUoM">
                                <option *ngFor="let uom of unitOfMeasures" [value]="uom?.unitOfMeasureCode">{{ uom.unitOfMeasure
                                    }}
                                </option>
                            </select>
                            <span class="userDefaultsFormSelectCaret clickableCaret">
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="rightColumn">
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Consignee</div>
                        <div class="userDefaultsFormValue">
                            <input formControlName="defaultConsignee">
                        </div>
                        <span class="userDefaultsFormSelectCaret clickable">
                            <i aria-hidden="true" class="fa fa-search searchIcon"
                               (click)="showContactModal('defaultConsignee', searchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE)">
                            </i>
                        </span>
                    </div>
                    <div class="userDefaultsFormRow" *ngIf="defaultConsignee && userForm['controls'].defaultConsignee.value">
                        <div class="addressPanel">
                            <div>{{ defaultConsignee?.name }}</div>
                            <div>{{ defaultConsignee?.address }}</div>
                            <div>{{ defaultConsignee?.city }}
                                <span *ngIf="defaultConsignee?.city">,</span> {{ defaultConsignee?.state }} {{ defaultConsignee?.zip
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Destination Zip Code</div>
                        <div class="userDefaultsFormValue">
                            <input type="text" formControlName="defaultDestZip">
                        </div>
                        <span class="userDefaultsFormSelectCaret clickable" (click)="showZipSearchModal(zipTypes.destination)">
                            <i aria-hidden="true" class="fa fa-search searchIcon"></i>
                        </span>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Destination Type</div>
                        <div class="userDefaultsRadioButtonPanel">
                            <div class="destOption">
                                <span class="userDefaultsFormRadio clickable" (click)="userForm.controls['defaultDestType'].setValue('R')">
                                    <i *ngIf="userForm.value.defaultDestType !== 'R'" class="fa fa-circle-o"></i>
                                    <i *ngIf="userForm.value.defaultDestType === 'R'" class="fa fa-dot-circle-o"></i>
                                </span>
                                <span class="userDefaultsFormRadioLabel">Residential</span>

                            </div>
                            <div class="originOption">
                                <span class="userDefaultsFormRadio clickable" (click)="userForm.controls['defaultDestType'].setValue('C')">
                                    <i *ngIf="userForm.value.defaultDestType !== 'C'" class="fa fa-circle-o"></i>
                                    <i *ngIf="userForm.value.defaultDestType === 'C'" class="fa fa-dot-circle-o"></i>
                                </span>
                                <span class="userDefaultsFormRadioLabel">Commercial</span>
                            </div>
                        </div>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">TSA Only</div>
                        <span id="tsaCheckbox" class="isTsaOnlyIcon clickable" (click)="toggleTsaOnly()">
                            <i *ngIf="!userForm['controls'].defaultTSA.value" class="fa fa-square-o uncheckedBox" aria-hidden="true"></i>
                            <i *ngIf="userForm['controls'].defaultTSA.value"  class="fa fa-check-square checkedBox" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormToggleLabel">Auto Save Contacts</div>
                        <div class="userDefaultsFormToggleValue">
                            <span class="toggleLabel">No</span>
                            <span class="toggleIcon blue" (click)="this.userForm.controls['autoSaveContacts'].setValue(!this.userForm.get('autoSaveContacts').value)">
                                <i *ngIf="!userForm.value.autoSaveContacts" class="fa fa-toggle-off" aria-hidden="true"></i>
                                <i *ngIf="userForm.value.autoSaveContacts" class="fa fa-toggle-on" aria-hidden="true"></i>
                            </span>
                            <span class="toggleLabel">Yes</span>
                        </div>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Class</div>
                        <div class="userDefaultsFormSelectPanel">
                            <select class="userDefaultsFormSelectEntry" formControlName="defaultClass">
                                <option [value]="0">N/A</option>
                                <option *ngFor="let c of nmfcClasses" [value]="c.class">{{ c.displayClass }}</option>
                            </select>
                            <span class="userDefaultsFormSelectCaret clickableCaret">
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </div>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Shipment Value</div>
                        <div class="userDefaultsFormValue">
                            <input type="text" formControlName="shipmentValue" [ngClass]="{ 'invalidBorder': !shipmentValue.pristine && shipmentValue.errors && shipmentValue.errors!.pattern }">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="userCreditCardSection padLeft padRight" *ngIf="userForm && (activeUserSection === creditCard)">
    <form [formGroup]="userForm">
        <button type="submit" name="formSubmission" class="btn-hidden-IE"></button>
        <div class="userCreditCardFromSection">
            <div class="defaultsHeader">Credit Card Billing Info</div>
            <div class="userDefaultsFormContainer">
                <div class="leftColumn">
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">First Name</div>
                        <div class="userDefaultsFormValue">
                            <input type="text" formControlName="ccFirstName">
                        </div>
                    </div>
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Last Name</div>
                        <div class="userDefaultsFormValue">
                            <input type="text" formControlName="ccLastName">
                        </div>
                    </div>

                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Address 1</div>
                        <div class="userDefaultsFormValue">
                            <input type="text" formControlName="ccBillingAddress1">
                        </div>
                    </div>

                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Address 2</div>
                        <div class="userDefaultsFormValue">
                            <input type="text" formControlName="ccBillingAddress2">
                        </div>
                    </div>

                </div>
                <div class="rightColumn">
                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">City</div>
                        <div class="userDefaultsFormValue">
                            <input type="text" formControlName="ccBillingCity">
                        </div>
                        <span class=" userDefaultsFormSelectCaret clickable" (click)="showZipSearchModal(zipTypes.creditCard)">
                            <i aria-hidden="true" class="fa fa-search searchIcon"></i>
                        </span>
                    </div>

                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">State</div>
                        <div class="userDefaultsFormValue">
                            <input type="text" formControlName="ccBillingSt">
                        </div>
                        <span class=" userDefaultsFormSelectCaret clickable" (click)="showZipSearchModal(zipTypes.creditCard)">
                            <i aria-hidden="true" class="fa fa-search searchIcon"></i>
                        </span>
                    </div>

                    <div class="userDefaultsFormRow">
                        <div class="userDefaultsFormLabel">Zip</div>
                        <div class="userDefaultsFormValue">
                            <input type="text" formControlName="ccBillingZip">
                        </div>
                        <span class=" userDefaultsFormSelectCaret clickable" (click)="showZipSearchModal(zipTypes.creditCard)">
                            <i aria-hidden="true" class="fa fa-search searchIcon"></i>
                        </span>
                    </div>

                </div>
            </div>

        </div>
    </form>
</div>

<app-select-contact-modal *ngIf="isSelectContactModalShown " [contactType]="contactType " [contactField]="contactField"
                          (updateContactInfo)="hideContactModal($event)" [contactInput]="contactInput"></app-select-contact-modal>
<app-zip-search-modal *ngIf="isZipSearchModalShown " [zipType]="zipSearchModalType "
                      (closeZipModal)="hideZipSearchModal($event)"></app-zip-search-modal>
<app-roles-customer-modal *ngIf='isCustomerRolesModalShown' (closeCustomerRolesModal)="isCustomerRolesModalShown = false"
                          [adminRolesCustomersList]="masterDataCustomersList"
                          (selectCustomer)="userForm.get('customer').setValue($event.custNo)"></app-roles-customer-modal>
<app-select-role-modal *ngIf='isCustomerRoleIdModalShown' (closeModal)="isCustomerRoleIdModalShown = false"
                       (selectRole)="userForm.get('roleID').setValue($event.RoleID)"></app-select-role-modal>
<app-ok-cancel-modal *ngIf="isLoseChangesWarningModalVisible" [title]="'Lose Changes'" [message]="'You will lose all changes if your proceed.  Click Cancel to prevent this.'"
                     (onOk)="doNavAway()" (onCancel)="cancelNavAway()"></app-ok-cancel-modal>
<app-password-modal [passwordRegex]="passwordRegex" [passwordRegexText]="passwordRegexText" (setPassword)="setPassword($event)" (cancel)="showPasswordChange = false" *ngIf="showPasswordChange"></app-password-modal>
