<label *ngIf="requireOldPassword" class="passwordLabels">Old Password</label>
<div *ngIf="requireOldPassword" class="passwordInputBox">
    <input (ngModelChange)="doPasswordUpdate($event, 'oldPasswordValue')" class="passwordInput input" [type]="oldPasswordElementType" required [ngModel]="oldPasswordValue" #oldPasswordElement/>
    <span class="clickable" (click)="togglePasswordVisibility('oldPasswordElementType');">
    <i class="fa fa-eye"></i>
</span>
</div>
<label class="passwordLabels">New Password</label>
<div class="passwordInputBox">
    <input #focusPoint (ngModelChange)="doPasswordUpdate($event, 'newPasswordValue')" class="passwordInput input" [type]="newPasswordElementType" [ngModel]="newPasswordValue" #newPasswordElement/>
    <span class="clickable" (click)="togglePasswordVisibility('newPasswordElementType');">
    <i class="fa fa-eye"></i>
</span>
</div>
<label class="passwordLabels">Repeat New Password</label>
<div class="passwordInputBox">
    <input (ngModelChange)="doPasswordUpdate($event, 'repeatPasswordValue')" class="passwordInput input" [type]="repeatPasswordElementType" [ngModel]="repeatPasswordValue" #repeatPasswordElement/>
    <span class="clickable" (click)="togglePasswordVisibility('repeatPasswordElementType');">
    <i class="fa fa-eye"></i>
</span>
</div>
<div *ngIf="passwordRegexText" class="infoText" [ngClass]="{'normal': !(errors?.regularExpressionFail), 'error': errors?.regularExpressionFail}">{{passwordRegexText}}</div>
<div class="infoText" [ngClass]="{'normal': !(errors?.passwordsDontMatch), 'error': errors?.passwordsDontMatch}">{{passwordMatchText}}</div>
<div *ngIf="errors?.noOldPassword" class="infoText error">{{noOldPasswordText}}</div>
