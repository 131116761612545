import {MarkupRangeType} from '../models/markup.rangeType';
import {RateOn} from '../models/markupRateOn';
import {MarkupType} from '../models/markupType';

export const
    CUBE_CALCULATOR_UOM_LABELS = {
        imperial: {
            length: 'in',
            width: 'in',
            height: 'in',
            volume: `ft<sup>3</sup>`,
            weight: 'lbs',
            density: 'pcf',
        },
        metric: {
            length: 'cm',
            width: 'cm',
            height: 'cm',
            volume: `m<sup>3</sup>`,
            weight: 'kg',
            density: 'kgm',
        }
    },
    DEFAULT_MARKUP_TYPE: MarkupType = {
        displayMarkupType: '',
        markupType: '',
        rateTypes: [],
    },
    MASTER_DATA_SECTIONS = {
        products: 'Products',
        carriers: 'Carriers',
        contacts: 'Contacts'
    },
    MASTER_DATA_SECTION_PATHS = {
        products: 'products',
        carriers: 'carriers',
        contacts: 'contacts'
    },
    MASTER_DATA_CONTACT_FIELDS = {
        NAME: 'Name',
        ADDRESS1: 'Address1',
        CITY: 'City',
        STATE: 'State',
        ZIP: 'Zip',
    },
    MARKUP_TAB_SECTIONS = {
        general: 'GENERAL',
        definitionAndExceptions: 'EXCEPTIONS',
        sampleQuote: 'SAMPLE_QUOTE'
    },
    ROLE_TAB_SECTIONS = {
        general: 'GENERAL',
        permissions: 'PERMISSIONS',
        quoting: 'QUOTING',
        shipments: 'SHIPMENTS',
        defaultsAndDisclaimer: 'DEFAULTS_DISCLAIMER'
    },
    MARKUP_RELATIVE_PATH = '/admin/markups',
    MARKUP_NEW_PATH = '/admin/markups/new',
    MARKUP_EXCEPTION_RELATIVE_PATH = '/admin/markups/exceptionCarrier',
    MARKUP_DATE_VALUE_indefinitely = '9999-12-31',
    MARKUP_DISPLAY_DATE_VALUE_indefinitely = 'Indefinitely',
    MARKUP_RATE_DEFAULTS = {
        minMarkup: 0,
        maxMarkup: 5000,
        maxMarkupType: 0,
    },
    MARKUP_EXCEPTION_DEFAULTS = {
        maxMarkupType: 0,
        minMarkup: 0,
        maxMarkup: 100000,
        rangeMin: 0,
        rangeMax: 5000,
    },
    MARKUP_RANGE_MIN = 0,
    MARKUP_RANGE_MAX = 100000,
    MARKUP_RANGE_TYPE_DEFAULTS: MarkupRangeType = {
        rangeTypeID: 'PCF',
        displayRangeType: 'Total PCF'
    },
    MARKUP_RATE_ON_DEFAULTS: RateOn = {
        rateOnID: 'AP',
        displayRateOn: 'Net'
    },
    MARKUP_RATE_TYPE_ICONS = {
        usd: '$',
        percent: '%',
    },
    MARKUP_TYPE_DEFAULTS: MarkupType = {
        displayMarkupType: 'Fuel',
        markupType: 'FUEL',
        rateTypes: [
            {
                RateTypeIcon: '%',
                displayRateType: 'Percent',
                rateTypeID: 1
            }
        ]
    },
    ROLE_PERMISSION_DETAIL_TYPES = {
        GLOBAL: 'global',
        GROUP: 'group',
        USER: 'user',
    },
    ROLE_PERMISSION_DETAIL_FIELDS = {
        QUOTES: 'quotes',
        SHIPMENTS: 'shipments',
        INVOICES: 'invoices',
        CONTACTS: 'contacts',
        PRODUCTS: 'products',
        CARRIER: 'carrier',
        CUSTOMERS: 'customers',
        USERS: 'users',
        MARKUPS: 'markups',
        ROLES: 'roles',
    },
    ROLE_PERMISSION_DETAIL_VALUES = {
        VIEW: 'view',
        EDIT: 'edit',
        NONE: 'none',
    },
    ROLE_PERMISSION_DETAIL_DEFAULTS = {
        quote: 'none',
        shipment: 'none',
        invoice: 'none',
        contact: 'none',
        product: 'none',
        carrier: 'none',
        customers: 'none',
        users: 'none',
        markups: 'none',
        roles: 'none'
    },
    TEMPLATE_TYPES_quote = 'Q',
    TEMPLATE_TYPES_role = 'R',
    TEMPLATE_TYPES_shipment = 'S',
    MASTER_DATA_ROOT_URL = '/masterData';
