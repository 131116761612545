<div class="reportsInvoiceListComponent">
    <!-- =========================== INVOICE REPORT LIST HEADER =========================== -->

    <div class="invoicesReportHeader">
        <div class="invoicesReportHeaderTextPanel">
            <span class="invoicesReportHeaderTextMain">
                Reporting
            </span>
            <span class="invoicesReportHeaderBreadCrumbs">
                Reporting &gt;
                <span class="invoicesReportHeaderBreadCrumbsFinal">Invoice Summary</span>
            </span>
        </div>
        <app-button (click)="exportReport()">Export to Excel File</app-button>
    </div>

    <!-- =========================== INVOICE REPORT LIST FILTER TOOLS =========================== -->
    <form class="filterControlsPanel" [formGroup]="invoiceReportForm">
        <div class="filterControlsPanel">
            <div class="invoiceReportsTypeControls">
                <div class="reportsTypeLabel">View Reports By</div>
                <div class="selectPanel clickableCaretExtension">
                    <select class="selectTag" formControlName="summaryType">
                        <option *ngFor="let groupType of invoiceReportGroupTypes" [value]="groupType?.value">{{ groupType?.displayName
                            }}</option>
                    </select>
                    <span class="selectCaret clickableCaret clickableCaretPositioning">
                        <i class="fa fa-caret-down"></i>
                    </span>
                </div>
            </div>
            <div class="invoiceReportDateTypeControls">
                <div class="reportsTypeLabel">for Date Type</div>
                <div class="selectPanel clickableCaretExtension">
                    <select class="selectTag" formControlName="dateType">
                        <option *ngFor="let dateType of invoiceReportDateTypes" [value]="dateType?.value">{{ dateType?.displayName
                            }}</option>
                    </select>
                    <span class="selectCaret clickableCaret clickableCaretPositioning">
                        <i class="fa fa-caret-down"></i>
                    </span>
                </div>
            </div>
            <div class="invoiceReportDateRangeControls">
                <div class="reportsTypeLabel">within Date Range</div>
                <div class="dateRangeContainer">
                    <div class="dateRangePanel" [ngClass]="{'dateRangeInvalid': invoiceReportForm &&
                    (
                        (invoiceReportForm['controls'].startdate && invoiceReportForm['controls'].startdate.errors && invoiceReportForm['controls'].startdate.errors.invalidDateRange) ||
                        (invoiceReportForm['controls'].endDate && invoiceReportForm['controls'].endDate.errors && invoiceReportForm['controls'].endDate.errors.invalidDateRange)
                    )}">
                        <input type="text" class="dateRangeStart" formControlName="startDate">
                        <span class="dateRangeText">
                            to
                        </span>
                        <input type="text" class="dateRangeEnd" formControlName="endDate">
                        <span class="dateRangeIcon blue clickable" (click)="showDatePicker()">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="invoiceReportControlsButtonHolder">
                <div class="btn-outline" (click)="updateReport()" [ngClass]="{
                    'active': isInvoiceFormDirty && isDateRangeValid(),
                    'inactive': !isDateRangeValid()
                }">Update</div>
            </div>
        </div>
    </form>
    <!-- =========================== INVOICE REPORT LIST BODY =========================== -->
    <div class="reportInvoicesListContainer">
        <div class="reportInvoicesListHeaderRow">
            <div class="firstRowItem">{{ firstColName }}</div>
            <div class="headerItem">Paid $</div>
            <div class="headerItem">Paid %</div>
            <div class="headerItem">Weight</div>
            <div class="headerItem">Weight %</div>
            <div class="headerItem">Shipments</div>
            <div class="headerItem">Shipments %</div>
            <div class="headerItem">Cartons</div>
            <div class="headerItem">Carton Cost</div>
            <div class="headerItem">Fuel Cost</div>
            <div class="headerItem">CWT Cost</div>
            <div class="headerItem longHeaderItem">Shipment Cost</div>
            <!-- <div class="headerItem longHeaderItem">Shipment Weight</div> -->
            <div class="headerItem">Mile Cost</div>
        </div>

        <div class="reportInvoicesDetailContainer">
            <div class="totalsRow" *ngIf="invoiceSummaryTotals">
                <div class="totalFirstRowItem">Total Cost</div>
                <div class="rowDetailItem">${{ invoiceSummaryTotals?.AmountPaidTotal | number }}</div>
                <div class="rowDetailItem"></div>
                <div class="rowDetailItem">{{ invoiceSummaryTotals?.WeightTotal | number }}</div>
                <div class="rowDetailItem"></div>
                <div class="rowDetailItem">{{ invoiceSummaryTotals?.ShipmentTotal | number }}</div>
                <div class="rowDetailItem"></div>
                <div class="rowDetailItem">{{ invoiceSummaryTotals?.CartonsTotal | number }}</div>
                <div class="rowDetailItem">${{ invoiceSummaryTotals?.CostPerCartonTotal }}</div>
                <div class="rowDetailItem">${{ invoiceSummaryTotals?.FuelCostTotal | number }}</div>
                <div class="rowDetailItem">${{ invoiceSummaryTotals?.CostPerCWTTotal }}</div>
                <div class="rowDetailItem">${{ invoiceSummaryTotals?.CostPerShipmentTotal }}</div>
                <div class="rowDetailItem">${{ invoiceSummaryTotals?.CostPerMileTotal }}</div>
            </div>
            <div class="reportInvoicesDetailRow clickable borderBottomShadow" (click)="navigateToInvoiceDetail(ir)" *ngFor="let ir of invoiceSummaryReports">
                <div class="firstRowItem">{{ ir?.firstColumn }}</div>
                <div class="rowDetailItem">${{ ir?.AmountPaid | number }}</div>
                <div class="rowDetailItem">{{ ir?.PaidPercent }}%</div>
                <div class="rowDetailItem">{{ ir?.Weight | number }}</div>
                <div class="rowDetailItem">{{ ir?.WeightPercent }}%</div>
                <div class="rowDetailItem">{{ ir?.Shipment | number }}</div>
                <div class="rowDetailItem">{{ ir?.ShipmentPercent }}%</div>
                <div class="rowDetailItem">{{ ir?.Cartons | number }}</div>
                <div class="rowDetailItem">${{ ir?.CostPerCarton }}</div>
                <div class="rowDetailItem">${{ ir?.FuelCost | number }}</div>
                <div class="rowDetailItem">${{ ir?.CostPerCWT }}</div>
                <div class="rowDetailItem">${{ ir?.CostPerShipment  }}</div>
                <div class="rowDetailItem">${{ ir?.CostPerMile }}</div>
            </div>
        </div>
    </div>
</div>

<app-custom-datepicker *ngIf="isDatePickerShown" [isDateRangePossible]="true" [title]="'Select Date Range'" [dateFormatPattern]="reportDisplayDateFormat"
    [inputDate]="formattedStartDate" (closeDatePicker)="hideDatePicker($event)" [dateFormatValidationPattern]="reportDisplayDateValidation"
    [isDateRangeOnly]="true" [inputEndDate]="formattedEndDate"></app-custom-datepicker>
