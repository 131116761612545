import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dashboard } from '../../../models/dashboard';

@Component({
  selector: 'app-dashboard-menu',
  styleUrls: ['./dashboard-menu.component.scss'],
  templateUrl: './dashboard-menu.component.html'

})
export class DashboardMenuComponent implements OnInit {
  @Input() public availableDashboards: Array<Dashboard>;
  @Output() public dashboardEditable = new EventEmitter();
  @Output() public closeDashMenu = new EventEmitter();
  @Output() public showModal = new EventEmitter();
  @Output() public emitDashboardId = new EventEmitter();
  @Output() public showConfirmDeleteModal = new EventEmitter();
  @Output() public confirmDefault = new EventEmitter();

  private createdDashboardName: string;

  constructor() { }

  ngOnInit() {
    this.positionDashboardMenuIcon();
  }

  public renameDashTitle(): void {
    this.dashboardEditable.emit('');
  }

  public showCreateDashboardModal(): void {
    this.showModal.emit('');
    this.closeMenu();
  }

  public chooseActiveDashboard(id): void {
    this.emitDashboardId.emit(id);
  }

  public closeMenu(): void {
    this.closeDashMenu.emit('');
  }

  public deleteDashboard(): void {
    this.showConfirmDeleteModal.emit('');
  }

  public setDefault(): void {
    this.confirmDefault.emit(true);
    this.closeMenu();
  }

  private positionDashboardMenuIcon(): void {
    const
      iconTop = document.getElementById('dashboardMenuIcon').offsetTop,
      iconRight = document.getElementById('dashboardMenuIcon').offsetLeft + document.getElementById('dashboardMenuIcon').offsetWidth,
      menu = document.getElementById('dashboardMenu'),
      menuWidth = menu.offsetWidth,
      menuLeft = iconRight - menuWidth,
      iconLeft = document.getElementById('dashboardMenuIcon').getBoundingClientRect().left;

    menu.style.top = `${iconTop + 60}px`;
    menu.style.left = `${iconLeft - 12.4}px`;
  }
}
