import {Injectable} from "@angular/core";
import {Breadcrumb} from "../models/breadCrumb";
import {LoggingService, LogLevels} from "./logging.service";
import {clearArray} from "../helpers/utilities";
import * as Constants from "../constants/constants";

function breadcrumbIsSame(a: Breadcrumb, b: Breadcrumb) {
    return (a.urlValue === b.urlValue) && (a.label === b.label);
}

@Injectable()
export class BreadcrumbService {
    breadcrumbs: Array<Breadcrumb> = [];

    constructor(private _loggingService: LoggingService) {
    }

    public setBreadcrumbs(breadcrumbs: Array<Breadcrumb>): void {
        if (Array.isArray(breadcrumbs)) {
            clearArray(this.breadcrumbs);
            this.breadcrumbs.push(...breadcrumbs);
        } else {
            this._loggingService.sendLogMessage(LogLevels.ERROR, `invalid breadcrumb data provided to breadcrumb actions`);
        }
    }

    public popBreadcrumb() {
        this.breadcrumbs.pop();
    }

    public addOrUpdateBreadcrumb(breadcrumb: Breadcrumb) {
        if (breadcrumb) {
            if (this.breadcrumbs.length && breadcrumbIsSame(this.breadcrumbs[this.breadcrumbs.length - 1], breadcrumb)) {
                return;
            }
            if (this.breadcrumbs.length && (this.breadcrumbs[this.breadcrumbs.length - 1].urlValue === breadcrumb.urlValue)) {
                this.breadcrumbs[this.breadcrumbs.length - 1] = breadcrumb;
            } else {
                this.breadcrumbs.push(breadcrumb);
            }
        } else {
            this._loggingService.sendLogMessage(LogLevels.ERROR, `invalid breadcrumb data provided to breadcrumb actions`);
        }
    }

    public addBreadcrumb(breadcrumbs: Array<Breadcrumb>): void {

        if (breadcrumbs && breadcrumbs.length) {
            if (this.breadcrumbs.length && breadcrumbIsSame(this.breadcrumbs[this.breadcrumbs.length - 1], breadcrumbs[0])) {
                return;
            }

            breadcrumbs.forEach((bc: Breadcrumb) => {
                this.breadcrumbs.push(bc);
            });
        } else {
            this._loggingService.sendLogMessage(LogLevels.ERROR, `invalid breadcrumb data provided to breadcrumb actions`);
        }
    }

    public clearBreadcrumbs(): void {
        clearArray(this.breadcrumbs);
    }

    public breadcrumbsForSearch(): Array<Breadcrumb> {
        const firstBreadcrumb: Breadcrumb = {
            label: 'Dashboard',
            urlValue: Constants.NAVIGATE_home
        }

        const secondBreadcrumb: Breadcrumb = {
            label: 'Global Search',
        }

        return [firstBreadcrumb, secondBreadcrumb];
    }

    public breadcrumbsForReports(searchCriteriaValue: string, entityType: string): Array<Breadcrumb> {
        const breadcrumbs: Array<Breadcrumb> = [
            {
                label: 'Reporting',
                urlValue: `reports/${entityType}`
            },
            {
                label: searchCriteriaValue,
                urlValue: `reports/${entityType}`,
            },
            {
                label: 'Search Results',
            }
        ];

        return breadcrumbs;
    }
}
