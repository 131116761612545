import { HttpErrorResponse } from '@angular/common/http';
import {Subscription} from 'rxjs';
import {ContactMasterData} from '../models/contact.masterData';
import {ContactSearchResult} from '../models/contact.searchResult';
import {Product} from '../models/product';
import {ProductMasterData} from '../models/product.masterData';
import {ProductSearchResult} from '../models/product.searchResult';
import {ProductDetail} from '../models/productDetail';

export const makeClone = (original) => {
    return JSON.parse(JSON.stringify(original));
};

export function getRootCauseErrorMessage(err: HttpErrorResponse): string {
    let errMsg;
    if (err && err.error && err.error.error && err.error.error.message) {
        errMsg = err.error.error.message;
    } else {
        errMsg = `${JSON.stringify(err)}`;
    }
    return errMsg;
}

export function unsubscribe(subscription: Subscription|undefined) {
    if (subscription) {
        subscription.unsubscribe();
    }
}

export function filterList(sourceList, fields, filterField, filterTerm) {
    const searchPattern = new RegExp(filterTerm, 'i');
    let filteredList;

    filteredList = sourceList.filter((data) => {
        let field;

        if (filterField === 'all') {
            for (field of fields) {
                if ((field.filterField !== 'all') && data[field.filterField].toString().match(searchPattern)) {
                    return data;
                }
            }
        } else if (data[filterField].toString().match(searchPattern)) {
            return data;
        }
    });

    return filteredList;
}

export function ifNotUndefinedAssign(toObj, prop, value) {
    if (value !== undefined) {
        toObj[prop] = value;
    }
}

export function searchResultToProductMasterData(searchResult: ProductSearchResult): ProductMasterData {
    const productMD = new ProductMasterData();

    ifNotUndefinedAssign(productMD, 'Active', searchResult.active);
    ifNotUndefinedAssign(productMD, 'class', searchResult.productclass);
    ifNotUndefinedAssign(productMD, 'Customer', searchResult.customer);
    ifNotUndefinedAssign(productMD, 'Description', searchResult.description);
    ifNotUndefinedAssign(productMD, 'description2', searchResult.description2);
    ifNotUndefinedAssign(productMD, 'displayClass', searchResult.displayclass);
    ifNotUndefinedAssign(productMD, 'GL_Code', searchResult.gl_code);
    ifNotUndefinedAssign(productMD, 'Hazmat', searchResult.hazmat);
    ifNotUndefinedAssign(productMD, 'hazmat_class', searchResult.hazmat_class);
    ifNotUndefinedAssign(productMD, 'Hazmat_number', searchResult.hazmat_number);
    ifNotUndefinedAssign(productMD, 'hazmat_packingGroup', searchResult.hazmat_packinggroup);
    ifNotUndefinedAssign(productMD, 'hazmat_subClass', searchResult.hazmat_subclass);
    ifNotUndefinedAssign(productMD, 'ID', searchResult.id);
    ifNotUndefinedAssign(productMD, 'Marks', searchResult.marks);
    ifNotUndefinedAssign(productMD, 'Name', searchResult.name);
    ifNotUndefinedAssign(productMD, 'NMFC', searchResult.nmfc);
    ifNotUndefinedAssign(productMD, 'nmfc', searchResult.nmfc);
    ifNotUndefinedAssign(productMD, 'OutsideID', searchResult.outsideid);
    ifNotUndefinedAssign(productMD, 'Pack', searchResult.pack);
    ifNotUndefinedAssign(productMD, 'produtsId', searchResult.productid);
    ifNotUndefinedAssign(productMD, 'Type', searchResult.producttype);
    ifNotUndefinedAssign(productMD, 'Type2', searchResult.type2);

    return productMD;
}

export function productMasterDataToProduct(productMD: ProductMasterData): Product {
    const product = new Product();

    ifNotUndefinedAssign(product, 'class', productMD.class);
    ifNotUndefinedAssign(product, 'description', productMD.Description);
    ifNotUndefinedAssign(product, 'description2', productMD.description2);
    ifNotUndefinedAssign(product, 'hazmat', productMD.Hazmat);
    ifNotUndefinedAssign(product, 'hazmat_class', productMD.hazmat_class);
    ifNotUndefinedAssign(product, 'hazmat_number', productMD.Hazmat_number);
    ifNotUndefinedAssign(product, 'hazmat_packingGroup', productMD.hazmat_packingGroup);
    ifNotUndefinedAssign(product, 'hazmat_subClass', productMD.hazmat_subClass);
    ifNotUndefinedAssign(product, 'ID', productMD.ID);
    ifNotUndefinedAssign(product, 'name', productMD.Name);
    ifNotUndefinedAssign(product, 'nmfc', productMD.NMFC);
    ifNotUndefinedAssign(product, 'NMFC', productMD.NMFC);
    ifNotUndefinedAssign(product, 'productId', productMD.produtsId);
    ifNotUndefinedAssign(product, 'Product', productMD.produtsId);
    ifNotUndefinedAssign(product, 'Pkg_Type', productMD.Pack);
    ifNotUndefinedAssign(product, 'Unit_Type', productMD.Type);

    return product;
}

export function productDetailToProductMasterData(product: ProductDetail): ProductMasterData {
    const productMD = new ProductMasterData();

    ifNotUndefinedAssign(productMD, 'class', product.class);
    ifNotUndefinedAssign(productMD, 'Description', product.description);
    ifNotUndefinedAssign(productMD, 'description2', product.description2);
    ifNotUndefinedAssign(productMD, 'Hazmat', product.hazmat);
    ifNotUndefinedAssign(productMD, 'hazmat_class', product.hazmat_class);
    ifNotUndefinedAssign(productMD, 'Hazmat_number', product.hazmat_number);
    ifNotUndefinedAssign(productMD, 'hazmat_packingGroup', product.hazmat_packingGroup);
    ifNotUndefinedAssign(productMD, 'hazmat_subClass', product.hazmat_subClass);
    ifNotUndefinedAssign(productMD, 'produtsId', product.productId);
    ifNotUndefinedAssign(productMD, 'Name', product.name);
    ifNotUndefinedAssign(productMD, 'nmfc', product.nmfc);
    ifNotUndefinedAssign(productMD, 'NMFC', product.nmfc);
    ifNotUndefinedAssign(productMD, 'Pack', product.Pkg_Type);
    ifNotUndefinedAssign(productMD, 'Type', product.Unit_Type);

    return productMD;
}

export function clearArray(arr) {
    arr.splice(0, arr.length);
}

export function contactMasterDataToContactSearchResult(value: ContactMasterData) {
    const csr = new ContactSearchResult();

    csr.contactsid = value.contactsId;
    csr.contact = value.Contact;
    csr.default_contact = value.default_contact;
    csr.country = value.country;
    csr.fax = value.Fax;
    csr.customer = value.Customer;
    csr.contact_thirdparty = value.contact_thirdparty;
    csr.contact_shipper = value.contact_shipper;
    csr.contact_consignee = value.contact_consignee;
    csr.contact_billto = value.contact_billto;
    csr.commercialresidential = value.commercialResidential;
    csr.phone = value.Phone;
    csr.callappt = value.callAppt;
    csr.hoursclose = value.hoursClose;
    csr.hoursopen = value.hoursOpen;
    csr.email = value.Email;
    csr.zip = value.Zip;
    csr.state = value.State;
    csr.city = value.City;
    csr.address2 = value.Address2;
    csr.address1 = value.Address1;
    csr.name2 = value.Name2;
    csr.name = value.Name;
    csr.id = value.ID;
    csr.active = value.Active;

    return csr;
}

