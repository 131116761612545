<form (ngSubmit)="submit()">
    <div class="overlay">
        <div class="create-dashboard-modal" id="createDashboardModal">
            <div class="modal-header">
                <div class="modalHeaderContentText">Create New Dashboard</div>
                <div class="modalHeaderContent">
                    <i class="fa fa-times clickable" aria-hidden="true" (click)="closeModal()"></i>
                </div>
            </div>
            <div class="modal-body">
                <div class="description-container">
                    <div class="nameInput">
                        <input [(ngModel)]="createdDashboardName" id="createNewDashboardInput"
                               name="createdDashboardName" placeholder=" * Enter Name Here">
                        <div class="maxNewDashBoardCharacterNote"
                             *ngIf="createdDashboardName?.length > maxDashboardNameCharacterLength">*Please limit your
                            dashboard name to {{ maxDashboardNameCharacterLength }} characters
                        </div>
                    </div>
                </div>
                <div class="buttonHolder">
                    <div class="clickable btn-outline" (click)="submit()"> Create Dashboard</div>
                </div>
            </div>
            <div class="bottom"></div>
        </div>
    </div>
</form>
