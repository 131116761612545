<div *ngIf="productForm" class="mainBody padLeft padRight">
    <!--========================================= HEADER =======================================================-->
    <div class="masterDataHeader">
        <div class="masterDataHeaderText">
            <span><span *ngIf="isNewProduct()">New&nbsp;</span>Master Data Product</span>
        </div>
        <div class="masterDataHeaderButtonsHolder">
            <app-button [inactive]="!productForm.dirty" [buttonColor]="'white'" (click)="cancelChanges()" >Cancel</app-button>
            <app-button *ngIf="!isNewProduct()" [buttonColor]="'red'" (click)="deleteProduct()" >Delete</app-button>
            <app-button [inactive]="!productForm.dirty || productForm.invalid" [buttonColor]="'blue'" (click)="saveChanges()" >Save</app-button>
        </div>
    </div>

    <!--=========================================== MASTER DATA PRODUCTS FORM BODY ===================================-->
    <form [formGroup]="productForm" (ngSubmit)="saveChanges()">
        <button type="submit" name="formSubmission" class="btn-hidden-IE"></button>
        <div class="productsFormColumnContainer">

            <!--=========================================== MASTER DATA PRODUCTS FORM LEFT COLUMN ===================================-->
            <div class="productsFormLeftColumn">
                <div class="productsFormRow">
                    <div class="productsFormLabel">
                        <span class="required">*</span>&nbsp;Name
                    </div>
                    <input class="productsFormValue productsFormValueInput" formControlName="name" type="text">
                </div>

                <div class="productsFormTextAreaRow">
                    <div class="productsFormLabel">
                        <span class="required">*</span>&nbsp;Description
                    </div>
                    <textarea class="productsFormTextArea" formControlName="description"></textarea>
                    <!-- <input class="productsFormTextArea"> -->
                </div>

                <div class="productsFormRow productsFormNoteRow">
                    <div class="productsFormLabel"></div>
                    <span class="productsFormNote">Note: Description displays on printed BOL</span>
                </div>

                <div class="productsFormTextAreaRow">
                    <div class="productsFormLabel">
                        Additional Info
                    </div>
                    <textarea class="productsFormTextArea" formControlName="additionalInfo"></textarea>
                </div>

                <div class="productsFormRow productsFormNoteRow">
                    <div class="productsFormLabel"></div>
                    <span class="padLeft productsFormNote">Note: Description displays on printed BOL</span>
                </div>
            </div>

            <!--=========================================== MASTER DATA PRODUCTS FORM RIGHT COLUMN ===================================-->
            <div class="productsFormRightColumn padRight">
                <div class="productsFormRow">
                    <div class="productsFormLabel">
                        Hazmat
                    </div>
                    <div class="productsFormValue productsFormHazmatNumber">
                        <span><app-checkbox formControlName="hazmat"></app-checkbox></span>
                        <div class="productsFormHazmatHolder" *ngIf="productForm.value.hazmat">
                            <span class="productsFormHazmatLabel">Hazmat #</span>
                            <input type="text" class="productsFormValueInput productsFormHazmatNumberInput" formControlName="hazmatNumber">
                        </div>
                    </div>
                </div>
                <div *ngIf="productForm.value.hazmat" class="productsFormRow">
                    <div class="productsFormLabel">
                        Hazmat Class
                    </div>
                    <input class="productsFormValue productsFormValueInput" formControlName="hazmatClass" type="text">
                </div>
                <div *ngIf="productForm.value.hazmat" class="productsFormRow">
                    <div class="productsFormLabel">
                        Hazmat Sub-Class
                    </div>
                    <input class="productsFormValue productsFormValueInput" formControlName="hazmatSubClass" type="text">
                </div>
                <div *ngIf="productForm.value.hazmat" class="productsFormRow">
                    <div class="productsFormLabel">
                        Hazmat Packing Group
                    </div>
                    <input class="productsFormValue productsFormValueInput" formControlName="hazmatPackingGroup" type="text">
                </div>
                <div class="productsFormRow">
                    <div class="productsFormLabel">Handling Unit</div>
                    <div class="productsFormSelectPanel">
                        <app-list [valueField]="'id'" [list]="productFormHandlingUnits" formControlName="handlingUnit" ></app-list>
                    </div>
                </div>
                <div class="productsFormRow">
                    <div class="productsFormLabel">Package Type</div>
                    <div class="productsFormSelectPanel">
                        <app-list [valueField]="'id'" [list]="productFormPackageTypes" formControlName="packageType"></app-list>
                    </div>
                </div>
                <div class="productsFormRow">
                    <div class="productsFormLabel">NMFC No</div>
                    <div class="productsFormSelectPanel">
                        <input #nmfcNumberInput type="text" class="productsFormNMFCInput full-width" formControlName="nmfc">
                        <span class="productsFormSelectSearchIcon clickable" (click)="nmfcSearchModalShown = true;">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="productsFormRow">
                    <div class="productsFormLabel">
                        <span class="required">* </span>&nbsp;Class
                    </div>
                    <div class="productsFormSelectPanel">
                        <app-list [valueField]="'class'" [getKey]="getClassKey" [getLabel]="getClassLabel" formControlName="class" [list]="productFormNMFCClasses"></app-list>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-nmfc-search-modal *ngIf="nmfcSearchModalShown" (nmfcSelected)="nmfcSelected($event)" ></app-nmfc-search-modal>
