import {HttpParams} from '@angular/common/http';
import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import * as RCConstants from '../../../constants/recordCreation.constants';
import {User} from '../../../models/user';
import {SignalsService} from "../../../services/signals.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-place-shipment-modal',
    templateUrl: './place-shipment-modal.component.html',
    styleUrls: ['./place-shipment-modal.component.scss']
})
export class PlaceShipmentModalComponent implements OnInit {
    @Input() public isCreditCardPaymentPermissible: boolean;
    @Input() public currentShipment = null;
    @Output() public closePlaceShipmentModal = new EventEmitter();

    public placeShipmentForm: UntypedFormGroup;
    private currentUser: User;
    private creditCardStaticValues: {
        hosted_secure_id: string,
        action: string,
        collectAddress: string,
        allowed_types: string,
        required: string
    } = RCConstants.CC_URL_VALUES;
    public creditCardIFrameUrl: string;
    public isPlaceShipmentUIVisible: boolean = true;
    public isCreditCardUIVisible: boolean = false;

    private callbackHTMLFullPath: string = 'http://localhost:4200/assets/external/Callback.html';
    private iframeStylesFullPath: string = 'http://localhost:4200/assets/external/chaseIframe.css';

    constructor(
        private _fb: UntypedFormBuilder,
        private _notificationService: NotificationService,
        private _signalsService: SignalsService,
    ) {
    }

    public ngOnInit() {
        this.currentUser = this._signalsService.userSignal();

        this.setupPlaceShipmentForm();

        if (this.isCreditCardPaymentPermissible) {
            this.setCCIFrameSource();
            console.log(this.creditCardIFrameUrl);

            this.isCreditCardUIVisible = true;
            this.isPlaceShipmentUIVisible = false;
        }
    }

    private setupPlaceShipmentForm(): void {
        if (this.currentUser) {
            this.assignComponentPropertiesFromCurrentUser();
        } else {
            this.clearPlaceShipmentForm();
        }
    }

    private assignComponentPropertiesFromCurrentUser(): void {
        this.placeShipmentForm = this._fb.group({
            bolName: new UntypedFormControl(this.currentUser.name || '', [
                Validators.required
            ]),
            bolEmail: new UntypedFormControl(this.currentUser.email || '', [
                Validators.required
            ]),
            bolPhone: new UntypedFormControl(this.currentUser.phone || '', [
                Validators.required
            ])
        });

        if (this.isCreditCardPaymentPermissible) {
            this.setCCIFrameSource();
        }
    }

    private clearPlaceShipmentForm(): void {
        this.placeShipmentForm = this._fb.group({
            bolName: new UntypedFormControl('', [
                Validators.required
            ]),
            bolPhone: new UntypedFormControl('', [
                Validators.required
            ]),
            bolEmail: new UntypedFormControl('', [
                Validators.required
            ])
        });
    }

    public saveChanges(): void {
        const isPlaceShipmentFormValid = this.placeShipmentForm.valid;

        if (!isPlaceShipmentFormValid) {
            const requiredFormControls: Array<AbstractControl> = [
                this.placeShipmentForm.get('bolName'),
                this.placeShipmentForm.get('bolEmail'),
                this.placeShipmentForm.get('bolPhone'),
            ];

            requiredFormControls.forEach(placeShipmentFormControl => {
                if (placeShipmentFormControl.invalid) {
                    placeShipmentFormControl.markAsDirty();
                }
            });
        }

        if (!isPlaceShipmentFormValid) {
            return;
        }

        this.closePlaceShipmentModal.emit(this.placeShipmentForm.value);
    }

    public cancel(): void {
        this.closePlaceShipmentModal.emit(null);
    }

    public continue(): void {
        // continue
        this.isPlaceShipmentUIVisible = true;
        this.isCreditCardUIVisible = false;
    }

    // =============================================================================================
    // ==================================== CREDIT CARD METHODS ====================================
    // =============================================================================================
    @HostListener('window:message', ['$event'])
    public receiveMessage(event): void {
        // code that should fire when the iframe data is recieved by component
        console.log(event);
    }

    public setCCIFrameSource(): void {
        const hostedSecureID: string = this.creditCardStaticValues.hosted_secure_id;
        const action: string = this.creditCardStaticValues.action;
        const sessionId: string = this.generateRandomSessionId();
        const amount: number = this.currentShipment.rated_amt;
        const order_desc: string = this.currentShipment.BOL;
        const orderId = `${this.currentShipment.Customer}-${this.currentShipment.BOL}`;
        const allowed_types: string = this.creditCardStaticValues.allowed_types;
        const customerRefNum: string = this.currentShipment.BOL;
        const collectAddress: string = this.creditCardStaticValues.collectAddress;
        const required: string = this.creditCardStaticValues.required;

        const baseUrl = RCConstants.CC_IFRAME_BASE_URL;
        const sample = 'https://www.chasepaymentechhostedpay.com/hpf/1_1/?hostedSecureID=cpt9282730&action=buildForm&sessionId=3435-32544587&amount=75.81&order_desc=TestBol1245&orderId=FINDAM-4&allowed_types=Discover|MasterCard|Visa|American Express&customerRefNum=TestBol1245&collectAddress=1&required=all'

        const params = new HttpParams()
            .set('hostedSecureID', hostedSecureID)
            .set('action', action)
            .set('sessionId', sessionId)
            .set('amount', amount.toString())
            // .set('amount', '1')  // hardcoding this value so that we can actually test making a payment, since the cc user is currently unable to return rates
            .set('order_desc', order_desc)
            .set('orderId', orderId)
            .set('allowed_types', allowed_types)
            .set('customerRefNum', customerRefNum)
            .set('collectAddress', collectAddress)
            .set('required', required)
            .set('callback_url', this.callbackHTMLFullPath)
            .set('css_url', this.iframeStylesFullPath)
            .set('customer_address', this.currentUser.ccBillingAddress1)
            .set('customer_address2', this.currentUser.ccBillingAddress2)
            .set('customer_city', this.currentUser.ccBillingCity)
            .set('customer_state', this.currentUser.ccBillingSt)
            .set('customer_postal_code', this.currentUser.ccBillingZip);

        this.creditCardIFrameUrl = `${baseUrl + params}`;
    }

    private generateRandomSessionId(): string {
        const part1 = Math.round(Math.random() * (9999 - 1000) + 1000);
        const part2 = Math.round(Math.random() * (50000000 - 10000000) + 10000000);
        const sessionId = `${part1}-${part2}`;

        return sessionId;
    }

    // =============================================================================================
    // ==================================== CUSTOM CHASE METHODS ====================================
    // =============================================================================================

    public cancelCREPayment(): void {
        // Called when the customer clicks the cancel button on the payment form. The function closes
        // the iFrame and indicates that the customer’s card was not charged.
        this.cancel();
    }

    public cvv2What(): void {
        // Called when the customer clicks the link for more information about CVV2 numbers. The
        // function displays a popup window, which describes what the CVV2 number is and where to
        // find it on the card.

        this._notificationService.notifyInfo({
            title: `Credit Card CVC`,
            message: `The CVC Number is the 3 digit number located on the back of your credit card. For American Express, it is the 4 digit number located on the front of your card.`
        })
    }

    public creHandleErrors(errorCode) {
        // Called when a transaction error occurs
        this._notificationService.notifyError({title: `Credit Card Error`, message: `${errorCode}`});

        return false;
    }

    public creHandleDetailErrors(errorCode, gatewayCode, gatewayMessage) {
        // Optionally called for transaction errors when more detailed information is required for debugging

        // jQuery.getJSON(
        //   './cfc/creditCard.cfc',
        //   {
        //     'method': 'getError',
        //     'code': errorCode
        //   },
        //   function(data){
        //     if(data.ERROR==0) {
        //       alert(data.DATA);
        //     } else {
        //       alert('An error occured while processing your request. Please try again.');
        //     }
        //   }
        // );

        return false;
    }

    public completeCREPayment(transaction) {
        // Called when a transaction is processed successfully
        // set variable to true, ngClass the Continue button to become active when this variable is true
    }
}
