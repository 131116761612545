import {Router} from "@angular/router";
import * as Constants from "../constants/constants";
import {ThinkOwlChatService} from "./thinkOwlChat.service";
import {Injectable} from "@angular/core";
import {SignalsService} from "./signals.service";
import {initialClips} from "../models/clipboardNote";
import {NotificationService} from "./notification.service";
import {DashboardHelper} from "../helpers/dashboardHelper";
import {CarriersMasterDataService} from "./carriers.masterdata.service";
import {CommonDataService} from "./commonData.service";

@Injectable()
export class LogoutService {

    constructor(
        private _router: Router,
        private _thinkOwlChatService: ThinkOwlChatService,
        private _notificationService: NotificationService,
        private _signalsService: SignalsService,
        private _dashboardHelper: DashboardHelper,
        private _carriersMasterDataService: CarriersMasterDataService,
        private _commonDataService: CommonDataService,
    ) {}

    public async logout(): Promise<void> {
        const isUserOnHomePage: boolean = this._router.url === '/';
        let shouldLogout = true;

        this._thinkOwlChatService.removeChat();

        if (!isUserOnHomePage) {
            shouldLogout = await this._router.navigate(['/']);  // navigation can be stopped in the event of preventing unsaved changes via the unsavedChangesRouteGuard, but navigating to the same path will never resolve to true as delta is null
        }

        if (!shouldLogout) {
            return;
        }

        this._signalsService.clearAppState();
        this._commonDataService.clearBillToContacts();
        this._dashboardHelper.clearDashboards();
        this._signalsService.displaySignal.set({});
        this._signalsService.clearLoginSettings();
        this._signalsService.clearSearchResults();
        this._signalsService.clearUserFavorites();
        this._signalsService.clipsSignal.set(initialClips);
        this._signalsService.clearUserLoggedIn();
        this._signalsService.templatesSignal.set([]);
        this._carriersMasterDataService.clearMasterDataCarriers();
        this._commonDataService.beAdvisedMessage = '';
        this._commonDataService.clearAvailableAccessorials();
        this._commonDataService.clearMarkupTypes();
        this._commonDataService.clearRateOnOptions();
        this._commonDataService.clearRoleTypes();
        this._commonDataService.clearMarkupRangeTypes();

        localStorage.removeItem(Constants.USER_TOKEN_KEY);

        if (!this._signalsService.loggedOutSignal()) {
            this._notificationService.notifySuccess({
                title: 'User Logout', message: `You have been logged out!`
            });
        }
        this._signalsService.loggedOutSignal.set(true);
    }
}
