<div class="overlay">
  <div class="searchContactModal">
    <!--================================== MODAL HEADER ==============================================-->
    <div class="modalHeader">
      <div class="modalHeaderText">
        Select Vanguard Contact
      </div>
      <span class="modalHeaderIcon clickable" (click)="cancel()">
        <i class="fa fa-times"></i>
      </span>
    </div>

    <!--================================== MODAL BODY ==============================================-->
    <div class="modalBody">
      <div class="searchContactQueryPanel">
        <div class="searchContactQuerySelectPanel">
          <select name="" id="" class="selectTag" [(ngModel)]="selectedContactField" (change)="updateFilteredVanguardContactList()">
            <option [value]="contactField.contactField" *ngFor="let contactField of dropdownContactFields">
              {{ contactField.displayText }}
            </option>
          </select>
          <span class="selectIcon clickableCaret">
            <i class="fa fa-caret-down"></i>
          </span>
        </div>
        <!--================================== MODAL INPUT AND DROPDOWN ==============================================-->
        <div class="searchContactQueryInputPanel">
          <input type="text" class="inputTag" placeholder="Search" [(ngModel)]="contactQuery" (ngModelChange)="updateFilteredVanguardContactList()">
          <span class="inputIcon clickable">
            <i class="fa fa-search"></i>
          </span>
        </div>
      </div>

      <!--================================== NO RESULTS PROMPT ==============================================-->
      <div class="performSearchPrompt" *ngIf="isNoResultsPromptDisplayed">
        <div class="performSearchPromptText">
          No results found.
        </div>
        <div class="performSearchPromptIconHolder">
          <i class="fa fa-frown-o"></i>
        </div>
      </div>


      <!--================================== MODAL SEARCH RESULTS ==============================================-->
      <div class="searchResultsHeader" *ngIf="filteredVanguardContactList.length">
        <span class="nameHeader">Name</span>
        <span class="locationHeader">Location</span>
      </div>
      <div class="searchResultsContainer" *ngIf="filteredVanguardContactList.length">
        <div class="searchResultRow clickable" *ngFor="let contact of filteredVanguardContactList" (click)="selectVanguardContact(contact)">
          <span class="searchResultRowName">
            {{ contact.Name | titlecase }}
          </span>
          <span class="searchResultRowLocation">
            {{ contact.City | titlecase }}, {{ contact.State | titlecase }}, {{ contact.Zip }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>