<div class="overlay" [ngStyle]="{'display': (showMenu ? 'block' : 'none')}" (click)="closeMenu($event)">
</div>
<div (click)="preventDefaultClicked($event)" class="btn-filled clickable"
     [ngStyle]="customStyling"
     [ngClass]="{
            'inactive': inactive,
            'active': active,
            'btn-filled': (buttonColor.toLowerCase() === 'blue'),
            'btn-outline': (buttonColor.toLowerCase() === 'white'),
            'btn-filled-red': (buttonColor.toLowerCase() === 'red'),
            'btn-filled-green': (buttonColor.toLowerCase() === 'green'),
            'btn-filled-orange': (buttonColor.toLowerCase() === 'orange'),
            'btn-filled-yellow': (buttonColor.toLowerCase() === 'yellow'),
            'btn-filled-babyblue': (buttonColor.toLowerCase() === 'babyblue'),
            'btn-filled-odyssey-green': (buttonColor.toLowerCase() === 'odysseygreen'),
            'btn-filled-odyssey-grey': (buttonColor.toLowerCase() === 'odysseygrey'),
            'btn-transparent': (buttonColor.toLowerCase() === 'transparent')
        }">
    <ng-content></ng-content>
</div>

<ng-template #defaultMenuItemRenderer let-opt>
    {{opt?.label || opt?.name || opt}}
</ng-template>

<div [ngClass]="getMenuClass()" [ngStyle]="{'display': (this.showMenu ? 'block' : 'none')}">
    <div class="popMenuItem clickable" (click)="clicked($event, opt)" *ngFor="let opt of menu">
        <ng-container [ngTemplateOutlet]="menuItemRenderer || defaultMenuItemRenderer" [ngTemplateOutletContext]="{ $implicit: opt }"></ng-container>
    </div>
</div>
