<div class="productLine">
    <ng-container *ngIf="quickQuoteDisplay">
        <input class="lineElement weightCol curved" (ngModelChange)="updateWeight($event)" [ngModel]="value.Weight"
               [ngClass]="getClassFor(weightModel)" #weightModel="ngModel" />

        <app-list [list]="nmfcClasses" [getLabel]="getListLabel" [getKey]="getListKey" (ngModelChange)="updateClass($event)"
                  [ngModel]="nmfcClass" class="lineElement classCol curved" [ngClass]="getClassFor(classModel)" #classModel="ngModel" ></app-list>

        <input class="lineElement cubeCol curved" (ngModelChange)="checkCube($event)" [ngModel]="value.Cube"
               [ngClass]="getClassFor(cubeModel)" #cubeModel="ngModel" #cubeElement />

        <app-cube-calculator (dialogShown)="calcDialogShown($event, cubeElement)" [getCubeInfoFn]="getCalculatorData()" [closeButtonName]="'Submit'" (ngModelChange)="updateFromCalculator($event)"
                             [ngModel]="calculatorData" class="calculatorCol"></app-cube-calculator>

        <input class="lineElement piecesCol curved" (blur)="calculateCube()" (ngModelChange)="updateProp('Qty', $event)" [ngModel]="value.Qty"
               [ngClass]="getClassFor(qtyModel)" #qtyModel="ngModel" />

        <input class="lineElement lengthCol boxed" (blur)="calculateCube()" (ngModelChange)="updateProp('Length', $event)"
               [ngModel]="value.Length" [ngClass]="getClassFor(lengthModel)" #lengthModel="ngModel" />

        <input class="lineElement widthCol boxedOpen" (blur)="calculateCube()" (ngModelChange)="updateProp('Width', $event)"
               [ngModel]="value.Width" [ngClass]="getClassFor(widthModel)" #widthModel="ngModel" />

        <input class="lineElement heightCol boxed" (blur)="calculateCube()" (ngModelChange)="updateProp('Height', $event)"
               [ngModel]="value.Height" [ngClass]="getClassFor(heightModel)" #heightModel="ngModel" />

        <app-product-input (dialogShown)="dialogShown.emit($event);" class="lineElement nameCol curved" (ngModelChange)="updateProduct($event)" [ngModel]="product"
                           [ngClass]="getClassFor(productModel)" #productModel="ngModel" ></app-product-input>
    </ng-container>
    <ng-container *ngIf="!quickQuoteDisplay">
        <ng-container *ngIf="!readOnly">
            <app-product-input [isRequired]="true" (dialogShown)="dialogShown.emit($event);" class="lineElement nameCol curved" (ngModelChange)="updateProduct($event)" [ngModel]="product"
                               [ngClass]="getClassFor(productModel)" #productModel="ngModel" ></app-product-input>

            <app-list [list]="nmfcClasses" [getLabel]="getListLabel" [getKey]="getListKey" (ngModelChange)="updateClass($event)"
                      [ngModel]="nmfcClass" class="lineElement classCol curved" [ngClass]="getClassFor(classModel)" #classModel="ngModel" ></app-list>

            <app-list [list]="handlingUnits" [getLabel]="getHandlingUnitListLabel" [getKey]="getHandlingUnitListKey" (ngModelChange)="updateHandlingUnit($event)"
                      [ngModel]="handlingUnit" class="lineElement handlingUnitTypeCol curved" [ngClass]="getClassFor(handlingUnitsModel)" #handlingUnitsModel="ngModel" ></app-list>

            <input style="width: 100%" class="lineElement handlingUnitQtyCol curved" (blur)="calculateCube()" (ngModelChange)="updateProp('Units', $event)" [ngModel]="value.Units"
                   [ngClass]="getClassFor(handlingUnitModel)" #handlingUnitModel="ngModel" />

            <app-list [list]="packageTypes" [getLabel]="getPackageTypeListLabel" [getKey]="getPackageTypeListKey" (ngModelChange)="updatePackageType($event)"
                      [ngModel]="packageType" class="lineElement packageTypeCol curved" [ngClass]="getClassFor(packageTypesModel)" #packageTypesModel="ngModel" ></app-list>

            <input class="lineElement piecesCol curved" (blur)="calculateCube()" (ngModelChange)="updateProp('Qty', $event)" [ngModel]="value.Qty"
                   [ngClass]="getClassFor(qtyModel)" #qtyModel="ngModel" />

            <input class="lineElement lengthCol boxed" (blur)="calculateCube()" (ngModelChange)="updateProp('Length', $event)"
                   [ngModel]="value.Length" [ngClass]="getClassFor(lengthModel)" #lengthModel="ngModel" />

            <input class="lineElement widthCol boxedOpen" (blur)="calculateCube()" (ngModelChange)="updateProp('Width', $event)"
                   [ngModel]="value.Width" [ngClass]="getClassFor(widthModel)" #widthModel="ngModel" />

            <input class="lineElement heightCol boxed" (blur)="calculateCube()" (ngModelChange)="updateProp('Height', $event)"
                   [ngModel]="value.Height" [ngClass]="getClassFor(heightModel)" #heightModel="ngModel" />

            <input class="lineElement cubeCol curved" (ngModelChange)="checkCube($event)" [ngModel]="value.Cube"
                   [ngClass]="getClassFor(cubeModel)" #cubeModel="ngModel" #cubeElement />

            <app-cube-calculator (dialogShown)="calcDialogShown($event, cubeElement)" [piecesType]="calculatorPiecesType" [getCubeInfoFn]="getCalculatorData()" [closeButtonName]="'Submit'" (ngModelChange)="updateFromCalculator($event)"
                                 [ngModel]="calculatorData" class="calculatorCol textOffset centerContent"></app-cube-calculator>

            <input class="lineElement weightCol curved" (ngModelChange)="updateWeight($event)" [ngModel]="value.Weight"
                   [ngClass]="getClassFor(weightModel)" #weightModel="ngModel" />

            <app-checkbox [readOnly]="readOnly" class="stackableCol textOffset centerContent" (changeMade)="updateStackable($event)" [ngModel]="value.stackable"></app-checkbox>
        </ng-container>
        <ng-container *ngIf="readOnly">
            <div class="nameCol textOffset">{{product.Name}}</div>

            <div class="classCol textOffset">{{getListLabel(nmfcClass)}}</div>

            <div class="handlingUnitTypeCol textOffset">{{getHandlingUnitListLabel(handlingUnit)}}</div>

            <div class="handlingUnitQtyCol textOffset">{{value.Units}}</div>

            <div class="packageTypeCol textOffset">{{getPackageTypeListLabel(packageType)}}</div>

            <div class="piecesCol textOffset">{{value.Qty}}</div>

            <div class="lengthCol textOffset">{{value.Length}}</div>

            <div class="widthCol textOffset">{{value.Width}}</div>

            <div class="heightCol textOffset">{{value.Height}}</div>

            <div class="cubeCol textOffset">{{value.Cube | number: '1.2-2'}}</div>

            <div class="calculatorCol textOffset"></div>

            <div class="weightCol textOffset">{{value.Weight | number: '1.2-2'}}</div>

            <app-checkbox [tabindex]="-1" [readOnly]="readOnly" class="stackableCol textOffset centerContent" (changeMade)="updateStackable($event)" [ngModel]="value.stackable"></app-checkbox>
        </ng-container>

        <div class="densityCol textOffset">{{calculateDensity() | number: '1.2-2'}}</div>
    </ng-container>
</div>
<div *ngIf="isInvalidAndDirty" class="errorLine">
    <div class="underErrors">
        Please correct highlighted fields.
    </div>
</div>
