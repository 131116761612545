<toaster-container [toasterconfig]="toasterconfig"></toaster-container>
<div class="mainBodyLayout">
    <app-create-view-prompt *ngIf="createViewBySearch"></app-create-view-prompt>
    <app-mocking-ribbon></app-mocking-ribbon>
    <app-header *ngIf="isUserSessionActive" [isUserSessionActive]="isUserSessionActive"></app-header>
    <app-loader-graphic></app-loader-graphic>
    <div class="mainBody" (click)="closeShortcut()">
        <router-outlet></router-outlet>
    </div>
    <app-clipboard *ngIf="isUserSessionActive"></app-clipboard>
    <app-footer *ngIf="isUserSessionActive"></app-footer>
    <app-tma-acceptance-modal *ngIf="isTMAAcceptanceVisible" (closeTmaAcceptanceModal)="hideAcceptTMA()"></app-tma-acceptance-modal>
    <app-site-inaccessible-modal *ngIf="isSiteInaccessibleModalVisible" (closeSiteInaccessibleModal)="hideSiteInaccessibleModal()"></app-site-inaccessible-modal>
    <app-tour *ngIf="tourService.tourPdfNameSubject|async"></app-tour>
    <app-cube-calculator-modal *ngIf="isCubeCalculatorModalVisible"></app-cube-calculator-modal>
</div>
