import {Injectable} from "@angular/core";
import {HttpRequest} from "@angular/common/http";
import {ApiService, REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT} from "./api.service";
import {ResponseDTO} from "../models/responseDto";
import {ContactMasterData} from "../models/contact.masterData";
import {UserHelper} from "../helpers/userHelper";
import {catchError, map} from "rxjs/operators";
import {clearArray} from "../helpers/utilities";
import {NotificationService} from "./notification.service";

@Injectable()
export class ContactsMasterDataService {

    public contactsMasterData = [];

    constructor(
        private _notificationService: NotificationService,
        private _api: ApiService,
        private _userHelper: UserHelper,
    ) {}

    public getMasterDataContactsList(customer: string) {
        const req = new HttpRequest(REQUEST_TYPE_GET, `MasterData/Contacts/All/${customer}`);
        return this._api.callApiService<ResponseDTO<Array<ContactMasterData>>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    clearArray(this.contactsMasterData);
                    this.contactsMasterData.push(...response.dto);
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Master Data - Get Contacts List` });
                throw err;
            })
        );
    }

    public getMasterDataContact(contactId: number) {
        const getMasterDataContactReq = new HttpRequest(REQUEST_TYPE_GET, `MasterData/Contacts/${contactId}`);
        return this._api.callApiService<ResponseDTO<Array<ContactMasterData>>>(getMasterDataContactReq).pipe(
            map(response => {
                if (response.isValid) {
                    const retrievedMasterDataContact = response.dto[0];
                    if (response.dto.length > 1) {
                        this._notificationService.notifyWarning({
                            title: 'MasterData - Contact',
                            message: `${response.dto.length} contacts returned from get master data contact call, displaying first value.`
                        });
                    }
                    return response.dto[0];
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Master Data - Get Contact` });
                throw err;
            })
        );
    }

    public createMasterDataContact(createdContact: ContactMasterData) {
        const customer = this._userHelper.getUserCustomer();
        const createMasterDataContactReq = new HttpRequest(REQUEST_TYPE_POST, `MasterData/Contacts`, createdContact);
        return this._api.callApiService<ResponseDTO<{ locationId: number }>>(createMasterDataContactReq).pipe(
            map(response => {
                if (response.isValid) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Master Data - Create Contact` });
                throw err;
            })
        );
    }

    public updateMasterDataContact(updatedContact: ContactMasterData) {
        const updateMasterDataContactReq = new HttpRequest(REQUEST_TYPE_PUT, `MasterData/Contacts/${updatedContact.contactsId}`, updatedContact);
        return this._api.callApiService<ResponseDTO<Array<ContactMasterData>>>(updateMasterDataContactReq).pipe(
            map(response => {
                if (response.isValid) {
                    const updatedContactResponse = response.dto[0];
                    return response.dto[0];
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Master Data - Update Contact` });
                throw err;
            })
        );
    }

    public deleteMasterDataContact(contactId: number, deletedFromMasterDataList?: boolean) {
        const deleteMasterDataContactReq = new HttpRequest(REQUEST_TYPE_DELETE, `MasterData/Contacts/${contactId}`);
        return this._api.callApiService<ResponseDTO<Array<any>>>(deleteMasterDataContactReq).pipe(
            map(response => {
                if (response.isValid) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Master Data - Delete Contact` });
                throw err;
            })
        );
    }
}
