<div class="purchaseOrderLine">
    <input class="lineElement purchaseOrderNumberCol" (ngModelChange)="valueChanged(value)" [(ngModel)]="value.poNumber" />

    <input class="lineElement packagesCol" (ngModelChange)="valueChanged(value)" [(ngModel)]="value.Packages" />

    <input class="lineElement weightCol" (ngModelChange)="valueChanged(value)" [(ngModel)]="value.Weight" />

    <app-list [list]="yesNoList" [ngModel]="pallet" (ngModelChange)="palletChanged($event)" class="lineElement palletSlipCol"></app-list>

    <input class="lineElement additionalInfoCol" (ngModelChange)="valueChanged(value)" [(ngModel)]="value.Info" />
</div>
