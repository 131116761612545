<div class="mainBody padLeft padRight">
  <!--================================= HEADER =====================================-->

  <div class="masterDataHeader">
    <div class="masterDataHeaderText">
      <span *ngIf="!masterDataContact" class="topMasterDataContactHeaderText">
        Master Data
      </span>
      <span *ngIf="masterDataContact">
        Master Data
      </span>
      <div *ngIf="masterDataContact && areGlobalSearchResultsBreadcrumbsDisplayed" class="breadcrumbs">
        <span class="clickable" (click)="navigateToDashboard()">Dashboard &gt;</span>
        <span class="clickable" (click)="navigateToSearch()">Global Search &gt;</span>
        <span class="clickable" (click)="navigateToSearch()">Search Results &gt;</span>
        <span class="breadCrumbsFinal">&nbsp;{{ masterDataContact.Name }} Contact/Location Details </span>
      </div>
      <div *ngIf="masterDataContact && areMasterDataBreadcrumbsDisplayed" class="breadcrumbs">
        <span class="clickable" (click)="navigateToMasterDataManagement()">Master Data &gt;</span>
        <span class="clickable" (click)="navigateToMasterDataManagement()">&nbsp;Contacts &gt;</span>
        <span class="breadCrumbsFinal">&nbsp;{{ masterDataContact.Name }} Contact/Location Details </span>
      </div>
      <div *ngIf="!masterDataContact && areMasterDataBreadcrumbsDisplayed" class="breadcrumbs">
        <span class="clickable" (click)="navigateToMasterDataManagement()">Master Data &gt;</span>
        <span class="clickable" (click)="navigateToMasterDataManagement()">&nbsp;Contacts &gt;</span>
        <span class="breadCrumbsFinal">&nbsp;Create Contact </span>
      </div>
    </div>
    <div class="masterDataHeaderButtonsHolder">
      <button *ngIf="contactForm.dirty" class="btn-outline clickable" (click)="revertContactChanges()">
        <i class="fa fa-ban btn-icon" aria-hidden="true"></i>
        Cancel
      </button>
      <button *ngIf="masterDataContact" class="btn-outline clickable" (click)="deleteContact()">
        <i class="fa fa-trash-o"></i>
        Delete
      </button>
      <button class="btn-outline clickable" [ngClass]="{'contactFormDirty': contactForm.dirty}" (click)="saveChanges()">
        <i class="fa fa-floppy-o" aria-hidden="true"></i>
        Save
      </button>
    </div>
  </div>

  <!--==================================== CONTACT FORM HEADER ================================================-->
  <div class="contactFormHeader">
    <span *ngIf="!masterDataContact">
      Create Contact/Location
    </span>
    <span *ngIf="masterDataContact">
      "{{ masterDataContact.Name }}" Contact/Location Details
    </span>
  </div>

  <!--====================================== CONTACT FORM BODY =================================================-->
  <form [formGroup]="contactForm" (ngSubmit)="saveChanges()">
    <button type="submit" name="formSubmission" class="btn-hidden-IE"></button>
    <div class="contactFormColumnContainer">

      <!--==================================== CONTACT FORM LEFT COLUMN ====================================-->
      <div class="contactFormLeftColumn">
        <div class="contactFormRow">
          <div class="contactFormLabel">
            <span class="required">*</span>&nbsp;Name
          </div>
          <input type="text" class="contactFormValue" formControlName="name" [ngClass]="{ 'contactFormInputInvalid': (contactForm['controls'].name.dirty || contactForm['controls'].name.touched) && contactForm['controls'].name.invalid }">
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">Alias</div>
          <input type="text" class="contactFormValue" formControlName="alias">
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">
            <span class="required">*</span>&nbsp;Address 1
          </div>
          <input type="text" class="contactFormValue" formControlName="address1" [ngClass]="{ 'contactFormInputInvalid': (contactForm['controls'].address1.dirty || contactForm['controls'].address1.touched) &&
          contactForm['controls'].address1.invalid }">
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">Address 2</div>
          <input type="text" class="contactFormValue" formControlName="address2">
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">
            <span class="required">*</span>&nbsp;City
          </div>
          <input type="text" class="contactFormValue" formControlName="city" [ngClass]="{ 'contactFormInputInvalid': (contactForm['controls'].city.dirty || contactForm['controls'].city.touched) &&
          contactForm['controls'].city.invalid }">
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">
            <span class="required">*</span>&nbsp;State
          </div>
          <div class="contactFormSelectPanel">
            <select class="full-width contactFormSelectTag" formControlName="state" [ngClass]="{ 'contactFormInputInvalid': (contactForm['controls'].state.dirty || contactForm['controls'].state.touched) &&
            contactForm['controls'].state.invalid }">
              <option [value]=""></option>
              <option *ngFor="let state of contactFormStates" [value]="state.Code">{{ state.Code }}</option>
            </select>
            <span class="contactFormSelectCaret clickableCaret">
              <i class="fa fa-caret-down"></i>
            </span>
          </div>
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">
            <span class="required">*</span>&nbsp;Postal
          </div>
          <input type="text" class="contactFormValue" formControlName="zip" [ngClass]="{ 'contactFormInputInvalid': (contactForm['controls'].zip.dirty || contactForm['controls'].zip.touched) &&
          contactForm['controls'].zip.invalid }">
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">
            Country
          </div>
          <div class="contactFormSelectPanel">
            <select class="full-width contactFormSelectTag" formControlName="country">
              <option *ngFor="let country of contactFormCountries" [value]="country.countryCode">{{ country.countryDesc }}</option>
            </select>
            <span class="contactFormSelectCaret clickableCaret">
              <i class="fa fa-caret-down"></i>
            </span>
          </div>
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">
            Contact
          </div>
          <input type="text" class="contactFormValue" formControlName="contact">
        </div>

      </div>


      <!--==================================== CONTACT FORM RIGHT COLUMN ====================================-->
      <div class="contactFormRightColumn padRight">

        <div class="contactFormRow">
          <div class="contactFormLabel">
            Email
          </div>
          <input type="text" class="contactFormValue" formControlName="email">
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">
            Phone
          </div>
          <input type="text" class="contactFormValue" formControlName="phone">
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">Fax</div>
          <input type="text" class="contactFormValue" formControlName="fax">
        </div>

        <div class="contactFormRow" formGroupName="open">
          <div class="contactFormLabel">
            Open
          </div>
          <div class="contactFormTimePanel">
            <div class="contactFormTimeHolder">
              <select name="" id="" class="contactFormOpenCloseHourMinute" formControlName="hourMinute">
                <option [value]="time" *ngFor="let time of contactFormOpenCloseTimes">{{ time }}</option>
              </select>
              <span class="contactFormDatalistCaret clickableCaret">
                <i class="fa fa-caret-down" aria-hidden="true"></i>
              </span>

              <div class="contactFormMeridianPanel">
                <select name="" id="" class="contactFormMeridianSelectTag" formControlName="meridian">
                  <option *ngFor="let meridian of ['AM', 'PM']" [value]="meridian">{{ meridian }}</option>
                </select>
                <span class="contactFormOpenCloseMeridianSelectCaret clickableCaret">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="contactFormRow" formGroupName="close">
          <div class="contactFormLabel">
            Close
          </div>
          <div class="contactFormTimePanel">
            <div class="contactFormTimeHolder">
              <select name="" id="" class="contactFormOpenCloseHourMinute" formControlName="hourMinute">
                <option [value]="time" *ngFor="let time of contactFormOpenCloseTimes">{{ time }}</option>
              </select>
              <span class="contactFormDatalistCaret clickableCaret">
                <i class="fa fa-caret-down" aria-hidden="true"></i>
              </span>

              <div class="contactFormMeridianPanel">
                <select name="" id="" class="contactFormMeridianSelectTag" formControlName="meridian">
                  <option *ngFor="let meridian of ['AM', 'PM']" [value]="meridian">{{ meridian }}</option>
                </select>
                <span class="contactFormOpenCloseMeridianSelectCaret clickableCaret">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">Call for Appointment</div>
          <div class="cfaContactFormValue">
            <i *ngIf="!contactForm.value.callForAppt" class="fa fa-square-o clickable contactFormCheckType" aria-hidden="true" (click)="toggleContactFormCallForAppt()"></i>
            <i *ngIf="contactForm.value.callForAppt" class="fa fa-check-square clickable contactFormCheckType" aria-hidden="true" (click)="toggleContactFormCallForAppt()"></i>
          </div>
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">
            Location Type
          </div>
          <div class="cfaContactFormValue">
            <div class="contactFormRadioHolder">
              <div class="contactFormRadioPanel">
                <span class="contactFormRadio">
                  <i *ngIf="contactForm.value.locationType === 'C'" class="fa fa-dot-circle-o" aria-hidden="true"></i>
                  <i *ngIf="contactForm.value.locationType !== 'C'" class="fa fa-circle-o clickable" aria-hidden="true" (click)="setContactFormLocationType('C')"></i>
                </span>
                Commercial
              </div>

              <div class="contactFormRadioPanel">
                <span class="contactFormRadio">
                  <i *ngIf="contactForm.value.locationType === 'R'" class="fa fa-dot-circle-o" aria-hidden="true"></i>
                  <i *ngIf="contactForm.value.locationType !== 'R'" class="fa fa-circle-o clickable" aria-hidden="true" (click)="setContactFormLocationType('R')"></i>
                </span>
                Residential
              </div>
            </div>
          </div>
        </div>

        <div class="contactFormRow">
          <div class="contactFormLabel">Check Types That Apply</div>
          <div class="cfaContactFormValue">
            <div class="contactFormTypesHolder full-width">
              <div class="contactFormCheckTypePanel">
                <span class="contactFormCheckType clickable">
                  <i *ngIf="contactForm.value.shipperContactType" class="fa fa-check-square" aria-hidden="true" (click)="toggleContactFormContactType('shipperContactType')"></i>
                  <i *ngIf="!contactForm.value.shipperContactType" class="fa fa-square-o" aria-hidden="true" (click)="toggleContactFormContactType('shipperContactType')"></i>
                </span>
                Shipper
              </div>

              <div class="contactFormCheckTypePanel">
                <span class="contactFormCheckType clickable">
                  <i *ngIf="contactForm.value.thirdPartyContactType" class="fa fa-check-square" aria-hidden="true" (click)="toggleContactFormContactType('thirdPartyContactType')"></i>
                  <i *ngIf="!contactForm.value.thirdPartyContactType" class="fa fa-square-o" aria-hidden="true" (click)="toggleContactFormContactType('thirdPartyContactType')"></i>
                </span>
                3rd Party
              </div>
              <div class="contactFormCheckTypePanel">
                <span class="contactFormCheckType clickable">
                  <i *ngIf="contactForm.value.consigneeContactType" class="fa fa-check-square" aria-hidden="true" (click)="toggleContactFormContactType('consigneeContactType')"></i>
                  <i *ngIf="!contactForm.value.consigneeContactType" class="fa fa-square-o" aria-hidden="true" (click)="toggleContactFormContactType('consigneeContactType')"></i>
                </span>
                Consignee
              </div>
              <div class="contactFormCheckTypePanel">
                <span class="contactFormCheckType clickable">
                  <i *ngIf="contactForm.value.billToContactType" class="fa fa-check-square" aria-hidden="true" (click)="toggleContactFormContactType('billToContactType')"></i>
                  <i *ngIf="!contactForm.value.billToContactType" class="fa fa-square-o" aria-hidden="true" (click)="toggleContactFormContactType('billToContactType')"></i>
                </span>
                Bill To
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </form>

</div>