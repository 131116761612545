export class ContactSearchResult {
  id: number;
  contactsid: number;
  _type: string;
  name: string;
  name2: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: number | string;
  country: string;
  contact: string;
  phone: string;
  email: string;
  fax: string;
  hours: string;
  hoursclose: string;
  hoursopen: string;
  active: number;
  callappt: number;
  commercialresidential: string;
  contact_billto: number;
  contact_consignee: number;
  contact_shipper: number;
  contact_thirdparty: number;
  customer: string;
  default_contact: number;
  gl_code: string;
  gl_code2: string;
  outsideid: string;
  '@timestamp': string;
  '@version': number;
}
