<div class="reportsShipmentListComponent">
    <!-- =========================== SHIPMENT REPORT LIST HEADER SECTION =========================== -->
    <div class="shipmentsReportHeader">
        <div class="shipmentsReportHeaderTextPanel">
            <span class="shipmentsReportHeaderTextMain">
                Reporting
            </span>
            <span class="shipmentsReportHeaderBreadCrumbs">
                Reporting &gt;
                <span class="shipmentsReportHeaderBreadCrumbsFinal">Shipment Summary</span>
            </span>
        </div>
        <app-button (click)="exportReport()">Export to Excel File</app-button>
    </div>
    <!-- =========================== SHIPMENT REPORT LIST FILTER TOOLS =========================== -->
    <form class="filterControlsPanel" [formGroup]="shipmentReportForm">
        <div class="filterControlsPanel">
            <div class="shipmentReportsTypeControls">
                <div class="reportsTypeLabel">View Reports By</div>
                <div class="selectPanel clickableCaretExtension">
                    <select class="selectTag" formControlName="summaryType">
                        <option *ngFor="let groupType of shipmentReportGroupTypes" [value]="groupType?.value">{{
                            groupType?.displayName
                            }}
                        </option>
                    </select>
                    <span class="selectCaret clickableCaret clickableCaretPositioning">
                        <i class="fa fa-caret-down"></i>
                    </span>
                </div>
            </div>
            <div class="shipmentReportDateTypeControls">
                <div class="reportsTypeLabel">for Date Type</div>
                <div class="selectPanel clickableCaretExtension">
                    <select class="selectTag" formControlName="dateType">
                        <option *ngFor="let dateType of shipmentReportDateTypes" [value]="dateType?.value">{{
                            dateType?.displayName
                            }}
                        </option>
                    </select>
                    <span class="selectCaret clickableCaret clickableCaretPositioning">
                        <i class="fa fa-caret-down"></i>
                    </span>
                </div>
            </div>
            <div class="shipmentReportDateRangeControls">
                <div class="reportsTypeLabel">within Date Range</div>
                <div class="dateRangeContainer">
                    <div class="dateRangePanel" [ngClass]="{'dateRangeInvalid': shipmentReportForm &&
                        (
                            (shipmentReportForm['controls'].startdate && shipmentReportForm['controls'].startdate.errors && shipmentReportForm['controls'].startdate.errors.invalidDateRange) ||
                            (shipmentReportForm['controls'].endDate && shipmentReportForm['controls'].endDate.errors && shipmentReportForm['controls'].endDate.errors.invalidDateRange)
                        )}">
                        <input type="text" class="dateRangeStart" formControlName="startDate">
                        <span class="dateRangeText">
                            to
                        </span>
                        <input type="text" class="dateRangeEnd" formControlName="endDate">
                        <span class="dateRangeIcon blue clickable" (click)="showDatePicker()">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="shipmentReportControlsButtonHolder">
                <div class="btn-outline" (click)="updateReport()" [ngClass]="{
                    'active': isShipmentFormDirty && isDateRangeValid(),
                    'inactive': !isDateRangeValid()
                }">Update</div>
            </div>
        </div>
    </form>
    <!-- ===========================SHIPMENT REPORT LIST BODY SECTION=========================== -->
    <div class="shipmentsReportListContainer">
        <div class="shipmentsReportListHeaderRow">
            <div class="firstRowItem">{{ firstColName }}</div>
            <div class="headerItem">Paid $</div>
            <div class="headerItem">Paid %</div>
            <div class="headerItem">Weight</div>
            <div class="headerItem">Weight %</div>
            <div class="headerItem">Shipments</div>
            <div class="headerItem">Shipments %</div>
            <div class="headerItem">Cartons</div>
            <div class="headerItem">Carton Cost</div>
            <div class="headerItem">Fuel Cost</div>
            <div class="headerItem">CWT Cost</div>
            <div class="headerItem">Shipment Cost</div>
            <div class="headerItem">Mile Cost</div>
        </div>

        <div class="shipmentsReportDetailContainer">
            <div class="shipmentTotalsRow" *ngIf="shipmentReportSummaryTotalList">
                <div class="shipmentTotalFirstRowItem">Total Cost</div>
                <div class="rowDetailItem">${{ shipmentReportSummaryTotalList?.AmountPaidTotal | number }}</div>
                <div class="rowDetailItem"></div>
                <div class="rowDetailItem">{{ shipmentReportSummaryTotalList?.WeightTotal | number }}</div>
                <div class="rowDetailItem"></div>
                <div class="rowDetailItem">{{ shipmentReportSummaryTotalList?.ShipmentTotal | number }}</div>
                <div class="rowDetailItem"></div>
                <div class="rowDetailItem">{{ shipmentReportSummaryTotalList?.CartonsTotal | number }}</div>
                <div class="rowDetailItem">${{ shipmentReportSummaryTotalList?.CostPerCartonTotal }}</div>
                <div class="rowDetailItem">${{ shipmentReportSummaryTotalList?.FuelCostTotal | number }}</div>
                <div class="rowDetailItem">${{ shipmentReportSummaryTotalList?.CostPerCWTTotal }}</div>
                <div class="rowDetailItem">${{ shipmentReportSummaryTotalList?.CostPerShipmentTotal }}</div>
                <div class="rowDetailItem">${{ shipmentReportSummaryTotalList?.CostPerMileTotal }}</div>
            </div>
            <div class="shipmentsReportDetailRow clickable borderBottomShadow" (click)="navigateToShipmentDetail(shipReport)"
                *ngFor="let shipReport of shipmentReportSummaryList">
                <div class="firstRowItem">{{ shipReport?.firstColumn }}</div>
                <div class="rowDetailItem">${{ shipReport?.AmountPaid | number }}</div>
                <div class="rowDetailItem">{{ shipReport?.PaidPercent }}%</div>
                <div class="rowDetailItem">{{ shipReport?.Weight | number }}</div>
                <div class="rowDetailItem">{{ shipReport?.WeightPercent }}%</div>
                <div class="rowDetailItem">{{ shipReport?.Shipment | number }}</div>
                <div class="rowDetailItem">{{ shipReport?.ShipmentPercent }}%</div>
                <div class="rowDetailItem">{{ shipReport?.Cartons | number }}</div>
                <div class="rowDetailItem">${{ shipReport?.CostPerCarton }}</div>
                <div class="rowDetailItem">${{ shipReport?.FuelCost | number }}</div>
                <div class="rowDetailItem">${{ shipReport?.CostPerCWT }}</div>
                <div class="rowDetailItem">${{ shipReport?.CostPerShipment }}</div>
                <div class="rowDetailItem">${{ shipReport?.CostPerMile }}</div>
            </div>
        </div>
    </div>
</div>

<app-custom-datepicker *ngIf="isDatePickerShown" [isDateRangePossible]="true" [title]="'Select Date Range'"
    [dateFormatPattern]="reportDisplayDateFormat" [inputDate]="formattedStartDate" (closeDatePicker)="hideDatePicker($event)"
    [dateFormatValidationPattern]="reportDisplayDateValidation" [isDateRangeOnly]="true" [inputEndDate]="formattedEndDate"></app-custom-datepicker>
