import { Component, OnInit } from '@angular/core';

import { SearchState } from '../../../models/searchState';
import {SignalsService} from "../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {SearchService} from "../../../services/search.service";

@Component({
    selector: 'app-create-view-prompt',
    styleUrls: ['./create-view-prompt.component.scss'],
    templateUrl: './create-view-prompt.component.html',
})
export class CreateViewPromptComponent implements OnInit {

    private searchSubscription: any;
    public searchResponse: SearchState;
    public isSaveButtonActive: boolean = false;
    private search$;

    constructor(
        private _signalsService: SignalsService,
        private _searchService: SearchService
    ) {
        this.search$ = toObservable(_signalsService.searchStateSignal);
    }

    public ngOnInit() {
        this.searchSubscription = this.search$.subscribe((searchResponse: SearchState) => {
            this.isSaveButtonActive = this._searchService.areSearchResultsFilteredByEntityType();
        });
    }

    public saveDashboardViewContent(): void {
        this._searchService.saveDashboardViewContent();
    }

    public closePrompt(): void {
        this._signalsService.updateAppState({ 'dashboard.createViewBySearch': false });
    }

}
