import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash-es';
import { RadioValue } from '../../models/radioValue';

@Component({
    selector: 'app-radio-group',
    styleUrls: ['./app-radio-group.component.scss'],
    templateUrl: './app-radio-group.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppRadioGroupComponent,
            multi: true
        }
    ]
})
export class AppRadioGroupComponent implements OnInit, ControlValueAccessor {
    @Input()
    public allowClear: boolean = true;
    @Input()
    public labelAfter: boolean = true;
    @Input()
    public values: Array<RadioValue> = new Array<RadioValue>();
    @Input()
    public direction = 'row';
    @Input()
    public justify = 'space-between';
    @Input()
    public align = 'flex-start';
    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-native
    public change = new EventEmitter();
    @Input()
    public tabindex = 0;
    @Input()
    public isDisabled = false;

    @HostBinding('tabindex') hostTabindex;

    public value;

    constructor() {}

    @HostListener('keydown', ['$event'])
    keyEvent(event: KeyboardEvent) {
        let val;
        let idx;

        if (event.code !== 'Space') {
            for (val of this.values) {
                if (val.shortcut && ((val.shortcut.toUpperCase()) === event.key.toUpperCase())) {
                    this.value = val.value;
                    this.valueChanged(this.value);
                    return;
                }
            }
        } else {
            event.preventDefault();
            idx = _.findIndex(this.values, (option: RadioValue) => this.value === option.value, 0);
            if (idx < 0) {
                this.value = this.values[0].value;
            } else if (idx+1 >= this.values.length) {
                if (this.allowClear) {
                    this.value = null;
                } else {
                    this.value = this.values[0].value;
                }
            } else {
                this.value = this.values[idx+1].value;
            }
            this.valueChanged(this.value);
        }
    }

    public ngOnInit(): void {
        this.hostTabindex = this.tabindex;
    }

    public writeValue(value): void {
        this.value = value;
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public updateValue(value: string) {
        if (!this.isDisabled) {
            this.value = value;
            this.valueChanged(this.value);
        }
    }
    private valueChanged = (__: any) => {};
}
