<app-admin-tabs [activeAdminSection]="sectionName"></app-admin-tabs>
<div class="roleListComponent">
    <div class="filterSectionRow">
        <div class="filterSection">
            <div class="searchCriteriaTypePanel">
                <select class="searchCriteriaTypeText" name="selectedFilterField" id="searchCriteriaTypes" [(ngModel)]="selectedFilterField"
                    (change)="filterRoles()">
                    <option [value]="ff.filterField" *ngFor="let ff of dropdownFilterFields">{{ ff.displayText }}</option>
                </select>
                <span class="searchCriteriaTypeChevron">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
            </div>
            <form class="filterInputPanel" (ngSubmit)="filterRoles()" [formGroup]="roleListForm">
                <input type="text" id="searchQuery" autofocus class="searchQuery" name="searchQuery" formControlName="filterTerm"
                    placeholder="Enter Search Criteria" (change)="filterRoles()" />
            </form>
            <span class="iconSearch clickable blue">
                <i class="fa fa-search" aria-hidden="true"></i>
            </span>
        </div>
        <div class="createRoleSection" (click)="createRole()" *ngIf="isAdminInternal">
            <span class="createRoleIcon blue clickable">
                <i class="fa fa-plus-circle"></i>
            </span>
            <span class="createRoleLabel blue clickable actionText">Create</span>
        </div>
    </div>

    <div class="roleListContainer">
        <div class="roleListHeaderRow">
            <div class="headerItem">Role</div>
            <div class="headerItem">Description</div>
            <div class="headerItem">Type</div>
            <div class="headerItem">Customer</div>
            <div class="lastCol">&nbsp;</div>
        </div>
        <div class="roleDetailContainer" *ngIf="masterDataRoles.length">
            <cdk-virtual-scroll-viewport itemSize="25" style="height: 100%">
                <div class="roleDetailRow bottomBorderShadow" *cdkVirtualFor="let role of filteredRoles; let i = index">
                    <div class="rowDetailItem rowDetailItemName blue actionText" (click)="navigateToRole(role.RoleID)">{{
                            role?.roleName }}</div>
                    <div class="rowDetailItem">{{ role?.roleDescription }}</div>
                    <div class="rowDetailItem">{{ role?.roleType }}</div>
                    <div class="rowDetailItem">{{ role?.roleCustNo }}</div>
                    <div *ngIf="role.canDelete" class="lastCol" (click)="showConfirmDeleteModal(role)">
                        <i class="fa fa-times blue clickable" aria-hidden="true"></i>
                    </div>
                    <div *ngIf="!role.canDelete" class="lastCol">&nbsp;</div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>

<app-ok-cancel-modal *ngIf="isConfirmDeleteModalVisible" [title]="confirmDeleteRoleTitle" [message]="confirmDeleteRoleMessage"
    (onCancel)="hideConfirmDeleteRoleModal()" (onOk)="deleteRole($event)" [okPayload]="selectedRole"></app-ok-cancel-modal>
