import {Injectable, signal} from "@angular/core";
import {LoginSettings} from "../models/loginSettings";
import {User} from "../models/user";
import {AppState, initialAppState} from "../models/appState";
import {LoadingBar, initialLoadingBarState} from "../models/loadingBar";
import {initialSearchState, SearchState} from "../models/searchState";
import {SearchResponse} from "../models/searchResponse";
import {makeClone} from "../helpers/utilities";
import {SearchResultTypeSet} from "../models/searchResultType";
import * as SearchConstants from "../constants/searchCriteria";
import {FilteredListValue} from "../models/filteredListValue";
import {FilteredSearchResults} from "../models/filteredSearchResults";
import {FilteredSearchResultFieldValue} from "../models/filteredSearchResultFieldValue";
import {SearchCriterion} from "../models/searchCriterion";
import {SearchRequest} from "../models/searchRequest";
import {ContactSearchResult} from "../models/contact.searchResult";
import {NmfcSearchResult} from "../models/nmfc.searchResult";
import {PaginationPageNumber} from "../models/paginationPageNumber";
import {ClipboardNote, initialClips} from "../models/clipboardNote";
import {Favorite, initialFavorites} from "../models/favorite";
import {initialTemplates, Template} from '../models/template';
import {initialReportingState, ReportingState} from "../models/reportingState";
import {Dashboard} from "../models/dashboard";
import {DashboardView} from "../models/dashboardView";
import {InvoiceDetail} from "../models/invoice.detail";

@Injectable()
export class SignalsService {
    public loginSettingsSignal = signal<LoginSettings>(undefined);
    public userSignal = signal<User>(undefined);
    public loggedOutSignal = signal<boolean>(false);
    public appStateSignal = signal<AppState>(initialAppState);
    public loadingBarSignal = signal<LoadingBar>(initialLoadingBarState);
    public searchStateSignal = signal<SearchState>(initialSearchState);
    public clipsSignal = signal<Array<ClipboardNote>>(initialClips);
    public favoritesSignal = signal<Array<Favorite>>(initialFavorites);
    public templatesSignal = signal<Array<Template>>(initialTemplates);
    public reportingSignal = signal<ReportingState>(initialReportingState);
    public displaySignal = signal<any>({});
    public dashboardsSignal = signal<Array<Dashboard>>([]);
    public dashboardViewContentSignal = signal<Array<any>>([]);
    public dashboardViewLibrarySignal = signal<Array<DashboardView>>([]);
    public currentInvoiceSignal = signal<InvoiceDetail|{}>({});
    public tmaSignal = signal<string>('');

    public addUserFavorite(favList: Array<Favorite>) {
        let newFavorites = makeClone(this.favoritesSignal());
        favList.forEach((fav: Favorite) => {
            newFavorites.push(fav);
        });
        this.favoritesSignal.set(newFavorites);
    }

    public removeUserFavorite(favNumberToRemove) {
        let newFavorites = makeClone(this.favoritesSignal());
        const removedFavoriteIndex = newFavorites.findIndex((fav: Favorite) => fav.favoriteNo === favNumberToRemove);
        if (removedFavoriteIndex !== -1) {
            newFavorites.splice(removedFavoriteIndex, 1);
        }
        this.favoritesSignal.set(newFavorites);
    }

    public clearUserFavorites() {
        this.favoritesSignal.set([]);
    }

    public clearSearchPageNumber() {
        this.searchStateSignal.set({ ...this.searchStateSignal(), paginationPageNumber: null });
    }
    public loadSearchPageNumber(pageNumber:  PaginationPageNumber) {
        this.searchStateSignal.set({ ...this.searchStateSignal(), paginationPageNumber: pageNumber });
    }

    public loadDashboardViewQuery(query:  SearchCriterion[]) {
        this.searchStateSignal.set({ ...this.searchStateSignal(), dashboardViewQuery: query });
    }

    public loadNmfcSearchResults(nmfcSearchResults:  NmfcSearchResult[]) {
        this.searchStateSignal.set({ ...this.searchStateSignal(), nmfcSearchResults: nmfcSearchResults });
    }

    public loadRecordContacts( contactSearchResults?: ContactSearchResult[]) {
        if (contactSearchResults) {
            this.searchStateSignal.set({ ...this.searchStateSignal(), recordContacts: contactSearchResults });
        } else {
            this.searchStateSignal.set({ ...this.searchStateSignal(), recordContacts: null });
        }
    }

    public updateFilteredSearchResults() {
        let newState;
        newState = makeClone(this.searchStateSignal());
        newState.filteredSearchResults.entities = newState.searchResults.filter(
            responses => responses.entityType === newState.filterTerm
        )[0].entities;

        newState.filteredSearchResults.fieldValues.forEach(field => {
            if (!field.query) {
                field.pattern = SearchConstants.SEARCH_CRITERIA_pattern.EXACT;
            }

            if (!field.query && field.isDate) { // defaulting to remove the start and end fields in event of clearing ranged date field
                field = {
                    displayName: field.displayName,
                    fieldName: field.fieldName,
                    uniqueValues: field.uniqueValues,
                    query: field.query,
                    pattern: field.pattern,
                    isDate: field.isDate
                };
            }

            const newSearchCriterion: SearchCriterion = {
                type: field.fieldName,
                value: field.query,
                entityType: newState.filterTerm,
                boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                pattern: field.pattern
            };

            if (field.isDate && field.pattern === SearchConstants.SEARCH_CRITERIA_pattern.RANGE) {
                newSearchCriterion.start = (field.start ? field.start : '');
                newSearchCriterion.end = (field.end ? field.end : '');
            }

            const searchCriterionIndex = newState.searchRequest.searchCriteria.findIndex((searchCriterion: SearchCriterion) => searchCriterion.type === newSearchCriterion.type);

            if (searchCriterionIndex === -1 && field.query) {
                newState.searchRequest.searchCriteria.push(newSearchCriterion);
            } else if (searchCriterionIndex !== -1 && !field.query) {
                newState.searchRequest.searchCriteria.splice(searchCriterionIndex, 1);
            } else if (field.query) {
                newState.searchRequest.searchCriteria[searchCriterionIndex] = newSearchCriterion;
            }
        });
        this.searchStateSignal.set(newState);
    }

    public completeDashboardViewSearch() {
        this.searchStateSignal.set(initialSearchState);
    }

    public updateSearchRequestQuery(searchRequest: SearchRequest) {
        this.searchStateSignal.set({
            ...this.searchStateSignal(),
            searchRequest: searchRequest
        })
    }

    public createFilteredSearchResultsFieldValues(entityFieldsAndDates: {entityFilterFields: object, entityDateFields: string[]}) {
        let newState: SearchState;

        newState = makeClone(this.searchStateSignal());
        newState.filteredSearchResults.fieldValues = [];
        const entityFilterFields = entityFieldsAndDates.entityFilterFields;
        const entityDateFields = entityFieldsAndDates.entityDateFields;

        Object.keys(entityFilterFields).forEach((field: string) => { // tuples of field: displayName e.g. "wk_end_date": "Invoice Date"
            if (newState && newState.filteredSearchResults && newState.filteredSearchResults.filteredListValues) {

                const matchedFilterListValue: FilteredListValue = newState.filteredSearchResults.filteredListValues.find((fLV: FilteredListValue) => fLV.fieldName === field);
                if (!matchedFilterListValue) {
                    return;
                }
                const newFilteredSearchResultFieldValue: FilteredSearchResultFieldValue = {
                    displayName: entityFilterFields[field],
                    fieldName: field,
                    uniqueValues: [],
                    query: '',
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.EXACT,
                    isDate: entityDateFields !== undefined ? entityDateFields.includes(field) : false
                };

                newFilteredSearchResultFieldValue.uniqueValues = matchedFilterListValue.uniqueValues;
                newState.filteredSearchResults.fieldValues.push(newFilteredSearchResultFieldValue);
            }
        });

        newState.searchRequest.searchCriteria.forEach((searchCriterion: SearchCriterion) => {
            if (!searchCriterion || searchCriterion.type === SearchConstants.SEARCH_CRITERIA_type.ALL) {
                return;
            }

            const matchedFilteredSearchResultFieldValue: FilteredSearchResultFieldValue = newState.filteredSearchResults.fieldValues.find((fieldValue: FilteredSearchResultFieldValue) => fieldValue.fieldName === searchCriterion.type);

            if (matchedFilteredSearchResultFieldValue) {
                matchedFilteredSearchResultFieldValue.query = searchCriterion.value;
                matchedFilteredSearchResultFieldValue.pattern = searchCriterion.pattern;
            }

            if (matchedFilteredSearchResultFieldValue && matchedFilteredSearchResultFieldValue.isDate && matchedFilteredSearchResultFieldValue.pattern === SearchConstants.SEARCH_CRITERIA_pattern.RANGE) { // NOTE Hassan (08/21/18) - only date ranges have pattern of 'Range'
                matchedFilteredSearchResultFieldValue.start = searchCriterion.start;
                matchedFilteredSearchResultFieldValue.end = searchCriterion.end;
            }
        });

        this.searchStateSignal.set(newState);
    }

    public setEntityFilter(newFilter: string) {
        this.searchStateSignal.set({
            ...this.searchStateSignal(),
            filterTerm: newFilter,
            filteredSearchResults: this.searchStateSignal().searchResults.filter(responses => responses.entityType === newFilter)[0] as FilteredSearchResults
        })
    }

    public updateSearchState(searchState: SearchState) {
        this.searchStateSignal.set(searchState);
    }

    public clearSearchResults() {
        this.searchStateSignal.set(initialSearchState);
    }

    public loadSearchResults(res: SearchResponse) {
        let searchResultSet: SearchResultTypeSet;
        let filteredListValue: FilteredListValue;
        let uniqueValue: any;
        const searchResults: Array<SearchResultTypeSet> = res.searchResults.filter((filteredSearchResultSet: SearchResultTypeSet) => {
            if (filteredSearchResultSet.entities && filteredSearchResultSet.entities.length) {
                return filteredSearchResultSet;
            }
        });

        for (searchResultSet of searchResults) {
            if (searchResultSet.filteredListValues) {
                for (filteredListValue of searchResultSet.filteredListValues) {
                    const fieldType = filteredListValue.fieldType;
                    for (uniqueValue of filteredListValue.uniqueValues) {
                        if (!(!uniqueValue || typeof uniqueValue !== 'string' || !uniqueValue.length || uniqueValue.charAt(0) !== ' ')) {
                            uniqueValue = uniqueValue.substring(1);

                            if (fieldType === SearchConstants.FILTER_LIST_VALUE_fieldType.NUMBER) {
                                uniqueValue = parseFloat(uniqueValue);
                            }
                        }
                    }
                }
            }
        }

        this.searchStateSignal.set({
            ...this.searchStateSignal(),
            searchResults,
            searchTotals: res.searchTotals
        });
    }

    public clearLoginSettings() {
        this.loginSettingsSignal.set(undefined);
    }

    public clearUserLoggedIn() {
        this.userSignal.set(undefined);
    }

    public updateAppState(modifyProps) {
        let appState = Object.assign({}, this.appStateSignal());
        Object.keys(modifyProps).map((prop) => {
            // only update known properties, to catch errors
            if (prop in appState) {
                appState[prop] = modifyProps[prop];
            } else {
                console.error(`Invalid property [${prop}] for AppState.`);
            }
        });
        this.appStateSignal.set(appState);
    }

    public clearAppState() {
        this.appStateSignal.set(initialAppState);
    }

    public clearLoadingBar() {
        this.loadingBarSignal.set(Object.assign(
            {},
            {
                isBlockerVisible: false,
                isLoaderVisible: false,
                refCount: 0,
            }
        ));
    }

    public stopLoadingBar() {
        this.loadingBarSignal.set(Object.assign(
            {},
            {
                isBlockerVisible: (this.loadingBarSignal().isBlockerVisible && (this.loadingBarSignal().refCount - 1) === 0) ? false : this.loadingBarSignal().isBlockerVisible,
                isLoaderVisible: ((this.loadingBarSignal().refCount - 1) > 0),
                refCount: this.loadingBarSignal().refCount - 1,
            }
        ));
    }

    public startLoadingBar(shouldBlock: boolean = false) {
        this.loadingBarSignal.set(Object.assign(
            {},
            {
                isBlockerVisible: shouldBlock ? true : this.loadingBarSignal().isBlockerVisible,
                isLoaderVisible: true,
                refCount: this.loadingBarSignal().refCount + 1,
            }
        ));
    }
}
