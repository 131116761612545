import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

export interface UnsavedChangesComponent {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class UnsavedChangesRouteGuard  {

    canDeactivate(component: UnsavedChangesComponent) {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}
