<ng-template #defaultMenuItemRenderer let-item>
    {{item.text}}
</ng-template>

<div class="headerPanel">
    <div class="headerItemsPanel">
        <ng-container *ngFor="let menuItem of menu">
            <div *ngIf="menuItem.enabled" class="headerItem dropdown clickable">
                <span [ngClass]="{dropbtn: menuItem.submenu}" (click)="clicked(menuItem)">
                    <ng-container [ngTemplateOutlet]="menuItemRenderer || defaultMenuItemRenderer" [ngTemplateOutletContext]="{ $implicit: menuItem }"></ng-container>
                </span>
                <ng-container *ngIf="menuItem.submenu">
                    &nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i>

                    <div class="dropdown-content">
                        <ng-container *ngFor="let submenuItem of menuItem.submenu">
                            <div *ngIf="submenuItem.enabled" class="dropdownItem clickable" (click)="clicked(submenuItem)">
                                <ng-container [ngTemplateOutlet]="subMenuItemRenderer || defaultMenuItemRenderer" [ngTemplateOutletContext]="{ $implicit: submenuItem }"></ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
