import { HttpClient } from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';

import * as Constants from '../constants/constants';

import { environment } from '../../environments/environment';
import {SignalsService} from "./signals.service";
import {toObservable} from "@angular/core/rxjs-interop";

export const { version: appVersion } = require('../../../package.json');

export const LogLevels = {
    ALL: 0, // only for use in appState.logging.logLevel
    DEBUG: 1,
    INFO: 2,
    WARN: 4,
    ERROR: 8,
    FATAL: 16,
    OFF: 32, // only for use in appState.logging.logLevel
}

@Injectable()
export class LoggingService implements OnDestroy {
    public appVersion: string;
    private _sessionId: number = 0;
    private appState: any;
    private appStateSubscription: any;
    private apiBaseUrl: string = environment.apiBaseUrl;

    constructor(
        private _httpClient: HttpClient,
        private _signalsService: SignalsService,
    ) {
        this.appVersion = appVersion;
        this.appStateSubscription = toObservable(this._signalsService.appStateSignal).subscribe(appState => {
            this.appState = appState;
        });
    }

    public ngOnDestroy() {
        this.appStateSubscription.unsubscribe();
    }

    public initialize({ sessionId }): void {
        this._sessionId = sessionId;
    }

    public sendLogMessage(level, message): void {
        const payload = {
            applicationName: Constants.AppName,
            applicationVersion: this.appVersion,
            date: new Date(),
            exception: null,
            ipAddress: null,
            level,
            line: null,
            logger: null,
            message,
            server: null,
            source: 'html browser client',
            stackTrace: null,
            thread: null,
            userAgent: window.navigator.userAgent,
            userId: this._signalsService.userSignal() && this._signalsService.userSignal().userlogin || null,
            userName: this._signalsService.userSignal() && this._signalsService.userSignal().name || null,
            sessionId: this._sessionId,
        };
        this.send(payload);
    }

    public sendLogException(level, exc): void {
        const payload = {
            applicationName: Constants.AppName,
            applicationVersion: this.appVersion,
            date: new Date(),
            exception: null,
            ipAddress: null,
            level,
            line: null,
            logger: null,
            message: exc.message,
            server: null,
            source: 'html browser client',
            stackTrace: exc.stackTrace || null,
            thread: null,
            userAgent: window.navigator.userAgent,
            userId: this._signalsService.userSignal() && this._signalsService.userSignal().userlogin || null,
            userName: this._signalsService.userSignal() && this._signalsService.userSignal().name || null,
            sessionId: this._sessionId,
        };
        this.send(payload);
    }

    private send(payload): void {
        if (this.shouldLog(payload)) {
            if (this.appState['logging.sendToConsole']) {
                if (payload.level >= LogLevels.ERROR) {
                    console.error(`[${payload.level}] => ${payload.message}`);
                } else {
                    console.log(`[${payload.level}] => ${payload.message}`);
                }
            }
            if (this.appState['logging.sendToApi']) {
                this.sendLogDataToApi({ payload });
            }
        }
    }

    private shouldLog(payload): boolean {
        if (this.appState && this.appState['logging.logLevel'] <= payload.level) {
            return true;
        } else {
            return false;
        }
    }

    private sendLogDataToApi({ payload }): void {
        try {
            // const headers = new Headers();
            // headers.append('Content-Type', 'application/json');
            this._httpClient.post<void>(`${this.apiBaseUrl}/log`, payload).subscribe(
                res => {
                    // no response expected
                },
                err => {
                    console.log(`Error occurred in LoggingService.sendLogDataToApi(${payload}) => ${err}`);
                });
        } catch (err) {
            console.log(`Exception occurred in LoggingService.sendLogDataToApi(${payload}) => ${err}`);
        }
    }
}
