import { ContactSearchResult } from './contact.searchResult';
import { FilteredSearchResults } from './filteredSearchResults';
import { NmfcSearchResult } from './nmfc.searchResult';
import { PaginationPageNumber } from './paginationPageNumber';
import { SearchCriterion } from './searchCriterion';
import { SearchRequest } from './searchRequest';
import { SearchResultTypeSet } from './searchResultType';
import { SearchTotal } from './searchTotal';

export class SearchState {
  searchResults: Array<SearchResultTypeSet>;
  filterTerm: string;
  filteredSearchResults: FilteredSearchResults;
  searchRequest: SearchRequest;
  searchTotals: Array<SearchTotal>;
  recordContacts: Array<ContactSearchResult>;
  nmfcSearchResults: Array<NmfcSearchResult>;
  dashboardViewQuery: Array<SearchCriterion>;
  paginationPageNumber: PaginationPageNumber;
}

export const initialSearchState: SearchState = {
    searchRequest: {
        searchCriteria: []
    },
    searchResults: [],
    searchTotals: [],
    filterTerm: '',
    filteredSearchResults: null,
    recordContacts: null,
    nmfcSearchResults: [],
    dashboardViewQuery: null,
    paginationPageNumber: null,
};
