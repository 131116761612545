<div class="overlay">
  <div class="create-dashboard-modal">
    <div class="modal-header">
      <div class="modalHeaderContent">Select a Ship Date</div>
      <div class="modalHeaderContent gray">
        <i class="fa fa-times clickable" aria-hidden="true" (click)="closeModal()"></i>
      </div>
    </div>
    <div class="modal-body">
      <div class="bodyContent">
        <div class="filter-container">
          <div class="dateLabel">
            <span class="required">*</span> Date</div>
          <form>
            <div class="queryField">
              <input class="dateInput" placeholder="" name="filterQuery">
            </div>
          </form>
        </div>

        <div class="calendarContainer">
          <div class="calendarHolder">
          </div>
        </div>
      </div>

      <div class="buttonHolder">
        <button class="btn-outline clickable" (click)="closeModal()">Cancel</button>
        <button class="btn-outline clickable" (click)="passParsedDateToParent()">Apply Date</button>
      </div>
    </div>
    <div class="bottom"></div>
  </div>
</div>