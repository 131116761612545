<!--Following Div is only loaded if coming in via user's profile since breadcrumbs/button panel are
    typically loaded in Admin parent, and user's profile has no admin parent -->
<div *ngIf="!userId" class="padLeft padRight adminHeader">
    <div>
        <div class="adminHeaderTextMain">Profile</div>
        <app-breadcrumbs></app-breadcrumbs>
    </div>

    <app-admin-button-panel class="rightItems"></app-admin-button-panel>
</div>

<div class="userAdminBody padLeft padRight">
    <app-user-tabs [activeUserSection]="activeUserSection" (selectUserTab)="switchUserTabs($event)"
        [isCreditCardPaymentPermissible]="isCreditCardPaymentPermissible"></app-user-tabs>
    <app-admin-user-detail [activeUserSection]="activeUserSection" [isUserProfileAdminFieldVisible]="isUserProfileAdminFieldVisible"
                           [userForm$]="userFormReadySubject.asObservable()" [isCreditCardPaymentPermissible]="isCreditCardPaymentPermissible"></app-admin-user-detail>
</div>
