<div class="csBestCarrierIcon">
    <!-- Remove false && below to re-enable stars rating display for users. -->
    <app-carrier-option-icon *ngIf="bestCarrierOption" (infoClick)="doInfoClick()" [carrierOption]="bestCarrierOption"
                             [isCarrierKPIShown]="false && isCarrierKPIShown" [showInfoIcon]="true"
                             infoTooltip="Click for Terminal Details" (imageClick)="doImageClick()"></app-carrier-option-icon>
    <app-carrier-option-icon *ngIf="!bestCarrierOption" (infoClick)="doInfoClick()" [rate]="rate"
                             [isCarrierKPIShown]="false && isCarrierKPIShown" [showInfoIcon]="true"
                             infoTooltip="Click for Terminal Details" (imageClick)="doImageClick()"></app-carrier-option-icon>
</div>
<div class="csBestCarrierInfo">
    <div class="csBestCarrierButton">
        <app-button style="width: 100%; text-align: center"
                    (click)="selectCarrier()">{{buttonText}}</app-button>
    </div>
</div>
