import {Injectable} from '@angular/core';
import { Router } from '@angular/router';

import {LoginSettings} from '../models/loginSettings';
import {User} from '../models/user';
import {LoginService} from "../services/login.service";
import {SignalsService} from "../services/signals.service";

@Injectable()
export class UserAuthenticationRouteGuard  {
    public userProfile: User;
    public userSettings: LoginSettings;

    constructor(
        private _router: Router,
        private _signalsService: SignalsService,
    ) {
    }

    public canActivate(): boolean {
        if (this._signalsService.userSignal() && this._signalsService.loginSettingsSignal()) {
            return true;
        } else {
            this._router.navigate(['']);
            return false;
        }
    }

}
