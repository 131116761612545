import { Component, ElementRef, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {SetPassword} from '../../models/setPassword';
import {PasswordService} from '../../services/password.service';
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'app-password-reset',
    styleUrls: ['./app-password-reset.component.scss'],
    templateUrl: './app-password-reset.component.html'
})
export class AppPasswordResetComponent implements OnInit {
    public resetPasswordForm: UntypedFormGroup;
    public passwordRegex;
    public passwordRegexText;
    public token: string;

    constructor(
        private _notificationService: NotificationService,
        private _formBuilder: UntypedFormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private _passwordService: PasswordService,
    ) { }

    public ngOnInit() {
        this._route.params.pipe(
            switchMap((params) => {
                this.token = params['token'];
                return this._passwordService.getPasswordPolicy();
            }),
            map(result => {
                this.passwordRegex = result.regex;
                this.passwordRegexText = result.Policy;

                this.resetPasswordForm = this._formBuilder.group({
                    password: new UntypedFormControl(new SetPassword()),
                });
            }),
        ).subscribe();
    }

    public setFocusPassword(passwordElement: ElementRef) {
        passwordElement.nativeElement.focus();
    }

    public checkForEnter($event: KeyboardEvent) {
        if ($event.keyCode === 13) {
            this.processresetPassword();
        }
    }

    public processresetPassword() {
        if (this.resetPasswordForm.valid) {
            this._passwordService.resetPassword(this.token, this.resetPasswordForm.get('password').value.newPasswordValue).pipe(
                map((response) => {
                    this._router.navigate([''])
                    this._notificationService.notifySuccess({title: 'Password Reset', message: response.messages[0].message});
                })
            ).subscribe();
        }
    }
}
