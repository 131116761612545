import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class PreviousRouteService {
  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private _router: Router
  ) {
    this.currentUrl = this._router.url;
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl(): string {
    if (!this.previousUrl) {
      return '';
    }

    return this.previousUrl;
  }

}
