export class MarkupRate {
  effective: string;
  end: string;
  rate: number;
  rateType: number;
  displayRateOn?: string;
  rateOn: string;
  minMarkup: number;
  minMarkupon: string;
  maxMarkup: number;
  maxMarkupType: number;

  // fields returned in response only
  markupRateID?: number;
  overlap?: boolean;
  overlapsWith?: string;
}
