import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as Constants from '../../../constants/constants';

@Component({
    selector: 'app-user-tabs',
    styleUrls: ['./user-tabs.component.scss'],
    templateUrl: './user-tabs.component.html',
})
export class UserTabsComponent {
    @Input() public activeUserSection: string;
    @Input() public isCreditCardPaymentPermissible: boolean;
    @Output() public selectUserTab = new EventEmitter();

    public constants = Constants;
}
