import {HttpErrorResponse, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {saveAs} from 'file-saver-es';
import {catchError, map} from 'rxjs/operators';
import {UserHelper} from '../helpers/userHelper';
import {Document} from '../models/document';
import {DocumentUploadRequest} from '../models/documentUploadRequest';
import {ResponseDTO} from '../models/responseDto';
import {ApiService, REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT} from './api.service';
import {NotificationService} from "./notification.service";

@Injectable()
export class FilesService {

    constructor(private _api: ApiService,
                private _notificationService: NotificationService,
                private _userHelper: UserHelper,
    ) {}

    public getDocuments(shipmentId) {
        return this._api.callApiService<ResponseDTO<Document[]>>(new HttpRequest(REQUEST_TYPE_GET, `shipment/document/${this._userHelper.getUserCustomer()}/${shipmentId}`)).pipe(
            map((response) => {
                if (response && response.isValid) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Get Documents` });
                throw err;
            })
        );
    }

    public doUpload(document: DocumentUploadRequest) {
        const req = new HttpRequest(REQUEST_TYPE_POST, `shipment/document`, document);
        return this._api.callApiService<ResponseDTO<void>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    return document;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `File Upload` });
                throw err;
            }));
    }

    public downloadFileAttachment(d: Document) {
        const req = new HttpRequest(REQUEST_TYPE_GET, `shipment/document/download/${d.id}`);
        return this._api.callApiServiceBinary(req).pipe(
            map(response => {
                const blob = new Blob([response], { type: 'application/octet-stream' }); // { type: response.type } ||  { type: 'application/octet-stream' }
                saveAs(blob, d.fileName);
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `File Download` });
                throw err;
            })
        );
    }

    public deleteFileAttachment(documentId: number) {
        const req = new HttpRequest(REQUEST_TYPE_DELETE, `shipment/document/${documentId}`);
        return this._api.callApiService<ResponseDTO<void>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    this._notificationService.notifySuccess({ title: 'Delete Attachment', message: `Successfully deleted attachment.` });
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Delete Attachment` });
                throw err;
            })
        )
    }

    public updateFileAttachmentDescription(updatedFile: { docId: number, description: string }) {
        const req = new HttpRequest(REQUEST_TYPE_PUT, `shipment/document`, updatedFile);
        return this._api.callApiService<ResponseDTO<any>>(req).pipe(
            map((response) => {
                if (response && response.isValid) {
                    this._notificationService.notifySuccess({ title: 'Update Attachment', message: `Successfully updated attachment.` });
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Update Attachment` });
                throw err;
            })
        );
    }
}
