<div class="searchContainer">
  <app-entity-filter class="filterPanel" *ngIf="searchResponse?.searchResults?.length">
  </app-entity-filter>
  <div class="homePanel">
    <span class="breadcrumbHeader" [ngClass]="{ 'padLeft': !searchResponse?.searchResults?.length }">
      {{ getBreadcrumbHeaderText() }}
    </span>

    <div class="breadcrumbsButton">
      <app-breadcrumbs [ngClass]="{ 'padLeft': !searchResponse?.searchResults?.length }"></app-breadcrumbs>

      <div class="saveButtonHolder" *ngIf="searchResponse.filterTerm">
        <div class="saveSearchIcon padRight clickable" (click)="saveDashboardViewContent()">
          <span>
            <i class="fa fa-binoculars" aria-hidden="true"></i>
          </span>
        </div>
      </div>

    </div>
    <app-search-bar class="padRight" [ngClass]="{ 'padLeft': !searchResponse?.searchResults?.length }" (processSearch)="handleSearchBarRequestChanges($event)"></app-search-bar>

    <app-search-results *ngIf="searchResponse.searchResults.length"></app-search-results>

    <div class="paginationPanelContainer" *ngIf="searchResponse?.searchResults?.length && searchResponse.filterTerm">
      <app-search-pagination-panel [searchResultTotal]="filteredSearchResultTotal" (pageChanged)="handlePaginationPanelChanges($event)"></app-search-pagination-panel>
    </div>
  </div>
  <app-ok-cancel-modal *ngIf="showOkCancelModal" [title]="saveViewFromSearchTitle" [message]="saveViewFromSearchMessage" [suppressCancelButton]="true"
    (onOk)="closeOkCancelModal()"></app-ok-cancel-modal>
</div>