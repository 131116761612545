<div class="masterDataProductsListComponent">
    <div class="filterSectionRow">
        <div class="filterSection">
            <div class="searchCriteriaTypePanel">
                <select class="searchCriteriaTypeText" name="selectedFilterField" id="searchCriteriaTypes" [(ngModel)]="selectedFilterField"
                    (change)="filterProducts()">
                    <option [value]="ff.filterField" *ngFor="let ff of dropdownFilterFields">{{ ff.displayText }}</option>
                </select>
                <span class="searchCriteriaTypeChevron">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
            </div>
            <form class="filterInputPanel" (ngSubmit)="filterProducts()" [formGroup]="productListForm">
                <input type="text" id="searchQuery" autofocus class="searchQuery" name="searchQuery" formControlName="filterTerm" placeholder="Enter Search Criteria"
                    (change)="filterProducts()" />
            </form>
            <span class="iconSearch clickable blue">
                <i class="fa fa-search" aria-hidden="true"></i>
            </span>
        </div>
        <div class="createCustomerSection">
            <span class="createCustomerIcon blue clickable">
                <i class="fa fa-plus-circle"></i>
            </span>
            <span class="createCustomerLabel blue clickable actionText" (click)="navigateToCreateProductPage()">Create</span>
        </div>
    </div>

    <div class="customerListContainer">
        <div class="customerListHeaderRow">
            <div class="firstColHeaderItem">Product Name</div>
            <div class="productIDCol">Product ID</div>
            <div class="classCol">Class</div>
            <div class="nmfcCol">NMFC</div>
            <div class="descriptionCol">Description</div>
            <div class="lastCol">&nbsp;</div>
        </div>
        <div class="customerDetailContainer" *ngIf="masterDataProducts.length">
            <cdk-virtual-scroll-viewport itemSize="25" style="height: 100%">
                <div class="customerDetailRow bottomBorderShadow" *cdkVirtualFor="let product of filteredProducts; let i = index" (click)="navigateToProductShowPage(product)">
                    <div class="firstCol rowDetailItemName blue actionText ellipsis">{{ product?.Name }}</div>
                    <div class="productIDCol">{{ product?.produtsId }}</div>
                    <div class="classCol">{{ product?.class }}</div>
                    <div class="nmfcCol">{{ product?.NMFC }}</div>
                    <div class="descriptionCol ellipsis">{{ product?.Description }}</div>
                    <div class="lastCol" (click)="showConfirmDeleteModal($event, product)">
                        <i class="fa fa-times blue clickable" aria-hidden="true"></i>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>

<app-ok-cancel-modal *ngIf="isConfirmDeleteModalVisible" [title]="confirmDeleteProductTitle" [message]="confirmDeleteProductMessage"
    (onCancel)="hideConfirmDeleteProductModal()" (onOk)="deleteProduct($event)" [okPayload]="selectedProduct"></app-ok-cancel-modal>
