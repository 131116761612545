import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Product } from '../../../models/product';

@Component({
  selector: 'app-product-details-modal',
  templateUrl: './product-details-modal.component.html',
  styleUrls: ['./product-details-modal.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  @Input() selectedProduct: Product;
  @Output() closeProductDetailsModal = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {

  }

  public closeModal(): void {
    this.closeProductDetailsModal.emit();
  }
}
