import {HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {ResponseDTO} from '../models/responseDto';
import {SetPassword} from '../models/setPassword';
import {ApiService, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT} from './api.service';
import {IntegrationsToken} from "../models/integrationsToken";
import {NotificationService} from "./notification.service";

@Injectable()
export class PasswordService {

    constructor(private _api: ApiService,
                private _notificationService: NotificationService,
    ) {
    }

    public resetLockout(userId: string) {
        const req = new HttpRequest(REQUEST_TYPE_POST, `MasterData/User/lockoutReset/${userId}`, {});
        return this._api.callApiService<ResponseDTO<any>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    return response.messages[0].message;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Lockout Reset` });
                throw err;
            })
        );
    }

    public resetPassword(token: string, password: string) {
        const req = new HttpRequest(REQUEST_TYPE_POST, 'MasterData/Password/Reset', {
            token,
            password
        });
        return this._api.callApiService<ResponseDTO<any>>(req).pipe(
            map(response => {
                if (response && response.isValid) {
                    return response;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Reset Password` });
                throw err;
            })
        );
    }

    public updatePassword(userId: string, passwordInfo: SetPassword) {
        const req = new HttpRequest(REQUEST_TYPE_PUT, 'MasterData/Password/Update', {
            userid: userId,
            password: passwordInfo.oldPasswordValue,
            newPassword: passwordInfo.newPasswordValue
        });
        return this._api.callApiService<ResponseDTO<any>>(req).pipe(
            map(response => {
                if (response && response.isValid && response.dto) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Update Password` });
                throw err;
            })
        );
    }

    public getPasswordPolicy() {
        return this._api.callApiService<ResponseDTO<any>>(new HttpRequest(REQUEST_TYPE_GET, `MasterData/Password/Policy`)).pipe(
            map((response) => {
                if (response && response.isValid) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Get Password Policy` });
                throw err;
            })
        );
    }

    public sendPasswordResetEmail(username: string) {
        const passwordResetReq = new HttpRequest(REQUEST_TYPE_POST, `MasterData/Password/Recovery`, {
            username
        });
        return this._api.callApiService<ResponseDTO<any>>(passwordResetReq).pipe(
            map(response => {
                if (response && response.isValid) {
                    return response;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Password Reset` });
                    throw err;
                }
            )
        );
    }

    public getNewToken() {
        const integrationsTokenReq = new HttpRequest(REQUEST_TYPE_POST, `integrations/token`, {});
        return this._api.callApiService<ResponseDTO<IntegrationsToken>>(integrationsTokenReq).pipe(
            map(response => {
                if (response && response.isValid) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `New Integrations Token` });
                    throw err;
                }
            )
        )
    }

    public getIntegrationsToken() {
        const integrationsTokenReq = new HttpRequest(REQUEST_TYPE_GET, `integrations/token`);
        return this._api.callApiService<ResponseDTO<IntegrationsToken>>(integrationsTokenReq).pipe(
            map(response => {
                if (response && response.isValid) {
                    return response.dto;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Get Integrations Token` });
                    throw err;
                }
            )
        );
    }
}
