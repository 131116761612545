<div class="overlay">
    <div class="quoteSelectionModal">
        <div class="modalHeader">
            <div>{{title}}</div>
            <div class="closeModalContainer">
                <app-button *ngIf="!instantSelect" [inactive]="!lastRateClicked" [buttonColor]="'blue'" (click)="selectClicked()" >SELECT</app-button>
                <div class="closeModalIconContainer clickable">
                    <i class="fa fa-times closeModalIcon" (click)="cancelClicked()"></i>
                </div>
            </div>
        </div>

        <app-carrier-select class="carrierSelect" [lowestCostRateId]="lowestCostRateId" [fastestDeliveryRateId]="fastestDeliveryRateId" (showCarrierDetails)="showTerminalDetails($event)" [isCarrierKPIShown]="isCarrierKPIShown" [isRatedClassShown]="isRatedClassShown" (selected)="selected($event)" [rates]="rates"></app-carrier-select>
    </div>
</div>

<app-terminal-details-modal *ngIf="isTerminalDetailsModalVisible" [rates]="ratesToShow" (cancel)="isTerminalDetailsModalVisible = false"></app-terminal-details-modal>
