export class Shipper {
    // primary identifier ------------- //
    Shipper: number; // Shipper ID;
    // alternate identifier ----------- //
    contactsId: number;
    Customer: string; // 'ACENTB';
    ID: number; // BOL_ID
    // associated data ---------------- //
    BOL: string; // 813481;
    Pickup_date: string; // 'August; 08 2017 00:00:00';
    shipper_address: string; // '2345 Vauxhall Rd';
    shipper_address2: string; // '';
    shipper_city: string; //  'Union ';
    shipper_contact_email: string; //  '';
    shipper_contact_fax: string; // 9086323097;
    shipper_contact_name: string; // 'Receiving';
    shipper_contact_phone: string; // '908-351-3400';
    shipper_country: string; // 'US';
    shipper_hours: string; // '';
    Shipper_Name: string; // 'American Cargo Express'
    shipper_name2: string; // '';
    shipper_ref: string; // '';
    shipper_state: string; // 'NJ';
    shipper_zip: string; // '07083';
    shipperCallAppt: number; //  0;
    shipperCommercialResidential: string; //  'C';
    shipperHoursClose: string; // 'January; 01 1970 17:00:00';
    shipperHoursOpen: string; // 'January; 01 1970 08:00:00';
    shipperTimezone: string; // 'E';
    User: number; //  7267;
}
