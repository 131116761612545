import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import * as Constants from '../../../constants/constants';
import * as SearchConstants from '../../../constants/searchCriteria';
import { makeClone } from '../../../helpers/utilities';
import { ContactSearchResult } from '../../../models/contact.searchResult';
import { Favorite } from '../../../models/favorite';
import { FavoriteRequest } from '../../../models/favoriteRequest';
import { InvoiceSearchResult } from '../../../models/invoice.searchResult';
import { MarkupSearchResult } from '../../../models/markup.searchResult';
import { ProductSearchResult } from '../../../models/product.searchResult';
import { QuoteSearchResult } from '../../../models/quote.searchResult';
import { SearchResultTypeSet } from '../../../models/searchResultType';
import {SearchState} from '../../../models/searchState';
import { SearchTotal } from '../../../models/searchTotal';
import { ShipmentSearchResult } from '../../../models/shipment.searchResult';
import { UserSearchResult } from '../../../models/user.searchResult';
import { LoggingService, LogLevels } from '../../../services/logging.service';
import {ShipmentService} from '../../../services/shipment.service';
import {SignalsService} from "../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {SearchService} from "../../../services/search.service";
import {FavoritesService} from "../../../services/favorites.service";
import {NotificationService} from "../../../services/notification.service";
import {InvoiceService} from "../../../services/invoice.service";

@Component({
    selector: 'app-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnDestroy {
    public searchState: SearchState;
    public isExpanded: object = {};
    private searchSubscription: any;
    public displayData;
    public defaultSearchColumns;
    public searchEntityTypes = SearchConstants.SEARCH_CRITERIA_entityType;
    private favoritesSubscription: any;
    public userFavorites: Array<Favorite> = [];
    private search$;
    private favorites$;

    constructor(
        private _loggingService: LoggingService,
        private _notificationService: NotificationService,
        private _router: Router,
        private _signalsService: SignalsService,
        private _invoiceService: InvoiceService,
        private _searchService: SearchService,
        private _favoritesService: FavoritesService,
        private _shipmentService: ShipmentService,
    ) {
        this.searchState = _signalsService.searchStateSignal();
        this.search$ = toObservable(_signalsService.searchStateSignal);
        this.favorites$ = toObservable(_signalsService.favoritesSignal);
    }

    public ngOnInit() {
        this.displayData = this._signalsService.displaySignal();

        this.searchSubscription = this.search$.subscribe((search: SearchState) => {
            this.searchState = makeClone(search);
            // initially, show all record sets from the search as expanded
            this.searchState.searchResults.map((r) => {
                this.isExpanded[r.entityType] = true;
            });

            if (this.searchState && this.searchState.filterTerm) {
                const columnsToDisplay = this.displayData.searchEntityColumns[this.searchState.filterTerm];
                if (columnsToDisplay) {
                    this.defaultSearchColumns = Object.keys(columnsToDisplay);
                } else {
                    this._loggingService.sendLogMessage(LogLevels.ERROR, `Missing AppDisplayData for filterTerm [${this.searchState.filterTerm}]`);
                    this._notificationService.notifyError({ title: 'Search Results', message: `Missing AppDisplayData for filterTerm [${this.searchState.filterTerm}]` });
                }
            }
        });

        this.favoritesSubscription = this.favorites$.subscribe((favs: Array<Favorite>) => {
            this.userFavorites = favs;
        });
    }

    public ngOnDestroy() {
        this.searchSubscription.unsubscribe();
        this.favoritesSubscription.unsubscribe();
    }

    public isFavorite(entityType: string, entity: any): boolean {
        return this._favoritesService.isFavorite(entityType, entity);
    }

    public addFavorite(event: Event, entityType: string, entity: any): void {
        event.stopPropagation();
        const favoriteReq: FavoriteRequest = this._favoritesService.prepareFavoriteRequest(entityType, entity);
        this._favoritesService.addFavorite(favoriteReq);
    }

    public removeFavorite(event: Event, entityType: string, entity: any): void {
        event.stopPropagation();
        const favoriteNumber = this._favoritesService.getFavoriteNumber(entityType, entity);
        this._favoritesService.removeFavorite(favoriteNumber);
    }

    public selectFilter(entity: string): void {
        this._searchService.setEntityFilter(entity);
    }

    public navigateToRecord(r: any): void {
        if (this.searchState.filterTerm) {
            switch (this.searchState.filterTerm) {
                case this.searchEntityTypes.SHIPMENTS:
                    this.navigateToShipmentRecord(r);
                    break;

                case this.searchEntityTypes.INVOICES:
                    this.navigateToInvoiceRecord(r);
                    break;

                case this.searchEntityTypes.QUOTES:
                    this.navigateToQuoteRecord(r);
                    break;

                case this.searchEntityTypes.MASTER_DATA_CONTACTS:
                    this.navigateToMasterDataContact(r);
                    break;

                case this.searchEntityTypes.MASTER_DATA_PRODUCTS:
                    this.navigateToMasterDataProduct(r);
                    break;

                case this.searchEntityTypes.MASTER_DATA_MARKUPS:
                    this.navigateToMasterDataMarkup(r);
                    break;

                case this.searchEntityTypes.USERS:
                    this.navigateToUserRecord(r);
                    break;

                default:
                    this._loggingService.sendLogMessage(LogLevels.ERROR, `Unsupported search result EntityType [${this.searchState.filterTerm}]`);
                    break;
            }
        }
    }

    public getSearchTotal(matchedTypeSet: SearchResultTypeSet): number {
        const entityType = matchedTypeSet.entityType;
        const matchedSearchTotalSet = this.searchState.searchTotals.find((searchTotalSet: SearchTotal) => searchTotalSet.index === entityType);

        return matchedSearchTotalSet.total;
    }

    public navigateToInvoiceRecord(r: InvoiceSearchResult): void {
        if (!r || !r.id) {
            return;
        }

        this._invoiceService.getInvoice(r.id);
        this._signalsService.updateAppState({ 'invoice.navigationOrigin': Constants.INVOICE_NAVIGATION_SOURCE_search });
        this._router.navigate(['invoice', r.id]);
    }

    public navigateToQuoteRecord(r: QuoteSearchResult): void {
        if (!r || !r.id) {
            this._notificationService.notifyError({ title: 'Load Quote Error', message: 'Missing quote information' });
            return;
        }

        this._shipmentService.navigateViaQuote(r.id);
    }

    public navigateToShipmentRecord(r: ShipmentSearchResult): void {
        if (!r || !r.customer || !r.id) {
            this._notificationService.notifyError({ title: 'Navigate Shipment Error', message: 'Missing shipment information' });
            return;
        }

        this._shipmentService.navigateViaShipment(r.customer, r.id);
    }

    public navigateToUserRecord(r: UserSearchResult): void {
        if (!r || !r.userid) {
            this._notificationService.notifyError({ title: 'Navigate User Error', message: 'Missing User Profile information' });
            return;
        }

        this._router.navigate(['admin/users', r.userid]);
    }

    public navigateToMasterDataProduct(product: ProductSearchResult): void {
        if (!product || !product.productid) {
            this._notificationService.notifyError({ title: 'Navigate Product Error', message: 'Missing product information' });
            return;
        }

        this._router.navigate(['masterData/products', product.productid]);
    }

    public navigateToMasterDataContact(contact: ContactSearchResult): void {
        if (!contact || !contact.contactsid) {
            this._notificationService.notifyError({ title: 'Navigate Contact Error', message: 'Missing contact information' });
            return;
        }

        this._router.navigate(['masterData/contacts', contact.contactsid]);
    }

    public navigateToMasterDataRecord(r: any): void {
        if (!r || !r.type || !r.id) {
            this._notificationService.notifyError({ title: 'Navigate Masterdata Error', message: 'Missing masterdata information' });
            return;
        }

        this._router.navigate(['masterData', r.type, r.id]);
    }

    public navigateToMasterDataMarkup(r: MarkupSearchResult): void {
        if (!r || !r.id) {
            this._notificationService.notifyError({ title: 'Navigate Markup Error', message: 'Missing markup information' });
            return;
        }

        this._router.navigate(['masterData/markups', r.id]);
    }

    public isValidDate(dateString) {
        return dateString && (dateString !== 'T0');
    }
}
