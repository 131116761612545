import {Injectable} from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators, ValidatorFn
} from '@angular/forms';
import * as _ from 'lodash-es';
import {Observable} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import * as Constants from '../constants/constants';
import {DATE_FORMAT_yyyymmdd} from '../constants/datetime.constants';
import * as DateConstants from '../constants/datetime.constants';
import * as MasterDataConstants from '../constants/masterData.constants';
import {DateTimeHelper} from '../helpers/datetime.helper';
import {clearArray} from '../helpers/utilities';
import {Markup} from '../models/markup';
import {MarkupRangeType} from '../models/markup.rangeType';
import {MarkupException} from '../models/markupException';
import {MarkupExceptionAccessorial} from '../models/markupExceptionAccessorial';
import {MarkupExceptionCarrier} from '../models/markupExceptionCarrier';
import {MarkupRate} from '../models/markupRate';
import {RateOn} from '../models/markupRateOn';
import {MarkupType} from '../models/markupType';
import {HttpRequest} from "@angular/common/http";
import {ApiService, REQUEST_TYPE_POST} from "./api.service";
import {EntityService} from "./entity.service";
import {NotificationService} from "./notification.service";

@Injectable()
export class MarkupsService {
    public markups = [];
    private lastMarkup: Markup;
    private lastMarkupForm: UntypedFormGroup;

    constructor(
        private _entityService: EntityService,
        private _notificationService: NotificationService,
        private _fb: UntypedFormBuilder,
        private _dateHelper: DateTimeHelper,
        private _api: ApiService,
    ) {}

    public getMarkupList() {
        return this._entityService.getEntityList<Markup>('Markup').pipe(
            take(1),
            map(list => {
                if (Array.isArray(list)) {
                    clearArray(this.markups);
                    this.markups.push(...list);
                }
            })
        );
    }

    public validateMarkupName(markupValidReq: { markupType: string, markupName: string }): Observable<{ markupName: string, valid: boolean }> {
        const req = new HttpRequest(REQUEST_TYPE_POST, `MasterData/Markups/Validate`, markupValidReq);
        return this._api.callApiService<{ markupName: string, valid: boolean }>(req);
    }

    public getMarkup(id) {
        return this._entityService.getEntity<Markup>('Markup', id);
    }

    public createMarkup(markup) {
        return this._entityService.createEntity<Markup>(markup, 'Markup', 'markupID', 'markupName', 'admin/markups');
    }

    public updateMarkup(markup) {
        return this._entityService.updateEntity<Markup>(markup, 'Markup', markup.markupID, 'markupName');
    }

    public setLastMarkupForm(markupForm?: UntypedFormGroup) {
        this.lastMarkupForm = markupForm;
    }

    public getLastMarkupForm() {
        return this.lastMarkupForm;
    }

    public getLastMarkup() {
        return this.lastMarkup;
    }

    public getMarkupByPath(id) {
        if (this.lastMarkupForm) {
            return new Observable(subscriber => {
                subscriber.next(_.cloneDeep(this.lastMarkup));
                subscriber.complete();
            });
        } else {
            switch (id) {
                case 'new':
                    return new Observable(subscriber => {
                        this.lastMarkup = new Markup();
                        this.lastMarkup.rates = [];
                        this.lastMarkup.exceptions = [];
                        subscriber.next(_.cloneDeep(this.lastMarkup));
                        subscriber.complete();
                    })
                case 'clone':
                    return new Observable(subscriber => {
                        delete this.lastMarkup.markupID;

                        this.validateMarkupName({
                            markupType: this.lastMarkup.markupType,
                            markupName: this.lastMarkup.markupName
                        }).pipe(
                            take(1),
                            map((response: any) => {
                                if (response) {
                                    this.lastMarkup.markupName = response.markupName;
                                } else {
                                    this._notificationService.notifyError({title: 'Validate Markup Name', message: 'Unexpected response'});
                                }
                                subscriber.next(_.cloneDeep(this.lastMarkup));
                                subscriber.complete();
                            }),
                            catchError((err) => {
                                this._notificationService.notifyError({title: 'Validate Markup Name', message: 'Unexpected Response'});
                                throw err;
                            })
                        ).subscribe();
                    });
                default:
                    return this.getMarkup(id).pipe(
                        take(1),
                        map(markup => {
                            if (Object.keys(markup).length > 0) {
                                this.lastMarkup = markup as Markup;
                            }
                            return _.cloneDeep(this.lastMarkup);
                        }),
                        catchError(err => {
                            throw err;
                        })
                    )
            }
        }
    }

    public getMarkupFormFromLastMarkup() {
        let markupForm: UntypedFormGroup;
        if (this.lastMarkupForm) {
            markupForm = this.lastMarkupForm;
            this.setLastMarkupForm();
        }
        markupForm = this._fb.group({
            markupID: this.lastMarkup.markupID,
            markupName: new UntypedFormControl(this.lastMarkup.markupName, {
                validators: [
                    Validators.required
                ]
            }),
            markupActive: this.lastMarkup.markupActive,
            markupDescription: new UntypedFormControl(this.lastMarkup.markupDescription, {
                validators: [
                    Validators.required
                ]
            }),
            markupType: new UntypedFormControl(this.lastMarkup.markupType, {
                validators: [
                    Validators.required
                ]
            }),
            rates: this.itemsToFormArray(this.lastMarkup.rates, false),
            exceptions: this.itemsToFormArray(this.lastMarkup.exceptions, true),
        });
        return markupForm;
    }

    public updateLastMarkup(markupResult: Markup) {
        this.lastMarkup = markupResult;
        this.lastMarkup.rates = (this.lastMarkup.rates) ? this.lastMarkup.rates.sort(this.compareMarkupRatesByEffectiveDate) : this.lastMarkup.rates;
    }

    public getMarkupFromForm(markupForm) {
        const markup: Markup = _.cloneDeep(this.lastMarkup);
        let a;
        a = (markupForm.controls.markupID.value) ? markup.markupID = markupForm.controls.markupID.value : null;
        a = (markupForm.controls.markupName.value !== undefined) ? markup.markupName = markupForm.controls.markupName.value : null;
        a = (markupForm.controls.markupActive.value !== undefined) ? markup.markupActive = markupForm.controls.markupActive.value : null;
        a = (markupForm.controls.markupDescription !== undefined) ? markup.markupDescription = markupForm.controls.markupDescription.value : null;
        a = (markupForm.controls.markupType.value !== undefined) ? markup.markupType = markupForm.controls.markupType.value : null;
        markup.rates = this.itemArrayFromControlArrayValues(markupForm.controls.rates.value, false) as Array<MarkupRate>;
        markup.exceptions = this.itemArrayFromControlArrayValues(markupForm.controls.exceptions.value, true) as Array<MarkupException>;
        return markup;
    }

    public formEqualsLastMarkup(markupForm: UntypedFormGroup) {
        return (this.lastMarkup.markupID === markupForm.controls.markupID.value) &&
            (this.lastMarkup.markupName === markupForm.controls.markupName.value) &&
            // eslint-disable-next-line eqeqeq
            (this.lastMarkup.markupActive == markupForm.controls.markupActive.value) &&
            (this.lastMarkup.markupDescription === markupForm.controls.markupDescription.value) &&
            (this.lastMarkup.markupType === markupForm.controls.markupType.value) &&
            this.itemEqualsForm(this.lastMarkup.rates, markupForm.controls.rates.value, false) &&
            this.itemEqualsForm(this.lastMarkup.exceptions, markupForm.controls.exceptions.value, true);
    }

    public addNewExceptionToForm(markupForm, markupType: MarkupType, rateOn: RateOn, rangeType: MarkupRangeType) {
        const exception = new MarkupException();
        exception.breakType = rangeType.rangeTypeID;
        exception.low = MasterDataConstants.MARKUP_EXCEPTION_DEFAULTS.minMarkup;
        exception.high = MasterDataConstants.MARKUP_EXCEPTION_DEFAULTS.maxMarkup;
        exception.rateType = markupType.rateTypes[0].rateTypeID;
        exception.rateOn = rateOn.rateOnID;
        exception.minMarkup = MasterDataConstants.MARKUP_EXCEPTION_DEFAULTS.rangeMin;
        exception.maxMarkup = MasterDataConstants.MARKUP_EXCEPTION_DEFAULTS.rangeMax;
        exception.minMarkupon = rateOn.rateOnID;
        exception.effective = this._dateHelper.getPreparedCurrentDate(DateConstants.DATE_FORMAT_yyyymmdd);
        exception.end = DateConstants.DATE_VALUE_INDEFINITELY;
        exception.exceptionDescription = '';
        exception.exceptionCarriers = [];
        exception.exceptionAccessorials = [];
        markupForm.get('exceptions').push(this.itemToFormGroup(exception, true));
    }

    public addNewRateToForm(markupForm, markupType: MarkupType, rateOn: RateOn, rangeType: MarkupRangeType) {
        const rate = new MarkupRate();
        rate.rateType = markupType.rateTypes[0].rateTypeID;
        rate.rateOn = rateOn.rateOnID;
        rate.minMarkup = MasterDataConstants.MARKUP_EXCEPTION_DEFAULTS.rangeMin;
        rate.maxMarkup = MasterDataConstants.MARKUP_EXCEPTION_DEFAULTS.rangeMax;
        rate.minMarkupon = rateOn.rateOnID;
        rate.effective = this._dateHelper.getPreparedCurrentDate(DateConstants.DATE_FORMAT_yyyymmdd);
        rate.end = DateConstants.DATE_VALUE_INDEFINITELY;

        markupForm.get('rates').push(this.itemToFormGroup(rate, false));
    }

    public deleteExceptionFromForm(markupForm: UntypedFormGroup, idx) {
        const exceptionsArray = markupForm.get('exceptions') as UntypedFormArray;
        exceptionsArray.removeAt(idx);
    }

    public deleteRateFromForm(markupForm: UntypedFormGroup, idx) {
        const ratesArray = markupForm.get('rates') as UntypedFormArray;
        ratesArray.removeAt(idx);
    }

    public updateForm(markupForm: UntypedFormGroup) {
        let a;
        a = (this.lastMarkup.markupID) ? markupForm.controls.markupID.setValue(this.lastMarkup.markupID) : null;
        a = (this.lastMarkup.markupName !== undefined) ? markupForm.controls.markupName.setValue(this.lastMarkup.markupName) : null;
        a = (this.lastMarkup.markupActive !== undefined) ? markupForm.controls.markupActive.setValue(this.lastMarkup.markupActive) : null;
        a = (this.lastMarkup.markupDescription !== undefined) ? markupForm.controls.markupDescription.setValue(this.lastMarkup.markupDescription) : null;
        a = (this.lastMarkup.markupType !== undefined) ? markupForm.controls.markupType.setValue(this.lastMarkup.markupType) : null;
        this.updateFormArray(markupForm, false);
        this.updateFormArray(markupForm, true);
    }

    public carrierToFormGroup(exceptionCarrier: MarkupExceptionCarrier): UntypedFormGroup {
        const fg: UntypedFormGroup = this._fb.group({
            SCAC: exceptionCarrier.SCAC,
            effective: new UntypedFormControl(this.getFormattedMarkupDate(exceptionCarrier.effective), {
                updateOn: 'blur',
                validators: [
                    this._dateHelper.dateValueValidator()
                ]
            }),
            end: new UntypedFormControl(this.getFormattedMarkupDate(exceptionCarrier.end), {
                updateOn: 'blur',
                validators: [
                    this._dateHelper.dateValueValidator()
                ]
            }),
        });
        if (exceptionCarrier.markupCarrierID) {
            fg.addControl('markupCarrierID', new UntypedFormControl(exceptionCarrier.markupCarrierID));
        }
        if (exceptionCarrier.changeBy) {
            fg.addControl('changeBy', new UntypedFormControl(exceptionCarrier.changeBy));
        }
        if (exceptionCarrier.changeDate) {
            fg.addControl('changeDate', new UntypedFormControl(exceptionCarrier.changeDate));
        }
        return fg;
    }

    public accessorialToFormGroup(exceptionAccessorial: MarkupExceptionAccessorial): UntypedFormGroup {
        const fg: UntypedFormGroup = this._fb.group({
            code: exceptionAccessorial.code,
            effective: new UntypedFormControl(this.getFormattedMarkupDate(exceptionAccessorial.effective), {
                updateOn: 'blur',
                validators: [
                    this._dateHelper.dateValueValidator()
                ]
            }),
            end: new UntypedFormControl(this.getFormattedMarkupDate(exceptionAccessorial.end), {
                updateOn: 'blur',
                validators: [
                    this._dateHelper.dateValueValidator()
                ]
            }),
        });
        if (exceptionAccessorial.markupAccessorialID) {
            fg.addControl('markupAccessorialID', new UntypedFormControl(exceptionAccessorial.markupAccessorialID));
        }
        if (exceptionAccessorial.changeBy) {
            fg.addControl('changeBy', new UntypedFormControl(exceptionAccessorial.changeBy));
        }
        if (exceptionAccessorial.changeDate) {
            fg.addControl('changeDate', new UntypedFormControl(exceptionAccessorial.changeDate));
        }
        return fg;
    }

    public carriersEqual(formCarriers: Array<MarkupExceptionCarrier>, carriers: Array<MarkupExceptionCarrier>) {
        let formCarrier: MarkupExceptionCarrier;

        if (!formCarriers  && !carriers) {
            return true;
        }

        if ((!formCarriers && carriers) || (formCarriers && !carriers) || (formCarriers.length !== carriers.length)) {
            return false;
        }

        for (formCarrier of formCarriers) {
            if (!_.find(carriers, (carrier: MarkupExceptionCarrier) =>
                (formCarrier.markupCarrierID === carrier.markupCarrierID) &&
                    (this._dateHelper.prepareDate(formCarrier.effective, DATE_FORMAT_yyyymmdd) === carrier.effective) &&
                    (this._dateHelper.prepareDate(formCarrier.end, DATE_FORMAT_yyyymmdd) === carrier.end))) {
                return false;
            }
        }

        return true;
    }

    public acclsEqual(formCarriers: Array<MarkupExceptionAccessorial>, carriers: Array<MarkupExceptionAccessorial>) {
        let formCarrier: MarkupExceptionAccessorial;

        if (!formCarriers.length  && !carriers) {
            return true;
        }

        if ((!formCarriers && carriers) || (formCarriers && !carriers) || (formCarriers.length !== carriers.length)) {
            return false;
        }

        for (formCarrier of formCarriers) {
            if (!_.find(carriers, (carrier: MarkupExceptionAccessorial) =>
                (formCarrier.markupAccessorialID === carrier.markupAccessorialID) &&
                (this._dateHelper.prepareDate(formCarrier.effective, DATE_FORMAT_yyyymmdd) === carrier.effective) &&
                (this._dateHelper.prepareDate(formCarrier.end, DATE_FORMAT_yyyymmdd) === carrier.end))) {
                return false;
            }
        }

        return true;
    }

    private compareMarkupRatesByEffectiveDate(a: MarkupRate, b: MarkupRate) {
        const aDate = new Date(a.effective);
        const bDate = new Date(b.effective);
        if (aDate > bDate) {
            return 1;
        } else if (aDate < bDate) {
            return -1;
        }
        return 0;
    }

    private getFormattedMarkupDate(markupDate: string): string {
        if (!markupDate) {
            return '';
        } else if (markupDate === DateConstants.DATE_VALUE_INDEFINITELY) {
            return DateConstants.DATE_VALUE_INDEFINITELY_display;
        }

        return this._dateHelper.getFormattedDisplayDate(markupDate, DateConstants.DATE_FORMAT_yyyymmdd);
    }

    private itemToFormGroup(item: MarkupException|MarkupRate, isException: boolean) {
        const formGroup = this._fb.group({
            rateType: item.rateType,
            rate: item.rate,
            rateOn: item.rateOn,
            minMarkup: new UntypedFormControl(item.minMarkup, {
                updateOn: 'blur',
                validators: [
                    Validators.pattern(Constants.VALID_DECIMAL_REGEX),
                    Validators.min(MasterDataConstants.MARKUP_RANGE_MIN)
                ]
            }),
            maxMarkup: new UntypedFormControl(item.maxMarkup, {
                updateOn: 'blur',
                validators: [
                    Validators.pattern(Constants.VALID_DECIMAL_REGEX),
                    Validators.min(MasterDataConstants.MARKUP_RANGE_MIN)
                ]
            }),
            minMarkupon: item.minMarkupon,
            effective: new UntypedFormControl(this.getFormattedMarkupDate(item.effective), {
                updateOn: 'blur',
                validators: [
                    this._dateHelper.dateValueValidator()
                ]
            }),
            end: new UntypedFormControl(this.getFormattedMarkupDate(item.end), {
                updateOn: 'blur',
                validators: [
                    this._dateHelper.dateValueValidator()
                ]
            }),
            maxMarkupType: (item.maxMarkupType) ? item.maxMarkupType : MasterDataConstants.MARKUP_RATE_DEFAULTS.maxMarkupType,
        });
        if (isException) {
            formGroup.addControl('minCharge', new UntypedFormControl((item as MarkupException).minCharge, {
                updateOn: 'blur',
                validators: [Validators.pattern(Constants.VALID_DECIMAL_REGEX), Validators.min(0)]
            }));
            formGroup.addControl('high', new UntypedFormControl((item as MarkupException).high, {
                updateOn: 'blur',
                validators: [Validators.pattern(Constants.VALID_DECIMAL_REGEX), Validators.min(MasterDataConstants.MARKUP_RANGE_MIN)]
            }));
            formGroup.addControl('low', new UntypedFormControl((item as MarkupException).low, {
                updateOn: 'blur',
                validators: [Validators.pattern(Constants.VALID_DECIMAL_REGEX), Validators.min(MasterDataConstants.MARKUP_RANGE_MIN)]
            }));
            formGroup.addControl('breakType', new UntypedFormControl((item as MarkupException).breakType));
            formGroup.addControl('exceptionCarriers', this._fb.array(
                (item as MarkupException).exceptionCarriers.map((exceptionCarrier: MarkupExceptionCarrier) =>
                    this.carrierToFormGroup(exceptionCarrier))));
            if ((item as MarkupException).exceptionAccessorials) {
                formGroup.addControl('exceptionAccessorials', this._fb.array(
                    (item as MarkupException).exceptionAccessorials.map((exceptionAccessorial: MarkupExceptionAccessorial) =>
                        this.accessorialToFormGroup(exceptionAccessorial))));
            } else {
                formGroup.addControl('exceptionAccessorials', this._fb.array([]));
            }
            formGroup.addControl('exceptionDescription', new UntypedFormControl((item as MarkupException).exceptionDescription));
            if ((item as MarkupException).markupExceptionID) {
                formGroup.addControl('markupExceptionID', new UntypedFormControl((item as MarkupException).markupExceptionID));
            }
            if ((item as MarkupException).changeBy) {
                formGroup.addControl('changeBy', new UntypedFormControl((item as MarkupException).changeBy));
            }
            if ((item as MarkupException).changeDate) {
                formGroup.addControl('changeDate', new UntypedFormControl((item as MarkupException).changeDate));
            }
            formGroup.setValidators([this.lowHighValidator('minMarkup', 'maxMarkup', 'markupRangeInvalid'),
                this.lowHighValidator('low', 'high', 'exceptionRangeInvalid')]);
        } else {
            if ((item as MarkupRate).markupRateID) {
                formGroup.addControl('markupRateID', new UntypedFormControl((item as MarkupRate).markupRateID));
            }
            formGroup.setValidators([this.lowHighValidator('minMarkup', 'maxMarkup', 'markupRangeInvalid')]);
        }
        return formGroup;
    }

    private lowHighValidator(lowControlName: string, highControlName: string, errorName): ValidatorFn {
        return (fg: UntypedFormGroup) => {
            const errorObj = {};
            const lowControl: UntypedFormControl = fg.get(lowControlName) as UntypedFormControl;
            const highControl: UntypedFormControl = fg.get(highControlName) as UntypedFormControl;

            errorObj[errorName] = true;
            return (+highControl.value <= +lowControl.value) ? errorObj : null;
        };
    }

    private itemsToFormArray(items: Array<MarkupException|MarkupRate>, isException: boolean) {
        let item: MarkupException|MarkupRate;
        const itemsFormArray = this._fb.array([]);

        for (item of items) {
            itemsFormArray.push(this.itemToFormGroup(item, isException));
        }

        if (!isException) {
            itemsFormArray.setValidators(this.rateGroupValidator(itemsFormArray));
        }

        return itemsFormArray;
    }

    private itemArrayFromControlArrayValues(formArrayValues: Array<MarkupException|MarkupRate>, isException: boolean) {
        let formItem: MarkupException|MarkupRate;
        const itemArray: Array<MarkupException|MarkupRate> = new Array<MarkupException|MarkupRate>();
        let item: MarkupException|MarkupRate;
        let newItem: MarkupException|MarkupRate;

        for (formItem of formArrayValues) {
            if ((isException) ? (formItem as MarkupException).markupExceptionID : (formItem as MarkupRate).markupRateID) {
                if (isException) {
                    item = this.lastMarkup.exceptions.find((lastMarkupException) => (lastMarkupException.markupExceptionID === (formItem as MarkupException).markupExceptionID));
                    newItem = {...item, ...formItem} as MarkupException;
                    this.convertFormDates((newItem as MarkupException).exceptionCarriers);
                    if ((newItem as MarkupException).exceptionAccessorials) {
                        this.convertFormDates((newItem as MarkupException).exceptionAccessorials);
                    }
                    newItem.rate = (newItem.rate) ? newItem.rate : 0;
                } else {
                    item = this.lastMarkup.rates.find((lastMarkupRate) => (lastMarkupRate.markupRateID === (formItem as MarkupRate).markupRateID));
                    newItem = {...item, ...formItem} as MarkupRate;
                }
            } else {
                newItem = _.cloneDeep(formItem);
                if (isException) {
                    this.convertFormDates((newItem as MarkupException).exceptionCarriers);
                    if ((newItem as MarkupException).exceptionAccessorials) {
                        this.convertFormDates((newItem as MarkupException).exceptionAccessorials);
                    }
                    newItem.rate = (newItem.rate) ? newItem.rate : 0;
                }
            }
            itemArray.push(newItem);
        }
        this.convertFormDates(itemArray);
        return itemArray;
    }

    private itemEqualsForm(items: Array<MarkupException|MarkupRate>, formItems: Array<MarkupException|MarkupRate>, isException: boolean) {
        let item: MarkupException|MarkupRate;
        let idx = 0;

        if (items.length !== formItems.length) {
            return false;
        }

        for (item of formItems) {
            if (((isException) ? ((item as MarkupException).markupExceptionID !== (items[idx] as MarkupException).markupExceptionID) :
                ((item as MarkupRate).markupRateID !== (items[idx] as MarkupRate).markupRateID)) ||
                (item.rateType !== items[idx].rateType) ||
                (+(item.rate) !== items[idx].rate) ||
                (item.rateOn !== items[idx].rateOn) ||
                (+item.minMarkup !== items[idx].minMarkup) ||
                (+item.maxMarkup !== items[idx].maxMarkup) ||
                (item.minMarkupon !== items[idx].minMarkupon) ||
                (item.effective !== this.getFormattedMarkupDate(items[idx].effective)) ||
                (item.end !== this.getFormattedMarkupDate(items[idx].end)) ||
                ((isException) ? +((item as MarkupException).minCharge) !== (items[idx] as MarkupException).minCharge : false) ||
                ((isException) ? +((item as MarkupException).low) !== (items[idx] as MarkupException).low : false) ||
                ((isException) ? +((item as MarkupException).high) !== (items[idx] as MarkupException).high : false) ||
                ((isException) ? (item as MarkupException).breakType !== (items[idx] as MarkupException).breakType : false) ||
                ((isException) ? (item as MarkupException).exceptionDescription !== (items[idx] as MarkupException).exceptionDescription : false) ||
                ((isException) ? !this.carriersEqual((item as MarkupException).exceptionCarriers, (items[idx] as MarkupException).exceptionCarriers) : false) ||
                ((isException) ? !this.acclsEqual((item as MarkupException).exceptionAccessorials, (items[idx] as MarkupException).exceptionAccessorials) : false)
            ) {
                return false;
            }
            idx++;
        }

        return true;
    }

    private clearFormArray(formArray: UntypedFormArray) {
        while (formArray.length !== 0) {
            formArray.removeAt(0)
        }
    }

    private updateFormArray(markupForm: UntypedFormGroup, isException: boolean) {
        let item: MarkupException|MarkupRate;
        const formArray: UntypedFormArray = ((isException) ? markupForm.controls.exceptions : markupForm.controls.rates) as UntypedFormArray;

        this.clearFormArray(formArray);

        for (item of ((isException) ? this.lastMarkup.exceptions : this.lastMarkup.rates)) {
            formArray.push(this.itemToFormGroup(item, isException));
        }
    }

    private rateGroupValidator(rateArray: UntypedFormArray): ValidatorFn {
        return (): ValidationErrors => {
            let rateA: MarkupRate;
            let rateAGroup: UntypedFormGroup;
            let rateB: MarkupRate;
            let rateBGroup: UntypedFormGroup;
            let i = 0;
            let j = 0;

            for (i = 0; i < rateArray.length; i++) {
                rateAGroup = rateArray.at(i) as UntypedFormGroup;
                this.clearOverlapError(rateAGroup.controls.effective);
                this.clearOverlapError(rateAGroup.controls.end);
            }

            for (i =  0; i < rateArray.length; i++) {
                rateAGroup = rateArray.at(i) as UntypedFormGroup;
                rateA = rateAGroup.value as MarkupRate;
                for (j = i + 1; j < rateArray.length; j++) {
                    rateBGroup = rateArray.at(j) as UntypedFormGroup;
                    rateB = rateBGroup.value as MarkupRate;
                    if (this.rateDatesOverlap(rateA, rateB)) {
                        this.setOverlapError(rateAGroup.controls.effective);
                        this.setOverlapError(rateBGroup.controls.effective);
                        this.setOverlapError(rateAGroup.controls.end);
                        this.setOverlapError(rateBGroup.controls.end);
                    }
                }
            }
            return;
        }
    }

    private rateDatesOverlap(rateA: MarkupRate, rateB: MarkupRate) {
        const rateAEffectiveDate = this._dateHelper.prepareDate(rateA.effective, DATE_FORMAT_yyyymmdd);
        const rateBEffectiveDate = this._dateHelper.prepareDate(rateB.effective, DATE_FORMAT_yyyymmdd);
        const rateAEndDate = this._dateHelper.prepareDate(rateA.end, DATE_FORMAT_yyyymmdd);
        const rateBEndDate = this._dateHelper.prepareDate(rateB.end, DATE_FORMAT_yyyymmdd);
        return (rateAEffectiveDate <= rateBEndDate) && (rateAEndDate >= rateBEffectiveDate);
    }

    private clearOverlapError(control: AbstractControl) {
        let errors = control.errors;
        if (errors) {
            delete errors.Overlap;
            if (Object.getOwnPropertyNames(errors).length === 0) {
                errors = null;
            }

            control.setErrors(errors);
        }
    }

    private setOverlapError(control: AbstractControl) {
        control.setErrors({Overlap: true});
    }

    private convertFormDates(exceptionCarriers: Array<MarkupExceptionCarrier|MarkupRate|MarkupException|MarkupExceptionAccessorial>) {
        let carrier;
        for (carrier of exceptionCarriers) {
            carrier.effective = this._dateHelper.prepareDate(carrier.effective, DATE_FORMAT_yyyymmdd);
            carrier.end = this._dateHelper.prepareDate(carrier.end, DATE_FORMAT_yyyymmdd);
        }
    }
}
