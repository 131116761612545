<div class="overlay">
  <div class="saveQuoteModal">

    <!-- ================== SAVE QUOTE MODAL HEADER ================== -->
    <div class="saveQuoteModalHeader">
      <span class="closeModalButtonHolder clickable" (click)="cancel()">
        <i class="fa fa-times"></i>
      </span>
    </div>

    <!-- ================== SAVE QUOTE MODAL BODY ================== -->
    <div class="saveQuoteModalBody">
      <div class="warningIconHolder">
        <i class="fa fa-exclamation-triangle"></i>
      </div>

      <div class="saveQuoteModalBodyText">
        If you leave this quote without saving, you will lose the work you have done so far.
      </div>
      <div class="saveQuoteModalBodyText">
        Are you sure you want to continue without saving your quote?
      </div>
    </div>

    <!-- ================== SAVE QUOTE MODAL FOOTER ================== -->
    <div class="saveQuoteModalFooter">
      <div class="clickable cancelButton btn-outline saveQuoteModalButton" (click)="cancel()">
        Continue Without Saving
      </div>
      <div class="clickable saveQuoteButton btn-filled saveQuoteModalButton" (click)="ok()">
        Save Quote
      </div>
    </div>

  </div>
</div>