<div class="gridContainer">

    <div class="contentContainer">
        <div *ngIf="showSearchAll" class="searchAll">
            <form class="searchAll" (ngSubmit)="filterAll()">
                <span class="hideSearchAllIcon">
                    <i class="clickable fa fa-ban" aria-hidden="true" (click)="hideSearchAll()"></i>
                </span>
                <input type="text" name="filterAllQuery" class="searchQuery" placeholder="Search"
                       [(ngModel)]="filterAllQuery"/>
                <span class="searchAllSpan">
                    <i class="clickable fa fa-search" aria-hidden="true"></i>
                </span>
            </form>
        </div>

        <div class="recordsContainer">
            <div class="headerRow" *ngIf="filteredDashboardViewContent?.length">
                <div class="filterAll">
                    <button *ngIf="!showSearchAll" class="searchIcon clickable" (click)="expandViewContentSearch()">
                        <i class="clickable fa fa-search" aria-hidden="true"></i>
                    </button>
                </div>

                <div class="favoriteHeader">
                    <div class="favoriteAll clickable">
                        <i (click)="selectAllFavorites()" *ngIf="!checkIfAllFavorite()" class="blue fa fa-star-o"></i>
                        <i (click)="unselectAllFavorites()" *ngIf="checkIfAllFavorite()" class="yellow fa fa-star"></i>
                    </div>
                </div>

                <div class="columnHeaders" [dragula]='"bag-view-columns"' [(dragulaModel)]="columnHeaders">
                    <div class="columnHeaderItems" *ngFor="let column of columnHeaders; let i = index"
                         [attr.modelName]="column.modelName">
                        <div class="filterIcon clickable" (click)="showColumnSearch(column)">
                            <i class="fa fa-ellipsis-v bagViewColumnHandle" aria-hidden="true"></i>
                        </div>

                        <div class="headerName">
                            <span class="sortContainer clickable" (click)="sortOn(column?.modelName)">
                                {{ column?.modelName | DisplayData:'searchEntityFilters':filterTerm }}
                                <span *ngIf="isSortedAscending(column?.modelName)">
                                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                                </span>
                                <span *ngIf="isSortedDescending(column?.modelName)">
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </span>
                            </span>
                        </div>

                    </div>
                </div>
            </div>

            <div class="filterByColumnRow" *ngIf="expandFilterByColumnRow">
                <div class="columnFilterCancelButton" (click)="cancelColumnFilter()">
                    <i class="cancelSearchButton clickable fa fa-ban" aria-hidden="true"></i>
                </div>
                <div class="favoriteHeader">
                    <div class="favoriteAll clickable"></div>
                </div>
                <div class="columnHeaderFilters" *ngFor="let column of columnHeaders; let i = index">
                    <form (ngSubmit)="filterByColumn()" *ngIf="column.showSearch" class="columnHeaderFilterPanel">
                        <input class="colSearchInput" name="columnSearchTerm" [(ngModel)]="column.searchTerm"
                               placeholder="Search">
                        <span class="columnFilterIcon">
                            <i class="fa fa-search blue" aria-hidden="true"></i>
                        </span>
                    </form>
                </div>
            </div>
            <div class="resultsContainer">
                <div class="resultRow bottomBorderShadow"
                     *ngFor="let record of filteredDashboardViewContent; let i = index">
                    <div class="filterAll"></div>

                    <div class="favoriteHeader clickable" [id]="'favorite' + i">
                        <i (click)="addSelectedFavorite($event, record)" *ngIf="!isFavorite(record)"
                           class="grey fa fa-star-o"></i>
                        <i (click)="removeSelectedFavorite($event, record)" *ngIf="isFavorite(record)"
                           class="yellow fa fa-star"></i>
                    </div>

                    <div class="recordDetail clickable" *ngFor="let detail of columnHeaders"
                         (click)="navigateToRecord(record, detail.modelName)">
                        @if (detail.modelName !== 'shipmentNotes') {
                            {{ record[detail.modelName] | apiParseError:detail.modelName | checkDate | amount:detail.modelName }}
                        } @else {
                            <span class="linkText blue clickable">Notes</span>
                        }
                    </div>
                </div>

                <div class="noQueryContainer" *ngIf="activeDashboardView?.viewType !== dashboardViewTypes.help_center">
                    <div class="emptyUI" *ngIf="filteredDashboardViewContent && !filteredDashboardViewContent?.length">
                        <div class="emptyUIMessage">No content found for this view.</div>
                        <div class="emptyUIIconHolder">
                            <i class="emptyUIIcon fa fa-inbox" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>

                <!-- ============================== HELP CENTER VIEW ============================== -->
                <div class="noQueryContainer" *ngIf="activeDashboardView?.viewType === dashboardViewTypes.help_center"
                     id="helpCenterDashboardView">
                    <div class="helpCenterViewContainer">
                        <div class="helpCenterPromptContainer">
                            <div class="helpCenterWelcomeMsg">
                                Welcome to ShipRite!
                            </div>

                            <div class="helpCenterWelcomeDetails">
                                Let's get your packages where they need to be.
                            </div>
                            <div class="helpCenterWelcomeDetails">
                                Select one of the tutorials below.
                            </div>

                            <div class="helpCenterButtonPanel">
                                <app-button (click)="startShipmentsTour()">
                                    Create Quote & Shipment
                                </app-button>
                                <div class="btn-filled" id="helpCenterOptions-dashboard"
                                     (click)="startDashboardTour()">
                                    Manage Dashboard
                                </div>
                                <div class="btn-filled" (click)="startClipboardTour()">
                                    Shortcuts &amp; Clipboard
                                </div>
                                <app-button (click)="startQuickQuoteTour()">
                                    Quick Quote
                                </app-button>
                            </div>

                            <div class="removeHelpCenterPanel">
                                <span>
                                    Already got the hang of it?
                                </span>
                                <span class="blue clickable removeHelpCenterActionText"
                                      (click)="removeHelpCenterView()">
                                    Remove Help Center
                                </span>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<app-shipment-notes *ngIf="isShipmentNotesVisible" [userName]="userName"
                    [shipmentNotes]="shipmentNotes" [showIconWhenCollapsed]="false" ></app-shipment-notes>
