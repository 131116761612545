<div class="overlay">
  <div class="placeShipmentModal">

    <!--============ PLACE SHIPMENT MODAL HEADER ============-->
    <div class="modalHeader">
      <div class="modalHeaderText">
        Complete Your Shipment
      </div>

      <span class="modalHeaderIcon clickable" (click)="cancel()">
        <i class="fa fa-times"></i>
      </span>
    </div>

    <!--============ PLACE SHIPMENT MODAL BODY ============-->
    <div class="modalBody">
      <div class="modalBodyContent">
        <div class="placeShipmentFormNote">
          <span class="placeShipmentFormNoteTop">
            NOTE
          </span>
          <span class="placeShipmentFormNoteBottom">
            Personal information will be used by CTS to inform you of updates regarding your shipment
          </span>
        </div>

        <!--============ CHASE CREDIT CARD MAIN ============-->
        <div *ngIf="isCreditCardPaymentPermissible && isCreditCardUIVisible" class="creditCardFormMain">
          <iframe [src]="creditCardIFrameUrl | urlSanitizer" class="iframeMain"></iframe>
          <!-- <iframe #ccIframe></iframe> -->
        </div>

        <!--============ PLACE SHIPMENT FORM MAIN ============-->
        <form *ngIf="isPlaceShipmentUIVisible" class="placeShipmentFormMain" [formGroup]="placeShipmentForm" (ngSubmit)="saveChanges()">
          <button type="submit" name="formSubmission" class="btn-hidden-IE"></button>
          <div class="placeShipmentFormRow">
            <div class="placeShipmentFormRowLeftColumn">
              <div class="placeShipmentFormRowLabel">
                <span class="required">*</span>Name
              </div>
              <input type="text" class="placeShipmentFormRowValue" formControlName="bolName" [ngClass]="{ 'placeShipmentFormInputInvalid': (placeShipmentForm['controls'].bolName.dirty || placeShipmentForm['controls'].bolName.touched) && placeShipmentForm['controls'].bolName.invalid }">
            </div>

            <div class="placeShipmentFormRowRightColumn">
              <div class="placeShipmentFormRowLabel">
                <span class="required">*</span>Phone
              </div>
              <input type="text" class="placeShipmentFormRowValue" formControlName="bolPhone" [ngClass]="{ 'placeShipmentFormInputInvalid': (placeShipmentForm['controls'].bolPhone.dirty || placeShipmentForm['controls'].bolPhone.touched)
              && placeShipmentForm['controls'].bolPhone.invalid }">
            </div>
          </div>

          <div class="placeShipmentFormRow">
            <div class="placeShipmentFormRowLeftColumn">
              <div class="placeShipmentFormRowLabel">
                <span class="required">*</span>Email
              </div>
              <input type="text" class="placeShipmentFormRowValue" formControlName="bolEmail" [ngClass]="{ 'placeShipmentFormInputInvalid': (placeShipmentForm['controls'].bolEmail.dirty || placeShipmentForm['controls'].bolEmail.touched)
              && placeShipmentForm['controls'].bolEmail.invalid }">
            </div>

            <div class="placeShipmentFormRowRightColumn"></div>
          </div>
        </form>

      </div>
    </div>

    <!--============ PLACE SHIPMENT MODAL FOOTER ============-->
    <div class="modalFooter">
      <button class="btn-outline clickable" (click)="cancel()">
        Cancel
      </button>
      <button *ngIf="!isCreditCardPaymentPermissible" class="btn-outline clickable" (click)="saveChanges()" [ngClass]="{ 'btn-filled': placeShipmentForm.valid, 'placeShipmentFormSubmitValid': placeShipmentForm.valid }">
        Complete
      </button>
      <button *ngIf="isCreditCardPaymentPermissible" class="btn-outline clickable" (click)="continue()" [ngClass]="{ 'btn-filled': placeShipmentForm.valid, 'placeShipmentFormSubmitValid': placeShipmentForm.valid }">
        Continue
      </button>
    </div>
  </div>
</div>