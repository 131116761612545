import {Injectable, OnDestroy} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

import {AuthService} from '../services/auth.service';
import {LoggingService, LogLevels} from '../services/logging.service';
import {SignalsService} from "../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";

@Injectable()
export class AuthGuard  implements OnDestroy {

    private isUserSessionActive: boolean;
    private appStateSubscription: Subscription;

    constructor(
        private _authService: AuthService,
        private _loggingService: LoggingService,
        private _router: Router,
        private _signalsService: SignalsService
    ) {
        this._loggingService.sendLogMessage(LogLevels.DEBUG, 'AuthGuard#constructor called');
        this.appStateSubscription = toObservable(this._signalsService.appStateSignal).subscribe((appState) => {
            this.isUserSessionActive = appState['global.isUserSessionActive'];
        });
    }

    public ngOnDestroy() {
        this._loggingService.sendLogMessage(LogLevels.DEBUG, 'AuthGuard#ngOnDestroy called');
        this.appStateSubscription.unsubscribe();
    }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        this._loggingService.sendLogMessage(LogLevels.DEBUG, 'AuthGuard#canActivate called');

        this._loggingService.sendLogMessage(LogLevels.DEBUG, `${JSON.stringify(this._signalsService.userSignal())}`);
        if (this._signalsService.userSignal()) {
            this._loggingService.sendLogMessage(LogLevels.DEBUG, `already logged in, return TRUE`);
            return Promise.resolve(true);
        }

        if (!this.isUserSessionActive) {
            await this._authService.refreshLoginAsync();
            return of(this._signalsService.userSignal()).pipe(
                map((up) => {
                    if (up) {
                        this._loggingService.sendLogMessage(LogLevels.INFO, `successfully refreshed login, return TRUE`);
                        return true;
                    } else {
                        this._loggingService.sendLogMessage(LogLevels.INFO, `redirecting to '/' and returning FALSE`);
                        this._router.navigate(['']);
                        return false;
                    }
                })
            ).toPromise();
        }
    }
}
