import * as Constants from "../constants/constants";
import * as DateConstants from "../constants/datetime.constants";
import {LogLevels} from "../services/logging.service";

export class AppState {
    /* Admin */
    'admin.activeAdminSection': string;
    'admin.showLevel1Header': boolean;
    'admin.showLevel2Header': boolean;
    'admin.user.isSaveUserProfileReady': boolean;
    'admin.user.isSaveUserDefaultsReady': boolean;
    'admin.roles.isAdminRoleSaveReady': boolean;
    'admin.customer.areCustomerTabsVisible': boolean;
    'admin.navigateToMarkup': boolean;

    /* Master Data */
    'masterdata.activeSection': string;

    /* dashboard */
    'dashboard.editDashboardView': boolean;
    'dashboard.editDashboardViewId': number;
    'dashboard.editDashboardViewSearch': boolean;
    'dashboard.activeDashboardId': number;
    'dashboard.activeDashboardViewId': number;
    'dashboard.createViewBySearch': boolean;

    /* Modals */
    'modal.isAccessorialSelectorShown': boolean;
    'modal.isCreateDashboardModalShown': boolean;
    'modal.isQuotingModalShown': boolean;
    'modal.isShipperSelectContactModalShown': boolean;
    'modal.isConsigneeSelectContactModalShown': boolean;
    'modal.isCubeCalculatorModalShown': boolean;
    'modal.isSaveDashboardErrorModalShown': boolean;
    'modal.isSolicitTemplateNameModalShown': boolean;
    'modal.isImpersonateModalShown': boolean;

    /* Main Menu */
    'menu.mainMenuCollapsed': boolean;
    'menu.isDashboardMenuShown': boolean;

    /* Record */
    'record.activeSection': string;
    'record.displayStyle': string;
    'record.accordion.isQuoteSectionShown': boolean;
    'record.accordion.isGeneralRefSectionShown': boolean;
    'record.accordion.isShipperSectionShown': boolean;
    'record.accordion.isConsigneeSectionShown': boolean;
    'record.accordion.isProductListSectionShown': boolean;
    'record.accordion.isCarrierRefSectionShown': boolean;
    'record.accordion.isInvoicingSectionShown': boolean;
    'record.accordion.isAttachedFileSectionShown': boolean;
    'record.accordion.isSummarySectionShown': boolean;
    'interceptedSelectedSection': string;
    'record.fileUpload.isLoading': boolean;
    'record.isOverflowMenuVisible': boolean;
    'record.isQuoteReRatingNeeded': boolean;
    'record.isQuoteReRateWarningShown': boolean;
    'record.haveNonQuoteRelatedFieldsChanged': boolean;
    'record.isLoaderOverlayShown': boolean;
    'record.isRecordViewStylePromptShown': boolean;

    /* Quote */
    'quote.selectedRateId': number;

    /* search */
    'search.quoteSearchObject': {};
    'search.returnToQuoteSearchResults': boolean;
    'search.backToSearchResultsNeeded': boolean;
    'search.createDashboardView': boolean;
    'search.hasNewGlobalSearchBeenProcessed': boolean;

    /* login component */
    'isLoginComponentShown': boolean;

    /* floaters */
    'floater.clipboardCollapsed': boolean;
    'floater.shipmentNotesCollapsed': boolean;
    'floater.clipboardPinned': boolean;
    'floater.shipmentNotesPinned': boolean;

    /* clipboard */
    'clipboard.isFavoritesShown': boolean;
    'clipboard.isTemplatesShown': boolean;
    'clipboard.isNotesShown': boolean;

    /* guided tour */
    'guidedTour.isGuidedTourToolTipShown': boolean;

    /* globals */
    'global.apiVersion': string;
    'global.isUserSessionActive': boolean;
    'global.uniformDateDisplay.format': string;
    'global.uniformDateDisplay.validation': RegExp;

    /* logging service */
    'logging.sendToConsole': boolean;
    'logging.sendToApi': boolean;
    'logging.logLevel': number;

    /* Invoice */
    'invoice.navigationOrigin': string;

    /* Markups */
    'markups.navigateFromCarrierExceptionSelection': boolean;
    'markups.shouldCreateNew': boolean;

    /* Reporting */
    'shipmentReport.navigateFromHeader': boolean;
    'invoiceReport.navigateFromHeader': boolean;
}

export const initialAppState: AppState = {
    /* Admin */
    'admin.activeAdminSection': '',
    'admin.showLevel1Header': true,
    'admin.showLevel2Header': false,
    'admin.user.isSaveUserProfileReady': false,
    'admin.user.isSaveUserDefaultsReady': false,
    'admin.roles.isAdminRoleSaveReady': false,
    'admin.customer.areCustomerTabsVisible': false,
    'admin.navigateToMarkup': false,

    /* dashboard */
    'masterdata.activeSection': '',

    'dashboard.editDashboardView': false,
    'dashboard.editDashboardViewId': null,
    'dashboard.editDashboardViewSearch': false,
    'dashboard.activeDashboardId': null,
    'dashboard.activeDashboardViewId': null,
    'dashboard.createViewBySearch': false,

    /* Modals */
    'modal.isAccessorialSelectorShown': false,
    'modal.isCreateDashboardModalShown': false,
    'modal.isQuotingModalShown': false,
    'modal.isShipperSelectContactModalShown': false,
    'modal.isConsigneeSelectContactModalShown': false,
    'modal.isCubeCalculatorModalShown': false,
    'modal.isSaveDashboardErrorModalShown': false,
    'modal.isSolicitTemplateNameModalShown': false,
    'modal.isImpersonateModalShown': false,

    /* Main Menu */
    'menu.mainMenuCollapsed': true,
    'menu.isDashboardMenuShown': false,

    /* Record */
    'record.activeSection': '',
    'record.displayStyle': Constants.RecordDisplayStyle_Wizard,
    'record.accordion.isQuoteSectionShown': true,
    'record.accordion.isGeneralRefSectionShown': true,
    'record.accordion.isShipperSectionShown': true,
    'record.accordion.isConsigneeSectionShown': true,
    'record.accordion.isProductListSectionShown': true,
    'record.accordion.isCarrierRefSectionShown': true,
    'record.accordion.isInvoicingSectionShown': true,
    'record.accordion.isAttachedFileSectionShown': true,
    'record.accordion.isSummarySectionShown': true,
    'interceptedSelectedSection': '',
    'record.fileUpload.isLoading': false,
    'record.isOverflowMenuVisible': false,
    'record.isQuoteReRatingNeeded': false,
    'record.isQuoteReRateWarningShown': false,
    'record.haveNonQuoteRelatedFieldsChanged': false,
    'record.isLoaderOverlayShown': false,
    'record.isRecordViewStylePromptShown': false,

    /* Quote */
    'quote.selectedRateId': 0,

    /* search */
    'search.quoteSearchObject': null,
    'search.returnToQuoteSearchResults': false,
    'search.backToSearchResultsNeeded': false,
    'search.createDashboardView': false,
    'search.hasNewGlobalSearchBeenProcessed': false,

    /* login component */
    'isLoginComponentShown': false,

    /* floaters */
    'floater.clipboardCollapsed': true,
    'floater.shipmentNotesCollapsed': true,
    'floater.clipboardPinned': false,
    'floater.shipmentNotesPinned': false,

    /* clipboard */
    'clipboard.isFavoritesShown': true,
    'clipboard.isTemplatesShown': false,
    'clipboard.isNotesShown': false,

    /* guided tour */
    'guidedTour.isGuidedTourToolTipShown': false,

    /* globals */
    'global.apiVersion': '',
    'global.isUserSessionActive': false,
    'global.uniformDateDisplay.format': DateConstants.UNIFORM_DATE_DISPLAY.format,
    'global.uniformDateDisplay.validation': DateConstants.UNIFORM_DATE_DISPLAY.validation,

    /* logging service */
    'logging.sendToConsole': true,
    'logging.sendToApi': false,
    'logging.logLevel': 2,

    /* Invoice */
    'invoice.navigationOrigin': '',

    /* Markups Exception */
    'markups.navigateFromCarrierExceptionSelection': false,
    'markups.shouldCreateNew': false,

    /* Reporting */
    'shipmentReport.navigateFromHeader': false,
    'invoiceReport.navigateFromHeader': false,

};
