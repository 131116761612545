<div *ngIf="resetPasswordForm" class="resetPasswordMain">
    <div class="resetPasswordBody">
        <div class="shipriteLogoContainer">
            <img src="../../../assets/images/Logo.svg" class="shipriteLogo">
        </div>
        <div class="instructions">
            Please enter the new password you would like to use.
        </div>
        <form class="resetPasswordForm" [formGroup]="resetPasswordForm" (keyup)="checkForEnter($event)">
            <div class="resetPasswordFormRow">
                <app-password (getFocusElem)="setFocusPassword($event)" [passwordRegex]="passwordRegex" [passwordRegexText]="passwordRegexText" [requireOldPassword]="false" formControlName="password"></app-password>
            </div>
            <app-button class="resetPasswordSubmitButton" (click)="processresetPassword()">Submit</app-button>
        </form>
    </div>
</div>
