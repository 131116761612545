import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Router} from '@angular/router';
import * as _ from 'lodash-es';
import {Address, Contact, Terminal, TerminalRoute, USO} from '../../models/unifiedShipmentObject';
import {QuickQuoteService} from '../../services/quickQuote.service';

@Component({
    selector: 'app-quote-carrier-details',
    styleUrls: ['./app-quote-carrier-details.component.scss'],
    templateUrl: './app-quote-carrier-details.component.html',
})
export class AppQuoteCarrierDetailsComponent implements OnChanges {
    @Input()
    public quickQuote: USO;
    @Output()
    public changeCarrier = new EventEmitter<void>();

    originTerminal: Terminal;
    destinationTerminal: Terminal;

    constructor(
                private _quickQuoteService: QuickQuoteService,
                private _router: Router,
               ) {}

    public ngOnChanges() {
        const originTerminalStop = this.quickQuote.bookingSelection.terminalDetails[0].originStop;
        const destinationTerminalStop = this.quickQuote.bookingSelection.terminalDetails[0].destinationStop;
        const terminals = this.quickQuote.bookingSelection.terminalDetails[0].terminalRoute;

        this.originTerminal = _.find(terminals, (terminal: TerminalRoute) => terminal.terminalStopID === originTerminalStop).terminal;
        this.destinationTerminal = _.find(terminals, (terminal: TerminalRoute) => terminal.terminalStopID === destinationTerminalStop).terminal;
    }

    public carrierChanged() {
        this.changeCarrier.emit();
    }

    public emptyAddress(address: Address) {
        return !address.postalCode && !address.city && !address.state && !address.name &&
            !address.country && !address.addressLines.length;
    }

    public emptyContact(contact: Contact) {
        return !contact.fax && !contact.phoneMobile && !contact.phoneWork;
    }
}
