export class Template {
    templateID: number;
    templateType: string;
    templateGroup: string;
    templateName: string;
    template: any;
    templateUserid: number;
    changeBy: string;
    changeDate: string; // date
}

export class CreateTemplateRequest {
    templateType: string; // "S"
    templateGroup: string; // "New Group Test "
    templateName: string;
    template: object; // "JSON content"
}

export class CreateTemplateResponse {
    templateID: number;
}

export const initialTemplates: Array<Template> = [];
