export class Insurance {
    records: number;
    purchased: boolean;
    shipmentValue?: number;
    charge?: number;
    rated_amt?: number;
    carrier?: string;
    pkgQty?: number;
    totalcube?: number;
    totalweight?: number;
    unitQty?: number;
}
