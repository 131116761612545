export class AccessorialForShipment {
    Customer: string;    // shipper.customer-
    ID: number;    // shipper.id-
    description: string;    // accessorial code-
    value: number;    // hardcoded to 0 for now per Josh-
    amount: number;    // hardcoded to 0 for now per Josh-
    buy_amount: number;    // hardcoded to 0 for now per Josh-
    net_amount: number;    // hardcoded to 0 for now per Josh-
    Chg_ID?: number;
    displayName?: string;
}
