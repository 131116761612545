export class Favorite {
    changeDate: string;
    changeBy: string; // userId but being returned as a string currently.
    favoriteNo: number; // unique identifier for favorite within favorites table
    favoriteDescription: string;
    favoriteIdentifier: string; // refers to unique id of entity that is being favorited. Can also be entire JSON values in the event of a search being favorited.
    favoriteType: string; // entityType of object being favorited
    userid: string;
    favoriteName: string;
}

export const initialFavorites: Array<Favorite> = [];
