<div class="detailRow">
    <div class="labelCol">
        Carrier
    </div>
    <div class="carrier dataCol">
        <img src="{{ quickQuote.bookingSelection.LSP.logoURL }}" alt="{{ quickQuote.bookingSelection.LSP.name }} logo">
        <div class="clickable blue" (click)="carrierChanged()">Change Carrier</div>
    </div>
</div>

<div class="detailRow">
    <div class="labelCol">
        <span *ngIf="!emptyAddress(originTerminal.address) && !emptyContact(originTerminal.contact)">Origin Terminal </span>
        <span *ngIf="originTerminal.code">({{originTerminal.code}})</span>
    </div>
    <div class="dataCol">
        <app-address [terminal]="originTerminal"></app-address>
    </div>
</div>

<div class="detailRow">
    <div class="labelCol">
        <span *ngIf="!emptyAddress(destinationTerminal.address) && !emptyContact(destinationTerminal.contact)">Destination Terminal </span>
        <span *ngIf="destinationTerminal.code">({{destinationTerminal.code}})</span>
    </div>
    <div class="dataCol">
        <app-address [terminal]="destinationTerminal"></app-address>
    </div>
</div>
