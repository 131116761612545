<app-admin-tabs [activeAdminSection]="sectionName"></app-admin-tabs>
<div class="userListComponent">
    <div class="filterSectionRow">
        <div class="filterSection">
            <div class="searchCriteriaTypePanel">
                <select class="searchCriteriaTypeText" name="selectedFilterField" id="searchCriteriaTypes" [(ngModel)]="selectedFilterField"
                    (change)="filterUsers()">
                    <option [value]="ff.filterField" *ngFor="let ff of dropdownFilterFields">{{ ff.displayText }}</option>
                </select>
                <span class="searchCriteriaTypeChevron">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
            </div>
            <form class="filterInputPanel" (ngSubmit)="filterUsers()" [formGroup]="userListForm">
                <input type="text" id="searchQuery" autofocus class="searchQuery" name="searchQuery" formControlName="filterTerm"
                    placeholder="Enter Search Criteria" (change)="filterUsers()" />
            </form>
            <span class="iconSearch clickable blue">
                <i class="fa fa-search" aria-hidden="true"></i>
            </span>
        </div>
        <div class="createUserSection" (click)="createNewUser()">
            <span class="createUserIcon blue clickable">
                <i class="fa fa-plus-circle"></i>
            </span>
            <span class="createUserLabel blue clickable actionText">Create</span>
        </div>
        <div class="inviteUserSection" (click)="navigateToInviteUserScreen()">
            <span class="inviteUserIcon blue clickable">
                <i class="fa fa-envelope"></i>
            </span>
            <span class="inviteUserLabel blue clickable actionText">Invite</span>
        </div>
    </div>

    <div class="userListContainer">
        <div class="userListHeaderRow">
            <div class="headerItem">User Name</div>
            <div class="headerItem">User Login</div>
            <div class="headerItem">User ID</div>
            <div class="headerItem">Customer</div>
            <div class="lastCol">&nbsp;</div>
        </div>
        <div class="userDetailContainer" *ngIf="masterDataUsers.length">
            <cdk-virtual-scroll-viewport itemSize="25" style="height: 100%">
                <div class="userDetailRow bottomBorderShadow" *cdkVirtualFor="let user of filteredUsers; let i = index">
                    <div class="rowDetailItem rowDetailItemName blue actionText" (click)="navigateToUser(user.userID)">{{
                            user?.name }}</div>
                    <div class="rowDetailItem">{{ user?.userlogin }}</div>
                    <div class="rowDetailItem">{{ user?.userID }}</div>
                    <div class="rowDetailItem">{{ user?.customer }}</div>
                    <div class="lastCol" (click)="showConfirmDeleteModal(user)">
                        <i class="fa fa-times blue clickable" aria-hidden="true"></i>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>

<app-ok-cancel-modal *ngIf="isConfirmDeleteModalVisible" [title]="confirmDeleteUserTitle" [message]="confirmDeleteUserMessage"
    (onCancel)="hideConfirmDeleteUserModal()" (onOk)="deactivateUser($event)" [okPayload]="selectedUser"></app-ok-cancel-modal>
