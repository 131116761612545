<div class="overlay">
    <div class="rolesCustomerModalContainer">

        <!-- *********************ROLES CUSTOMER MODAL HEADER SECTION********************* -->
        <div class="rolesCustomerModalHeader">
            <div class="rolesCustomerModalTitle">
                Select a Customer
            </div>
            <div class="rolesCustomerModalCloseIcon" (click)="closeModal()">
                <i class="fa fa-times clickable" aria-hidden="true"></i>
            </div>
        </div>
        <!-- **************************ROLES CUSTOMER MODAL SEARCH SECTION******************** -->
        <div class="rolesCustomerInputWrapper">
            <form class="customerInputPanel" (ngSubmit)="filterValues()" [formGroup]="roleCustomerListForm">
                <input class="modalFilterInput" placeholder="Search" type="text" formControlName="customerSearchTerm" #queryField >
                <div class="searchIcon actionText blue clickable" (click)="filterValues()">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </form>
        </div>


        <!-- ***************************** ROLES CUSTOMER MODAL BODY SECTION********************** -->
        <div class="rolesCustomerResultsWrapper">
            <div class="resultsHeaderRow">
                <div class="firstResultHeaderItem">Customer Name</div>
                <div class="resultsHeaderItem">Customer Number</div>
            </div>

            <div class="filteredCustomerList scrollbarGray">
                <div class="resultsBodyRow clickable" *ngFor="let customer of displayedCustomerList" (click)="selectFilteredCustomer(customer)">
                    <div class="firstResultRowDetailItem">{{ customer?.custName | titlecase }} </div>
                    <div class="resultsRowDetailItem">{{ customer?.custNo }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
