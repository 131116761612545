import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { PaginationPageNumber } from '../../../models/paginationPageNumber';
import { SearchState } from '../../../models/searchState';
import {SignalsService} from "../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {SearchService} from "../../../services/search.service";

@Component({
  selector: 'app-search-pagination-panel',
  templateUrl: './search-pagination-panel.component.html',
  styleUrls: ['./search-pagination-panel.component.scss'],
})
export class SearchPaginationPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() searchResultTotal: number = 100;
  @Output() public pageChanged = new EventEmitter<{ from: number, size: number }>();

  private appStateSubscription;
  private searchSubscription: any;
  public savedPaginationData: PaginationPageNumber;
  public hasNewGlobalSearchBeenProcessed: boolean;

  public resultsPerPage: number = 20;
  private previousResultsPerPage: number = this.resultsPerPage;
  public resultsPerPageOptions: Array<number> = [
    10,
    20,
    50,
    100
  ];
  public pageNumbers: Array<PaginationPageNumber> = [];
  public currentPage: PaginationPageNumber = null;
  private initialized: boolean = false;
  private appState$;
  private search$

  constructor(
    private _searchService: SearchService,
    private _signalsService: SignalsService,
  ) {
      this.appState$ = toObservable(this._signalsService.appStateSignal);
      this.search$ = toObservable(this._signalsService.searchStateSignal);
  }

  public ngOnInit() {
    this.appStateSubscription = this.appState$.subscribe(appState => {
      this.hasNewGlobalSearchBeenProcessed = appState['search.hasNewGlobalSearchBeenProcessed'];
    });

    this.searchSubscription = this.search$.subscribe((search: SearchState) => {
      this.savedPaginationData = search.paginationPageNumber;

      // if !this.hasNewGlobalSearchBeenProcessed, then run local this.setCurrentPage() method passing in this.savedPaginationData
      if (!this.hasNewGlobalSearchBeenProcessed && this.savedPaginationData && this.savedPaginationData.resultsPerPage) {
        this.setCurrentPage(this.savedPaginationData);
        this.resultsPerPage = this.savedPaginationData.resultsPerPage;
      }
    });

    this.initialized = true;
    this.setPageNumberOptionsFromTotal();

  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.initialized) {
      if (changes.searchResultTotal && (changes.searchResultTotal.previousValue !== changes.searchResultTotal.currentValue)) {
        this.setPageNumberOptionsFromTotal();
      }
    }
  }

  public ngOnDestroy() {
    const currentPageData: PaginationPageNumber = {
      ...this.currentPage,
      resultsPerPage: this.resultsPerPage
    };

    this._searchService.updatePaginationData(currentPageData);

    this.appStateSubscription.unsubscribe();
    this.searchSubscription.unsubscribe();
  }

  // ===========================================================================
  // ============================== SETUP METHODS ==============================
  // ===========================================================================
  private setPageNumberOptionsFromTotal(): void {
    const numberOfPages: number = Math.ceil(this.searchResultTotal / this.resultsPerPage);

    this.pageNumbers = [];
    for (let i = 0; i < numberOfPages; i++) {
      this.pageNumbers.push({
        ... new PaginationPageNumber(),
        pageNumber: i + 1,
      });
    }

    if (!this.savedPaginationData) {
      this.setCurrentPage(this.pageNumbers[0]);
    } else {
      const savedCurrentPageIndex = this.pageNumbers.findIndex((page: PaginationPageNumber) => page.pageNumber === this.savedPaginationData.pageNumber);
      this.setCurrentPage(this.pageNumbers[savedCurrentPageIndex]);
    }

  }

  private resetPageNumbers(): void {
    this.pageNumbers = this.pageNumbers.map((pageNumberOption: PaginationPageNumber) => {
      return {
        ...pageNumberOption,
        isCurrentPage: false,
        isPreviousSetEllipsis: false,
        isNextSetEllispis: false,
      };
    });
  }

  public setCurrentPage(page: PaginationPageNumber): void {
    if (!page || page.isCurrentPage) {
      return;
    }

    this.resetPageNumbers();

    const newCurrentPage: PaginationPageNumber = this.pageNumbers.find((pNO: PaginationPageNumber) => pNO.pageNumber === page.pageNumber);
    newCurrentPage.isCurrentPage = true;
    this.currentPage = newCurrentPage;
  }

  private passPageChangesToParent(): void {
    this.pageChanged.emit({
      from: (this.currentPage.pageNumber - 1) * this.resultsPerPage,
      size: this.resultsPerPage,
    });
  }

  // ================================================================================================
  // ======================================= UI CHANGE METHODS =======================================
  // ================================================================================================

  public callNextPage(): void {
    if (!this.pageNumbers || !this.pageNumbers.length) {
      return;
    }

    const currentPage: PaginationPageNumber = this.pageNumbers.find((pNO: PaginationPageNumber) => pNO.isCurrentPage);
    const currentPageIndex: number = this.pageNumbers.indexOf(currentPage);

    if (!currentPage || currentPage.pageNumber === this.pageNumbers.length) {
      return;
    }

    this.setCurrentPage(this.pageNumbers[currentPageIndex + 1]);
    this.passPageChangesToParent();
  }

  public callPreviousPage(): void {
    if (!this.pageNumbers || !this.pageNumbers.length) {
      return;
    }

    const currentPage: PaginationPageNumber = this.pageNumbers.find((pNO: PaginationPageNumber) => pNO.isCurrentPage);
    const currentPageIndex: number = this.pageNumbers.indexOf(currentPage);

    if (!currentPage || currentPage.pageNumber === 1) {
      return;
    }

    this.setCurrentPage(this.pageNumbers[currentPageIndex - 1]);
    this.passPageChangesToParent();
  }

  public callPageNumber(numberPage: PaginationPageNumber): void {
    this.setCurrentPage(numberPage);
    this.passPageChangesToParent();
  }

  public callNextSet(ellipsisPage: PaginationPageNumber): void {
    if (!ellipsisPage || !ellipsisPage.isNextSetEllispis) {
      return;
    }

    this.setCurrentPage(ellipsisPage);
    this.passPageChangesToParent();
  }

  public callPreviousSet(ellipsisPage: PaginationPageNumber): void {
    if (!ellipsisPage || !ellipsisPage.isPreviousSetEllipsis) {
      return;
    }

    this.setCurrentPage(ellipsisPage);
    this.passPageChangesToParent();
  }

  public setPreviousResultsPerPageValue(): void {
    this.previousResultsPerPage = this.resultsPerPage;
  }

  public handleResultsPerPageChanges(): void {
    if (!this.pageNumbers || !this.pageNumbers.length || !this.currentPage) {
      return;
    }

    const selectedResultSetStart: number = ((this.currentPage.pageNumber - 1) * this.previousResultsPerPage) + 1;
    this.setPageNumberOptionsFromTotal();

    for (let i = 0; i < this.pageNumbers.length; i++) {
      const pNO: PaginationPageNumber = this.pageNumbers[i];
      const previousResultSetEnd: number = (pNO.pageNumber - 1) * this.resultsPerPage;
      const currentResultSetEnd: number = pNO.pageNumber * this.resultsPerPage;

      if ((previousResultSetEnd <= selectedResultSetStart) && (selectedResultSetStart <= currentResultSetEnd)) {
        this.setCurrentPage(pNO);
        this.passPageChangesToParent();
        return;
      }
    }
  }
}
