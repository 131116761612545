<div class="overlay">
    <div class="okCancelModal">
        <!-- =========================== MODAL HEADER =========================== -->
        <div class="modalHeader">
            <span class="closeModalIconContainer clickable">
                <i class="fa fa-times closeModalIcon" (click)="cancel()"></i>
            </span>
        </div>

        <!-- =========================== MODAL MESSAGES =========================== -->
        <div class="messageContainer mainMessage">
            <div class="message">
                {{ message }}
            </div>
        </div>

        <div class="messageContainer additionalMessage" *ngIf="additionalMessage">
            <div class="message">
                {{ additionalMessage }}
            </div>
            <input [(ngModel)]="value" (change)="validate()" class="value" />
        </div>

        <div class="errorMessage" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
        <!-- =========================== OKAY AND CANCEL BUTTONS =========================== -->
        <div class="buttonPanel">
            <div class="btn-outline clickable" (click)="cancel()" *ngIf="!suppressCancelButton">
                {{ cancelBtnText }}
            </div>

            <div class="btn-filled clickable" (click)="ok()">
                {{ okayBtnText }}
            </div>
        </div>
    </div>
</div>
