import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import * as Constants from '../../../constants/constants';
import {CommonDataService} from '../../../services/commonData.service';
import {InsuranceService} from '../../../services/insurance.service';
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-insurance-quoting-modal',
    templateUrl: './insurance-quoting-modal.component.html',
    styleUrls: ['./insurance-quoting-modal.component.scss']
})
export class InsuranceQuotingModalComponent implements OnInit {
    @Input()
    public shipment;
    @Output()
    public updateInsurancePurchase = new EventEmitter<any>();

    public insuranceCharge: number = null;
    public declaredShipmentValue: number = null;
    public shipmentCost: number = null;
    public declareShipmentValueForm: UntypedFormGroup;
    public totalShipmentCost: number;

    constructor(
        private _commonDataService: CommonDataService,
        private _notificationService: NotificationService,
        private _insuranceService: InsuranceService,
    ) {}

    public ngOnInit() {
        document.getElementById('termsOfAgreementContainer').innerHTML = this._commonDataService.insuranceTermsOfAgreement;

        this.shipmentCost = this.shipment.rated_amt ? this.shipment.rated_amt : 0;
        this.totalShipmentCost = this.shipmentCost;

        this.declareShipmentValueForm = new UntypedFormGroup({
            declaredShipmentValue: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern(Constants.VALID_DECIMAL_REGEX),
                Validators.min(0.01)
            ])
        });
    }

    public async requestInsuranceQuotedValue() {
        if (this.declareShipmentValueForm.invalid) {
            if (this.declareShipmentValueForm.controls.declaredShipmentValue.errors.required) {
                this._notificationService.notifyError({
                    title: 'Declared Shipment Value Error',
                    message: 'Declared Shipment Value is required.'
                });
            } else if (this.declareShipmentValueForm.controls.declaredShipmentValue.errors.pattern) {
                this._notificationService.notifyError({
                    title: 'Declared Shipment Value Error',
                    message: 'A valid decimal or number amount is required'
                });
            } else if (this.declareShipmentValueForm.controls.declaredShipmentValue.errors.min) {
                this._notificationService.notifyError({
                    title: 'Declared Shipment Value Error',
                    message: 'Declared value must be positive.'
                });
            }
            return;
        }
        this.declaredShipmentValue = parseFloat(this.declareShipmentValueForm.value.declaredShipmentValue);
        this.insuranceCharge = await this._insuranceService.getRecordInsuranceQuotedValue(this.declaredShipmentValue).toPromise();
        this.totalShipmentCost = this.shipmentCost + this.insuranceCharge;
    }

    public purchaseInsurance(): void {
        this.updateInsurancePurchase.emit({
            declaredShipmentValue: this.declaredShipmentValue,
            insuranceCharge: this.insuranceCharge
        })
    }

    public closeModal(): void {
        this.updateInsurancePurchase.emit(null);
    }
}
