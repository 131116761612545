<div class="mainRoleSection">
    <app-admin-role-tabs (selectedAminRoleTab)="activeRoleTabSection = $event"></app-admin-role-tabs>
    <div class="rolesDetailSections" *ngIf="roleDetailForm">
        <form class="rolesDetailSections" [formGroup]="roleDetailForm">
            <!-- =========================================================================== -->
            <!-- =========================== ROLES GENERAL SECTION =========================== -->
            <!-- =========================================================================== -->
            <div *ngIf="activeRoleTabSection === roleTabSections.general" class="roleGeneralContainer">
                <div class="rolesGeneralLeftColumn">
                    <div class="rolesGeneralRow" [ngClass]="{'inputSearchBoxReadOnly': isAdminExternal}">
                        <div class="rolesGeneralRowLabel">
                            <span class="red required">*</span>Role Name</div>
                        <input class="rolesRedGeneralRowField" formControlName="roleName">
                    </div>
                    <div class="rolesGeneralRow" [ngClass]="{'inputSearchBoxReadOnly': isAdminExternal}">
                        <div class="rolesGeneralRowLabel ">
                            <span class="red required">*</span>System-Wide or
                            <br>Customer-Specific</div>
                        <div class="rolesGeneralRowSelectPanel clickableCaretExtension">
                            <select class="rolesGeneralRowSelectTag" formControlName="roleType">
                                <option *ngFor="let roleType of roleTypes" [value]="roleType.roleType">{{ roleType.displayRoleType
                                    }}
                                </option>
                            </select>
                            <span class="rolesGeneralRowSelectCaret clickableCaret clickableCaretPositioning">
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </div>
                    </div>
                    <div class="rolesGeneralRow" *ngIf="roleDetailForm['controls'].roleType.value === 'CUSTOMER'" [ngClass]="{'inputSearchBoxReadOnly': isAdminExternal}">
                        <div class="rolesGeneralRowLabel">
                            <span class="red required">*</span>Customer</div>
                        <div class="clickable rolesGeneralRowSelectPanel clickableCaretExtension" (click)="isCustomerRolesModalShown = true">
                            <div class="adminRoleCustomerSearch ">{{ selectedCustomerName }}</div>
                            <span class="rolesGeneralRowSelectCaret  clickableCaretPositioning">
                                <i class="fa fa-search searchIcon blue"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="rolesGeneralRightColumn">
                    <div class="rolesGeneralRow" [ngClass]="{'inputSearchBoxReadOnly': isAdminExternal}">
                        <div class="rolesGeneralRowTextAreaLabel">
                            Description
                        </div>
                        <textarea class="rolesRedGeneralRowTextArea" formControlName="roleDescription"></textarea>
                    </div>
                    <div class="rolesGeneralRow">
                        <div class="rolesGeneralRowLabel">
                            TMA Authorized
                        </div>
                        <span *ngIf="!roleDetailForm['controls'].tmaAuthorized.value" class="tmaAuthorizedIcon clickable">
                            <i class="fa fa-square-o uncheckedBox" aria-hidden="true" (click)="roleDetailForm.controls.tmaAuthorized.setValue(1)"></i>
                        </span>
                        <span *ngIf="roleDetailForm['controls'].tmaAuthorized.value" class="tmaAuthorizedIcon clickable">
                            <i class="fa fa-check-square checkedBox" aria-hidden="true" (click)="roleDetailForm.controls.tmaAuthorized.setValue(0)"></i>
                        </span>
                    </div>
                </div>
            </div>

            <!-- =========================================================================== -->
            <!-- =========================== ROLES PERMISSIONS SECTION =========================== -->
            <!-- =========================================================================== -->
            <div class="rolePermissionsContainer" [ngClass]="{ 'inactiveRoleTabSection': activeRoleTabSection !== roleTabSections.permissions }">

                <div class="entityPermissions">
                    <div class="permissionsContainer userPermissions">
                        <div class="recordTypeResultsHeader userPermissionsHeader clickable" (click)="isUserPermissionsExpanded = !isUserPermissionsExpanded">
                            <div class="leftHeaderItems">
                                <div *ngIf="isUserPermissionsExpanded" class="collapseIcon">
                                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="!isUserPermissionsExpanded" class="collapseIcon">
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </div>
                                <span class="fa-stack permissionsIconStack">
                                    <i class="fa fa-circle fa-stack-2x"></i>
                                    <i class="fa fa-user fa-stack-1x" aria-hidden="true"></i>
                                </span>
                                <div class="recordTypeLabel">
                                    <span class="entitySubheadLabel">User Permissions</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ collapsedPermissionPanel: isUserPermissionsExpanded }" class="userPermissionsDetailPanel">
                            <div class="permissionDetailLeftCol">
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Quote
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.QUOTES, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].quotes.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].quotes.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.QUOTES, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].quotes.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].quotes.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.QUOTES, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].quotes.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].quotes.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Shipment
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.SHIPMENTS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].shipments.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].shipments.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.SHIPMENTS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].shipments.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].shipments.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.SHIPMENTS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].shipments.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].shipments.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Invoice
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.INVOICES, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].invoices.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].invoices.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.INVOICES, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].invoices.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].invoices.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.INVOICES, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].invoices.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].invoices.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="permissionDetailMidCol">
                                <div class="permissionsRow" id="userPermissions-contact">
                                    <div class="permissionsRowLabel">
                                        Contact
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.CONTACTS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].contacts.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].contacts.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.CONTACTS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].contacts.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].contacts.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable"
                                             [ngClass]="{ 'disabled': isViewOrEdit(permissionDetailTypes.USER, permissionDetailFields.SHIPMENTS) }"
                                             (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.CONTACTS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].contacts.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].contacts.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow" id="userPermissions-product">
                                    <div class="permissionsRowLabel">
                                        Product
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.PRODUCTS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].products.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].products.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.PRODUCTS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].products.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].products.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable"
                                             [ngClass]="{ 'disabled': isViewOrEdit(permissionDetailTypes.USER, permissionDetailFields.SHIPMENTS) || isViewOrEdit(permissionDetailTypes.USER, permissionDetailFields.QUOTES) }"
                                             (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.PRODUCTS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].products.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].products.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow" id="userPermissions-carrier">
                                    <div class="permissionsRowLabel">
                                        Carrier
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.CARRIER, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].carrier.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].carrier.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.CARRIER, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].carrier.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].carrier.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable"
                                             [ngClass]="{ 'disabled': isViewOrEdit(permissionDetailTypes.USER, permissionDetailFields.SHIPMENTS) || isViewOrEdit(permissionDetailTypes.USER, permissionDetailFields.QUOTES) }"
                                             (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.CARRIER, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].carrier.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].carrier.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="permissionDetailRightCol">
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Customers
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.CUSTOMERS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].customers.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].customers.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.CUSTOMERS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].customers.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].customers.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.CUSTOMERS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].customers.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].customers.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow" id="userPermissions-users">
                                    <div class="permissionsRowLabel">
                                        Users
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.USERS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].users.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].users.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.USER, permissionDetailFields.USERS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].users.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].userPermissions['controls'].users.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="permissionsContainer groupPermissions">
                        <div class="recordTypeResultsHeader groupPermissionsHeader clickable" (click)="isGroupPermissionsExpanded = !isGroupPermissionsExpanded">
                            <div class="leftHeaderItems">
                                <div *ngIf="isGroupPermissionsExpanded" class="collapseIcon">
                                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="!isGroupPermissionsExpanded" class="collapseIcon">
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </div>
                                <span class="fa-stack permissionsIconStack">
                                    <i class="fa fa-circle fa-stack-2x"></i>
                                    <i class="fa fa-users fa-stack-1x" aria-hidden="true"></i>
                                </span>
                                <div class="recordTypeLabel">
                                    <span class="entitySubheadLabel">Group Permissions</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ collapsedPermissionPanel: isGroupPermissionsExpanded }" class="userPermissionsDetailPanel">
                            <div class="permissionDetailLeftCol">
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Quote
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.QUOTES, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].quotes.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].quotes.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable">
                                            <span class="permissionsRadioButton" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.QUOTES, permissionDetailValues.EDIT)">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].quotes.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].quotes.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.QUOTES, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].quotes.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].quotes.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Shipment
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.SHIPMENTS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].shipments.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].shipments.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.SHIPMENTS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].shipments.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].shipments.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.SHIPMENTS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].shipments.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].shipments.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Invoice
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.INVOICES, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].invoices.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].invoices.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.INVOICES, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].invoices.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].invoices.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.INVOICES, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].invoices.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].invoices.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="permissionDetailMidCol">
                                <div class="permissionsRow" id="groupPermissions-contact">
                                    <div class="permissionsRowLabel">
                                        Contact
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.CONTACTS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].contacts.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].contacts.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.CONTACTS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].contacts.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].contacts.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable"
                                             [ngClass]="{ 'disabled': isViewOrEdit(permissionDetailTypes.GROUP, permissionDetailFields.SHIPMENTS) }"
                                             (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.CONTACTS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].contacts.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].contacts.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow" id="groupPermissions-product">
                                    <div class="permissionsRowLabel">
                                        Product
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.PRODUCTS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].products.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].products.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.PRODUCTS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].products.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].products.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable"
                                             [ngClass]="{ 'disabled': isViewOrEdit(permissionDetailTypes.GROUP, permissionDetailFields.SHIPMENTS) || isViewOrEdit(permissionDetailTypes.GROUP, permissionDetailFields.QUOTES) }"
                                             (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.PRODUCTS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].products.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].products.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow" id="groupPermissions-carrier">
                                    <div class="permissionsRowLabel">
                                        Carrier
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.CARRIER, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].carrier.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].carrier.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.CARRIER, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].carrier.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].carrier.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable"
                                             [ngClass]="{ 'disabled': isViewOrEdit(permissionDetailTypes.GROUP, permissionDetailFields.SHIPMENTS) || isViewOrEdit(permissionDetailTypes.GROUP, permissionDetailFields.QUOTES) }"
                                             (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.CARRIER, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].carrier.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].carrier.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="permissionDetailRightCol">
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Customers
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.CUSTOMERS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].customers.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].customers.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.CUSTOMERS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].customers.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].customers.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.CUSTOMERS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].customers.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].customers.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Users
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.USERS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].users.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].users.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.USERS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].users.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].users.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.USERS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].users.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].users.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Markups
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.MARKUPS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].markups.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].markups.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.MARKUPS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].markups.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].markups.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.MARKUPS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].markups.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].markups.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Roles
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.ROLES, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].roles.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].roles.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.ROLES, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].roles.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].roles.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GROUP, permissionDetailFields.ROLES, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].roles.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].groupPermissions['controls'].roles.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="permissionsContainer globalPermissions">
                        <div class="recordTypeResultsHeader globalPermissionsHeader clickable" (click)="isGlobalPermissionsExpanded = !isGlobalPermissionsExpanded">
                            <div class="leftHeaderItems">
                                <div *ngIf="isGlobalPermissionsExpanded" class="collapseIcon">
                                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="!isGlobalPermissionsExpanded" class="collapseIcon">
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </div>
                                <span class="fa-stack permissionsIconStack">
                                    <i class="fa fa-circle fa-stack-2x"></i>
                                    <i class="fa fa-globe fa-stack-1x" aria-hidden="true"></i>
                                </span>
                                <div class="recordTypeLabel">
                                    <span class="entitySubheadLabel">Global Permissions</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{ collapsedPermissionPanel: isGlobalPermissionsExpanded }" class="userPermissionsDetailPanel">
                            <div class="permissionDetailLeftCol">
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Quote
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.QUOTES, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].quotes.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].quotes.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.QUOTES, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].quotes.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].quotes.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.QUOTES, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].quotes.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].quotes.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Shipment
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.SHIPMENTS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].shipments.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].shipments.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.SHIPMENTS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].shipments.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].shipments.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.SHIPMENTS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].shipments.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].shipments.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Invoice
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.INVOICES, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].invoices.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].invoices.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.INVOICES, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].invoices.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].invoices.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.INVOICES, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].invoices.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].invoices.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="permissionDetailMidCol">
                                <div class="permissionsRow" id="globalPermissions-contact">
                                    <div class="permissionsRowLabel">
                                        Contact
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.CONTACTS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].contacts.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].contacts.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.CONTACTS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].contacts.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].contacts.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable"
                                             [ngClass]="{ 'disabled': isViewOrEdit(permissionDetailTypes.GLOBAL, permissionDetailFields.SHIPMENTS) }"
                                             (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.CONTACTS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].contacts.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].contacts.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow" id="globalPermissions-product">
                                    <div class="permissionsRowLabel">
                                        Product
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.PRODUCTS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].products.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].products.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.PRODUCTS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].products.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].products.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable"
                                             [ngClass]="{ 'disabled': isViewOrEdit(permissionDetailTypes.GLOBAL, permissionDetailFields.SHIPMENTS) || isViewOrEdit(permissionDetailTypes.GLOBAL, permissionDetailFields.QUOTES) }"
                                             (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.PRODUCTS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].products.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].products.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow" id="globalPermissions-carrier">
                                    <div class="permissionsRowLabel">
                                        Carrier
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.CARRIER, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].carrier.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].carrier.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.CARRIER, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].carrier.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].carrier.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable"
                                             [ngClass]="{ 'disabled': isViewOrEdit(permissionDetailTypes.GLOBAL, permissionDetailFields.SHIPMENTS) || isViewOrEdit(permissionDetailTypes.GLOBAL, permissionDetailFields.QUOTES) }"
                                             (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.CARRIER, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].carrier.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].carrier.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="permissionDetailRightCol">
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Customers
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.CUSTOMERS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].customers.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].customers.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.CUSTOMERS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].customers.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].customers.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.CUSTOMERS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].customers.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].customers.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow">
                                    <div class="permissionsRowLabel">
                                        Users
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.USERS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].users.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].users.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.USERS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].users.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].users.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.USERS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].users.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].users.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow" id="globalPermissions-markups">
                                    <div class="permissionsRowLabel">
                                        Markups
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.MARKUPS, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].markups.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].markups.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.MARKUPS, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].markups.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].markups.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.MARKUPS, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].markups.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].markups.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="permissionsRow" id="globalPermissions-roles">
                                    <div class="permissionsRowLabel">
                                        Roles
                                    </div>
                                    <div class="permissionsRowField permissionsRowRadioPanel">
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.ROLES, permissionDetailValues.VIEW)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].roles.value !== permissionDetailValues.VIEW"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].roles.value === permissionDetailValues.VIEW"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                View
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.ROLES, permissionDetailValues.EDIT)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].roles.value !== permissionDetailValues.EDIT"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].roles.value === permissionDetailValues.EDIT"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                Edit
                                            </span>
                                        </div>
                                        <div class="permissionsRowRadioOption clickable" (click)="setPermissionValue(permissionDetailTypes.GLOBAL, permissionDetailFields.ROLES, permissionDetailValues.NONE)">
                                            <span class="permissionsRadioButton">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].roles.value !== permissionDetailValues.NONE"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].globalPermissions['controls'].roles.value === permissionDetailValues.NONE"></i>
                                            </span>
                                            <span class="permissionsRadioButtonLabel">
                                                None
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- =========================================================================== -->
            <!-- =========================== ROLES QUOTING SECTION =========================== -->
            <!-- =========================================================================== -->
            <div *ngIf="activeRoleTabSection === roleTabSections.quoting" class="roleQuotingContainer">
                <div class="roleQuotingContainer">
                    <div class="roleQuotingRatedTierSection">
                        <div class="rateTiersLabelRow borderBottomGray">
                            <div class="rateTiersIndexCol">
                                <span>

                                </span>
                            </div>

                            <div class="addTierIconCol">
                                <span>
                                    &nbsp;
                                </span>
                            </div>
                            <div class="tierLabelCol">
                                <span class="labelRowText">
                                    Tier Label
                                </span>
                            </div>
                            <div class="tierMarkUpIDCol">
                                <span class="labelRowText">
                                    Rate ID
                                </span>
                            </div>
                            <div class="fuelIdCol">
                                <span class="labelRowText">
                                    Fuel ID
                                </span>
                            </div>
                            <div class="acclIdCol">
                                <span class="labelRowText">
                                    Accl ID
                                </span>
                            </div>
                            <div class="displayTierCol">
                                <span class="labelRowText">
                                    Display Tier
                                </span>
                            </div>
                            <div class="dateRangeSelectionCol">
                                <span class="labelRowText">
                                    Date Range
                                </span>
                            </div>
                            <div class="removeLineIconCol">
                                <span class="labelRowText">
                                </span>
                            </div>
                        </div>
                        <div class="ratedTiersFormRow borderBottomGray">
                            <span class="rateTiersIndexCol">
                                <span>1</span>
                            </span>
                            <div class="addTierIconCol">
                                <span>
                                    &nbsp;
                                </span>
                            </div>

                            <div class="tierLabelCol">
                                <span>Net</span>
                            </div>
                            <div class="tierMarkUpIDCol"></div>
                            <div class="fuelIdCol"></div>
                            <div class="acclIdCol"></div>
                            <div class="displayTierCol">
                                <span></span>
                            </div>
                            <div class="dateRangeSelectionCol">
                                <div class="dateRangePanelEmpty"></div>
                            </div>
                            <div class="removeLineIconCol"></div>
                        </div>
                        <!-- ==================================== TIER ORDER 2 RATE TEARZ ==================================== -->
                        <div class="ratedTiersFormRow borderBottomGray" formArrayName="levelTwoRateTiers" *ngIf="!visibleLineExists('levelTwoRateTiers')">
                            <span class="rateTiersIndexCol">
                                <span>2</span>
                            </span>
                            <div class="addTierIconCol">
                                <span class="clickable" (click)="addRateTierFormLine('levelTwoRateTiers')">
                                    <i class="fa fa-plus-circle blue"></i>
                                </span>
                            </div>
                        </div>
                        <div class="ratedTiersFormRow borderBottomGray" formArrayName="levelTwoRateTiers" *ngFor="let rateTier of roleDetailForm['controls'].levelTwoRateTiers['controls']; let i = index;"
                            [ngClass]="{ 'hiddenHistoricalRateTier': !areHistoricalRateTiersDisplayed && rateTier?.value.history, 'activeTier': isActiveTier(rateTier), 'historicalTier': isHistoricalTier(rateTier), 'invalidTier': !rateTier.value.history && !isValidTier(rateTier) }">
                            <span class="rateTiersIndexCol" [formGroupName]="i">
                                <span *ngIf="i === 0">2</span>
                            </span>
                            <div class="addTierIconCol">
                                <span class="clickable" (click)="addRateTierFormLine('levelTwoRateTiers')" *ngIf="i === 0">
                                    <i class="fa fa-plus-circle blue"></i>
                                </span>
                            </div>
                            <div class="tierLabelCol" [formGroupName]="i">
                                <input *ngIf="isNewTier(rateTier)" type="text" class="tierLabelValueInputTag" formControlName="tierLabel">
                                <span *ngIf="!isNewTier(rateTier)">{{rateTier.value.tierLabel}}</span>
                            </div>
                            <div class="tierMarkUpIDCol" [formGroupName]="i">
                                <div *ngIf="isNewTier(rateTier)" class="rateTiersValuePanel clickable" (click)="showRateMarkupSearchModal(rateTier)">
                                    <div class="markupDescriptionContainer">
                                        <div class="markupDescriptionContents" *ngIf="getRateMarkupName(rateTier?.value.rateMarkupID)">
                                            <div class="markupName clickable">
                                                {{ getRateMarkupName(rateTier?.value.rateMarkupID) }}
                                            </div>
                                            <div class="markupNameRemoveIcon" (click)="removeRateMarkupId($event, rateTier)">
                                                <i class="fa fa-times blue clickable"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="markupDescriptionSearchIcon blue clickable">
                                        <i aria-hidden="true" class="fa fa-search searchIcon"></i>
                                    </span>
                                </div>
                                <span class="blue clickable actionText" (click)="goToMarkup(rateTier?.value.rateMarkupID)" *ngIf="!isNewTier(rateTier)">{{ getRateMarkupName(rateTier?.value.rateMarkupID) }}</span>
                            </div>
                            <div class="fuelIdCol" [formGroupName]="i">
                                <div *ngIf="isNewTier(rateTier)" class="rateTiersValuePanel clickable" (click)="showFuelMarkupSearchModal(rateTier)">
                                    <div class="markupDescriptionContainer">
                                        <div class="markupDescriptionContents" *ngIf="getFuelMarkupName(rateTier?.value.fuelMarkupID)">
                                            <div class="markupName">
                                                {{ getFuelMarkupName(rateTier?.value.fuelMarkupID) }}
                                            </div>
                                            <div class="markupNameRemoveIcon" (click)="removeFuelMarkupId($event, rateTier)">
                                                <i class="fa fa-times blue clickable"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="markupDescriptionSearchIcon blue clickable">
                                        <i aria-hidden="true" class="fa fa-search searchIcon"></i>
                                    </span>
                                </div>
                                <span class="blue clickable actionText" (click)="goToMarkup(rateTier?.value.fuelMarkupID)" *ngIf="!isNewTier(rateTier)">{{ getFuelMarkupName(rateTier?.value.fuelMarkupID) }}</span>
                            </div>
                            <div class="acclIdCol" [formGroupName]="i">
                                <div *ngIf="isNewTier(rateTier)" class="rateTiersValuePanel clickable" (click)="showAcclMarkupSearchModal(rateTier)">
                                    <div class="markupDescriptionContainer">
                                        <div class="markupDescriptionContents" *ngIf="getAcclMarkupName(rateTier?.value.acclMarkupID)">
                                            <div class="markupName">
                                                {{ getAcclMarkupName(rateTier?.value.acclMarkupID) }}
                                            </div>
                                            <div class="markupNameRemoveIcon" (click)="removeAcclMarkupId($event, rateTier)">
                                                <i class="fa fa-times blue clickable"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="markupDescriptionSearchIcon blue clickable">
                                        <i aria-hidden="true" class="fa fa-search searchIcon"></i>
                                    </span>
                                </div>
                                <span class="blue clickable actionText" (click)="goToMarkup(rateTier?.value.acclMarkupID)" *ngIf="!isNewTier(rateTier)">{{ getAcclMarkupName(rateTier?.value.acclMarkupID) }}</span>
                            </div>
                            <div class="displayTierCol" [formGroupName]="i">
                                <span>
                                    <i *ngIf="!roleDetailForm['controls'].levelTwoRateTiers['controls'][i]['controls'].displayTier.value"
                                        class="iconSize clickable fa fa-square-o blue"
                                        aria-hidden="true" (click)="toggleRateTierDisplay(rateTier)"></i>
                                    <i *ngIf="roleDetailForm['controls'].levelTwoRateTiers['controls'][i]['controls'].displayTier.value"
                                        class="iconSize clickable fa fa-check-square blue"
                                        aria-hidden="true" (click)="toggleRateTierDisplay(rateTier)"></i>
                                </span>
                            </div>
                            <div class="dateRangeSelectionCol" [formGroupName]="i">
                                <div *ngIf="isNewTier(rateTier)" class="dateRangePanel" [ngClass]="{ 'dateRangeContradiction': rateTier &&
                            (
                                (rateTier['controls'].effective && rateTier['controls'].effective.errors && rateTier['controls'].effective.errors.invalidDate) ||
                                (rateTier['controls'].end && rateTier['controls'].end.errors && rateTier['controls'].end.errors.invalidDate)
                            ) }">
                                    <input type="text" class="dateRangeStart" formControlName="effective">
                                    <span class="dateRangeText">
                                        to
                                    </span>
                                    <input type="text" class="dateRangeEnd" formControlName="end">
                                    <span class="dateRangeIcon blue clickable" (click)="showDatePicker(rateTier)">
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div *ngIf="!isNewTier(rateTier)" class="dateRangePanelEndEditable">
                                    <span class="dateRangeStart">{{rateTier.value.effective}}</span>
                                    <span class="dateRangeText">
                                        to
                                    </span>
                                    <input *ngIf="!rateTier.value.history" type="text" class="dateRangeEnd" formControlName="end">
                                    <span *ngIf="!rateTier.value.history" class="dateRangeIcon blue clickable" (click)="showSingleDatePicker(rateTier)">
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </span>
                                    <span *ngIf="rateTier.value.history"  class="dateRangeEnd">{{rateTier.value.end}}</span>
                                </div>
                            </div>
                            <div class="removeLineIconCol">
                                <span class="removeRateTierIcon clickable" (click)="removeRateTier(i, 'levelTwoRateTiers')">
                                    <i class="fa fa-times" *ngIf="tierThreeHasTierTwoDependency(rateTier, 1)"></i>
                                </span>
                            </div>
                        </div>
                        <!-- ==================================== TIER ORDER 3 RATE TEARZ ==================================== -->
                        <div class="ratedTiersFormRow borderBottomGray" formArrayName="levelThreeRateTiers" *ngIf="nonHistoricalExists('levelTwoRateTiers') && !visibleLineExists('levelThreeRateTiers')">
                            <span class="rateTiersIndexCol">
                                <span>3</span>
                            </span>
                            <div class="addTierIconCol">
                                <span class="clickable" (click)="addRateTierFormLine('levelThreeRateTiers')">
                                    <i class="fa fa-plus-circle blue"> </i>
                                </span>
                            </div>
                        </div>
                        <div class="ratedTiersFormRow borderBottomGray" formArrayName="levelThreeRateTiers" *ngFor="let rateTier of roleDetailForm['controls'].levelThreeRateTiers['controls']; let i = index;"
                            [ngClass]="{ 'hiddenHistoricalRateTier': !areHistoricalRateTiersDisplayed && rateTier.value.history, 'activeTier': isActiveTier(rateTier), 'historicalTier': isHistoricalTier(rateTier), 'invalidTier': !rateTier.value.history && !isValidTier(rateTier) }">
                            <span class="rateTiersIndexCol" [formGroupName]="i">
                                <span *ngIf="i === 0">3</span>
                            </span>
                            <div class="addTierIconCol">
                                <span class="clickable" *ngIf="i === 0 && nonHistoricalExists('levelTwoRateTiers')" (click)="addRateTierFormLine('levelThreeRateTiers')">
                                    <i class="fa fa-plus-circle blue"> </i>
                                </span>
                            </div>
                            <div class="tierLabelCol" [formGroupName]="i">
                                <input *ngIf="isNewTier(rateTier)" type="text" class="tierLabelValueInputTag" formControlName="tierLabel">
                                <span *ngIf="!isNewTier(rateTier)">{{rateTier.value.tierLabel}}</span>
                            </div>
                            <div class="tierMarkUpIDCol" [formGroupName]="i">
                                <div *ngIf="isNewTier(rateTier)" class="rolesRateTierRowSelectPanel clickableCaretExtension" (click)="showRateMarkupSearchModal(rateTier)">
                                    <div class="markupDescriptionContainer">
                                        <div class="markupDescriptionContents" *ngIf="getRateMarkupName(rateTier?.value.rateMarkupID)">
                                            <div class="markupName">
                                                {{ getRateMarkupName(rateTier?.value.rateMarkupID) }}
                                            </div>
                                            <div class="markupNameRemoveIcon" (click)="removeRateMarkupId($event, rateTier)">
                                                <i class="fa fa-times blue clickable"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="markupDescriptionSearchIcon blue clickable">
                                        <i aria-hidden="true" class="fa fa-search searchIcon"></i>
                                    </span>
                                </div>
                                <span class="blue clickable actionText" (click)="goToMarkup(rateTier?.value.rateMarkupID)" *ngIf="!isNewTier(rateTier)">{{ getRateMarkupName(rateTier?.value.rateMarkupID) }}</span>
                            </div>
                            <div class="fuelIdCol" [formGroupName]="i">
                                <div *ngIf="isNewTier(rateTier)" class="rolesRateTierRowSelectPanel clickableCaretExtension" (click)="showFuelMarkupSearchModal(rateTier)">
                                    <div class="markupDescriptionContainer">
                                        <div class="markupDescriptionContents" *ngIf="getFuelMarkupName(rateTier?.value.fuelMarkupID)">
                                            <div class="markupName">
                                                {{ getFuelMarkupName(rateTier?.value.fuelMarkupID) }}
                                            </div>
                                            <div class="markupNameRemoveIcon" (click)="removeFuelMarkupId($event, rateTier)">
                                                <i class="fa fa-times blue clickable"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="markupDescriptionSearchIcon blue clickable">
                                        <i aria-hidden="true" class="fa fa-search searchIcon"></i>
                                    </span>
                                </div>
                                <span class="blue clickable actionText" (click)="goToMarkup(rateTier?.value.fuelMarkupID)" *ngIf="!isNewTier(rateTier)">{{ getFuelMarkupName(rateTier?.value.fuelMarkupID) }}</span>
                            </div>
                            <div class="acclIdCol" [formGroupName]="i">
                                <div *ngIf="isNewTier(rateTier)" class="rolesRateTierRowSelectPanel clickableCaretExtension" (click)="showAcclMarkupSearchModal(rateTier)">
                                    <div class="markupDescriptionContainer">
                                        <div class="markupDescriptionContents" *ngIf="getAcclMarkupName(rateTier?.value.acclMarkupID)">
                                            <div class="markupName">
                                                {{ getAcclMarkupName(rateTier?.value.acclMarkupID) }}
                                            </div>
                                            <div class="markupNameRemoveIcon" (click)="removeAcclMarkupId($event, rateTier)">
                                                <i class="fa fa-times blue clickable"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="markupDescriptionSearchIcon blue clickable">
                                        <i aria-hidden="true" class="fa fa-search searchIcon"></i>
                                    </span>
                                </div>
                                <span class="blue clickable actionText" (click)="goToMarkup(rateTier?.value.acclMarkupID)" *ngIf="!isNewTier(rateTier)">{{ getAcclMarkupName(rateTier?.value.acclMarkupID) }}</span>
                            </div>
                            <div class="displayTierCol" [formGroupName]="i">
                                <span>
                                    <i *ngIf="!roleDetailForm['controls'].levelThreeRateTiers['controls'][i]['controls'].displayTier.value" class="iconSize clickable fa fa-square-o blue"
                                        aria-hidden="true" (click)="toggleRateTierDisplay(rateTier)"></i>
                                    <i *ngIf="roleDetailForm['controls'].levelThreeRateTiers['controls'][i]['controls'].displayTier.value" class="iconSize clickable fa fa-check-square blue"
                                        aria-hidden="true" (click)="toggleRateTierDisplay(rateTier)"></i>
                                </span>
                            </div>
                            <div class="dateRangeSelectionCol" [formGroupName]="i">
                                <div *ngIf="isNewTier(rateTier)" class="dateRangePanel" [ngClass]="{ 'dateRangeContradiction': rateTier &&
                                (
                                    (rateTier['controls'].effective && rateTier['controls'].effective.errors && rateTier['controls'].effective.errors.invalidDate) ||
                                    (rateTier['controls'].end && rateTier['controls'].end.errors && rateTier['controls'].end.errors.invalidDate)
                                ) }">
                                    <input type="text" class="dateRangeStart" formControlName="effective">
                                    <span class="dateRangeText">
                                        to
                                    </span>
                                    <input type="text" class="dateRangeEnd" formControlName="end">
                                    <span class="dateRangeIcon blue clickable" (click)="showDatePicker(rateTier)">
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div *ngIf="!isNewTier(rateTier)" class="dateRangePanelEndEditable">
                                    <span class="dateRangeStart">{{rateTier.value.effective}}</span>
                                    <span class="dateRangeText">
                                        to
                                    </span>
                                    <input *ngIf="!rateTier.value.history" type="text" class="dateRangeEnd" formControlName="end">
                                    <span *ngIf="!rateTier.value.history" class="dateRangeIcon blue clickable" (click)="showSingleDatePicker(rateTier)">
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </span>
                                    <span *ngIf="rateTier.value.history"  class="dateRangeEnd">{{rateTier.value.end}}</span>
                                </div>
                            </div>
                            <div class="removeLineIconCol">
                                <span class="removeRateTierIcon clickable" (click)="removeRateTier(i, 'levelThreeRateTiers')">
                                    <i class="fa fa-times" *ngIf="isNewTier(rateTier)"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="markupHistoryLinkSection">
                        <span class="blue clickable actionText" *ngIf="!areHistoricalRateTiersDisplayed && roleDetailForm && shouldDisplayMarkupLinkBeVisible()"
                            (click)="areHistoricalRateTiersDisplayed = !areHistoricalRateTiersDisplayed">
                            Display Markup History
                        </span>

                        <span class="blue clickable actionText" *ngIf="areHistoricalRateTiersDisplayed" (click)="areHistoricalRateTiersDisplayed = !areHistoricalRateTiersDisplayed">
                            Hide Markup History
                        </span>
                    </div>

                    <div class="roleQuotingDetailsSection">
                        <div class="roleQuotingLeftColumn">
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">
                                    Display Rate
                                </div>
                                <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('displayRates').setValue(!roleDetailForm.get('displayRates').value)">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].displayRates.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="!roleDetailForm['controls'].displayRates.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            No
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('displayRates').setValue(!roleDetailForm.get('displayRates').value)">
                                            <i class="fa fa-circle-o" *ngIf="!roleDetailForm['controls'].displayRates.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].displayRates.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Yes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">Shiprite Contract ID</div>
                                <div class="rolesQuotingRowSelectPanel clickableCaretExtension">
                                    <select class="rolesGeneralRowSelectTag" formControlName="APcontract">
                                        <option *ngFor="let customer of masterDataContractIdList" [value]="customer.customer">{{
                                            customer.customer }}</option>
                                    </select>
                                    <span class="rolesGeneralRowSelectCaret clickableCaret clickableCaretPositioning">
                                        <i class="fa fa-caret-down"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">No. of Carriers to Display</div>
                                <input class="roleQuotingInputField" formControlName="displayCarriers">
                            </div>
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">Exclude Carriers</div>
                                <input class="roleQuotingInputField" formControlName="excludeCarriers">
                            </div>
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">
                                    Include Guarantee Rates
                                </div>
                                <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('guaranteeRates').setValue(!roleDetailForm.get('guaranteeRates').value)">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].guaranteeRates.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="!roleDetailForm['controls'].guaranteeRates.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            No
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('guaranteeRates').setValue(!roleDetailForm.get('guaranteeRates').value)">
                                            <i class="fa fa-circle-o" *ngIf="!roleDetailForm['controls'].guaranteeRates.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].guaranteeRates.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Yes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">
                                    Require Cube
                                </div>
                                <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('requireCube').setValue(!roleDetailForm.get('requireCube').value)">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].requireCube.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="!roleDetailForm['controls'].requireCube.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            No
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('requireCube').setValue(!roleDetailForm.get('requireCube').value)">
                                            <i class="fa fa-circle-o" *ngIf="!roleDetailForm['controls'].requireCube.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].requireCube.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Yes
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="roleQuotingRightColumn">
                            <div class="rolesGeneralRow quotingTripleRow">
                                <div class="rolesGeneralRowLabel quotingTripleLabel">
                                    Cubic Feet Limit
                                </div>
                                <div class="rolesGeneralRowField rolesGeneralRowRadioPanel quotingTriplePanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('limitCube').setValue(!roleDetailForm.get('limitCube').value)">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].limitCube.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="!roleDetailForm['controls'].limitCube.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            No
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('limitCube').setValue(!roleDetailForm.get('limitCube').value)">
                                            <i class="fa fa-circle-o" *ngIf="!roleDetailForm['controls'].limitCube.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].limitCube.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Yes
                                        </span>
                                    </div>
                                    <input class="quotingTripleInput" formControlName="limitCubeValue">
                                </div>
                            </div>
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">
                                    Commodity Check
                                </div>
                                <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('requireCommodity').setValue(!roleDetailForm.get('requireCommodity').value)">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].requireCommodity.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="!roleDetailForm['controls'].requireCommodity.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            No
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('requireCommodity').setValue(!roleDetailForm.get('requireCommodity').value)">
                                            <i class="fa fa-circle-o" *ngIf="!roleDetailForm['controls'].requireCommodity.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].requireCommodity.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Yes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">
                                    Show VLS CFS List
                                </div>
                                <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('showVLSCFS').setValue(!roleDetailForm.get('showVLSCFS').value)">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].showVLSCFS.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="!roleDetailForm['controls'].showVLSCFS.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            No
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('showVLSCFS').setValue(!roleDetailForm.get('showVLSCFS').value)">
                                            <i class="fa fa-circle-o" *ngIf="!roleDetailForm['controls'].showVLSCFS.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].showVLSCFS.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Yes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">
                                    Show Carrier KPI
                                </div>
                                <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('showCarrierKPI').setValue(!roleDetailForm.get('showCarrierKPI').value)">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].showCarrierKPI.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="!roleDetailForm['controls'].showCarrierKPI.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            No
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('showCarrierKPI').setValue(!roleDetailForm.get('showCarrierKPI').value)">
                                            <i class="fa fa-circle-o" *ngIf="!roleDetailForm['controls'].showCarrierKPI.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].showCarrierKPI.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Yes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">
                                    Final Mile Service
                                </div>
                                <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('finalMile').setValue(!roleDetailForm.get('finalMile').value)">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].finalMile.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="!roleDetailForm['controls'].finalMile.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            No
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('finalMile').setValue(!roleDetailForm.get('finalMile').value)">
                                            <i class="fa fa-circle-o" *ngIf="!roleDetailForm['controls'].finalMile.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].finalMile.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Yes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">
                                    Display Rated Class
                                </div>
                                <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('ratedClass').setValue(!roleDetailForm.get('ratedClass').value)">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].ratedClass.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="!roleDetailForm['controls'].ratedClass.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            No
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('ratedClass').setValue(!roleDetailForm.get('ratedClass').value)">
                                            <i class="fa fa-circle-o" *ngIf="!roleDetailForm['controls'].ratedClass.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].ratedClass.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Yes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">
                                    SpotRite
                                </div>
                                <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('spotRite').setValue(!roleDetailForm.get('spotRite').value)">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].spotRite.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="!roleDetailForm['controls'].spotRite.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            No
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('spotRite').setValue(!roleDetailForm.get('spotRite').value)">
                                            <i class="fa fa-circle-o" *ngIf="!roleDetailForm['controls'].spotRite.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].spotRite.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Yes
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- =========================================================================== -->
            <!-- =========================== ROLES SHIPMENTS SECTION =========================== -->
            <!-- =========================================================================== -->
            <div *ngIf="activeRoleTabSection === roleTabSections.shipments" class="roleShipmentsContainer">
                <div class="rolesShipmentLeftColumn">
                    <div class="rolesGeneralRow">
                        <div class="rolesGeneralRowLabel">
                            Execution Action (Show in SROD)
                        </div>
                        <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                            <app-radio-group class="rolesGeneralRowField rolesRadioGroup" [allowClear]="false" [align]="'center'" [justify]="'flex-start'" [values]="yesNoRadioValues" formControlName="executeShipment" ></app-radio-group>
                        </div>
                    </div>
                    <div class="rolesGeneralRow">
                        <div class="rolesGeneralRowLabel">
                            Place To Print BOL
                        </div>
                        <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                            <app-radio-group class="rolesGeneralRowField rolesRadioGroup" [allowClear]="false" [align]="'center'" [justify]="'flex-start'" [values]="yesNoRadioValues" formControlName="placeToPrintBOL" ></app-radio-group>
                        </div>
                    </div>
                    <div class="rolesGeneralRow">
                        <div class="rolesGeneralRowLabel">
                            Show Forwarder Fields
                        </div>
                        <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                            <app-radio-group class="rolesGeneralRowField rolesRadioGroup" [allowClear]="false" [align]="'center'" [justify]="'flex-start'" [values]="yesNoRadioValues" formControlName="forwarderFields" ></app-radio-group>
                        </div>
                    </div>
                    <div class="rolesGeneralRow">
                        <div class="roleShipmentRowLabel">Auto Clear Shipments (days)</div>
                        <div class="roleShipmentRowSelectPanel">
                            <select class="rolesShipmentRowSelectTag" formControlName="autoClearShipments">
                                <option *ngFor="let clearShipmentOption of autoClearShipmentsOptions" [ngValue]="clearShipmentOption">{{
                                    clearShipmentOption }}</option>
                            </select>
                            <span class="rolesGeneralRowSelectCaret clickableCaret clickableCaretPositioning">
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="rolesShipmentMiddleColumn">
                    <div class="rolesGeneralRow">
                        <div class="rolesGeneralRowLabel">
                            Insurance
                        </div>
                        <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                            <app-radio-group class="rolesGeneralRowField rolesRadioGroup" [allowClear]="false" [align]="'center'" [justify]="'flex-start'" [values]="yesNoRadioValues" formControlName="insurance" ></app-radio-group>
                        </div>
                    </div>
                    <div class="rolesGeneralRow">
                        <div class="rolesGeneralRowLabel">
                            Credit Card Payment
                        </div>
                        <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                            <app-radio-group class="rolesGeneralRowField rolesRadioGroup" [allowClear]="false" [align]="'center'" [justify]="'flex-start'" [values]="yesNoRadioValues" formControlName="creditCard" ></app-radio-group>
                        </div>
                    </div>
                    <div class="rolesGeneralRow">
                        <div class="rolesGeneralRowLabel">
                            Lock Bill To
                        </div>
                        <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                            <app-radio-group class="rolesGeneralRowField rolesRadioGroup" [allowClear]="false" [align]="'center'" [justify]="'flex-start'" [values]="yesNoRadioValues" formControlName="lockBillTo" ></app-radio-group>
                        </div>
                    </div>
                </div>
                <div class="rolesShipmentRightColumn">
                    <div class="rolesGeneralRow">
                        <div class="rolesGeneralRowLabel">
                            Lite-Carrier Auto Tender On
                        </div>
                        <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                            <app-radio-group class="rolesGeneralRowField rolesRadioGroup" [allowClear]="false" [align]="'center'" [justify]="'flex-start'" [values]="yesNoRadioValues" formControlName="autoTender" ></app-radio-group>
                        </div>
                    </div>
                    <div class="rolesGeneralRow">
                        <div class="rolesGeneralRowLabel">
                            Enable SROD-Tracking Accessorial
                        </div>
                        <div class="rolesGeneralRowField rolesGeneralRowRadioPanel">
                            <app-radio-group class="rolesGeneralRowField rolesRadioGroup" [allowClear]="false" [align]="'center'" [justify]="'flex-start'" [values]="yesNoRadioValues" formControlName="srodTrackingAccl" ></app-radio-group>
                        </div>
                    </div>

                </div>
            </div>
            <!-- =========================================================================== -->
            <!-- =========================== ROLES DEFAULTS SECTION =========================== -->
            <!-- =========================================================================== -->
            <div *ngIf="activeRoleTabSection === roleTabSections.defaultsAndDisclaimer" class="roleDefaultsAndDisclaimerContainer">
                <div class="defaultRecordContainer borderBottomGray">
                    <div class="defaultsSectionHeader">Default User Settings</div>
                    <div class="defaultRecordColumnContainer">
                        <div class="roleDefaultsLeftColumn">
                            <div class="roleDefaultSectionRow">
                                <div class="roleDefaultsLabel">Default Record Tab</div>
                                <div class="userDefaultsFormSelectPanel">
                                    <select class="userDefaultsFormSelectEntry" formControlName="defaultRecordTab">
                                        <option *ngFor="let rcTab of recordCreationTabOptions" [value]="rcTab">{{ rcTab }}</option>
                                    </select>
                                    <span class="userDefaultsFormSelectCaret clickableCaret">
                                        <i class="fa fa-caret-down"></i>
                                    </span>
                                </div>
                            </div>

                            <div class="roleDefaultSectionRow" [formGroupName]="'defaultShipper'">
                                <div class="roleDefaultsLabel">Shipper</div>
                                <div class="roleDefaultsValuePanel">
                                    <input formControlName="defaultContactDisplay">
                                </div>
                                <span class="userDefaultsFormSelectCaret clickable">
                                    <i aria-hidden="true" class="fa fa-search searchIcon" (click)="showContactModal(searchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER)"></i>
                                </span>
                            </div>
                            <div class="roleDefaultSectionRow">
                                <div class="roleDefaultsLabel">Origin Zip Code</div>
                                <div class="roleDefaultsValuePanel">
                                    <input formControlName="defaultOriginZip">
                                </div>
                                <span class="userDefaultsFormSelectCaret clickable">
                                    <i aria-hidden="true" class="fa fa-search searchIcon" (click)="showZipSearchModal('origin')"></i>
                                </span>
                            </div>
                            <div class="roleDefaultSectionRow">
                                <div class="roleDefaultsLabel">Origin Country</div>
                                <div class="userDefaultsFormSelectPanel">
                                    <select class="userDefaultsFormSelectEntry" formControlName="defaultOriginCountry">
                                        <option *ngFor="let c of countries" [value]="c.countryCode">{{ c.countryDesc }}</option>
                                    </select>
                                    <span class="userDefaultsFormSelectCaret clickableCaret">
                                        <i class="fa fa-caret-down"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="roleDefaultSectionRow">
                                <div class="rolesGeneralRowLabel">
                                    Origin Type
                                </div>
                                <div class="rolesDefaultsRowField rolesDefaultsRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.controls.defaultOriginType.setValue('R')">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].defaultOriginType.value !== 'R'"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].defaultOriginType.value === 'R'"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Residential
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.controls.defaultOriginType.setValue('C')">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].defaultOriginType.value !== 'C'"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].defaultOriginType.value === 'C'"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Commercial
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="roleDefaultSectionRow">
                                <div class="roleDefaultsLabel">Weight</div>
                                <input class="roleDefaultsField" type="text" formControlName="defaultWeight">
                            </div>
                            <div class="roleDefaultSectionRow">
                                <div class="roleDefaultsLabel">Unit of Measure</div>
                                <div class="userDefaultsFormSelectPanel">
                                    <select class="userDefaultsFormSelectEntry" formControlName="defaultUoM">
                                        <option *ngFor="let uom of unitOfMeasures" [value]="uom?.unitOfMeasureCode">{{ uom.unitOfMeasure
                                            }}
                                        </option>
                                    </select>
                                    <span class="userDefaultsFormSelectCaret clickableCaret">
                                        <i class="fa fa-caret-down"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="roleDefaultSectionRow">
                                <div class="rolesGeneralRowLabel">
                                    Direction
                                </div>

                                <div class="rolesDefaultsRowField rolesDefaultsRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.controls.defaultDirection.setValue('inbound')">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].defaultDirection.value !== 'inbound'"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].defaultDirection.value === 'inbound'"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Inbound
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.controls.defaultDirection.setValue('outbound')">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].defaultDirection.value !== 'outbound'"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].defaultDirection.value === 'outbound'"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Outbound
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="roleDefaultsRightColumn">
                            <div class="roleDefaultSectionRow">
                                <div class="roleDefaultsLabel">Class</div>
                                <div class="userDefaultsFormSelectPanel">
                                    <select class="userDefaultsFormSelectEntry" formControlName="defaultClass">
                                        <option [value]="0">N/A</option>
                                        <option *ngFor="let c of nmfcClasses" [value]="c.class">{{ c.displayClass }}</option>
                                    </select>
                                    <span class="userDefaultsFormSelectCaret clickableCaret">
                                        <i class="fa fa-caret-down"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="roleDefaultSectionRow" [formGroupName]="'defaultConsignee'">
                                <div class="roleDefaultsLabel">Consignee</div>
                                <div class="roleDefaultsValuePanel">
                                    <input formControlName="defaultContactDisplay">
                                </div>
                                <span class="userDefaultsFormSelectCaret clickable">
                                    <i aria-hidden="true" class="fa fa-search searchIcon" (click)="showContactModal(searchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE)"></i>
                                </span>
                            </div>
                            <div class="roleDefaultSectionRow">
                                <div class="roleDefaultsLabel">Destination Zip Code</div>
                                <div class="roleDefaultsValuePanel">
                                    <input formControlName="defaultDestZip">
                                </div>
                                <span class="userDefaultsFormSelectCaret clickable">
                                    <i aria-hidden="true" class="fa fa-search searchIcon" (click)="showZipSearchModal('destination')"></i>
                                </span>
                            </div>
                            <div class="roleDefaultSectionRow">
                                <div class="roleDefaultsLabel">Destination Country</div>
                                <div class="userDefaultsFormSelectPanel">
                                    <select class="userDefaultsFormSelectEntry" formControlName="defaultDestCountry">
                                        <option *ngFor="let c of countries" [value]="c.countryCode">{{ c.countryDesc }}</option>
                                    </select>
                                    <span class="userDefaultsFormSelectCaret clickableCaret">
                                        <i class="fa fa-caret-down"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="roleDefaultSectionRow">
                                <div class="rolesGeneralRowLabel">
                                    Destination Type
                                </div>
                                <div class="rolesDefaultsRowField rolesDefaultsRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.controls.defaultDestType.setValue('R')">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].defaultDestType.value !== 'R'"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].defaultDestType.value === 'R'"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Residential
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.controls.defaultDestType.setValue('C')">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].defaultDestType.value !== 'C'"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].defaultDestType.value === 'C'"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Commercial
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="roleDefaultSectionRow">
                                <div class="rolesGeneralRowLabel">
                                    TSA Only
                                </div>
                                <span *ngIf="!roleDetailForm['controls'].defaultTSA.value" class="defaultTSAIcon clickable" (click)="roleDetailForm.controls.defaultTSA.setValue(currentTsaValue = !currentTsaValue)">
                                    <i class="fa fa-square-o uncheckedBox" aria-hidden="true "></i>
                                </span>
                                <span *ngIf="roleDetailForm['controls'].defaultTSA.value" class="defaultTSAIcon clickable" (click)="roleDetailForm.controls.defaultTSA.setValue(currentTsaValue = !currentTsaValue)">
                                    <i class="fa fa-check-square checkedBox" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">Auto Save Contacts</div>
                                <div class="toggleIconPanel">
                                    <span class="toggleLabel">No</span>
                                    <span *ngIf="!roleDetailForm['controls'].autoSaveContacts.value" class="autoSaveContactsToggleIcon clickable">
                                        <i class="fa fa-toggle-off blue clickable" aria-hidden="true" (click)="roleDetailForm.controls.autoSaveContacts.setValue(true)"></i>
                                    </span>
                                    <span *ngIf="roleDetailForm['controls'].autoSaveContacts.value" class="autoSaveContactsToggleIcon clickable">
                                        <i class="fa fa-toggle-on blue clickable" aria-hidden="true" (click)="roleDetailForm.controls.autoSaveContacts.setValue(false)"></i>
                                    </span>
                                    <span class="toggleLabel">Yes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="defaultDisclaimerContainer">
                    <div class="defaultsSectionHeader">Default Role Settings</div>
                    <div class="defaultDisclaimerColumnContainer">
                        <div class="roleDefaultsLeftColumn">
                            <div class="rolesGeneralRow">
                                <div class="rolesGeneralRowLabel">
                                    Edit Default Values
                                </div>
                                <div class="rolesDefaultsRowField rolesDefaultsRowRadioPanel">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('editDefaults').setValue(!roleDetailForm.get('editDefaults').value)">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].editDefaults.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="!roleDetailForm['controls'].editDefaults.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            No
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('editDefaults').setValue(!roleDetailForm.get('editDefaults').value)">
                                            <i class="fa fa-circle-o" *ngIf="!roleDetailForm['controls'].editDefaults.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].editDefaults.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Yes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="rolesGeneralRow">
                                    <div class="rolesGeneralRowLabel">
                                        Payment Terms
                                    </div>
                                    <div class="rolesDefaultsRowField rolesDefaultsRowRadioPanel">
                                        <div class="rolesGeneralRowRadioOption">
                                            <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm['controls'].paymentTerms.setValue(paymentTerms.prepaid)">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].paymentTerms.value !== paymentTerms.prepaid"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].paymentTerms.value === paymentTerms.prepaid"></i>
                                            </span>
                                            <span class="rolesGeneralRadioButtonLabel">
                                                Prepaid
                                            </span>
                                        </div>
                                        <div class="rolesGeneralRowRadioOption">
                                            <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm['controls'].paymentTerms.setValue(paymentTerms.collect)">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].paymentTerms.value !== paymentTerms.collect"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].paymentTerms.value === paymentTerms.collect"></i>
                                            </span>
                                            <span class="rolesGeneralRadioButtonLabel">
                                                Collect
                                            </span>
                                        </div>
                                        <div class="rolesGeneralRowRadioOption">
                                            <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm['controls'].paymentTerms.setValue(paymentTerms.thirdParty)">
                                                <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].paymentTerms.value !== paymentTerms.thirdParty"></i>
                                                <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].paymentTerms.value === paymentTerms.thirdParty"></i>
                                            </span>
                                            <span class="rolesGeneralRadioButtonLabel">
                                                Third Party
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="roleDefaultSectionRow" *ngIf="!roleDetailForm['controls'].roleCustomer.value">
                                    <div class="roleDefaultsLabel">Default Bill To</div>
                                    <select class="userDefaultsFormSelectEntry" name="thirdPartyBillToSelected" formControlName="defaultBillTo">
                                        <option value="">&lt;None&gt;</option>
                                        <option *ngFor="let tpbt of thirdPartyBillToDDL" [value]="tpbt.id" [selected]="tpbt.id === thirdPartyBillToSelected">{{
                                            tpbt.name }}</option>
                                    </select>
                                    <span class="userDefaultsFormSelectCaret clickableCaret">
                                        <i class="fa fa-caret-down"></i>
                                    </span>
                                </div>

                                <div class="roleDefaultSectionRow" *ngIf="roleDetailForm['controls'].roleCustomer.value">
                                    <div class="roleDefaultsLabel">Default Bill To</div>
                                    <select class="userDefaultsFormSelectEntry" name="thirdPartyBillToSelected" formControlName="defaultBillTo">
                                        <option value="">&lt;None&gt;</option>
                                        <option *ngFor="let contact of roleCustomerBillToContacts" [value]="contact.id">{{
                                            contact.name }}</option>
                                    </select>
                                    <span class="userDefaultsFormSelectCaret clickableCaret">
                                        <i class="fa fa-caret-down"></i>
                                    </span>
                                </div>

                                <div class="roleDefaultSectionRow">
                                    <div class="roleDefaultsLabel">Record Display Style</div>
                                    <div class="userDefaultsFormSelectPanel">
                                        <select class="userDefaultsFormSelectEntry" formControlName="preferredRecordDisplayStyle">
                                            <option *ngFor="let style of displayStyleOptions" [value]="style">{{ style }}</option>
                                        </select>
                                        <span class="userDefaultsFormSelectCaret clickableCaret">
                                            <i class="fa fa-caret-down"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="roleDefaultsRightColumn">
                            <div class="rolesGeneralRow" *ngIf="isAdminInternal">
                                <div class="rolesGeneralRowLabel">
                                    Hide Quote/Shipment Disclaimer
                                </div>
                                <div class="rolesDefaultsRowField rolesDefaultsRowRadioPanel" *ngIf="isAdminInternal">
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('hideQuoteDisclaimer').setValue(!roleDetailForm.get('hideQuoteDisclaimer').value)">
                                            <i class="fa fa-circle-o" *ngIf="roleDetailForm['controls'].hideQuoteDisclaimer.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="!roleDetailForm['controls'].hideQuoteDisclaimer.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            No
                                        </span>
                                    </div>
                                    <div class="rolesGeneralRowRadioOption">
                                        <span class="rolesGeneralRadioButton clickable" (click)="roleDetailForm.get('hideQuoteDisclaimer').setValue(!roleDetailForm.get('hideQuoteDisclaimer').value)">
                                            <i class="fa fa-circle-o" *ngIf="!roleDetailForm['controls'].hideQuoteDisclaimer.value"></i>
                                            <i class="fa fa-dot-circle-o" *ngIf="roleDetailForm['controls'].hideQuoteDisclaimer.value"></i>
                                        </span>
                                        <span class="rolesGeneralRadioButtonLabel">
                                            Yes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rolesGeneralRow" *ngIf="isAdminInternal">
                                <div class="roleDefaultsTextAreaLabel">Quote/Shipment Disclaimer Override</div>
                                <textarea class="roleDefaultsTextArea" formControlName="quoteDisclaimer"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<app-select-contact-modal *ngIf="isSelectContactModalShown" [contactType]="contactType" [contactField]="contactField" [contactInput]="contactInput"
    (updateContactInfo)="hideContactModal($event)" [contactInput]="contactInput"></app-select-contact-modal>
<app-zip-search-modal *ngIf="isZipSearchModalShown" [zipType]="zipSearchModalType" (closeZipModal)="hideZipSearchModal($event)"></app-zip-search-modal>
<app-roles-customer-modal *ngIf='isCustomerRolesModalShown' (closeCustomerRolesModal)="isCustomerRolesModalShown = false" [adminRolesCustomersList]="masterDataCustomersList"
    (selectCustomer)="roleDetailForm.controls.roleCustomer.setValue($event.custNo)"></app-roles-customer-modal>
<app-fuel-markup-search-modal *ngIf="isFuelMarkupSearchModalShown" (closeFuelMarkupSearchModal)="isFuelMarkupSearchModalShown = false"
    (passFuelMarkupOption)="handleSelectedFuelMarkupOption($event)"></app-fuel-markup-search-modal>
<app-rate-markup-search-modal *ngIf="isRateMarkupSearchModalShown" (closeRateMarkupSearchModal)="isRateMarkupSearchModalShown = false"
    (passRateMarkupOption)="handleSelectedRateMarkupOption($event)"></app-rate-markup-search-modal>
<app-accl-markup-search-modal *ngIf="isAcclMarkupSearchModalShown" (closeAcclMarkupSearchModal)="isAcclMarkupSearchModalShown = false"
                              (passAcclMarkupOption)="handleSelectedAcclMarkupOption($event)"></app-accl-markup-search-modal>

<app-ok-cancel-modal *ngIf="isLoseChangesWarningModalVisible" [title]="'Lose Changes'" [message]="'You will lose all changes if your proceed.  Click Cancel to prevent this.'"
                     [okPayload]="okPayload" (onOk)="goToMarkup($event)" (onCancel)="isLoseChangesWarningModalVisible = false"></app-ok-cancel-modal>
<app-ok-cancel-modal *ngIf="isDisplayTierSelectionWarningModalVisible" [title]="'Missing DisplayTier'" [message]="'You have not selected a Display Tier. Net rates will be displayed to the customer.'"
                     (onOk)="saveChanges(true)" (onCancel)="isDisplayTierSelectionWarningModalVisible = !isDisplayTierSelectionWarningModalVisible"></app-ok-cancel-modal>
<app-ok-cancel-modal *ngIf="isGapsExistWarningModalVisible" [title]="'Markup Date Gap Exists'" [message]="'Markup coverage date gaps exist in one of the tier levels.  Press OK to save anyway, CANCEL otherwise.'"
                     (onOk)="saveChanges(true, true)" (onCancel)="isGapsExistWarningModalVisible = false"></app-ok-cancel-modal>
<app-ok-cancel-modal *ngIf="isTierThreeNeedsTwoWarningModalVisible" [title]="'Tier Dependency Warning'" [message]="'There is a tier 3 markup without a tier 2 markup.  Press OK to save anyway, CANCEL otherwise.'"
                     (onOk)="saveChanges(true, true, true)" (onCancel)="isTierThreeNeedsTwoWarningModalVisible = false"></app-ok-cancel-modal>

<app-solicit-template-name-modal *ngIf="isSolicitTemplateNameModalShown"
                                 (closeSolicitTemplateNameModal)="closeSolicitTemplateNameModal($event)"></app-solicit-template-name-modal>

<app-custom-datepicker *ngIf="isDatePickerShown" [isDateRangePossible]="true" [title]="'Select Date Range'" [dateFormatPattern]="rateTierDisplayDateFormat"
                       [inputDate]="selectedRateTierFormGroup.value.effective" [inputEndDate]="selectedRateTierFormGroup.value.end" [isDateRangeOnly]="true"
                       (closeDatePicker)="hideDatePicker($event)" [dateFormatValidationPattern]="rateTierDisplayDateValidation"></app-custom-datepicker>
<app-custom-datepicker *ngIf="isSingleDatePickerShown" [isDateRangePossible]="false" [title]="'Select Date'" [dateFormatPattern]="rateTierDisplayDateFormat"
                       [inputDate]="selectedRateTierFormGroup.value.end" [allowSingleIndefinately]="true"
                       (closeDatePicker)="hideSingleDatePicker($event)" [dateFormatValidationPattern]="rateTierDisplayDateValidation"></app-custom-datepicker>
