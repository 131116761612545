import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-icon-selector-modal',
  templateUrl: './icon-selector-modal.component.html',
  styleUrls: ['./icon-selector-modal.component.scss']
})

export class IconSelectorModalComponent implements OnInit, OnDestroy {
  @Input() public fontAwesomeIcons: Array<{ className: string, displayName: string }> = [];
  @Output() public closeIconSelectorModal = new EventEmitter();
  @Output() public passIconToViewCreation = new EventEmitter();

  public iconListValueChangesSubscription: any;
  public filteredIcons: Array<{ className: string, displayName: string }> = [];
  public iconSelectorForm: UntypedFormGroup;

  constructor(
    private _fb: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.filteredIcons = this.fontAwesomeIcons;

    this.iconSelectorForm = this._fb.group({
      filterTerm: ''
    });

    this.iconListValueChangesSubscription = this.iconSelectorForm.valueChanges.subscribe(val => {
      this.updateFilteredValues();
    });

  }

  ngOnDestroy() {
    this.iconListValueChangesSubscription.unsubscribe();
  }

  public closeModal() {
    this.closeIconSelectorModal.emit('');
  }

  public selectIcon(icon: { className: string, displayName: string }): void {
    this.passIconToViewCreation.emit(icon);
    this.closeModal();
  }

  public updateFilteredValues() {
    const filterTerm = this.iconSelectorForm.get('filterTerm').value;
    const filteredResults = [];
    const searchPattern = new RegExp(filterTerm, 'i');

    this.fontAwesomeIcons.forEach(icon => {
      if (icon.displayName.match(searchPattern)) {
        filteredResults.push(icon);
      }
    });

    this.filteredIcons = filteredResults;
  }

}
