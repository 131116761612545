<ng-container *ngIf="!isCtns()">
    <div class="serviceColumn">{{getService()}}</div>

    <div class="guarenteeColumn">
        <span class="fa-stack fa-xs guarenteeFontSize" *ngIf="isGuarenteedDelivery()">
            <i class="fa fa-certificate fa-stack-2x guaranteedOuterIcon currentOuterIcon"></i>
            <i class="fa fa-check fa-stack-1x guaranteedInnerIcon" aria-hidden="true"></i>
        </span>
    </div>

    <div class="timeTransitColumn">{{getTimeInTransit()}}</div>

    <div class="densityColumn">{{densityCarrier() ? 'Y' : 'N'}}</div>

    <div *ngIf="isRatedClassShown" class="classColumn">{{densityClass()}}</div>

    <div class="typeColumn">{{serviceType()}}</div>

    <div class="currencyColumn">{{currency}}</div>

    <div class="lineHaulColumn">{{lineHaul | currency:currency:'symbol'}}</div>

    <div class="fuelColumn">{{fuel | currency:currency:'symbol'}}</div>

    <div class="extraColumn">{{extra | currency:currency:'symbol'}}</div>

    <div class="finalColumn">{{final | currency:currency:'symbol'}}</div>
</ng-container>
<ng-container *ngIf="isCtns()">
    <div class="srodColumn">
        <span>Please email <a href="mailto:livequotes@odysseylogistics.com">livequotes&#64;odysseylogistics.com</a> for a spot market rate.</span>
    </div>
</ng-container>
