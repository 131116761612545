
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {throwError as observableThrowError, Observable} from 'rxjs';
import {catchError, map, skipWhile, switchMap} from 'rxjs/operators';

import * as Constants from '../constants/constants';
import { getRootCauseErrorMessage } from '../helpers/utilities';
import { environment } from '../../environments/environment';
import { LoggingService, LogLevels } from './logging.service';
import {LogoutService} from "./logout.service";
import {fromPromise} from "rxjs/internal-compatibility";
import {SignalsService} from "./signals.service";
import {NotificationService} from "./notification.service";

// declare Angular HTTP RequestType constants for callers
export const REQUEST_TYPE_GET = 'GET';
export const REQUEST_TYPE_POST = 'POST';
export const REQUEST_TYPE_PUT = 'PUT';
export const REQUEST_TYPE_DELETE = 'DELETE';

@Injectable()
export class ApiService {

    private apiBaseUrl: string = environment.apiBaseUrl;
    private _logoutService: LogoutService;

    constructor(
        private _httpClient: HttpClient,
        private _loggingService: LoggingService,
        private _notificationService: NotificationService,
        private _signalsService: SignalsService,
        private _injector: Injector,
    ) {
    }

    private isJwtError(res: any) {
        if (!this._logoutService) {
            this._logoutService = this._injector.get(LogoutService);
        }
        if (!res.isValid && res.messages && res.messages[0] && res.messages[0].messageType === 'jwtAuthError') {
            if (this._logoutService) {
                if (!this._signalsService.loggedOutSignal()) {
                    this._notificationService.notifyWarning({ title: `Session Expired`, message: `Please log back in again.` });
                }
                this._logoutService.logout();
            }
            return true;
        }
        return false;
    }

    public callApiService<T>(req: HttpRequest<any>, shouldBlock?: boolean): Observable<T> {
        this._loggingService.sendLogMessage(LogLevels.DEBUG, `Entered ApiService.callApiService => ${JSON.stringify(req)})`);
        this._signalsService.startLoadingBar(shouldBlock);
        let response;
        switch (req.method) {
            case REQUEST_TYPE_GET:
                response = this._httpClient.get<T>(`${this.apiBaseUrl}/${req.url}`, { headers: this.addAuthTokenHeader(this.jsonHeaders()) }).pipe(
                    map(res => {
                        this._signalsService.stopLoadingBar();
                        return res;
                    }),
                    skipWhile(res => this.isJwtError(res)),
                    catchError(err => {
                        this._signalsService.stopLoadingBar();
                        this._loggingService.sendLogMessage(LogLevels.ERROR, this.getLoggableErrorMessage({ req, err }));
                        return observableThrowError(err);
                    })
                );
                break;
            case REQUEST_TYPE_POST:
                response = this._httpClient.post<T>(`${this.apiBaseUrl}/${req.url}`, req.body, { headers: this.addAuthTokenHeader() }).pipe(
                    map(res => {
                        this._signalsService.stopLoadingBar();
                        return res;
                    }),
                    skipWhile(res => this.isJwtError(res)),
                    catchError(err => {
                        this._signalsService.stopLoadingBar();
                        this._loggingService.sendLogMessage(LogLevels.ERROR, this.getLoggableErrorMessage({ req, err }));
                        return observableThrowError(err);
                    })
                );
                break;
            case REQUEST_TYPE_PUT:
                response = this._httpClient.put<T>(`${this.apiBaseUrl}/${req.url}`, req.body, { headers: this.addAuthTokenHeader() }).pipe(
                    map(res => {
                        this._signalsService.stopLoadingBar();
                        return res;
                    }),
                    skipWhile(res => this.isJwtError(res)),
                    catchError(err => {
                        this._signalsService.stopLoadingBar();
                        this._loggingService.sendLogMessage(LogLevels.ERROR, this.getLoggableErrorMessage({ req, err }));
                        return observableThrowError(err);
                    })
                );
                break;
            case REQUEST_TYPE_DELETE:
                response = this._httpClient.delete<T>(`${this.apiBaseUrl}/${req.url}`, { headers: this.addAuthTokenHeader() }).pipe(
                    map(res => {
                        this._signalsService.stopLoadingBar();
                        return res;
                    }),
                    skipWhile(res => this.isJwtError(res)),
                    catchError(err => {
                        this._signalsService.stopLoadingBar();
                        this._loggingService.sendLogMessage(LogLevels.ERROR, this.getLoggableErrorMessage({ req, err }));
                        return observableThrowError(err);
                    })
                );
                break;
            default:
                throw new Error(`invalid value provided for RequestType => [${req.method}]`);
        }
        return response;
    }

    public callApiServiceBinary(req: HttpRequest<any>, shouldBlock?: boolean): Observable<Blob> {
        let origRes;
        this._loggingService.sendLogMessage(LogLevels.DEBUG, `Entered ApiService.callApiServiceBinary => ${JSON.stringify(req)})`);
        this._signalsService.startLoadingBar(shouldBlock);

        // The back end if failing because of expired JWT token sends back a JWT error as a 200 response type with JSON
        // in body, but we are getting a stream of bytes from the _httpClient.get, so first we store the response to a
        // local variable once it is received and then try to get the text value from that out of the promise.  The
        // text value is then attempted to be parsed into JSON, and if that is successful, it is checked to see if it
        // is a JWT error and if that is the case, we skip the response and are sent to login page.  If on the other
        // hand the parsing fails, or it isn't a JWT Error, then we continue and return the blob out of the original
        // response's body.
        const response = this._httpClient.get(`${this.apiBaseUrl}/${req.url}`,
            {responseType: 'blob', observe: 'response', headers: this.addAuthTokenHeader() }).pipe(
            switchMap((res) => {
                origRes = res;
                return fromPromise(res.body.text());
            }),
            skipWhile((textBody) => {
                this._signalsService.stopLoadingBar();
                try {
                    console.log(textBody);
                    let resp = JSON.parse(textBody);
                    if (this.isJwtError(resp)) {
                        return true;
                    }
                } catch (e) {
                    console.log(e);
                }
                return false;
            }),
            map(textBody => {
                this._signalsService.stopLoadingBar();
                return origRes.body;
            }),
            catchError(err => {
                this._signalsService.stopLoadingBar();
                this._loggingService.sendLogMessage(LogLevels.ERROR, this.getLoggableErrorMessage({ req, err }));
                return observableThrowError(err);
            })
        );
        return response;
    }

    private getLoggableErrorMessage<T>({ req, err }: { req: HttpRequest<T>, err: HttpErrorResponse }): string {
        const errMsg = getRootCauseErrorMessage(err);
        return `Error occurred in ApiService.callApiService(${req.method}, ${this.apiBaseUrl}/${req.url}, ${JSON.stringify(req.headers)}, ${err.message}) => ${errMsg}`;
    }

    /* for further reference, see RFC6750 => https://tools.ietf.org/html/rfc6750 */
    private addAuthTokenHeader(headers?: HttpHeaders): HttpHeaders {
        if (!headers) {
            headers = new HttpHeaders();
        }
        const token = localStorage.getItem(Constants.USER_TOKEN_KEY);
        if (token) {
            headers = headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    }

    private jsonHeaders(): HttpHeaders {
        const headers: HttpHeaders = new HttpHeaders()
            .set('Pragma', 'no-cache')
            .set('Cache-Control', 'no-cache')
            .set('Cache-Control', 'no-store');
        return headers;
    }

}
