<div class="stepper-wrapper">
    <ng-template #defaultStepLabelRenderer let-i>
        {{getStepLabel(i)}}
    </ng-template>
    <ng-template #defaultCircleTextRenderer let-i>
        {{getCircleText(i)}}
    </ng-template>
    <div [ngClass]="getClass(i)" class="stepper-item" *ngFor="let step of steps; let i = index">
        <div *ngIf="showLabels && labelsTop" class="step-name">
            <ng-container [ngTemplateOutlet]="stepLabelRenderer || defaultStepLabelRenderer" [ngTemplateOutletContext]="{$implicit: i}" ></ng-container>
        </div>
        <div class="step-counter">
            <span *ngIf="showCircleText">
                <ng-container [ngTemplateOutlet]="circleTextRenderer || defaultCircleTextRenderer" [ngTemplateOutletContext]="{$implicit: i}"></ng-container>
            </span>
        </div>
        <div *ngIf="showLabels && !labelsTop" class="step-name">
            <ng-container [ngTemplateOutlet]="stepLabelRenderer || defaultStepLabelRenderer" [ngTemplateOutletContext]="{$implicit: i}" ></ng-container>
        </div>
    </div>
</div>
