export class AdminButtonPanelConfig {
    showSaveButton: boolean;
    showCancelButton: boolean;
    activateSaveButton: boolean;
    activateCustomButton: boolean;
    activateCustomButton2: boolean;
    showCustomButton: boolean;
    showCustomButton2: boolean;
    customButtonIcon: string;
    customButton2Icon: string;
    customButtonLabel: string;
    customButton2Label: string;
    registration: AdminButtonPanelFunctionRegistrations;
}

export class AdminButtonPanelFunctionRegistrations {
    saveEvent?: Function;
    cancelEvent?: Function;
    customEvent?: Function;
    custom2Event?: Function;
}
