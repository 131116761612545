<div class="exceptionsContainer">
    <div class="exceptionsHeaderRow">
        <span class="exceptionsHeaderRowText">Exceptions
            <span class="clickable" (click)="doAddEvent($event)" *ngIf="markupType">
                <i class="fa fa-plus-circle tooltip" aria-hidden="true"><span style="top: -30px; left: -45px" class="tooltiptext">Add Markup Exception</span></i>
            </span>
        </span>

    </div>

    <!-- ================================= MARKUP EXCEPTIONS LABEL ROW ================================= -->
    <div class="exceptionsLabelRow borderBottomGray">
        <div *ngIf="markupType && (markupType.markupType !== 'ACCL')" class="exceptionTypeAndRangeColumnsContainer">
            <div class="exceptionRowIndexCol"></div>

            <div class="exceptionTypeCol">
                <span class="labelRowText">
                    Exception Type
                </span>
            </div>

            <div class="exceptionsRangeValuesCol">
                <span class="labelRowText">
                    Exception
                </span>
            </div>
        </div>

        <div *ngIf="markupType && (markupType.markupType === 'ACCL')" class="exceptionTypeAndRangeColumnsContainer">
            <div class="exceptionRowIndexCol"></div>

            <div class="exceptionTypeCol">
                <span class="labelRowText">
                    Accessorial
                </span>
            </div>
        </div>

        <div class="rateColumnsContainer">
            <div class="rateTypeCol">
                <span class="labelRowText">
                    Rate Type
                </span>
            </div>

            <div class="rateValueCol">
                <span class="labelRowText">
                    Rate
                </span>
            </div>

            <div class="rateOnCol">
                <span class="labelRowText">
                    Rate On
                </span>
            </div>
        </div>

        <div class="exceptionsRangeColumnsContainer">
            <div class="exceptionsMarkupRangeCol">
                <span class="labelRowText">
                    Markup Range
                </span>
            </div>

            <div class="exceptionsRangeOnCol">
                <span class="labelRowText">
                    Range On
                </span>
            </div>
        </div>

        <div *ngIf="markupType && (markupType.markupType === 'ACCL')" class="acclMinChargeContainer">
            <div class="acclMinChargeCol">
                <span class="labelRowText" style="margin-left: 4px">
                    Min Charge
                </span>
            </div>
        </div>

        <div class="dateRangeContainer">
            <div class="dateRangeCol">
                <span class="labelRowText">
                    Date Range
                </span>
            </div>

            <div class="exceptionDescriptionCol">
                <span class="labelRowText">
                    Description
                </span>
            </div>

            <div class="exceptionCarriersCol">
                <span class="labelRowText">
                    Carriers
                </span>
            </div>
        </div>
    </div>

    <!-- ==================================== ACTUAL EXCEPTIONS FORM ROWS ==================================== -->
    <div class="exceptionsFormRowContainer borderBottomGray"
         *ngFor="let exceptionGroup of exceptionsFormArray; let i = index;" [formGroup]="exceptionGroup">
        <div class="exceptionsFormRow">
            <div class="exceptionTypeAndRangeColumnsContainer">
                <span class="exceptionRowIndexCol">
                    {{ i + 1 }}
                </span>
                <ng-container *ngIf="markupType.markupType === 'ACCL'" >
                    <div class="exceptionAcclCol clickable">
                        <span *ngIf="!exceptionGroup.value.exceptionAccessorials || !exceptionGroup.value.exceptionAccessorials!.length"
                              (click)="navigateToAccessorialSelection(i)">
                            Add
                        </span>

                        <span *ngIf="exceptionGroup.value.exceptionAccessorials && exceptionGroup.value.exceptionAccessorials!.length"
                              (click)="navigateToAccessorialSelection(i)">
                            Accessorials ({{ exceptionGroup.value.exceptionAccessorials.length }})
                        </span>
                    </div>
                </ng-container>
                <ng-container *ngIf="markupType.markupType !== 'ACCL'">
                    <div class="exceptionTypeCol">
                        <div class="ruleRowSelectPanel clickable clickableCaretExtension">
                            <select class="ruleRowSelectTag" formControlName="breakType">
                                <option [value]="rangeType.rangeTypeID" *ngFor="let rangeType of rangeTypes">
                                    {{ rangeType.displayRangeType }}
                                </option>
                            </select>
                            <span class="ruleRowSelectCaret clickableCaret clickableCaretPositioning">
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </div>
                    </div>

                    <div class="exceptionsRangeValuesCol"
                         [ngClass]="{ 'exceptionRangeInvalid': exceptionGroup.errors && exceptionGroup.errors!.exceptionRangeInvalid }">
                        <input type="text" class="exceptionsRangeValuesInputTag" formControlName="low"
                               [ngClass]="{ 'exceptionRangeInputTagInvalid': (exceptionGroup.controls.low.dirty ||
                                exceptionGroup.controls.low.touched) && exceptionGroup.controls.low.errors &&
                                (exceptionGroup.controls.low.errors.pattern || exceptionGroup.controls.low.errors.min)}">
                        <span class="markupRangeText">
                            to
                        </span>
                        <input type="text" class="exceptionsRangeValuesInputTag" formControlName="high"
                               [ngClass]="{ 'exceptionRangeInputTagInvalid': (exceptionGroup.controls.high.dirty ||
                               exceptionGroup.controls.high.touched) && exceptionGroup.controls.high.errors &&
                               (exceptionGroup.controls.high.errors.pattern || exceptionGroup.controls.high.errors.min)}">
                    </div>
                </ng-container>
            </div>

            <div class="rateColumnsContainer"
                 [ngClass]="{ 'rateContradiction': exceptionGroup.errors && exceptionGroup.errors!.rateContradiction &&
                            !exceptionGroup.errors!.rateContradiction.valid }">
                <div class="rateTypeCol">
                    <div class="ruleRowSelectPanel clickable clickableCaretExtension">
                        <select class="ruleRowSelectTag" formControlName="rateType">
                            <option [ngValue]="rateType.rateTypeID"
                                    *ngFor="let rateType of markupType.rateTypes">
                                {{ rateType.displayRateType }}
                            </option>
                        </select>
                        <span class="ruleRowSelectCaret clickableRateCaretPositioning clickableCaret">
                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>

                <div class="rateValueCol">
                    <div class="ruleRateValueInputPanel"
                         [ngClass]="{
                          'ruleRateValueInputPanelCurrency': getRateIcon(exceptionGroup.controls.rateType.value) === '$',
                          'ruleRateValueInputPanelPercent': getRateIcon(exceptionGroup.controls.rateType.value) === '%'
                          }">
                        <span class="ruleRateValueIcon" *ngIf="getRateIcon(exceptionGroup.controls.rateType.value) === '$'" >
                            <i class="fa fa-usd" aria-hidden="true"></i>
                        </span>
                        <input type="text" class="ruleRateValueInputTag" formControlName="rate">
                        <span class="ruleRateValueIcon" *ngIf="getRateIcon(exceptionGroup.controls.rateType.value) === '%'">
                            <i class="fa fa-percent" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>

                <div class="rateOnCol">
                    <div class="ruleRowSelectPanel clickable clickableCaretExtension">
                        <select class="ruleRowSelectTag" formControlName="rateOn">
                            <option [value]="rateOn.rateOnID" *ngFor="let rateOn of rateOnOptions">
                                {{ rateOn.displayRateOn }}
                            </option>
                        </select>
                        <span class="ruleRowSelectCaret clickableCaretPositioning clickableCaret ">
                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>

            <div class="exceptionsRangeColumnsContainer">
                <div class="exceptionsMarkupRangeCol"
                     [ngClass]="{ 'markupRangeInvalid': exceptionGroup.errors && exceptionGroup.errors!.markupRangeInvalid }">
                    <div class="markupRangePanel" [ngClass]="{ 'markupRangePanelInvalid': (exceptionGroup.controls.minMarkup.dirty ||
                        exceptionGroup.controls.minMarkup.touched) && exceptionGroup.controls.minMarkup.errors &&
                        (exceptionGroup.controls.minMarkup.errors.pattern || exceptionGroup.controls.minMarkup.errors.min)}">
                        <span class="markupRangeIcon">
                            <i class="fa fa-usd" aria-hidden="true"></i>
                        </span>
                        <input type="text" class="markupRangeInputTag" formControlName="minMarkup">
                    </div>

                    <span class="markupRangeText">
                        to
                    </span>

                    <div class="markupRangePanel"
                         [ngClass]="{ 'markupRangePanelInvalid': (exceptionGroup.controls.maxMarkup.dirty ||
                             exceptionGroup.controls.maxMarkup.touched) &&
                             exceptionGroup.controls.maxMarkup.errors &&
                             (exceptionGroup.controls.maxMarkup.errors.pattern || exceptionGroup.controls.maxMarkup.errors.min)}">
                             <span class="markupRangeIcon">
                                 <i class="fa fa-usd" aria-hidden="true"></i>
                             </span>
                        <input type="text" class="markupRangeInputTag" formControlName="maxMarkup">
                    </div>
                </div>

                <div class="exceptionsRangeOnCol">
                    <div class="ruleRowSelectPanel clickable clickableCaretExtension">
                        <select class="ruleRowSelectTag" formControlName="minMarkupon">
                            <option [value]="rateOn.rateOnID" *ngFor="let rateOn of rateOnOptions">
                                {{ rateOn.displayRateOn }}
                            </option>
                        </select>
                        <span class="ruleRowSelectCaret clickableCaretPositioning clickableCaret">
                            <i class="fa fa-caret-down"></i>
                        </span>
                    </div>
                </div>
            </div>

            <div *ngIf="markupType && (markupType.markupType === 'ACCL')" class="acclMinChargeContainer">
                <div class="acclMinChargeCol">
                    <div class="markupInputPanel" [ngClass]="{ 'minChargePanelInvalid': (exceptionGroup.controls.minCharge.dirty ||
                        exceptionGroup.controls.minCharge.touched) && exceptionGroup.controls.minCharge.errors &&
                        (exceptionGroup.controls.minCharge.errors.pattern || exceptionGroup.controls.minCharge.errors.min)}">
                        <span class="markupUSDIcon">
                            <i class="fa fa-usd" aria-hidden="true"></i>
                        </span>
                        <input type="text" class="markupInputTag" formControlName="minCharge">
                    </div>
                </div>
            </div>

            <div class="dateRangeContainer">
                <div class="dateRangeCol">
                    <div class="dateRangePanel" [ngClass]="{ 'dateRangeContradiction': (exceptionGroup.errors &&
                        exceptionGroup.errors!.dateRangeContradiction && !exceptionGroup.errors!.dateRangeContradiction.valid) ||
                        (exceptionGroup.controls.effective.errors && exceptionGroup.controls.effective.errors!.invalidDate) ||
                        (exceptionGroup.controls.end.errors && exceptionGroup.controls.end.errors!.invalidDate)}">
                        <input type="text" class="dateRangeStart" formControlName="effective">
                        <span class="dateRangeText">
                            to
                        </span>
                        <input type="text" class="dateRangeEnd" formControlName="end">
                        <span class="dateRangeIcon blue clickable" (click)="showDatePicker(exceptionGroup)">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>

                <div class="exceptionDescriptionCol clickable"
                     (click)="showMarkupExceptionDescriptionModal(exceptionGroup)">
                    <div *ngIf="exceptionGroup.value.exceptionDescription" class="exceptionDescriptionContainer">
                        <span class="exceptionDescriptionText">
                            {{ exceptionGroup.value.exceptionDescription }}

                            <div class="markupDescriptionToolTip">
                                <div class="markupDescriptionToolTipBody">
                                    <span>
                                        {{ exceptionGroup.value.exceptionDescription }}
                                    </span>
                                </div>
                                <div class="markupDescriptionToolTipArrow"></div>
                            </div>
                        </span>
                    </div>
                    <span *ngIf="!exceptionGroup.value.exceptionDescription">Add</span>
                </div>

                <div class="exceptionCarriersCol clickable">
                    <span *ngIf="exceptionGroup.value.exceptionCarriers && !exceptionGroup.value.exceptionCarriers!.length"
                          (click)="navigateToCarrierSelection(i)">
                        Add
                    </span>

                    <span *ngIf="exceptionGroup.value.exceptionCarriers && exceptionGroup.value.exceptionCarriers!.length"
                          (click)="navigateToCarrierSelection(i)">
                        Carriers ({{ exceptionGroup.value.exceptionCarriers.length }})
                    </span>
                </div>

                <div>
                    <span class="clickable" (click)="doDeleteEvent(i)">
                        <i class="fa fa-trash tooltip" aria-hidden="true">
                            <span style="top: -30px; left: -45px" class="tooltiptext">Delete Markup Exception</span>
                        </i>
                    </span>
                </div>
            </div>
        </div>

        <div class="contradictionMessagesContainer">
            <div class="contradictionDisplayText" *ngIf="(exceptionGroup.errors && exceptionGroup.errors!.exceptionRangeInvalid) ||
                (exceptionGroup.controls.low.errors && (exceptionGroup.controls.low.errors.pattern || exceptionGroup.controls.low.errors.min)) ||
                (exceptionGroup.controls.high.errors && (exceptionGroup.controls.high.errors.pattern || exceptionGroup.controls.high.errors.min))">
                Please enter a valid exception range.
            </div>

            <div class="contradictionDisplayText" *ngIf="(exceptionGroup.errors && exceptionGroup.errors!.markupRangeInvalid) ||
                (exceptionGroup.controls.minMarkup.errors && (exceptionGroup.controls.minMarkup.errors.pattern || exceptionGroup.controls.minMarkup.errors.min)) ||
                (exceptionGroup.controls.maxMarkup.errors && (exceptionGroup.controls.maxMarkup.errors.pattern || exceptionGroup.controls.maxMarkup.errors.min))">
                Please enter a valid markup range.
            </div>

            <div class="contradictionDisplayText" *ngIf="exceptionGroup.controls.effective.errors &&
                (exceptionGroup.controls.effective.errors!.invalidDate ||
                exceptionGroup.controls.end.errors!.invalidDate)" >
                Please enter a valid date range.
            </div>

            <div class="contradictionDisplayText"
                 *ngIf="exceptionGroup.errors && exceptionGroup.errors!.rateContradiction &&
                        (exceptionGroup.errors!.rateContradiction.contradictionIndex ||
                        exceptionGroup.errors!.rateContradiction.contradictionIndex === 0)">
                This exception contradicts the rates provided in
                Exception {{ exceptionGroup.errors!.rateContradiction.contradictionIndex + 1 }}.
            </div>

            <div class="contradictionDisplayText"
                 *ngIf="exceptionGroup.errors && exceptionGroup.errors!.dateRangeContradiction &&
                        (exceptionGroup.errors!.dateRangeContradiction.contradictionIndex ||
                        exceptionGroup!.errors!.dateRangeContradiction!.contradictionIndex === 0)">
                This exception contradicts the dates provided in
                Exception {{ exceptionGroup.errors!.dateRangeContradiction.contradictionIndex + 1 }}.
            </div>
        </div>

    </div>
</div>

<br/>
<br/>

<app-custom-datepicker *ngIf="isDatePickerShown" [isDateRangePossible]="true" [title]="'Select Date Range'"
                       [dateFormatPattern]="markupDisplayDateFormat"
                       [inputDate]="selectedDateFormGroup.value.effective"
                       (closeDatePicker)="hideDatePicker($event)"
                       [dateFormatValidationPattern]="_dateHelper.getDisplayDateValidation()"
                       [isDateRangeOnly]="true"
                       [inputEndDate]="selectedDateFormGroup.value.end"></app-custom-datepicker>

<app-markup-exception-description-modal *ngIf="isMarkupExceptionDescriptionModalShown"
                                        [selectedMarkupExceptionFormGroup]="editedMarkupExceptionFormGroup"
                                        (hideModal)="hideMarkupExceptionDescriptionModal()"></app-markup-exception-description-modal>
