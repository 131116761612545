<div class="overlay">
    <div class="searchContactModal">
        <div class="modalHeader">
            <div class="modalHeaderText">
                Select a Role
            </div>
            <span class="modalHeaderIcon clickable" (click)="cancel()">
                <i class="fa fa-times"></i>
            </span>
        </div>
        <div class="modalBody">
            <div class="searchRoleNamePanel">
                <form class="roleNameInputPanel" (ngSubmit)="filterValues()" [formGroup]="roleNameForm">
                    <input class="modalFilterInput" placeholder="Search" type="text" formControlName="roleSearchTerm">
                    <div class="searchIcon actionText blue clickable seacrhIconPosition " (click)="filterValues()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </div>
                </form>
            </div>
            <div class="searchResultsHeader">
                <div *ngIf="displayedRoleNameList.length">
                    Name
                </div>
            </div>
            <div class="searchResultsContainer">
                <div class="searchResultRow clickable" *ngFor="let role of displayedRoleNameList" (click)="passSelectedRole(role)">
                    {{ role.roleName }}
                </div>
            </div>
        </div>
    </div>
</div>