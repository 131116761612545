<div class="adminFlexing">
    <div class="padLeft padRight adminHeader">
        <div>
            <div class="adminHeaderTextMain clickable" (click)="navigateToAdmin()">Admin</div>
            <app-breadcrumbs></app-breadcrumbs>
        </div>

        <app-admin-button-panel class="rightItems"></app-admin-button-panel>
    </div>

    <div class="adminBody padLeft padRight adminFlexing">
        <router-outlet></router-outlet>
    </div>
</div>

