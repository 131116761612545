<div class="radioGroup" [ngStyle]="{'flex-direction': direction, 'justify-content': justify, 'align-items': align }">
    <div *ngFor="let radioButtonValue of values">
        <div class="radioButtonText clickable" (click)="updateValue(radioButtonValue.value)">
            <span class="labelPadding" *ngIf="!labelAfter" [innerHTML]="radioButtonValue.label"></span>
            <span class="radioButton" *ngIf="value === radioButtonValue.value">
                <i class="fa fa-dot-circle-o"></i>
            </span>
            <span class="radioButton" *ngIf="value !== radioButtonValue.value">
                <i class="fa fa-circle-o"></i>
            </span>
            <span class="labelPadding" *ngIf="labelAfter" [innerHTML]="radioButtonValue.label"></span>
        </div>
    </div>
</div>
