<div *ngIf="quickQuote" class="qqBackground">

    <div class="qqPage">
        <div class="qqHeader">
            <div>
                <span>Quote # {{quoteNumber}}</span>
                <span class="qqQuoteNameLabel">Quote Name</span>
                <input (ngModelChange)="checkDirty()" [(ngModel)]="quoteName" />
            </div>
            <div class="qqButtonPanel">
                <app-button *ngIf="quickQuote.bookingSelection && (quickQuoteId !== PATH_CHANGE)" [inactive]="!disableSaveQuote" [buttonColor]="'orange'" (click)="bookQuote()" >BOOK NOW</app-button>
                <app-button (click)="saveQuote()" [buttonColor]="'green'" [inactive]="disableSaveQuote" >SAVE QUOTE</app-button>
                <app-button (click)="clearQuote()" [buttonColor]="'red'">CLEAR QUOTE</app-button>
            </div>
        </div>
        <div class="qqBody">
            <div class="qqInfoPane">
                <div>
                    <app-quote-summary [quickQuote]="quickQuote"></app-quote-summary>
                    <div class="qqIconWithButton">
                        <i class="qqIconForButton fa fa-print" aria-hidden="true"></i>
                        <app-button (click)="printQuote()" [buttonColor]="'babyblue'">PRINT QUOTE</app-button>
                    </div>
                    <div *ngIf="false" class="qqIconWithButton">
                        <i class="qqIconForButton fa fa-envelope" aria-hidden="true"></i>
                        <app-button (click)="emailQuote()" [buttonColor]="'babyblue'">EMAIL QUOTE</app-button>
                    </div>
                    <div class="qqIconWithButton">
                        <i class="qqIconForButton fa fa-copy" aria-hidden="true"></i>
                        <app-button (click)="copyQuote()" [buttonColor]="'babyblue'">EDIT QUOTE</app-button>
                    </div>
                </div>
            </div>
            <div class="qqSelectionDetailsPane">
                <div class="qqSelection" *ngIf="!quickQuote.bookingSelection || (this.quickQuoteId === PATH_CHANGE)">
                    <app-carrier-select (showCarrierDetails)="showTerminalDetails($event)" [isCarrierKPIShown]="isCarrierKPIShown" [isRatedClassShown]="isRatedClassShown" (selected)="selected($event)" [bookingOptions]="quickQuote.bookingOptions"></app-carrier-select>
                </div>
                <div class="qqDetails" *ngIf="quickQuote.bookingSelection && (this.quickQuoteId !== PATH_CHANGE)">
                    <app-quote-carrier-details (changeCarrier)="changeCarrier()" [quickQuote]="quickQuote"></app-quote-carrier-details>
                    <app-quote-costs *ngIf="quickQuote.bookingSelection.LSP.scac !== 'CTNS'" [quickQuote]="quickQuote"></app-quote-costs>
                    <div *ngIf="quickQuote.bookingSelection.LSP.scac === 'CTNS'" style="width: 100%">
                        <!-- Empty Placeholder Div so app-quote-carrier-details doesn't take full width -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-ok-cancel-modal *ngIf="isOkCancelModalVisible" [title]="'Clear Quote'" [message]="'You will lose all changes if your proceed.  Click Cancel to prevent this.'"
                     (onOk)="clearQuote(false)" (onCancel)="isOkCancelModalVisible = false"></app-ok-cancel-modal>

<app-terminal-details-modal *ngIf="isTerminalDetailsModalVisible" [bookingOptions]="bookingOptions" (cancel)="isTerminalDetailsModalVisible = false"></app-terminal-details-modal>
