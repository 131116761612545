import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as Constants from '../constants/constants';
import { DashboardHelper } from '../helpers/dashboardHelper';
import { Dashboard } from '../models/dashboard';
import { DashboardView } from '../models/dashboardView';
import { ResponseDTO } from '../models/responseDto';
import { ApiService, REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST, REQUEST_TYPE_PUT } from '../services/api.service';
import {SignalsService} from "../services/signals.service";
import { SearchService } from 'app/services/search.service';
import { NotificationService } from 'app/services/notification.service';
import {AppStateService} from "./app-state.service";

@Injectable()
export class DashboardService {

    constructor(
        private _api: ApiService,
        private _router: Router,
        private _signalService: SignalsService,
        private _notificationService: NotificationService,
        private _searchService: SearchService,
        private _appStateService: AppStateService,
        private _dashboardHelper: DashboardHelper
    ) { }

    // ============================================================
    // ================== Dashboard methods below ==================
    // ============================================================

    public getDashboards(activeDashboardViewId?): void {
        const req = new HttpRequest(REQUEST_TYPE_GET, `dashboards`);
        this._api.callApiService<ResponseDTO<Dashboard[]>>(req)
            .subscribe(
                (response) => {
                    if (response.isValid) {
                        this._dashboardHelper.loadDashboards(response.dto);
                        let activeDashboard = this._dashboardHelper.getActiveDashboard();
                        if (!activeDashboard) {
                            // defaulting the first dashboard within returned list as 'activeDashboard'
                            activeDashboard = response.dto[0];
                            this._signalService.updateAppState({ 'dashboard.activeDashboardId': activeDashboard.id });
                        }
                        if (activeDashboard && activeDashboard.views && activeDashboard.views.length) {
                            if (activeDashboardViewId) {
                                this._signalService.updateAppState({ 'dashboard.activeDashboardViewId': activeDashboardViewId });
                            } else {
                                this._signalService.updateAppState({ 'dashboard.activeDashboardViewId': activeDashboard.views[0].id });
                            }
                            this.getDashboardViewContent();
                        } else {
                            // we should never get here
                            this._notificationService.notifyError({ title: `Load Dashboard Error`, message: `Dashboard ${activeDashboard.name} has no views` });
                        }
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Get Dashboards` });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._dashboardHelper.clearDashboards();
                    this._notificationService.notifyError({ title: `Load Dashboards Error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                });
    }

    public async getDashboardsAsync(): Promise<void> {
        try {
            const req = new HttpRequest(REQUEST_TYPE_GET, `dashboards`);
            const response = await this._api.callApiService<ResponseDTO<Dashboard[]>>(req).toPromise();
            if (response.isValid) {
                this._dashboardHelper.loadDashboards(response.dto);
                let activeDashboard = this._dashboardHelper.getActiveDashboard();
                if (!activeDashboard) {
                    // defaulting the first dashboard within returned list as 'activeDashboard'
                    activeDashboard = response.dto[0];
                    this._signalService.updateAppState({ 'dashboard.activeDashboardId': activeDashboard.id });
                }
                if (activeDashboard && activeDashboard.views && activeDashboard.views.length) {
                    this._signalService.updateAppState({ 'dashboard.activeDashboardViewId': activeDashboard.views[0].id });
                    await this.getDashboardViewContentAsync();
                } else {
                    // we should never get here
                    this._notificationService.notifyError({ title: `Load Dashboard Error`, message: `Dashboard ${activeDashboard.name} has no views` });
                }
            } else {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Get Dashboards` });
            }
        } catch (err) {
            this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: `Get Dashboards` });
        }
    }

    public updateDashboardName(name: string, id: number, defaultDashboard: number, dashboardViewId?): void {
        const req = new HttpRequest(REQUEST_TYPE_PUT, `dashboards/${id}`, { name, defaultDashboard });
        this._api.callApiService<ResponseDTO<Array<Dashboard>>>(req)
            .subscribe(
                (response) => {
                    if (response.isValid) {
                        if (response.dto.length > 1) {
                            this._notificationService.notifyWarning({ title: 'Dashboard Name', message: 'More than one Dashboard returned. Showing the first' });
                        }
                        this._dashboardHelper.loadDashboard(response.dto[0]);
                        this._notificationService.notifySuccess({
                            title: 'Dashboard Name', message: `Success! You have updated your Dashboard name`
                        });
                        this.getDashboards(dashboardViewId);
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Update Dashboard Name` });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.notifyError({ title: `Rename Dashboard Error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                });
    }

    public createDashboard(name: string): void {
        const req = new HttpRequest(REQUEST_TYPE_POST, `dashboards`, { name });
        this._api.callApiService<ResponseDTO<Dashboard>>(req)
            .subscribe(
                (response) => {
                    if (response.isValid) {
                        this._dashboardHelper.loadDashboard(response.dto[0]);
                        this._signalService.updateAppState(
                            {
                                'dashboard.activeDashboardId': response.dto[0].id,
                                'dashboard.activeDashboardViewId': response.dto[0].views[0].id
                            }
                        );
                        this._notificationService.notifySuccess({
                            title: 'Dashboard Creation', message: `Success! You have created a new Dashboard`
                        });
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Create Dashboard` });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.notifyError({ title: `Dashboard Creation Error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                }
            );
    }

    public setDefaultDashboard(dashboardId: number, defaultDashboard: number, name: string, dashboardViewId?): void {
        const req = new HttpRequest(REQUEST_TYPE_PUT, `dashboards/${dashboardId}`, { name, defaultDashboard });
        this._api.callApiService<ResponseDTO<Dashboard>>(req)
            .subscribe(
                (response) => {
                    if (response.isValid) {
                        this._notificationService.notifySuccess({ title: `Edit Dashboard`, message: `Success! You have updated your Dashboard` });
                        this.getDashboards(dashboardViewId);
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Set Default Dashboard` });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.notifyError({ title: `Edit Dashboard Error ${err.name} - ${err.statusText}`, message: `${err.message}` });
                }
            )
    }

    public switchDashboard(dashboard: Dashboard) {
        this._dashboardHelper.clearDashboardViewContent();
        this._signalService.updateAppState({
            'dashboard.activeDashboardId': dashboard.id,
            'dashboard.activeDashboardViewId': dashboard.views[0].id
        });
    }

    public deleteDashboard(dashboards: Array<Dashboard>, dashboardId: number): void {
        const req = new HttpRequest(REQUEST_TYPE_DELETE, `dashboards/${dashboardId}`);
        this._api.callApiService<{ isValid: boolean, messages: Array<any> }>(req)
            .subscribe(
                (response) => {
                    if (response.isValid) {
                        this._notificationService.notifySuccess({ title: 'Dashboard Delete', message: `You have successfully deleted your dasboard` });
                        this.getDashboards();
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Delete Dashboard` });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.notifyError({ title: `Dashboard deletion error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                });
    }

    // ============================================================
    // ================== DashboardView methods below ==================
    // ============================================================
    public getDashboardViewContent(): void {
        const activeDashboardView: DashboardView = this._dashboardHelper.getActiveDashboardView();

        if (this._appStateService.isUserSessionActive() && activeDashboardView && activeDashboardView.viewType === Constants.DashboardViewType_CONTENT_QUERY) {
            this._searchService.processDashboardViewSearch({ searchCriteria: activeDashboardView.viewContentQuery });
        } else if (this._appStateService.isUserSessionActive() && activeDashboardView && activeDashboardView.viewType === Constants.DashboardViewType_TEMPLATE_QUERY) {
            this._searchService.processTemplateViewSearch(activeDashboardView.templateContentQuery);
        } else {
            this._dashboardHelper.clearDashboardViewContent();
        }
    }

    public async getDashboardViewContentAsync(): Promise<void> {
        const activeDashboardView: DashboardView = this._dashboardHelper.getActiveDashboardView();

        if (this._appStateService.isUserSessionActive() && activeDashboardView && activeDashboardView.viewType === Constants.DashboardViewType_CONTENT_QUERY) {
            await this._searchService.processDashboardViewSearchAsync({ searchCriteria: activeDashboardView.viewContentQuery });
        }
        if (this._appStateService.isUserSessionActive() && activeDashboardView && activeDashboardView.viewType === Constants.DashboardViewType_TEMPLATE_QUERY) {
            await this._searchService.processTemplateViewSearchAsync(activeDashboardView.templateContentQuery);
        }
    }

    public deleteDashboardView(dashboardView: DashboardView, dashboardId: number) {
        const req = new HttpRequest(REQUEST_TYPE_DELETE, `dashboardViews/views/${dashboardView.id}`);
        this._api.callApiService<ResponseDTO<any>>(req)
            .subscribe(
                (response) => {
                    if (response.isValid) {
                        this._dashboardHelper.removeDashboardView({ dashboardViewId: dashboardView.id, dashboardId });
                        const activeDashboardViewId: number = this._dashboardHelper.getActiveDashboardViewId();
                        const activeDashboard: Dashboard = this._dashboardHelper.getActiveDashboard();
                        if (activeDashboardViewId === dashboardView.id) {
                            this._signalService.updateAppState(
                                {
                                    'search.createDashboardView': false,
                                    'dashboard.editDashboardView': false,
                                    'dashboard.editDashboardViewId': null,
                                    'dashboard.editDashboardViewSearch': false,
                                    'dashboard.activeDashboardViewId': activeDashboard.views[0].id,
                                });
                        } else {
                            this._signalService.updateAppState(
                                {
                                    'search.createDashboardView': false,
                                    'dashboard.editDashboardView': false,
                                    'dashboard.editDashboardViewId': null,
                                    'dashboard.editDashboardViewSearch': false,
                                });
                        }
                        this._notificationService.notifySuccess({ title: 'Delete Dashboard View', message: `You have successfully deleted ${dashboardView.name}.` });
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Delete Dashboard View` });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.notifyError({ title: `Dashboard View deletion error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                }
            )
    }

    public createDashboardView(newDashboardView: DashboardView, dashboardId: number): void {
        const req = new HttpRequest(REQUEST_TYPE_POST, `dashboardViews/views/${dashboardId}`, newDashboardView);
        this._api.callApiService<ResponseDTO<DashboardView>>(req)
            .subscribe(
                (response) => {
                    if (response.isValid) {
                        this._dashboardHelper.loadDashboardView({ dashboardView: response.dto, dashboardId: dashboardId });
                        this._signalService.updateAppState({ 'search.createDashboardView': false, 'dashboard.editDashboardView': false, 'dashboard.editDashboardViewId': null });
                        this.getDashboards();
                        const activeDashboard = this._dashboardHelper.getActiveDashboard();
                        this._notificationService.notifySuccess({ title: 'Save View to Dashboard', message: `View ${newDashboardView.name} has been saved to your Dashboard ${activeDashboard.name}` });
                        this._router.navigate(['dashboard']);
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Create Dashboard View` });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.notifyError({ title: `Dashboard creation error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                }
            );
    }

    public updateDashboardView(editedDashboardView: DashboardView, dashboardId: number) {
        const req = new HttpRequest(REQUEST_TYPE_PUT, `dashboardViews/views/${editedDashboardView.id}`, editedDashboardView);
        this._api.callApiService<ResponseDTO<DashboardView>>(req)
            .subscribe(
                (response) => {
                    if (response.isValid) {
                        this._dashboardHelper.updateDashboardView({ updatedDashboardView: response.dto, dashboardId });
                        this._notificationService.notifySuccess({ title: 'Edit Dashboard View', message: `${editedDashboardView.name} has been updated!` });
                        this.getDashboards(editedDashboardView.id);
                        this._router.navigate(['dashboard']);
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: `Update Dashboard View` });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.notifyError({ title: `Dashboard update error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                }
            )
    }

    public switchDashboardView(dashboardView: DashboardView): void {
        this._dashboardHelper.clearDashboardViewContent();
        this._signalService.updateAppState({ 'dashboard.activeDashboardViewId': dashboardView.id });
    }

    public updateDashboardViewOrder(updatedDashboardViewsOrder: Array<{ viewOrder: number, viewId: number }>): void {
        const updateDashboardViewOrderReq = new HttpRequest(REQUEST_TYPE_PUT, `dashboardViews/order`, updatedDashboardViewsOrder);
        this._api.callApiService<ResponseDTO<any>>(updateDashboardViewOrderReq)
            .subscribe(
                (response) => {
                    if (response.isValid) {
                        this.getDashboards();
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Dashboard View Reordering ' });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.notifyError({ title: `Reordering Dashboard Views Error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                });
    }

    // ============================================================
    // ============ DashboardView Library methods below =========
    // ============================================================

    public getAllLibraryViews(): void {
        const req = new HttpRequest(REQUEST_TYPE_GET, `dashboardViewsLib`);
        this._api.callApiService<ResponseDTO<{ views: Array<DashboardView> }>>(req)
            .subscribe(
                (response) => {
                    if (response && response.isValid && response.dto && response.dto.views) {
                        this._dashboardHelper.loadDashboardLibraryViews(response.dto.views);
                    } else {
                        response.messages.forEach(message => {
                            this._notificationService.notifyError({ title: `Delete View From Library Error:`, message: `${message.messageType} - ${message.message}` });
                        });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.notifyError({ title: `Load Library Views Error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                });

    }

    public saveViewToDashboardViewLibrary(dashboardView: DashboardView): void {
        const req = new HttpRequest(REQUEST_TYPE_POST, `dashboardViewsLib`, dashboardView);
        this._api.callApiService<ResponseDTO<Array<DashboardView>>>(req)
            .subscribe(
                (response) => {
                    if (response.isValid) {
                        // NOTE(Hassan && Sorum) 05/16/18 - Currently API behavior is very inconsitent. Unclear if intention is to return all views or a single view. Regardless, safer bet is to get latest data;
                        this.getAllLibraryViews();
                        this._notificationService.notifySuccess({ title: `Save Dashboard View to Library`, message: `Your view has been saved to your library.` });
                    } else {
                        response.messages.forEach(message => {
                            this._notificationService.notifyError({ title: `Save Dashboard View to Library Error:`, message: `${message.messageType} - ${message.message}` });
                        });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.notifyError({ title: `Save Dashboard View to Library error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                });
    }

    public deleteViewFromLibrary(deletedDashboardViews: Array<DashboardView>) {
        deletedDashboardViews.forEach((view: DashboardView) => {
            const viewId = view.id;
            const req = new HttpRequest(REQUEST_TYPE_DELETE, `dashboardViewsLib/${viewId}`);
            this._api.callApiService<ResponseDTO<any>>(req)
                .subscribe(async (response) => {
                        if (response.isValid) {
                            this.getAllLibraryViews();
                            this._notificationService.notifySuccess({ title: `Delete Dashboard View Library View`, message: `Your Dashboard View ${view.name} has been deleted from the Dashboard View Library.` })
                        } else {
                            response.messages.forEach(message => {
                                this._notificationService.notifyError({ title: `Delete View From Library Error:`, message: `${message.messageType} - ${message.message}` });
                            });
                        }
                    },
                    (err: HttpErrorResponse) => {
                        this._notificationService.notifyError({ title: `Delete View From Library Error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                    });
        });
    }

    public editLibraryView(editedView: DashboardView): void {
        const req = new HttpRequest(REQUEST_TYPE_PUT, `dashboardViewsLib/${editedView.id}`, editedView);
        this._api.callApiService<ResponseDTO<Array<{ views: Array<DashboardView> }>>>(req)
            .subscribe(
                (response) => {
                    if (response && response.isValid && response.dto && response.dto.length && response.dto[0].views && response.dto[0].views.length) {
                        this.getAllLibraryViews();
                        this._notificationService.notifySuccess({ title: `Edit Dashboard View Library View`, message: `Your Dashboard View ${editedView.name} has been deleted from the Dashboard View Library.` })
                    } else {
                        this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Edit Libary View' });
                    }
                },
                (err: HttpErrorResponse) => {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Edit Library View' });
                }
            )
    }

}
