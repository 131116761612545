import { MarkupException } from './markupException';
import { MarkupRate } from './markupRate';

export class Markup {
  markupType: string;
  markupName: string;
  markupDescription: string;
  markupActive: number; // 1 or 0 (basically a boolean)

  rates: Array<MarkupRate>;

  exceptions: Array<MarkupException>;
  markupID?: number;
}
