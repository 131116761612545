<div class="accessorialList" *ngFor="let accessorial of shownList">
    <span class="accessorialName">{{accessorial.displayName}}<span class="clickable blue" (click)="changeAccessorialValue(accessorial.code)" *ngIf="(accessorial.valueRequired) && (accessorial.value !== undefined)">&nbsp;({{accessorial.value}})</span></span>
    <span class="accessorialCheckbox"><app-checkbox [tabindex]="tabindex" [ngModel]="valueMap.has(accessorial.code)" (changeMade)="checkboxChanged(accessorial.code, $event)"></app-checkbox></span>
</div>
<div *ngFor="let group of groups">
    <span *ngIf="!isDisplayGroup(group.code) && !isAllAccessorialsGroup(group.code)" class="linkText clickable blue" (click)="showDialog(true, group)">{{group.label}}</span>
</div>
<span><span class="linkText clickable blue" (click)="showDialog(true, allAccessorialsGroup)">{{allAccessorialsGroup?.label}}</span></span>

<app-number-modal *ngIf="showNumberModal" [payload]="payload" [prop]="'value'" [message]="payload.displayName"
                  [additionalMessage]="payload.valueRequired" (onOk)="hideNumberModal($event)"
                  (onCancel)="cancelNumberModal($event)"></app-number-modal>
<app-accessorial-selector-modal *ngIf="showAccessorialModal" (addAccessorialsToRecord)="receiveAccessorials($event)"
                                (canceled)="accessorialSelectorCancelled()" [currentAccesorials]="value"
                                [shownAccessorials]="getAccessorialListFromGroup(groupShown)"
                                [okayButton]="'Submit'" [allowSameAccessorialsGiven]="true">
</app-accessorial-selector-modal>
