import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import * as RecordCreationConstants from '../../constants/recordCreation.constants';
import {ContactSearchResult} from '../../models/contact.searchResult';

@Component({
    selector: 'app-vlscfs',
    styleUrls: ['./app-vlscfs.component.scss'],
    templateUrl: './app-vlscfs.component.html',
})
export class AppVlscfsComponent {
    @Input()
    public vanguardContactType = RecordCreationConstants.VANGUARD_CFS_CONTACT_TYPE.ORIGIN;

    @Output()
    public dialogShown = new EventEmitter();
    @Output()
    public contactUpdate = new EventEmitter<ContactSearchResult>();

    public isVLSCFSModalShown = false;

    constructor() {
    }

    private static transformVLSContactToContactSearchResult(contactMasterData) {
        const contact: ContactSearchResult = new ContactSearchResult();

        contact.id = contactMasterData.id;
        contact.contactsid = contactMasterData.contactsID;
        contact.active = contactMasterData.Active;
        contact.name = contactMasterData.Name;
        contact.name2 = contactMasterData.Name2;
        contact.address1 = contactMasterData.Address1;
        contact.address2 = contactMasterData.Address2;
        contact.city = contactMasterData.City;
        contact.state = contactMasterData.State;
        contact.zip = contactMasterData.Zip;
        contact.country = contactMasterData.country;
        contact.contact = contactMasterData.Contact;
        contact.phone = contactMasterData.Phone;
        contact.email = contactMasterData.Email;
        contact.fax = contactMasterData.Fax;
        contact.hoursopen = contactMasterData.hoursOpen;
        contact.hoursclose = contactMasterData.hoursClose;
        contact.callappt = contactMasterData.callAppt;
        contact.commercialresidential = contactMasterData.commercialResidential;
        contact.contact_billto = contactMasterData.contact_billto;
        contact.contact_consignee = contactMasterData.contact_consignee;
        contact.contact_shipper = contactMasterData.contact_shipper;
        contact.contact_thirdparty = contactMasterData.contact_thirdparty;
        contact.customer = contactMasterData.Customer;
        contact.default_contact = contactMasterData.default_contact;

        return contact;
    }

    public showDialog() {
        this.dialogShown.emit(true);
        this.isVLSCFSModalShown = true;
    }

    public hideDialog($event) {
        this.isVLSCFSModalShown = false;
        this.dialogShown.emit(false);

        if ($event) {
            // Need to transform ContactMasterData into a ContactSearchResult to make response same as for
            // app-contact-search for consistency so same functionality can handle updates to view for shipments page.
            this.contactUpdate.emit(AppVlscfsComponent.transformVLSContactToContactSearchResult($event));
        }
    }
}
