<div class="padLeft padRight adminHeader">
    <div class="sectionBreadcrumbPanel">
        <div class="adminHeaderTextMain clickable" (click)="navigateToMasterData()">Master Data</div>
        <div class="breadcrumbs">
            <span class="clickable" (click)="navigateToMasterData()">Master Data</span>
            <span class="finalBreadcrumb" *ngIf="activeMasterdataSectionLabel"> > {{ activeMasterdataSectionLabel }}</span>
        </div>
    </div>
</div>

<div class="adminBody padLeft padRight ">
    <app-master-data-tabs [activeMasterdataSection]="activeMasterdataSection" (selectMasterdataTab)="switchMasterdataTabs($event)"></app-master-data-tabs>
    <app-masterdata-contacts-list *ngIf="activeMasterdataSection === masterDataTabSections.contacts"></app-masterdata-contacts-list>
    <app-masterdata-products-list *ngIf="activeMasterdataSection === masterDataTabSections.products"></app-masterdata-products-list>
    <app-masterdata-carriers-list *ngIf="activeMasterdataSection === masterDataTabSections.carriers"></app-masterdata-carriers-list>
</div>
