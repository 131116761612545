import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recently-closed-records',
  styleUrls: ['./recently-closed-records.component.scss'],
  templateUrl: './recently-closed-records.component.html'

})
export class RecentlyClosedRecordsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
