<div class="promptContainer">
    <div class="messageAndButtons">
        <div class="promptMessage">Perform a search and filter by entity. When you're done, click Save to create a new
            view.</div>
        <div class="promptButtonHolder">
            <div (click)="closePrompt()" class="btn-background promptButton clickable">Cancel</div>
            <div class="btn-background promptButton clickable" id="createViewPrompt-saveButton" (click)="saveDashboardViewContent()"
                [ngClass]="{ 'saveButtonActive': isSaveButtonActive, 'saveButtonDisabled': !isSaveButtonActive }">Save</div>
        </div>
    </div>
    <div class="closeIcon">
        <i (click)="closePrompt()" class="fa fa-times clickable" aria-hidden="true"></i>
    </div>
</div>