<div class="carrierListComponent">
    <div class="filterSectionRow">
        <div class="filterSection">
            <div class="searchCriteriaTypePanel">
                <select class="searchCriteriaTypeText" name="selectedFilterField" id="searchCriteriaTypes" [(ngModel)]="selectedFilterField"
                    (change)="filterCarriers()">
                    <option [value]="ff.filterField" *ngFor="let ff of dropdownFilterFields">{{ ff.displayText }}</option>
                </select>
                <span class="searchCriteriaTypeChevron">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
            </div>
            <form class="filterInputPanel" (ngSubmit)="filterCarriers()" [formGroup]="carrierListForm">
                <input type="text" id="searchQuery" autofocus class="searchQuery" name="searchQuery" formControlName="filterTerm" placeholder="Enter Search Criteria"
                    (change)="filterCarriers()" />
            </form>
            <span class="iconSearch clickable blue">
                <i class="fa fa-search" aria-hidden="true"></i>
            </span>
        </div>
        <div class="createCustomerSection">
            <span class="createCustomerIcon blue clickable">
                <i class="fa fa-plus-circle"></i>
            </span>
            <span class="createCustomerLabel blue clickable actionText" (click)="navigateToCreateCarrier()">Create</span>
        </div>
    </div>

    <div class="customerListContainer">
        <div class="customerListHeaderRow">
            <div class="headerItem">Carrier Name</div>
            <div class="headerItem">SCAC</div>
            <div class="lastCol">&nbsp;</div>
        </div>
        <div class="customerDetailContainer" *ngIf="masterDataCarriers.length">
            <cdk-virtual-scroll-viewport itemSize="25" style="height: 100%">
                <div class="customerDetailRow bottomBorderShadow" *cdkVirtualFor="let carrier of filteredCarriers; let i = index" (click)="navigateToCarrierShowPage(carrier.scac)">
                    <div class="rowDetailItem rowDetailItemName blue actionText">{{ carrier?.carrierName }}</div>
                    <div class="rowDetailItem">{{ carrier?.scac }}</div>
                    <div class="lastCol" (click)="showConfirmDeleteModal($event, carrier)">
                        <i class="fa fa-times blue clickable" aria-hidden="true"></i>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>

<app-ok-cancel-modal *ngIf="isConfirmDeleteModalVisible" [title]="confirmDeleteCarrierTitle" [message]="confirmDeleteCarrierMessage"
    (onCancel)="hideConfirmDeleteCarrierModal()" (onOk)="deleteCarrier($event)" [okPayload]="selectedCarrier"></app-ok-cancel-modal>
