export class Consignee {
    // primary identifier ------------- //
    Consignee: number; //  0,
    // alternate identifier ----------- //
    Customer: string; //  "ACENTB",
    ID: number; // 1201,
    contactsId: number;
    // associated data ---------------- //
    BOL: string; //  813481,
    consignee_address: string; //  "235 HERITAGE AVE",
    consignee_address2: string; //  "",
    consignee_city: string; // "Portsmouth",
    consignee_contact_email: string; //  "",
    consignee_contact_fax: string; //  6035555555,
    consignee_contact_name: string; //  "RONNY SANBORN",
    consignee_contact_phone: string; // 6035310229,
    consignee_country: string; //  "US",
    consignee_hours: string; //  "",
    Consignee_Name: string; // "COAST TO COAST",
    consignee_name2: string; // "",
    consignee_state: string; //  "NH",
    consignee_zip: string; //  "03801",
    consigneeCallAppt: number; //  0,
    consigneeCommercialResidential: string; //  "C",
    consigneeHoursClose: string; //  "January, 01 1970 17:00:00",
    consigneeHoursOpen: string; //  "January, 01 1970 08:00:00"
    consigneeTimezone: string; //  "E",
    Pickup_date: string; //  "August, 08 2017 00:00:00",
    Delivery_Date: string;
    User: number; //  7267,
}
