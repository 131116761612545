export class ProductDetail {
    class: number;
    Cube: number;
    description: string;
    description2: string;
    hazmat: number;
    hazmat_class: string;
    hazmat_number: string;
    hazmat_packingGroup: string;
    hazmat_subClass: string;
    Height: number;
    Length: number;
    loadingMeters: number;
    name: string;
    nmfc: string;
    PCF: number;
    Pkg_Type: number;
    Product: number;
    Qty: number = 1;
    stackable: number;
    Unit_Type: number;
    Units: number;
    Weight: number;
    Width: number;
    Detail_ID: number;
    productId: number;
    ID?: number;
}
