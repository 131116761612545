<div class="buttonContainer">
    <div *ngIf="buttonPanelState.showCancelButton" class="btn-outline clickable" (click)="cancel()">
        <i class="fa fa-ban" aria-hidden="true"></i>
        Cancel
    </div>
    <div *ngIf="buttonPanelState.showSaveButton" class="btn-filled clickable" (click)="save()"
         [ngClass]="{
                'inactive': !buttonPanelState.activateSaveButton
            }">
        <i class="fa fa-floppy-o " aria-hidden="true"></i>
        Save
    </div>
    <div *ngIf="buttonPanelState.showCustomButton" class="btn-outline clickable"
         (click)="custom()"
         [ngClass]="{
                'inactive': !buttonPanelState.activateCustomButton
            }">
        <i class="fa {{buttonPanelState.customButtonIcon}} " aria-hidden="true"></i>
        {{buttonPanelState.customButtonLabel}}
    </div>
    <div *ngIf="buttonPanelState.showCustomButton2" class="btn-outline clickable"
         (click)="custom2()"
         [ngClass]="{
                'inactive': !buttonPanelState.activateCustomButton2
            }">
        <i class="fa {{buttonPanelState.customButton2Icon}} " aria-hidden="true"></i>
        {{buttonPanelState.customButton2Label}}
    </div>
</div>
