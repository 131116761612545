import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import * as _ from 'lodash-es';
import { MarkupRateTierOption } from '../../../models/markupRateTierOption';
import {CommonDataService} from '../../../services/commonData.service';


@Component({
    selector: 'app-accl-markup-search-modal',
    templateUrl: './accl-markup-search-modal.component.html',
    styleUrls: ['./accl-markup-search-modal.component.scss']
})

export class AcclMarkupSearchModalComponent implements OnInit, OnDestroy {
    @Output() public closeAcclMarkupSearchModal = new EventEmitter();
    @Output() public passAcclMarkupOption = new EventEmitter();

    public rateTierAcclRateOptions: Array<MarkupRateTierOption> = [];
    public filteredRateTierAcclRateOptions: Array<MarkupRateTierOption> = [];
    public acclMarkupSearchForm: UntypedFormGroup;

    private acclMarkupFormValueChangesSubscription: any;

    constructor(
        private _fb: UntypedFormBuilder,
        private _commonDataService: CommonDataService,
    ) { }

    public async ngOnInit() {
        await this._commonDataService.loadedPromise(this._commonDataService.acclOptions);
        this.rateTierAcclRateOptions = _.cloneDeep(this._commonDataService.acclOptions);
        this.filteredRateTierAcclRateOptions = this.rateTierAcclRateOptions;

        this.acclMarkupSearchForm = this._fb.group({
            searchTerm: ''
        });

        this.acclMarkupFormValueChangesSubscription = this.acclMarkupSearchForm.valueChanges.subscribe(values => {
            this.filterResults();
        })
    }

    public ngOnDestroy() {
        this.acclMarkupFormValueChangesSubscription.unsubscribe();
    }

    public closeModal(): void {
        this.closeAcclMarkupSearchModal.emit('');
    }

    public filterResults(): void {
        const searchTerm = this.acclMarkupSearchForm.get('searchTerm').value;
        const searchPattern = new RegExp(searchTerm, 'i');

        this.filteredRateTierAcclRateOptions = this.filteredRateTierAcclRateOptions.filter((result: MarkupRateTierOption) => {
            if (result.markupName.match(searchPattern) || result.markupDescription.match(searchPattern)) {
                return result;
            }
        });
    }

    public selectAcclMarkupOption(acclMarkupOption: MarkupRateTierOption): void {
        this.passAcclMarkupOption.emit(acclMarkupOption);
    }
}
