<div class="headerPanel" id="appHeader">
    <!-- =========================== ICON & VERSION PANEL =========================== -->
    <div class="homeIconPanel" [ngClass]="{ 'defaultLogo': logoSrc === defaultShipriteLogo, 'customLogo': logoSrc !== defaultShipriteLogo }">
        <span class="logoContainer clickable" (click)="home()">
            <img alt="Application Logo" class="logoImage" [src]="logoSrc | urlSanitizer" />
        </span>
        <a class="versionNumberText">
            <span>v{{appVersion}}</span>
        </a>
    </div>

    <app-horizontal-menu (click)="handleMenuEvent($event)" [menu]="mainMenu" ></app-horizontal-menu>
</div>

<app-impersonate-user-modal (closeModal)="isImpersonateModalShown = false"
                            *ngIf="isImpersonateModalShown"></app-impersonate-user-modal>
