import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';

import * as Constants from '../../constants/constants';
import * as Defaults from '../../constants/defaults';
import {UserHelper} from '../../helpers/userHelper';
import {Breadcrumb} from '../../models/breadCrumb';
import {LoginSettings} from '../../models/loginSettings';
import {LogoDetail} from '../../models/logoDetail';
import {MenuWithSubmenu} from '../../models/menuWithSubmenu';
import {User} from '../../models/user';
import {AuthService} from '../../services/auth.service';
import {ProductsService} from '../../services/products.service';
import {UserService} from '../../services/user.service';
import {LogoutService} from "../../services/logout.service";
import {BreadcrumbService} from "../../services/breadcrumb.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {SignalsService} from "../../services/signals.service";
import {CarriersMasterDataService} from "../../services/carriers.masterdata.service";
import {ContactsMasterDataService} from "../../services/contacts.masterdata.service";
import {NotificationService} from "../../services/notification.service";

export const {version: appVersion} = require('../../../../package.json');

const ADMIN_URL = 'admin';
const GLOBAL_SEARCH_URL = 'search';
const SHIPMENT_SUMMARY_REPORTS_URL = 'reports/shipments';
const INVOICE_SUMMARY_REPORTS_URL = 'reports/invoices';
const QUICK_QUOTE_URL = 'quick-quote/new';
const MASTER_DATA_URL = 'masterData/';
const CONTACTS_URL = 'masterData/contacts';
const PRODUCTS_URL = 'masterData/products/new';
const CARRIERS_URL = 'masterData/carriers';
const NMFC_SEARCH_URL = 'masterData/products/nmfc/search';
const SHIPMENTS_URL = 'shipments';

@Component({
    selector: 'app-header',
    styleUrls: ['./app-header.component.scss'],
    templateUrl: './app-header.component.html',
})
export class AppHeaderComponent implements OnInit {
    @Input() public isUserSessionActive: boolean;

    public appVersion: string;
    public user: User;

    public isImpersonating: boolean;
    public isImpersonateModalShown: boolean;
    public isImpersonateOptionShown: boolean;

    public defaultShipriteLogo: string = Defaults.SHIPRITE_LOGO_RELATIVE_PATH;
    public logoSrc: string = Defaults.SHIPRITE_LOGO_RELATIVE_PATH;
    public mainMenu = [];

    private userSettings: LoginSettings;
    private loginSettings$;
    private user$;

    constructor(
        private _authService: AuthService,
        private _notificationService: NotificationService,
        private _router: Router,
        private _breadcrumbService: BreadcrumbService,
        private _userHelper: UserHelper,
        private _userService: UserService,
        public _signalsService: SignalsService,
        private _productsService: ProductsService,
        private _logoutService: LogoutService,
        private _carriersMasterDataService: CarriersMasterDataService,
        private _contactsMasterDataService: ContactsMasterDataService,
    ) {
        this.loginSettings$ = toObservable(this._signalsService.loginSettingsSignal);
        this.user$ = toObservable(this._signalsService.userSignal);
    }

    public ngOnInit() {
        this.appVersion = appVersion;

        this.user$.pipe(
            switchMap((user: User) => {
                this.user = user;
                this.isImpersonating = this._userHelper.checkIsImpersonating();

                if (this.user && this.user.customer) {
                    this._carriersMasterDataService.getMasterDataCarriersList().subscribe();
                    this._contactsMasterDataService.getMasterDataContactsList(this.user.customer).subscribe();
                    this._productsService.getProductsList().subscribe();
                }
                return this.loginSettings$;
            }),
            map((us: LoginSettings) => {
                const adminEnabled = this._userHelper.isAdminLinkAccessible(Constants.ACCESS_TYPES.read, Constants.LOGIN_SETTINGS_PERMISSIONS_FIELDS.admin, us);
                this.userSettings = us;
                this.setLogoSrc();

                if (adminEnabled) {
                    this._userService.getUsersList().subscribe();
                    this.isImpersonateOptionShown = true;
                }

                this.populateMainMenu(adminEnabled);
            })
        ).subscribe();
    }

    public home(): void {
        this._router.navigate(['']);
    }

    public navigateToHeaderSection(section): void {
        this.setNavigationRelatedData(section);

        this._router.navigate([section]);
    }

    public handleMenuEvent(menuOption: MenuWithSubmenu) {
        switch(menuOption.code) {
            case 'PROFILE':
                this.navigateToUserProfile();
                break;
            case 'IMPERSONATE':
                this.isImpersonateModalShown = true;
                break;
            case 'DEIMPERSONATE':
                this.deimpersonate();
                break;
            case 'CUBECALC':
                this.showCubeCalculator();
                break;
            case 'LOGOUT':
                this._logoutService.logout();
                break;
            default:
                if (menuOption.url) {
                    this.navigateToHeaderSection(menuOption.url);
                }
                break;
        }
    }

    private navigateToUserProfile(): void {
        const userProfile = this._userHelper.getUserProfile();

        if (!userProfile || !userProfile.userlogin) {
            this._notificationService.notifyError({title: 'User Profile', message: `Missing data for user profile`});
            return;
        }

        this._router.navigate(['userprofile']);
    }

    private deimpersonate(): void {
        this._authService.deimpersonate();
    }

    private showCubeCalculator(): void {
        this._signalsService.updateAppState({'modal.isCubeCalculatorModalShown': true});
    }

    private setNavigationRelatedData(section: string): void {
        let breadcrumbs: Array<Breadcrumb>;
        if (section !== ADMIN_URL) {
            this._breadcrumbService.clearBreadcrumbs();
        }
        if (section === GLOBAL_SEARCH_URL) {
            breadcrumbs = this._breadcrumbService.breadcrumbsForSearch();
            this._breadcrumbService.addBreadcrumb(breadcrumbs);
        } else if (section === SHIPMENT_SUMMARY_REPORTS_URL) {
            this._signalsService.updateAppState({'shipmentReport.navigateFromHeader': true});
        } else if (section === INVOICE_SUMMARY_REPORTS_URL) {
            this._signalsService.updateAppState({'invoiceReport.navigateFromHeader': true});
        } else {
            return;
        }
    }

    private checkUserAccess(thingToBeAccessed, requestedAccess) {
        return this.userSettings && this._userHelper.canAccess({
            requestedAccess,
            thingToBeAccessed,
            permissions: this.userSettings,
            accessPermissionLevel: Constants.ACCESS_PERMISSION_LEVELS.user
        });
    }

    private checkGroupAccess(thingToBeAccessed, requestedAccess) {
        return this.userSettings && this._userHelper.canAccess({
            requestedAccess,
            thingToBeAccessed,
            permissions: this.userSettings,
            accessPermissionLevel: Constants.ACCESS_PERMISSION_LEVELS.group
        });
    }

    private checkExecuteOrWriteUserAccess(thingToBeAccessed) {
        return this.checkUserAccess(thingToBeAccessed, Constants.ACCESS_TYPES.write) ||
            this.checkUserAccess(thingToBeAccessed, Constants.ACCESS_TYPES.execute);
    }

    private checkReadOrWriteUserOrGroupAccess(thingToBeAccessed) {
        return this.checkUserAccess(thingToBeAccessed, Constants.ACCESS_TYPES.read) ||
            this.checkUserAccess(thingToBeAccessed, Constants.ACCESS_TYPES.write) ||
            this.checkGroupAccess(thingToBeAccessed, Constants.ACCESS_TYPES.read) ||
            this.checkGroupAccess(thingToBeAccessed, Constants.ACCESS_TYPES.write);
    }

    private setLogoSrc(): void {
        if (!this.userSettings || !this.userSettings.logo || !this.userSettings.logo.logo) {
            this.logoSrc = Defaults.SHIPRITE_LOGO_RELATIVE_PATH;
            return;
        }

        const logoDetail: LogoDetail = this.userSettings.logo;
        this.logoSrc = logoDetail.logo;

        this.adjustLogoHeight(logoDetail);
    }

    private adjustLogoHeight(logoDetail: LogoDetail): void {
        if (!logoDetail.height || !logoDetail.width || !logoDetail.logo) {
            return;
        }

        if (logoDetail.height > Defaults.SHIPRITE_HEADER_PANEL_HEIGHT_PX_VALUE) {
            const appHeaderHTMLEl: HTMLElement = document.getElementById('appHeader');
            appHeaderHTMLEl.style.setProperty('--appHeaderHeight', `${Defaults.SHIPRITE_HEADER_PANEL_MAXIMUM_HEIGHT_EM_VALUE}`);
        }
    }

    private populateMainMenu(adminEnabled) {
        const shipmentSummaryEnabled = this.checkReadOrWriteUserOrGroupAccess(Constants.LOGIN_SETTINGS_PERMISSIONS_FIELDS.shipments);
        const invoiceSummaryEnabled = this.checkReadOrWriteUserOrGroupAccess(Constants.LOGIN_SETTINGS_PERMISSIONS_FIELDS.invoices);
        const basicAccessEnabled = !!this.userSettings;
        const userCustomer = (this.user) ? this._userHelper.getUserCustomer() : '';

        this.mainMenu = [
            {enabled: basicAccessEnabled, text: 'Global Search', url: GLOBAL_SEARCH_URL},
            {enabled: true, text: 'Quick Quote', url: QUICK_QUOTE_URL},
            {enabled: basicAccessEnabled, text: 'Create Shipment', url: SHIPMENTS_URL + `/${userCustomer}/new`},
            {enabled: basicAccessEnabled, text: 'Master Data', url: MASTER_DATA_URL, submenu: [
                    {enabled: this.checkExecuteOrWriteUserAccess(Constants.MASTER_DATA_SECTIONS.CONTACTS), text: 'Create Contact/Location', url: CONTACTS_URL},
                    {enabled: this.checkExecuteOrWriteUserAccess(Constants.MASTER_DATA_SECTIONS.PRODUCTS), text: 'Create Products', url: PRODUCTS_URL},
                    {enabled: this.checkExecuteOrWriteUserAccess(Constants.MASTER_DATA_SECTIONS.CARRIER), text: 'Create Carriers', url: CARRIERS_URL},
                    {enabled: true, text: 'NMFC Lookup', url: NMFC_SEARCH_URL},
                    {enabled: true, text: 'Cube Calculator', code: 'CUBECALC'}
                ]
            },
            {enabled: shipmentSummaryEnabled || invoiceSummaryEnabled, text: 'Reporting', submenu: [
                    {enabled: shipmentSummaryEnabled, text: 'Shipment Reports', url: SHIPMENT_SUMMARY_REPORTS_URL},
                    {enabled: invoiceSummaryEnabled, text: 'Invoice Reports', url: INVOICE_SUMMARY_REPORTS_URL}
                ]
            },
            {enabled: adminEnabled, text: 'Admin', url: ADMIN_URL},
            {enabled: true, text: `Hi ${this.user?.firstname.charAt(0).toUpperCase() + this.user?.firstname.slice(1)}`, code: 'PROFILE', submenu: [
                    {enabled: true, text: 'User Profile', code: 'PROFILE'},
                    {enabled: this.isImpersonateOptionShown && !this.isImpersonating, text: 'Impersonate', code: 'IMPERSONATE'},
                    {enabled: this.isImpersonating, text: 'End Impersonation', code: 'DEIMPERSONATE'},
                    {enabled: true, text: 'Logout', code: 'LOGOUT'}
                ]
            }
        ];
    }
}
