import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { CarrierMasterData } from '../../../models/carrier.masterData';
import {CarriersMasterDataService} from "../../../services/carriers.masterdata.service";

@Component({
    selector: 'app-masterdata-carriers-list',
    styleUrls: ['./masterdata-carriers-list.component.scss'],
    templateUrl: './masterdata-carriers-list.component.html',
})
export class MasterDataCarriersListComponent implements OnInit, OnDestroy {
    public masterDataCarriers: Array<CarrierMasterData>;
    public filteredCarriers: Array<CarrierMasterData> = [];
    public carrierListForm: UntypedFormGroup;
    public selectedFilterField: string;
    public dropdownFilterFields: Array<{ displayText: string, filterField: string }> = [
        { displayText: 'All', filterField: 'all' },
        { displayText: 'Carrier Name', filterField: 'carrierName' },
        { displayText: 'SCAC', filterField: 'scac' },
    ];

    public isConfirmDeleteModalVisible: boolean = false;
    public confirmDeleteCarrierTitle: string = 'Confirm Carrier Deletion';
    public confirmDeleteCarrierMessage: string = 'Are you certain you\'d like to delete this carrier?';
    public selectedCarrier: CarrierMasterData;

    private carrierListValueChangesSubscription: any;

    constructor(
        private _fb: UntypedFormBuilder,
        private _router: Router,
        private _carriersMasterDataService: CarriersMasterDataService,
    ) { }

    public ngOnInit() {
        this.masterDataCarriers = this._carriersMasterDataService.carriersMasterData;
        this.filteredCarriers = this._carriersMasterDataService.carriersMasterData;

        this.carrierListForm = this._fb.group({
            filterTerm: ''
        });

        this.carrierListValueChangesSubscription = this.carrierListForm.valueChanges.subscribe(val => {
            this.filterCarriers();
        });

        this.selectedFilterField = this.dropdownFilterFields[0].filterField;
    }

    public ngOnDestroy() {
        this.carrierListValueChangesSubscription.unsubscribe();
    }

    public filterCarriers(): void {
        const filteredResults = [];
        const filterTerm = this.carrierListForm.get('filterTerm').value;
        const searchPattern = new RegExp(filterTerm, 'i');

        this.filteredCarriers = this.masterDataCarriers.filter((mdCarrier: CarrierMasterData) => {
            if (this.selectedFilterField === 'all') {
                if (mdCarrier.carrierName.match(searchPattern) || mdCarrier.scac.match(searchPattern)) {
                    return mdCarrier;
                }
            } else if (mdCarrier[this.selectedFilterField].toString().match(searchPattern)) {
                return mdCarrier;
            }
        });

    }
    public deleteCarrier(carrier: CarrierMasterData): void {
        this.hideConfirmDeleteCarrierModal();
        if (!carrier || !carrier.scac) {
            return;
        }

        this._carriersMasterDataService.deleteMasterDataCarrier(carrier).subscribe(res => {
            this._carriersMasterDataService.getMasterDataCarriersList().subscribe();
        });
    }

    public hideConfirmDeleteCarrierModal(): void {
        this.isConfirmDeleteModalVisible = false;
    }

    public showConfirmDeleteModal(event: Event, carrier: CarrierMasterData): void {
        event.stopPropagation();

        this.selectedCarrier = carrier;
        this.isConfirmDeleteModalVisible = true;
    }

    public navigateToCarrierShowPage(scac: string): void {
        this._router.navigate(['masterData/carriers', scac]);
    }

    public navigateToCreateCarrier(): void {
        this._router.navigate(['masterData/carriers']);
    }

}
