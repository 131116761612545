<div class="overlay">
    <div class="searchContactModal">
        <div class="modalHeader">
            <div class="modalHeaderText">
                Impersonate
            </div>
            <span class="modalHeaderIcon clickable" (click)="cancel()">
                <i class="fa fa-times"></i>
            </span>
        </div>
        <div class="modalBody">
            <div class="searchRoleNamePanel">
                <form class="roleNameInputPanel" (ngSubmit)="filterUsers()" [formGroup]="userListForm">
                    <input class="modalFilterInput" placeholder="Search" type="text" formControlName="userSearchTerm">
                    <div class="searchIcon actionText blue clickable seacrhIconPosition " (click)="filterUsers()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </div>
                </form>
            </div>
            <div class="searchResultsHeader">
                <div class="headerEntity">
                    User Name
                </div>
                <div class="headerEntity">
                    User Login
                </div>
                <div class="headerEntity">
                    Customer
                </div>
            </div>
            <div class="searchResultsContainer">
                <cdk-virtual-scroll-viewport itemSize="25" style="height: 100%">
                    <div class="searchResultRow clickable" *cdkVirtualFor="let user of filteredUsers; let i = index" (click)="selectUserForImpersonation(user)">
                        <div class="resultEntity">
                            {{ user?.name }}
                        </div>

                        <div class="resultEntity">
                            {{ user?.userlogin }}
                        </div>
                        <div class="resultEntity">
                            {{ user?.customer }}
                        </div>

                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
    </div>
</div>
