<div class="overlay" (click)="closeMenu()">
  <div class="menu-container" id="dashboardMenu">
    <div class="actionItems">
      <div class="clickable menuItem" (click)="setDefault()">Set Dashboard as Default</div>
      <div class="clickable menuItem" id="dashboarMenu-createNewDashboardLink" (click)="showCreateDashboardModal()">Create
        New Dashboard</div>
      <div class="clickable menuItem" (click)="renameDashTitle()">Rename Dashboard</div>
      <div class="clickable menuItem" (click)="deleteDashboard()">Delete Dashboard</div>
    </div>
    <div class="availableDashboards">
      <ul class="menu-list">
        <li *ngFor="let dashboard of availableDashboards" (click)="chooseActiveDashboard(dashboard?.id)">{{dashboard?.name}}</li>
      </ul>
    </div>
  </div>
</div>