import {HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {UserHelper} from '../helpers/userHelper';
import {Insurance} from '../models/insurance';
import {ApiService, REQUEST_TYPE_DELETE, REQUEST_TYPE_GET, REQUEST_TYPE_POST} from './api.service';
import {NotificationService} from "./notification.service";

@Injectable()
export class InsuranceService {

    constructor(private _api: ApiService,
                private _notificationService: NotificationService,
                private _userHelper: UserHelper,
    ) {}

    public getRecordInsuranceQuotedValue(declaredShipmentValue: number) {
        const req = new HttpRequest(REQUEST_TYPE_GET, `shipment/insurance/quote/${declaredShipmentValue}`);
        return this._api.callApiService<{ insuranceQuote: string }>(req).pipe(
            map(response => {
                if (response) {
                    return parseFloat(response.insuranceQuote);
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ title: `Error retrieving quote for shipment`, response: err});
                throw err;
            })
        );
    }

    public removeRecordInsurance(shipmentId: number) {
        const customerId = this._userHelper.getUserCustomer();
        const req = new HttpRequest(REQUEST_TYPE_DELETE, `shipment/insurance/${customerId}/${shipmentId}`);
        return this._api.callApiService<{ charge: number, removed: boolean }>(req).pipe(
            map(response => {
                if (response) {
                    return response.removed;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ title: `Error removing insurance for Customer: ${customerId} and Shipment: ${shipmentId}`, response: err});
                throw err;
            })
        );
    }

    public addRecordInsurance(declaredShipmentValue: string, shipmentId: number) {
        const customerId = this._userHelper.getUserCustomer();
        const req = new HttpRequest(REQUEST_TYPE_POST, `shipment/insurance/${customerId}/${shipmentId}`, { shipmentValue: declaredShipmentValue });
        return this._api.callApiService<Insurance>(req).pipe(
            map(response => {
                if (response) {
                    return response;
                } else {
                    const error: Error = {...(new Error()), ...response};
                    throw error;
                }
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ title: `Error purchasing insurance for Customer: ${customerId} and Shipment: ${shipmentId}`, response: err});
                throw err;
            })
        );
    }
}
