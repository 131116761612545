<div class="overlay">
    <div class="okCancelModal">
        <!-- =========================== MODAL HEADER =========================== -->
        <div class="modalHeader">
            <span class="closeModalIconContainer clickable">
                <i class="fa fa-times closeModalIcon" (click)="cancel()"></i>
            </span>
        </div>

        <!-- =========================== WARNING ICON =========================== -->
        <div class="warningIconHolder">
            <span class="">
                <i class="fa fa-exclamation-triangle warningIcon"></i>
            </span>
        </div>

        <!-- =========================== MODAL MESSAGES =========================== -->
        <div class="messageContainer mainMessage">
            <div class="message">
                If you continue without saving, you will lose the work you have done so far
            </div>
        </div>

        <div class="messageContainer additionalMessage">
            <div class="message">
                Are you sure you want to continue without saving your markup?
            </div>
        </div>

        <!-- =========================== OKAY AND CANCEL BUTTONS =========================== -->
        <div class="buttonPanel" *ngIf="!iscloneMarkupScenarioActive">
            <div class="btn-outline clickable" (click)="continueSavingMarkup(false)">
                Continue without saving
            </div>

            <div class="btn-filled clickable" (click)="continueSavingMarkup(true)">
                Save & Continue
            </div>
        </div>
        <div class="buttonPanel" *ngIf="iscloneMarkupScenarioActive">
            <div class="btn-outline clickable" (click)="continueWithCloningMarkup(false)">
                Continue without saving
            </div>

            <div class="btn-filled clickable" (click)="continueWithCloningMarkup(true)">
                Save & Continue
            </div>
        </div>
    </div>
</div>