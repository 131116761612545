<div class="inviteUserMainPanel">
    <div class="inviteUserBody">
        <div class="inviteUserFormContainer">
            <form [formGroup]="inviteForm" class="userInviteForm" (ngSubmit)="submitInvitation()">
                <div class="inviteUserFormTopSection">
                    <div class="inviteUserBodyHeader">User Details</div>
                    <div class="inviteUserTopFormFields">
                        <div class="userFormSection" *ngIf="!canAccessGlobalLevelCustomers && userProfile">
                            <div class="formLabel">
                                <span class="required">*</span>Customer #</div>
                            <div class="readOnlyFormValue">{{ userProfile?.customer }}</div>
                        </div>
                        <div class="userFormSection" *ngIf="canAccessGlobalLevelCustomers">
                            <div class="formLabel">
                                <span class="required">*</span>Customer #</div>
                            <select class="formValue" formControlName="custNo">
                                <option *ngFor="let customer of masterDataCustomers" [value]="customer.custNo">{{ customer.custName
                                    }} {{ customer.custNo}}</option>
                            </select>
                            <span class="userInviteFormSelectCaret clickableCaret">
                                <i class="fa fa-caret-down blue"></i>
                            </span>
                        </div>
                        <div class="userFormSection">
                            <div class="formLabel">
                                <span class="required">*</span>Invitee Role</div>
                            <select class="formValue" formControlName="roleId">
                                <option *ngFor="let role of filteredRoles" [value]="role.RoleID">{{ role.roleName }}</option>
                            </select>
                            <span class="userInviteFormSelectCaret clickableCaret">
                                <i class="fa fa-caret-down blue"></i>
                            </span>
                        </div>
                        <div class="userFormSection">
                            <div class="formLabel">
                                <span class="required">*</span>Expiration Period</div>
                            <div class="formValue">
                                <input formControlName="expiration" class="formValue" [ngClass]="{ 'invalidBorder': !expiration.pristine && expiration.errors }" type="number" placeholder="Enter a number between 2-30">
                                <div *ngIf="!expiration.pristine && expiration.errors && expiration.errors!.required" class="invalidMessage">Expiration Required</div>
                                <div *ngIf="!expiration.pristine && expiration.errors && !(expiration.errors!.required)" class="invalidMessage">Must be between 2-30</div>
                            </div>
                        </div>
                    </div>
                    <div class="inviteUserTopFormFields">
                        <div class="userFormSection">
                            <div class="formLabel">
                                <span class="required">*</span>Email
                            </div>
                            <div class="invitationRowEmailCol">
                                <div class="formValue">
                                    <input formControlName="email" class="formValue" [ngClass]="{ 'invalidBorder': !email.pristine && email.errors }" placeholder="Enter Invitee Email">
                                    <div *ngIf="!email.pristine && email.errors && email.errors!.required" class="invalidMessage">Email address Required</div>
                                    <div *ngIf="!email.pristine && email.errors && !(email.errors!.required)" class="invalidMessage">Must be valid email address</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
