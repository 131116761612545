import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as SearchConstants from '../constants/searchCriteria';
import { Dashboard } from '../models/dashboard';
import { FilteredListValue } from '../models/filteredListValue';
import { SearchCriterion } from '../models/searchCriterion';
import { SearchState } from '../models/searchState';
import { SearchTotal } from '../models/searchTotal';
import { DashboardHelper } from '../helpers/dashboardHelper';
import {SignalsService} from "../services/signals.service";
import {PaginationPageNumber} from "../models/paginationPageNumber";
import {SearchRequest} from "../models/searchRequest";
import {HttpErrorResponse, HttpRequest} from "@angular/common/http";
import {ApiService, REQUEST_TYPE_GET, REQUEST_TYPE_POST} from "./api.service";
import {Observable, Subject} from "rxjs";
import {ResponseDTO} from "../models/responseDto";
import {SearchResponse} from "../models/searchResponse";
import {takeUntil} from "rxjs/operators";
import {SearchResultTypeSet} from "../models/searchResultType";
import {TemplateContentQuery} from "../models/templateContentQuery";
import {NmfcSearchResult} from "../models/nmfc.searchResult";
import {NotificationService} from "./notification.service";
import {CommonDataService} from "./commonData.service";
import {AppStateService} from "./app-state.service";

@Injectable()
export class SearchService {
    searchCancelSubject: Subject<void>;

    constructor(
        private _signalsService: SignalsService,
        private _router: Router,
        private _appStateService: AppStateService,
        private _dashboardHelper: DashboardHelper,
        private _api: ApiService,
        private _notificationService: NotificationService,
        private _commonDataService: CommonDataService,
    ) {}

    public getSearchState(): SearchState {
        return this._signalsService.searchStateSignal();
    }

    public areSearchResultsFilteredByEntityType(): boolean {
        const searchState: SearchState = this.getSearchState();
        if (!searchState || !searchState.filterTerm) {
            return false;
        }

        return true;
    }

    public getSearchResultTotalByEntityType(entityType: string): number {
        if (!this.areSearchResultsFilteredByEntityType()) {
            return null;
        }

        const searchState: SearchState = this.getSearchState();
        const searchTotals: Array<SearchTotal> = searchState ? searchState.searchTotals : null;

        if (!searchTotals || !searchTotals.length) {
            return null;
        }

        const entityTypeResultTotal: SearchTotal = searchTotals.find((sT: SearchTotal) => sT.index === entityType);

        return entityTypeResultTotal ? entityTypeResultTotal.total : null;
    }

    public getSearchResultTotalSum(): number {
        const searchState: SearchState = this.getSearchState();
        const searchTotals: Array<SearchTotal> = searchState ? searchState.searchTotals : null;

        if (!searchTotals || !searchTotals.length) {
            return;
        }

        const searchResultsTotal: number = searchTotals.reduce((acc: number, curr: SearchTotal): number => {
            return acc + curr.total;
        }, 0);

        return searchResultsTotal;
    }

    public saveDashboardViewContent(): void {
        const appState = this._appStateService.getAppState();
        const activeDashboard: Dashboard = this._dashboardHelper.getActiveDashboard();

        if (this._dashboardHelper.doesDashboardHaveMaxNumberOfViews() && activeDashboard.name &&
            (appState['dashboard.activeDashboardViewId'] !== appState['dashboard.editDashboardViewId'])
        ) {
            this._signalsService.updateAppState({ 'modal.isSaveDashboardErrorModalShown': true });
        } else {
            if (!appState['dashboard.editDashboardViewSearch']) {
                this._signalsService.updateAppState({
                    'search.createDashboardView': true,
                    'dashboard.editDashboardView': true
                });
            }

            const searchState: SearchState = this.getSearchState();

            const dashboardViewQuery: Array<SearchCriterion> = [
                {
                    ...searchState.searchRequest.searchCriteria[0],
                    entityType: searchState.filterTerm,
                },
                ...searchState.searchRequest.searchCriteria.slice(1),
            ];
            this.loadDashboardViewQuery(dashboardViewQuery);
            this._router.navigate(['createView']);
        }
    }

    public leftTrimFilterListUniqueValues(filterListValue: FilteredListValue): void {
        if (!filterListValue || !filterListValue.fieldType || !filterListValue.uniqueValues || !filterListValue.uniqueValues.length) {
            return;
        }

        const fieldType = filterListValue.fieldType;

        filterListValue.uniqueValues = filterListValue.uniqueValues.map((uniqueValue: any) => {
            if (!uniqueValue || typeof uniqueValue !== 'string' || !uniqueValue.length || uniqueValue.charAt(0) !== ' ') {
                return uniqueValue;
            }

            uniqueValue = uniqueValue.substring(1);

            if (fieldType === SearchConstants.FILTER_LIST_VALUE_fieldType.NUMBER) {
                uniqueValue = parseFloat(uniqueValue);
            }

            return uniqueValue;
        });
    }

    public updatePaginationData(paginationPageNumber: PaginationPageNumber): void {
        this._signalsService.loadSearchPageNumber(paginationPageNumber);
    }

    public clearPaginationData(): void {
        this._signalsService.clearSearchPageNumber();
    }

    public doCancel() {
        if (this.searchCancelSubject) {
            this.searchCancelSubject.next();
            this.searchCancelSubject.complete();
            this.searchCancelSubject = null;
        }
    }

    public processGlobalSearch(searchRequest: SearchRequest): void {
        this._signalsService.clearSearchResults();
        const req = new HttpRequest(REQUEST_TYPE_POST, `Search`, searchRequest);
        const searchCancelSubject: Subject<void> = new Subject<void>();
        this.searchCancelSubject = searchCancelSubject;
        this._api.callApiService<ResponseDTO<SearchResponse>>(req, true).pipe(
            takeUntil(searchCancelSubject))
            .subscribe((response) => {
                    this.searchCancelSubject = null;
                    if (response.isValid) {
                        this._signalsService.updateSearchRequestQuery(response.dto.searchRequest);
                        this._signalsService.loadSearchResults(response.dto);

                        if (response.dto.searchResults.length === 1) {
                            this._signalsService.setEntityFilter(response.dto.searchResults[0].entityType);
                        }

                        // set new appstatevariable hasNewGlobalSearchProcessed: true (if true then clear paginationPageNumber part of store from pagination component oninit)
                        this._signalsService.updateAppState({ 'search.hasNewGlobalSearchBeenProcessed': true });
                        this.clearPaginationData();
                    } else {
                        this._signalsService.clearSearchResults();

                        response.messages.forEach(message => {
                            this._notificationService.notifyError({ title: 'Search', message: `${message.messageType} - ${message.message}` });
                        });
                    }
                },
                (err: HttpErrorResponse) => {
                    this.searchCancelSubject = null;
                    this._signalsService.clearSearchResults();
                    this._notificationService.notifyError({ title: `Search error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                });
    }

    public processPaginatedGlobalSearch(searchRequest: SearchRequest, entityFilter: string): void {
        const req = new HttpRequest(REQUEST_TYPE_POST, `Search`, searchRequest);
        const searchCancelSubject: Subject<void> = new Subject<void>();
        this.searchCancelSubject = searchCancelSubject;
        this._api.callApiService<ResponseDTO<SearchResponse>>(req, true).pipe(
            takeUntil(searchCancelSubject))
            .subscribe((response) => {
                    this.searchCancelSubject = null;

                    if (response.isValid && response.dto && response.dto.searchResults && response.dto.searchResults.length) {
                        this._signalsService.loadSearchResults(response.dto);

                        this._signalsService.setEntityFilter(entityFilter);

                        this.createFilteredSearchResultsFieldValues({
                            entityFilterFields: this._commonDataService.getDisplayDataEntityFilterFields(entityFilter),
                            entityDateFields: this._commonDataService.getDisplayDataEntityDateFields(entityFilter),
                        });

                        // set hasNewGlobalSearchProcessed: false (in pagination component, if false, use store value for paginationPageNumber to set current pagination state)
                        this._signalsService.updateAppState({ 'search.hasNewGlobalSearchBeenProcessed': false });
                    } else {
                        this._signalsService.clearSearchResults();

                        response.messages.forEach(message => {
                            this._notificationService.notifyError({ title: 'Search', message: `${message.messageType} - ${message.message}` });
                        });
                    }
                },
                (err: HttpErrorResponse) => {
                    this.searchCancelSubject = null;
                    this._signalsService.clearSearchResults();
                    this._notificationService.notifyError({ title: `Search error: ${err.name} - ${err.statusText}`, message: `${err.message}` });
                });
    }

    public processPaginatedFilterValueSearch(searchRequest: SearchRequest): Observable<ResponseDTO<{ filteredListValues: FilteredListValue }>> {
        const req = new HttpRequest(REQUEST_TYPE_POST, `Search/filterList`, searchRequest);
        return this._api.callApiService<ResponseDTO<{ filteredListValues: FilteredListValue }>>(req);
    }

    public processDashboardViewSearch(dashboardViewSearchRequest: SearchRequest): void {
        this._dashboardHelper.updateDashboardViewContent(null);
        const req = new HttpRequest(REQUEST_TYPE_POST, `Search`, dashboardViewSearchRequest);
        const searchCancelSubject: Subject<void> = new Subject<void>();
        this.searchCancelSubject = searchCancelSubject;
        this._api.callApiService<ResponseDTO<SearchResponse>>(req, true).pipe(
            takeUntil(searchCancelSubject))
            .subscribe(
                (response) => {
                    this.searchCancelSubject = null;
                    if (response.isValid) {
                        let filterTerm = '';
                        let entities = [];
                        if (dashboardViewSearchRequest.searchCriteria[0].entityType) {
                            filterTerm = dashboardViewSearchRequest.searchCriteria[0].entityType;
                        }

                        if (filterTerm) {
                            response.dto.searchResults.forEach((searchResult: SearchResultTypeSet) => {
                                if (searchResult.entityType === filterTerm) {
                                    entities = searchResult.entities;
                                }
                            });
                        }

                        this._dashboardHelper.updateDashboardViewContent(entities);
                    } else {
                        this._signalsService.clearSearchResults();
                        response.messages.forEach(message => {
                            this._notificationService.notifyError({ title: 'Search', message: `${message.messageType} - ${message.message}` });
                        });
                    }
                },
                (err: HttpErrorResponse) => {
                    this.searchCancelSubject = null;
                    this._signalsService.clearSearchResults();
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Dashboard Search' });
                });
    }

    public async processDashboardViewSearchAsync(dashboardViewSearchRequest: SearchRequest): Promise<void> {
        this._dashboardHelper.clearDashboardViewContent();
        const req = new HttpRequest(REQUEST_TYPE_POST, `Search`, dashboardViewSearchRequest);
        try {
            const response = await this._api.callApiService<ResponseDTO<SearchResponse>>(req, true).toPromise();
            if (response.isValid) {
                let filterTerm = '';
                let entities = [];
                if (dashboardViewSearchRequest.searchCriteria[0].entityType) {
                    filterTerm = dashboardViewSearchRequest.searchCriteria[0].entityType;
                }

                if (filterTerm) {
                    response.dto.searchResults.forEach((searchResult: SearchResultTypeSet) => {
                        if (searchResult.entityType === filterTerm) {
                            entities = searchResult.entities;
                        }
                    });
                }

                this._dashboardHelper.updateDashboardViewContent(entities);
            } else {
                this._dashboardHelper.updateDashboardViewContent([]);
                response.messages.forEach(message => {
                    this._notificationService.notifyError({ title: 'Search', message: `${message.messageType} - ${message.message}` });
                });
            }
        } catch (err) {
            this._signalsService.clearSearchResults();
            this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Dashboard Search' });
        }
    }

    public processTemplateViewSearch(templateContentQuery: TemplateContentQuery): void {
        this._dashboardHelper.updateDashboardViewContent(null);
        const req = new HttpRequest(REQUEST_TYPE_POST, `Search/template`, templateContentQuery);
        const searchCancelSubject: Subject<void> = new Subject<void>();
        this.searchCancelSubject = searchCancelSubject;
        this._api.callApiService<ResponseDTO<SearchResponse>>(req, true).pipe(
            takeUntil(searchCancelSubject))
            .subscribe(
                (response) => {
                    this.searchCancelSubject = null;
                    if (response && response.isValid && response.dto && response.dto.searchResults && response.dto.searchResults.length) {
                        this._dashboardHelper.updateDashboardViewContent(response.dto.searchResults[0].entities);
                    } else {
                        this._dashboardHelper.updateDashboardViewContent([]);
                        this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Load Dashboard View-Template Query' });
                    }
                },
                (err: HttpErrorResponse) => {
                    this.searchCancelSubject = null;
                    this._signalsService.clearSearchResults();
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Load Dashboard View-Template Query' });
                });
    }

    public async processTemplateViewSearchAsync(templateContentQuery: TemplateContentQuery): Promise<void> {
        this._dashboardHelper.clearDashboardViewContent();
        const req = new HttpRequest(REQUEST_TYPE_POST, `Search/template`, templateContentQuery);
        try {
            const response = await this._api.callApiService<ResponseDTO<SearchResponse>>(req, true).toPromise();
            if (response && response.isValid && response.dto && response.dto.searchResults && response.dto.searchResults.length) {
                this._dashboardHelper.updateDashboardViewContent(response.dto.searchResults[0].entities);
            } else {
                this._dashboardHelper.updateDashboardViewContent([]);
                this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Load Dashboard View-Template Query' });
            }
        } catch (err) {
            this._signalsService.clearSearchResults();
            this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Load Dashboard View-Template Query' });
        }
    }

    public processContactSearch(contactSearchRequest: SearchRequest): Observable<ResponseDTO<SearchResponse>> {
        const req = new HttpRequest(REQUEST_TYPE_POST, `Search`, contactSearchRequest);
        return this._api.callApiService<ResponseDTO<SearchResponse>>(req);
    }

    public zipSearchUSDefault(zipCode: string, countryCode?: string) {
        const zipCodeSearchObject: SearchRequest = {
            searchCriteria: [
                {
                    type: SearchConstants.SEARCH_CRITERIA_type.ZIP_CODE,
                    value: zipCode,
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.ZIP_POSTAL,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH,
                    from: 0,
                    size: 10
                },
                {
                    type: SearchConstants.SEARCH_CRITERIA_type.COUNTRY,
                    value: countryCode ? countryCode : 'US',
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.ZIP_POSTAL,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH,
                }
            ]
        };

        return this.processZipSearch(zipCodeSearchObject);
    }

    public processZipSearch(zipSearchRequest: SearchRequest): Observable<ResponseDTO<SearchResponse>> {
        const req = new HttpRequest(REQUEST_TYPE_POST, `Search`, zipSearchRequest);
        return this._api.callApiService<ResponseDTO<SearchResponse>>(req);
    }

    public processNmfcSearch(nmfcSearchQuery: string): void {
        const nmfcSearchReq = new HttpRequest(REQUEST_TYPE_GET, `MasterData/NMFC/search/${nmfcSearchQuery}`);
        this._api.callApiService<ResponseDTO<Array<NmfcSearchResult>>>(nmfcSearchReq).subscribe(
            (response) => {
                if (response.isValid) {
                    this._signalsService.loadNmfcSearchResults(response.dto);
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'Search - NMFC' });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Search - NMFC' });
            });
    }

    public clearNmfcSearhResults(): void {
        this._signalsService.loadNmfcSearchResults([]);
    }

    public setEntityFilter(entity: string): void {
        this._signalsService.setEntityFilter(entity);
    }

    public createFilteredSearchResultsFieldValues({ entityFilterFields, entityDateFields }: { entityFilterFields: object, entityDateFields: Array<string> }): void {
        this._signalsService.createFilteredSearchResultsFieldValues({ entityFilterFields, entityDateFields });
    }

    public updateFilteredSearchResults(): void {
        this._signalsService.updateFilteredSearchResults()
    }

    public clearSearchResults() {
        this._signalsService.clearSearchResults();
    }

    public completeDashboardViewSearch(): void {
        this._signalsService.completeDashboardViewSearch();
    }

    public clearRecordContacts(): void {
        this._signalsService.loadRecordContacts();
    }

    public loadDashboardViewQuery(dashboardViewQuery: Array<SearchCriterion>): void {
        this._signalsService.loadDashboardViewQuery(dashboardViewQuery);
    }
}
