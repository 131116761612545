import {
    Accessorial,
    Address, Appointment, BillTo,
    BookingOption, BookingSource,
    Carrier,
    Contact,
    Currency, CustomerField,
    Dimensions,
    Equipment, EquipmentType, FreightClass,
    HandlingUnit,
    HazmatDetail,
    Location,
    Manifest, Mode, MonetaryValue, Note,
    PackingUnit,
    PhoneNumber,
    Product,
    Rate, RateBreakdown,
    RateDetail, RateServiceType, RateSource,
    Reference, ReferenceType, Source, SourceConfig, SourceReference, Status,
    Stop,
    Terminal,
    TerminalDetail, TerminalRoute, Time, TotalLinearDimensions, TrailerType, TransitTime,
    USO,
    Volume,
    Weight,
    Window
} from './unifiedShipmentObject';

export class TrailerTypeImpl implements TrailerType {
    code: string = '';
    description: string = '';
    id: number = 0;
    name: string = '';
}

export class ModeImpl implements Mode {
    code: string = '';
    description: string = '';
    id: number = 0;
    name: string = '';
}

export class EquipmentImpl implements Equipment {
    equipmentTypes: Array<EquipmentType> = [];
    mode: Mode = new ModeImpl();
    trailerType: TrailerType = new TrailerTypeImpl();
}

export class UsoImpl implements USO {
    accessorials: Array<Accessorial> = [];
    custNo: string = '';
    deliveryDirectionType: 'inbound' | 'outbound' | 'import' | 'export' = 'inbound';
    equipment: Equipment = new EquipmentImpl();
    id: number = 0;
    manifest: Array<Manifest> = [];
    route: Array<Stop> = [];
    version: number = 0;
}

export class AddressImpl implements Address {
    addressLines: Array<string> = [];
    city: string = '';
    country: string = 'US';
    name: string = '';
    postalCode: string = '';
    state: string = '';
}

export class WindowImpl implements Window {
    date: string = '';
    times: Array<Time> = [];
    type: 'operating' | 'requested' | 'estimated' | 'scheduled' | 'actual' = 'requested';
}

export class LocationImpl implements Location {
    address: Address = new AddressImpl();
    id: number = 0;
    name: string = '';
    type: 'C' | 'R' = 'C';
    window: Window = new WindowImpl();
}

export class StopImpl implements Stop {
    location: Location = new LocationImpl();
    stopID: number = 1;
    window: Window = new WindowImpl();
}

export class TimeImpl implements Time {
    endTime: string = '';
    startTime: string = '';
}

export class PhoneNumberImpl implements PhoneNumber {
    phoneNumber: string = '';
}

export class ContactImpl implements Contact {
    address: Address = new AddressImpl();
    companyName: string = '';
    contactName: string = '';
    email: string = '';
    fax: PhoneNumber = new PhoneNumberImpl();
    phoneMobile: PhoneNumber = new PhoneNumberImpl();
    phoneWork: PhoneNumber = new PhoneNumberImpl();
    preferredContactMethodType: string = '';
}

export class AppointmentImpl implements Appointment {
    contact: Contact = new ContactImpl();
    stopID: number = 1;
    window: Window = new WindowImpl();
}

export class WeightImpl implements Weight {
    uom: string = '';
    value: number = 0;
}

export class VolumeImpl implements Volume {
    uom: string = '';
    value: number = 0;
}

export class CurrencyImpl implements Currency {
    currency: string = '';
    description: string = '';
    name: string = '';
}

export class MonetaryValueImpl implements MonetaryValue {
    amount: number = 0;
    currency: Currency = new CurrencyImpl();
}

export class DimensionsImpl implements Dimensions {
    height: number = 0;
    length: number = 0;
    uom: string = '';
    width: number = 0;
}

export class ManifestImpl implements Manifest {
    dimensions: Dimensions = new DimensionsImpl();
    dropoffStop: number = 2;
    handlingUnits: Array<HandlingUnit> = [];
    hazmat: boolean = false;
    id: number = 0;
    pickupStop: number = 1;
    stackable: boolean = false;
    totalHandlingUnits: number = 0;
    totalPackingUnits: number = 0;
    totalProducts: number = 0;
    volume: Volume = new VolumeImpl();
    weight: Weight = new WeightImpl();
    monetaryValue: MonetaryValue = new MonetaryValueImpl();
}

export class HandlingUnitImpl implements HandlingUnit {
    code: string = '';
    description: string = '';
    dimensions: Dimensions = new DimensionsImpl();
    hazmat: boolean = false;
    id: number = 0;
    name: string = '';
    packingUnits: Array<PackingUnit> = [];
    quantity: number = 0;
    sequence: number = 1;
    stackable: boolean = false;
    totalPackingUnits: number = 0;
    totalProducts: number = 0;
    volume: Volume = new VolumeImpl();
    weight: Weight = new WeightImpl();
    monetaryValue: MonetaryValue = new MonetaryValueImpl();
}

export class PackingUnitImpl implements PackingUnit {
    code: string = '';
    description: string = '';
    dimensions: Dimensions = new DimensionsImpl();
    hazmat: boolean = false;
    id: number = 0;
    name: string = '';
    products: Array<Product> = [];
    quantity: number = 0;
    sequence: number = 1;
    stackable: boolean = false;
    totalProducts: number = 0;
    volume: Volume = new VolumeImpl();
    weight: Weight = new WeightImpl();
    monetaryValue: MonetaryValue = new MonetaryValueImpl();
}

export class FreightClassImpl implements FreightClass {
    code: number = 0;
    id: number = 0;
    name: number = 0;
}

export class HazmatDetailImpl implements HazmatDetail {
    emergencyContact: Contact = new ContactImpl();
    hazmatClass: string = '';
    hazmatNumber: string = '';
    packingGroup: string = '';
    properShippingName: string = '';
    type: string = '';
}

export class ProductImpl implements Product {
    code: string = '';
    description: Array<string> = [];
    dimensions: Dimensions = new DimensionsImpl();
    freightClass: FreightClass = new FreightClassImpl();
    hazmat: boolean = false;
    hazmatDetail: HazmatDetail = new HazmatDetailImpl();
    id: number = 0;
    name: string = '';
    nmfcItemCode: string = '';
    nmfcSubCode: string = '';
    quantity: number = 0;
    sequence: number = 1;
    stackable: boolean = false;
    volume: Volume = new VolumeImpl();
    weight: Weight = new WeightImpl();
    monetaryValue: MonetaryValue = new MonetaryValueImpl();
}

export class AccessorialImpl implements Accessorial {
    code: string = '';
    description: string = '';
    id: number = 0;
    name: string = '';
    serviceType: string = '';
    value: number = 0;
}

export class EquipmentTypeImpl implements EquipmentType {
    code: string = '';
    description: string = '';
    id: number = 0;
    name: string = '';
}

export class CarrierImpl implements Carrier {
    TSA: boolean = false;
    address: Address = new AddressImpl();
    contact: Contact = new ContactImpl();
    name: string = '';
    scac: string = '';
    type: string = '';
}

export class ReferenceTypeImpl implements ReferenceType {
    code: string = '';
    description: string = '';
    name: string = '';
}

export class ReferenceImpl implements Reference {
    name: string = '';
    type: ReferenceType = new ReferenceTypeImpl();
    value: string = '';
}

export class SourceConfigImpl implements SourceConfig {
}

export class SourceImpl implements Source {
    sourceConfig: SourceConfig = new SourceConfigImpl();
    sourceReferences: Array<SourceReference> = [];
}

export class TransitTimeImpl implements TransitTime {
    destinationStop: number = 2;
    originStop: number = 1;
    uom: string = '';
    value: number = 0;
}

export class BookingOptionImpl implements BookingOption {
    LSP: Carrier = new CarrierImpl();
    accessorials: Array<Accessorial> = [];
    bookingStatus: string = '';
    expiry: string = '';
    rateDetails: Array<RateDetail> = [];
    rateID: number = 0;
    rateSummaries: Array<Rate> = [];
    route: Array<Stop> = [];
    sequence: number = 0;
    source: Source = new SourceImpl();
    terminalDetails: Array<TerminalDetail> = [];
    totalTransit: TransitTime = new TransitTimeImpl();
    transitTimes: Array<TransitTime> = [];
}

export class SourceReferenceImpl implements SourceReference {
}

export class TerminalDetailImpl implements TerminalDetail {
    destinationStop: number = 2;
    originStop: number = 1;
    terminalRoute: Array<TerminalRoute> = [];
}

export class TerminalImpl implements Terminal {
    address: Address = new AddressImpl();
    code: string = '';
    contact: Contact = new ContactImpl();
    id: number = 0;
    name: string = '';
}

export class TerminalRouteImpl implements TerminalRoute {
    terminal: Terminal = new TerminalImpl();
    terminalStopID: number = 0;
}

export class BookingSourceImpl implements BookingSource {
}

export class RateServiceTypeImpl implements RateServiceType {
}

export class RateSourceImpl implements RateSource {
}

export class RateDetailImpl implements RateDetail {
    bookingSource: BookingSource = new BookingSourceImpl();
    carrier: Carrier = new CarrierImpl();
    destinationStop: number = 2;
    equipment: Equipment = new EquipmentImpl();
    originStop: number = 1;
    rateServiceType: RateServiceType = new RateServiceTypeImpl();
    rateSource: RateSource = new RateSourceImpl();
    rates: Array<Rate> = [];
    references: Array<Reference> = [];
}

export class RateImpl implements Rate {
    rateBreakdown: Array<RateBreakdown> = [];
    type: string = '';
    rate: MonetaryValue = new MonetaryValueImpl();
}

export class RateBreakdownImpl implements RateBreakdown {
    code: string = '';
    description: string = '';
    name: string = '';
    sequence: number = 0;
    type: string = '';
    rate: MonetaryValue = new MonetaryValueImpl();
}

export class CustomerFieldImpl implements CustomerField {
    name: string = '';
    value: string = '';
}

export class BillToImpl implements BillTo {
    address: Address = new AddressImpl();
    contact: Contact = new ContactImpl();
    id: number = 0;
    name: string = '';
}

export class NoteImpl implements Note {
    note: string = '';
}

export class StatusImpl implements Status {
    code: string = '';
    ediCode: string = '';
    id: number = 0;
    name: string = '';
}

export class TotalLinearDimensionsImpl implements TotalLinearDimensions {
    uom: string = '';
    value: number = 0;
}
